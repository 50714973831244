/*
 * Created by Gerald Garcia on 26/03/15.
 */
module.exports = [
    'ConfigUtils',
    '$location',
    '$scope',
    '$state',
    '$stateParams',
    '$anchorScroll',
    'DaModal',
    'Confirmation',
    'Payment',
    'MitLogUtils',
    'WfeApiPayment',
    /**
     * Controller for the Payment
     * @constructor
     */
        function AsiapayCallbackController(ConfigUtils, $location, $scope, $state, $stateParams, $anchorScroll, DaModal,
                                               Confirmation, Payment, MitLogUtils,WfeApiPayment) {
        $anchorScroll();

        var fn = {

            goToNextPage: function (){

                var parameters = {
                    token: $scope.payment.MITSessionToken,
                    productState: $scope.product.ProductCode
                };

                if($stateParams.prc==='')//QA&UAT: No return prc & src
                {
                    $stateParams.prc='-99';
                }
                if($stateParams.src==='')
                {
                    $stateParams.src='-99';
                }
                $scope.$parent.asiapay.prc = $stateParams.prc;
                $scope.$parent.asiapay.src = $stateParams.src;
                $state.go('public.'+ $scope.product.ProductCode +'.quote.steps.buy', parameters);

            },


            //saving: {
            //    var savePayment = function () {
            //        var savePaymentFunc = fn.paymentApi.utils.getSaveFunctionName($scope.payment, 'Payment');
            //        fn.assetApi[savePaymentFunc](
            //            {
            //                onSuccess: function (data) {
            //                    console.log(data);
            //                    console.log('success: paymentApi.' + savePaymentFunc);
            //                    //savePH();
            //                },
            //                onError: function () {
            //                    hasError = true;
            //                    //savePH();
            //                }
            //            }
            //        );
            //    };
            //},



            savePayment: function(creditCard, callbacks) {
                callbacks = callbacks || {};
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return false;
                }

                var onSuccess = function (data) {
                    creditCard.PaRes = data.pares;
                    creditCard.XidRes = data.md;
                    fn.updatePayment(creditCard, callbacks);
                };

                fn.eventHandler.logEvent('/app/ui/code/asiapay.response.aspx', 'Before getting data from asiapay.response.aspx');

                Payment.getCybersourcePaymentResponse(creditCard, onSuccess,
                    function () {
                        fn.eventHandler.errorOnRequest('getCybersourcePaymentResponse', arguments);
                    }
                );

                return true;
            },

            createPayment: function(creditCard, callbacks) {

                return fn.updatePayment(creditCard, callbacks);
            },

            updatePayment: function(creditCard, callbacks) {
                var dummyCreditCard = {
                    PaRes: creditCard.PaRes,
                    XidRes: creditCard.XidRes
                };

                fn.eventHandler.logEvent(ConfigUtils.api + '/DataServices/Payment/UpdatePaymentStatus', 'Before update of PARes and MD', dummyCreditCard);

                Payment.updatePaymentStatus(undefined, creditCard,
                    function createMotorcyclePaymentSuccess(data){
                        if (!data.success) {
                            fn.eventHandler.errorOnRequest('updatePaymentStatus-successblock', arguments);
                            return;
                        }

                        fn.confirmPayment(creditCard, callbacks);

                    },

                    function () {
                        fn.eventHandler.errorOnRequest('updatePaymentStatus', arguments);
                    }
                );

                return true;
            },

            confirmPayment: function(creditCard, callbacks) {
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return;
                }

                fn.eventHandler.logEvent(ConfigUtils.api + '/TransactionServices/Confirmation/ProcessPayment', 'Before 2nd process payment');
                Confirmation.processPayment(undefined, creditCard,
                    function confirmMotorcycleSuccess(data){

                        if (!data.success) {
                            fn.eventHandler.errorOnRequest('processPayment-successblock', arguments);
                            return;
                        }

                        fn.eventHandler.logEvent(ConfigUtils.api + '/TransactionServices/Confirmation/ProcessPayment', '2nd process payment - Successful');
                        fn.postPaymentConfirmation(creditCard, data, callbacks);
                    },


                    function () {
                        fn.eventHandler.errorOnRequest('processPayment', arguments);
                    }
                );
            },

            postPaymentConfirmation: function(creditCard, data) {

                var isSuccess = true;
                //validate data here

                if (!data.success) {
                    $scope.hasError = true;
                    return;
                }


                if (isSuccess) {
                    fn.goToNextPage(data);
                }

                //if (callbacks.onPaymentSuccess) {
                //    callbacks.onPaymentSuccess(creditCard, data);
                //    //$scope.isPaymentSaving = false;
                //}
            },

            hasInvalid: function hasInvalid(){
                return false;
                //return $scope.frmCreditCard.$invalid ||
                //    (totalAmount || 0) <= 0;
            },



            //showSCSPopup: function () {
            //    DaModal.open('#CSCPopup');
            //},

            initScopeObjects: function () {
                $scope.creditCard = {};
                $scope.payment = {};
            },
            initApiObjects: function () {
                fn.paymentApi = WfeApiPayment.instance($scope.$parent.sessionApi, $scope, $scope.payment);
            },

            eventHandler: {

                logEvent: function (processName, msg, data) {

                    var dataString;
                    try {
                        if (data) {
                            dataString = angular.toJson(data);
                            msg = msg + ' data= ' + dataString;
                        }
                    }
                    catch(err){}

                    try {
                        var newObj = {
                            uniqueID: $scope.session.MITSessionToken,
                            serviceName: processName,
                            msg: msg
                        };

                        MitLogUtils.eventLogWrite(newObj);
                    }
                    catch(err){}

                },

                errorOnRequest: function (processName, args) {

                    try {
                        var newObj = {
                            sourceUrl: $location.url(),
                            exceptionMsg: processName,
                            stacktrace: angular.toJson(args)
                        };

                        MitLogUtils.exceptionLogWrite(newObj);
                    }
                    catch(err){}

                    $scope.isPaymentSaving = false;
                    $scope.hasError = true;
                    console.error('ERR: There was a problem when saving information.');
                }

            }
        };


        fn.initScopeObjects();
        $scope.hasError = true;
        fn.goToNextPage();

    }];