/**
 * Created by localsunil on 9/8/2015.
 */

/***
 *
 * @type {*[]}
 */
module.exports = [
    'HKFormValidation',
    'MITDate',
    '$filter',
    /**
     * TravelQuoteValidationService provides utility functions for page validation of Travel Car Quote
     * @returns {{}}
     * @constructor
     */
        function TravelQuoteValidationService(HKFormValidation, MITDate,$filter) {

        var fn = {

            validations: {
                validateCurrentStep: function ($scope) {
                    if (!$scope.$parent.getIsAccepted()) {
                        fn.goToStart();
                        return false;
                    }
                    return true;
                },

                validateToken: function ($scope, callbacksParam) {
                    callbacksParam = callbacksParam || {};

                    var onSuccess = callbacksParam.onSuccess;
                    var onError = callbacksParam.onError;

                    var callbacks =
                    {
                        onSuccess: function (data) {
                            if (onSuccess) {
                                onSuccess(data);
                            }
                        },

                        onError: function (data) {
                            console.log(data);
                            if (onError) {
                                onError(data);
                            }
                            //fn.eventHandlers.sessionExpiredHandler();
                        }
                    };

                    $scope.$parent.sessionApi.retrieveSession(callbacks);

                },


                isInvalidPHAge: function (dateOfBirthString, dateToCompare, formElement) {

                    if (!dateOfBirthString) {
                        if (formElement) {
                            formElement.$setValidity('custom_age', true);
                        }
                        return true;
                    }

                    var isValidAge = HKFormValidation.isValidAge(18, 70, dateOfBirthString, dateToCompare);

                    if (formElement) {
                        formElement.$setValidity('custom_age', isValidAge);
                    }

                    return !isValidAge;
                },

                isInvalidChildAgeFamilyPlan: function (dateOfBirthString, dateToCompare, formElement) {

                    if (!dateOfBirthString) {
                        if (formElement) {
                            formElement.$setValidity('custom_age', true);
                        }
                        return true;
                    }

                    var isValidAge = HKFormValidation.isValidAge(0, 24, dateOfBirthString, dateToCompare);

                    if (formElement) {
                        formElement.$setValidity('custom_age', isValidAge);
                    }

                    return !isValidAge;
                },

                isInValidRelationWithPH: function (policyContacts) {
                    var duplicate = $filter('filter')(policyContacts, {RelationshipIdWithPH: '1000002'}, true);
                    if(duplicate.length >= 2){
                        return true;
                    }
                    return false;
                },




                isValidMinDate: function (dateObj, minDate) {
                    return dateObj.diff(minDate, 'days') >= 0;
                },

                isValidMaxDate: function (dateObj, maxDate) {
                    return dateObj.diff(maxDate, 'days') <= 0;
                },

                isInvalidDateInRange: function (dateObj, minDate, maxDate) {

                    if (!dateObj) {
                        return false;
                    }

                    var startDate = dateObj;
                    var isValidMin = fn.validations.isValidMinDate(startDate, minDate);

                    if (!isValidMin) {
                        return true;
                    }

                    return !fn.validations.isValidMaxDate(startDate, maxDate);

                },

                isInvalidStartDate: function (dateString, formElement) {
                    if (!dateString) {
                        if (formElement) {
                            formElement.$setValidity('custom_start_date', true);
                        }
                        return false;
                    }

                    var startDate = MITDate.newDate(dateString);

                    var minDate = MITDate.makeDate(MITDate.toAngularDate( MITDate.newDate().add(1, 'days')));
                    var maxDate = MITDate.makeDate(MITDate.toAngularDate( MITDate.newDate().add(3, 'months')));

                    var isInvalid = fn.validations.isInvalidDateInRange(startDate, minDate, maxDate);

                    if (formElement) {
                        formElement.$setValidity('custom_start_date', !isInvalid);
                    }

                    return isInvalid;
                },

                isInvalidEndDate: function (dateString, startDateString, formElement) {

                    if ((!dateString) || (!startDateString)) {
                        if (formElement) {
                            formElement.$setValidity('custom_end_date', true);
                        }
                        return false;
                    }

                    var endDate = MITDate.newDate(dateString);
                    var minDate = MITDate.newDate(startDateString).add(7, 'months');
                    var maxDate = MITDate.newDate(startDateString).add(18, 'months');

                    var isInvalid = fn.validations.isInvalidDateInRange(endDate, minDate, maxDate);

                    if (formElement) {
                        formElement.$setValidity('custom_end_date', !isInvalid);
                    }

                    return isInvalid;
                }
            }

        };

        this.isInvalidPHAge = fn.validations.isInvalidPHAge;
        this.isInValidRelationWithPH = fn.validations.isInValidRelationWithPH;
        this.isInvalidChildAgeFamilyPlan = fn.validations.isInvalidChildAgeFamilyPlan;

        this.isInvalidStartDate = fn.validations.isInvalidStartDate;
        this.isInvalidEndDate = fn.validations.isInvalidEndDate;
    }];