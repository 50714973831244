module.exports = [
    '$anchorScroll',
    '$scope',
    '$window',
    '$state',
    '$filter',
    'HKLocale',
    'ConfigUtils',
    'DaModal',
    'MITDate',
    'FormValidation',
    'Confirmation',
    'Payment',
    'Policy',
    'StaticDataKeyValuePair',
    'WfeApiStaticData',
    'WfeApiLog',

    function B2CPaymentController($anchorScroll, $scope, $window, $state, $filter,
                               HKLocale, ConfigUtils, DaModal, MITDate, FormValidation,
                               Confirmation, Payment, Policy, StaticDataKeyValuePair,
                               WfeApiStaticData, WfeApiLog) {
        $anchorScroll();

        var fnNew = {

            initStaticData: function () {
                var wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.$parent.$parent.product.ProductId);

                var startRetrieval = function () {
                    getBooleanListWithSometimes();
                };

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },
                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getIssuingBankList();
                        },
                        onError: function () {
                            getIssuingBankList();
                        }
                    });
                };

                var getIssuingBankList = function () {
                    wfeApiStaticData.getIssuingBankList({
                        onSuccess: function (data) {

                            var skipList = ["90078",
                                            "90079",
                                            "90081",
                                            "90082",
                                            "90084",
                                            "90085",
                                            "80123"];

                            var useList = [];

                            data.forEach(function(xx, ix){
                                if(skipList.indexOf(xx.key) < 0 ){
                                    useList.push(xx);
                                }
                                else{
                                    //console.log("rem " + xx.key + " - " + xx.value);
                                }
                            });

                            $scope.staticData.issuingBanks = useList;
                            getCreditCardTypeList();
                        },

                        onError: function () {
                            getCreditCardTypeList();
                        }
                    });
                };

                var getCreditCardTypeList = function () {
                    wfeApiStaticData.getCreditCardTypeList({
                        onSuccess: function (data) {
                            let creditCardTypes = [];
                            
                            var trueIndex = data.findIndex(x => x.value == "True Money Wallet")
                            if(trueIndex != -1) {
                                data[trueIndex].value = 'TrueMoney Wallet'
                            }

                            creditCardTypes = data;
                            // creditCardTypes.push({key: "000000", value: "TrueMoney Wallet"});
                            
                            $scope.staticData.creditCardTypes = creditCardTypes;
                            // console.log(`$scope.staticData.creditCardTypes : ${JSON.stringify($scope.staticData.creditCardTypes)}`);

                        //    let cardTypes2 = {};
                        //    cardTypes2 = {...$scope.staticData.creditCardTypes};
                        //    console.log(`cardTypes2 : ${JSON.stringify(cardTypes2)}`);
                           

                        }
                    });
                };

                startRetrieval();
            },

            initScopeObjects: function () {
                $scope.staticData = {};
                $scope.creditCard = {};
                $scope.bidata = {};

                if($scope.HKLocale.getLocale() === "en-us") {
                    $scope.staticDataExpMonth= [
                            {key: '01', value: 'Jan (01)'},
                            {key: '02', value: 'Feb (02)'},
                            {key: '03', value: 'Mar (03)'},
                            {key: '04', value: 'Apr (04)'},
                            {key: '05', value: 'May (05)'},
                            {key: '06', value: 'Jun (06)'},
                            {key: '07', value: 'Jul (07)'},
                            {key: '08', value: 'Aug (08)'},
                            {key: '09', value: 'Sep (09)'},
                            {key: '10', value: 'Oct (10)'},
                            {key: '11', value: 'Nov (11)'},
                            {key: '12', value: 'Dec (12)'}
                        ];
                }
                else{
                    $scope.staticDataExpMonth= [
                            {key: '01', value: 'ม.ค. (01)'},
                            {key: '02', value: 'ก.พ. (02)'},
                            {key: '03', value: 'มี.ค. (03)'},
                            {key: '04', value: 'เม.ษ. (04)'},
                            {key: '05', value: 'พ.ค. (05)'},
                            {key: '06', value: 'มิ.ย. (06)'},
                            {key: '07', value: 'ก.ค. (07)'},
                            {key: '08', value: 'ส.ค. (08)'},
                            {key: '09', value: 'ก.ย. (09)'},
                            {key: '10', value: 'ต.ค. (10)'},
                            {key: '11', value: 'พ.ย. (11)'},
                            {key: '12', value: 'ธ.ค. (12)'}
                        ];
                }

                var d = new Date();
                var y = parseInt(d.getFullYear());
                $scope.staticDataExpYear=[];
                for (var i = y; i <= ( y + 10 ) ; i++) { // Original: Current year + 20, Changed to + 10
                    $scope.staticDataExpYear.push({key:i,value:i})
                }

            },

            initApiObjects: function () {
                fnNew.logApi = WfeApiLog.instance($scope.$parent.sessionApi, null, $scope.bidata, null);
            },

            retrieveScopeObjects: function () { /* nothing to do */ },

            eventHandlers: {
                setIssuingBankExternalContactNumber: function (e) {
                    $scope.txtIssuingBankExternalContactNumber = e.target.textContent.trim();
                    $scope.creditCard.IssuingBankExternalContactNumber = e.target.dataset.key;
                },
            }

        };

        fnNew.initScopeObjects();
        fnNew.initApiObjects();
        fnNew.initStaticData();
        fnNew.retrieveScopeObjects();

        // Event Handlers
        $scope.setIssuingBankExternalContactNumber = fnNew.eventHandlers.setIssuingBankExternalContactNumber;

        var fn = {
            savePayment: function (creditCard, callbacks) {
                callbacks = callbacks || {};
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return false;
                }

                fn.updatePayment(creditCard, callbacks);

                return true;
            },

            createPayment: function (creditCard, callbacks) {

                Payment.create(undefined, creditCard,
                    function createMotorcyclePaymentSuccess(data) {
                        if (!data.success) {
                            if (callbacks.onCreatePaymentError) {
                                callbacks.onCreatePaymentError(data);
                            }
                        }
                    },

                    callbacks.onCreatePaymentError
                );

                return true;
            },

            updatePayment: function (creditCard, callbacks) {
                var onError = function (data) {
                    if (callbacks && callbacks.onPaymentError) {
                        callbacks.onPaymentError(creditCard, data);
                    }
                    fn.eventHandler.errorOnRequest(data, callbacks);
                };

                Payment.update(undefined, creditCard,
                    function createMotorcyclePaymentSuccess(data) {
                        if (!data.success) {
                            onError(data);
                            return;
                        }
                        fn.confirmPayment(creditCard, callbacks);
                    },

                    onError
                );

                return true;
            },

            /**
             * only credit card mode and not defer payment must do process payment
             * @returns {boolean}
             */
            isForProcessPayment: function () {
                return true;
            },

            confirmPayment: function (creditCard, callbacks) {
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return;
                }

                var origin = ConfigUtils.origin;
                creditCard.ReturnUrl = origin +
                    '/app/ui/code/b2c.cybersource.callback.aspx?pcode=b2c&token=' + encodeURIComponent(creditCard.MITSessionToken);

                var onError = function (data) {
                    try{
                        if(data.ErrorInfo && data.ErrorInfo.StatusCode=="PAYMENT_FAILURE") {
                            var validationNumberString = (data.ErrorInfo.ValidationNumber || '') + '';
                            $scope.$parent.cyberResponseId = validationNumberString;
                            $scope.$parent.DaModal = DaModal;
                            DaModal.open('#cyberSourceResponse');
                        }
                        else if(data.Payment === null){
                            DaModal.open('#cyberSourceResponse');
                        }
                    }
                    catch (e) {
                        console.log(e);
                    }

                    if (callbacks && callbacks.onPaymentError) {
                        callbacks.onPaymentError(creditCard, data);
                    }
                    fn.eventHandler.errorOnRequest(data, callbacks);
                };

                //Proceed to processPayment only if credit card and not a defer payment build faild. try again
                if (!fn.isForProcessPayment()) {
                    fn.postPaymentConfirmation(creditCard, creditCard, callbacks);
                    return;
                }

                Confirmation.processPayment(undefined, creditCard,
                    function confirmMotorcycleSuccess(data) {
                        $scope.isPaymentSaving = false;

                        if (!data.success) {
                            onError(data);
                            return;
                        }

                        /*if (data.Payment && data.Payment.PaymentStatusString === 'Success') {
                            fn.redirectToAsiaPay(data);
                            return;
                        }*/
                        if (data.Payment && data.Payment.PaymentStatusString === 'RequireExternalCall') {
                            fn.redirectToCyberSource(data);
                            return;
                        }
                        else if (data.Payment && data.Payment.PaymentStatusString === 'Fail') {
                            onError(data);
                            return;
                        }else if (data.Payment === null){
                            onError(data);
                            return;
                        }

                        fn.postPaymentConfirmation(creditCard, data, callbacks);
                    },

                    onError
                );
            },

            postPaymentConfirmation: function (creditCard, data, callbacks) {

                $scope.isPaymentSaving = false;
                if (callbacks.onPaymentSuccess) {
                    callbacks.onPaymentSuccess(creditCard, data);
                }
            },

            redirectToAsiaPay: function (data) {
                Payment.postToAsiaPay(data);
            },
            redirectToCyberSource: function (data) {
                Payment.postToCybersource(data);
            },

            isOtherBankSelected: function () {
                return $scope.creditCard.IssuingBankExternalContactNumber === '90112';
            },

            buyProduct: function buyProduct(mitScopeBindingUtils, totalAmount, callbacks) {
                $scope.creditCard.MITSessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken;

                if ($scope.creditCard.ExpDate) {
                    var expDate = MITDate.makeDate($scope.creditCard.ExpDate, MITDate.baseMonthFormat);
                    if (expDate.isValid()) {
                        $scope.creditCard.ValidYear = '' + MITDate.getYYYY(expDate);
                        $scope.creditCard.ValidMonth = '' + MITDate.getMM(expDate);
                    }
                }

                if (!fn.isOtherBankSelected()) {
                    $scope.creditCard.OtherBankName = '';
                }

                $scope.creditCard.PurchaseAmount = totalAmount;

                return fn.savePayment($scope.creditCard, callbacks);
            },

            hasInvalid: function hasInvalid(totalAmount) {

                return $scope.frmPayment.$invalid ||
                    (totalAmount || 0) <= 0;
            },

            showSCSPopup: function () {
                DaModal.open('#CSCPopup');
            },

            initScopeObjects: function () {
                $scope.session = {};
                $scope.policy = {};
                $scope.creditCard = {};

                fn.createPayment($scope.sessionApi.pAndCModelStorage.session, {
                    onCreatePaymentError: fn.errorOnRequest
                });
            },

            initWatchers: function () {
                var sessionWatcher, currentProposalWatcher;

                var pAndCCallBack = function (newValueModel) {
                    if (!newValueModel) {
                        return;
                    }

                    sessionWatcher = $scope.$parent.$parent.$on('Session/Bind', sessionCallBack);
                    currentProposalWatcher = $scope.$parent.$parent.$on('CurrentProposal/Bind', currentProposalCallBack);

                    if ($scope.$parent.$parent.mitScopeBindingUtils) {
                        $scope.$parent.$parent.mitScopeBindingUtils.firstBind();
                    }
                };

                var sessionCallBack = function (eventObj, newValue) {
                    angular.extend($scope.session, newValue);

                    fn.createPayment(newValue, {
                        onCreatePaymentError: fn.errorOnRequest
                    });
                };

                var currentProposalCallBack = function (eventObj, newValue) {
                    angular.extend($scope.policy, newValue);
                };

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        sessionWatcher();
                        currentProposalWatcher();
                        destroyWatcher();
                    }
                );

                pAndCCallBack({});

            },

            paymentStaticData: {
                paymentModes: []
            },

            resetCreditcardExpStatus: function () {
                $scope.creditcardExpStatus = true;
            },

            validateCreditcardExp: function () {
                var valid = false;

                var d = new Date();
                var y = parseInt(d.getFullYear());
                var m = parseInt(d.getMonth() + 1);

                var temp = $scope.creditCard.ExpDate;

                if (temp === undefined) {
                    valid = true;
                }
                else {
                    var month = parseInt(temp.substring(0, 2));
                    var year = parseInt(temp.substring(3));

                    if (year > y) {
                        valid = true;
                    } 
                    else if (year === y) {
                        if (month >= m) {
                            valid = true;
                        }
                    }
                }

                $scope.creditcardExpStatus = valid;
                return valid;
            },
            receivedExpMonth :function() {
                $scope.creditCard.ExpDate='';
                if($scope.creditCard.ExpMonth) {
                    if($scope.creditCard.ExpYear) {
                        $scope.creditCard.ExpDate = $scope.creditCard.ExpMonth + '/' +  $scope.creditCard.ExpYear;
                        fn.validateCreditcardExp();
                    }
                }
            },
            receivedExpYear :function() {
                $scope.creditCard.ExpDate='';
                if($scope.creditCard.ExpYear) {
                    if($scope.creditCard.ExpMonth) {
                        $scope.creditCard.ExpDate = $scope.creditCard.ExpMonth + '/' + $scope.creditCard.ExpYear;
                        fn.validateCreditcardExp();
                    }
                }
            },

            isValidAccountNumber: function () {
                var masterRegExp = /^5[1-5][0-9]{14}$/;
                var visaRegExp = /^4[0-9]{12}(?:[0-9]{3})?$/;

                if ($scope.creditCard.CreditCardTypeString === 'MasterCard') {
                    return masterRegExp;
                }
                else {
                    return visaRegExp;
                }
            },
            isValidCreditCardNumber: function () {
                if(!$scope.creditCard.AccountNumberWithDash) {
                    $scope.invalidCreditCardNumber = false;
                    return;
                }
                let cardnumber = $scope.creditCard.AccountNumberWithDash.replace(/[^0-9\.]+/g, "")
                var creditRegExp = /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/;
                let isCreditRegExp = creditRegExp.test(cardnumber);
                if (isCreditRegExp) {
                    $scope.invalidCreditCardNumber = false;
                    //console.log(`isValidCreditCardNumber isCreditRegExp : ${isCreditRegExp}`);
                }
                else {
                    $scope.invalidCreditCardNumber = true;
                    //console.log(`isValidCreditCardNumber isCreditRegExp : ${isCreditRegExp}`);
                }
            },

            deferPaymentDisplay: function () {
                var startDate = MITDate.makeDate($scope.currentProposal.PolicyStartDate);
                var currentDate = MITDate.newDate();
                var difference = startDate.diff(currentDate, 'days');

                if (difference > 14) {
                    return true;
                }
                return false;
            },

            eventHandler: {
                errorOnRequest: function () {
                    $scope.isPaymentSaving = false;
                    console.error('ERR: There was a problem when saving information.');
                },

                exceptionOnRequest: function exceptionOnRequest(data) {
                    $scope.isPaymentSaving = false;
                    var msgObj = DaModal.makeGenericErrorMessage(data);
                    DaModal.exception(msgObj.message);
                    console.error('EXP: Status "Fail" returned by MBL from processing the payment.');
                },

                doFormValidation: function doFormValidation(mitScopeBindingUtils, totalAmount) {
                    $scope.paymentPageSubmitted = true;

                    if (fn.hasInvalid(totalAmount)) {
                        FormValidation.focusOnError();
                        return false;
                    }

                    if (!fn.validateCreditcardExp()) {
                        FormValidation.focusOnError();
                        return false;
                    }

                    return true;
                },

                doPayment: function doPayment(mitScopeBindingUtils, productId, isRenewalProposal, totalAmount, callbacks) {

                    // Remove "-" out from CreditCard format before send to IDIT
                    $scope.creditCard.AccountNumber = $scope.creditCard.AccountNumberWithDash.replace(/[^0-9\.]+/g, "")

                    if ($scope.isPaymentSaving) {
                        console.error('ERR: still saving ...');
                        return false;
                    }

                    var isValid = fn.eventHandler.doFormValidation(mitScopeBindingUtils, totalAmount);

                    if (!isValid) {
                        return false;
                    }

                    callbacks.enableLoadingMask();
                    try {
                        if ($scope.$parent.product.isBILogEnabled) {
                            if (!isRenewalProposal) {
                                $scope.bidata = {
                                    Status: 'Proceed (buy) button clicked'
                                };
                                fnNew.logApi.updateLog();
                            }
                        }
                    }
                    catch (err) { /* nothing to do */ }

                    $scope.isPaymentSaving = true;
                    $scope.isPaymentSaving = fn.buyProduct(mitScopeBindingUtils, totalAmount, callbacks);
                    return $scope.isPaymentSaving;
                }
            }
        };

        fn.initScopeObjects();

        $scope.showSCSPopup = fn.showSCSPopup;
        $scope.isOtherBankSelected = fn.isOtherBankSelected;

        $scope.$parent.PaymentScope = {
            hasInvalid: fn.hasInvalid,
            doFormValidation: fn.eventHandler.doFormValidation,
            doPayment: fn.eventHandler.doPayment,
            policy: $scope.policy
        };

        $scope.paymentStaticData = fn.paymentStaticData;
        $scope.paymentStaticData.paymentModes = StaticDataKeyValuePair.getPaymentMode();
        $scope.receivedExpMonth=fn.receivedExpMonth;
        $scope.receivedExpYear=fn.receivedExpYear;
        $scope.validateCreditcardExp = fn.validateCreditcardExp;
        $scope.resetCreditcardExpStatus = fn.resetCreditcardExpStatus;
        $scope.creditcardExpStatus = true;
        $scope.invalidCreditCardNumber = false;
        $scope.isValidAccountNumber = fn.isValidAccountNumber;
        $scope.isValidCreditCardNumber = fn.isValidCreditCardNumber;
        $scope.deferPaymentDisplay = fn.deferPaymentDisplay;

    }];