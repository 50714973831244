/*
 * Created by Chanon Tangputthajit 2021/04/26
 */
module.exports = [
    '$scope',
    '$state',
    '$anchorScroll',
    'DaModal',
    'StaticDataKeyValuePair',
    'WfeApiCurrentProposal',
    'WfeApiStaticData',
    'WfeApiPayment',
    'WfeApiConfirmation',
    'WfeApiPolicyContact',
    '$window',
    'HKFormValidation',
    'WfeApiContact',
    'WfeApiCoverages',
    '$filter',
    'WfeApiAsset',
    '$timeout',
    '$localStorage',
    '$sessionStorage',
    

    /**
     * Controller for the Buy of Quote
     * @constructor
     */
    function B2CMTISuccessController($scope, $state, $anchorScroll, DaModal, StaticDataKeyValuePair, 
                                        WfeApiCurrentProposal,  WfeApiStaticData, 
                                        WfeApiPayment, WfeApiConfirmation, WfeApiPolicyContact,
                                        $window, HKFormValidation,
                                        WfeApiContact, WfeApiCoverages, $filter, WfeApiAsset, $timeout, $localStorage,$sessionStorage) {


        var fn = {

            initScopeObjects: function () {
                $scope.currentdate = new Date();
                $scope.isBtnSaveHide = true;
                $scope.currentProposal = {};
                $scope.creditCard = {};
                // $scope.paymentConsentPrivacyFlag = false;
                $scope.isBtnBackHide = false;  // Hide back button
                $scope.buyControllerStaticData = {
                    allPaymentTerms: [],
                    products: [],
                    localePaymentTerms:[]
                };
                $scope.phContact = {};
                $scope.currentProposal.agreeInstallments=false;
                $scope.policyHolder={};
                $scope.coverages = {};
                $scope.selectedCoverage = {};
                $scope.changeNextCopy=true;
                $scope.proceedBuy=true;
                $scope.staticData={};
                $scope.callbackObj={};
                $scope.showSaveAndExitOnBuyPage=false;
                $scope.showConfirmBuyPageB2C=true;
                $scope.showConfirmBuyPageOnlineSelling=false;
                $scope.asset = {};

                // For OptinMonster Checker
                $sessionStorage.currentPage = 5; 
                $scope.Ca = $sessionStorage.currentPage
            },

            initApiObjects: function () {
                
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
            },

            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrieveProposal();
                };
                var retrieveProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal({
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                            },
                            onError: function () {
                                /* nothing to do */
                            }
                    });
                };

                startRetrievalScopeObject();
            },
        };

        $scope.getJSONFromSeconObjMaxar = $sessionStorage.getSecondObjToMX ? $sessionStorage.getSecondObjToMX : null
        
        fn.initScopeObjects();
        fn.initApiObjects();
        // fn.initStaticData();
        fn.retrieveScopeObjects();
        
    }];