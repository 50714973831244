/**
 * Created by Gerald Garcia on 15/09/14.
 */
module.exports = [
    'ConfigUtils',
    '$scope',
    '$document',
    '$rootScope',
    '$location',
    '$window',
    '$state',
    '$timeout',
    '$sessionStorage',
    'DaAuthorization',
    'Account',
    'Login',
    '$localStorage',
    'DaModal',
    'Idle',

    function UserstateController(ConfigUtils, $scope, $document, $rootScope, $location, $window, $state, $timeout,
                                 $sessionStorage, DaAuthorization, Account, Login, $localStorage, DaModal, Idle) {
        DaAuthorization.initAuthorization();
        $scope.isAuthenticated = false;
        $scope.userName = 'DA User';

        var fn = {

            setPageTitle: function(event, toState){
                $document[0].title = toState.title || 'DirectAsia';
            },

            isUserLoggedIn: false,

            productPages: [
                {
                    state: 'public.motor.quote.start',
                    title: 'Car'
                },
                {
                    state: 'public.motorcycle.quote.start',
                    title: 'Motorcycle'
                },
                {
                    state: 'public.travel.quote.start',
                    title: 'Travel'
                },
                {
                    state: 'public.termlife.quote.start',
                    title: 'Life'
                },
                {
                    state: 'public.health.quote.start',
                    title: 'Health'
                }
            ],

            goToQuotePage: function(pageObject){
                if (!pageObject) {
                    return;
                }

                $state.go(pageObject.state);
            },

            goToHomePage: function(){
                var homePageUrl = ConfigUtils.homePageUrl || '/';

                if (homePageUrl.indexOf('https://') >= 0) {
                    $window.location.href = homePageUrl;
                    return;
                }

                var goToHome = function () {
                    $location.path(homePageUrl);
                    $timeout.cancel(timeoutGoToHome);
                };

                var timeoutGoToHome = $timeout(goToHome, 10);
            },

            goToQuotePageB2C: function(){
                var homePageUrl = `${$window.location.origin}/b2c/start/?lang=th-th`;

                if (homePageUrl.indexOf('https://') >= 0) {
                    $window.location.href = homePageUrl;
                    return;
                }

                var goToQuotePageB2C = function () {
                    $location.path(homePageUrl);
                    $timeout.cancel(timeoutGoToHome);
                };

                var timeoutGoToHome = $timeout(goToQuotePageB2C, 10);
            },

            goToChangePasswordPage: function(){
                $state.go('private.changepassword');
            },

            goToLogin: function(){
                $state.go('public.login');
            },

            goToOtpPage: function(){
                $state.go('private.otppassword');
            },

            showSessionExpired: function () {
                DaModal.open('#sessionExpired',{onClose: fn.goToHomePage});
            },

            initScopeObjects: function () {
                $scope.productPages = fn.productPages;
                var currentUser = DaAuthorization.getAuthorizedPerson();
                fn.setLoggedInUser(currentUser);

                $scope.promoUrl = "";
                $scope.partialUrl = "";
                $scope.callbackUrl = "";
                $scope.isUrlAvailable = false;
            },

            getLoggedInUser: function () {
                var currentUser = DaAuthorization.getAuthorizedPerson();

                if (!currentUser) {
                    return {
                        fullName: 'DirectAsia User'
                    };
                }

                if ((!currentUser.fullName) || (currentUser.fullName.trim() === '')) {
                    currentUser.fullName = (currentUser.FirstName||'') +
                    ' ' +
                    (currentUser.LastName || '');
                }

                return currentUser;
            },

            setLoggedInUser: function (user) {
                if (!user) {
                    return;
                }

                //angular.extend($scope.loggedInUser, user);
                //if (!user.fullName) {
                //    $scope.loggedInUser.fullName = ($scope.loggedInUser.FirstName||'') +
                //    ' ' +
                //    ($scope.loggedInUser.LastName || '');
                //}
            },

            initWatchers: function () {
                fn.setPageTitle(undefined, $state.current);
                $rootScope.$on('$stateChangeSuccess', fn.setPageTitle);

                $scope.isEnableFooter = false;
                $scope.$on('ENABLE_PUBLIC_FOOTER', function(events, args){
                    $scope.isEnableFooter = args;
                });

                var privateAccessWatcher, requireChangePasswordWatcher, requireOTPWatcher, otpSentWatcher, mitSessionWatcher;

                var defineWatcher = function () {
                    privateAccessWatcher = $rootScope.$on('FORBIDDEN_ACCESS', privateAccessCallback);
                    requireChangePasswordWatcher = $rootScope.$on('REQUIRE_CHANGE_PASSWORD', requireChangePasswordCallback);
                    requireOTPWatcher = $rootScope.$on('REQUIRE_SMS_OTP', requireOTPCallback);
                    otpSentWatcher = $rootScope.$on('SMS_OTP_SENT', otpSentCallback);

                    mitSessionWatcher = $rootScope.$on('SESSION_EXPIRED', sessionExpiredCallback);
                };

                var privateAccessCallback = function () {
                    fn.logoutCallback();
                };

                var requireChangePasswordCallback = function () {
                    fn.goToChangePasswordPage();
                };

                var requireOTPCallback = function () {
                    Login.sendOtpSms({})
                        .$promise.then(fn.goToOtpPage, fn.goToOtpPage);
                };

                var otpSentCallback = function () {
                    fn.goToOtpPage();
                };

                var sessionExpiredCallback = function () {
                    fn.showSessionExpired();
                };


                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        privateAccessWatcher();
                        requireChangePasswordWatcher();
                        requireOTPWatcher();
                        otpSentWatcher();

                        destroyWatcher();
                    }
                );

                defineWatcher();
            },

            setPageReady: function(isReady) {
                $scope.isAuthenticated = isReady;
            },

            isViewingPrivate : function (){

                if($state.includes('private')){
                    return true;
                }
                return false;
            },

            logout : function logout() {
                var logoutService = Account.logout();
                logoutService.$promise.then(fn.logoutCallback);
            },

            logoutCallback : function logout() {
                DaAuthorization.clearAuthorizedPerson();
                //$scope.isUserLoggedIn = false;
                $location.path('/login');
            },

            isLoggedIn: function () {
                return DaAuthorization.isLoggedIn();
                //$scope.isUserLoggedIn;
            },

            onFirstLoad: function () {
                $scope.isHidden = true;

                fn.setPageReady(false);
                //$scope.isUserLoggedIn = DaAuthorization.getAuthorization() && true;

                if ((!fn.isLoggedIn()) && fn.isViewingPrivate()) {
                    $location.path('/login');
                    $scope.isHidden = false;
                    return;
                }

                if (fn.isLoggedIn() && $location.path()==='/login') {
                    $location.path('/customer-access/policies');
                    return;
                }

                $scope.isHidden = false;
            },

            /** Doesn't use now : how to use => $scope.$parent.setEnableFooter(); */
            setEnableFooter: function(){
                $(document).ready(function(){
                    $('.main-footer').removeClass('da-override-display-none');
                    $('.post-footer').removeClass('da-override-display-none');
                });
            },

            onBannerLoad: function(){
                if($scope.HKLocale.getLocale() == 'th-th') {
                    $scope.promoUrl = 'https://static.directasia.co.th/DirectAsiaThailandSite/media/Images/car-loading-alpha.gif';
                    $scope.partialUrl = 'https://static.directasia.co.th/DirectAsiaThailandSite/media/Images/car-loading-alpha.gif';
                    $scope.callbackUrl = 'https://static.directasia.co.th/DirectAsiaThailandSite/media/Images/car-loading-alpha.gif';
                }else{
                    $scope.promoUrl = 'https://static.directasia.co.th/DirectAsiaThailandSite/media/Images/car-loading-alpha.gif';
                    $scope.partialUrl = 'https://static.directasia.co.th/DirectAsiaThailandSite/media/Images/car-loading-alpha.gif';
                    $scope.callbackUrl = 'https://static.directasia.co.th/DirectAsiaThailandSite/media/Images/car-loading-alpha.gif';
                }
            },

            daTimer:{

                closeModals: function(){

                },
                openTimerModal:function()
                {

                    try{
                        DaModal.open("#timeoutAction");
                    }
                    catch (e){
                        console.log(e);
                    }

                    try{
                        $window.dataLayer = $window.dataLayer || [];var dataLayer = $window.dataLayer;
                        dataLayer.push({
                            'event':'displayed',
                            'category':'timeout'
                        });
                    }
                    catch(er)
                    {

                    }

                },
                closeTimerModal:function()
                {
                    try{
                        DaModal.close("#timeoutAction");
                    }
                    catch(e){
                        console.log(e);
                    }

                },
                closeAgreeModal:function()
                {
                    try{
                        DaModal.close("#sessionExpired");
                    }
                    catch(e){
                        console.log(e);
                    }

                },
                interruptTimer:function()
                {
                    $rootScope.$broadcast('interruptTimer');
                    try{
                        $window.dataLayer = $window.dataLayer || [];var dataLayer = $window.dataLayer;
                        dataLayer.push({
                            'event':'clicked-extend-yes',
                            'category':'timeout'
                        });
                    }
                    catch(er)
                    {

                    }
                },
                closeAndGoBack:function()
                {
                    $rootScope.$broadcast('closeAndGoBack');
                    try{
                        $window.dataLayer = $window.dataLayer || [];var dataLayer = $window.dataLayer;
                        dataLayer.push({
                            'event':'clicked-extend-no',
                            'category':'timeout'
                        });
                    }
                    catch(er)
                    {

                    }
                },
                closeAndAgree:function()
                {
                    $rootScope.$broadcast('closeAndAgree');
                    fn.daTimer.closeAgreeModal();
                    try{
                        $window.dataLayer = $window.dataLayer || [];var dataLayer = $window.dataLayer;
                        dataLayer.push({
                            'event':'clicked-extend-no',
                            'category':'timeout'
                        });
                    }
                    catch(er)
                    {

                    }
                }


            },

        };

        $scope.$on('IdleStart', function() {
            //console.log('Idlestart');
            fn.daTimer.closeModals();
            fn.daTimer.openTimerModal();
        });
        $scope.$on('IdleEnd', function() {
            //console.log('Idleend');
            fn.daTimer.closeTimerModal();
        });
        $scope.$on('IdleTimeout', function() {
            //console.log("IT");
            fn.daTimer.closeTimerModal();
            //divert to home
        });

        $localStorage.credentials = null;
        fn.initScopeObjects();
        fn.initWatchers();

        fn.onFirstLoad();
        $scope.onFirstLoad = fn.onFirstLoad;
        $scope.setLoggedInUser = fn.setLoggedInUser;
        $scope.getLoggedInUser = fn.getLoggedInUser;
        $scope.isLoggedIn = fn.isLoggedIn;
        $scope.logout = fn.logout;
        $scope.goToQuotePage = fn.goToQuotePage;
        $scope.goToHomePage = fn.goToHomePage;
        $scope.goToQuotePageB2C = fn.goToQuotePageB2C;
        $scope.goToChangePasswordPage = fn.goToChangePasswordPage;
        $scope.goToLogin = fn.goToLogin;
        $scope.goToOtpPage = fn.goToOtpPage;
        $scope.showSessionExpired = fn.showSessionExpired;
        $scope.setEnableFooter = fn.setEnableFooter;
        $scope.onBannerLoad = fn.onBannerLoad;

        $scope.interuptTimer=fn.daTimer.interruptTimer;
        $scope.closeAndGoBack=fn.daTimer.closeAndGoBack;
        $scope.closeAndAgree=fn.daTimer.closeAndAgree;

        $scope.$on('SetSeoCampaign', (event, value) => {
            $scope.seoCampaign = value;
        });
    }];