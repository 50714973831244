/*
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$state',
    '$filter',
    '$anchorScroll',
    'WfeApiStaticData',
    'WfeApiConfirmation',
    'WfeApiCurrentProposal',
    /**
     * Controller for the Decline Renewal Controller
     * @constructor
     */
	 
	 function DeclineRenewalSuccessController($scope, $state, $filter, $anchorScroll,
        WfeApiStaticData,WfeApiConfirmation,WfeApiCurrentProposal) {
        $anchorScroll();
		
		var fn = {
            initApiObjects: function () {
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);

            },
            initScopeObjects: function () {
                $scope.currentProposal = {};
                $scope.confirmation = {};
            },
            retrieveScopeObjects:function(){
                fn.currentProposalApi.retrieveCurrentProposal(
                    {
                        onSuccess: function(data) {
                            angular.extend($scope.currentProposal, data);
                            //angular.extend($scope.policy, data);
                        }
                    }
                );
            }
			
            //initWatchers: function(){
            //    var //policyHolderWatcher,
				//	//coverageWatcher;
				//	//confirmationCreateWatcher;
            //        currentProposalWatcher,
            //        confirmationWatcher;
				//
            //
            //    var pAndCCallBack = function(newValueModel){
            //        if (!newValueModel) {
            //            return;
            //        }
            //
            //        currentProposalWatcher = $scope.$parent.$on('CurrentProposal/Bind', currentProposalCallBack);
            //        //policyHolderWatcher = $scope.$parent.$on('PolicyHolder/Bind', policyHolderCallBack);
            //        //coverageWatcher = $scope.$parent.$on('Coverages/Bind', coverageCallBack);
            //
            //        confirmationWatcher = $scope.$parent.$on('Confirmation/Bind', confirmationCallBack);
            //        //confirmationCreateWatcher = $scope.$parent.$on('Confirmation/Create', confirmationCreateCallBack);
            //
            //        if ($scope.$parent.mitScopeBindingUtils) {
            //            $scope.$parent.mitScopeBindingUtils.firstBind();
            //        }
            //    };
            //
            //    var currentProposalCallBack = function(eventObj, newValue){
            //        angular.extend($scope.currentProposal, newValue);
            //        if (newValue.ProductId) {
            //            //$scope.$parent.mitScopeBindingUtils.retrieveCoverage();
				//
				//		if(!newValue.IsRenewalProposal)//checking if it is renewal
				//		 { $state.go('private.account.user.policies'); }
            //        }
            //    };
            //
            //    var confirmationCallBack = function(eventObj, newValue){
            //        angular.extend($scope.confirmation, newValue);
            //    };
            //
            //
            //    var destroyWatcher = $scope.$on('$destroy',
            //        function () {
            //            //policyHolderWatcher();
            //            currentProposalWatcher();
            //            //coverageWatcher();
            //
            //            confirmationWatcher();
            //            //confirmationCreateWatcher();
            //
            //            destroyWatcher();
            //        }
            //    );
            //
            //    pAndCCallBack({});
            //}
		};
		


        fn.initScopeObjects();
        fn.initApiObjects();
        fn.retrieveScopeObjects();


	
		}];