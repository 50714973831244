module.exports = [
    'HKLocale',
    'ConfigUtils',
    '$http',

    /**
     * Country Level Locale for HK
     * @returns {Function}
     * @constructor
     */
    function ExternalServiceProvider(HKLocale, config, $http) {

        var confighead = {
            withCredentials: true,
            headers: {
                'Content-Type': '"application/json; charset=utf-8";',
                'dataType': '"json"'
            }
        };

        const aws4 = require('aws4');

        const awsEndpoint  = "https://n0hf9a902f.execute-api.ap-southeast-1.amazonaws.com/develop/google-conversion";
        const region = 'ap-southeast-1';
        const service = 'execute-api';

        const credentials = {
            accessKeyId: 'AKIAW6SBUH7GYHBRWUDW',
            secretAccessKey: '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',
        };

        var fn = {
            relaySend: function (callback, requestString) {
                try {
                    let requestParam = requestString;

                    let obj = JSON.parse(requestString);
                    let priority = 'priority' in obj ? obj.priority : 0;
                    let language = 'language' in obj ? obj.language : '';
                    if (priority > 0 && language !== '') {
                        let languages = language.split('-');
                        let newLang = languages[0] + '_P' + priority.toString();
                        obj.language = newLang;

                        requestParam = JSON.stringify(obj);
                    }
                    
                    $http.post(config.api + '/ExternalServices/Relay/Send', requestParam, confighead)
                        .success(function (data, status) {
                            if (callback !== null) {
                                callback.onSuccess(data, status);
                            }
                        })
                        .error(function (err, status) {
                            if (callback !== null) {
                                callback.onError(err, status);
                            }
                        });
                } catch (err) {
                    callback.onError(err, 500);
                }
            },
            getPriority: function (callback,requestString) {
                try {
                    var confighead = {
                        withCredentials: true,
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }

                    let requestParam = requestString;

                    let obj = JSON.parse(requestString);
                    let ProductType = 'ProductType' in obj ? obj.ProductType : '';                  
                    let ProductTypeId = 'ProductTypeId' in obj ? obj.ProductTypeId : 0;                  
                    if (ProductTypeId !== '' && ProductType != '') {
                        let newProductType = ProductType;
                        switch (ProductTypeId) {
                            case 2000015:
                                newProductType = "TYPE_1";
                                break;
                            case 7000114:
                                newProductType = "TYPE_1LITE";
                                break;
                            case 2000017:
                                newProductType = "TYPE_2PLUS";
                                break;
                            case 2000016:
                                newProductType = "TYPE_2";
                                break;
                            case 2000019:
                                newProductType = "TYPE_3PLUS";
                                break;
                            case 2000018:
                                newProductType = "TYPE_3";
                                break;
                                default: break;
                        // switch (productType) {
                        //     case "Voluntary Type 1":
                        //         newProductType = "TYPE_1";
                        //         break;
                        //     case "Voluntary Type 1 LITE":
                        //         newProductType = "TYPE_1LITE";
                        //         break;
                        //     case "Voluntary Type 2+":
                        //         newProductType = "TYPE_2PLUS";
                        //         break;
                        //     case "Voluntary Type 2\t":
                        //         newProductType = "TYPE_2";
                        //         break;
                        //     case "Voluntary Type 3+":
                        //         newProductType = "TYPE_3PLUS";
                        //         break;
                        //     case "Voluntary Type 3":
                        //         newProductType = "TYPE_3";
                        //         break;
                        //         default: break;
                        }
                        obj.ProductType = newProductType;
                    }
                    let ClaimExperienceId = 'ClaimExperienceId' in obj ? obj.ClaimExperienceId : '';                  
                    if (ClaimExperienceId !== '') {
                        let newClaimExperience = ClaimExperienceId;
                        switch (ClaimExperienceId) {
                            case "1000003":
                                newClaimExperience = "0";
                                break;
                            case "1000004":
                                newClaimExperience = "1_AT_FAULT";
                                break;
                            case "1000008":
                                newClaimExperience = "1_NOT_AT_FAULT";
                                break;
                            case "1000011":
                                newClaimExperience = "2_ONE_AT_FAULT_ONE_NOT_AT_FAULT";
                                break;
                            case "1000005":
                                newClaimExperience = "2_AT_FAULT";
                                break;
                            case "1000010":
                                newClaimExperience = "2_NOT_AT_FAULT";
                                break;
                            case "1000012":
                                newClaimExperience = "3_ONE_AT_FAULT_TWO_NOT_AT_FAULT";
                                break;
                            case "1000013":
                                newClaimExperience = "3_TWO_AT_FAULT_ONE_NOT_AT_FAULT";
                                break;
                            case "1000014":
                                newClaimExperience = "3_AT_FAULT";
                                break;
                            case "1000006":
                            newClaimExperience = "3_NOT_AT_FAULT";
                            break;
                            case "1000007":
                                newClaimExperience = "MORE_THAN_3";
                                break;
    
                                    default: break;
                        }
                        obj.ClaimExperience = newClaimExperience;
                    }
                    //***If want to use condition with value ClaimExperience
                    // let claimExperience = 'ClaimExperience' in obj ? obj.ClaimExperience : '';                  
                    // if (claimExperience !== '') {
                    //     let newClaimExperience = claimExperience;
                    //     switch (claimExperience) {
                    //         case "0":
                    //             newClaimExperience = "0";
                    //             break;
                    //         case "1 at fault":
                    //             newClaimExperience = "1_AT_FAULT";
                    //             break;
                    //         case "1 not at fault":
                    //             newClaimExperience = "1_NOT_AT_FAULT";
                    //             break;
                    //         case "2 (1 at fault and 1 not at fault)":
                    //             newClaimExperience = "2_ONE_AT_FAULT_ONE_NOT_AT_FAULT";
                    //             break;
                    //         case "2 at fault":
                    //             newClaimExperience = "2_AT_FAULT";
                    //             break;
                    //         case "2 not at fault":
                    //             newClaimExperience = "2_NOT_AT_FAULT";
                    //             break;
                    //         case "3 (1 at fault, 2 not at fault)":
                    //             newClaimExperience = "3_ONE_AT_FAULT_TWO_NOT_AT_FAULT";
                    //             break;
                    //         case "3 (2 at fault, 1 not at fault)":
                    //             newClaimExperience = "3_TWO_AT_FAULT_ONE_NOT_AT_FAULT";
                    //             break;
                    //         case "3 at fault":
                    //             newClaimExperience = "3_AT_FAULT";
                    //             break;
                    //         case "3 not at fault":
                    //         newClaimExperience = "3_NOT_AT_FAULT";
                    //         break;
                    //         case "more than 3":
                    //             newClaimExperience = "MORE_THAN_3";
                    //             break;
    
                    //                 default: break;
                    //     }
                    //     obj.ClaimExperience = newClaimExperience;
                    // }

                    requestParam = JSON.stringify(obj);
                    $http.post(config.api + '/ExternalServices/Propensity/GetPriority', requestParam, confighead)
                    .success(function (data, status) {
                        if (callback !== null) {
                            callback.onSuccess(data, status);                       
                        }
                    })
                    .error(function (err, status) {
                        if (callback !== null) {
                            callback.onError(err, status);
                        }
                    });
                } catch (err) {
                    callback.onError(err, 500);
                }
            },
            sendGoogleConversionAdjustment: function(callback, payload){
                const request = {
                    host: new URL(awsEndpoint).hostname,
                    path: new URL(awsEndpoint).pathname,
                    region: region,
                    service: service,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                };

                aws4.sign(request, credentials);

                $http({
                    method: request.method,
                    url: awsEndpoint,
                    headers: request.headers,
                    data: JSON.parse(request.body)
                })
                    .then(function (response) {
                        if (callback !== null) {
                            callback.onSuccess(response);                       
                        }
                    })
                    .catch(function (error) {
                        if (callback !== null) {
                            callback.onError(error);
                        }
                    });
            }
        };

        this.relaySend = fn.relaySend;
        this.getPriority = fn.getPriority;
        this.sendGoogleConversionAdjustment = fn.sendGoogleConversionAdjustment;
    }];
