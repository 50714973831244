/*
 * Created by Chanon Tangputthajit 2021/04/26
 */
module.exports = [
    '$scope',
    '$state',
    '$anchorScroll',
    '$filter',
    'DaModal',
    'WfeApiStaticData',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'WfeApiCoverages',
    'WfeApiAddress',
    'WfeApiContact',
    'WfeApiEssential',
    'WfeApiConfirmation',
    'StaticDataKeyValuePair',
    '$stateParams',
    '$window',
    'HKFormValidation',
    '$sessionStorage',
    '$timeout',
    '$http',
    'ExternalService',
    'ApiServiceV2',
    'Utils',
    'AppConstants',
    /**
     * Controller for the Confirmation of Motor Quote
     * @constructor
     */
        function B2CConfirmController($scope, 
                                        $state, 
                                        $anchorScroll, 
                                        $filter, 
                                        DaModal,
                                        WfeApiStaticData,
                                        WfeApiCurrentProposal, 
                                        WfeApiAsset, 
                                        WfeApiPolicyContact, 
                                        WfeApiQuote,
                                        WfeApiCoverages, 
                                        WfeApiAddress, 
                                        WfeApiContact, 
                                        WfeApiEssential,
                                        WfeApiConfirmation, 
                                        StaticDataKeyValuePair, 
                                        $stateParams, 
                                        $window, 
                                        HKFormValidation, 
                                        $sessionStorage, 
                                        $timeout,
                                        $http,
                                        ExternalService,
                                        ApiServiceV2,
                                        Utils,
                                        AppConstants) {

        $anchorScroll();

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'category': 'B2CPortal',
                    'virtualPageURL':'/b2c/buy/step-6/'}
                );
            },
            gaEvents:function(v){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                var p='';

                switch(v) {
                    case 1: p='Add Named Driver'; break;
                    case 2: p='Edit'; break;
                    default: break;
                }

                dataLayer.push({
                    'event': p,
                    'category': 'Portal',
                    'action': 'Navigation',
                    'label': p
                });
            }
        };

        var fn = {

            showCoverageDetails: function () {
                DaModal.open('#popupCoverageDetails');
            },

            postToMaxParent:function(callBackStatus,Priority,FinalCall){

                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;

                // if P_number (prefix number) not to be P24/P25
                // remove prefix_number out and then return to origin "firstName" only
                var checkFirstNameBeforeOverWriteToMAXAR;
                let checkLastNameBeforeOverWriteToMAXAR = '';
                for(var loopCounter = 0; loopCounter < maxarLayer.length; loopCounter++) {
                    if((maxarLayer[loopCounter].priority === "24")||(maxarLayer[loopCounter].priority === "25")){
                        checkFirstNameBeforeOverWriteToMAXAR = maxarLayer[loopCounter].firstName.substring(3);
                    }
                    else{
                        checkFirstNameBeforeOverWriteToMAXAR = maxarLayer[loopCounter].firstName;
                    }
                    checkLastNameBeforeOverWriteToMAXAR = maxarLayer[loopCounter].lastName;
                }

                let provinceId = $scope.phAddress.ProvinceId;
                let province = "";
                let getProvince = $scope.staticData.provinces.find(w => w.key == provinceId);
                if(getProvince){
                    province = getProvince.value;
                }

                maxarLayer.push({
                    'totalPremium':$scope.currentProposal.TotalPremiumToColl,
                    'message':$scope.callbackObj.message,
                    'commercial':$scope.callbackObj.commercial,
                    'type':'Quote lead',
                    'sourcePage':'Review',
                    'callback':callBackStatus,
                    'priority':Priority,
                    'dataFinal':FinalCall,
                    'referenceID':$scope.phContact.PrimaryPhoneNumber,
                    'telephone':$scope.phContact.PrimaryPhoneNumber,
                    'firstName':checkFirstNameBeforeOverWriteToMAXAR,
                    'province': province,
                    'lastName': checkLastNameBeforeOverWriteToMAXAR == '-' ? $scope.policyHolder.LastName : checkLastNameBeforeOverWriteToMAXAR
                });

                if($scope.$parent !== null){
                    $scope.$parent.postMaxar();
                }
            },

            setTA: function(){
                if(window.location.hostname !== "secure.directasia.co.th"){
                    $scope.termAgreement = true;
                }
            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;
                var wfeApiStaticDataPaymentPlan = fn.wfeApiStaticDataPaymentPlan;

                var startRetrieval = function () {
                    getMakeList();
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            $scope.staticData.makes = data;
                            getDistrictList();
                        },

                        onError: function () {
                            getDistrictList();
                        }
                    });
                };

                var getDistrictList = function () {
                    wfeApiStaticData.getDistrictList({
                        onSuccess: function (data) {
                            $scope.staticData.districts = data;
                            getProvinceList();
                        },

                        onError: function () {
                            getProvinceList();
                        }
                    });
                };

                var getProvinceList = function () {
                    wfeApiStaticData.getProvinceList({
                        onSuccess: function (data) {
                            $scope.staticData.provinces = data;
                            getContactRelationshipTypeList();
                        },

                        onError: function () {
                            getContactRelationshipTypeList();
                        }
                    });
                };

                var getContactRelationshipTypeList = function () {
                    wfeApiStaticData.getContactRelationshipTypeList({
                        onSuccess: function (data) {
                            $scope.staticData.relationships = data;
                            getBooleanListWithSometimes();
                        },

                        onError: function () {
                            getBooleanListWithSometimes();
                        }
                    });
                };

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getPaymentTermList();
                        },

                        onError: function () {
                            getPaymentTermList();
                        }
                    });
                };

                var getPaymentTermList = function () {
                    wfeApiStaticDataPaymentPlan.getPaymentTermList({
                        onSuccess: function (data) {
                            $scope.staticData.paymentTerms = data;
                            getInsurerList();
                        },

                        onError: function () {
                            getInsurerList();
                        }
                    });
                };

                var getInsurerList = function () {
                    wfeApiStaticData.getInsurerList({
                        onSuccess: function (data) {
                            $scope.staticData.otherInsurances = data;
                            getNoClaimDiscountList();
                        },

                        onError: function () {
                            getNoClaimDiscountList();
                        }
                    });
                };

                var getNoClaimDiscountList = function () {
                    wfeApiStaticData.getNoClaimDiscountList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data;
                            getOccupationList();
                        },

                        onError: function () {
                            getOccupationList();
                        }
                    });
                };

                var getOccupationList = function () {
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getGenderList();
                        },

                        onError: function () {
                            getGenderList();
                        }
                    });
                };

                var getGenderList = function () {
                    wfeApiStaticData.getGenderList({
                        onSuccess: function (data) {
                            $scope.staticData.genders = data;
                            getMaritalStatusList();
                        },

                        onError: function () {
                            getMaritalStatusList();
                        }
                    });
                };

                var getMaritalStatusList = function () {
                    wfeApiStaticData.getMaritalStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getNoOfClaimList();
                        },

                        onError: function () {
                            getNoOfClaimList();
                        }
                    });
                };
                var getNoOfClaimList = function () {
                    wfeApiStaticData.getNoOfClaimList({
                        onSuccess: function (data) {
                            $scope.staticData.nrOfClaimsInPeriods = data;
                            getDriverExperienceList();
                        },

                        onError: function () {
                            getDriverExperienceList();
                        }
                    });
                };
                var getDriverExperienceList = function () {
                    wfeApiStaticData.getDriverExperienceList({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                            getResidentialStatusList();
                        },

                        onError: function () {
                            getResidentialStatusList();
                        }
                    });
                };
                var getResidentialStatusList = function () {
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentStatuses = data;
                            getVehicleMileageList();
                        },
                        OnError: function () {
                            getVehicleMileageList();
                        }
                    });
                };
                var getVehicleMileageList = function () {
                    wfeApiStaticData.getVehicleMileageList({
                        onSuccess: function (data) {
                            $scope.staticData.mileages = data;
                            getVehicleUsageList();
                        },

                        onError: function () {
                            getVehicleUsageList();
                        }
                    });
                };

        
                var getVehicleUsageList = function () {
                    wfeApiStaticData.getVehicleUsageList({
                        onSuccess: function (data) {
                            $scope.staticData.usages = data;
                            //getBooleanListWithSometimes();
                        }//,
                        //
                        //onError: function () {
                        //    getBooleanListWithSometimes();
                        //}
                    });
                };
                //getVehicleUsageList
                startRetrieval();

            },

            initSubscribeWatcher: function(){

                $scope.$watch(function () { return $(window).scrollTop() }, function (scroll) {

                    var setInitialStaticMarginTop = 15
                    $scope.currentPos = scroll
                    var stickyHeight = $('#rightPanel').height()
                    var stickyTopPosition = $('#rightPanel').offset().top
                    var stickyBottomPosition = $('#rightPanel').offset().top + (stickyHeight-18)
                    var leftPanelBottomPositionReview = $(".MainBlockOfReview").offset().top + $(".MainBlockOfReview").height()

                    if (scroll == 0) {
                        $('#rightPanel').css({ "z-index":"1000", "top": 170 })
                    } 
                    else if (scroll > 0 && scroll < 170) {

                        var recheckSpacingOnTopSticky = 170-scroll

                        if(recheckSpacingOnTopSticky >= setInitialStaticMarginTop){
                            $('#rightPanel').css({ "z-index": "1000", "top": recheckSpacingOnTopSticky })
                        }
                        else{
                            $('#rightPanel').css({ "z-index": "1000", "top": setInitialStaticMarginTop })
                        }
                    } 
                    else if (scroll >= 170 && scroll <= 1000) {
                        $('#rightPanel').css({ "z-index": "1000", "top": 15 })
                    } 
                    else if (scroll > 1000) {

                        // Forth page
                        if(stickyBottomPosition < leftPanelBottomPositionReview){

                            var getDiffResult = leftPanelBottomPositionReview - stickyBottomPosition                                    
                            var setNewTopPositionSticky = stickyTopPosition + getDiffResult
                            var recheckSpacingPointWhenScrollUp = setNewTopPositionSticky - setInitialStaticMarginTop
                            
                            // "Scroll-Up" is eual with "Top position Sticky"                                    
                            if(scroll < recheckSpacingPointWhenScrollUp){                                      
                                $('#rightPanel').css({"z-index":"1000","top": setInitialStaticMarginTop })
                            }

                            // "Scroll-Up" is not eual with "Top position Sticky"
                            else{
                                $('#rightPanel').offset({ top : setNewTopPositionSticky })
                            }
                    
                        }
                        if(stickyBottomPosition == leftPanelBottomPositionReview){
                            /* nothing to do */                                    
                        }
                        if(stickyBottomPosition > leftPanelBottomPositionReview){
                            var minusDistance = stickyBottomPosition - leftPanelBottomPositionReview                                    
                            var newCalculate = stickyTopPosition - minusDistance
                            $('#rightPanel').offset({top:newCalculate})
                        }
                    }
                })

            },

            initScopeObjects: function () {

                // to set language on HTML DOM for "SaleForce (Case 4)"
                if($scope.HKLocale.getLocale() == "en-us"){
                    document.documentElement.lang = 'en-US'
                }
                else{
                    document.documentElement.lang = 'th-TH'
                }

                $scope.$parent.isUserProceedOnBuy();
                $scope.isBtnSaveHide = true;
                $scope.isHide = false;
                $scope.loadingSpinner2 = false;
                $scope.staticData = {};
                $scope.thirdPageShow = false;
                $scope.currentProposal = {};
                $scope.asset = {};
                $scope.policyContact = {};
                $scope.policyHolder = {};
                $scope.mainDriver = {};
                $scope.namedDrivers = {
                    PolicyContacts: []
                };

                //$scope.hasError = true; // For edit issue policy unsuccess
                $scope.forthPageShow = true;
                $scope.phAddress = {};
                $scope.phContact = {};
                $scope.OTP = {};
                $scope.OTP.phoneNumber = "";
                $scope.OTP.code = "" ;
                $scope.coverages = {};
                $scope.selectedCoverage = {};

                $scope.buyControllerStaticData = {
                    allPaymentTerms: [],
                    products: []
                };

                $scope.PHN_MDEditVisible=false;
                $scope.PH_MDEditVisible=false;
                $scope.MDEditVisible=false;
                $scope.CarEditVisible=false;
                $scope.callbackObj={};
                $scope.sumInsured={};
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;
                $scope.IsReEmailMismatch = false;

                $scope.basicCoverageInfo = {
                    "2000015":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Choice of deductible, sum insured is xxx,xxx",
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Deductible of xxx sum insured is xxx,xxx",
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Deductible of xxx sum insured is xxx,xxx",
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                            //"Deductible of x,xxx. Sum insured is xxx,xxx",
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                            //"Deductible of x,xxx. Sum insured is xxx,xxx",
                        ]};
                        
                $scope.basicCoverageInfo_Part2 = {
                    "2000015":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000019":
                        [
                            //"Deductible of xxx sum insured is xxx,xxx",
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_payment'
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_payment'
                        ]};

                $scope.termAgreement = false; // by pass OTP
                $scope.showSaveAndExitOnBuyPage = true;
                $scope.proceedBuy=true;
                $scope.isBtnBackHide = true;  // Hide back button
                $sessionStorage.currentPage = 4;
                $scope.showConfirmBuyPageB2C = true;
                $scope.showConfirmBuyPageOnlineSelling = false;

            },

            initApiObjects: function () {

                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);

                $scope.asset.ProductId = $scope.$parent.product.ProductId;
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.policyContactApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyContact);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.namedDriversApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.namedDrivers);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);
                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), 1941);

                fn.wfeApiStaticData = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataPaymentPlan = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), 4);

                $scope.buyControllerStaticData.products = StaticDataKeyValuePair.getProducts();
                $scope.buyControllerStaticData.allPaymentTerms = StaticDataKeyValuePair.getAllPaymentTerms();
            },

            retrieveScopeObjects: function () {

                fn.currentProposalApi.retrieveCurrentProposal({
                    onSuccess: function (data) {
                      angular.extend($scope.currentProposal, data);
              
                      $scope.easyRenewProposal = false;
                    },
                    onError: function () {
                      /* nothing to do */
                    },
                  });

                fn.assetApi.retrieveAsset({
                    onSuccess: function (data) {

                        angular.extend($scope.asset, data);

                        if ($scope.asset.ManufactureId) {
                            fn.retrieveModelStaticData();
                        }

                        //Set PlanId for parent
                        $scope.$parent.PlanId = $scope.asset.PlanId;
                    }
                });

                fn.coverageApi.retrieveCoverages({
                        onSuccess: function (data) {

                            angular.extend($scope.coverages, data);
                            var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true})[0];
                            $scope.loadingSpinner3 = false;
                            var topItem = [];
                            var lowItem = [];

                            if (selectedCoverage) {
                                for(var i in selectedCoverage.Covers){
                                    if(selectedCoverage.Covers[i]['ProductLineGroupId'] == '2000106' || selectedCoverage.Covers[i]['ProductLineGroupId'] == '2000105'){
                                        topItem.push(selectedCoverage.Covers[i]);
                                    }
                                    else{
                                        lowItem.push(selectedCoverage.Covers[i]);
                                    }
                                }

                                selectedCoverage.Covers = topItem;
                                for(var j in lowItem) {
                                    topItem.push(lowItem[j]);
                                }
                                //fn.eventHandlers.onBtnSelectClicked(selectedCoverage);
                                angular.extend($scope.selectedCoverage, selectedCoverage);
                                retrieveExcess();
                                //fn.utils.sortOptionalCovers();
                                retrievePhAddress();

                                var excessCover = Utils.getCoverVoluntaryBasicByProductId(selectedCoverage);
                                $scope.excessLevelId = excessCover && excessCover.length > 0 ? excessCover[0].ExcessLevelId : -1;

                                var cc = selectedCoverage;
                                if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG){
                                    $scope.currentCoverage = "1";
                                }
                                else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE1SAVE_FALCON_DG){
                                    $scope.currentCoverage = "1s";
                                }
                                else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE2_FALCON_DG){
                                    $scope.currentCoverage = "2";
                                }
                                else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG){
                                    $scope.currentCoverage = "2p";
                                }
                                else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE3_FALCON_DG){
                                    $scope.currentCoverage = "3";
                                }
                                else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG){
                                    $scope.currentCoverage = "3p";
                                }
                            }
                        },
                        onError: function () {
                            retrievePhAddress();
                        }
                    }
                );
                var retrievePhAddress = function () {
                    fn.phAddressApi.retrievePHAddress(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phAddress, data);
                                if($scope.phAddress){
                                    var wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), {"postalCode":$scope.phAddress.PostalCode, "provinceId": $scope.phAddress.ProvinceId});
                                    wfeApiStaticData.getDistrictListByPostal({
                                        onSuccess: function (data2) {
                                            $scope.staticData.districtByPostal = data2;
                                        }
                                    });
                                }

                                retrievePhContact();
                            },
                            onError: function () {
                                retrievePhContact();
                            }
                        }
                    );
                }

                var retrievePhContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                fn.setSumInsured();
                                $scope.OTP.phoneNumber = $scope.phContact.PrimaryPhoneNumber;
                            },
                            onError: fn.setSumInsured
                        }
                    );
                }

                var retrieveExcess =function(){
                    var hasError = false;

                    ApiServiceV2.getVehicleExcess({
                        onSuccess: function (data) {
                            if (data && data.length > 0) {
                                data.sort((n1,n2) => {
                                    if (n1.amount > n2.amount) {
                                        return 1;
                                    }
                                
                                    if (n1.amount < n2.amount) {
                                        return -1;
                                    }
                                
                                    return 0;
                                });
    
                                data.map((dt) =>{
                                    dt.key = dt.level.toString();
                                    dt.value = Number(dt.amount) + '.00';
                                })
                                if (data[0] && data[0].value) {
                                    $scope.allExcess = data[0].value;
                                    $scope.selectedCoverage.excess = data;                
                                }
                            }
                        },
                        onError: function (err) {

                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveExcess', method: 'ApiServiceV2.getVehicleExcess', line: 3787} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                        }
                    }, 'VEHICLE_EXCESS', `formulaId=${$scope.selectedCoverage.CoverTypeId}&productVersionId=${$scope.selectedCoverage.ProductVersionId}`);

                    // fn.coverageApi.getVehicleExcess(
                    //     {
                    //         onSuccess: function (data) {
                    //             $scope.selectedCoverage.excess = data;
                    //         },
                    //         onError: function(){
                    //         }
                    //     }
                    // );
                };
                //PolicyContact Retrieval
                //this will retrieve MD if PH is not MD
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {

                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data,data);
                                }
                                angular.extend($scope.policyHolder, data);
                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                }
                                else {
                                    retrieveMainDriver();
                                }
                            }
                        }
                    );
                };

                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact({
                        onSuccess: function (data) {
                            if (data && data.Identifier) {
                                HKFormValidation.splitIdentifier(data,data)
                            }
                            angular.extend($scope.mainDriver, data)
                        }
                    })
                }

                //updated CurrentProposal
                var updatedCurrentProposal = function () {
            
                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                    $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;

                    fn.currentProposalApi[saveCurrentProposalFunc]({
                            onSuccess: function () {                               
                            },
                            onError: function () {
                                $scope.hasError = true;
                            }
                        }
                    );
                };
                             
                retrievePolicyHolder();
                fn.retrieveNamedDrivers();
            },

            retrieveNamedDrivers: function () {

                fn.namedDriversApi.retrieveNDPolicyContacts(
                    {
                        onSuccess: function (dataArray) {

                            if (!dataArray) {
                                return;
                            }

                            angular.forEach(dataArray,
                                function (data) {
                                    if (data && data.Identifier) {
                                        HKFormValidation.splitIdentifier(data,data);
                                        //data.PreIdentifier = data.Identifier.substr(0, 1) || '';
                                        //data.IdentifierNumber = data.Identifier.substr(1, 6) || '';
                                        //data.PostIdentifier = data.Identifier.substr(7, 1) || '';
                                    }
                                    //data.isForUpdate = true;
                                }
                            );

                            $scope.namedDrivers.PolicyContacts.splice(0);
                            angular.extend($scope.namedDrivers.PolicyContacts, dataArray);
                            $scope.isEditing = true;
                            if ($scope.namedDrivers.PolicyContacts.length) {
                                $scope.isEditing = false;
                            }

                        }
                    }
                );
            },

            retrieveModelStaticData: function () {

                var objParams = {};
                objParams.manufacturerId = $scope.asset.ManufactureId;
                objParams.purchasingYear = $scope.asset.PurchasingYear;

                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                wfeApiStaticDataModel.getModelListWithYear({
                    onSuccess: function (data) {
                        $scope.staticData.models = data;
                        fn.retrieveDescStaticData();
                        if(data.length==0) {
                            DaModal.alert('The Make and Model you choose is not available. Call us at 02-767-7777 to talk with an agent.');
                        }
                    }
                });
            },

            retrieveDescStaticData: function () {

                var objParams = {};
                objParams.modelId = $scope.asset.ModelId;
                objParams.purchasingYear = $scope.asset.PurchasingYear;
                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                wfeApiStaticDataModel.getCarDescWithYear({
                    onSuccess: function (data) {
                        for(var i in data)
                            if(data[i]['key'] == $scope.asset.CarDescriptionId)
                                $scope.staticData.cardescriptions = data[i];
                        var txt2door = "";
                        filteredCarDescription = $filter('filter')(data, function (r) {
                            if($scope.asset.CarDescriptionId === r.key){
                                txt2door = r.description.toLowerCase();
                                if(txt2door.includes("2door")){
                                    $scope.bodyType = "2door";
                                }
                                
                            }
                        });
                        
                        fn.retrieveBodyStaticData();
                    }
                });

            },

            retrieveBodyStaticData: function () {
                var objParams = {};
                objParams.modelId = $scope.asset.ModelId;
                objParams.purchasingYear = $scope.asset.PurchasingYear;
                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                wfeApiStaticDataModel.getBodyTypes({
                    onSuccess: function (data) {
                        $scope.staticData.bodytypes = data;
                        fn.setSumInsured();
                    },
                    onError: function () {
                        fn.setSumInsured();
                    }
                });
            },

            setSumInsured:function()
            {
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;

                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE3_FALCON_DG)
                {
                    //Type 3 is always NA and hidden
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                    return;
                }

                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG || $scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG ||
                    $scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG || $scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE1SAVE_FALCON_DG
                ){

                    // "Type 2+/ 3+ / 1 / 1 save" - value from own damage collision
                    // "Type 1" - own damage collision value.

                    var odc = $filter('filter')($scope.selectedCoverage.Covers, { ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA },true);

                    if(odc && odc[0].InsuranceAmount){
                        $scope.sumInsured.value=odc[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }

                    return;
                }

                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2_FALCON_DG)
                {

                    //Type 2- own damage Fire and theft value
                    var odft=$filter('filter')($scope.selectedCoverage.Covers, {
                        ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA
                    },true);
                    if(odft && odft[0].InsuranceAmount){

                        $scope.sumInsured.value=odft[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }
                    return;
                }

                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG) {
                    //Type Compulsory only
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;
                    return;
                }
                //Exception- Everything fails- hide
                $scope.sumInsured.visible=false;
                $scope.sumInsured.value=0;

            },

            utils: {
                sortOptionalCovers: function () {
                    var covers =  $scope.selectedCoverage.Covers;
                    var windscreenCoverIndex = -1, lossCoverIndex = -1;

                    for (var i = 0; i<covers.length; i++){
                        var cover = covers[i];
                        if(cover.ProductLineGroupId === 2000056){
                            windscreenCoverIndex = i;
                        }
                        else if(cover.ProductLineGroupId===1000093){
                            lossCoverIndex = i;
                        }
                    }

                    if(windscreenCoverIndex >= 0 && lossCoverIndex >= 0 && lossCoverIndex+1 !== windscreenCoverIndex){
                        var windscreenCover = covers[windscreenCoverIndex];
                        covers.splice(windscreenCoverIndex,1);
                        covers.splice(lossCoverIndex,0,windscreenCover);
                    }
                    $scope.selectedCoverage.Covers = covers;
                },

                addExtraComprehensiveCoverforDisplay: function () {
                    var coverageCovers = $scope.coverages.Coverages;
                    var comprehensiveCoverlist = {};
                    var FinalComprehensiveCoverList = [];

                    for (var i = 0; i < coverageCovers.length; i++) {

                        // only when it is comprehensive
                        if(coverageCovers[i].CoverTypeId === 1) {
                            comprehensiveCoverlist = coverageCovers[i].Covers;

                            for (var k = 0; k < comprehensiveCoverlist.length; k++) {

                                var cover = comprehensiveCoverlist[k];

                                // before Damage to own car (No other vehicle involved)
                                if(cover.ProductLineGroupId === 1000102) {
                                    var ownDamageCollisionCover = {
                                        "CoverName": "Collision with third party vehicle - Comprehensive[Added]",
                                        "IsSelected": true,
                                        "ProductLineGroupId": 1000103,
                                        "ProductLineTypeString":"Mandatory",
                                        "ProductLineOptionId":2000809,"ProductLineType":0};
                                    FinalComprehensiveCoverList.push(ownDamageCollisionCover);
                                }
                                FinalComprehensiveCoverList.push(cover);
                            }

                            $scope.coverages.Coverages[i].Covers = FinalComprehensiveCoverList;
                        }
                    }
                },

                isRenewalProposalLanding: function () {
                    //Except renewal Motor car, remaining all products hide buttons only visible back button
                    if($scope.asset.ProductId !== 1000009 && ($stateParams.isRenewalLanding === 'true')) {
                        $scope.isHide = true;
                    }
                    else {
                        $scope.isHide = false;
                    }
                    return fn.utils.isRenewalProposal && ($stateParams.isRenewalLanding === 'true');
                },
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },
                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                    $scope.DaLoadingMask.toggle(isSaving);
                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    $scope.policyHolder.OccupationId = $scope.mainDriver.OccupationId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.IsDemeritPoints = $scope.mainDriver.IsDemeritPoints;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;
                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;
                },

                filterBaseCover: function (r) {
                    var status = false;
                    if (r.ProductLineGroupId === 1000087) {
                        status = true;
                    }
                    return status;
                },
                

                filterMandatoryCovers: function (r) {

                    var status = false;
                    if (r.ProductLineType === '0' || r.ProductLineType === 0) {

                        if (r.CoverName === 'Basic Cover' || r.ProductLineGroupId === 1000087) {
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000108) { //policyExcess
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000109) {//Administration Fee
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000059) {//Recovery Expenses - TPO
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000060) {//Legal Expenses - TPO
                            status = false;
                        }
                        else {
                            status = true;
                        }
                    } 
                    else {
                        status = false;
                    }
                    return status;
                },
                getInsuranceAmount: function (groupid) {
                    if ($scope.selectedCoverage) {
                        var ins = $filter('filter')($scope.selectedCoverage.Covers, { ProductLineGroupId: groupid });
                        if (ins) {
                            if (ins.length > 0 && ins[0].InsuranceAmount) {
                                return ins[0].InsuranceAmount;
                            } 
                            else {
                                return 0;
                            }
                        }
                    } 
                    else {
                        return 0;
                    }
                },
                getIncludeCompulsory: function () {
                    if ($scope.selectedCoverage) {
                        var compulsory = Utils.getCoverComplusoryBasicCoverByProductId($scope.selectedCoverage);
                        if(compulsory && compulsory.length > 0) {
                            return compulsory[0].IsSelected;
                        }
                    } 
                    else {
                        return false;
                    }
                },

                getExcessValueSummary: function () {

                    if ($scope.selectedCoverage) {
                        var KeyValuePairString = $scope.selectedCoverage.excess;
                    } 
                    else {
                        return 0;
                    }

                    if ($scope.selectedCoverage && KeyValuePairString !== undefined) {
                        var excessId = Utils.getCoverVoluntaryBasicByProductId($scope.selectedCoverage);
                        if (excessId && excessId.length > 0) {
                            excessId = excessId[0]
                            if(excessId && excessId.ExcessLevelId) {
                                 // var excessAmount = $filter('filter')(KeyValuePairString, { 'key': excessId[0].ExcessLevelId })[0].value; //Old
                                var excessAmount = KeyValuePairString.filter(w => Number(w.level) == excessId.ExcessLevelId)
                                if (excessAmount && excessAmount.length > 0) {
                                    excessAmount = excessAmount[0]
                                    if(excessAmount && excessAmount.value) {
                                        return Number(excessAmount.value);
                                    } else {
                                        return 0;
                                    }
                                } else {
                                    return 0;
                                }
                            } else {
                                return 0;
                            }

                        } else {
                            var excessAmt = Utils.getCoverVoluntaryBasicByProductId($scope.selectedCoverage);
                            if (excessAmt && excessAmt.length > 0) {
                                excessAmt = excessAmt[0]
                                if(excessAmt && excessAmt.ExcessAmount) {
                                    return excessAmt.ExcessAmount;
                                } else {
                                    return 0;
                                }
                            } else {
                                return 0;
                            }

                        }

                    } 
                    else {
                        return 0;
                    }
                },
                paymentScheduleToShow: function (c) {
                    let amount = 0.00;
                    let amountIns  = 0.00;

                    if(c) {
                        let totalPremiumToColl =  fn.utils.getComplusoryBasicCoverPremium(c.Covers,c.ProductId);
                        let isSelected = fn.utils.getComplusoryBasicCoverPremiumIsSelected(c.Covers,c.ProductId);;
                    //   if($scope.currentProposal.PaymentTermId != 1000001) {
                    //     amountIns = c.PaymentSchedule[0].Amount;
                    //   }
                      if(c.PaymentSchedule && c.PaymentSchedule.length > 0) {
                        amountIns = c.PaymentSchedule[0].Amount;
                      }
                      if(isSelected) {
                        amount = isSelected ? amountIns : amountIns + totalPremiumToColl;
                      } else {
                        amount = isSelected ? amountIns - totalPremiumToColl : amountIns;
                      }
                  
                      if(c.PaymentSchedule && c.PaymentSchedule.length > 0) {
                        $scope.getFirstPaymentToString = Math.round((amount + Number.EPSILON) * 100) / 100;
                        $scope.gettMonthlyPremiumToString =  c.PaymentSchedule && c.PaymentSchedule.length > 1 ? c.PaymentSchedule[1].Amount : 0.00
                      }  
                
                    }
                },
                getPriceToShow: function (c) {
                    // console.log(`compulsoryOriginal = c.compulsoryOriginal.checked : ${c.compulsoryOriginal.checked}`);
                    if(c.length == 0) {
                        return 0.00;
                    }
                    if(c) {
                        var totalPremium = c.CalculatedPremium;
                        var amountIns = c.CalculatedPremium;    
                     
                        let totalPremiumToColl =  fn.utils.getComplusoryBasicCoverPremium(c.Covers,c.ProductId);
                        let isSelected = fn.utils.getComplusoryBasicCoverPremiumIsSelected(c.Covers,c.ProductId);;
                        // if($scope.currentProposal.PaymentTermId != 1000001) {
                        //     amountIns = c.PaymentSchedule[0].Amount;
                        // }
                        if($scope.currentProposal.PaymentTermId != 1000001) {
                            if(isSelected) {
                                totalPremium = isSelected ? amountIns : amountIns + totalPremiumToColl;
                            } else {
                                totalPremium = isSelected ? amountIns - totalPremiumToColl :  amountIns;
                            }
                        } else {
                            if(isSelected) {
                                totalPremium = isSelected ? c.CalculatedPremium : c.CalculatedPremium + totalPremiumToColl;
                            } else {
                                totalPremium = isSelected ? c.CalculatedPremium - totalPremiumToColl : c.CalculatedPremium;
                            }

                        }
    
                        return totalPremium;
                    }

                },
                getComplusoryBasicCoverPremium: function (covers,productId) {
                    let totalPremiumToColl = 0.00;
                    if(covers) {
                      if(productId == $scope.PRODUCT_ID_MTI) {
                        let totalPremiumToCollValue = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI);
                        if(totalPremiumToCollValue) {
                          totalPremiumToColl = totalPremiumToCollValue.TotalPremiumToColl;
                        }
                      } else if(productId == $scope.PRODUCT_ID_AXA) {
                        let totalPremiumToCollValue = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA);
                        if(totalPremiumToCollValue) {
                          totalPremiumToColl = totalPremiumToCollValue.TotalPremiumToColl;
                        }
                      } else {
                        let totalPremiumToCollValue = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA);
                        if(totalPremiumToCollValue) {
                          totalPremiumToColl = totalPremiumToCollValue.TotalPremiumToColl;
                        }  
                      }
                    }
                    return totalPremiumToColl;                
                },
                getComplusoryBasicCoverPremiumIsSelected: function (covers,productId) {
                    let isSelected = true;
                    if(covers) {
                      if(productId == $scope.PRODUCT_ID_MTI) {
                        let cover = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI);
                        if(cover) {
                            isSelected = cover.IsSelected;
                        }
                      } else if(productId == $scope.PRODUCT_ID_AXA) {
                        let cover = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA);
                        if(cover) {
                            isSelected = cover.IsSelected;
                        }
                      } else {
                        let cover = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA);
                        if(cover) {
                            isSelected = cover.IsSelected;
                        }  
                      }
                    }
                    return isSelected;                
                },

                //Old
                // getPriceToShow: function (c) {

                //     if (!c) { return 0; }

                //     var cx = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: c.CoverTypeId, PlanId: $scope.asset.PlanId })[0];

                //     if (cx) {
                //         var baseprice = cx.CalculatedPremium;
                //     } 
                //     else {
                //         return 0;
                //     }
    
                //     var ccb = $filter('filter')(cx.Covers, { ProductLineGroupId: 2000161, IsSelected: true }, true);
                //     if (cx.CoverTypeId !== 2000020 && ccb.length === 0) {
                //         return baseprice;
                //     }
                //     if (ccb) {
                //         if (ccb[0]) {
                //             if (ccb[0].TotalPremiumToColl) {
                //                 total = baseprice + ccb[0].TotalPremiumToColl;
                //             }
                //         }
                //     }
                //     return total;
                // },

            },

            navigation: {

                goToNextStep: function () {

                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(6);
                    
                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {IsSelected : true})[0];

                    var goNext = function(){
                        fn.utils.setIsSaving(false);
                        try {
                            marketing.ga();
                        } 
                        catch (err) {
                            /* nothing to do */
                        }
                        //$state.go('publicB2C.motor.quote2.steps.buy');
                        let ExternalProposalNumberArr = $scope.currentProposal.ExternalProposalNumber.split('/');
                        if (ExternalProposalNumberArr[0] === 'MT') {
                            $state.go('publicB2C.motor.quote2.steps.buy');
                            // $state.go('publicB2C.motor.mtisuccess');
                        } else {
                            $state.go('publicB2C.motor.quote2.steps.mtisuccess');
                        }
                    }

                    var defaultPriorityJudge = function () {
                        if (selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG) {
                            fn.postToMaxParent('No', 4,'N')
                            goNext();
                        } 
                        else {
                            fn.postToMaxParent('No', 1,'N')
                            goNext();
                        }
                    }
                    defaultPriorityJudge();
                },

                goToPrevStep: function () {
                    fn.utils.setIsSaving(false);
                    $state.go('public.motor.quote.steps.quote');
                }

            },
            saving: {
                savePHContactCallback: function () {

                    var callMaxar= function(){

                        DaModal.close('#quotationCallback');

                        try{
                            $scope.$parent.gaEvents(5,3);
                        }
                        catch(e){ /* nothing to do */ }

                        //onsuccess
                        $scope.$parent.maxarCallbackTrigger = true;
                        fn.postToMaxParent('No',1,'Y');
                    };

                    //If Proposal
                    if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10){
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                fn.utils.setIsSaving(false);
                                callMaxar();
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        });
                    }
                    else {
                        fn.essentialApi.partialSave({
                            onSuccess: function () {
                                fn.utils.setIsSaving(false);
                                callMaxar();
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        });
                    }
                },

                savePHContact: function () {

                    //If Proposal
                    if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10){
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                fn.utils.setIsSaving(false);
                                DaModal.close('#quotationPartialSave');
                                $scope.$parent.divertAfterSECB();
                            },
                            onError: function(){
                                fn.utils.setIsSaving(false);
                            }
                        });
                    }

                    else {
                        fn.essentialApi.partialSave({
                            onSuccess: function () {
                                fn.utils.setIsSaving(false);
                                DaModal.close('#quotationPartialSave');
                                $scope.$parent.divertAfterSECB();
                            },
                            onError: function(){
                                fn.utils.setIsSaving(false);
                            }
                        });
                    }
                    fn.postToMaxParent('No',1,'Y')
                }
            },
            eventHandlers: {

                onBtnCallbackClicked :function(){
                 DaModal.open('#quotationCallback');
                },
                onBtnCallbackContinueClicked: function () {

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    $scope.pageSubmitted = true;

                    if ($scope.phContact.PrimaryEmailAddress.invalid 
                        || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }
                    fn.saving.savePHContactCallback();
                },
                onBtnCallbackCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        $scope.phContact.PrimaryPhoneNumber = "";
                    }
                    DaModal.close('#quotationCallback');
                },

                onBtnSaveAndExitClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,2);
                    }
                    catch(e){ /* nothing to do */ }
                    DaModal.open('#quotationPartialSave');
                },
                onBtnPartialSaveContinueClicked: function () {

                    if ($scope.phContact.PrimaryEmailAddress.invalid ) {
                        return;
                    }
                    fn.saving.savePHContact();
                },
                onBtnPartialSaveCancelClicked: function () {
                    DaModal.close('#quotationPartialSave');
                },
                onBtnBackToMyPolicy: function () {
                    $state.go('private.account.user.policies');
                },
                onBtnDeclineClicked: function () {
                    $state.go('public.motor.quote.steps.declinerenewal');
                },
                onBtnPrintClicked: function () {
                    // nothing to do
                },
                onBtnAmendClicked: function () {
                    $state.go('public.motor.quote.steps.basic');
                },

                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },

                onBtnToBuy: function () {
                    
                    try{ $scope.$parent.gaEvents(0,0); }
                    catch(e){ /* nothing to do */ }

                    var hasError = false;

                    let ExternalProposalNumberArr = $scope.currentProposal.ExternalProposalNumber.split('/');
                    if (ExternalProposalNumberArr[0] === 'MT') {
                        if(!$scope.termAgreement){
                            fn.eventHandlers.showOTPBox();
                            return;
                        }
                    }

                    fn.utils.setIsSaving(true);

                    var triggerSave = function () {
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                fn.utils.setIsSaving(false);
                                savingComplete();
                            },
                            onError: function(err){
                                DaModal.open("#daExceptionModal");
                                fn.utils.setIsSaving(false);
                            }
                        });
                    };

                    var savingComplete = function () {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }
                        fn.navigation.goToNextStep();
                    };

                    triggerSave();
                },

                openCallbackPopup: function () {
                    DaModal.open("#callback_popup")
                },
                closeCallbackPopup: function (customerPhoneNumber) {

                    var getCustomerPhoneNumber = customerPhoneNumber

                    // validate "limited telphone number: TRUE"
                    if(getCustomerPhoneNumber.length >= 10){

                        $scope.validatePhoneNumberChecker = true

                        var getJSONFromFirstObjMaxar = $sessionStorage.getFirstObjToMX
                        var getJSONFromSeconObjMaxar = $sessionStorage.getSecondObjToMX
                        var getAllJSONSentToMaxar = Object.assign(getJSONFromFirstObjMaxar,getJSONFromSeconObjMaxar)
                        
                        for(var loopCounter = 0; loopCounter < Object.keys(getAllJSONSentToMaxar).length; loopCounter++){
                            // change new blank2's value
                            if((Object.keys(getAllJSONSentToMaxar)[loopCounter]) === 'blank2'){
                                getAllJSONSentToMaxar.blank2 = "hot quote"
                            }
                            // change new language's value
                            if((Object.keys(getAllJSONSentToMaxar)[loopCounter]) === 'language'){
                                getAllJSONSentToMaxar.language = "th-hl"
                            }
                            // change new telephone's value
                            if((Object.keys(getAllJSONSentToMaxar)[loopCounter]) === 'telephone'){
                                getAllJSONSentToMaxar.telephone = getCustomerPhoneNumber
                            }
                        }

                        var payloadString = JSON.stringify(getAllJSONSentToMaxar)
        
                        // var confighead = {
                        //     headers: {
                        //         'Content-Type': '"application/json; charset=utf-8";',
                        //         'dataType': '"json"'
                        //     }
                        // }
                        
                        // post API requestion
                        // $http.post(config.api + '/ExternalServices/Relay/Send',payloadString,confighead)
                        ExternalService.relaySend(null, payloadString);                                                

                        DaModal.close("#callback_popup")
                        DaModal.open("#callback_thankyou")
                    }
                    
                    // validate "limited telphone number: FLASE"
                    else{
                        $scope.validatePhoneNumberChecker = false
                        return
                    }
                },                

                closeCallbackThankyou: function () {
                    DaModal.close("#callback_thankyou")
                },

                onBtnBackClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){ /* nothing to do */ }
                    fn.utils.setIsSaving(true);
                    fn.navigation.goToPrevStep();
                },

                onPolicyHolderEditClicked: function () {
                    $state.go('public.motor.quote.steps.quote');
                },

                onPolicyMainDriveEditClicked: function () {
                    $state.go('public.motor.quote.steps.quote');
                },

                onCarDetailEditClicked: function () {
                    $state.go('public.motor.quote.steps.basic');
                },

                onQuoteDetailsEditClicked: function () {
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){ /* nothing to do */ }
                    $state.go('public.motor.quote.steps.customise');
                },

                onAdditionalDriversEditClicked: function () {
                    try {
                        marketing.gaEvents(1);
                    }
                    catch(e){ /* nothing to do */ }
                    $state.go('public.motor.quote.steps.customise');
                },
                onGoToCoverCtrlClicked: function () {
                    $state.go('public.motor.quote.steps.cover');
                },
                onGoToBasicCtrlClicked: function () {
                    $state.go('public.motor.quote.steps.basic');
                },
                setSubEditPH_NMD:function() {
                    $scope.PHN_MDEditVisible=!$scope.PHN_MDEditVisible;
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){ /* nothing to do */ }
                },
                setSubEditMD:function() {
                    $scope.MDEditVisible=!$scope.MDEditVisible;
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){ /* nothing to do */ }
                },
                setSubEditCarDetails:function() {
                    $scope.CarEditVisible=!$scope.CarEditVisible;
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){ /* nothing to do */ }
                },

                onFocusReEmail: function(){
                    $scope.IsReEmailMismatch = false;
                },

                showAgreementBox: function(event){
                    DaModal.open("#agreementBox");
                },
                acceptAgreement: function(){
                    $scope.termAgreement = true;
                    $sessionStorage.tachk = 1;
                    DaModal.close("#agreementBox");
                    $('.modal-backdrop').remove();
                },
                showOTPBox: function(){
                    $scope.$parent.gaEvents(0, 15);
                    DaModal.open("#otp_request");
                },
                otpClose: function(){
                    DaModal.close("#otp_request");
                    DaModal.close("#otp_retry");
                    DaModal.close("#otp_confirm");
                },
                otpRequest: function(){
                    if($scope.OTP.phoneNumber){
                        var tmpPhoneNum = $scope.OTP.phoneNumber;
                        $scope.phContact.PrimaryPhoneNumber = tmpPhoneNum;
                        // if(tmpPhoneNum.substr(0, 1) === '0'){
                        //     $scope.phContact.PrimaryPhoneNumber = '66' + tmpPhoneNum.substr(1);
                        // }
                    }
                    else{
                        return;
                    }

                    if($sessionStorage.cvo > 2){
                        DaModal.close("#otp_request");
                        DaModal.open("#daExceptionModal");
                        return;
                    }

                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc]({
                        onSuccess: function () {
                            DaModal.close("#otp_request");
                            DaModal.close("#otp_retry");
                            $window.OTPLayer = $window.OTPLayer || [];
                            var OTPLayer = $window.OTPLayer;
                            var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();
                            
                            if ($scope.phContact.PrimaryPhoneNumber) {
                                OTPLayer.push({
                                    mitBaseInitialParam: {MITSessionToken: sessionToken}
                                });
                                // Counter
                                if($sessionStorage.cvo === undefined){
                                    $sessionStorage.cvo = 0;  // initial value for session.
                                }

                                $sessionStorage.cvo = $sessionStorage.cvo + 1;
                                $scope.$parent.requestOTP();
                            }
                            $scope.$parent.gaEvents(0, 18);
                            DaModal.open("#otp_confirm");
                        },
                        onError: function (err) {
                            hasError = true;
                            // nothing to do
                        }
                    });
                },
                otpConfirm: function(){
                    if($scope.OTP.code){
                        $window.OTPLayer = [];
                        var OTPLayer = $window.OTPLayer;
                        var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();

                        OTPLayer.push({
                            mitBaseInitialParam: {MITSessionToken: sessionToken},
                            OTP: $scope.OTP.code
                        });

                        $scope.$parent.validateOTP(function(ret){
                            
                            if(ret){
                                $scope.$parent.gaEvents(0, 24);
                                $scope.$parent.gaEvents(0, 25);
                                DaModal.close("#otp_request");
                                DaModal.close("#otp_retry");
                                DaModal.close("#otp_confirm");
                                DaModal.open("#agreementBox");
                            }
                            else{
                                $scope.$parent.gaEvents(0, 21);
                                DaModal.close("#otp_request");
                                DaModal.close("#otp_retry");
                                DaModal.close("#otp_confirm");

                                if($sessionStorage.cvo < 3){
                                    DaModal.open("#otp_retry");
                                }
                                else{
                                    DaModal.open("#daExceptionModal");
                                }
                            }
                        });
                    }
                    else{
                        return;
                    }
                },

                getSelectedCoverage: function () {

                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {IsSelected: true})[0];

                    if (selectedCoverage) {
                        $scope.selectedCoverage = selectedCoverage;
                    }
                    else {
                        $scope.selectedCoverage = null;
                    }

                    return selectedCoverage;
                },

                onBtnNextClicked: function () {

                    try{ $scope.$parent.gaEvents(0,0); }
                    catch(e){ /* nothing to do */ }

                    var hasError = false;

                    let ExternalProposalNumberArr = $scope.currentProposal.ExternalProposalNumber.split('/');
                    if (ExternalProposalNumberArr[0] === 'MT') {
                        if(!$scope.termAgreement){
                            fn.eventHandlers.showOTPBox();
                            return;
                        }
                    }

                    fn.utils.setIsSaving(true);

                    var triggerSave = function () {
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                fn.utils.setIsSaving(false);
                                savingComplete();
                            },
                            onError: function(){
                                DaModal.open("#daExceptionModal");
                                fn.utils.setIsSaving(false);
                            }
                        });
                    };

                    var savingComplete = function () {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }
                        fn.navigation.goToNextStep();
                    };

                    triggerSave();
                },
            },

            calculateTotalAmout: function () {
                var x = 0;
                if ((!$scope.coverages) || !$scope.coverages.Coverages) {
                    return 0;
                }

                // var coverage = $filter('filter')($scope.coverages.Coverages, { IsSelected: true }, true);
                var coverage = $scope.coverages.Coverages.filter(w => w.IsSelected == true);
                if(coverage && coverage.length > 0) {
                    coverage = coverage[0];
                } 
                // else {
                //     return 0;
                // }
                if (!coverage) {
                    return 0;
                }
                x = fn.utils.getPriceToShow(coverage)
                fn.utils.paymentScheduleToShow(coverage);
                // if (coverage) {
                //     var getCoverageID = coverage.CoverTypeId
                   
                    // to get new "FirstPayment & MonthlyPremium" after "Change Plan" immediately
                    // $scope.getAllPlanSelected = $filter('filter')($scope.coverages.Coverages, { CoverTypeId : getCoverageID, IsSelected: true })
                    // $scope.getAllPlanSelected = $scope.coverages.Coverages.filter(w => w.CoverTypeId == getCoverageID && IsSelected == true);
                    // if( $scope.getAllPlanSelected &&  $scope.getAllPlanSelected.length > 0) {
                    //     $scope.getFirstPaymentToString = $scope.getAllPlanSelected[0].PaymentSchedule && $scope.getAllPlanSelected[0].PaymentSchedule[0].length > 1 ?  $scope.getAllPlanSelected[0].PaymentSchedule[0].Amount : 0.00
                    //     $scope.gettMonthlyPremiumToString = $scope.getAllPlanSelected[0].PaymentSchedule && $scope.getAllPlanSelected[0].PaymentSchedule[0].length > 1 ? $scope.getAllPlanSelected[0].PaymentSchedule[1].Amount : 0.00
    
                        // $scope.getFirstPaymentToShow = $scope.getAllPlanSelected[0].PaymentSchedule[0].Amount
                        // $scope.getMonthlyPremiumToShow = $scope.getAllPlanSelected[0].PaymentSchedule.length > 1 ? $scope.getAllPlanSelected[0].PaymentSchedule[1].Amount : 0.00
    
                        // $sessionStorage.toSetFirstPaymentToShow = $scope.getFirstPaymentToShow
                        // $sessionStorage.toSetMonthlyPremiumToShow = $scope.getMonthlyPremiumToShow    
                    // }
                    

                    // to get the latest values of "FirstPayment & MonthlyPremium"
                    // $scope.getFirstPaymentToString = coverage.PaymentSchedule && coverage.PaymentSchedule.length > 1 ?  coverage.PaymentSchedule[0].Amount : 0.00
                    // $scope.gettMonthlyPremiumToString = coverage.PaymentSchedule && coverage.PaymentSchedule.length > 1 ? coverage.PaymentSchedule[1].Amount : 0.00

                    // x = fn.utils.getPriceToShow(coverage)
                // }

                return (x)

            },

            getTotalAmout: function () {
                $scope.totalPremium2 = fn.calculateTotalAmout();
                return $scope.totalPremium2;
            },

        };

        var aggFn = {
            getStaticDataDisplayAgg: function (paramKey, paramStaticData, financialOption) {
                if(paramStaticData) {
                    const result = paramStaticData.find((item) => {
                        var it = item && item.key ? item.key.toString() : '';
                        var pKey = paramKey ? paramKey.toString() : '';
                        return it === pKey
                    });
                    if (result) {
                        if(financialOption == 'financialOption') {
                            if($scope.HKLocale.getLocale() == 'th-th') {
                                return paramKey == 1000001 ? "ชำระเต็มจำนวน" : "ผ่อนชำระ";
                            } else {
                                return result.value;
                            }
                        } else {
                            return result.value;
                        }
                    } else {
                        return "";
                    }    
                } else {
                    return "";
                }
            },
            toLocaleDateString: function(dateOfBirth) {
                if(!dateOfBirth) return '';
                const birthDate = new Date(dateOfBirth);
                let dateofBirthFormat = birthDate.toLocaleDateString("th-TH",{
                    year: 'numeric', 
                    month: 'long', 
                    day: 'numeric'
                });
                return dateofBirthFormat;
            },
            convertEngYear: function(yearString) {
                if(!yearString) return '';
                return parseInt(yearString.substr(0,4));
            },
            convertThaiYear: function(yearString) {
                if(!yearString) return '';
                return parseInt(yearString.substr(0,4)) + 543;
            },
            convertThaiYearOnly: function(yearString) {
                if(!yearString) return '';
                return parseInt(yearString) + 543;
            },
            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getVehicleColour();
                };

                var getVehicleColour = function () {
                    // ApiService.getVehicleColour({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.vehicleColour = data;
                    //         getMakeList();
                    //     },
                    //     onError: function () {
                    //         getMakeList();
                    //     }
                    // });
                    ApiServiceV2.getVehicleColour({
                        onSuccess: function (data) {
                            $scope.staticData.vehicleColour = data;
                            getMakeList();
                        },
                        onError: function () {
                            getMakeList();
                        }
                    });
                };

                var getMakeList = function () {
                    // ApiService.getStaticDataFromRel({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.makes = data;
                    //         getDistrictList();
                    //     },
                    //     onError: function () {
                    //         getDistrictList();
                    //     }
                    // }, 'manufacturer');
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.makes = data;
                            getProvinceList();
                        },
                        onError: function () {
                            getProvinceList();
                        }
                    }, 'MANUFACTURE');
                };

                var getProvinceList = function () {
                    // ApiService.getStaticDataFromRel({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.provinces = data;

                    //         getDistrictList();
                    //     },
                    //     onError: function () {
                    //         getDistrictList();
                    //     }
                    // }, 'provinces');
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.provinces = data;
                            getDistrictList();
                        },
                        onError: function () {
                            getDistrictList();
                        }
                    }, 'PROVINCE');
                };

                var getDistrictList = function () {
                    // wfeApiStaticData.getDistrictList({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.districts = data;
                    //         getContactRelationshipTypeList();
                    //     },

                    //     onError: function () {
                    //         getContactRelationshipTypeList();
                    //     }
                    // });
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.districts = data;
                            getContactRelationshipTypeList();
                        },
                        onError: function () {
                            getContactRelationshipTypeList();
                        }
                    }, 'DISTRICT', 'provinceId=' + $scope.staticData.provinces[0].key);
                };

                var getContactRelationshipTypeList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.relationships = data;
                    //         getBooleanListWithSometimes();
                    //     },
                    //     onError: function () {
                    //         getBooleanListWithSometimes();
                    //     }
                    // }, 'contactRelationshipTypes');
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.relationships = data;
                            getBooleanListWithSometimes();
                        },
                        onError: function () {
                            getBooleanListWithSometimes();
                        }
                    }, 'CONTACT_RELATIONSHIP_TYPE');
                };

                var getBooleanListWithSometimes = function () {
                    // wfeApiStaticData.getBooleanListWithSometimes({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.booleansSometimes = data;
                    //         getBooleanList();
                    //     },

                    //     onError: function () {
                    //         getBooleanList();
                    //     }
                    // });
                    ApiServiceV2.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },
                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.booleans = data;
                    //         getPaymentTermList();
                    //     },
                    //     onError: function () {
                    //         getPaymentTermList();
                    //     }
                    // }, 'yesOrNo');
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getPaymentTermList();
                        },
                        onError: function () {
                            getPaymentTermList();
                        }
                    }, 'YES_NO');
                };

                var getPaymentTermList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.paymentTerms = data;
                    //         getInsurerList();
                    //     },
                    //     onError: function () {
                    //         getInsurerList();
                    //     }
                    // }, 'paymentTerms');
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.paymentTerms = data;
                            getInsurerList();
                        },
                        onError: function () {
                            getInsurerList();
                        }
                    }, 'PAYMENT_TERMS', 'paymentModeId=4');
                };

                var getInsurerList = function () {
                    wfeApiStaticData.getInsurerList({
                        onSuccess: function (data) {
                            $scope.staticData.otherInsurances = data;
                            getNoClaimDiscountList();
                        },
                        onError: function () {
                            getNoClaimDiscountList();
                        }
                    });
                };

                var getNoClaimDiscountList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.ncdLevels = data;
                    //         getOccupationList();
                    //     },
                    //     onError: function () {
                    //         getOccupationList();
                    //     }
                    // }, 'ncd');
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data;
                            getOccupationList();
                        },
                        onError: function () {
                            getOccupationList();
                        }
                    }, 'NCD_LEVEL', 'productTypeId=1000000');
                };

                var getOccupationList = function () {
                    // ApiService.getStaticDataFromRel({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.occupations = data;
                    //         getGenderList();
                    //     },
                    //     onError: function () {
                    //         getGenderList();
                    //     }
                    // }, 'occupations');
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getGenderList();
                        },
                        onError: function () {
                            getGenderList();
                        }
                    }, 'OCCUPATION');
                };

                var getGenderList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.genders = data;
                    //         getMaritalStatusList();
                    //     },
                    //     onError: function () {
                    //         getMaritalStatusList();
                    //     }
                    // }, 'gender');
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.genders = data;
                            getMaritalStatusList();
                        },
                        onError: function () {
                            getMaritalStatusList();
                        }
                    }, 'GENDER');
                };

                var getMaritalStatusList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.maritalStatuses = data;
                    //         getNoOfClaimList();
                    //     },
                    //     onError: function () {
                    //         getNoOfClaimList();
                    //     }
                    // }, 'familyStatus');
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getNoOfClaimList();
                        },
                        onError: function () {
                            getNoOfClaimList();
                        }
                    }, 'FAMILY_STATUS');
                };

                var getNoOfClaimList = function () {
                    // wfeApiStaticData.getNoOfClaimList({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.nrOfClaimsInPeriods = data;
                    //         getDriverExperienceList();
                    //     },

                    //     onError: function () {
                    //         getDriverExperienceList();
                    //     }
                    // });
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.nrOfClaimsInPeriods = data;
                            getDriverExperienceList();
                        },
                        onError: function () {
                            getDriverExperienceList();
                        }
                    }, 'NO_OF_CLAIMS', 'productTypeId=1000000');
                };

                var getDriverExperienceList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.drivingExperiences = data;
                    //         getResidentialStatusList();
                    //     },
                    //     onError: function () {
                    //         getResidentialStatusList();
                    //     }
                    // }, 'driverExperiences');
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                            getResidentialStatusList();
                        },
                        onError: function () {
                            getResidentialStatusList();
                        }
                    }, 'DRIVER_EXPERIENCE', 'productTypeId=1000000');
                };

                var getResidentialStatusList = function () {
                    // wfeApiStaticData.getResidentialStatusList({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.residentStatuses = data;
                    //         getVehicleMileageList();
                    //     },
                    //     OnError: function () {
                    //         getVehicleMileageList();
                    //     }
                    // });
                    ApiServiceV2.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentStatuses = data;
                            getVehicleMileageList();
                        },
                        onError: function () {
                            getVehicleMileageList();
                        }
                    });
                };

                var getVehicleMileageList = function () {
                    wfeApiStaticData.getVehicleMileageList({
                        onSuccess: function (data) {
                            $scope.staticData.mileages = data;
                            getVehicleUsageList();
                        },
                        onError: function () {
                            getVehicleUsageList();
                        }
                    });
                };

        
                var getVehicleUsageList = function () {
                    // ApiService.getStaticData({
                    //     onSuccess: function (data) {
                    //         $scope.staticData.usages = data;
                    //     },
                    //     onError: function () {
                    //     }
                    // }, 'vehicleUsages');
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.usages = data;
                        },
                        onError: function () {
                        }
                    }, 'VEHICLE_USAGE', 'productTypeId=1000000');
                };

                startRetrieval();
            },
        };

        $scope.PACKAGE_TYPE1 = AppConstants.AGGConfig.PACKAGE_TYPE1;
        $scope.PACKAGE_TYPE1_SAVE = AppConstants.AGGConfig.PACKAGE_TYPE1_SAVE;
        $scope.PACKAGE_TYPE2 = AppConstants.AGGConfig.PACKAGE_TYPE2;
        $scope.PACKAGE_TYPE2_PLUS = AppConstants.AGGConfig.PACKAGE_TYPE2_PLUS;
        $scope.PACKAGE_TYPE3 = AppConstants.AGGConfig.PACKAGE_TYPE3;
        $scope.PACKAGE_TYPE3_PLUS  = AppConstants.AGGConfig.PACKAGE_TYPE3_PLUS;

        $scope.TYPE1_MTI_G = AppConstants.AGGConfig.TYPE1_MTI_G; //Type 1 (MTI) Garage
        $scope.TYPE1_MTI_D = AppConstants.AGGConfig.TYPE1_MTI_D; //Type 1 (MTI) Dealer

        $scope.TYPE1_AXA_G = AppConstants.AGGConfig.TYPE1_AXA_G; //Type 1 (AXA) Garage
        $scope.TYPE1_AXA_D = AppConstants.AGGConfig.TYPE1_AXA_D; //Type 1 (AXA) Dealer

        $scope.TYPE1_FALCON_DG = AppConstants.AGGConfig.TYPE1_FALCON_DG; //Type 1 (Falcon) Garage/Dealer
        $scope.TYPE1SAVE_FALCON_DG = AppConstants.AGGConfig.TYPE1SAVE_FALCON_DG; //Type 1 Save (Falcon) Garage/Dealer
        $scope.TYPE2_FALCON_DG = AppConstants.AGGConfig.TYPE2_FALCON_DG; //Type 2 (Falcon) Garage/Dealer
        $scope.TYPE2PLUS_FALCON_DG = AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG; //Type 2+ (Falcon) Garage/Dealer
        $scope.TYPE3_FALCON_DG = AppConstants.AGGConfig.TYPE3_FALCON_DG; //Type 3 (Falcon) Garage/Dealer
        $scope.TYPE3PLUS_FALCON_DG = AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG; //Type 3+ (Falcon) Garage/Dealer
        $scope.TYPECOMPULSORY_FALCON_DG = AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG; //พ.ร.บ.

        $scope.PRODUCT_ID_DA = AppConstants.AGGConfig.PRODUCT_ID_DA;
        $scope.PRODUCT_ID_MTI = AppConstants.AGGConfig.PRODUCT_ID_MTI;
        $scope.PRODUCT_ID_AXA = AppConstants.AGGConfig.PRODUCT_ID_AXA;

        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA;
        $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_DEATH_DIS_DA;
        $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_DEATH_DIS_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_DEATH_DIS_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA;
        $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_DA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_DA;
        $scope.PRODUCT_LINE_GROUP_ID_2000112_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_2000112_DA;
        $scope.PRODUCT_LINE_GROUP_ID_2000107_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_2000107_DA;
        $scope.PRODUCT_LINE_GROUP_ID_2000160_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_2000160_DA;
        $scope.VALUE_PLAN = AppConstants.AGGConfig.VALUE_PLAN;
        $scope.FLEXIBLE_PLAN = AppConstants.AGGConfig.FLEXIBLE_PLAN;
        $scope.VALUE_PLUS_PLAN = AppConstants.AGGConfig.VALUE_PLUS_PLAN;
        $scope.SINGLE_PAYMENT = AppConstants.AGGConfig.SINGLE_PAYMENT;
        $scope.INSTALLMENT_PAYMENT = AppConstants.AGGConfig.INSTALLMENT_PAYMENT;
        $scope.SINGLE_PAYMENT_MODE_ID = AppConstants.AGGConfig.SINGLE_PAYMENT_MODE_ID;
        $scope.INSTALLMENT_PAYMENT_MODE_ID = AppConstants.AGGConfig.INSTALLMENT_PAYMENT_MODE_ID;

        $scope.PRODUCT_LINE_GROUPID_FALCON_2000116 = AppConstants.AGGConfig.PRODUCT_LINE_GROUPID_FALCON_2000116;

        $scope.PLANID_NAMED_DRIVER = AppConstants.AGGConfig.PLANID_NAMED_DRIVER;
        $scope.PLANID_ANY_DRIVER = AppConstants.AGGConfig.PLANID_ANY_DRIVER;

        $scope.PRODUCT_LINE_GROUP_VALUE_VOLUNTARY_BASIC_COVER = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_VALUE_VOLUNTARY_BASIC_COVER;



        fn.initScopeObjects();
        fn.initApiObjects();
        //fn.initStaticData();
        aggFn.initStaticData();
        fn.initSubscribeWatcher();
        fn.retrieveScopeObjects();

        // RegEx : PhoneNumberChecker
        $scope.PhoneNumberChecker = /^[0-9]{1}[0-9]{9,10}$/

        ////showCoverageDetails
        $scope.showCoverageDetails = fn.showCoverageDetails;

        ////Event Handlers
        $scope.onBtnToBuy = fn.eventHandlers.onBtnToBuy;
        $scope.openCallbackPopup = fn.eventHandlers.openCallbackPopup;
        $scope.closeCallbackThankyou = fn.eventHandlers.closeCallbackThankyou;
        $scope.closeCallbackPopup = fn.eventHandlers.closeCallbackPopup;        
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnBackToMyPolicy = fn.eventHandlers.onBtnBackToMyPolicy;
        $scope.onBtnDeclineClicked = fn.eventHandlers.onBtnDeclineClicked;
        $scope.onBtnPrintClicked = fn.eventHandlers.onBtnPrintClicked;
        $scope.onBtnAmendClicked = fn.eventHandlers.onBtnAmendClicked;
        $scope.showAgreementBox = fn.eventHandlers.showAgreementBox;
        $scope.acceptAgreement = fn.eventHandlers.acceptAgreement;

        $scope.onPolicyHolderEditClicked = fn.eventHandlers.onPolicyHolderEditClicked;
        $scope.onPolicyMainDriveEditClicked = fn.eventHandlers.onPolicyMainDriveEditClicked;
        $scope.onCarDetailEditClicked = fn.eventHandlers.onCarDetailEditClicked;
        $scope.onQuoteDetailsEditClicked = fn.eventHandlers.onQuoteDetailsEditClicked;
        $scope.onAdditionalDriversEditClicked = fn.eventHandlers.onAdditionalDriversEditClicked;
        $scope.setSubEditPH_NMD=fn.eventHandlers.setSubEditPH_NMD;
        $scope.onGoToCoverCtrlClicked=fn.eventHandlers.onGoToCoverCtrlClicked;
        $scope.onGoToBasicCtrlClicked=fn.eventHandlers.onGoToBasicCtrlClicked;
        $scope.setSubEditMD=fn.eventHandlers.setSubEditMD;
        $scope.setSubEditCarDetails=fn.eventHandlers.setSubEditCarDetails;

        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;

        ////Utils
        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.utils.getStaticDataDisplayAgg = aggFn.getStaticDataDisplayAgg;
        $scope.utils.toLocaleDateString = aggFn.toLocaleDateString;
        $scope.utils.convertEngYear = aggFn.convertEngYear;
        $scope.utils.convertThaiYear = aggFn.convertThaiYear;
        $scope.utils.convertThaiYearOnly = aggFn.convertThaiYearOnly;
        $scope.utils.filterBaseCover = fn.utils.filterBaseCover;
        $scope.utils.filterMandatoryCovers = fn.utils.filterMandatoryCovers;
        $scope.utils.sortOptionalCovers = fn.utils.sortOptionalCovers;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.stepFn.isRenewalProposalLanding = fn.utils.isRenewalProposalLanding;

        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.divertAfterSECB=$scope.$parent.divertAfterSECB;
        $scope.onFocusReEmail = fn.eventHandlers.onFocusReEmail;
        $scope.otpClose = fn.eventHandlers.otpClose;
        $scope.otpRequest = fn.eventHandlers.otpRequest;
        $scope.otpConfirm = fn.eventHandlers.otpConfirm;
        $scope.gaEvents = $scope.$parent.gaEvents;
        $scope.setTA = fn.setTA;
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;

        $scope.getInsuranceAmount = fn.utils.getInsuranceAmount;
        $scope.getIncludeCompulsory = fn.utils.getIncludeCompulsory;
        $scope.getExcessValueSummary = fn.utils.getExcessValueSummary;
        $scope.getTotalAmout = fn.getTotalAmout;
        
        // about "Rate adjustment"
        $scope.getFinancialOptionToString = $sessionStorage.getFinancialOptionText
        $scope.getFinancialCheckerToString = $sessionStorage.toSetFinancialChecker

        //Detect width of screen
        $scope.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

        $scope.thirdPageShow = false;


        // to set "singlepayment state only" once customer select Type2 & Type3 at first
        $scope.getSinglePaymentOnlyStateFromType2Type3 = $sessionStorage.toSetSinglePaymentFromType2Type3Selected

        $scope.$on('$viewContentLoaded', function() {
            $timeout(function(){
                $scope.$parent.changeGlobalContactNo();
            }, 1000);
        });
        $(window).resize(function(){
            $scope.$apply(function(){
                $scope.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            });
        });

    }];