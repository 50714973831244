/*
 * Created by Gerald Garcia on 05/06/15.
 */
module.exports = [
    '$state',
    '$anchorScroll',
    '$scope',
    '$filter',
    '$timeout',
    'HKFormValidation',
    'HKLocale',
    'DaModal',
    'CountryMotorQuoteValidation',
    'WfeApiStaticData',
    'WfeApiPolicyContact',
    'WfeApiCoverages',
    'WfeApiQuote',
    'WfeApiEssential',
    'WfeApiContact',
    'WfeApiLog',
    'WfeApiConfirmation',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'MITDate',
    '$window',
    '$sessionStorage',
    '$localStorage',

    /**
     * Controller for the Customising Coverages of Motor Quote,
     * @constructor
     */
        function MotorQuoteCustomiseCoverController($state, $anchorScroll, $scope, $filter, $timeout,
                                                    HKFormValidation, HKLocale, DaModal, CountryMotorQuoteValidation,
                                                    WfeApiStaticData, WfeApiPolicyContact, WfeApiCoverages, WfeApiQuote,
                                                    WfeApiEssential, WfeApiContact, WfeApiLog, WfeApiConfirmation, WfeApiCurrentProposal,WfeApiAsset,MitDate,$window, $sessionStorage, $localStorage) {
        $anchorScroll();

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/car-and-you/step-4/'}
                );

            },

            gaEvents:function(v){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                var p='';
                switch(v)
                {
                    case 1: p='Add Named Driver'; break;
                    default: break;
                }
                dataLayer.push({
                    'event': p,
                    'category': 'Portal',
                    'action': 'Navigation',
                    'label': p
                });

            },

            dLCE:function(ref,v) {

                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                switch(ref)
                {
                    case 12:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'deductible-amount':v
                    });
                        break;
                    case 13:
                        var s='';
                        if(v==1000003 || v== '1000003') {s='0 %'};
                        if(v==1000005 || v== '1000005' ) {s='20%'};
                        if(v==1000006 || v== '1000006' ) {s='30%'};
                        if(v==1000007 || v== '1000007' ) {s='40%'};
                        if(v==1000008 || v== '1000008' ) {s='50%'};
                        if(v==2500000 || v== '2500000' ) {s='I don\'t know'};

                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'no-claims no claims bonus':s
                    });
                        break;
                    case 14:
                        var s='';
                        if(v==1 || v== '1') {s='0'};
                        if(v==2 || v== '2' ) {s='1'};
                        if(v==3 || v== '3' ) {s='2'};
                        if(v==4 || v== '4' ) {s='3'};
                        if(v==5 || v== '5' ) {s='4'};
                        if(v==1000000 || v== '1000000' ) {s='5'};
                        if(v==1000001 || v== '1000001' ) {s='more than 5'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'driving years of driving exp':s
                    });
                        break;
                    case 15:
                        var s='';
                        switch(v){
                            case "1000003":s='0';break;
                            case "1000004":s='1: At fault';break;
                            case "1000008":s='1: Not at fault';break;
                            case "1000011":s='2 (1 at fault and 1 not at fault)';break;
                            case "1000005":s='2 at fault';break;
                            case "1000010":s='2 not at fault';break;
                            case "1000012":s='3 (1 at fault, 2 not at fault)';break;
                            case "1000013":s='3 (2 at fault, 1 not at fault)';break;
                            case "1000014":s='3 at fault';break;
                            case "1000006":s='3 not at fault';break;
                            case "1000007":s='more than 3';break;
                            default:s='';break;
                        }
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'main driver claims':s
                    });
                        break;
                    case 16:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'is the main driver also the policyholder':s
                    });
                        break;
                    case 17:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'policy-cover-other will this insurance policy cover other drivers':v
                        });
                        break;
                    case 18:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'who-do-you-want-to-cover':v
                        });
                        break;
                    case 19:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'choose-your-workshop':v
                        });

                        break;
                    case 20:

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'days-to-policy-start-date':v+1
                        });
                        break;
                    case 21:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'named-driver-DOB':v
                    });
                    break;
                    case 22:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'named-driver-gender':v
                    });
                        break;
                    case 23:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'named-driver-marital-status':v
                    });
                        break;
                    case 24:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'named-driver-experience':v
                    });
                        break;
                    case 25:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'named-driver-claims':v
                    });
                        break;
                    case 26:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'previous-insurer':v
                    });
                        break;
                    case 27:
                        var s='';
                        if(v==1000003 || v== '1000003') {s='0 %'};
                        if(v==1000005 || v== '1000005' ) {s='20%'};
                        if(v==1000006 || v== '1000006' ) {s='30%'};
                        if(v==1000007 || v== '1000007' ) {s='40%'};
                        if(v==1000008 || v== '1000008' ) {s='50%'};
                        if(v==2500000 || v== '2500000' ) {s='I don\'t know'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'previous-NCD':s
                    });
                        break;
                    case 30:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'do-you-have-any-car-modification-or-accessories-that-you-want-to-cover':s
                    });
                        break;
                    case 31:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'is-your-car-used-for-commercial-purpose':s
                    });
                        break;
                    case 100:
                        if($scope.namedDrivers.PolicyContacts.length>0) {
                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'named-driver-DOB':undefined,
                                'named-driver-gender':undefined,
                                'named-driver-marital-status':undefined,
                                'named-driver-experience':undefined,
                                'named-driver-claims':undefined
                            });//resets GTM datalayer duplications.

                            var nddob=[];var ndgen=[];var ndmar=[];var nddexp=[];var ndclaims=[];
                            angular.forEach($scope.namedDrivers.PolicyContacts, function (obj) {

                            try{
                                    s=obj.DateOfBirth.split("-");
                                    v=s[0].replace(/\s/g,'');
                                    nddob.push(v);
                                }
                                catch(err){console.log(err);}


                                if( obj.GenderId=="1")
                                {
                                    ndgen.push("Female");
                                }
                                else {ndgen.push("Male");}

                                if( obj.MaritalStatusId=="1")
                                {
                                    ndmar.push("Single");
                                }
                                else {ndmar.push("Married");}

                                switch(obj.DrivingExperienceId){
                                    case "1000001":nddexp.push('Over 5');break;
                                    case "1":nddexp.push('0');break;
                                    case "2":nddexp.push('1');break;
                                    case "3":nddexp.push('2');break;
                                    case "4":nddexp.push('3');break;
                                    case "5":nddexp.push('5');break;
                                    case "1000000":nddexp.push('5');break;
                                    default:nddexp.push('');break;
                                }
                                switch(obj.NrOfClaimsInPeriodId){
                                    case "1000003":ndclaims.push('0');break;
                                    case "1000004":ndclaims.push('1: At fault');break;
                                    case "1000008":ndclaims.push('1: Not at fault');break;
                                    case "1000011":ndclaims.push('2 (1 at fault and 1 not at fault)');break;
                                    case "1000005":ndclaims.push('2 at fault');break;
                                    case "1000010":ndclaims.push('2 not at fault');break;
                                    case "1000012":ndclaims.push('3 (1 at fault, 2 not at fault)');break;
                                    case "1000013":ndclaims.push('3 (2 at fault, 1 not at fault)');break;
                                    case "1000014":ndclaims.push('3 at fault');break;
                                    case "1000006":ndclaims.push('3 not at fault');break;
                                    case "1000007":ndclaims.push('more than 3');break;
                                    default:ndclaims.push('');break;
                                }
                            });
                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'named-driver-DOB':nddob.toString(),
                                'named-driver-gender':ndgen.toString(),
                                'named-driver-marital-status':ndmar.toString(),
                                'named-driver-experience':nddexp.toString(),
                                'named-driver-claims':ndclaims.toString()
                            });
                        }
                        else
                        {}

                        break;
                    default: break;
                }
            }
        };

        var fn = {
            gtAnchor:function(x){
                if (!x)
                {return;}

                HKFormValidation.animateToID(x);
            },

            /*****On load function******/
            postToMaxParent:function(callBackStatus,Priority,FinalCall){
                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                
                var typeCoverage='';
                var coverageId='';
                if($scope.coverages.Coverages)
                {
                    for(var i=0;i<=$scope.coverages.Coverages.length;i++)
                    {
                        if($scope.coverages.Coverages[i].IsSelected)
                        {
                            typeCoverage = $scope.coverages.Coverages[i].Name;
                            coverageId = $scope.coverages.Coverages[i].CoverTypeId;
                            break;
                        }
                    }
                }
                if(coverageId === 2000017){
                    Priority = 4;
                }

                var tmp = $scope.currentProposal.PolicyStartDate.split("-");
                var policyStartDateMaxar = tmp[2] + "-" + tmp[1] + "-" + tmp[0] + " 00:00";

                var deductible=$scope.$parent.filterStatic($scope.selectedCoverage.excess,$scope.selectedCoverage.ExcessLevelId);
                var ncd=$scope.$parent.filterStatic($scope.staticData.ncdLevels,$scope.asset.NcdLevelId);
                var drivingYears=$scope.$parent.filterStatic($scope.staticData.drivingExperiences,$scope.mainDriver.DrivingExperienceId);
                var numAccidents=$scope.$parent.filterStatic($scope.staticData.nrOfClaimsInPeriods,$scope.mainDriver.NrOfClaimsInPeriodId);
                var mainDriverPolicyholder=$scope.$parent.filterStatic($scope.staticData.booleans,$scope.policyHolder.IsMainDriver);
                
                var otherDrivers='';
                var typeOtherDrivers=''; 
                if($scope.plan.coversNamed==0){
                    otherDrivers='No';
                    typeOtherDrivers='NA'}
                if($scope.plan.coversNamed==1){
                    otherDrivers='Yes';
                    typeOtherDrivers='Any Driver';}
                if($scope.plan.coversNamedWho==0){typeOtherDrivers='Named Drivers';}
                if($scope.plan.coversNamedWho==1){typeOtherDrivers='Any Driver';}

                var workshop='';
                if($scope.asset.UseMyWorkshop == false){ workshop = 'Panel';}
                else{ workshop = 'Dealer';}

                var Typeplan='';
                if($scope.asset.PlanId==1000003){Typeplan='Value';}
                if($scope.asset.PlanId==1000004){Typeplan='Flexible';}
                if($scope.asset.PlanId==1000005){Typeplan='Value plus';}

                var previousInsurer=$scope.$parent.filterStatic($scope.staticData.otherInsurances,$scope.asset.PastInsuranceContactExtNum);
                var qn=($scope.currentProposal.ExternalProposalNumber)? $scope.currentProposal.ExternalProposalNumber:'NA';
                
                maxarLayer.push( {
                    quoteNumber:qn,
                    typeCoverage:typeCoverage,
                    totalPremium:$scope.totalPremium2,
                    message:$scope.callbackObj.message,
                    commercial:$scope.callbackObj.commercial,
                    deductible:deductible,
                    ncd:ncd,
                    drivingYears:drivingYears,
                    numAccidents:numAccidents,
                    mainDriverPolicyholder:mainDriverPolicyholder,
                    otherDrivers:otherDrivers,
                    typeOtherDrivers:typeOtherDrivers,
                    plan:Typeplan,
                    workshop:workshop,
                    policyStartDate:policyStartDateMaxar,
                    previousInsurer:'-',
                    type:'Quote lead',
                    sourcePage:'Your Policy',
                    callback:callBackStatus,
                    priority:Priority,
                    dataFinal:FinalCall,
                    referenceID:$scope.phContact.PrimaryPhoneNumber,
                    telephone:$scope.phContact.PrimaryPhoneNumber,

                });

                if($scope.$parent !== null){
                    $scope.$parent.postMaxar();
                }
            },
            /** Suminsured */
            setSumInsured:function()
            {
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;
                if($scope.selectedCoverage.CoverTypeId==2000018 || $scope.selectedCoverage.CoverTypeId=='2000018')
                {
                    //Type 3 is always NA and hidden
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                    return;
                }
                if($scope.selectedCoverage.CoverTypeId==2000019 || $scope.selectedCoverage.CoverTypeId=='2000019'
                || $scope.selectedCoverage.CoverTypeId==2000017 || $scope.selectedCoverage.CoverTypeId=='2000017'
                || $scope.selectedCoverage.CoverTypeId==2000015 || $scope.selectedCoverage.CoverTypeId=='2000015')
                {
                    //Type 2+/ 3+ / Type 1 - value from own damage collision
                    //Type 1- own damage collision value.
                    var odc=$filter('filter')($scope.selectedCoverage.Covers, {
                        ProductLineGroupId: 2000105
                    },true);
                    if(odc && odc[0].InsuranceAmount){

                        $scope.sumInsured.value=odc[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }


                    return;
                }

                if($scope.selectedCoverage.CoverTypeId==2000016 || $scope.selectedCoverage.CoverTypeId=='2000016')
                {

                    //Type 2- own damage Fire and theft value
                    var odft=$filter('filter')($scope.selectedCoverage.Covers, {
                        ProductLineGroupId: 2000106
                    },true);
                    if(odft && odft[0].InsuranceAmount){

                        $scope.sumInsured.value=odft[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }
                    return;
                }

                if($scope.selectedCoverage.CoverTypeId==2000020 || $scope.selectedCoverage.CoverTypeId=='2000020')
                {

                    //Type Compulsory only
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                    return;
                }
                //Exception- Everything fails- hide
                $scope.sumInsured.visible=false;
                $scope.sumInsured.value=0;

            },

            /** Falcon UW API */
            postUWFC: function(){
                $window.UWFCLayer = [];
                var UWFCLayer = $window.UWFCLayer;
                var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();
                fn.setSumInsured();
                if ($scope.asset.ModelId) {
                    var objParams = {};
                    objParams.manufacturerId = $scope.asset.ManufactureId;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getModelListV2({
                        onSuccess: function (data) {
                            $scope.txtModel = $filter('filter')(data, {key: $scope.asset.ModelId})[0].value;
                            $scope.txtMake = $filter('filter')($scope.staticData.makes, {key: $scope.asset.ManufactureId})[0].value;
                            UWFCLayer.push({
                                mitBaseInitialParam: {MITSessionToken: sessionToken},
                                Make : $scope.txtMake, //fn.wfeApiStaticData.utils.getStaticDataDisplay($scope.asset.ManufactureId, $scope.staticData.makes),
                                Model : $scope.txtModel,
                                YearOfRegistration : $scope.asset.PurchasingYear.toString(),
                                SumInsured : $scope.sumInsured.value,
                                Deductible : parseInt(fn.wfeApiStaticData.utils.getStaticDataDisplay( $filter('filter')($scope.selectedCoverage.Covers, {ProductLineGroupId: '2000103'} )[0].ExcessLevelId, $scope.selectedCoverage.excess)),
                                Premium : $scope.totalPremium2.toFixed(2)
                            });

                            $scope.$parent.checkUWFC();
                        }
                    });
                }
            },
            /** Falcon BL API */
            postBLFC: function(){
                $window.BLFCLayer = [];
                var BLFCLayer = $window.BLFCLayer;
                var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();
                var idNumber = $scope.policyHolder.Identifier  //$scope.policyHolder.Identifier.replace(/-/g, "")
                var identifier = idNumber.replace(/-/g, "");
                var cardType = "I"
                if($scope.policyHolder.ResidentialStatusId == 2000001){
                    cardType = "P"
                }
                
                BLFCLayer.push({
                    mitBaseInitialParam: {MITSessionToken: sessionToken},
                    CardType : cardType,
                    Identification : identifier,
                    FirstName : $scope.policyHolder.FirstName,
                    LastName : $scope.policyHolder.LastName
                });

                if($scope.policyHolder.FirstName !== '' && $scope.policyHolder.LastName !== '' && identifier.length === 13)
                {
                    $scope.$parent.checkBLFC();
                }
                

            },

            /** Use for reset camera token */
            updateCarCameraReset: function() {

                DaModal.open("#daLoadingMask");
                $scope.asset.IsCarCameraInstalled = false;
                $scope.asset.UseMyWorkshop = true;

                fn.assetApi.updateAsset(
                    {
                        onSuccess: function () {

                            $scope.camCount = 0;
                            $scope.camCoverSelected = 0;
                            for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                                if($scope.coverages.Coverages[i].IsSelected == true){
                                    $scope.camCoverSelected = i;
                                }
                            }

                            for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                                $scope.coverages.Coverages[i].IsSelected = false;
                            }
                            //$scope.coverages.Coverages[$scope.camCount].IsSelected = true;

                            var callbacks = {
                                onSuccess: function(){
                                    fn.saving.calculatePremiumCarCamera(
                                        {
                                            onSuccess: function () {
                                                /*$scope.camCount++;
                                                if ($scope.coverages.Coverages[$scope.camCount].CoverTypeId == '2000020') {
                                                    $scope.camCount++;
                                                }

                                                for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                                                    $scope.coverages.Coverages[i].IsSelected = false;
                                                }

                                                if ($scope.camCount < $scope.coverages.Coverages.length) {
                                                    $scope.coverages.Coverages[$scope.camCount].IsSelected = true;
                                                    fn.saving.saveCoveragesModel(callbacks);
                                                } else {
                                                    $scope.coverages.Coverages[$scope.camCoverSelected].IsSelected = true;
                                                    fn.saving.saveCoveragesModel(callbacks2);
                                                }*/

                                                for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                                                    $scope.coverages.Coverages[i].IsSelected = false;
                                                }

                                                $scope.coverages.Coverages[$scope.camCoverSelected].IsSelected = true;
                                                fn.saving.saveCoveragesModel(callbacks2);
                                            },
                                            onError: function() {

                                            }
                                        }
                                    );
                                },
                                onError: function(){

                                }
                            }

                            var callbacks2 = {
                                onSuccess: function () {
                                    fn.retrieveScopeObjects();
                                    fn.utils.setIsSaving(false);
                                },

                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            };

                            fn.saving.saveCoveragesModel(callbacks);

                        },
                        onError: function () {

                        }
                    }
                );
            },

            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    //getBooleanListWithSometimes();
                    getMakeList();
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            $scope.staticData.makes = data;
                            getBooleanListWithSometimes();
                        }
                    });
                };

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getNoOfClaimList();
                        },

                        onError: function () {
                            getNoOfClaimList();
                        }
                    });
                };

                var getNoOfClaimList = function () {
                    wfeApiStaticData.getNoOfClaimList({
                        onSuccess: function (data) {
                            /* if($scope.HKLocale.getLocale() === "th-th"){
                                var enableListNROfClaims = [{'key': 1000003, 'value': '0 ครั้ง'}, 
                                                        {'key': 1000004, 'value': '1 ครั้ง'},
                                                        {'key': 1000005, 'value': '2 ครั้ง'}, 
                                                        {'key': 1000007, 'value': '3 ครั้ง หรือมากกว่า'}];
                            }else{
                                var enableListNROfClaims = [{'key': 1000003, 'value': '0'}, 
                                                        {'key': 1000004, 'value': '1'},
                                                        {'key': 1000005, 'value': '2'}, 
                                                        {'key': 1000007, 'value': '3 or more'}];
                            
                            } */
                            $scope.staticData.nrOfClaimsInPeriods = data;
                            getVehiclePriorDamages();
                        },

                        onError: function () {
                            getVehiclePriorDamages();
                        }
                    });
                };

                var getVehiclePriorDamages = function () {
                    wfeApiStaticData.getVehiclePriorDamages({
                        onSuccess: function (data) {
                            $scope.staticData.vehiclePriorDamages = data;
                            getInsurerList();
                        },

                        onError: function () {
                            getInsurerList();
                        }
                    });
                };

                /*var getContactRelationshipTypeList = function () {
                    wfeApiStaticData.getContactRelationshipTypeList({
                        onSuccess: function (data) {
                            $scope.staticData.relationships = data;
                            getInsurerList();
                        },

                        onError: function () {
                            getInsurerList();
                        }
                    });
                };*/

                var getInsurerList = function () {
                    wfeApiStaticData.getInsurerList({
                        onSuccess: function (data) {
                            var skipList = ["90001",
                            "90003",
                            "90005",
                            "90006",
                            "90021",
                            "90032",
                            "90034",
                            "90036",
                            "90060",
                            "90061",
                            "90062",
                            "284705",
                            "90016",
                            "134351"];
                            var useList = [];
                            data.forEach(function(xx, ix){
                                if(skipList.indexOf(xx.key) < 0 ){
                                    useList.push(xx);
                                }else{
                                    //console.log("rem " + xx.key + " - " + xx.value);
                                }
                            });

                            $scope.staticData.otherInsurances = useList;
                            getInsurerListEnglish();
                        },

                        onError: function () {
                            getInsurerListEnglish();
                        }
                    });
                };
                var getInsurerListEnglish = function () {
                    fn.wfeApiStaticDataEnglishOnly.getInsurerList({
                        onSuccess: function (data) {
                            $scope.staticData.otherInsurancesEnglish = data;
                            getNoClaimDiscountList();
                        },

                        onError: function () {
                            getNoClaimDiscountList();
                        }
                    });
                };

                var getNoClaimDiscountList = function () {
                    wfeApiStaticData.getNoClaimDiscountList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data;
                            getNoOfYearsInsured();
                        },

                        onError: function () {
                            getNoOfYearsInsured();
                        }
                    });
                };

                var getNoOfYearsInsured = function () {
                    wfeApiStaticData.getNoOfYearsInsured({
                        onSuccess: function (data){
                            $scope.staticData.yearsInsured = data;
                            getOccupationList();
                        },

                        onError: function () {
                            getOccupationList();
                        }
                    });
                };

                var getOccupationList = function () {
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getOccupationListEnglish();
                        },

                        onError: function () {
                            getOccupationListEnglish();
                        }
                    });
                };

                var getOccupationListEnglish = function () {
                    fn.wfeApiStaticDataEnglishOnly.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupationsEnglish = data;
                            getDriverExperienceList();
                        },

                        onError: function () {
                            getDriverExperienceList();
                        }
                    });
                };

                var getDriverExperienceList = function () {
                    wfeApiStaticData.getDriverExperienceList({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                            getGenderList();
                        },

                        onError: function () {
                            getGenderList();
                        }
                    });
                };

                var getGenderList = function () {
                    /* wfeApiStaticData.getGenderList({
                        onSuccess: function (data) {
                            $scope.staticData.genders = data;
                            getMaritalStatusList();
                        },

                        onError: function () {
                            getMaritalStatusList();
                        }
                    }); */
                    if($scope.HKLocale.getLocale() === "th-th"){
                        var data = [{"key":"2","value":"ชาย"},{"key":"1","value":"หญิง"}];
                        $scope.staticData.genders = data;
                    }else{
                        var data = [{"key":"1","value":"Female"},{"key":"2","value":"Male"}];
                        $scope.staticData.genders = data.reverse();
                    }
                    getMaritalStatusList();
                };

                var getMaritalStatusList = function () {
                    wfeApiStaticData.getMaritalStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getResidentialStatusList();

                        },

                        onError: function () {
                            getResidentialStatusList();

                        }
                    });
                };

                var getResidentialStatusList = function () {
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentialStatus = data;
                            getCountryList();

                        },

                        onError: function () {
                            getCountryList();

                        }
                    });
                };

                var getCountryList = function () {
                    wfeApiStaticData.getCountryList({
                        onSuccess: function (data) {
                            $scope.staticData.countries = data;
                            getNcdReasonList();
                        },

                        onError: function () {
                            getNcdReasonList();
                        }
                    });
                };
                var getNcdReasonList = function () {
                    wfeApiStaticData.getNcdReasonList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdReasons = data;
                            //getMakeList();
                        },
                        onError: function () {

                        }
                    });
                };

                

                startRetrieval();

            },

            initScopeObjects: function () {
                $scope.$parent.isUserProceedOnBuy();
                $scope.isEditing = true;
                $scope.staticData = {};

                $scope.currentProposal = {};
                $scope.policyHolder = {};

                $scope.namedDrivers = {
                    PolicyContacts: []
                };
                $scope.namedDriver={};
                $scope.phContact = {};
                $scope.coverages = {};
                $scope.selectedCoverage = {};
                $scope.asset = {};
                $scope.mainDriver={};
                $scope.plan={
                    coversNamed:1
                };
                $scope.addNamedDriver = false;
                $scope.addNamedDriverAgeLessThan30 = false;
                $scope.addNamedDriverAgeLessThan30_2nd = false;
                $scope.loadingSpinner = true;
                $scope.policyMinStartDate = MitDate.toAngularDate(MitDate.newDate().add(0, 'days'));
                $scope.policyMaxStartDate = MitDate.toAngularDate(MitDate.newDate().add(59, 'days'));
                $scope.IsMyWorkshopDisabled=false;
                $scope.showRemoveWarning=false;
                $scope.callbackObj={};
                $scope.IsReEmailMismatch = false;

                $scope.partialSaveSubmitted = false;
                $scope.callBackSubmitted = false;
                $scope.sumInsured={};
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;
                $window.UWFCLayer = $window.UWFCLayer || [];
                $scope.currentCoverTypeId;

                $scope.showConfirmBuyPageB2C=false;
                $scope.showConfirmBuyPageOnlineSelling=true;

            },

            initApiObjects: function () {
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);
                fn.namedDriversApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.namedDrivers);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataEnglishOnly = WfeApiStaticData.instance($scope, 'en-us', $scope.$parent.product.ProductId);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.coverages, undefined);
            },

            retrieveScopeObjects: function () {
                fn.currentProposalApi.retrieveCurrentProposal(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.currentProposal, data);
                            if($scope.currentProposal.PolicyStartDate) {
                                fn.utils.ConvertMITDateToDisplay();
                            }
                        },
                        onError: function(){

                        }
                    }
                );

                /** Swap these sequence because of VPP */
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.policyHolder, data);
                                if($scope.policyHolder.FirstName.indexOf(" ") > -1 
                                    && ($scope.policyHolder.LastName === null || $scope.policyHolder.LastName === undefined || $scope.policyHolder.LastName === "")){
                                    //New split : Pu 07/12/2022
                                    const getSplitFullname = $scope.policyHolder.FirstName.split(' ').map(element => element.trim()).filter(element => element !== '');    
                                    var firstName = getSplitFullname[0];
                                    var lastName = getSplitFullname[1];
                                    //Old split
                                    // var firstName = $scope.policyHolder.FirstName.split(" ")[0];
                                    // var lastName = $scope.policyHolder.FirstName.split(" ")[1];
                                }else{
                                    var firstName = $scope.policyHolder.FirstName;
                                    var lastName = $scope.policyHolder.LastName;
                                }
                                
                                $scope.policyHolder.FirstName = firstName;
                                $scope.policyHolder.LastName = lastName;

                                if($scope.policyHolder.DateOfBirth)
                                {
                                    fn.utils.ConvertMITDatePHToDisplay();
                                }

                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                    retrieveAsset();
                                }
                                else {
                                    retrieveMainDriver();
                                }
                            }
                        }
                    );
                };

                /** Swap these sequence because of VPP */
                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.mainDriver, data);
                                if($scope.mainDriver.DateOfBirth)
                                {
                                    fn.utils.ConvertMITDateMDToDisplay();
                                }
                                
                                retrieveAsset();
                            },
                            onError: function(){
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                if(data.PlanId)
                                {
                                    /** VPP: Check landing condition when user hit to this page */

                                    //VPP
                                    if(data.PlanId==1000003){
                                        var age = fn.utils.getAge($scope.policyHolder.DateOfBirth);
                                        if(age < 30){
                                            $scope.plan.coversNamed=1; 
                                            $scope.plan.coversNamedWho=1;
                                            $scope.addNamedDriver = false;
                                            $scope.asset.PlanId=1000004;
                                        }else{
                                            $scope.plan.coversNamed=1; 
                                            $scope.plan.coversNamedWho=0;
                                            $scope.addNamedDriver = true;
                                        }
                                    }
                                    //Flexible
                                    if(data.PlanId==1000004){
                                        $scope.plan.coversNamed=1; 
                                        $scope.plan.coversNamedWho=1
                                    }

                                    //Set PlanId for parent
                                    $scope.$parent.PlanId = $scope.asset.PlanId;
                                    /* if(data.PlanId==1000003){
                                        $scope.plan.coversNamedWho=0;
                                        $scope.addNamedDriver = true;
                                    }else{
                                        $scope.plan.coversNamedWho=1;
                                    } */

                                    $scope.asset.PastInsuranceContactExtNum = "291909";  // i dont know as default

                                    /** Enable this Logic for VPP */
                                    /*if(data.PlanId==1000003){$scope.plan.coversNamed=0; $scope.plan.coversNamedWho=1} //Value
                                    if(data.PlanId==1000004){$scope.plan.coversNamed=1; $scope.plan.coversNamedWho=1} //Flex
                                    if(data.PlanId==1000005){$scope.plan.coversNamed=1; $scope.plan.coversNamedWho=0} //VPP*/
                                    /** Enable this Logic for VPP */
                                }
                                //retrieveCoverages();
                                var objParams = {};

                                objParams.modelId = $scope.asset.ModelId;
                                objParams.purchasingYear = $scope.asset.PurchasingYear;
                                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                                
                                wfeApiStaticDataModel.getCarDescWithYear({
                                    onSuccess: function (data) {
                                        for (var i in data)
                                            if (data[i]['key'] == $scope.asset.CarDescriptionId)
                                                $scope.staticData.cardescriptions = data[i];

                                        retrieveCoverages();
                                    },
                                    onError: function (){
                                        retrieveCoverages();
                                    }
                                });
                                
                                fn.retrieveNamedDrivers();
                            },
                            onError: function () {
                                retrieveCoverages();
                                fn.retrieveNamedDrivers();
                            }
                        }
                    );
                };

                var retrieveCoverages = function() {
                    fn.coverageApi.retrieveCoverages(
                        {
                            onSuccess: function (data) {
                                /* console.log(data);
                                var coverages = [];
                                if(data.CoverListsCount == 5){
                                    var index = [2000017, 2000019, 2000016, 2000018, 2000020];
                                }else{
                                    var index = [2000015, 2000017, 2000019, 2000016, 2000018, 2000020];
                                }
                                for(var j in index){
                                    for(var i in data.Coverages){
                                        if(data.Coverages[i].CoverTypeId === index[j]){
                                            coverages[j] = data.Coverages[i];
                                        }
                                    }
                                } */

                                //data.Coverages = coverages;
                                angular.extend($scope.coverages, data);
                                //console.log(data);
                                var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true})[0];
                                
                                if (selectedCoverage) {
                                    angular.extend($scope.selectedCoverage, selectedCoverage);
                                    $scope.selectedCoverage = selectedCoverage;
                                    $scope.currentCoverTypeId = selectedCoverage.CoverTypeId;
                                    if (selectedCoverage.Covers) {
                                        if (selectedCoverage.CoverTypeId != 2000020)
                                        {
                                            var vbc = $filter('filter')(selectedCoverage.Covers, {
                                                ProductLineGroupId: 2000103,

                                            }, true);
                                            if (vbc || vbc[0])
                                            $scope.selectedCoverage.ExcessLevelId = vbc[0].ExcessLevelId;
                                        }
                                    }

                                    if(selectedCoverage.CoverTypeId==2000018 || selectedCoverage.CoverTypeId==2000016)
                                    {
                                        /*disable and default MyWorkshop for Types 2 and 3*/
                                        $scope.asset.UseMyWorkshop=false; //sets to Panel
                                        $scope.IsMyWorkshopDisabled=true;
                                    }
                                    else
                                    {
                                        //$scope.asset.UseMyWorkshop=null;
                                        $scope.IsMyWorkshopDisabled=false;
                                    }

                                }
                                else
                                {
                                    fn.utils.setDefaultCoverage();
                                }

                                $scope.coverages.isForUpdate = true;
                                fn.utils.closeIsSaving();

                                retrieveExcess();
                            },
                            onError: function(){
                                retrieveExcess();
                            }
                        }
                    );
                };

                var retrieveExcess =function(c){
                    /*c==1 is a flag to continue further retrieval. Not needed for inPage changes.*/
                    fn.coverageApi.getVehicleExcess(
                        {
                            onSuccess: function (data) {
                                $scope.selectedCoverage.excess = data;
                                getDatesPartsDD();
                                fn.eventHandlers.sendDefaultExcessGTM();

                            },
                            onError: function(){
                                getDatesPartsDD();
                            }
                        }
                    );
                };

                var getDatesPartsDD = function () {
                    fn.wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },

                        onError: function () {
                            getDatesPartsMM();
                        }
                    });
                };

                var getDatesPartsMM = function () {
                    fn.wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;
                            getDatesPartsYY();
                        },

                        onError: function () {
                            getDatesPartsYY();
                        }
                    });
                };

                var getDatesPartsYY = function () {
                    fn.wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            //$scope.staticData.datesYearsPolicy = [data[0], {"key":data[0]["key"] + 1, "value":data[0]["value"] + 1, "valueTH":data[0]["valueTH"] + 1}];
                            getDatesPartsFullYY();
                        },

                        onError: function () {
                            getDatesPartsFullYY();
                        }
                    });
                };

                var getDatesPartsFullYY = function () {
                    fn.wfeApiStaticData.getDatesPartsFullYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYearsPolicy = [data[0], {"key":data[0]["key"] + 1, "value":data[0]["value"] + 1, "valueTH":data[0]["valueTH"] + 1}];
                            retrievePHContact();
                        },

                        onError: function () {
                            retrievePHContact();
                        }
                    });
                };

                var retrievePHContact = function() {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                // Call UW falcon API here.
                                fn.postUWFC();
                                $scope.loadingSpinner = false;
                                //retrievePolicyHolder();
                            },
                            onError: function(){
                                $scope.loadingSpinner = false;
                                //retrievePolicyHolder();
                            }
                        }
                    );
                };

                //retrieveCoverages();
                retrievePolicyHolder();
            },

            retrieveNamedDrivers: function () {

                fn.namedDriversApi.retrieveNDPolicyContacts(
                    {
                        onSuccess: function (dataArray) {

                            if (!dataArray) {
                                return;
                            }

                            angular.forEach(dataArray,
                                function (data) {
                                    data.isForUpdate = true;
                                }
                            );

                            /** Need to enable this condition for VPP */
                            if($scope.asset.PlanId==1000003 && dataArray.length > 0){
                                $scope.plan.coversNamed=1;
                                $scope.plan.coversNamedWho=0;
                                $scope.addNamedDriver = true;
                            }
                            /** End of condition */

                            $scope.namedDrivers.PolicyContacts.splice(0);
                            angular.extend($scope.namedDrivers.PolicyContacts, dataArray);
                            angular.forEach($scope.namedDrivers.PolicyContacts,
                                function(obj){
                                    if (obj.DateOfBirth)
                                    {
                                        fn.utils.ConvertNDMITDateToDisplay(obj);
                                    }
                                    if (obj.Identifier) {
                                        HKFormValidation.splitIdentifier(obj,obj);
                                        /*To solve some problem on policyHolder scope*/
                                    }
                                }
                            );
                            $scope.isEditing = true;
                            if ($scope.namedDrivers.PolicyContacts.length) {
                                $scope.isEditing = false;
                            }
                            marketing.dLCE(100,'');


                        },
                        onError: function(){
                        }
                    }
                );
            },

            navigation: {
                goToNextStep: function () {
                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(4);
                    
                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {IsSelected : true})[0];
                    $sessionStorage.noOfPTSave = $sessionStorage.noOfPTSave + 1;
                    var goNext = function(){
                        fn.utils.setIsSaving(false);
                        try {
                            marketing.ga();
                        } catch (err) {
                            console.log(err);
                        }
                        $state.go('public.motor.quote.steps.quote');
                    }

                    var defaultPriorityJudge = function () {
                        if (selectedCoverage.CoverTypeId === 2000017) {
                            fn.postToMaxParent('No', 4,'N')
                            goNext();
                        } else {
                            fn.postToMaxParent('No', 3,'N')
                            goNext();
                        }
                    }

                    if (selectedCoverage.CoverTypeId === 2000015 && $scope.$parent.ppstEnable) {
                        fn.getPriority(function (priority) {
                            
                                if (priority != 0) {
                                    fn.postToMaxParent('No', priority, 'N');
                                    goNext();
                                } else {
                                    defaultPriorityJudge();
                                }
                        

                        });
                    } else {
                        defaultPriorityJudge();
                    }


                    
                    
                },

                goToPrevStep: function () {
                    fn.utils.setIsSaving(false);
                    $state.go('public.motor.quote.steps.cover');
                }
            },

            saving: {
                getSaveFunctionName: function (model, functionName) {
                    var saveFunc = model.isForUpdate ? 'update' : 'create';
                    return saveFunc + functionName;
                },

                getSaveFunctionNameContact: function (model, functionName) {
                    if (model.isForUpdate && model.isForDelete) {
                        return 'deletePolicyContact';
                    }
                    else if (model.isForDelete) {
                        return null;
                    }

                    if (model.isForUpdate) {
                        return 'updatePolicyContact';
                    }

                    return 'create' + functionName;
                },

                savePHContact: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                //if ($scope.$parent.isLoggedIn())
                                if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    fn.confirmationApi.createConfirmation(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                DaModal.close('#quotationPartialSave');
                                                $scope.$parent.divertAfterSECB();
                                            },
                                            onError: function(){
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                } else {
                                    fn.essentialApi.partialSave(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                DaModal.close('#quotationPartialSave');
                                                $scope.$parent.divertAfterSECB();
                                            },
                                            onError: function(){
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                }
                                fn.postToMaxParent('No',3,'Y')
                            },
                            onError: function(){
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                saveModels: function (callbacks) { //missing checkuser ?

                    var triggerSave = function () {
                        updateBILog();
                    };

                    var updateBILog = function () {
                        try {
                            if (!$scope.currentProposal.IsRenewalProposal) {
                                fn.logApi.writeLog();
                            }
                        } catch (err) {
                        }

                        savePHContactArrays();
                    };

                    var savePHContactArrays = function () {
                        var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                        fn.phContactApi[savePHContactFunc](
                            {
                                onSuccess: function () {
                                    savePH();
                                },
                                onError: function () {
                                    hasError = true;
                                    savePH();
                                }
                            }
                        );
                    };

                    var savePH = function () {

                        if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }
                        //console.log($scope.policyHolder.Identifier);
                        var tmpId = $scope.policyHolder.Identifier;
                        if($scope.policyHolder.IsMainDriver){  // PH == MD
                            $scope.policyHolder.ConsentId = 7000000;
                            $scope.mainDriver.ConsentId = 7000000;
                        }else{  // PH != MD
                            $scope.policyHolder.ConsentId = 7000002;
                            $scope.mainDriver.ConsentId = 7000000;
                        }
                        $scope.policyHolder.Identifier = tmpId.replace(/-/g, "");
                        //console.log($scope.policyHolder.Identifier);
                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function () {
                                    saveMD();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveMD();
                                }
                            }
                        );
                    };

                    var saveMD = function () {
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');
                        if (!saveMDFunc) {
                            saveCoverages();
                            return;
                        }

                        fn.mainDriverApi[saveMDFunc](
                            {
                                onSuccess: function () {
                                    saveCoverages();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveCoverages();
                                }
                            }
                        );
                    };

                    var saveCoverages = function () {
                        /* var sc = $filter('filter')($scope.coverages.Coverages, {
                            IsSelected: true
                        }, true); */

                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc](
                            {
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;
                                    saveAsset();
                                },
                                onError: function(){
                                    saveAsset();
                                }
                            }
                        );
                    };

                    var saveAsset = function () {

                        $scope.asset.Coverages = $scope.coverages.Coverages;
                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');
                        fn.assetApi[saveAssetFunc](
                            {
                                onSuccess: function () {
                                    saveCurrentProposal();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveCurrentProposal();
                                }
                            }
                        );
                    };
                    
                    var saveCurrentProposal = function () {
                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                        fn.currentProposalApi[saveCurrentProposalFunc](
                            {
                                onSuccess: function (data) {
                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess(data);
                                    }
                                },
                                onError: function () {
                                    $scope.hasError = true;
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },

                updatePlan: function (callbacks) { //missing checkuser ?

                    var triggerUpdatePlan = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        /* var sc = $filter('filter')($scope.coverages.Coverages, {
                            IsSelected: true
                        }, true); */

                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc](
                            {
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;
                                    saveAsset();
                                },
                                onError: function(){
                                    saveAsset();
                                }
                            }
                        );
                    };

                    var saveAsset = function () {

                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');
                        fn.assetApi[saveAssetFunc](
                            {
                                onSuccess: function (data) {
                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess(data);
                                    }
                                },
                                onError: function () {
                                    $scope.hasError = true;
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerUpdatePlan();
                },

                /** Doesn't use now */
                saveAccessories: function () {
                    var selectedCovers = $filter('filter')($scope.selectedCoverage.Covers, {
                        IsSelected: true,
                        ProductLineType: 2
                    });

                    $scope.accessoriesCover = {};
                    var accessoriesCover = $filter('filter')($scope.selectedCoverage.Covers, {
                        CoverName: 'Accessories'
                    })[0];
                    if (accessoriesCover) {
                        $scope.accessoriesCover = accessoriesCover;
                        $scope.accessoriesCover.IsSelected = false;
                    }

                    if (selectedCovers.length > 0) {
                        angular.forEach(selectedCovers, function (item) {
                            if (fn.utils.isAccessories(item.ProductLineGroupId)) {
                                $scope.accessoriesCover.IsSelected = true;
                            }
                        });
                    }
                },

                saveNamedDriversModel: function (callbacks) {
                    var hasError = false;
                    var namedDrivers = [].concat($scope.namedDrivers.PolicyContacts);

                    var saveNextDriver = function () {
                        var nextNamedDriver = namedDrivers.pop();

                        if (nextNamedDriver) {
                            saveNamedDriver(nextNamedDriver);
                            return true;
                        }

                        if (callbacks && callbacks.onSuccess) {
                            callbacks.onSuccess();
                        }
                        else if (hasError) {
                            if (callbacks && callbacks.onError) {
                                callbacks.onError();
                            }
                        }

                        fn.retrieveNamedDrivers();

                        return false;
                    };

                    var saveNamedDriver = function (namedDriver) {
                        if(!namedDriver.RelationshipIdWithPH)
                        {
                            namedDriver.RelationshipIdWithPH=1000007;
                        }


                        var saveFunc = fn.saving.getSaveFunctionNameContact(namedDriver, 'NDPolicyContact');

                        if (!saveFunc) {
                            saveNextDriver();
                            return;
                        }

                        var namedDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, namedDriver);

                        namedDriverApi[saveFunc](
                            {
                                onSuccess: function () {
                                    namedDriver.isForUpdate = true;

                                    saveNextDriver();
                                },

                                onError: function () {
                                    saveNextDriver();
                                    hasError = true;
                                }
                            }
                        );
                    };

                    saveNextDriver();

                },

                saveSingleNamedDriverModel: function (callbacks) {
                    var hasError = false;
                    if($scope.namedDriver.Identifier){
                        if($scope.namedDriver.ResidentialStatusId == 2000000 || $scope.namedDriver.ResidentialStatusId ==='2000000')
                            $scope.namedDriver.Identifier=$scope.namedDriver.Identifier.replace(/[^0-9\.]+/g, "");
                    }
                    var namedDriver = $scope.namedDriver;


                    var cb = function () {

                        if (callbacks && callbacks.onSuccess) {
                            callbacks.onSuccess();
                        }
                        else if (hasError) {
                            if (callbacks && callbacks.onError) {
                                callbacks.onError();
                            }
                        }

                        fn.retrieveNamedDrivers();

                        return false;
                    };

                    if(!namedDriver.RelationshipIdWithPH)
                    {
                        namedDriver.RelationshipIdWithPH=1000007;
                    }

                    var saveFunc = fn.saving.getSaveFunctionNameContact(namedDriver, 'NDPolicyContact');

                    if (!saveFunc) {
                        cb();
                        return;
                    }

                    var namedDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, namedDriver);

                    namedDriverApi[saveFunc](
                        {
                            onSuccess: function () {
                                namedDriver.isForUpdate = true;

                                cb();
                            },

                            onError: function () {
                                cb();
                                hasError = true;
                            }
                        }
                    );

                },

                calculatePremium: function () {
                    fn.quoteApi.calculate(
                        {
                            onSuccess: function(){
                                fn.retrieveScopeObjects();
                                fn.utils.setIsSaving(false);
                            },
                            onError: function(){
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                calculatePremiumCarCamera: function (callbacks) {
                    fn.quoteApi.calculate(callbacks);
                },

                savePHContactCallback: function () {

                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                    var callMaxar= function(){

                        DaModal.close('#quotationCallback');
                        try{
                            $scope.$parent.gaEvents(3,3);
                        }
                        catch(e){}

                        $scope.$parent.maxarCallbackTrigger = true;
                        fn.postToMaxParent('Yes',1,'Y');
                    };

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                //if ($scope.$parent.isLoggedIn())
                                if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    fn.confirmationApi.createConfirmation(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                callMaxar();
                                            },
                                            onError: function () {
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                } else {
                                    fn.essentialApi.partialSave(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                callMaxar();
                                            },
                                            onError: function () {
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                }
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                calculatePremiumCallBack: function (callbacks) {
                    fn.quoteApi.calculate(callbacks);
                },

                saveCoveragesModel: function (callbacks) {

                    var triggerSave = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc](
                            {
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;

                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },

            },

            validations: {
                isTHIDValidPH: function () {
                    if($scope.policyHolder.Identifier)
                        return HKFormValidation.validateTHID($scope.policyHolder.Identifier);

                },

                isTHIDValidMD: function (){
                    if($scope.mainDriver.Identifier)
                        return HKFormValidation.validateTHID($scope.mainDriver.Identifier);
                },

                isTHIDValidND: function () {
                    if($scope.namedDriver.Identifier)
                        return HKFormValidation.validateTHID($scope.namedDriver.Identifier);

                },

                checkDrivingExp:function(){
                    /*lessThanTwoDrivingExp*/
                    if($scope.mainDriver.DrivingExperienceId)
                    {
                        if($scope.mainDriver.DrivingExperienceId==1 ||$scope.mainDriver.DrivingExperienceId=="1"
                        || $scope.mainDriver.DrivingExperienceId==2 ||$scope.mainDriver.DrivingExperienceId=="2")
                        {
                            //Less than 2 driving experiencee.
                            $scope.frmAboutMD.mainDriverDrivingExp.$setValidity('lt2dexp', false);
                            return;
                        }
                    }
                    $scope.frmAboutMD.mainDriverDrivingExp.$setValidity('lt2dexp', true);
                },

                checkDrivingExpND:function(){
                    $scope.DrivingExperienceError = false;
                    if($scope.namedDriver.DrivingExperienceId)
                    {
                        if($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2" )
                        {
                            //Less than 2 driving experiencee.
                            $scope.DrivingExperienceError = true;
                            return;
                        }
                    }
                    $scope.namedDriver.DrivingExperienceError = false;
                },

                checkNoOfAcc:function(){
                    /*lessThanTwoDrivingExp*/
                    if($scope.mainDriver.NrOfClaimsInPeriodId)
                    {
                        if( $scope.mainDriver.NrOfClaimsInPeriodId==1000005 ||$scope.mainDriver.NrOfClaimsInPeriodId=="1000005"
                            ||$scope.mainDriver.NrOfClaimsInPeriodId==1000013 ||$scope.mainDriver.NrOfClaimsInPeriodId=="1000013"
                            || $scope.mainDriver.NrOfClaimsInPeriodId==1000014 ||$scope.mainDriver.NrOfClaimsInPeriodId=="1000014"
                            || $scope.mainDriver.NrOfClaimsInPeriodId==1000007 ||$scope.mainDriver.NrOfClaimsInPeriodId=="1000007")
                        {
                            //Less than 2 driving experiencee.
                            $scope.frmAboutMD.accidentsInThreeYearsMD.$setValidity('noOfAcci', false);
                            return;
                        }
                    }
                    $scope.frmAboutMD.accidentsInThreeYearsMD.$setValidity('noOfAcci', true);
                },

                checkNoOfAccND:function(nd,formElement){
                    /*lessThanTwoDrivingExp*/
                    if(nd.NrOfClaimsInPeriodId)
                    {
                        if(nd.NrOfClaimsInPeriodId==1000005 || nd.NrOfClaimsInPeriodId=="1000005"
                            || nd.NrOfClaimsInPeriodId==1000013 ||nd.NrOfClaimsInPeriodId=="1000013"
                            || nd.NrOfClaimsInPeriodId==1000014 ||nd.NrOfClaimsInPeriodId=="1000014"
                            || nd.NrOfClaimsInPeriodId==1000007 ||nd.NrOfClaimsInPeriodId=="1000007"
                            || nd.NrOfClaimsInPeriodId==1000007 ||nd.NrOfClaimsInPeriodId=="1000007")
                        {
                            /*Less than 2 driving experience.*/
                            DaModal.open("#NoofAccidents");
                            formElement.$setValidity('noOfAcci', false);
                            return;
                        }
                    }
                    formElement.$setValidity('noOfAcci', true);
                },

                validateNamedDriver: function (ndDriverLocal) {
                    if (CountryMotorQuoteValidation.isInvalidAccidentClaims(ndDriverLocal.NrOfClaimsInPeriodId) ||
                        CountryMotorQuoteValidation.isInvalidYearsOfExperience(ndDriverLocal.DrivingExperienceId) ||
                        CountryMotorQuoteValidation.isInvalidDriverAge(ndDriverLocal.DateOfBirth, $scope.currentProposal.PolicyStartDate) ||
                        CountryMotorQuoteValidation.isInvalidOccupation(ndDriverLocal.OccupationId)) {
                        return true;
                    } else {
                        return false;
                    }
                },

                validateForms: function () {
                    return $scope.frmAboutPH.$invalid ||
                        $scope.frmCarDetails.$invalid || $scope.frmAboutMD.$invalid;
                },

                validateNDForms: function (frmAboutNamedDrivers) {
                    return frmAboutNamedDrivers.$invalid;
                },

                hasInvalid: function(){

                    var isInvalid = false;
                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }
                    else if($scope.asset.IsCarModified){
                        isInvalid = true;
                        DaModal.open('#modifiedWarning');
                    }
                    else if($scope.asset.IsCommercialPurpose){
                        isInvalid = true;
                        DaModal.open('#usedForCommercial');
                    }
                    else if(!fn.validations.isTHIDValidPH() && $scope.policyHolder.ResidentialStatusId == 2000000){
                        //$scope.frmAboutPH.IdentifierCardPH.$setValidity('da-thid', false);
                        fn.gtAnchor('#IdentifierCardPH');
                        isInvalid = true;
                    }
                    else if(!fn.validations.isTHIDValidMD() && $scope.mainDriver.ResidentialStatusId == 2000000){
                        fn.gtAnchor('#IdentifierCardMD');
                        //$scope.frmAboutMD.IdentifierCardMD.$setValidity('da-thid', false);
                        isInvalid = true;
                    }

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;
                },

                hasInvalid2: function hasInvalid2() {
                    var isInvalid = false;
                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    } else if ($scope.namedDrivers &&
                        $scope.namedDrivers.PolicyContacts &&
                        $scope.namedDrivers.PolicyContacts.length > 0) {
                        var ndList = $scope.namedDrivers.PolicyContacts;
                        for (var q = 0; q < ndList.length; q++) {
                            var ndLocal = ndList[q];
                            if (fn.validations.validateNamedDriver(ndLocal) === true) {
                                isInvalid = true;
                                $scope.$parent.ndError = ndLocal;
                                DaModal.open('#nameDriverValidation');
                            }
                        }
                    } else if ($scope.namedDriver && $scope.namedDriver.DateOfBirth) {

                        var ndNewLocal = $scope.namedDriver;
                        if (fn.validations.validateNamedDriver.validateNamedDriver(ndNewLocal) === true) {
                            isInvalid = true;
                            $scope.ndError = ndNewLocal;
                            DaModal.open('#nameDriverValidation');
                        }
                    }

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;
                },

            },

            eventHandlers: {
                popModifiedYeswarning:function(){
                    if($scope.asset.IsCarModified==true)
                    DaModal.open("#modifiedWarning");
                },
                onIsCommercialPurposeClicked: function() {
                    if($scope.asset.IsCommercialPurpose){
                        DaModal.open('#usedForCommercial');
                    }
                },
                sendDefaultExcessGTM:function()
                {
                    if(!$scope.selectedCoverage) { return;}
                    try{
                        if($scope.selectedCoverage.CoverTypeId!= 2000020) {
                            if($scope.selectedCoverage.excess && $scope.selectedCoverage.ExcessLevelId) {
                                var exc = $filter('filter')($scope.selectedCoverage.excess, {
                                    key: $scope.selectedCoverage.ExcessLevelId,

                                }, true);
                                if(exc && exc[0].value)
                                    $scope.dLCE(12, exc[0].value);
                                else
                                    $scope.dLCE(12,'NA');
                            } else {
                                $scope.dLCE(12,'NA');
                            }
                        } else { //Compulsory
                            $scope.dLCE(12,'NA');
                        }

                    }
                    catch(e) {
                        console.log(e);
                    }
                },

                setPlan:function()
                {

                    /** Default login for VPP */
                    /*if($scope.plan.coversNamed==0){$scope.asset.PlanId=1000003; return;}
                    else {
                        if($scope.plan.coversNamedWho==0){$scope.asset.PlanId=1000005; return;}
                        else {
                            if($scope.plan.coversNamedWho==1){$scope.asset.PlanId=1000004; return;}
                        }
                    }*/

                    /** VPP: New setPlan condition for VPP */
                    if($scope.plan.coversNamed==0){
                        $scope.asset.PlanId = 1000003;
                    } else {
                        //if($scope.plan.coversNamedWho==0){$scope.asset.PlanId=1000005; return;}
                        if($scope.plan.coversNamedWho==0){

                            var age = fn.utils.getAge($scope.policyHolder.DateOfBirth);
                            if(age < 30){
                                $scope.addNamedDriver = false;
                                $scope.asset.PlanId=1000004;
                            }else{
                                $scope.addNamedDriver = true;
                                $scope.asset.PlanId=1000003;
                            }
                        }else if($scope.plan.coversNamedWho==1){
                            $scope.asset.PlanId=1000004;
                            $scope.addNamedDriver = false;
                        }
                    }

                    // Set PlanID to parent
                    $scope.$parent.PlanId = $scope.asset.PlanId;
                    
                    /** VPP: New setPlan condition for VPP */
                    fn.utils.setIsSaving(true);
                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.calculatePremiumCallBack(
                                {
                                    onSuccess: function () {
                                        fn.retrieveScopeObjects(
                                            {
                                                onSuccess: function (data) {
                                                    fn.utils.setIsSaving(false);
                                                },
                                                onError: function(){

                                                }
                                            }
                                        );
                                        /* fn.coverageApi.retrieveCoverages(
                                            {
                                                onSuccess: function (data) {

                                                    
                                                    fn.utils.setIsSaving(false);
                                                    //$scope.coverages = {};
                                                    /* angular.extend($scope.coverages, data);
                                                    var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true})[0];
                    
                                                    if (selectedCoverage) {

                                                        console.log(selectedCoverage);
                                                    } 
                    
                                                },
                                                onError: function(){
                                                }
                                            }
                                        ); */
                                        fn.utils.setIsSaving(false);
                                    },
                                    onError: function () {
                                        fn.utils.setIsSaving(false);
                                    }
                                });
                        },
                        onError: function(){
                            fn.utils.setIsSaving(false);
                        }
                    };

                    fn.saving.saveModels(callbacks);

                },

                onBtnCallbackClicked :function(){
                    fn.saving.saveModels(
                        {
                            onSuccess: function () {
                                DaModal.open('#quotationCallback');
                            },
                            onError: function () {
                            }
                        }
                    );
                },

                onBtnCallbackContinueClicked: function () {

                    $scope.pageSubmitted = true;
                    $scope.callBackSubmitted = true;

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    if ($scope.policyHolder.FirstName.invalid ||$scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }

                    fn.saving.savePHContactCallback();

                },

                onBtnCallbackCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        $scope.phContact.PrimaryPhoneNumber = "";
                    }

                    DaModal.close('#quotationCallback');
                },

                onBtnPartialSaveContinueClicked: function () {
                    $scope.pageSubmitted = true;
                    $scope.partialSaveSubmitted = true;
                    if ($scope.phContact.PrimaryEmailAddress.invalid) {
                        return;
                    }
                    fn.saving.savePHContact();
                },

                onBtnSaveAndExitClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,2);
                    }
                    catch(e){}
                    fn.saving.saveModels(
                        {
                            onSuccess: function () {
                                DaModal.open('#quotationPartialSave');
                            },
                            onError: function () {

                            }
                        }
                    );
                },

                onBtnPartialSaveCancelClicked: function () {
                    DaModal.close('#quotationPartialSave');
                },

                onBtnNextClicked: function () {
                    
                    try{
                        $scope.$parent.gaEvents(0,0);
                    }
                    catch(e){}
                    $scope.pageSubmitted = true;
                    if (fn.validations.hasInvalid()) {
                        return;
                    }

                    //console.log($scope.frmAboutNamedDriver);
                    if($scope.isNDModalOpen){
                        DaModal.open("#isNDformOpened");
                        return
                        
                    }
                    /* if ($scope.isStartDateInvalid()) {
                        return;
                    } */
                    fn.utils.setIsSaving(true);
                    

                    /** VPP: To remove all ND when plan has been changed to flexible one. IDIT will hit error of flexible plan with ND has endorsement*/
                    angular.forEach($scope.namedDrivers.PolicyContacts,
                        function(obj) {
                            if($scope.asset.PlanId==1000004){
                                $scope.namedDriver=obj;

                                $scope.namedDriver.isForDelete = true;

                                var callbacks = {
                                    onSuccess: function () {
                                        $scope.namedDriver={};
                                    }
                                };

                                fn.saving.saveSingleNamedDriverModel(callbacks);
                            }
                        }
                    );
                    /** VPP: To remove all ND when plan has been changed to flexible one. IDIT will hit error of flexible plan with ND has endorsement*/

                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.calculatePremiumCallBack(
                                {
                                    onSuccess: function (data) {
                                        $scope.coverages = data;
                                        fn.navigation.goToNextStep();
                                        // Add one more partial save after calculate.
                                        /* fn.essentialApi.partialSave({
                                            onSuccess: function(){
                                                fn.navigation.goToNextStep();
                                            },
                                            onError: function(){
                                                fn.utils.setIsSaving(false);
                                            }
                                        }); */
                                    },
                                    onError: function () {
                                        fn.utils.setIsSaving(false);
                                    }
                                });
                        },
                        onError: function(){
                            fn.utils.setIsSaving(false);
                        }
                    };

                    //fn.postUWFC();
                    fn.postBLFC();
                    fn.saving.saveModels(callbacks);
                },

                onBtnBackClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){}
                    fn.utils.setIsSaving(true);
                    var callbacks = {
                        onSuccess: fn.navigation.goToPrevStep
                    };
                    fn.saving.saveModels(callbacks);
                },

                onBtnAddClicked: function () {
                    $scope.namedDrivers.PolicyContacts.push({});
                },

                onBtnEditClicked: function () {
                    $scope.isEditing = true;
                },

                onBtnRemoveClicked: function (namedDriver) {
                    namedDriver.isForDelete = true;
                },

                onBtnNDAddClicked:function(){
                    $scope.isNDModalOpen=true;
                    marketing.gaEvents(1);
                },

                onBtnNDCancelClicked:function(frmAboutNamedDriver) {

                    $scope.modalSubmitted=false;
                    $scope.isNDModalOpen=false;
                    $scope.namedDriver={};

                    /** VPP: Add this condition to replace VPP by Flex if ND age is less than 30 */
                    var age = fn.utils.getAge(frmAboutNamedDriver.namedDriverDOBYear.$viewValue + "-" + frmAboutNamedDriver.namedDriverDOBMON.$viewValue + "-" + frmAboutNamedDriver.namedDriverDOBDD.$viewValue);
                    if (age < 30){
                        //$scope.addNamedDriver = false;
                        $scope.asset.PlanId=1000004;
                        $scope.plan.coversNamedWho = 1;
                        $scope.addNamedDriverAgeLessThan30_2nd = true;
                        $scope.addNamedDriverAgeLessThan30 = false;
                        $scope.isEditing = false;
                        return;
                    }else{
                        $scope.addNamedDriverAgeLessThan30_2nd = false;
                    }
                    /** VPP: Add this condition to replace VPP by Flex if ND age is less than 30 */
                },

                onBtnNDSaveClicked: function (frmAboutNamedDriver) {

                    /** VPP: Add this condition to replace VPP by Flex if ND age is less than 30 */
                    /* var age = fn.utils.getAge(frmAboutNamedDriver.namedDriverDOBYear.$viewValue + "-" + frmAboutNamedDriver.namedDriverDOBMON.$viewValue + "-" + frmAboutNamedDriver.namedDriverDOBDD.$viewValue);
                    if (age < 30){
                        //$scope.addNamedDriver = false;
                        $scope.asset.PlanId=1000004;
                        $scope.plan.coversNamedWho = 1;
                        $scope.addNamedDriverAgeLessThan30_2nd = true;
                        $scope.addNamedDriverAgeLessThan30 = false;

                        $scope.isEditing = false;
                        $scope.isNDModalOpen=false;
                        $scope.namedDriver={};
                        isInvalid = true;
                        return;
                    }else{
                        $scope.addNamedDriverAgeLessThan30_2nd = false;
                    } */
                    /** VPP: Add this condition to replace VPP by Flex if ND age is less than 30 */

                    $scope.modalSubmitted = true;
                    var isInvalid = false;
                    if (fn.validations.validateNDForms(frmAboutNamedDriver)) {
                        isInvalid = true;
                    }

                    if(!fn.validations.isTHIDValidND() && $scope.isNDModalOpen && $scope.namedDriver.ResidentialStatusId == 2000000){
                        fn.gtAnchor('#IdentifierCardND');
                        isInvalid = true;
                    }

                    if($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2"  ){
                        isInvalid = true;
                    }

                    if (isInvalid) {
                        return;
                    }

                    $scope.namedDriver.ConsentId = 7000002;  // default as follow up

                    $scope.modalSubmitted = false;
                    var callbacks = {
                        onSuccess: function () {
                            $scope.isEditing = false;
                            $scope.isNDModalOpen=false;
                            $scope.namedDriver={};
                            $scope.modalSubmitted=false;
                        }
                    };

                    fn.saving.saveSingleNamedDriverModel(callbacks);
                    window.location.href = window.location.href + "#named_driver";
                },

                onBtnNamedDriverModalUpdateClicked:function(nd)
                {
                    $scope.namedDriver=nd;
                    fn.eventHandlers.onBtnNDAddClicked();
                },

                onBtnNamedDriverModalRemoveClicked:function(nd,$event)
                {
                    $event.stopPropagation();
                    $scope.showRemoveWarning=true;
                    $scope.choosenND=nd;

                },

                onBtnNamedDriverModalRemoveConfirm: function onBtnNamedDriverModalRemoveConfirm(){
                    var e;
                    $scope.showRemoveWarning=false;
                    $scope.namedDriver=$scope.choosenND;

                    $scope.namedDriver.isForDelete = true;

                    var callbacks = {
                        onSuccess: function () {

                            $scope.namedDriver={};
                            $scope.showRemoveWarning=false;

                        }
                    };

                    fn.saving.saveSingleNamedDriverModel(callbacks);

                    //fn.onBtnNamedDriverModalDeleteClicked($scope.choosenND,e)
                    $scope.choosenND={};

                },

                onBtnNamedDriverModalRemoveCancelled: function onBtnNamedDriverModalRemoveCancelled(){
                    $scope.choosenND={};
                    $scope.showRemoveWarning=false;
                },

                //Start PA ddl
                onPersonalAccidentChanged: function () {
                    fn.utils.setIsSaving(true);

                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function(){
                                        fn.utils.setIsSaving(false);
                                        fn.retrieveScopeObjects();
                                    },
                                    onError: function(){
                                        fn.utils.setIsSaving(false);
                                    }
                                }
                            );
                        }
                    };
                    fn.saving.saveModels(callbacks);
                },

                onBtnConfirmClicked: function (frmAboutNamedDrivers) {
                    $scope.pageSubmitted = true;
                    var isInvalid = false;
                    if (fn.validations.validateNDForms(frmAboutNamedDrivers)) {
                        isInvalid = true;
                    }

                    if (isInvalid) {
                        return;
                    }

                    $scope.pageSubmitted = false;
                    var callbacks = {
                        onSuccess: function () {
                            $scope.isEditing = false;
                        }
                    };

                    fn.saving.saveNamedDriversModel(callbacks);
                },

                onIsPHMDButton: function () {

                    if ($scope.policyHolder.IsMainDriver) {
                        fn.utils.copyPHFieldsToMD();
                    } else {
                        $scope.mainDriver.DOB_DD = null;
                        $scope.mainDriver.DOB_MON = null;
                        $scope.mainDriver.DOB_Year = null;
                        $scope.mainDriver.DateOfBirth=null;
                        $scope.mainDriver.FirstName = null;
                        $scope.mainDriver.LastName = null;
                        $scope.mainDriver.ResidentialStatusId = undefined;
                        $scope.mainDriver.Identifier = null;
                        $scope.mainDriver.GenderId=null;
                        $scope.mainDriver.MaritalStatusId=null;
                        $scope.mainDriver.OccupationId=null;
                    }
                },

                onFocusReEmail: function(){
                    $scope.IsReEmailMismatch = false;
                }
            },

            utils: {

                convertMITDate:function(){
                    if(typeof $scope.currentProposal.PolicyStartDate_DD !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_MON !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_Year !== "undefined"){

                        $scope.$parent.setDateValidity($scope.currentProposal.PolicyStartDate_DD,$scope.currentProposal.PolicyStartDate_MON,$scope.currentProposal.PolicyStartDate_Year,$scope.frmCarDetails.policyStartDateDD);

                        $scope.currentProposal.PolicyStartDate=$scope.$parent.convertDateThreeToOne($scope.currentProposal.PolicyStartDate_DD,$scope.currentProposal.PolicyStartDate_MON,$scope.currentProposal.PolicyStartDate_Year);
                        $scope.currentProposal.PolicyStartDate==null?($scope.currentProposal.PolicyStartDate=''):'';

                        if($scope.currentProposal.PolicyStartDate)
                        {
                            var dateToday = MitDate.newDate();
                            var pdate=MitDate.newDate($scope.currentProposal.PolicyStartDate);
                            var timeDiff = (dateToday - pdate);
                            var diff = Math.ceil(timeDiff / (1000 * 3600 * 24));
                            marketing.dLCE(20,-1*diff);
                        }
                    }
                },

                convertMITDatePH: function(){
                    if(typeof $scope.policyHolder.DOB_DD !== "undefined" &&
                       typeof $scope.policyHolder.DOB_MON !== "undefined" &&
                       typeof $scope.policyHolder.DOB_Year !== "undefined"){

                        $scope.$parent.setDateValidity($scope.policyHolder.DOB_DD,$scope.policyHolder.DOB_MON,$scope.policyHolder.DOB_Year,$scope.frmAboutPH.policyHolderDOBDD);
                        $scope.policyHolder.DateOfBirth=$scope.$parent.convertDateThreeToOne($scope.policyHolder.DOB_DD,$scope.policyHolder.DOB_MON,$scope.policyHolder.DOB_Year);
                        $scope.policyHolder.DateOfBirth==null?($scope.policyHolder.DateOfBirth=''):'';
                        $scope.validations.isValidDriverAge($scope.policyHolder.DateOfBirth,$scope.frmAboutPH.policyHolderDOBDD);
                        $scope.DOBEmpty = false;
                    }else{
                        $scope.policyHolder.DateOfBirth = undefined;
                    }
                },

                convertMITDateMD: function(){
                    if(typeof $scope.mainDriver.DOB_DD !== "undefined" &&
                       typeof $scope.mainDriver.DOB_MON !== "undefined" &&
                       typeof $scope.mainDriver.DOB_Year !== "undefined"){

                        $scope.$parent.setDateValidity($scope.mainDriver.DOB_DD,$scope.mainDriver.DOB_MON,$scope.mainDriver.DOB_Year,$scope.frmAboutMD.mainDriverDOBDD);
                        $scope.mainDriver.DateOfBirth=$scope.$parent.convertDateThreeToOne($scope.mainDriver.DOB_DD,$scope.mainDriver.DOB_MON,$scope.mainDriver.DOB_Year);
                        $scope.mainDriver.DateOfBirth==null?($scope.mainDriver.DateOfBirth=''):'';
                        $scope.validations.isValidDriverAge($scope.mainDriver.DateOfBirth,$scope.frmAboutMD.mainDriverDOBDD);
                        $scope.DOBEmpty = false;
                    }else{
                        $scope.mainDriver.DateOfBirth = undefined;
                    }
                },

                ConvertMITDateToDisplay:function(){
                    if($scope.currentProposal.PolicyStartDate)
                    {
                        var d=$scope.$parent.convertDateOneToThree($scope.currentProposal.PolicyStartDate);
                        if(d!=null || d.length>0)
                        {
                            $scope.currentProposal.PolicyStartDate_DD=d[0];
                            $scope.currentProposal.PolicyStartDate_MON=d[1];
                            $scope.currentProposal.PolicyStartDate_Year=d[2];
                        }
                    }
                },

                ConvertMITDateMDToDisplay:function(){
                    if($scope.mainDriver.DateOfBirth)
                    {
                        var d=$scope.$parent.convertDateOneToThree($scope.mainDriver.DateOfBirth);
                        if(d!=null || d.length>0)
                        {
                            $scope.mainDriver.DOB_DD=d[0];
                            $scope.mainDriver.DOB_MON=d[1];
                            $scope.mainDriver.DOB_Year=d[2];
                        }
                    }
                },

                ConvertMITDatePHToDisplay:function(){
                    if($scope.policyHolder.DateOfBirth)
                    {
                        var d=$scope.$parent.convertDateOneToThree($scope.policyHolder.DateOfBirth);
                        if(d!=null || d.length>0)
                        {
                            $scope.policyHolder.DOB_DD=d[0];
                            $scope.policyHolder.DOB_MON=d[1];
                            $scope.policyHolder.DOB_Year=d[2];
                        }
                    }
                },

                convertNDMITDate:function(nd,formElement){
                    if(!nd) return;

                    if(typeof nd.DOB_DD !== "undefined" &&
                        typeof nd.DOB_MON !== "undefined" &&
                        typeof nd.DOB_Year !== "undefined"){

                        /** VPP: Need to check ND age that are their age is over 30 or not */
                        var age = fn.utils.getAge(nd.DOB_Year + "-" + nd.DOB_MON + "-" + nd.DOB_DD);
                        if(age < 30){
                            $scope.addNamedDriverAgeLessThan30 = true;
                        }else{
                            $scope.addNamedDriverAgeLessThan30 = false;
                        }

                        $scope.$parent.setDateValidity(nd.DOB_DD,nd.DOB_MON,nd.DOB_Year,formElement);
                        nd.DateOfBirth=$scope.$parent.convertDateThreeToOne(nd.DOB_DD,nd.DOB_MON,nd.DOB_Year);
                        nd.DateOfBirth==null?(nd.DateOfBirth=''):'';
                        $scope.$parent.isInvalidDriverAge(nd.DateOfBirth,formElement);
                    }
                },

                ConvertNDMITDateToDisplay:function(nd){
                    if(nd.DateOfBirth)
                    {
                        var d=$scope.$parent.convertDateOneToThree(nd.DateOfBirth);
                        if(d!=null || d.length>0)
                        {
                            nd.DOB_DD=d[0];
                            nd.DOB_MON=d[1];
                            nd.DOB_Year=d[2];
                        }
                    }
                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    $scope.policyHolder.OccupationId = $scope.mainDriver.OccupationId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.IsDemeritPoints = $scope.mainDriver.IsDemeritPoints;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;
                    $scope.policyHolder.FirstName = $scope.mainDriver.FirstName;
                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    $scope.mainDriver.DOB_DD = $scope.policyHolder.DOB_DD;
                    $scope.mainDriver.DOB_MON = $scope.policyHolder.DOB_MON;
                    $scope.mainDriver.DOB_Year = $scope.policyHolder.DOB_Year;
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;
                    $scope.mainDriver.FirstName = $scope.policyHolder.FirstName;
                    $scope.mainDriver.LastName = $scope.policyHolder.LastName;
                    $scope.mainDriver.ResidentialStatusId = $scope.policyHolder.ResidentialStatusId;
                    $scope.mainDriver.Identifier = $scope.policyHolder.Identifier;
                    $scope.mainDriver.NationalityId = $scope.policyHolder.NationalityId;
                },

                bindingPHMD: function(){
                    if($scope.policyHolder.IsMainDriver){
                        fn.utils.copyPHFieldsToMD();
                    }
                },

                setDefaultCoverage: function() {
                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000015})[0];
                    if (selectedCoverage) selectedCoverage.IsSelected = true;

                    if (selectedCoverage.Covers) {
                        if (selectedCoverage.CoverTypeId != 2000020) {

                        var vbc = $filter('filter')(selectedCoverage.Covers, {
                            ProductLineGroupId: 2000103,

                        }, true);
                        if (vbc || vbc[0])
                            $scope.selectedCoverage.ExcessLevelId = vbc[0].ExcessLevelId;
                        }
                    }

                    if(selectedCoverage.CoverTypeId==2000018 || selectedCoverage.CoverTypeId==2000016)
                    {
                        /*disable and default MyWorkshop for Types 2 and 3*/
                        $scope.asset.UseMyWorkshop=false; //sets to Panel
                        $scope.IsMyWorkshopDisabled=true;
                    }
                    else
                    {
                        //$scope.asset.UseMyWorkshop=null;
                        $scope.IsMyWorkshopDisabled=false;
                    }

                },

                /*** Currently use for disable some mandatory fields, however it's possible to remove it */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                setIsSaving: function (isSaving) {
                    if($scope.partialSaveSubmitted){
                        DaModal.open("#daLoadingMaskPartialSave");
                    }else if($scope.callBackSubmitted){
                        DaModal.open("#daLoadingMaskCallBack");
                    }else{
                        $scope.DaLoadingMask.toggle(isSaving);
                    }
                },

                closeIsSaving: function () {
                    if($scope.partialSaveSubmitted){
                        DaModal.close("#daLoadingMaskPartialSave");
                    }else if($scope.callBackSubmitted){
                        DaModal.close("#daLoadingMaskCallBack");
                    }else{
                        DaModal.close("#daLoadingMask");
                    }
                },

                /*** Doesn't use now */
                calAccessoryAddPremblur: function () {
                    fn.utils.setIsSaving(true);
                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.utils.setIsSaving(false);
                                        fn.retrieveScopeObjects();
                                    },
                                    onError: function(){
                                        fn.utils.setIsSaving(false);
                                    }
                                }
                            );
                        }
                    };
                    fn.saving.saveModels(callbacks);
                },

                /*** Doesn't use now */
                sortOptionalCover: function () {
                    var selectedCoverageCovers = $scope.selectedCoverage.Covers;
                    var resultOptionalList = [];
                    resultOptionalList = selectedCoverageCovers;
                    //handle windscreen, though its id is 2000056, it is above accessory
                    if (resultOptionalList) {
                        var windscreenIndex = -1;
                        var accessoryIndex = -1;
                        var lossIndex = -1;
                        for (var i = 0; i < resultOptionalList.length; i++) {
                            if (resultOptionalList[i].ProductLineGroupId === 1000096) {
                                accessoryIndex = i;
                            } else if (resultOptionalList[i].ProductLineGroupId === 2000056) {
                                windscreenIndex = i;
                            } else if (resultOptionalList[i].ProductLineGroupId === 1000093) {
                                lossIndex = i;
                            }
                        }
                        if (windscreenIndex >= 0 && accessoryIndex >= 0) {
                            var temp = resultOptionalList[windscreenIndex];
                            resultOptionalList.splice(windscreenIndex, 1);
                            resultOptionalList.splice(accessoryIndex-1, 0, temp);
                        }
                        for (var j = 0; j < resultOptionalList.length; j++) {
                            if (resultOptionalList[j].ProductLineGroupId === 2000056) {
                                windscreenIndex = j;
                            } else if (resultOptionalList[j].ProductLineGroupId === 1000093) {
                                lossIndex = j;
                            }
                        }
                        if (windscreenIndex >= 0 && lossIndex >= 0) {
                            var windscreenCover = resultOptionalList[windscreenIndex];
                            resultOptionalList.splice(windscreenIndex, 1);
                            resultOptionalList.splice(lossIndex+1, 0, windscreenCover);
                        }
                    }
                    return resultOptionalList;
                },

                /*** Doesn't use now */
                isPersonalAccidentCover: function (productLineGroupId) {
                    var status = false;
                    var accessproductLineGroupId = [1000092];

                    if (accessproductLineGroupId.indexOf(productLineGroupId) >= 0) {
                        status = true;
                    }
                    return status;

                },

                isAccessories: function (productLineGroupId) {
                    var status = false;
                    var accessproductLineGroupId = [1000097, 1000098, 1000099, 1000100, 1000101];

                    if (accessproductLineGroupId.indexOf(productLineGroupId) >= 0) {
                        status = true;
                    }
                    return status;

                },

                /*** Doesn't use now */
                isAccessoriesParent: function (productLineGroupId) {
                    var status = false;
                    var accessproductLineGroupId = [1000096];

                    if (accessproductLineGroupId.indexOf(productLineGroupId) >= 0) {
                        status = true;
                    }
                    return status;
                },

                /*** Doesn't use now */
                filterBaseCover: function (r) {
                    var status = false;
                    if (r.ProductLineGroupId === 1000087) {
                        status = true;
                    }
                    return status;
                },

                /*** Doesn't use now */
                filterOptionalCovers: function (r) {

                    var status = false;
                    if (r.ProductLineType !== '0' && r.ProductLineType !== 0) {
                        status = true;
                    } else {
                        status = false;
                    }
                    return status;

                },

                getAge: function (d) {
                    var today = new Date();
                    var birthDate = new Date(d);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }

                    return age;
                }
            },
            getPriority: function(callback){
                $window.ppstLayer = $window.ppstLayer || $localStorage.ppstLayer;
                var ppstLayer = $window.ppstLayer;
                if($scope.mainDriver.GenderId !== undefined){
                    var gender = $scope.mainDriver.GenderId == 1 ? "FEMALE" : "MALE";
                }else{
                    var gender = $scope.policyHolder.GenderId == 1 ? "FEMALE" : "MALE";
                }
                
                if($scope.mainDriver.MaritalStatusId !== undefined){
                    var marital = $scope.mainDriver.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }else{
                    var marital = $scope.policyHolder.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }
                if($scope.asset.UsageId == 6){
                    var carUsage = "PRIVATE_USE";
                }else if ($scope.asset.UsageId == 1000000){
                    var carUsage = "PRIVATE_USE_AND_COMMUTING_TO_WORK";
                }else if ($scope.asset.UsageId == 1000002){
                    var carUsage = "PRIVATE_AND_BUSINESS_USE";
                }else{
                    var carUsage = "COMMERCIAL_SPECIAL";
                }

                if($scope.mainDriver.DateOfBirth !== undefined){
                    var dob = $scope.mainDriver.DateOfBirth;
                }else{
                    var dob = $scope.policyHolder.DateOfBirth;
                }

                var premium = $scope.$parent.getMonthlyPriceStepCtrl($scope.coverages);

                ppstLayer.push({
                    "Proposal": $scope.currentProposal.ExternalProposalNumber,
                    "PhoneNumber": $sessionStorage.maxarReference,
                    "DateOfBirth": dob,
                    "Gender": gender,
                    "MaritalStatus": marital,
                    "VehicleUsage": carUsage,
                    "MakeID": $scope.asset.ManufactureId,
                    "ModelID": $scope.asset.ModelId,
                    "YearOfRegistration": $scope.asset.PurchasingYear,
                    "CarDescriptionID": $scope.asset.CarDescriptionId,
                    "Premium": premium,
                    "NumberOfDataChanges": $sessionStorage.noOfPTSave
                });

                $localStorage.ppstLayer = ppstLayer;

                $scope.$parent.getPriority(function(res){
                    if(res){
                        callback(res);
                    }else{
                        callback(0);
                    }
                    
                });
            }
        };

        /** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        /** Event Handlers */
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnAddClicked = fn.eventHandlers.onBtnAddClicked;
        $scope.onBtnEditClicked = fn.eventHandlers.onBtnEditClicked;
        $scope.onBtnRemoveClicked = fn.eventHandlers.onBtnRemoveClicked;
        $scope.onBtnConfirmClicked = fn.eventHandlers.onBtnConfirmClicked;

        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;

        $scope.onPersonalAccidentChanged = fn.eventHandlers.onPersonalAccidentChanged;
        $scope.setPlan=fn.eventHandlers.setPlan;

        $scope.onBtnNDAddClicked=fn.eventHandlers.onBtnNDAddClicked;
        $scope.onBtnNDCancelClicked=fn.eventHandlers.onBtnNDCancelClicked;
        $scope.onBtnNDSaveClicked=fn.eventHandlers.onBtnNDSaveClicked;
        $scope.onBtnNamedDriverModalUpdateClicked=fn.eventHandlers.onBtnNamedDriverModalUpdateClicked;
        $scope.onBtnNamedDriverModalRemoveClicked=fn.eventHandlers.onBtnNamedDriverModalRemoveClicked;
        $scope.onBtnNamedDriverModalRemoveCancelled=fn.eventHandlers.onBtnNamedDriverModalRemoveCancelled;
        $scope.onBtnNamedDriverModalRemoveConfirm=fn.eventHandlers.onBtnNamedDriverModalRemoveConfirm;

        /** Utils */
        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;

        $scope.sortOptionalCover = fn.utils.sortOptionalCover;
        $scope.isAccessories = fn.utils.isAccessories;
        $scope.isAccessoriesParent = fn.utils.isAccessoriesParent;
        $scope.isPersonalAccidentCover = fn.utils.isPersonalAccidentCover;
        $scope.calAccessoryAddPremblur = fn.utils.calAccessoryAddPremblur;
        $scope.getAge = fn.utils.getAge;
        $scope.postUWFC = fn.postUWFC;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;

        $scope.onIsPHMDButton=fn.eventHandlers.onIsPHMDButton;
        $scope.convertMITDate=fn.utils.convertMITDate;
        $scope.convertMITDatePH=fn.utils.convertMITDatePH;
        $scope.convertMITDateMD=fn.utils.convertMITDateMD;
        $scope.bindingPHMD = fn.utils.bindingPHMD;
        $scope.convertNDMITDate=fn.utils.convertNDMITDate;
        $scope.isStartDateInvalid=fn.validations.isStartDateInvalid;
        $scope.isTHIDValidPH = fn.validations.isTHIDValidPH;
        $scope.validations = {
            isTHIDValidPH: fn.validations.isTHIDValidPH,
            isTHIDValidMD: fn.validations.isTHIDValidMD,
            isValidDriverAge: $scope.$parent.isInvalidDriverAge,
            isTHIDValidND: fn.validations.isTHIDValidND
        };
        /** Validations */
        $scope.checkDrivingExp=fn.validations.checkDrivingExp;
        $scope.checkDrivingExpND = fn.validations.checkDrivingExpND;
        $scope.checkNoOfAcc=fn.validations.checkNoOfAcc;
        $scope.checkNoOfAccND=fn.validations.checkNoOfAccND;
        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.popModifiedYeswarning=fn.eventHandlers.popModifiedYeswarning;
        $scope.onIsCommercialPurposeClicked=fn.eventHandlers.onIsCommercialPurposeClicked;
        $scope.postBLFC = fn.postBLFC;

        $scope.dLCE=marketing.dLCE;

        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function() {
            $timeout(function(){
                $scope.$parent.changeGlobalContactNo();
            }, 1000);
        });

        $scope.onFocusReEmail = fn.eventHandlers.onFocusReEmail;

    }];