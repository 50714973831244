/*
* Created by Gerald Garcia (29/06/2015) Jun2015
*/
/* global jQuery */

/***
 *
 * @type {*[]}
 */
module.exports = [
    'MITDate',
    /**
     * HKFormValidationService provides utility functions for page validation
     * @returns {{}}
     * @constructor
     */
    function HKFormValidationService(MITDate) {

        var fn = {

            animateToID: function (selector) {
                $(document).ready(function () {
                    jQuery('html,body').animate({
                        scrollTop: $(selector).offset().top - 100
                    }, 1000);
                });

            },


            splitIdentifier: function (data, person) {
                if (data.Identifier && data.ResidentialStatusId) {
                    if (data.ResidentialStatusId === '2000000' || data.ResidentialStatusId == 2000000) { //Thai Resident

                        var id = [];
                        data.Identifier = data.Identifier.replace(/[^0-9\.]+/g, "");
                        id.firstPart = data.Identifier.substr(0, 1) || '';
                        id.secondPart = data.Identifier.substr(1, 4) || '';
                        id.thirdPart = data.Identifier.substr(5, 5) || '';
                        id.fourthPart = data.Identifier.substr(10, 2) || '';
                        id.fifthPart = data.Identifier.substr(12, 1) || '';
                        id.final = id.firstPart + "-" + id.secondPart + "-" + id.thirdPart + "-" + id.fourthPart + "-" + id.fifthPart;
                        person.Identifier = id.final;
                    }

                }

                /*if(data.Identifier.length==8)
                {9-9999-99999-99-9
                    person.PreIdentifier = data.Identifier.substr(0, 1) || '';
                    person.IdentifierNumber = data.Identifier.substr(1, 6) || '';
                    person.PostIdentifier = data.Identifier.substr(7, 1) || '';
                }
                else if(data.Identifier.length==9)
                {
                    person.PreIdentifier = data.Identifier.substr(0, 2) || '';
                    person.IdentifierNumber = data.Identifier.substr(2, 6) || '';
                    person.PostIdentifier = data.Identifier.substr(8, 1) || '';
                }
                else
                {
                    person.PreIdentifier = data.Identifier.substr(0, 1) || '';
                    person.IdentifierNumber = data.Identifier.substr(1, 6) || '';
                    person.PostIdentifier = data.Identifier.substr(7, 1) || '';
                }*/

            },


            validatePreviousNcd: function ($scope, formElement) {
                var ncdStaticId = $scope.asset.PrevNcdLevelId;
                if (!$scope.asset.NcdLevelId) {
                    return true;
                }
                var renewNcdPrevNcdMap = {
                    '1000003': '1000003,1000005,1000006,1000007,1000008,1000004',
                    '1000005': '1000003,1000005,1000008',
                    '1000006': '1000005,1000006,1000004',
                    '1000007': '1000006,1000007',
                    '1000008': '1000007,1000008',
                    '1000004': '1000008,1000004'
                };
                var allowedNclString = renewNcdPrevNcdMap[$scope.asset.NcdLevelId];
                var result = ncdStaticId === undefined || allowedNclString.indexOf(ncdStaticId) >= 0;
                if (formElement) {
                    formElement.$setValidity('prevNcd', result);
                }
                return !result;
            },
            focusOnError: function () {
                fn.focusOnElement('.form-control.ng-invalid, ' +
                    'input[type=\'checkbox\'].ng-invalid, ' +
                    'input[type=\'radio\'].ng-invalid');
            },

            focusOnElement: function (selector) {
                var element = jQuery(selector).eq(0);

                if (element[0] && element.css('display').toLowerCase() === 'none') {
                    element.show();
                    element.focus();
                    element.hide();
                    return;
                }

                element.focus();
            },

            isValidHKIDOnForm: function (frmPreId, frmSerial, frmChecksum,
                prefix, serial, checksum) {
                var isValid = false;

                isValid = fn.validateHKID(prefix, serial, checksum);

                fn.setValidity('custom-hkid', frmPreId, isValid);
                fn.setValidity('custom-hkid', frmSerial, isValid);
                fn.setValidity('custom-hkid', frmChecksum, isValid);

                return isValid;

            },

            isValidTHIDOnForm: function (frmPreId, frmSerial, frmChecksum,
                idNumber) {
                var isValid = false;

                isValid = fn.validateTHID(idNumber);

                return isValid;

            },

            setValidity: function (validationGrpName, frmField, isFieldValid) {
                if (frmField && frmField.$setValidity) {
                    frmField.$setValidity(validationGrpName, isFieldValid);
                }
            },

            validateTHID: function (idNumber) {
                //console.log("aa" + idNumber + "cc");
                var isValid = true;

                idNumber = idNumber.toString();
                idNumber = idNumber.replace(/[^0-9\.]+/g, "");



                if (idNumber.length < 13 || idNumber.length > 13) {
                    isValid = false;
                    return false;
                }

                if (/^\d{13}$/.test(idNumber) == false) {
                    isValid = false;
                }

                var total = 0;
                var index = 0;
                var multi = 13;

                while (index < 12) {
                    var ch = idNumber.substr(index, 1);
                    total += ch * multi;

                    index++;
                    multi--;
                }

                var remainder = 11 - (total % 11);
                var temp = String(remainder);
                temp = temp.substr((temp.length - 1), 1);

                if (temp == idNumber.substr(12, 1)) {
                    isValid = true;
                }
                else {
                    isValid = false;
                }

                return isValid;
            },

            //build fail, rebuild
            validateHKID: function (prefix, serial, checksum) {
                prefix = prefix.trim();
                var isValid = false;


                if (
                    (prefix.length === 1 || prefix.length === 2) &&
                    serial.length === 6 &&
                    checksum.length === 1
                ) {
                    var prefixU = prefix.toUpperCase();
                    var preInx = 0;
                    var value = 0;

                    // 2 character prefix
                    if (prefixU.length === 2) {
                        value += (prefixU.charCodeAt(0) - 55) * 9 + (prefixU.charCodeAt(1) - 55) * 8;
                        preInx = 2;
                    }

                    // 1 character prefix
                    else if (prefixU.length === 1) {
                        value += 36 * 9 + (prefixU.charCodeAt(0) - 55) * 8;
                        preInx = 1;
                    }

                    for (var i = 0; i < 6; i++) {
                        value += (serial.charCodeAt(i) - 48) * (7 - i);
                    }

                    var reminder = value % 11;
                    var valid_checkdigit = reminder === 0 ? reminder : 11 - reminder;

                    if (valid_checkdigit.toString() === checksum) {
                        isValid = true;
                    }
                    else if (valid_checkdigit === 10 && 'A' === checksum.trim().toUpperCase()) {
                        isValid = true;
                    }
                }

                return isValid;
            },

            isValidAge: function isValidAge(minAge, maxAge, DOB, dateToCompare) {

                if (!minAge && minAge !== 0 || !maxAge || !DOB) {
                    return false;
                }

                var isValid = true;
                if (minAge || minAge === 0 && maxAge && DOB) {
                    var dob = MITDate.newDate(DOB);

                    var current_date;
                    if (dateToCompare) {
                        current_date = MITDate.newDate(dateToCompare);
                    }
                    if (!current_date) {
                        current_date = MITDate.newDate();
                    }


                    var difference = current_date.diff(dob, 'years');

                    if (minAge > difference || difference > maxAge) {
                        isValid = false;
                    }

                }
                return isValid;

            }

        };
        this.animateToID = fn.animateToID;
        this.splitIdentifier = fn.splitIdentifier;
        this.validateHKID = fn.validateHKID;
        this.validateTHID = fn.validateTHID;
        this.setValidity = fn.setValidity;
        this.isValidHKIDOnForm = fn.isValidHKIDOnForm;
        this.isValidTHIDOnForm = fn.isValidTHIDOnForm;
        this.focusOnError = fn.focusOnError;
        this.isValidAge = fn.isValidAge;
        this.validatePreviousNcd = fn.validatePreviousNcd;
    }];