/*
 * Created by Gerald Garcia on 29/06/15.
 */

/***
 * The common same height button container
 * @type {*[]}
 */
module.exports = [

    function sameHeightButtonContainer() {



            function link($scope,element) {


                $scope.$on('ngRepeatFinished',function(){
                    var buttons = element.find('.eqHtCtlClass');
                    var highestHeight = 0;
                    for(var q=0;q<buttons.length;q++){
                        var buttonLocalHt=buttons[q].offsetHeight;
                        if(buttonLocalHt>highestHeight){
                            highestHeight = buttonLocalHt;
                        }
                    }
                    $scope.eqHtCtl={
                        height :highestHeight+'px'
                    };
                });

            }

        return {
            restrict: 'AE',
            link: link
        };
    }];