module.exports = [
    '$anchorScroll',
    '$scope',
    '$window',
    '$document',
    '$http',
    '$filter',
    'ConfigUtils',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiContact',
    'WfeApiPayment',
    '$localStorage',
    'AppConstants',
    function B2CConfirmPaymentController($anchorScroll, $scope, $window, $document, $http, $filter,
                                      ConfigUtils,
                                      WfeApiCurrentProposal, WfeApiAsset, WfeApiContact, WfeApiPayment, $localStorage, AppConstants) {
        $anchorScroll();

        var marketing = {
            ga: function(productId){
                if(productId == AppConstants.AGGConfig.PRODUCT_ID_DA) {
                    $window.dataLayer = $window.dataLayer || [];
                    var dataLayer = $window.dataLayer;
                    dataLayer.push({
                        'event':'content-view',
                        'category': 'B2CPortal',
                        'virtualPageURL':'/b2c/confirm-payment/step-7/'}
                    );
                }
            },

            googleAdwords: function (revenue, productId) {
                $window.google_trackConversion({
                    google_conversion_id: 1015783185,
                    google_conversion_language: 'en',
                    google_conversion_format: '2',
                    google_conversion_color: 'ffffff',
                    google_conversion_label: ConfigUtils.marketing[productId].googleConversionLabel,
                    google_conversion_value: revenue,
                    google_conversion_currency: 'THB',
                    google_remarketing_only: false
                });
            },


            googleECommerce: function (quoteNo, revenue, productId, selectedCoverage) {

                var opt = {
                    url: '/app/ui/lib/marketingtracking/product.skucode.json',
                    method: 'GET'
                };

                $http(opt)
                    .success(function (jsonResponse) {
                        var coverItemInfoList = jsonResponse.root.coveriteminfo;

                        var optionalCovers = $filter('filter')(selectedCoverage.Covers, {
                            IsSelected: true,
                            ProductLineType: 2
                        });

                        var optionalCoversButWithRecommendedFlag = $filter('filter')(selectedCoverage.Covers, {
                            ProductLineGroupId: 2000116,
                            ProductLineType: 1
                        }); //refers to MyWorkshop- which has ProductLineType 1 but is optional.

                        var coverItemInfoBasic = $filter('filter')(coverItemInfoList, {
                            id_product: productId,
                            id_cover: selectedCoverage.CoverTypeId
                        })[0];

                        if (!coverItemInfoBasic) {
                            return;
                        }

                        var totalpremium = 0;
                        var transProds=[];

                        angular.forEach(selectedCoverage.Covers, function (cover) {
                            if (cover.ProductLineType === 1 && (cover.ProductLineGroupId === 2000103 )) {
                                if (cover.TotalPremiumToColl) {
                                    totalpremium = totalpremium + cover.TotalPremiumToColl;
                                }
                            }
                        });

                        var name = coverItemInfoBasic.tx_ga_category;
                        var basic_sku = coverItemInfoBasic.tx_ga_sku_code;

                            if (optionalCovers.length > 0 || optionalCoversButWithRecommendedFlag.length>0) {
                                name = name + ' w Options';
                            }

                        transProds.push(
                            {
                                'sku':basic_sku,
                                'name':name,
                                'category': coverItemInfoBasic.tx_ga_category,
                                'price':totalpremium,
                                'quantity':1
                            }
                        );

                        if (optionalCovers.length > 0) {
                            angular.forEach(optionalCovers, function (cover) {
                                var coverItemInfoBenefit = $filter('filter')(coverItemInfoList, {
                                    id_product: productId,
                                    id_sub_cover: cover.ProductLineGroupId
                                })[0];

                                if (coverItemInfoBenefit) {
                                    transProds.push(
                                        {
                                            'sku':coverItemInfoBasic.tx_ga_sku_code + '_' + coverItemInfoBenefit.tx_ga_sku_code,
                                            'name':coverItemInfoBasic.tx_ga_category + ' Optional Benefits',
                                            'category':  coverItemInfoBasic.tx_ga_category,
                                            'price':cover.TotalPremiumToColl,
                                            'quantity':1
                                        }
                                    );
                                }
                            });
                        }

                        $window.dataLayer = $window.dataLayer || [];
                        var dataLayer = $window.dataLayer;

                        dataLayer.push({
                                'event':'transaction',
                                'transactionId': quoteNo + '_TH',
                                'transactionAffiliation': 'www.directasia.co.th',
                                'transactionTotal': revenue,
                                'transactionTax': 0,
                                'transactionShipping': 0,
                                'transactionProducts':transProds
                            }
                        );

                    })
                    .error(function (errorResponse) {
                        console.error(errorResponse);
                    });
            }
        };

        var fn = {
            postToMaxParent:function(){
                $window.maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;
                var maxarLayer = $window.maxarLayer;
                maxarLayer.push( {

                    sale:'Y',
                    type:'Quote lead',
                    sourcePage:'Buy',
                    callback:'No',
                    priority:1,
                    dataFinal:'Y',

                });

                $scope.$parent.postMaxar();
            },
            printWindow:function()
            {
                $window.print();
            },

            initStaticData: function () {
            },

            initScopeObjects: function () {
                $scope.currentProposal = {};
                $scope.asset = {};
                $scope.phContact = {};
                $scope.payment = {};
                $scope.productId = '';
                $scope.selectedCoverage = {};
                $scope.easyRenewProposal = null;
            },

            initApiObjects: function () {
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.paymentApi = WfeApiPayment.instance($scope.$parent.sessionApi, $scope, $scope.payment);
            },

            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrieveCurrentProposal();
                };

                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                $scope.productId = $scope.currentProposal.ProductId;
                                $scope.currentProposal.isForUpdate = true;
                                $scope.policyStartDate = $scope.currentProposal.PolicyStartDate;
                                if($scope.currentProposal.ExternalProposalNumber.lastIndexOf('/') > 2){
                                    $scope.easyRenewProposal = true;
                                }else{
                                    $scope.easyRenewProposal = false;
                                }
                                retrieveAsset();
                            },
                            onError: function () {
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                $scope.asset.isForUpdate = true;

                                $scope.selectedCoverage = $filter('filter')($scope.asset.Coverages, {IsSelected: true})[0];

                                retrievePHContact();
                            },
                            onError: function () {
                                retrievePHContact();
                            }
                        }
                    );
                };

                var retrievePHContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                $scope.phContact.isForUpdate = true;
                                retrievePayment();
                            },
                            onError: function () {
                                retrievePayment();
                            }
                        }
                    );
                };

                var retrievePayment = function () {
                    fn.paymentApi.retrievePayment(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.payment, data);
                                $scope.payment.isForUpdate = true;
                                invalidateSession();
                                firingMarketing($scope.currentProposal.ExternalProposalNumber, $scope.currentProposal.TotalPremium, $scope.currentProposal.ProductId, $scope.selectedCoverage);
                            },
                            onError: function () {
                                firingMarketing($scope.currentProposal.ExternalProposalNumber, $scope.currentProposal.TotalPremium, $scope.currentProposal.ProductId, $scope.selectedCoverage);
                            }
                        }
                    );
                };
                var invalidateSession = function(){
                    //Invalidate the session once reached this page.
                    $scope.$parent.sessionApi.setToken('invalid');
                };

                var firingMarketing = function (quoteNo, revenue, productId, selectedCoverage) {
                    try {
                        marketing.ga(productId);
                    } catch (err) {
                        console.log(err);
                    }
                    try {
                        marketing.googleAdwords(revenue, productId);
                    } catch (err) {
                        console.log(err);
                    }
                    try {
                        marketing.googleECommerce(quoteNo, revenue, productId, selectedCoverage);
                    } catch (err) {
                        console.log(err);
                    }
                    try {
                        fn.postToMaxParent();
                    }
                    catch(err)
                    {
                        console.log(err);
                    }
                };

                startRetrievalScopeObject();
            },

            utils: {
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },
            },

            navigation: {},

            saving: {},

            validations: {},

            eventHandlers: {},

            convertThaiYear: function(){
                $policyStartDate = $scope.currentProposal.PolicyStartDate;
                //console.log($policyStartDate.substr(0,4));
                return parseInt($policyStartDate.substr(0,4)) + 543;
            }
        };

        ////Initialize and retrievals
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        $scope.convertThaiYear = fn.convertThaiYear;
        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.printWindow=fn.printWindow;
    }];