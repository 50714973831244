// 'use strict';
//  var PRODUCT_ID_DA = 2000003; //DA company
//  var PRODUCT_ID_MTI = 7000000; //MTI company
//  var PRODUCT_ID_AXA = 7000002; //AXA company

//  var PRODUCT_LINE_GROUP_ID_DA = 2000105;
//  var PRODUCT_LINE_GROUP_ID_MTI = 7000012;
//  var PRODUCT_LINE_GROUP_ID_AXA = 7000012;

// module.exports.PRODUCT_ID_DA = PRODUCT_ID_DA;
// module.exports.PRODUCT_ID_MTI = PRODUCT_ID_MTI;
// module.exports.PRODUCT_ID_AXA = PRODUCT_ID_AXA;
// module.exports.PRODUCT_LINE_GROUP_ID_DA = PRODUCT_LINE_GROUP_ID_DA;
// module.exports.PRODUCT_LINE_GROUP_ID_MTI = PRODUCT_LINE_GROUP_ID_MTI;
// module.exports.PRODUCT_LINE_GROUP_ID_AXA = PRODUCT_LINE_GROUP_ID_AXA;

module.exports = [

    /**
     * AppConstantsProvider
     * @returns {Function}
     * @constructor
     */
    function AppConstantsProvider() {

        var AGGConfig = {
            PRODUCT_ID_DA: 2000003, //DA company
            PRODUCT_ID_MTI: 7000000, //MTI company
            PRODUCT_ID_AXA: 7000002, //AXA company

            PACKAGE_TYPE1: 1,
            PACKAGE_TYPE1_SAVE: 2,
            PACKAGE_TYPE2: 3,
            PACKAGE_TYPE2_PLUS: 4,
            PACKAGE_TYPE3: 5,
            PACKAGE_TYPE3_PLUS: 6,

            TYPE1_MTI_G: 7000179, //Type 1 (MTI) Garage
            TYPE1_MTI_D: 7000171, //Type 1 (MTI) Dealer

            TYPE1_AXA_G: 7000222, //Type 1 (AXA) Garage
            TYPE1_AXA_D: 7000221, //Type 1 (AXA) Dealer

            TYPE1_FALCON_DG: 2000015, //Type 1 (Falcon) Garage/Dealer
            TYPE1SAVE_FALCON_DG: 7000114, //Type 1 Save (Falcon) Garage/Dealer
            TYPE2_FALCON_DG: 2000016, //Type 2 (Falcon) Garage/Dealer
            TYPE2PLUS_FALCON_DG: 2000017, //Type 2+ (Falcon) Garage/Dealer
            TYPE3_FALCON_DG: 2000018, //Type 3 (Falcon) Garage/Dealer
            TYPE3PLUS_FALCON_DG: 2000019, //Type 3+ (Falcon) Garage/Dealer
            TYPECOMPULSORY_FALCON_DG: 2000020, //พ.ร.บ.

            PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA: 2000105,
            PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_MTI: 7000012,
            PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_AXA: 7000073,
            PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA: 2000106,
            PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_MTI: 7000013,
            PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_AXA: 7000074,
            PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA: 2000161,
            PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI: 7000022,
            PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA: 7000081,
            PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA: 2000103,
            PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI: 7000000,
            PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA: 7000066,
            PRODUCT_LINE_GROUP_ID_DEATH_DIS_DA: 2000101,
            PRODUCT_LINE_GROUP_ID_DEATH_DIS_MTI: 7000024,
            PRODUCT_LINE_GROUP_ID_DEATH_DIS_AXA: 7000083,
            PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA: 2000102,
            PRODUCT_LINE_GROUP_ID_BODILY_INJURY_MTI: 7000025,
            PRODUCT_LINE_GROUP_ID_BODILY_INJURY_AXA: 7000084,
            PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_DA: 2000100,
            PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_MTI: 7000023,
            PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_AXA: 7000082,
            PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_DA: 2000114,
            PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_MTI: 7000002,
            PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_AXA: 7000079,

            PRODUCT_LINE_GROUP_ID_2000112_DA: 2000112,
            PRODUCT_LINE_GROUP_ID_2000112_MTI: 2000112,
            PRODUCT_LINE_GROUP_ID_2000112_AXA: 2000112,
            PRODUCT_LINE_GROUP_ID_2000107_DA: 2000107,
            PRODUCT_LINE_GROUP_ID_2000107_MTI: 2000107,
            PRODUCT_LINE_GROUP_ID_2000107_AXA: 2000107,
            PRODUCT_LINE_GROUP_ID_2000160_DA: 2000160,
            PRODUCT_LINE_GROUP_ID_2000160_MTI: 2000160,
            PRODUCT_LINE_GROUP_ID_2000160_AXA: 2000160,


            PRODUCT_LINE_GROUP_VALUE_VOLUNTARY_BASIC_COVER: 'Voluntary Basic Cover',

            PRODUCT_LINE_GROUPID_FALCON_2000116: 2000116,
            PRODUCT_LINE_GROUPID_FALCON_2000056: 2000056,
            PRODUCT_LINE_GROUPID_FALCON_1000093: 1000093,

            PLANID_NAMED_DRIVER: 1000003,
            PLANID_ANY_DRIVER: 1000004,

            VALUE_PLAN: 1000003,
            FLEXIBLE_PLAN: 1000004,
            VALUE_PLUS_PLAN: 1000005,

            SINGLE_PAYMENT: 1000001,
            INSTALLMENT_PAYMENT: 1000005,
            
            SINGLE_PAYMENT_MODE_ID: 7000000,
            INSTALLMENT_PAYMENT_MODE_ID: 7000001,


        };
        this.AGGConfig = AGGConfig;
}];
