/**
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$state',
    '$sessionStorage',
    '$localStorage',
    'ConfigUtils',
    'WfeApiAuth',
    'WfeApiUser',
    'DaAuthorization',
    'HKFormValidation',
    '$window',
    '$timeout',

    function AgentLoginController($scope, $state, $sessionStorage, $localStorage, ConfigUtils, WfeApiAuth, WfeApiUser, DaAuthorization, HKFormValidation, $window,$timeout) {

        var fn = {

            initApiObjects: function () {
                fn.authApi = WfeApiAuth.instance(undefined, $scope, $scope.credentials);
                fn.userApi = WfeApiUser.instance(undefined, $scope, $scope.person);
            },

            initScopeObjects: function(){
                $scope.agentpass = '';

            },

            eventHandlers: {
                onBtnLoginClicked: function () {
                    console.log($scope.agentpass);
                    var passlist = ['1234qwer'];
                    if(passlist.indexOf($scope.agentpass) > -1){
                        console.log("Login pass");
                        fn.util.procceedToNext();
                    }else{
                        console.log("login failed");
                    }
                }
            },

            util: {
                procceedToNext: function()
                {
                    $state.go('public.motor.newsessiontoken');
                }
            }
        };

        //// Initialization
        fn.initScopeObjects();
        fn.initApiObjects();

        ////Event Handlers
        $scope.onBtnLoginClicked = fn.eventHandlers.onBtnLoginClicked;


    }];