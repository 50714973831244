/*
 * Created by Gerald Garcia on 03/11/14.
 */
module.exports = [
    '$scope',
    '$state',
    '$anchorScroll',
    '$filter',
    'DaModal',
    'WfeApiStaticData',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'WfeApiCoverages',
    'WfeApiAddress',
    'WfeApiContact',
    'WfeApiEssential',
    'WfeApiConfirmation',
    'StaticDataKeyValuePair',
    '$stateParams',
    '$window',
    'HKFormValidation',
    '$http',
    '$timeout',
    'ConfigUtils',
    'AppConstants',
    /**
     * Controller for the Confirmation of Motor Quote
     * @constructor
     */
        function MotorQuoteConfirmBuyMixController($scope, $state, $anchorScroll, $filter, DaModal,
                                             WfeApiStaticData,
                                             WfeApiCurrentProposal, WfeApiAsset, WfeApiPolicyContact, WfeApiQuote,
                                             WfeApiCoverages, WfeApiAddress, WfeApiContact, WfeApiEssential,
                                             WfeApiConfirmation, StaticDataKeyValuePair, $stateParams, $window, HKFormValidation, $http, $timeout, ConfigUtils, AppConstants) {

        $anchorScroll();

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/buy/step-6/'}
                );
            },
            gaEvents:function(v){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                var p='';
                switch(v)
                {
                    case 1: p='Add Named Driver'; break;
                    case 2: p='Edit'; break;
                    default: break;
                }
                dataLayer.push({
                    'event': p,
                    'category': 'Portal',
                    'action': 'Navigation',
                    'label': p
                });

            }
        };

        var fn = {

            getMaxarPriority:function(action){

                /* console.log($scope.$parent.staticData)

                var PreferredMake = {
                    "1000015-90000180":"Honda-Brio",
                    "1000015-90000360":"Honda-Jazz",
                    "1000031-90000383":"Nissan-March",
                    "1000042-90000528":"Suzuki-Swift",
                    "1000091-90000561":"Toyota-Vios",
                    "1000091-90000586":"Toyota-Yaris"
                };

                if($scope.$parent.gaCampaign === 'CAT'){
                    //To check that current state is save&exit or callback action
                    if(action == "cb") fn.postToMaxParent('Yes',1,'Y');
                    else if(action == "s&e") fn.postToMaxParent('Yes',2,'Y');
                    else fn.postToMaxParent('Yes',4,'Y');
                }else if(PreferredMake[$scope.asset.ManufactureId + "-" + $scope.asset.ModelId] !== undefined){
                    if(action == "cb") fn.postToMaxParent('No',1,'Y');
                    else if(action == "s&e") fn.postToMaxParent('NO',2,'Y');
                    else fn.postToMaxParent('No',4,'Y');
                }else{
                    if(action == "cb") fn.postToMaxParent('No',1,'N');
                    else if(action == "s&e") fn.postToMaxParent('No',2,'N');
                    else fn.postToMaxParent('No',4,'N');
                } */

                var cbFlag, finalDataFlag;
                if(action == "cb"){
                    //cbFlag = 'Yes';  // marked for future ref.
                    cbFlag = 'No';   
                    finalDataFlag = 'Y';
                }else if(action == "s&e"){
                    cbFlag = 'No';
                    finalDataFlag = 'Y';
                }else{
                    cbFlag = 'No';
                    finalDataFlag = 'N';
                }
                fn.postToMaxParent(cbFlag, $scope.newMaxarPrior ,finalDataFlag);

            },

            postToMaxParent:function(callBackStatus,Priority,FinalCall){

                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                var ed='';
                var date = new Date();
                ed = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear()+ " " + ('0'+date.getHours()).slice(-2) + ":" + ('0'+date.getMinutes()).slice(-2);

                maxarLayer.push( {

                    totalPremium:$scope.currentProposal.TotalPremiumToColl,
                    message:$scope.callbackObj.message,
                    commercial:$scope.callbackObj.commercial,
                    type:'Quote lead',
                    sourcePage:'Review',
                    callback:callBackStatus,
                    priority:Priority,
                    dataFinal:FinalCall,
                    referenceID:$scope.phContact.PrimaryPhoneNumber,
                    telephone:$scope.phContact.PrimaryPhoneNumber,
                    endDate: ed,
                });

                $scope.$parent.postMaxar();
            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;
                //var wfeApiStaticDataPaymentPlan = fn.wfeApiStaticDataPaymentPlan;

                var startRetrieval = function () {
                    getMakeList();
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            $scope.staticData.makes = data;
                            getBooleanListWithSometimes();
                        },

                        onError: function () {
                            getBooleanListWithSometimes();
                        }
                    });
                };

                /*var getDistrictList = function () {
                    wfeApiStaticData.getDistrictList({
                        onSuccess: function (data) {
                            $scope.staticData.districts = data;
                            getProvinceList();
                        },

                        onError: function () {
                            getProvinceList();
                        }
                    });
                };*/

                /*var getProvinceList = function () {
                    wfeApiStaticData.getProvinceList({
                        onSuccess: function (data) {
                            $scope.staticData.provinces = data;
                            getContactRelationshipTypeList();
                        },

                        onError: function () {
                            getContactRelationshipTypeList();
                        }
                    });
                };*/

                /*var getContactRelationshipTypeList = function () {
                    wfeApiStaticData.getContactRelationshipTypeList({
                        onSuccess: function (data) {
                            $scope.staticData.relationships = data;
                            getBooleanListWithSometimes();
                        },

                        onError: function () {
                            getBooleanListWithSometimes();
                        }
                    });
                };*/

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getMaritalStatusList();
                        },

                        onError: function () {
                            getMaritalStatusList();
                        }
                    });
                };

                /*var getPaymentTermList = function () {
                    wfeApiStaticDataPaymentPlan.getPaymentTermList({
                        onSuccess: function (data) {
                            $scope.staticData.paymentTerms = data;
                            getInsurerList();
                        },

                        onError: function () {
                            getInsurerList();
                        }
                    });
                };*/

                /*var getInsurerList = function () {
                    wfeApiStaticData.getInsurerList({
                        onSuccess: function (data) {
                            $scope.staticData.otherInsurances = data;
                            getNoClaimDiscountList();
                        },

                        onError: function () {
                            getNoClaimDiscountList();
                        }
                    });
                };*/

                /*var getNoClaimDiscountList = function () {
                    wfeApiStaticData.getNoClaimDiscountList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data;
                            getOccupationList();
                        },

                        onError: function () {
                            getOccupationList();
                        }
                    });
                };*/

                /*var getOccupationList = function () {
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getGenderList();
                        },

                        onError: function () {
                            getGenderList();
                        }
                    });
                };*/

                /*var getGenderList = function () {
                    wfeApiStaticData.getGenderList({
                        onSuccess: function (data) {
                            $scope.staticData.genders = data;
                            getMaritalStatusList();
                        },

                        onError: function () {
                            getMaritalStatusList();
                        }
                    });
                };*/

                var getMaritalStatusList = function () {
                    wfeApiStaticData.getMaritalStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getNoOfClaimList();
                        },

                        onError: function () {
                            getNoOfClaimList();
                        }
                    });
                };

                var getNoOfClaimList = function () {
                    wfeApiStaticData.getNoOfClaimList({
                        onSuccess: function (data) {
                            $scope.staticData.nrOfClaimsInPeriods = data;
                            getDriverExperienceList();
                        },

                        onError: function () {
                            getDriverExperienceList();
                        }
                    });
                };

                var getDriverExperienceList = function () {
                    wfeApiStaticData.getDriverExperienceList({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                        },

                        onError: function () {
                        }
                    });
                };

                /*var getResidentialStatusList = function () {
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentStatuses = data;
                            getVehicleMileageList();
                        },
                        OnError: function () {
                            getVehicleMileageList();
                        }
                    });
                };*/

                /*var getVehicleMileageList = function () {
                    wfeApiStaticData.getVehicleMileageList({
                        onSuccess: function (data) {
                            $scope.staticData.mileages = data;
                            getVehicleUsageList();
                        },

                        onError: function () {
                            getVehicleUsageList();
                        }
                    });
                };*/

                /*var getVehicleUsageList = function () {
                    wfeApiStaticData.getVehicleUsageList({
                        onSuccess: function (data) {
                            $scope.staticData.usages = data;
                        }

                    });
                };*/

                //getVehicleUsageList
                startRetrieval();

            },

            initScopeObjects: function () {
                $scope.isBtnSaveHide = true;
                $scope.isHide = false;

                $scope.staticData = {};

                $scope.currentProposal = {};
                $scope.asset = {};
                $scope.policyContact = {};
                $scope.policyHolder = {};
                $scope.mainDriver = {};
                $scope.namedDrivers = {
                    PolicyContacts: []
                };
                $scope.phAddress = {};
                $scope.phContact = {};
                $scope.newMaxarPrior = "1";
                $scope.coverages = {};
                $scope.selectedCoverage = {};

                $scope.buyControllerStaticData = {
                    allPaymentTerms: [],
                    products: []
                };

                $scope.PHN_MDEditVisible=false;
                $scope.PH_MDEditVisible=false;
                $scope.MDEditVisible=false;
                $scope.CarEditVisible=false;
                $scope.callbackObj={};
                $scope.sumInsured={};
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;
                $scope.IsReEmailMismatch = false;

                $scope.basicCoverageInfo = {
                    "2000015":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Choice of deductible, sum insured is xxx,xxx",
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Deductible of xxx sum insured is xxx,xxx",
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Deductible of xxx sum insured is xxx,xxx",
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                            //"Deductible of x,xxx. Sum insured is xxx,xxx",

                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                            //"Deductible of x,xxx. Sum insured is xxx,xxx",

                        ]};
                $scope.basicCoverageInfo_Part2 = {
                    "2000015":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000019":
                        [
                            //"Deductible of xxx sum insured is xxx,xxx",
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000016":
                        [

                            'body_basic_coverage_info_payment'
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_payment'
                        ]};

                $scope.partialSaveSubmitted = false;
                $scope.callBackSubmitted = false;
                $scope.promoUrl = [];

                if($scope.HKLocale.getLocale() == 'th-th') {
                    $scope.promoUrl[0] = ConfigUtils.homePageUrl + '/../DirectAsiaThailandSite/media/Images/portal/buy/promo1_th.jpg';
                    $scope.promoUrl[1] = ConfigUtils.homePageUrl + '/../DirectAsiaThailandSite/media/Images/portal/buy/promo2_th.jpg';
                }else {
                    $scope.promoUrl[0] = ConfigUtils.homePageUrl + '/../DirectAsiaThailandSite/media/Images/portal/buy/promo1_en.jpg';
                    $scope.promoUrl[1] = ConfigUtils.homePageUrl + '/../DirectAsiaThailandSite/media/Images/portal/buy/promo2_en.jpg';
                }
            },

            initApiObjects: function () {
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);

                $scope.asset.ProductId = $scope.$parent.product.ProductId;
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.policyContactApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyContact);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.namedDriversApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.namedDrivers);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);
                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), 1941);

                fn.wfeApiStaticData = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataPaymentPlan = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), 4);

                $scope.buyControllerStaticData.products = StaticDataKeyValuePair.getProducts();
                $scope.buyControllerStaticData.allPaymentTerms = StaticDataKeyValuePair.getAllPaymentTerms();
            },

            retrieveScopeObjects: function () {

                fn.currentProposalApi.retrieveCurrentProposal(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.currentProposal, data);

                        },
                        onError: function () {

                        }
                    }
                );

                fn.assetApi.retrieveAsset(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.asset, data);
                            if ($scope.asset.ManufactureId) {
                                fn.retrieveModelStaticData();
                            }
                        }
                    }
                );

                fn.coverageApi.retrieveCoverages(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.coverages, data);
                            var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true})[0];

                            var topItem = [];
                            var lowItem = [];
                            if (selectedCoverage) {
                                for(var i in selectedCoverage.Covers){
                                    if(selectedCoverage.Covers[i]['ProductLineGroupId'] == '2000106' || selectedCoverage.Covers[i]['ProductLineGroupId'] == '2000105'){
                                        topItem.push(selectedCoverage.Covers[i]);
                                    }else{
                                        lowItem.push(selectedCoverage.Covers[i]);
                                    }
                                }

                                selectedCoverage.Covers = topItem;
                                for(var j in lowItem) {
                                    topItem.push(lowItem[j]);
                                }
                                angular.extend($scope.selectedCoverage, selectedCoverage);
                                retrieveExcess();
                                retrievePhAddress();
                            }
                        },
                        onError: function () {
                            retrievePhAddress();
                        }
                    }
                );
                var retrievePhAddress = function () {
                    fn.phAddressApi.retrievePHAddress(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phAddress, data);
                                retrievePhContact();
                            },
                            onError: function () {
                                retrievePhContact();
                            }
                        }
                    );
                }

                var retrievePhContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                fn.setSumInsured();
                                fn.utils.setIsSaving(false);
                            },
                            onError: fn.setSumInsured
                        }
                    );
                }

                var retrieveExcess =function(){
                    fn.coverageApi.getVehicleExcess(
                        {
                            onSuccess: function (data) {
                                $scope.selectedCoverage.excess = data;
                            },
                            onError: function(){
                            }
                        }
                    );
                };

                //PolicyContact Retrieval
                //this will retrieve MD if PH is not MD
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data,data);
                                }
                                angular.extend($scope.policyHolder, data);
                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                }
                                else {
                                    retrieveMainDriver();
                                }
                            }
                        }
                    );
                };


                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact(
                        {
                            onSuccess: function (data) {
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data,data);
                                }

                                angular.extend($scope.mainDriver, data);
                            }
                        }
                    );
                };


                retrievePolicyHolder();

                fn.retrieveNamedDrivers();

            },

            retrieveNamedDrivers: function () {

                fn.namedDriversApi.retrieveNDPolicyContacts(
                    {
                        onSuccess: function (dataArray) {

                            if (!dataArray) {
                                return;
                            }

                            angular.forEach(dataArray,
                                function (data) {
                                    if (data && data.Identifier) {
                                        HKFormValidation.splitIdentifier(data,data);
                                    }
                                }
                            );

                            $scope.namedDrivers.PolicyContacts.splice(0);
                            angular.extend($scope.namedDrivers.PolicyContacts, dataArray);
                            $scope.isEditing = true;
                            if ($scope.namedDrivers.PolicyContacts.length) {
                                $scope.isEditing = false;
                            }

                        }
                    }
                );
            },

            retrieveModelStaticData: function () {

                var objParams = {};
                objParams.manufacturerId = $scope.asset.ManufactureId;
                objParams.purchasingYear = $scope.asset.PurchasingYear;

                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                wfeApiStaticDataModel.getModelListWithYear({
                    onSuccess: function (data) {
                        $scope.staticData.models = data;
                        fn.retrieveDescStaticData();
                        if(data.length==0) {
                            DaModal.alert('The Make and Model you choose is not available. Call us at 02-767-7777 to talk with an agent.');

                        }
                    }
                });
            },

            retrieveDescStaticData: function () {

                var objParams = {};
                objParams.modelId = $scope.asset.ModelId;
                objParams.purchasingYear = $scope.asset.PurchasingYear;
                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                wfeApiStaticDataModel.getCarDescWithYear({
                    onSuccess: function (data) {
                        for(var i in data)
                            if(data[i]['key'] == $scope.asset.CarDescriptionId)
                                $scope.staticData.cardescriptions = data[i];

                        fn.retrieveBodyStaticData();
                    }
                });

            },

            retrieveBodyStaticData: function () {
                var objParams = {};
                objParams.modelId = $scope.asset.ModelId;
                objParams.purchasingYear = $scope.asset.PurchasingYear;
                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                wfeApiStaticDataModel.getBodyTypes({
                    onSuccess: function (data) {
                        $scope.staticData.bodytypes = data;
                        fn.setSumInsured();
                    },
                    onError: function () {
                        fn.setSumInsured();
                    }
                });
            },

            setSumInsured:function()
            {
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;
                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE3_FALCON_DG)
                {
                    //Type 3 is always NA and hidden
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                    return;
                }
                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG
                    || $scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG
                    || $scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG)
                {
                    //Type 2+/ 3+ / Type 1 - value from own damage collision
                    //Type 1- own damage collision value.
                    var odc=$filter('filter')($scope.selectedCoverage.Covers, {
                        ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA
                    },true);
                    if(odc && odc[0].InsuranceAmount){

                        $scope.sumInsured.value=odc[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }


                    return;
                }

                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2_FALCON_DG)
                {

                    //Type 2- own damage Fire and theft value
                    var odft=$filter('filter')($scope.selectedCoverage.Covers, {
                        ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA
                    },true);
                    if(odft && odft[0].InsuranceAmount){

                        $scope.sumInsured.value=odft[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }
                    return;
                }

                if($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG)
                {

                    //Type Compulsory only
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                    return;
                }
                //Exception- Everything fails- hide
                $scope.sumInsured.visible=false;
                $scope.sumInsured.value=0;

            },

            utils: {
                sortOptionalCovers: function () {
                    var covers =  $scope.selectedCoverage.Covers;
                    var windscreenCoverIndex = -1, lossCoverIndex = -1;
                    for (var i = 0; i<covers.length; i++){
                        var cover = covers[i];
                        if(cover.ProductLineGroupId == AppConstants.AGGConfig.PRODUCT_LINE_GROUPID_FALCON_2000056){
                            windscreenCoverIndex = i;
                        } else if(cover.ProductLineGroupId == AppConstants.AGGConfig.PRODUCT_LINE_GROUPID_FALCON_1000093){
                            lossCoverIndex = i;
                        }
                    }

                    if(windscreenCoverIndex >= 0 && lossCoverIndex >= 0 && lossCoverIndex+1 !== windscreenCoverIndex){
                        var windscreenCover = covers[windscreenCoverIndex];
                        covers.splice(windscreenCoverIndex,1);
                        covers.splice(lossCoverIndex,0,windscreenCover);
                    }
                    $scope.selectedCoverage.Covers = covers;
                },

                addExtraComprehensiveCoverforDisplay: function () {
                    var coverageCovers = $scope.coverages.Coverages;
                    var comprehensiveCoverlist = {};
                    var FinalComprehensiveCoverList = [];

                    for (var i = 0; i < coverageCovers.length; i++) {
                        if(coverageCovers[i].CoverTypeId === 1) // only when it is comprehensive
                        {
                            comprehensiveCoverlist = coverageCovers[i].Covers;

                            for (var k = 0; k < comprehensiveCoverlist.length; k++) {

                                var cover = comprehensiveCoverlist[k];

                                if(cover.ProductLineGroupId == AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA)  // before Damage to own car (No other vehicle involved)
                                {
                                    var ownDamageCollisionCover = {
                                        "CoverName": "Collision with third party vehicle - Comprehensive[Added]",
                                        "IsSelected": true,
                                        "ProductLineGroupId": AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA,
                                        "ProductLineTypeString":"Mandatory",
                                        "ProductLineOptionId": 2000809,
                                        "ProductLineType":0};
                                    FinalComprehensiveCoverList.push(ownDamageCollisionCover);
                                }
                                FinalComprehensiveCoverList.push(cover);

                            }

                            $scope.coverages.Coverages[i].Covers = FinalComprehensiveCoverList;
                        }
                    }
                },

                isRenewalProposalLanding: function () {
                    if($scope.asset.ProductId !== 1000009 && ($stateParams.isRenewalLanding === 'true'))//Except renewal Motor car, remaining all products hide buttons only visible back button
                    {
                        $scope.isHide = true;
                    }
                    else
                    {
                        $scope.isHide = false;
                    }
                    return fn.utils.isRenewalProposal && ($stateParams.isRenewalLanding === 'true');

                },
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },
                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                    if($scope.partialSaveSubmitted){
                        DaModal.open("#daLoadingMaskPartialSave");
                    }else if($scope.callBackSubmitted){
                        DaModal.open("#daLoadingMaskCallBack");
                    }else{
                        $scope.DaLoadingMask.toggle(isSaving);
                    }
                },
                closeIsSaving: function () {
                    if($scope.partialSaveSubmitted){
                        DaModal.close("#daLoadingMaskPartialSave");
                    }else if($scope.callBackSubmitted){
                        DaModal.close("#daLoadingMaskCallBack");
                    }else{
                        DaModal.close("#daLoadingMask");
                    }
                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    $scope.policyHolder.OccupationId = $scope.mainDriver.OccupationId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.IsDemeritPoints = $scope.mainDriver.IsDemeritPoints;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;
                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;
                },

                filterBaseCover: function (r) {
                    var status = false;
                    if (r.ProductLineGroupId === 1000087) {
                        status = true;
                    }
                    return status;
                },

                filterMandatoryCovers: function (r) {

                    var status = false;
                    if (r.ProductLineType === '0' || r.ProductLineType === 0) {

                        if (r.CoverName === 'Basic Cover' || r.ProductLineGroupId === 1000087) {
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000108) { //policyExcess
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000109) {//Administration Fee
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000059) {//Recovery Expenses - TPO
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000060) {//Legal Expenses - TPO
                            status = false;
                        }
                        //else if(r.ProductLineGroupId === 2000081){
                        //    status= false;
                        //}
                        else {
                            status = true;
                        }


                    } else {
                        status = false;
                    }

                    return status;

                }
            },

            navigation: {

                goToNextStep: function () {
                    //fn.postToMaxParent('No',2,'N')
                    fn.utils.setIsSaving(false);
                    try {
                        marketing.ga();
                    } catch (err) {
                        console.log(err);
                    }
                    $state.go('public.motor.quote.steps.buy');
                },

                goToPrevStep: function () {
                    fn.utils.setIsSaving(false);
                    //$state.go('public.motor.quote.steps.quote');
                    $state.go('public.motor.quote.steps.cover');
                }

            },
            saving: {

                /** Callback button clicked */
                savePHContactCallback: function () {

                    var callMaxar= function(){

                        DaModal.close('#quotationCallback');
                        try{
                            $scope.$parent.gaEvents(5,3);
                        }
                        catch(e){}

                        $scope.$parent.maxarCallbackTrigger = true;
                        fn.getMaxarPriority("cb");
                        //fn.postToMaxParent('Yes',1,'Y');
                    };

                    /*if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                    {
                        fn.confirmationApi.createConfirmation(
                            {
                                onSuccess: function () {
                                    fn.utils.setIsSaving(false);
                                    callMaxar();
                                },
                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    } else {
                        fn.essentialApi.partialSave(
                            {
                                onSuccess: function () {
                                    fn.utils.setIsSaving(false);
                                    callMaxar();
                                },
                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );

                    }*/

                    callMaxar();

                },

                /** Save & Exit button clicked */
                savePHContact: function () {

                    /*if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                    {
                        fn.confirmationApi.createConfirmation(
                            {
                                onSuccess: function () {
                                    fn.utils.setIsSaving(false);

                                    DaModal.close('#quotationPartialSave');
                                    $scope.$parent.divertAfterSECB();
                                },
                                onError: function(){
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    } else {

                        fn.essentialApi.partialSave(
                            {
                                onSuccess: function () {
                                    fn.utils.setIsSaving(false);
                                    DaModal.close('#quotationPartialSave');
                                    $scope.$parent.divertAfterSECB();
                                },
                                onError: function(){
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    }*/

                    $scope.$parent.maxarCallbackTrigger = true;
                    fn.getMaxarPriority("s&e");

                }
            },
            eventHandlers: {

                onBtnCallbackClicked :function(){
                    try{
                        $scope.$parent.gaEvents(5,3);
                    }catch(e){}

                    DaModal.open('#quotationCallback');

                },
                onBtnCallbackContinueClicked: function () {
                    $scope.pageSubmitted = true;
                    $scope.callBackSubmitted = true;

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    if ($scope.policyHolder.FirstName.invalid ||$scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }

                    fn.saving.savePHContactCallback();
                },
                onBtnCallbackCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        $scope.phContact.PrimaryPhoneNumber = "";
                    }

                    DaModal.close('#quotationCallback');
                },

                onBtnSaveAndExitClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,2);
                    }
                    catch(e){}
                    DaModal.open('#quotationPartialSave');

                },
                onBtnPartialSaveContinueClicked: function () {
                    /*if($scope.phContact.PrimaryEmailAddress != $scope.phContact.PrimaryEmailAddressReenter){
                        $scope.IsReEmailMismatch = true;
                        return;
                    }

                    $scope.pageSubmitted = true;
                    $scope.partialSaveSubmitted = true;
                    if ($scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryEmailAddressReenter.invalid) {
                        return;
                    }*/

                    $scope.pageSubmitted = true;
                    $scope.partialSaveSubmitted = true;
                    if ($scope.phContact.PrimaryEmailAddress.invalid) {
                        return;
                    }
                    fn.saving.savePHContact();

                },
                onBtnPartialSaveCancelClicked: function () {
                    DaModal.close('#quotationPartialSave');
                },
                onBtnBackToMyPolicy: function () {
                    $state.go('private.account.user.policies');
                },
                onBtnDeclineClicked: function () {
                    $state.go('public.motor.quote.steps.declinerenewal');
                },
                onBtnPrintClicked: function () {
                },
                onBtnAmendClicked: function () {
                    $state.go('public.motor.quote.steps.basic');
                },

                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },

                onBtnNextClicked: function () {

                    try{
                        $scope.$parent.gaEvents(0,0);
                    }
                    catch(e){}
                    var hasError = false;
                    var triggerSave = function () {
                        fn.utils.setIsSaving(true);
                        savingComplete();
                    };


                    var savingComplete = function () {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }

                        fn.navigation.goToNextStep();
                    };

                    triggerSave();
                },

                onBtnBackClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){}
                    fn.utils.setIsSaving(true);
                    fn.navigation.goToPrevStep();
                },

                onPolicyHolderEditClicked: function () {
                    $state.go('public.motor.quote.steps.quote');
                },

                onPolicyMainDriveEditClicked: function () {
                    $state.go('public.motor.quote.steps.quote');
                },

                onCarDetailEditClicked: function () {
                    $state.go('public.motor.quote.steps.basic');
                },

                onQuoteDetailsEditClicked: function () {
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){

                    }
                    $state.go('public.motor.quote.steps.customise');
                },

                onAdditionalDriversEditClicked: function () {
                    try {
                        marketing.gaEvents(1);
                    }
                    catch(e){

                    }
                    $state.go('public.motor.quote.steps.customise');
                },
                onGoToCoverCtrlClicked: function () {
                    $state.go('public.motor.quote.steps.cover');
                },
                onGoToBasicCtrlClicked: function () {
                    $state.go('public.motor.quote.steps.basic');
                },
                setSubEditPH_NMD:function()
                {
                    $scope.PHN_MDEditVisible=!$scope.PHN_MDEditVisible;
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){

                    }
                },
                setSubEditMD:function()
                {
                    $scope.MDEditVisible=!$scope.MDEditVisible;
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){

                    }
                },
                setSubEditCarDetails:function()
                {
                    $scope.CarEditVisible=!$scope.CarEditVisible;
                    try{
                        marketing.gaEvents(2);
                    }
                    catch(e){

                    }
                },

                onFocusReEmail: function(){
                    $scope.IsReEmailMismatch = false;
                }
            }

        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        ////Event Handlers
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnBackToMyPolicy = fn.eventHandlers.onBtnBackToMyPolicy;
        $scope.onBtnDeclineClicked = fn.eventHandlers.onBtnDeclineClicked;
        $scope.onBtnPrintClicked = fn.eventHandlers.onBtnPrintClicked;
        $scope.onBtnAmendClicked = fn.eventHandlers.onBtnAmendClicked;

        $scope.onPolicyHolderEditClicked = fn.eventHandlers.onPolicyHolderEditClicked;
        $scope.onPolicyMainDriveEditClicked = fn.eventHandlers.onPolicyMainDriveEditClicked;
        $scope.onCarDetailEditClicked = fn.eventHandlers.onCarDetailEditClicked;
        $scope.onQuoteDetailsEditClicked = fn.eventHandlers.onQuoteDetailsEditClicked;
        $scope.onAdditionalDriversEditClicked = fn.eventHandlers.onAdditionalDriversEditClicked;
        $scope.setSubEditPH_NMD=fn.eventHandlers.setSubEditPH_NMD;
        $scope.onGoToCoverCtrlClicked=fn.eventHandlers.onGoToCoverCtrlClicked;
        $scope.onGoToBasicCtrlClicked=fn.eventHandlers.onGoToBasicCtrlClicked;
        $scope.setSubEditMD=fn.eventHandlers.setSubEditMD;
        $scope.setSubEditCarDetails=fn.eventHandlers.setSubEditCarDetails;

        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;
        $scope.gaEvents = $scope.$parent.gaEvents;
        ////Utils
        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.utils.filterBaseCover = fn.utils.filterBaseCover;
        $scope.utils.filterMandatoryCovers = fn.utils.filterMandatoryCovers;
        $scope.utils.sortOptionalCovers = fn.utils.sortOptionalCovers;
        $scope.getMonthlyPriceStepCtrl = $scope.$parent.getMonthlyPriceStepCtrl;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.stepFn.isRenewalProposalLanding = fn.utils.isRenewalProposalLanding;

        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.divertAfterSECB=$scope.$parent.divertAfterSECB;
        $scope.onFocusReEmail = fn.eventHandlers.onFocusReEmail;

        $scope.$on('$viewContentLoaded', function() {

        });

    }];