/*
 * Created by Gerald Garcia on 25/08/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    'WfeApiSession',
    'HKSteps',

    /**
     * Controller that assigns token to the product
     * @param $scope
     * @param $state
     * @constructor
     */
    function ViewRenewalProposalController($scope, $state, $stateParams, WfeApiSession, HKSteps) {

        var fn = {

            products: {
                motor: {
                    ProductId: 1000009,
                    ProductCode: 'motor',
                    ProductHeader: $scope.HKLocale.getResource('car_h_product_name'),
                    FirstPageState: 'public.motor.quote.steps.renewalLanding'
                },
                travel: {
                    ProductId: 2000001,
                    ProductCode: 'travel',
                    ProductHeader: $scope.HKLocale.getResource('tr_h_product_name'),
                    FirstPageState: 'public.travel.quote.steps.renewalLanding'
                },
                motorcycle: {
                    ProductId: 2000004,
                    ProductCode: 'motorcycle',
                    ProductHeader: $scope.HKLocale.getResource('mc_h_product_name'),
                    FirstPageState: 'public.motorcycle.quote.steps.renewalLanding'
                },
                health: {
                    ProductId: 2000006,
                    ProductCode: 'health',
                    ProductHeader: $scope.HKLocale.getResource('health_h_product_name'),
                    FirstPageState: 'public.health.quote.steps.renewalLanding'
                },
                termlife: {
                    ProductId: 2000002,
                    ProductCode: 'termlife',
                    ProductHeader: $scope.HKLocale.getResource('tl_h_product_name'),
                    FirstPageState: 'public.termlife.quote.steps.renewalLanding'
                }

            },

            initScopeObjects: function (){
                $scope.product = {};
                $scope.prodOption= {};
            },

            initApiObjects: function(){


                var productCode = $stateParams.productCode;
                $scope.prodOption = '';


                if(productCode.indexOf('-') > -1)
                {
                    $scope.prodOption = productCode.substring(productCode.indexOf('-')+1);
                    productCode = productCode.substring(0,productCode.indexOf('-'));
                }


                var product = fn.products[productCode];

                if (!product) {
                    //when productcode is product id
                    var productCodeInt = parseInt(productCode);
                    var productReverseMap = {
                        1000009: 'motor',
                        2000001: 'travel',
                        2000004: 'motorcycle',
                        2000006: 'health',
                        2000002: 'termlife'
                    };

                    var productCodeTemp = productReverseMap[productCodeInt];
                    product = fn.products[productCodeTemp];

                    if(!product){
                        return;
                    }
                }

                angular.extend($scope.product, product);
                $scope.sessionApi = WfeApiSession.instance($scope, product.ProductCode);
                $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);

            },


            eventHandlers: {

                onLoadingWithToken: function () {
                    var token = $stateParams.token;


                    if (!token) {

                        return false;
                    }

                    $scope.sessionApi.setToken(token);
                    $scope.isAccepted = true;
                    $scope.stepsUtils.setIsAccepted(true);
                    $scope.stepsUtils.setProdOption($scope.prodOption);

                    fn.navigation.goToNextStep();
                    return true;
                }
            },

            navigation: {
                goToNextStep: function() {
                    var param = {
                        isRenewalLanding :true
                    };
                    $state.go($scope.product.FirstPageState,param);
                }
            }

        };
        fn.initScopeObjects();
        fn.initApiObjects();
        $scope.isTokenLoaded = !fn.eventHandlers.onLoadingWithToken();


    }];