/*
 * Created by Gerald Garcia on 19/06/15
 */
module.exports = [
    '$scope',
    '$state',
    '$anchorScroll',
    '$filter',
    '$document',
    'ConfigUtils',
    'HKVehicleValidationService',
    'DaModal',
    'WfeApiCoverages',
    'WfeApiQuote',
    'WfeApiEssential',
    'WfeApiPolicyContact',
    'WfeApiContact',
    'WfeApiAsset',
    'WfeApiLog',
    'WfeApiCurrentProposal',
    'WfeApiConfirmation',
    'WfeApiStaticData',
    '$window',
    '$http',
    'HKLocale',
    '$cookies',
    '$sessionStorage',
    'FormValidation',
    '$timeout',
    'MITDate',
    '$localStorage',
    'AppConstants',
    /**
     * Controller for the Coverages of Motor Quote
     * @constructor
     */
    function MotorQuoteCoverController($scope, $state, $anchorScroll, $filter, $document, ConfigUtils,
        HKVehicleValidationService, DaModal,
        WfeApiCoverages, WfeApiQuote, WfeApiEssential, WfeApiPolicyContact, WfeApiContact, WfeApiAsset, WfeApiLog,
        WfeApiCurrentProposal, WfeApiConfirmation, WfeApiStaticData, $window, $http, HKLocale, $cookies, $sessionStorage, FormValidation, $timeout, MitDate, $localStorage , AppConstants) {

        $anchorScroll();

        var marketing = {
            ga: function () {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event': 'content-view',
                    'virtualPageURL': '/motor/quote/your-policy/step-3/'
                }
                );
            },

            gaEvents: function (v) {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                var p = '';
                switch (v) {
                    case 1: p = 'Full Coverage Details'; break;
                    case 2: p = 'Types Of Cover Explained'; break;
                    default: break;
                }
                dataLayer.push({
                    'event': p,
                    'category': 'Portal',
                    'action': 'Information',
                    'label': p
                });

            },

            dLCE: function (ref, v) {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                switch (ref) {
                    case 10: dataLayer.push({
                        'event': 'dataLayer-initialised',
                        'type-of-coverage': v
                    });
                        break;

                    case 11: dataLayer.push({
                        'event': 'dataLayer-initialised',
                        'add-compulsory-insurance': v
                    });
                        break;

                    case 12: dataLayer.push({
                        'event': 'dataLayer-initialised',
                        'deductible-amount': v
                    });
                        break;
                    case 18:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'who-do-you-want-to-cover': v
                        });
                        break;
                    case 19:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'choose-your-workshop': v
                        });

                        break;
                    case 50: dataLayer.push({
                        'event': 'dataLayer-initialised',
                        'Sum-Insure-level': v
                    });
                        break;

                    default: break;
                }
            }
        };

        var fn = {

            gtAnchor: function (x) {
                if (!x) { return; }
                FormValidation.animateToID(x);
            },
            restart: function () {
                $state.go('public.dashboard')
            },

            getMaxarPriority: function (action) {

                var cbFlag, finalDataFlag;
                if (action == "cb") {
                    $scope.newMaxarPrior = 1;
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                } else if (action == "s&e") {
                    $scope.newMaxarPrior = 1;
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                } else {
                    cbFlag = 'No';
                    finalDataFlag = 'N'
                }
                if ($scope.newMaxarPrior !== null && $scope.newMaxarPrior !== undefined && $scope.newMaxarPrior !== 0 && $scope.newMaxarPrior !== "") {
                    fn.postToMaxParent(cbFlag, $scope.newMaxarPrior, finalDataFlag);
                }


            },

            setAssetMaxarPriority: function (action) {

                var cbFlag, finalDataFlag;
                if (action == "cb") {
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                } else if (action == "s&e") {
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                } else {
                    cbFlag = 'No';
                    finalDataFlag = 'N'
                }
                //DATH-1137
                var firstStar, secondStar;
                if (cbFlag == 'No') {
                    firstStar = "";
                } else {
                    firstStar = "*";
                }
                if (finalDataFlag == 'Y') {
                    secondStar = "*";
                } else {
                    secondStar = "";
                }
                $scope.asset.MaxarPriority = $scope.newMaxarPrior + firstStar + secondStar;
            },

            updateAssetMaxarPriority: function (callbacks) {
                var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');
                fn.assetApi[saveAssetFunc](callbacks);
            },

            postToMaxParent: function (callBackStatus, Priority, FinalCall) {
                $window.maxarLayer = $window.maxarLayer || [];
                var date = new Date();
                var maxarLayer = $window.maxarLayer;

                $sessionStorage.maxarReference = $scope.phContact.PrimaryPhoneNumber;
                var ref_id = '';
                var firstname = '';
                var ed = '';
                var ga_source = ''; var ga_campaign = ''; var ga_medium = '';
                var ck = $cookies["__utmz"];
                if (ck) {
                    var z = ck.split('.');
                    if (z.length >= 4) {
                        var y = z[4].split('|');
                        for (var i = 0; i < y.length; i++) {
                            if (y[i].indexOf('utmcsr=') >= 0) ga_source = y[i].substring(y[i].indexOf('=') + 1);
                            if (y[i].indexOf('utmccn=') >= 0) ga_campaign = y[i].substring(y[i].indexOf('=') + 1);
                            if (y[i].indexOf('utmcmd=') >= 0) ga_medium = y[i].substring(y[i].indexOf('=') + 1);
                        }
                    }

                } else {
                    // Use DA Cookie instead for maxar 
                    ga_source = $cookies["dac_gacsr"] || '';
                    ga_campaign = $cookies["dac_gaccn"] || '';
                    ga_medium = $cookies["dac_gacmd"] || '';


                    /* ga_source = $scope.asset.SourceChannelId;
                    ga_medium = $scope.asset.ChannelDetails;
                    ga_campaign = $scope.$parent.gaCampaign;  */
                }

                //$scope.asset.LeadTypeId = 7000001;
                if (ga_source === 0 || ga_source === null || ga_source === "") ga_source = "-";
                if (ga_medium === 0 || ga_medium === null || ga_medium === "") ga_medium = "-";
                if (ga_campaign === 0 || ga_campaign === null || ga_campaign === "") ga_campaign = "-";

                var typeCoverage = '';
                if ($scope.coverages.Coverages) {
                    for (var i = 0; i <= $scope.coverages.Coverages.length; i++) {
                        if ($scope.coverages.Coverages[i].IsSelected) {
                            typeCoverage = $scope.coverages.Coverages[i].Name;
                            break;
                        }
                    }
                }


                if (!$scope.phContact.PrimaryPhoneNumber) {
                    ref_id = '0000000000';
                } else {
                    ref_id = $scope.phContact.PrimaryPhoneNumber;
                }

                if (!$scope.policyHolder.FirstName) {
                    firstname = '-';

                    if (!$scope.phContact.FirstName) {
                        firstname = '-';
                    } else {
                        firstname = $scope.phContact.FirstName;
                    }

                } else {
                    firstname = $scope.policyHolder.FirstName;
                }
                try {
                    ed = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                }
                catch (e) {
                    ed = "01-01-2017 00:00";
                }

                var language = HKLocale.getLocale();
                var dataFinal = FinalCall;
                var hq = '';
                if ($scope.isHotLead || $cookies["hl_enable"]) {
                    language = "th-hl";
                    dataFinal = "Y";
                    hq = 'hot quote';
                }

                maxarLayer.push(
                    {
                        typeCoverage: typeCoverage,
                        compulsory: $scope.IsCompulsarySelectedFrontEnd,
                        totalPremium: $scope.totalPremium2,
                        consent: true,
                        message: $scope.callbackObj.message,
                        commercial: $scope.callbackObj.commercial,
                        referenceID: ref_id,
                        endDate: ed,
                        firstName: firstname,
                        lastName: '-',
                        email: $scope.phContact.PrimaryEmailAddress,
                        telephone: ref_id,
                        type: 'Quote lead',
                        sourcePage: 'Your Quote',
                        language: language,
                        callback: callBackStatus,

                        blank1: $scope.asset.IsCarCameraInstalled,
                        priority: Priority,
                        dataFinal: FinalCall,

                        //------- Modify for new maxar prioritize------------------
                        quoteNumber: $scope.currentProposal.ExternalProposalNumber,
                        sourceChannel: ga_source,
                        channelDetail: ga_medium,
                        gaCampaign: ga_campaign,

                        //hotquote mark
                        blank2: hq

                    }
                );
                /* fn.assetApi.updateAsset(
                    {
                        onSuccess: function(){
                            console.log($scope.asset);
                            fn.essentialApi.partialSave();
                        },
                        onError: function(){
    
                        }
                    }
                ); */

                if ($scope.$parent !== null) {
                    $scope.$parent.postMaxar();
                }
            },

            calculateTotalAmout: function () {
                if ((!$scope.coverages) || !$scope.coverages.Coverages) {
                    return 0;
                }
                // var coverage = $filter('filter')($scope.coverages.Coverages, { IsSelected: true }, true)[0];
                var coverage = $scope.coverages.Coverages.filter(w => w.IsSelected == true);
                if(coverage && coverage.length > 0) {
                    coverage = coverage[0]
                }
                if (!coverage) {
                    return 0;
                }
                var x = fn.utils.getPriceToShow(coverage);
                return (x);

            },

            getTotalAmout: function () {
                $scope.totalPremium2 = fn.calculateTotalAmout();
                return $scope.totalPremium2;
            },

            initStaticData: function () {

            },

            initScopeObjects: function () {
                $scope.$parent.isUserProceedOnBuy();
                $scope.isBtnProceedDisabled = true;
                $scope.isCoverSelected = false;

                $scope.coverages = {};
                $scope.comprehensiveCoverage = {};
                $scope.phContact = {};
                $scope.policyHolder = {};
                $scope.mainDriver = {};
                $scope.currentProposal = {};
                $scope.asset = {};

                $scope.bidata = {};
                $scope.toggle = false;
                //$scope.IsCompulsarySelectedFrontEnd=false;
                $scope.callbackObj = {};
                $scope.isSaveAndExitShowName = true;

                $scope.loadingSpinner = true;
                $scope.newMaxarPrior = "";
                $scope.coverNum = 0;
                $scope.coverDetailsClicked = true;
                $scope.ExcessLevelId = 7000154;
                $scope.staticData = {};
                $scope.basicCoverageInfo = {
                    "2000015":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',

                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',

                        ]
                };

                $scope.basicCoverageInfo_Part2 = {
                    "2000015":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000016":
                        [

                            'body_basic_coverage_info_payment'
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_payment'
                        ]
                };

                $scope.tyepOfCoverageTable = {
                    /** type1 type2+ type3+ type2 type3 */
                    "body_popup_cover_item1": [true, true, true, false, false],
                    "body_popup_cover_item2": [true, true, true, true, true],
                    "body_popup_cover_item3": [true, true, false, true, false],
                    "body_popup_cover_item4": [true, true, false, false, false],
                    "body_popup_cover_item5": [true, false, false, false, false],
                    "body_popup_cover_item6": [true, true, true, false, false]
                };


                $scope.sumInsured = {};
                $scope.sumInsured.value = 0;
                $scope.sumInsured.visible = false;
                $scope.IsReEmailMismatch = false;

                $scope.partialPolicyNumber = "";
                $scope.isCompulsoryOnly = false;

                $scope.partialSaveSubmitted = false;
                $scope.callBackSubmitted = false;

                $scope.showConfirmBuyPageB2C=false;
                $scope.showConfirmBuyPageOnlineSelling=true;


                /** lang + promotion lang setup */
                $scope.loc = '';
                $scope.loc = HKLocale.getLocale();
                if ($scope.loc == 'en-us') {
                    $scope.policyHolder.PreferredLanguageId = 101;
                }
                if ($scope.loc == 'th-th') {
                    $scope.policyHolder.PreferredLanguageId = 2000000;
                }

                $scope.promoUrl = "";
                if ($scope.loc == 'th-th') {
                    $scope.promoUrl = ConfigUtils.homePageUrl + '/../DirectAsiaThailandSite/media/Images/portal/buy/promo_cover_th.jpg';
                } else {
                    $scope.promoUrl = ConfigUtils.homePageUrl + '/../DirectAsiaThailandSite/media/Images/portal/buy/promo_cover_en.jpg';
                }

                $scope.customInsuranceAmount = 0;
                $scope.isHotLead = false;
                $scope.policyMinStartDate = MitDate.toAngularDate(MitDate.newDate().add(0, 'days'));
                $scope.policyMaxStartDate = MitDate.toAngularDate(MitDate.newDate().add(59, 'days'));
            },

            initApiObjects: function () {
                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);

                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, undefined);
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.wfeApiStaticData = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
            },

            initSubscribeWatcher: function () {
                $scope.$watch('policyHolder.HasUnsubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.SubscribedMarketingEmail = !value;
                });
                $scope.$watch('policyHolder.SubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.HasUnsubscribedMarketingEmail = !value;
                });

                $scope.$watch('selectedCoverage.CoverTypeId', function (coverages) {
                    if (coverages) {
                        fn.utils.setMaxarPriorRev3(coverages, function () {
                            fn.getMaxarPriority();
                            fn.saving.saveCoveragesModel(
                                {

                                    onSuccess: function () {
                                        fn.logApi.updateLog();
                                        // DATH-1137
                                        fn.setAssetMaxarPriority();
                                        fn.updateAssetMaxarPriority();

                                        if ($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG || $scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG) {
                                            fn.quoteApi.getInsuranceAmount({
                                                onSuccess: function (data) {
                                                    if (data && data[0].value) {
                                                        $scope.customInsuranceAmount = data;
                                                        fn.utils.setDefaultInsureAmount();
                                                    }
                                                },
                                                onError: function () {
                                                }
                                            });
                                        }
                                    },
                                    onError: function () {
                                    }
                                }
                            );
                        });


                    }

                });

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        destroyWatcher();
                    }
                );
            },

            retrieveScopeObjects: function () {

                try {
                    fn.logApi.writeLog(
                        {
                            onSuccess: function () {
                                $scope.$parent.bi.isDone = true;
                            },
                            onError: function () {
                            }
                        }
                    );
                } catch (err) {
                }

                var startRetrievalScopeObject = function () {
                    fn.utils.setIsSaving(true);
                    retrieveCurrentProposal();
                };

                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                if ($scope.currentProposal.PolicyStartDate) {
                                    fn.utils.ConvertMITDateToDisplay();
                                }
                                try {
                                    if (!$scope.currentProposal.IsRenewalProposal) {
                                        $scope.bi.whereDidYouHereUs = 'None';
                                        $scope.bidata = {
                                            Source: $scope.$parent.bi.whereDidYouHereUs
                                        };
                                        if ($scope.$parent.bi.whereDidYouHereUs && !$scope.$parent.bi.isDone) {
                                            fn.logApi.writeLog(
                                                {
                                                    onSuccess: function () {
                                                    },
                                                    onError: function () {
                                                    }
                                                }
                                            );
                                        }
                                    }
                                } catch (err) {
                                }

                                retrieveAsset();
                            },
                            onError: function () {
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);

                                retrieveCoverages();
                            },
                            onError: function () {
                                retrieveCoverages();
                            }
                        }
                    );
                };

                var retrieveCoverages = function () {
                    fn.coverageApi.retrieveCoverages(
                        {
                            onSuccess: function (data) {

                                /** Added by OB For temporary coverages sequence*/
                                //var coverages = [];
                                if (data.CoverListsCount == 5 || data.CoverListsCount == 10) {
                                    //var index = [2000017, 2000019, 2000016, 2000018, 2000020];
                                    $scope.tyepOfCoverageTable = {
                                        "body_popup_cover_item1": [true, true, false, false],
                                        "body_popup_cover_item2": [true, true, true, true],
                                        "body_popup_cover_item3": [true, false, true, false],
                                        "body_popup_cover_item4": [true, false, false, false],
                                        "body_popup_cover_item5": [false, false, false, false],
                                        "body_popup_cover_item6": [true, true, false, false]
                                    };
                                } else {
                                    //var index = [2000015, 2000017, 2000019, 2000016, 2000018, 2000020];
                                    $scope.tyepOfCoverageTable = {
                                        "body_popup_cover_item1": [true, true, true, false, false],
                                        "body_popup_cover_item2": [true, true, true, true, true],
                                        "body_popup_cover_item3": [true, true, false, true, false],
                                        "body_popup_cover_item4": [true, true, false, false, false],
                                        "body_popup_cover_item5": [true, false, false, false, false],
                                        "body_popup_cover_item6": [true, true, true, false, false]
                                    };
                                }
                                /* for(var j in index){
                                    for(var i in data.Coverages){
                                        if(data.Coverages[i].CoverTypeId === index[j]){
                                            coverages[j] = data.Coverages[i];
                                        }
                                    }
                                }

                                data.Coverages = coverages; */
                                /** ------------------------ */
                                //$scope.asset.PlanId;
                                /* var coverageSet = $filter('filter')(data.Coverages, {PlanId: $scope.asset.PlanId});
                                console.log(coverageSet);
                                console.log(data); */
                                angular.extend($scope.coverages, data);
                                $scope.$parent.PlanId = $scope.asset.PlanId;

                                $scope.IsCompulsarySelectedFrontEnd = fn.utils.IsCompulsarySelectedFrontEndFn();
                                var selectedCoverage = fn.utils.getSelectedCoverage();
                                if (selectedCoverage) {
                                    /* console.log("selected coverage");
                                    console.log(selectedCoverage); */
                                    fn.utils.setSelectedCoverage(selectedCoverage);

                                    /** Added by OB for selected coverages*/
                                    for (var i in selectedCoverage.Covers) {
                                        if (selectedCoverage.Covers[i].CoverName === "Voluntary Basic Cover") {
                                            $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                            $scope.selectedCoverage.ExcessLevelId = $scope.ExcessLevelId;
                                        }
                                    }
                                    /** ------------------------ */
                                }/* else{
                                    console.log('set default coverage');
                                    fn.utils.setDefaultCoverage();
                                    if($scope.coverages.CoverListsCount == 6){
                                        $scope.selectedCoverage.ExcessLevelId = 7000310;
                                    }
                                } */
                                fn.utils.setSumInsured();

                                $scope.coverages.isForUpdate = true;
                                if (selectedCoverage) {
                                    if (selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG) {
                                        $scope.isCompulsoryOnly = true;
                                    } else {
                                        $scope.isCompulsoryOnly = false;
                                    }

                                } else {
                                    $scope.isCompulsoryOnly = false;
                                }

                                fn.utils.closeIsSaving();
                                retrievePHContact();
                            },
                            onError: function () {
                                retrievePHContact();
                            }
                        }
                    );
                };

                var retrievePHContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                getBooleanList();

                                //for new maxar priority
                                //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                fn.getMaxarPriority();
                            },
                            onError: function () {
                                getBooleanList();
                            }
                        }
                    );
                };

                var getBooleanList = function () {
                    fn.wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;

                            //retrieveDescStaticData();
                            if ($scope.coverages.CoverListsCount == 5 || $scope.coverages.CoverListsCount == 10) {
                                var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG })[0];
                            } else {
                                var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE1_FALCON_DG })[0];
                            }
                            if (selectedCoverage.IsSelected) {
                                retrieveAllExcess();
                            } else {
                                //$scope.loadingSpinner = false;
                                fn.utils.setIsSaving(false);
                                retrieveAllExcess();
                            }

                        },

                        onError: function () {
                            //retrieveDescStaticData();

                            if ($scope.coverages.CoverListsCount == 5) {
                                var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG })[0];
                            } else {
                                var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE1_FALCON_DG })[0];
                            }
                            if (selectedCoverage.IsSelected) {
                                retrieveAllExcess();
                            } else {
                                //$scope.loadingSpinner = false;
                                fn.utils.setIsSaving(false);
                                retrieveAllExcess();
                            }
                        }
                    });
                };

                var retrieveAllExcess = function (c) {
                    /*c==1 is a flag to continue further retrieval. Not needed for inPage changes.*/
                    fn.coverageApi.getVehicleExcess(
                        {
                            onSuccess: function (data) {
                                $scope.selectedCoverage.excess = data;
                                retrieveExcess();
                                fn.eventHandlers.sendDefaultExcessGTM();

                            },
                            onError: function () {
                                retrieveExcess();
                            }
                        }
                    );
                };

                /*var retrieveDescStaticData = function () {

                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getCarDescWithYear({
                        onSuccess: function (data) {
                            for(var i in data)
                                if(data[i]['key'] == $scope.asset.CarDescriptionId)
                                    $scope.staticData.cardescriptions = data[i];
                            retrieveExcess();

                        },
                        onError: function () {
                            retrieveExcess();
                        }
                    });

                };*/

                var retrieveExcess = function () {
                    fn.coverageApi.getVehicleExcess(
                        {
                            onSuccess: function (data) {
                                if (data && data[0].value)
                                    $scope.allExcess = data[0].value;
                                getDatesPartsDD();
                                //$scope.loadingSpinner = false;
                                fn.utils.setIsSaving(false);
                            },
                            onError: function () {
                                getDatesPartsDD()
                                //$scope.loadingSpinner = false;
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                };

                var getDatesPartsDD = function () {
                    fn.wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },

                        onError: function () {
                            getDatesPartsMM();
                        }
                    });
                };

                var getDatesPartsMM = function () {
                    fn.wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;
                            getDatesPartsYY();
                        },

                        onError: function () {
                            getDatesPartsYY();
                        }
                    });
                };

                var getDatesPartsYY = function () {
                    fn.wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            //$scope.staticData.datesYearsPolicy = [data[0], {"key":data[0]["key"] + 1, "value":data[0]["value"] + 1, "valueTH":data[0]["valueTH"] + 1}];
                            getDatesPartsFullYY();
                        },

                        onError: function () {
                            getDatesPartsFullYY();
                        }
                    });
                };

                var getDatesPartsFullYY = function () {
                    fn.wfeApiStaticData.getDatesPartsFullYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYearsPolicy = [data[0], { "key": data[0]["key"] + 1, "value": data[0]["value"] + 1, "valueTH": data[0]["valueTH"] + 1 }];
                            fn.utils.setIsSaving(false);
                        },

                        onError: function () {
                        }
                    });
                };

                /** PolicyContact retrieval: this will retrieve MD if PH is not MD */
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {

                                angular.extend($scope.policyHolder, data);
                                if ($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                    $scope.policyHolder.SubscribedMarketingEmail = false;
                                } else {
                                    $scope.policyHolder.SubscribedMarketingEmail = true;
                                }

                                if (data === undefined) {
                                    retrieveMainDriver();
                                }

                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                } else {
                                    retrieveMainDriver();
                                }

                            },
                            onError: function () {
                            }
                        }
                    );
                };

                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.mainDriver, data);
                                if ($scope.mainDriver.DateOfBirth) {
                                    fn.utils.ConvertMITDateToDisplay();
                                }
                                if ($scope.mainDriver.IsMainDriver) {
                                    $scope.policyHolder.IsMainDriver = false;
                                }

                            }
                        }
                    );
                };

                startRetrievalScopeObject();
                retrievePolicyHolder();
            },

            navigation: {
                goToNextStep: function () {
                    fn.utils.setIsSaving(false);

                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(3);
                    /* Next action priority */
                    var selectedCoverage = fn.utils.getSelectedCoverage();
                    $sessionStorage.noOfPTSave = $sessionStorage.noOfPTSave + 1;
                    $scope.isHotLead = true;
                    var goNext = function () {
                        try {
                            marketing.ga();
                        } catch (err) {
                            console.log(err);
                        }

                        var sc = fn.utils.getSelectedCoverage();

                        if (sc.CoverTypeId == AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG) {
                            $state.go('public.motor.quote.steps.confirm2');
                        } else {
                            $state.go('public.motor.quote.steps.customise');
                        }
                    }

                    var defaultPriorityJudge = function () {
                        if (selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG) {
                            $scope.newMaxarPrior = 4;
                            fn.getMaxarPriority();
                            goNext();
                        } else {
                            $scope.newMaxarPrior = 3;
                            fn.getMaxarPriority();
                            goNext();
                        }
                    }

                    if(selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG && $scope.$parent.ppstEnable ) {
                        fn.getPriority(function (priority) {
                            
                                if (priority != 0) {
                                    fn.postToMaxParent('No', priority, 'N');
                                    goNext();
                                } else {
                                    defaultPriorityJudge();
                                }
                            
                        });
                    } else {
                        defaultPriorityJudge();
                    }


                },

                goToPrevStep: function () {
                    fn.utils.setIsSaving(false);
                    $state.go('public.motor.quote.steps.basic');
                }
            },

            saving: {
                getSaveFunctionName: function (model, functionName) {
                    var saveFunc = model.isForUpdate ? 'update' : 'create';
                    return saveFunc + functionName;
                },

                savePHContact: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                fn.setAssetMaxarPriority("s&e");
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function () {
                                        if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                        {
                                            fn.confirmationApi.createConfirmation(
                                                {
                                                    onSuccess: function () {
                                                        fn.utils.setIsSaving(false);
                                                        $scope.$parent.maxarCallbackTrigger = true;

                                                        /* Next action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.getMaxarPriority("s&e");
                                                        //fn.postToMaxParent('No',3,'Y');
                                                        DaModal.close('#quotationPartialSave');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        } else {

                                            fn.essentialApi.partialSave(
                                                {
                                                    onSuccess: function (data) {
                                                        fn.utils.setIsSaving(false);
                                                        $scope.$parent.maxarCallbackTrigger = true;

                                                        /* Next action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.getMaxarPriority("s&e");
                                                        DaModal.close('#quotationPartialSave');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                });

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                saveCoveragesModel: function (callbacks) {

                    var triggerSave = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc](
                            {
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;

                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },

                calculatePremium: function (callbacks) {
                    fn.quoteApi.calculate(callbacks);
                },

                savePHContactToProceed: function (callbacks) {

                    fn.utils.setIsSaving(true);
                    var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                    fn.policyHolderApi[savePHFunc](
                        {
                            onSuccess: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            }
                        }
                    );
                },
                saveCurrentProposal: function (callbacks) {
                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                    fn.currentProposalApi[saveCurrentProposalFunc](
                        {
                            onSuccess: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            }
                        }
                    );
                },
                saveOtherDetailsPH: function (callbacks) {
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                /*proceed if pnumber else ps.*/
                                if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                } else {

                                    fn.essentialApi.partialSave(
                                        {
                                            onSuccess: function (data) {
                                                $scope.partialPolicyNumber = data['QuoteNumber'];
                                                fn.utils.setIsSaving(false);
                                                marketing.dLCE(45, $scope.partialPolicyNumber);
                                                if (callbacks && callbacks.onSuccess) {
                                                    callbacks.onSuccess();
                                                }
                                            },
                                            onError: function () {
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                }

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                /** Doesn't use now */
                savePHToProceed: function (callbacks) {
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                //if ($scope.$parent.isLoggedIn())
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                savePHContactCallback: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {

                            onSuccess: function () {
                                //if ($scope.$parent.isLoggedIn())
                                fn.setAssetMaxarPriority("s&e");
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function () {
                                        if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                        {
                                            fn.confirmationApi.createConfirmation(
                                                {
                                                    onSuccess: function () {
                                                        fn.utils.setIsSaving(false);
                                                        try {
                                                            $scope.$parent.gaEvents(2, 3);
                                                        }
                                                        catch (e) { }
                                                        $scope.$parent.maxarCallbackTrigger = true;

                                                        /* Callback action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.getMaxarPriority("cb");
                                                        //fn.postToMaxParent('Yes',2,'Y');

                                                        DaModal.close('#quotationCallback');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        } else {

                                            fn.essentialApi.partialSave(
                                                {
                                                    onSuccess: function (data) {

                                                        fn.utils.setIsSaving(false);
                                                        try {
                                                            $scope.$parent.gaEvents(2, 3);
                                                        }
                                                        catch (e) {
                                                        }

                                                        $scope.$parent.maxarCallbackTrigger = true;

                                                        /* Callback action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.getMaxarPriority("cb");
                                                        DaModal.close('#quotationCallback');
                                                    },

                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                });

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

            },

            validations: {

                validateForms: function () {
                    var isCoverFormInvalid = !fn.utils.getSelectedCoverage();
                    return isCoverFormInvalid ||
                        $scope.frmAboutYou.$invalid;
                },

                hasInvalid: function hasInvalid() {
                    var isInvalid = false;
                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    var focusError = function () {
                        FormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;

                },

                isCoverNotAvail: function (coverageTypeId) {
                    return HKVehicleValidationService.isCoverNotAvail($scope.asset.PurchasingYear, 1, coverageTypeId);
                }
            },

            eventHandlers: {

                updateDeductibleAsset: function () {
                    //DaModal.open("#daLoadingMask");
                    fn.utils.setIsSaving(true);

                    // Type 1
                    for (var i in $scope.coverages.Coverages) {
                        if ($scope.coverages.Coverages[i].CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG) {
                            $scope.coverages.Coverages[i].CoverTypeId.ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                            for (var j in $scope.coverages.Coverages[i].Covers) {
                                if ($scope.coverages.Coverages[i].Covers[j].CoverName === "Voluntary Basic Cover") {
                                    $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                                }
                            }
                        }
                    }

                    fn.assetApi.updateAsset(
                        {
                            onSuccess: function () {

                                var callbacks = {
                                    onSuccess: function () {
                                        fn.saving.calculatePremium(
                                            {
                                                onSuccess: function () {
                                                    fn.retrieveScopeObjects();
                                                    //fn.utils.setIsSaving(false);

                                                },

                                                onError: function () {
                                                    //fn.utils.setIsSaving(false);
                                                }
                                            }
                                        );
                                    }
                                };

                                fn.saving.saveCoveragesModel(callbacks);

                            },
                            onError: function () {

                            }
                        }
                    );
                },

                sendDefaultExcessGTM: function () {
                    if (!$scope.selectedCoverage) { return; }
                    try {
                        if ($scope.selectedCoverage.CoverTypeId != AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG) {
                            if ($scope.selectedCoverage.excess && $scope.selectedCoverage.ExcessLevelId) {
                                var exc = $filter('filter')($scope.selectedCoverage.excess, {
                                    key: $scope.selectedCoverage.ExcessLevelId,

                                }, true);
                                if (exc && exc[0].value)
                                    $scope.dLCE(12, exc[0].value);
                                else
                                    $scope.dLCE(12, 'NA');
                            } else {
                                $scope.dLCE(12, 'NA');
                            }
                        } else { //Compulsory
                            $scope.dLCE(12, 'NA');
                        }

                    }
                    catch (e) {
                        console.log(e);
                    }
                },

                resetCompulsory: function () {
                    if (!$scope.coverages) return;
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                        /*CCB*/
                        var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000161
                        }, true);
                        if (ccb) {
                            ccb[0].IsSelected = !ccb[0].IsSelected;
                        }

                        /*Death & Dismemberment or permanent disability*/
                        var ddpd = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000101
                        }, true);
                        if (ddpd) {
                            ddpd[0].IsSelected = !ddpd[0].IsSelected;
                        }

                        /*Bodily Injury or affecting health*/
                        var bih = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000102
                        }, true);
                        if (bih) {
                            bih[0].IsSelected = !bih[0].IsSelected;
                        }
                    }
                    $scope.IsCompulsarySelectedFrontEnd = !$scope.IsCompulsarySelectedFrontEnd;
                },

                selectCoverage: function (c) {
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                        $scope.coverages.Coverages[i].IsSelected = false;
                    }
                    c.IsSelected = true;
                    $scope.$parent.PlanId = $scope.asset.PlanId;
                    if (c.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG) {
                        /*don't need to show excess for "Type 1"  in desc.*/
                        fn.coverageApi.getVehicleExcess(
                            {
                                onSuccess: function (data) {
                                    if (data && data[0].value) {
                                        $scope.selectedCoverage.excess = data;
                                        c.Covers.forEach(function (xx, n) {
                                            if (xx.ProductLineGroupId == AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {
                                                $scope.selectedCoverage.ExcessLevelId = xx.ExcessLevelId;
                                            }
                                        });
                                    }
                                },
                                onError: function () {
                                }
                            }
                        );
                    }

                    if (c.CoverTypeId == AppConstants.AGGConfig.TYPE3_FALCON_DG || c.CoverTypeId == AppConstants.AGGConfig.TYPE2_FALCON_DG) {
                        $scope.asset.UseMyWorkshop = false; //sets to Panel
                    } else if (c.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG || c.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG || c.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG) {
                        var chk = $filter('filter')(c.Covers, { ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUPID_FALCON_2000116 }, true);
                        if (chk) {
                            if (chk[0]) {
                                if (chk[0].IsSelected === true) {
                                    $scope.asset.UseMyWorkshop = true;
                                }
                            }
                        } else {
                            $scope.asset.UseMyWorkshop = false;
                        }
                    }

                    var cc = fn.utils.getSelectedCoverage();
                    $scope.coverNum = cc.Covers.length;
                    //$scope.coverNum = 0;
                    $scope.coverDetailsClicked = true;
                    $scope.isCompulsoryOnly = false;
                    $scope.totalPremium = fn.calculateTotalAmout();

                    /*slide down to correct type , if only its display on small screen*/
                    if ($window.matchMedia) {
                        var mq = $window.matchMedia("(max-width:991px)");
                        if (mq) {
                            if (mq.matches) {
                                var anc = '#dacover_' + c.CoverTypeId;

                                /*timeout cos the box expands and thus creates problems with top-margin calc*/
                                $timeout(function () {
                                    fn.gtAnchor(anc);
                                }, 100);
                            }
                        }
                    }
                    fn.utils.setSumInsured();
                },

                onBtnNextClicked: function () {

                    try {
                        $scope.$parent.gaEvents(0, 0);
                    } catch (e) { }
                    $scope.pageSubmitted = true;

                    if ($scope.isStartDateInvalid()) {
                        return;
                    }

                    $scope.DaLoadingMask.toggle(true);
                    /** Scenario 2 with all calculates */
                    var preCallbacks1 = {
                        onSuccess: function () {
                            fn.updateAssetMaxarPriority(callbacks1);
                        }
                    };

                    var callbacks1 = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacks1a);
                        }
                    };

                    var callbacks1a = {
                        onSuccess: function () {
                            fn.saving.saveCurrentProposal(callbacks2);
                        }
                    };

                    var callbacks2 = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPH(callbacks3);
                                    },
                                    onError: function () {
                                        fn.saving.saveOtherDetailsPH(callbacks3);
                                    }
                                }
                            );
                        }
                    };

                    var callbacks3 = {
                        onSuccess: function () {
                            fn.navigation.goToNextStep();
                        }
                    };

                    // Test for navigate next instantaneous -- roll back test
                    fn.saving.saveCoveragesModel(preCallbacks1);

                },

                onBtnBackClicked: function () {
                    try {
                        $scope.$parent.gaEvents(0, 1);
                    }
                    catch (e) { }

                    $scope.pageSubmitted = true;
                    fn.utils.setIsSaving(true);
                    var preCallbacks1 = {
                        onSuccess: function () {
                            fn.updateAssetMaxarPriority(callbacks1);
                        }
                    };

                    var callbacks1 = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacks2);
                        }
                    };

                    var callbacks2 = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPH(callbacks3);
                                    },
                                    onError: function () {
                                        fn.saving.saveOtherDetailsPH(callbacks3);
                                    }
                                }
                            );
                        }
                    };

                    var callbacks3 = {
                        onSuccess: function () {
                            fn.navigation.goToPrevStep();
                        }
                    };
                    // Change from callback1 to 3 for navigate instantaneously -- roll back test
                    fn.saving.saveCoveragesModel(preCallbacks1);

                },

                /***Click Save For Later btn*/
                onBtnCallbackClicked: function () {

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if ($scope.phContact.PrimaryPhoneNumber) {
                        if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                            $scope.phContact.PrimaryPhoneNumber = "";
                        } else {
                        }
                    }

                    fn.saving.saveCoveragesModel(
                        {
                            onSuccess: function () {
                                DaModal.open('#quotationCallback');
                            },
                            onError: function () {
                            }
                        }
                    );
                },

                onBtnCallbackContinueClicked: function () {

                    $scope.pageSubmitted = true;
                    $scope.callBackSubmitted = true;

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    if ($scope.policyHolder.FirstName.invalid || $scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }
                    fn.saving.savePHContactCallback();
                },

                onBtnCallbackCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        $scope.phContact.PrimaryPhoneNumber = "";
                    }

                    DaModal.close('#quotationCallback');
                },

                onBtnCoverExplainedClicked: function () {
                    //marketing.gaEvents(2);
                    DaModal.open('#coverExplained');
                },

                onBtnCoverExplainedSelected: function () {
                    DaModal.close('#coverExplained');
                },

                onBtnSaveAndExitClicked: function () {

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if ($scope.phContact.PrimaryPhoneNumber) {
                        if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                            $scope.phContact.PrimaryPhoneNumber = "";
                        } else {
                        }
                    }

                    try {
                        $scope.$parent.gaEvents(0, 2);
                    }
                    catch (e) { }
                    fn.saving.saveCoveragesModel(
                        {

                            onSuccess: function () {
                                DaModal.open('#quotationPartialSave');
                            },
                            onError: function () {
                            }
                        }
                    );
                },

                /*** Click partial save button after display pop up email */
                onBtnPartialSaveContinueClicked: function () {

                    $scope.pageSubmitted = true;
                    $scope.partialSaveSubmitted = true;

                    /*** to check first name */
                    if (!$scope.policyHolder.FirstName) {
                        return;
                    }

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    /*** To check email */
                    if ($scope.phContact.PrimaryEmailAddress.invalid) {
                        return;
                    }

                    fn.saving.savePHContact();
                },

                /*** Cancel button for pop up email */
                onBtnPartialSaveCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if ($scope.phContact.PrimaryPhoneNumber) {
                        if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                            $scope.phContact.PrimaryPhoneNumber = "";
                        } else {
                        }
                    }
                    DaModal.close('#quotationPartialSave');
                },

                onBtnSelectClicked: function (selectedCoverage) {
                    fn.utils.setSelectedCoverage(selectedCoverage);
                    DaModal.open('#modalPlanMessage');
                },

                /** Doesn't use now */
                onPolicyExcessChanged: function () {
                    fn.utils.setIsSaving(true);
                    var policyExcessChangedBILog = function () {
                        try {
                            if (!$scope.currentProposal.IsRenewalProposal) {
                                angular.extend($scope.bidata, $scope.coverages);
                                fn.logApi.updateLog();
                            }
                        } catch (err) {
                        }
                    };

                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        policyExcessChangedBILog();
                                        fn.retrieveScopeObjects();
                                        fn.utils.setIsSaving(false);
                                    },

                                    onError: function () {
                                        policyExcessChangedBILog();
                                        fn.utils.setIsSaving(false);
                                    }
                                }
                            );
                        }
                    };

                    fn.saving.saveCoveragesModel(callbacks);
                },

                onFullDetailClicked: function (totalNum) {
                    if ($scope.coverDetailsClicked) {
                        $scope.coverNum = totalNum;

                    } else {
                        $scope.coverNum = 0;
                    }


                    $scope.coverDetailsClicked = !$scope.coverDetailsClicked;
                    //marketing.gaEvents(1);
                },

                onFocusReEmail: function () {
                    $scope.IsReEmailMismatch = false;
                }

            },

            utils: {
                getExcessValue: function (val) {
                    var exc = $filter('filter')($scope.selectedCoverage.excess, {
                        key: val,

                    }, true);
                    if (exc) {
                        if (exc[0])
                            return exc[0].value;
                    } else {
                        return 0;
                    }

                },

                setSumInsured: function () {

                    $scope.sumInsured.value = 0;
                    $scope.sumInsured.visible = false;
                    var sc = fn.utils.getSelectedCoverage();
                    if (!sc) { return; }
                    if (sc.CoverTypeId == AppConstants.AGGConfig.TYPE3_FALCON_DG) {
                        /*Type 3 is always NA and hidden*/
                        $scope.sumInsured.visible = false;
                        $scope.sumInsured.value = 0;

                        return;
                    }

                    if (sc.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG
                        || sc.CoverTypeId == AppConstants.AGGConfigTYPE2PLUS_FALCON_DG
                        || sc.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG) {

                        /*Type 2+/ 3+ / Type 1 - value from own damage collision*/
                        /*Type 1- own damage collision value.*/
                        var odc = $filter('filter')(sc.Covers, {
                            ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA
                        }, true);
                        if (odc && odc[0].InsuranceAmount) {

                            $scope.sumInsured.value = odc[0].InsuranceAmount;
                            $scope.sumInsured.visible = true;
                        }
                        else {
                            $scope.sumInsured.value = 0;
                            $scope.sumInsured.visible = false;
                        }

                        return;
                    }

                    if (sc.CoverTypeId == AppConstants.AGGConfig.TYPE2_FALCON_DG) {

                        /*Type 2- own damage Fire and theft value*/
                        var odft = $filter('filter')(sc.Covers, {
                            ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA
                        }, true);
                        if (odft && odft[0].InsuranceAmount) {

                            $scope.sumInsured.value = odft[0].InsuranceAmount;
                            $scope.sumInsured.visible = true;
                        }
                        else {
                            $scope.sumInsured.value = 0;
                            $scope.sumInsured.visible = false;
                        }
                        return;
                    }

                    if (sc.CoverTypeId == AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG) {
                        /*Type Compulsory only*/
                        $scope.sumInsured.visible = false;
                        $scope.sumInsured.value = 0;

                        return;
                    }

                    /*Exception- Everything fails- hide*/
                    $scope.sumInsured.visible = false;
                    $scope.sumInsured.value = 0;

                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    $scope.policyHolder.OccupationId = $scope.mainDriver.OccupationId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.IsDemeritPoints = $scope.mainDriver.IsDemeritPoints;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;
                    $scope.policyHolder.FirstName = $scope.mainDriver.FirstName;
                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;
                    $scope.mainDriver.FirstName = $scope.policyHolder.FirstName;
                },

                IsCompulsarySelectedFrontEndFn: function () {
                    var status = false;
                    if ($scope.coverages) {
                        for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                            var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                                ProductLineGroupId: 2000161,
                            }, true);
                            if (ccb) {
                                if (ccb[0].IsSelected) {
                                    status = true;
                                    marketing.dLCE(11, true);
                                } else {
                                    status = false;
                                    marketing.dLCE(11, false);
                                }
                            }

                        }
                    }


                    return status;
                },

                getPriceToShow: function (c) {
                    if (!c) { return 0; }
                    //console.log(c.CoverTypeId);
                    var cx = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: c.CoverTypeId, PlanId: $scope.asset.PlanId })[0];
                    var baseprice = cx.CalculatedPremium;

                    var ccb = $filter('filter')(cx.Covers, { ProductLineGroupId: 2000161, IsSelected: true }, true);
                    if (cx.CoverTypeId !== AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG && ccb.length === 0) {
                        return baseprice;
                    }
                    if (ccb) {
                        if (ccb[0]) {
                            if (ccb[0].TotalPremiumToColl) {
                                total = baseprice + ccb[0].TotalPremiumToColl;
                            }
                        }
                    }
                    return total;
                },

                /*** Currently use for disable some mandatory fields, however it's possible to remove it */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                setIsSaving: function (isSaving) {
                    if (isSaving !== $scope.toggle) {
                        $scope.toggle = isSaving;
                        if ($scope.partialSaveSubmitted) {
                            DaModal.open("#daLoadingMaskPartialSave");
                        } else if ($scope.callBackSubmitted) {
                            DaModal.open("#daLoadingMaskCallBack");
                        } else {
                            $scope.loadingSpinner = isSaving;
                            $scope.loadingSpinner2 = isSaving;
                            $scope.loadingSpinner3 = isSaving;
                        }
                    }
                },

                closeIsSaving: function () {
                    if ($scope.partialSaveSubmitted) {
                        DaModal.close("#daLoadingMaskPartialSave");
                    } else if ($scope.callBackSubmitted) {
                        DaModal.close("#daLoadingMaskCallBack");
                    } else {
                        DaModal.close("#daLoadingMask");
                    }
                },

                setSelectedCoverage: function (selectedCoverage) {
                    $scope.selectedCoverage = selectedCoverage;
                    $scope.isBtnProceedDisabled = false;
                    $scope.isCoverSelected = true;
                },

                setDefaultCoverage: function () {
                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE1_FALCON_DG, PlanId: $scope.asset.PlanId })[0];
                    //console.log(selectedCoverage);
                    if (selectedCoverage) {
                        selectedCoverage.IsSelected = true;
                        $scope.currentSelectedCoverage = selectedCoverage.CoverTypeId;
                        marketing.dLCE(10, 'Voluntary Type 1');
                        marketing.dLCE(11, true);
                    } else {
                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG, PlanId: $scope.asset.PlanId })[0];
                        $scope.currentSelectedCoverage = selectedCoverage.CoverTypeId;
                        selectedCoverage.IsSelected = true;
                        marketing.dLCE(10, 'Voluntary Type 2+');
                        marketing.dLCE(11, true);
                    }
                },

                /** Doesn't use now */
                addExtraComprehensiveCoverforDisplay: function () {
                    var coverageCovers = $scope.coverages.Coverages;
                    var comprehensiveCoverlist = {};
                    var FinalComprehensiveCoverList = [];

                    for (var i = 0; i < coverageCovers.length; i++) {
                        if (coverageCovers[i].CoverTypeId === 1) // only when it is comprehensive
                        {
                            comprehensiveCoverlist = coverageCovers[i].Covers;

                            for (var k = 0; k < comprehensiveCoverlist.length; k++) {

                                var cover = comprehensiveCoverlist[k];

                                if (cover.ProductLineGroupId === 1000102)  // before Damage to own car (No other vehicle involved)
                                {
                                    var ownDamageCollisionCover = {
                                        "CoverName": "Collision with third party vehicle - Comprehensive[Added]",
                                        "IsSelected": true,
                                        "ProductLineGroupId": 1000103,
                                        "ProductLineTypeString": "Mandatory",
                                        "ProductLineOptionId": 2000809, "ProductLineType": 0
                                    };
                                    FinalComprehensiveCoverList.push(ownDamageCollisionCover);
                                }
                                FinalComprehensiveCoverList.push(cover);
                            }
                            $scope.coverages.Coverages[i].Covers = FinalComprehensiveCoverList;
                        }
                    }
                },

                getSelectedCoverage: function () {

                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { IsSelected: true })[0];

                    if (selectedCoverage) {
                        $scope.selectedCoverage = selectedCoverage;
                    }
                    else {
                        $scope.selectedCoverage = null;
                    }

                    return selectedCoverage;
                },

                /** Doesn't use now */
                filterBaseCover: function (r) {
                    var status = false;
                    if (r.ProductLineGroupId === 1000087) {
                        status = true;
                    }
                    return status;
                },

                /** Doesn't use now */
                filterMandatoryCovers: function (r) {

                    var status = false;
                    if (r.ProductLineType === '0' || r.ProductLineType === 0) {

                        if (r.CoverName === 'Basic Cover' || r.ProductLineGroupId === 1000087) {
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000108) { //policyExcess
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000109) {//Administration Fee
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000059) {//Recovery Expenses - TPO
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000060) {//Legal Expenses - TPO
                            status = false;
                        }
                        else {
                            status = true;
                        }

                    } else {
                        status = false;
                    }

                    return status;

                },

                filterOptionalButCompulsoryCovers: function (r) {

                    var status = false;

                    if (r.ProductLineType === '2' || r.ProductLineType === 2) {
                        if (r.ProductLineGroupId === 2000161 || r.ProductLineGroupId === 2000114) { //Complusory Basic Cove
                            status = false;
                        }
                        else {
                            if (r.IsSelected) {
                                status = true;
                            } else {
                                status = false;
                            }
                        }

                    } else {

                        if (r.ProductLineType === '0' || r.ProductLineType === 0) {

                            /*hides permanent disable for passenger as is merged with driver in copy*/
                            if (r.ProductLineGroupId === 2000101 || r.ProductLineGroupId === 2000102 || r.ProductLineGroupId === 2000112 || r.ProductLineGroupId === 2000107) {
                                status = false;
                            }
                            else {
                                /*always hide Compulsory Core Benefits, Core Benefits*/
                                if (r.ProductLineGroupId === 2000100 || r.ProductLineGroupId === 2000160) {
                                    status = false;
                                }
                                else {
                                    status = true;
                                }
                            }
                        } else {

                            if (r.ProductLineType === '1' || r.ProductLineType === 1) {
                                if (r.ProductLineGroupId === 2000103 || r.ProductLineGroupId === 2000116) { // VBC/MyWorkshop
                                    status = false;
                                }
                                else {
                                    status = true;
                                }
                            } else {
                                status = true;
                            }
                        }
                    }
                    return status;
                },

                setMaxarPriorRev3: function (coverage, callback) {
                    var defaultPriorityJudge = function () {
                        if (coverage == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG) {
                            $scope.newMaxarPrior = 4;
                            callback();
                        } else {
                            $scope.newMaxarPrior = 5;
                            callback();
                        }
                    }

                    
                    if ($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG && $scope.$parent.ppstEnable) {
                        fn.getPriority(function (priority) {
                            
                                if (priority != 0) {
                                    $scope.newMaxarPrior = priority;
                                    fn.postToMaxParent('No', priority, 'N');
                                    callback();
                                } else {
                                    defaultPriorityJudge();
                                }
                            
                        });
                    } else {
                        defaultPriorityJudge();
                    }

                },
                selectIAmount: function (iAmount) {
                    $scope.selectedInsureAmount = iAmount;

                    $scope.coverages.Coverages.forEach(function (cc, xx) {
                        if ((cc.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG || cc.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG) && cc.IsSelected == true) {
                            $scope.coverages.Coverages[xx].Covers.forEach(function (plo, yy) {
                                if (plo.ProductLineGroupId == AppConstants.AGGConfig.TYPE1_FALCON_DG) {
                                    $scope.coverages.Coverages[xx].Covers[yy].InsuranceAmountLevelId = iAmount;
                                    fn.saving.saveCoveragesModel({
                                        onSuccess: function () {

                                            fn.utils.setIsSaving(true);
                                            fn.quoteApi.calculate({
                                                onSuccess: function () {
                                                    fn.retrieveScopeObjects();
                                                },
                                                onError: function () {

                                                }
                                            });
                                        },
                                        onError: function () {
                                        }
                                    });

                                }
                            });
                        }

                    });
                },
                setGAIAmount: function (iAmount) {
                    marketing.dLCE(50, iAmount);
                },
                setDefaultInsureAmount: function () {
                    var sc = fn.utils.getSelectedCoverage();
                    if (sc.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG || sc.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG) {
                        var plo = $filter('filter')(sc.Covers, { ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA });
                        if (plo && plo[0]) {
                            $scope.selectedInsureAmount = plo[0].InsuranceAmountLevelId;
                            fn.utils.setGAIAmount(plo[0].InsuranceAmount);
                        }
                    }
                },

                selectMyWorkshopOption: function () {
                    fn.utils.setIsSaving(true);

                    fn.updateAssetMaxarPriority({
                        onSuccess: function () {

                            fn.utils.setIsSaving(true);
                            var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                            $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                            fn.currentProposalApi[saveCurrentProposalFunc](
                                {
                                    onSuccess: function () {
                                        fn.quoteApi.calculate({
                                            onSuccess: function () {
                                                fn.retrieveScopeObjects();
                                            }
                                        });
                                    },
                                    onError: function () {
                                        $scope.hasError = true;
                                        fn.utils.setIsSaving(false);
                                    }
                                }
                            );
                        }
                    });
                },
                convertMITDate: function () {
                    if (typeof $scope.currentProposal.PolicyStartDate_DD !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_MON !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_Year !== "undefined") {

                        $scope.$parent.setDateValidity($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year, $scope.frmCarDetails.policyStartDateDD);

                        $scope.currentProposal.PolicyStartDate = $scope.$parent.convertDateThreeToOne($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year);
                        $scope.currentProposal.PolicyStartDate == null ? ($scope.currentProposal.PolicyStartDate = '') : '';

                        if ($scope.currentProposal.PolicyStartDate) {
                            var dateToday = MitDate.newDate();
                            var pdate = MitDate.newDate($scope.currentProposal.PolicyStartDate);
                            var timeDiff = (dateToday - pdate);
                            var diff = Math.ceil(timeDiff / (1000 * 3600 * 24));
                            marketing.dLCE(20, -1 * diff);
                        }
                    }
                },
                isStartDateInvalid: function () {
                    if (!$scope.currentProposal.PolicyStartDate) return;
                    var startDate = MitDate.newDate($scope.currentProposal.PolicyStartDate);
                    var isMinConditionValid = startDate.diff($scope.policyMinStartDate, 'days') >= 0;
                    var isMaxConditionValid = startDate.diff($scope.policyMaxStartDate, 'days') <= 0;
                    if (!isMinConditionValid) {
                        $scope.frmCarDetails.policyStartDateDD.$setValidity('custom', true);
                        return true;
                    }

                    if (isMaxConditionValid) {
                        $scope.frmCarDetails.policyStartDateDD.$setValidity('custom', true);
                        return false;

                    } else {
                        $scope.frmCarDetails.policyStartDateDD.$setValidity('custom', false);
                        return true;
                    }
                },
                ConvertMITDateToDisplay: function () {
                    if ($scope.currentProposal.PolicyStartDate) {
                        var d = $scope.$parent.convertDateOneToThree($scope.currentProposal.PolicyStartDate);
                        if (d != null || d.length > 0) {
                            $scope.currentProposal.PolicyStartDate_DD = d[0];
                            $scope.currentProposal.PolicyStartDate_MON = d[1];
                            $scope.currentProposal.PolicyStartDate_Year = d[2];
                        }
                    }
                },
                setPlan: function () {
                    fn.utils.setIsSaving(true);

                    fn.assetApi.updateAsset({
                        onSuccess: function () {

                            var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                            $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                            fn.currentProposalApi[saveCurrentProposalFunc]({
                                onSuccess: function () {
                                    fn.saving.calculatePremium(
                                        {
                                            onSuccess: function () {
                                                fn.retrieveScopeObjects();

                                            },

                                            onError: function () {
                                            }
                                        }
                                    );
                                }
                            })

                        },
                        onError: function () {

                        }
                    });
                    
                }
            },
            getPriority: function (callback) {
                $window.ppstLayer = $window.ppstLayer || $localStorage.ppstLayer;
                var ppstLayer = $window.ppstLayer;
                if ($scope.mainDriver.GenderId !== undefined) {
                    var gender = $scope.mainDriver.GenderId == 1 ? "FEMALE" : "MALE";
                } else {
                    var gender = $scope.policyHolder.GenderId == 1 ? "FEMALE" : "MALE";
                }

                if ($scope.mainDriver.MaritalStatusId !== undefined) {
                    var marital = $scope.mainDriver.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                } else {
                    var marital = $scope.policyHolder.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }

                if ($scope.asset.UsageId == 6) {
                    var carUsage = "PRIVATE_USE";
                } else if ($scope.asset.UsageId == 1000000) {
                    var carUsage = "PRIVATE_USE_AND_COMMUTING_TO_WORK";
                } else if ($scope.asset.UsageId == 1000002) {
                    var carUsage = "PRIVATE_AND_BUSINESS_USE";
                } else {
                    var carUsage = "COMMERCIAL_SPECIAL";
                }

                if($scope.mainDriver.DateOfBirth !== undefined){
                    var dob = $scope.mainDriver.DateOfBirth;
                }else{
                    var dob = $scope.policyHolder.DateOfBirth;
                }

                var premium = fn.getTotalAmout();
                if (premium) {
                    ppstLayer.push({
                        "Proposal": $scope.currentProposal.ExternalProposalNumber,
                        "PhoneNumber": $sessionStorage.maxarReference,
                        "DateOfBirth": dob,
                        "Gender": gender,
                        "MaritalStatus": marital,
                        "VehicleUsage": carUsage,
                        "MakeID": $scope.asset.ManufactureId,
                        "ModelID": $scope.asset.ModelId,
                        "YearOfRegistration": $scope.asset.PurchasingYear,
                        "CarDescriptionID": $scope.asset.CarDescriptionId,
                        "Premium": premium,
                        "NumberOfDataChanges": $sessionStorage.noOfPTSave
                    });

                    $localStorage.ppstLayer = ppstLayer;

                    $scope.$parent.getPriority(function (res) {
                        if (res) {
                            callback(res);
                        } else {
                            callback(0);
                        }

                    });
                }

            }
        };

        /*** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.retrieveScopeObjects();
        fn.initSubscribeWatcher();

        /*** Event Handlers */
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnSelectClicked = fn.eventHandlers.onBtnSelectClicked;
        $scope.onPolicyExcessChanged = fn.eventHandlers.onPolicyExcessChanged;

        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;
        $scope.selectCoverage = fn.eventHandlers.selectCoverage;
        $scope.resetCompulsory = fn.eventHandlers.resetCompulsory;
        $scope.onBtnCoverExplainedClicked = fn.eventHandlers.onBtnCoverExplainedClicked;
        $scope.onBtnCoverExplainedSelected = fn.eventHandlers.onBtnCoverExplainedSelected;
        $scope.selectIAmount = fn.utils.selectIAmount;
        $scope.selectMyWorkshopOption = fn.utils.selectMyWorkshopOption;
        $scope.convertMITDate = fn.utils.convertMITDate;
        $scope.isStartDateInvalid = fn.utils.isStartDateInvalid;
        $scope.getExcessValue = fn.utils.getExcessValue;

        $scope.isCoverNotAvail = fn.validations.isCoverNotAvail;
        $scope.setPlan = fn.utils.setPlan;
        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.onFullDetailClicked = fn.eventHandlers.onFullDetailClicked;
        $scope.onFocusReEmail = fn.eventHandlers.onFocusReEmail;
        $scope.updateDeductibleAsset = fn.eventHandlers.updateDeductibleAsset;
        $scope.gaEvents = $scope.$parent.gaEvents;
        /*** Utils */
        $scope.utils = {
            filterBaseCover: fn.utils.filterBaseCover,
            filterMandatoryCovers: fn.utils.filterMandatoryCovers,
            filterOptionalButCompulsoryCovers: fn.utils.filterOptionalButCompulsoryCovers
        };

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.getPriceToShow = fn.utils.getPriceToShow;
        $scope.getTotalAmout = fn.getTotalAmout;

        $scope.dLCE = marketing.dLCE;

        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function () {
            $timeout(function () {
                $scope.$parent.changeGlobalContactNo();
            }, 1000);
        });


    }];