/*
 * Created by Nuwan Amarasinghe on 02/01/15.
 */
module.exports = [
  '$scope',
  '$rootScope',
  '$state',
  '$filter',
  'DaModal',
  'FormValidation',
  'DaLoadingMask',
  'WfeApiSession',
  'HKSteps',
  'WfeApiStaticData',
  'WfeApiCurrentProposal',
  'WfeApiAsset',
  'WfeApiCoverages',
  'WfeApiQuote',
  'WfeApiConfirmation',
  'HKLocale',
  'MITDate',
  '$window',
  '$http',
  '$sessionStorage',
  'Session',
  'Idle',
  '$cookies',
  '$localStorage', 
  'ExternalService',
  'AppConstants',
  /**
   * The controller that handles the Motor quote current step number.
   * This is also responsible on step navigation marking of active tab.
   * @param $scope
   * @param $state
   * @constructor
   */
  function B2CQuoteStepsController($scope, $rootScope, $state, $filter, DaModal, FormValidation, DaLoadingMask, WfeApiSession, HKSteps, WfeApiStaticData, WfeApiCurrentProposal,WfeApiAsset, WfeApiCoverages, WfeApiQuote, WfeApiConfirmation, HKLocale, MITDate, $window, $http, $sessionStorage, Session, Idle, $cookies, $localStorage, ExternalService, AppConstants) {

      //for data layer add on fields
      var marketing = {

        dLCE:function(ref,v)
        {
            $window.dataLayer = $window.dataLayer || [];
            var dataLayer = $window.dataLayer;

            console.log(ref);
            switch(ref)
            {
                case 46:
                    if(v==1000001 || v== '1000001' ) {s='Single Payment'};
                    if(v==1000005 || v== '1000005' ) {s='Installment'};
                    dataLayer.push({
                    'event':'dataLayer-initialised',
                    'financial-option':s
                });
                    break;                  
                default: break;
            }

            // console.log(dataLayer);
        }
      }; 

      var fn = {
        /** functions form base controller : WTH didn't use baseController*/
        product: {
          ProductId: AppConstants.AGGConfig.PRODUCT_ID_DA,
          ProductCode: 'motor',
          ProductHeader: $scope.HKLocale.getResource('car_h_product_name'),
          isRenewable: false,
          isBILogEnabled: true
        },

        initScopeObjects: function () {
          $scope.product = fn.product;
          $scope.sessionApi = WfeApiSession.instance($scope,fn.product.ProductCode);
          $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);

          $scope.asiapay = {};
          $scope.coverages = {};
          $scope.selectedCoverage = {};
          $scope.selectedPayment = "";
          $scope.maxarReference = '';
          $scope.maxarObject = {};
          $scope.maxarCallbackTrigger = false;
          $scope.bi = {};

          $scope.staticData = {};
          $scope.staticData.sourceChannels = [];
          $scope.staticData.paymentTerms = [];
          $scope.paymentTerms = {};
          $scope.gaCampaign = "";
          $scope.applyNCBRoot;
          $scope.applyNCBRootDontKnow;
          $scope.showPriceOnTopFlag = false;
          $scope.PlanId = 1000003; //default plan as value plus

          $scope.CurrentDate_DD;
          $scope.CurrentDate_MON;
          $scope.CurrentDate_Year;
          $scope.CurrentDate;
          $scope.currentdate = new Date();
          $scope.currentProposal = {};
          $scope.paymentTermSelectedId = "";
          $scope.PaymentSchedules = [];
          $scope.FirstPayment;
          $scope.MonthlyPremium;
          $scope.TotalPremium;
          $scope.paymentConsentPrivacyFlag = false;
          
          $scope.loadingSpinner4 = false;
          $scope.asset = {};
          $scope.creditCard = {};
        },

        initApiObjects: function () {
          fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.product.ProductId);
          fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.sessionApi, HKLocale.getLocale(), $scope.currentProposal);

          $scope.asset.ProductId = $scope.product.ProductId;
          fn.assetApi = WfeApiAsset.instance($scope.sessionApi, $scope, $scope.asset);


          fn.coverageApi = WfeApiCoverages.instance($scope.sessionApi, $scope, $scope.coverages);
          fn.quoteApi = WfeApiQuote.instance($scope.sessionApi, $scope);
          fn.confirmationApi = WfeApiConfirmation.instance($scope.sessionApi, $scope);
        },

        initStaticData: function () {

          var getSourceChannels = function () {
              fn.wfeApiStaticData.getSourceChannels({
                  onSuccess: function (data) {
                    $scope.staticData.sourceChannels = data;
                  },
                  onError: function () {
                    $scope.staticData.sourceChannels = {};                   
                  },
              });
          };
             getSourceChannels();
          },

        /*
          updated by Monthathip Y. , 3 Mar 2022
          JIRA Ticket : DATH-6412 , premium financing (installment)
          desc : fetch CurrentProposal
        */
        retrieveScopeObjects: function () {

          fn.assetApi.retrieveAsset({
            onSuccess: function (data) {
                
                angular.extend($scope.asset, data);

                if ($scope.asset.CarDescriptionId) {
                  var objParams = {};
                  objParams.modelId = $scope.asset.ModelId;
                  objParams.purchasingYear = $scope.asset.PurchasingYear;
                  var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                  
                  wfeApiStaticDataModel.getCarDescWithYear({
                      onSuccess: function (data) {
              
                          var txt2door = "";
                          filteredCarDescription = $filter('filter')(data, function (r) {
                              if($scope.asset.CarDescriptionId === r.key){
                                  txt2door = r.description.toLowerCase();
                                  if(txt2door.includes("2door")){
                                      $scope.bodyType = "2door";
                                  }
                                  
                              }
                          });
              
                      }
                  });
              }

                //Set PlanId for parent
                $scope.PlanId = $scope.asset.PlanId;
            }
        });

          var retrieveCurrentProposal = function () {
            fn.currentProposalApi.retrieveCurrentProposal({
                onSuccess: function (data) {

                      angular.extend($scope.currentProposal, data);
                      $scope.FirstPayment = $scope.currentProposal.FirstPayment;
                      $scope.MonthlyPremium = $scope.currentProposal.MonthlyPremium;
                      $scope.TotalPremium = $scope.currentProposal.TotalPremium;
                      $scope.paymentTermSelectedId = $scope.currentProposal.PaymentTermId;
                      $scope.PaymentSchedules = $scope.currentProposal.PaymentSchedules;
                      getPaymentTermList();
                },
                onError: function () {
                    getPaymentTermList();
                },
            });
          };

          /*
              updated by Monthathip Y. , 3 Mar 2022
              JIRA Ticket : DATH-6412 , premium financing (installment)
              desc : fetch paymentTerms
          */
          var getPaymentTermList = function () {

              var param = 4;              
              var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), param);
              wfeApiStaticDataModel.getPaymentTermList({
                  onSuccess: function (data) {
                      $scope.paymentTerms = data;
                      if ($scope.HKLocale.getLocale() === "th-th") {

                              var paymentTermId = $scope.paymentTerms.find(x => x.key == "1000001")
                              var index = $scope.paymentTerms.indexOf(paymentTermId)
                              $scope.paymentTerms[index].value = "ชำระเต็มจำนวน";

                              var paymentTermId = $scope.paymentTerms.find(x => x.key == "1000005")
                              var index = $scope.paymentTerms.indexOf(paymentTermId)
                              $scope.paymentTerms[index].value = "ผ่อนชำระ 10 งวด";

                      }else {
                              var paymentTermId = $scope.paymentTerms.find(x => x.key == "1000001")
                              var index = $scope.paymentTerms.indexOf(paymentTermId)
                              $scope.paymentTerms[index].value = "Single Payment";

                              var paymentTermId = $scope.paymentTerms.find(x => x.key == "1000005")
                              var index = $scope.paymentTerms.indexOf(paymentTermId)
                              $scope.paymentTerms[index].value = "Installment";
                      }
                      retrieveCoverages();
                  },
                  onError: function () {
                    retrieveCoverages();
                  }
              });
          }

          var retrieveCoverages = function () {
            fn.coverageApi.retrieveCoverages({
              onSuccess: function (data) {

                  angular.extend($scope.coverages, data);
                  var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true});
                  if(selectedCoverage && selectedCoverage.length > 0) {
                    selectedCoverage = selectedCoverage[0]
                  }
                  var topItem = [];
                  var lowItem = [];

                  if (selectedCoverage) {
                      for(var i in selectedCoverage.Covers){
                          if(selectedCoverage.Covers[i]['ProductLineGroupId'] == AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA || selectedCoverage.Covers[i]['ProductLineGroupId'] == AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA){
                              topItem.push(selectedCoverage.Covers[i]);
                          }
                          else{
                              lowItem.push(selectedCoverage.Covers[i]);
                          }
                      }

                      selectedCoverage.Covers = topItem;
                      for(var j in lowItem) {
                          topItem.push(lowItem[j]);
                      }

                      angular.extend($scope.selectedCoverage, selectedCoverage);

                      var cc = selectedCoverage;
                      if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG){
                          $scope.currentCoverage = "1";
                      }
                      else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE1SAVE_FALCON_DG){
                          $scope.currentCoverage = "1s";
                      }
                      else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE2_FALCON_DG){
                          $scope.currentCoverage = "2";
                      }
                      else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG){
                          $scope.currentCoverage = "2p";
                      }
                      else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE3_FALCON_DG){
                          $scope.currentCoverage = "3";
                      }
                      else if(cc.CoverTypeId == AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG){
                          $scope.currentCoverage = "3p";
                      }
                  }
              },
              onError: function () {
                //do nothing
              }
            });
          };

          retrieveCurrentProposal();
        },

        gaEvents: function gaEvents(type) {
          try {
            $window.dataLayer = $window.dataLayer || [];
            var dataLayer = $window.dataLayer;
            var p = '';

            /** Next hits */
            if (type == 0) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Navigation',
                'label': 'Next',
              });
            }

            /** Back hits */
            if (type == 1) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Navigation',
                'label': 'Back',
              });
            }

            if (type == 2) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'step1-calculate',
                'label': 'Calculate',
              });
            }

            if (type == 3) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Navigation',
                'label': 'Add Named Driver',
              });
            }

            if (type == 4) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Select Language',
                'label': 'English',
              });
            }

            if (type == 5) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Select Language',
                'label': 'Thai',
              });
            }

            if (type == 6) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Navigation',
                'label': 'To review proposal',
              });
            }

            if (type == 7) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Navigation',
                'label': 'See panel details',
              });
            }

            if (type == 8) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Navigation',
                'label': 'See coverage details',
              });
            }

            if (type == 9) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Help Icon',
                'label': 'Deductible',
              });
            }

            if (type == 10) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Compare Covers',
                'label': 'Compare Covers',
              });
            }

            if (type == 11) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'See Full Cover Details',
                'label': 'Cover Type',
              });
            }

            if (type == 12) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Call',
                'label': 'Phone Number',
              });
            }

            if (type == 13) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'StartQuote',
                'label': 'Interaction',
              });
            }

            if (type == 14) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Download pdf cover details',
                'label': '',
              });
            }

            if (type == 15) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Input phone number box displayed',
              });
            }

            if (type == 16) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Request OTP',
              });
            }

            if (type == 17) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Cancel - input phone number',
              });
            }

            if (type == 18) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Input OTP box displayed',
              });
            }

            if (type == 19) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Verify code',
              });
            }

            if (type == 20) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Cancel – input OTP',
              });
            }

            if (type == 21) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'OTP failure box displayed',
              });
            }

            if (type == 22) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Re-request OTP',
              });
            }

            if (type == 23) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'cancel – OTP failure box',
              });
            }

            if (type == 24) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'OTP',
                'label': 'Successfully submitted',
              });
            }

            if (type == 25) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Terms and conditions',
                'label': 'Box displayed',
              });
            }

            if (type == 26) {
              dataLayer.push({
                'event': 'clickevent',
                'category': 'B2CPortal',
                'action': 'Terms and conditions',
                'label': 'Agreed',
              });
            }
          } catch (err) {}
        },

        sessionExpiredHandler: function () {
          // $scope.$parent.baseMitScopeBindingUtils.clearPAndCModelFromStorage();
          if ($sessionStorage) {
            if ($sessionStorage.fromTerminal) {
              if ($sessionStorage.fromTerminal == 1) {
                DaLoadingMask.toggle(false);
                $scope.$parent.baseMitScopeBindingUtils.clearPAndCModelFromStorage();
                $state.go('public.partnerterminal');
                return;
              }
            }
          }
          DaLoadingMask.toggle(false);
          $scope.sessionExpired = true;
          if(!$rootScope.contactPopUpOpened && !$rootScope.isThankYouPopUpOpended){
            DaModal.open('#sessionExpired', { onClose: fn.goToStart });
          }
        },

        setIsAccepted: function (isAcceptedParam) {
          $scope.stepsUtils.setIsAccepted(isAcceptedParam);
        },

        getIsAccepted: function () {
          return $scope.stepsUtils.getIsAccepted();
        },

        getBaseCover: function (cover) {
          if (cover.ProductLineGroupId === 1000087) {
            return true;
          }

          return false;
        },

        calculateTotalAmout: function (coversges) {

          if (!coversges || !coversges.Coverages) {
            return 0;
          }

          var coverage = $filter('filter')(coversges.Coverages,{ IsSelected: true },true)[0];
          if (!coverage) {
            return 0;
          }

          var baseCover = $filter('filter')(coverage.Covers,fn.getBaseCover)[0];
          if (!baseCover) {
            return;
          }

          var runningTotal = baseCover.TotalPremiumToColl;

          var optionalCovers = $filter('filter')(coverage.Covers,{ ProductLineType: 2, IsSelected: true },true);
          if (optionalCovers.length === 0) {
            return runningTotal;
          }

          /** "CoverName": "Accessories","ProductLineGroupId": 1000096 */
          angular.forEach(optionalCovers, function (cover) {
            if (cover.ProductLineGroupId !== 1000096) {
              runningTotal += cover.TotalPremiumToColl || 0.0;
            }
          });

          return runningTotal;
        },

        /** Maxar API function */
        postMaxar: function postMaxar() {
          var obj = {};
          $window.maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;
          var maxarLayer = $window.maxarLayer;

          for (var i = 0; i < maxarLayer.length; i++) {
            angular.extend(obj, maxarLayer[i]);
          }

          if (!$sessionStorage.maxarReference) {
            /* ref is blank */
            if (obj.referenceID) {
              $scope.maxarReference = obj.referenceID;
              $sessionStorage.maxarReference = obj.referenceID;
            }
          } else {
            /* maxar ref exist */
            if (!obj.referenceID) {
              obj.referenceID = $sessionStorage.maxarReference || '-';
            }
          }
          if (obj.referenceID.substr(0, 2) === '66') {
            obj.referenceID = '0' + obj.referenceID.substr(2);
          }

          if (obj.priority == 0) {
            return;
          }

          if (obj.priority == 99) {
            obj.priority = 17;
          }

          if (!obj['endDate']) {
            obj['endDate'] = "01-01-2017 00:00";
          }

          var requestString = JSON.stringify(obj);

          $scope.retData = {};

          // var confighead = {
          //   headers: {
          //     'Content-Type': '"application/json; charset=utf-8";',
          //     'dataType': '"json"',
          //   },
          // };

          if (!config.dontSendMaxar) {
            ExternalService.relaySend({
              onSuccess: function (data, status) {
                $scope.retData.result = data;
              },
              onError: function (err, status) {
                $scope.status = status;
              }
            }, requestString);

            // $http.post(config.api + '/ExternalServices/Relay/Send',requestString,confighead)
            // .success(function (data, status, headers, config) {
            //   $scope.retData.result = data.d;
            // })
            // .error(function (data, status, headers, config) {
            //   $scope.status = status;
            // });
          }

        },        

        checkUWFC: function () {
          var obj = {};
          var UWFCLayer = $window.UWFCLayer;
          angular.extend(obj, UWFCLayer[0]);
          var requestString = JSON.stringify(obj);

          var confighead = {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          };

          $http.post(config.api + '/ExternalServices/Falcon/CheckUnderwriting',requestString,confighead)
          .success(function (data) {
            // nothing to do
          })
          .error(function (data, status, headers, config) {
            DaModal.open('#daExceptionModal', { onClose: fn.goToStart });
          });
        },
        checkBLFC: function () {
          var obj = {};
          var BLFCLayer = $window.BLFCLayer;
          angular.extend(obj, BLFCLayer[0]);
          var requestString = JSON.stringify(obj);

          var confighead = {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          };

          $http.post(config.api + '/ExternalServices/Falcon/CheckBlacklist',requestString,confighead)
          .success(function (data) {
            if (data.ErrorDescription == "Success") {
              if (data.Data.IsAcceptable) {
                // Is blacklist
                DaModal.open('#daExceptionModal', { onClose: fn.goToStart });
              } 
              else {
                // nothing to do
              }
            }
          })
          .error(function (data, status, headers, config) {
            DaModal.open('#daExceptionModal', { onClose: fn.goToStart });
          });
        },

        goToStart: function () {
          $scope.stepsUtils.clear();
          $scope.setIsAccepted(false);
          $scope.goToHomePage();
        },

        disablePremium: function () {
          var tabName = ['basic','done','counterdone','issue'];

          for (var i in tabName) {
            if ($state.current.name === 'public.motor.quote.steps.' + tabName[i]) {
              return 'disabled';
            }
          }

          return 'active';
        },

        isUserProceedOnBuy: function () {
          var tabName = ['basic','cover','customise','quote','confirm'];
          for (var i in tabName) {
            if ($state.current.name === 'public.motor.quote.steps.' + tabName[i]) {
              if ($sessionStorage.tachk == 1) {
                $state.go('public.motor.quote.steps.buy');
              } else if ($sessionStorage.tachk == 2) {
                $state.go('public.motor.quote.steps.confirm');
              }
            }
          }
        },

        hideEstimatedMsg: function () {
          var tabName = ['confirm', 'buy'];

          for (var i in tabName) {
            if ($state.current.name === 'public.motor.quote.steps.' + tabName[i]) {
              return true;
            }
          }

          return false;
        },

        disableBreadcrumb: function () {
          var tabName = ['done','counterdone'];

          for (var i in tabName) {
            if ($state.current.name ===  'public.motor.quote.steps.' + tabName[i]) {
              return false;
            }
          }

          return true;
        },

        getActiveTabSPA: function (tabno) {
          if (tabno == $sessionStorage.currentPage) {
            return "active";
          }
        },

        checkUWFC: function () {
          var obj = {};
          var UWFCLayer = $window.UWFCLayer;
          angular.extend(obj, UWFCLayer[0]);
          var requestString = JSON.stringify(obj);
          var confighead = {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          };

          $http.post(config.api + '/ExternalServices/Falcon/CheckUnderwriting',requestString,confighead)
          .success(function (data) {
            // nothing to do
          })
          .error(function (data, status, headers, config) {
            DaModal.open('#daExceptionModal', { onClose: fn.goToStart });
          });
        },
        checkBLFC: function () {
          var obj = {};
          var BLFCLayer = $window.BLFCLayer;
          angular.extend(obj, BLFCLayer[0]);
          var requestString = JSON.stringify(obj);

          var confighead = {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              },
          };

          $http.post(config.api + '/ExternalServices/Falcon/CheckBlacklist', requestString, confighead)
          .success(function (data) {
            
            if (data.ErrorDescription == "Success") {
              if (data.Data.IsAcceptable) {
                // Is blacklist
                DaModal.open('#daExceptionModal', {
                  onClose: fn.goToStart,
                });
              }
              else {
                // nothing to do
              }
            }
          })
          .error(function (data, status, headers, config) {
            DaModal.open('#daExceptionModal', { onClose: fn.goToStart });
          });
        },

        filterStatic: function (obj, val) {
          var result = '';
          for (var i = 0, len = obj.length; i < len; i++) {
            if (obj[i].key === val) {
              result = obj[i].value;
              break;
            }
          }
          return result;
        },

        filterDesc: function (obj, val) {
          var result = '';
          for (var i = 0, len = obj.length; i < len; i++) {
            if (obj[i].key === val) {
              result = obj[i].description;
              break;
            }
          }
          return result;
        },

        /* add issuepolicy */
        issuePolicy: function (creditCard) {
          fn.utils.setIsSaving(false);

          if (!creditCard) {
              console.error('No credit card object provided');
              return;
          }

          //fn.setIsPageSaving(false);
          var parameters = {
              token: creditCard.MITSessionToken,
              productState: $scope.product.ProductCode
          };

          $state.go('public.' + $scope.product.ProductCode + '.quote.steps.issue', parameters);
      },

        validations: {
          validateCurrentStep: function () {
            if (!$scope.getIsAccepted()) {
              fn.goToStart();
              return false;
            }
            return true;
          },

          validateToken: function (callbacksParam) {
            callbacksParam = callbacksParam || {};

            var onSuccess = callbacksParam.onSuccess;
            var onError = callbacksParam.onError;
            var callbacks = {
              onSuccess: function (data) {
                if (onSuccess) {
                  onSuccess(data);
                }
              },

              onError: function (data) {
                if (onError) {
                  onError(data);
                }
                $scope.showSessionExpired();
              },
            };

            $scope.sessionApi.retrieveSession(callbacks);
          },

          isInvalidDriverAge: function (dateOfBirthString, formElement) {
            if (!dateOfBirthString) {
              if (formElement) {
                formElement.$setValidity('custom_age', true);
              }
              return true;
            }

            var isValidAge = FormValidation.isValidAge(20,70,dateOfBirthString);

            if (formElement) {
              formElement.$setValidity('custom_age', isValidAge);
            }

            return !isValidAge;
          },

          isInvalidYearsOfExperience: function isInvalidYearsOfExperience(yearsOfexpId, formElement) {
            
            if (!yearsOfexpId) {
              formElement.$setValidity('custom_yrs_exp', true);
              return true;
            }

            var invalidExperiences = ['1', '2'];

            var isInvalidExp = invalidExperiences.indexOf(yearsOfexpId) >= 0;

            if (formElement) {
              formElement.$setValidity('custom_yrs_exp', !isInvalidExp);
            }

            return isInvalidExp;
          },

          isInvalidAccidentClaims: function isInvalidAccidentClaims(valueId,formElement) {

            if (!valueId) {
              formElement.$setValidity('custom_accident_claims', true);
              return true;
            }

            var validValues = ['1000003', '1000004', '1000008'];

            var isValidValue = validValues.indexOf(valueId) >= 0;

            if (formElement) {
              formElement.$setValidity('custom_accident_claims', isValidValue);
            }

            return !isValidValue;
          },
        },

        utils: {
          /** Redirect to thank you page */
          divertAfterSECB: function () {
            var loc = HKLocale.getLocale() || 'th-th';
            if (loc == 'en-us') {
              $window.location.href = config.homePageUrl + '/../en/thankyou-for-saving-proposal/';
            } else {
              $window.location.href = config.homePageUrl + '/../thankyou-for-saving-proposal/';
            }
          },

          getMonthlyPriceStepCtrl: function (cc) {

            if (!cc || !cc.Coverages) {
              return 0;
            }
            var coverage = $filter('filter')(cc.Coverages, { IsSelected: true }, true)[0];
            if (!coverage) {
              return 0;
            }
            if (coverage.PlanId === $scope.PlanId) {
              var x = fn.utils.getPriceToShow(coverage);
              $scope.totalPremium2 = x;
              return x;
            } else {
              var swCoverage = $filter('filter')(cc.Coverages, { IsSelected: false, PlanId: $scope.PlanId, CoverTypeId: coverage.CoverTypeId})[0];
              if (swCoverage) {
                var y = fn.utils.getPriceToShow(swCoverage);
                $scope.totalPremium2 = y;
                return y;
              } else {
                return 0;
              }
            }
          },

          getPriceToShow: function (c) {
            if (!c) {
              return 0;
            }
            var ccb = $filter('filter')(c.Covers,{ProductLineGroupId: 2000161,IsSelected: true},true);

            var total = 0;
            if (c.CalculatedPremium) {
              total = c.CalculatedPremium;
            }
            if (ccb) {
              if (ccb[0]) {
                if (ccb[0].TotalPremiumToColl) {
                  total = total + ccb[0].TotalPremiumToColl;
                }
              }
            }           
            return total;
          },

          getTotalAmount: function (coverages) {
            if (!coverages) {
              return;
            }
            $scope.totalPremium = fn.calculateTotalAmout(coverages);
            return $scope.totalPremium;
          },

          setSelectedPayment: function (payment) {
            $scope.selectedPayment = payment;
          },

          convertDateThreeToOne: function (d, m, y) {
            if (!d) return null; if (!m) return null; if (!y) return null;
            if (
              typeof d !== "undefined" &&
              typeof m !== "undefined" &&
              typeof y !== "undefined"
            ) {
              var day = '' + d;
              day = day.length == 1 ? "0" + day : day;
              var month = '' + m;
              month = (month.length == 1) ? "0" + month : month;
              var strDate = y + "-" + month + "-" + day;
              return strDate;
            }
            return null;
          },

          ConvertMITDateToDisplay: function (strDate) {
            if (strDate) {
                var d = fn.utils.convertDateOneToThree(strDate);
               
                if (d != null || d.length > 0) {
                    $scope.CurrentDate_DD = d[0];
                    $scope.CurrentDate_MON = d[1];
                    $scope.CurrentDate_Year = d[2];
                }
            }
          },

          convertDateOneToThree: function (strDate) {
            if (strDate) {
              var convertedDate = [];
              convertedDate.push(parseInt(MITDate.getDD(strDate))); //dd
              convertedDate.push(parseInt(MITDate.getMM(strDate))); //mm
              convertedDate.push(parseInt(MITDate.getYYYY(strDate))); //yyyy
              return convertedDate;
            } else return null;
          },

          setDateValidity: function (d, m, y, f) {
            if (m == 2) {
              /*feb*/
              if (y % 4 == 0) {
                /*leap year -29 is allowed*/
                if (d > 29) {
                  f.$setValidity("valid", false);
                  return;
                } else {
                  f.$setValidity("valid", true);
                }
              } else {
                /*28 days- 29/30/31 not allowed*/
                if (d > 28) {
                  f.$setValidity("valid", false);
                  return;
                } else {
                  f.$setValidity("valid", true);
                }
              }
            } else {
              if (m == 4 || m == 6 || m == 9 || m == 11) {
                if (d > 30) {
                  f.$setValidity("valid", false);
                  return;
                } else {
                  f.$setValidity("valid", true);
                }
              } else {
                f.$setValidity("valid", true);
              }
            }
          },

          setIsSaving: function (isSaving) {
            $scope.isSaving = isSaving;
          },
        },

        saving: {
          calculatePremium: function (callbacks) {
            fn.quoteApi.calculate(callbacks);
        },
        },

        sessionExpiredHandler: function () {
          DaLoadingMask.toggle(false);
          $scope.sessionExpired = true;
          if(!$rootScope.contactPopUpOpened && !$rootScope.isThankYouPopUpOpended){
            DaModal.open('#sessionExpired', { onClose: fn.goToStart });
          }
        },

        getPriority(callback) {
          var hasError = false;
          var obj = {};
          $window.ppstLayer = $window.ppstLayer || $localStorage.ppstLayer;
          var ppstLayer = $window.ppstLayer;
          for (var i = 0; i <= ppstLayer.length; i++) {
            angular.extend(obj, ppstLayer[i]);
          }

          obj.mitBaseInitialParam = {
            "MITSessionToken": $scope.sessionApi.pAndCModelStorage.session.MITSessionToken,
          };

          var requestString = JSON.stringify(obj);

          var confighead = {
            withCredentials: true,
            headers: {
              'Content-Type': 'application/json',
            },
          };
          
          //New change to ExternalServices (10/11/2022 BY PU)
          ExternalService.getPriority({
            onSuccess: function (data, status) {
                if (data.ErrorID === 0) { 
                    callback(data.Data.LeadPriority); 
                } else {
                  callback(0); 
                }
            },
            onError: function (err, status) {
                //console.log(err);
                if(!hasError) {
                  var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getPriority', method: 'ExternalService.getPriority', line: 1172} ;
                  var errorLog = {err: JSON.stringify(error), case: 'Priority'};
                  let priority = $localStorage.priority ? $localStorage.priority : 22;
                  fn.postToMaxParent('No', priority, 'N', errorLog)
                }        
                hasError = true  
            }
        }, requestString);

          //propensity model project : ExternalServices/Propensity/GetPriority
          // $http.post(config.api + '/ExternalServices/Propensity/GetPriority', requestString, confighead)
          // .success(function (ret) {
          //   if (ret.ErrorID === 0) {
          //     callback(ret.Data.LeadPriority);
          //   } else {
          //     return 0;
          //   }
          // })
          // .error(function (err) {
          //   // nothing to do
          // });
        },
        changeGlobalContactNo: function () {
          var type = 0;
          var ck = $cookies["__utmz"];
          var ga_source = $cookies["dac_gacsr"] || '';

          if (ck === "utmcsr=google|utmcmd=organic|utmccn=(not set)|utmctr=(not provided)") {
            type = 3;
          } else {
            if (ga_source === 'google') {
              type = 1;
            } else if (ga_source === 'facebook' || ga_source === 'facebook.com') {
              type = 2;
            } else {
              type = 0;
            }
          }

          var tel = 'tel:027677777';
          var tel_txt = '02-767-7777';
          var tel_func = '';
          if (type === 1) {
            // google
            tel = 'tel:027677774';
            tel_txt = '02-767-7774';
            tel_func = "dataLayer.push({'event':'clickevent', 'category':'portal', 'action':'Call', 'label':'02-767-7774'});";
          } else if (type === 3) {
            // organic
            tel = 'tel:027677806';
            tel_txt = '02-767-7806';
            tel_func = "dataLayer.push({'event':'clickevent', 'category':'portal', 'action':'Call', 'label':'02-767-7806'});";
          } else if (type === 2) {
            // Facebook
            tel = 'tel:027677844';
            tel_txt = '02-767-7844';
            tel_func = "dataLayer.push({'event':'clickevent', 'category':'portal', 'action':'Call', 'label':'02-767-7844'});";
          }

          $('a[href="tel:027677777"]').attr("onclick", tel_func).html(tel_txt).attr("href", tel);
        },
        /** Source of business function */
        sourceChanneler: function () {
          var sc = {};
          sc.id = 0;
          sc.cd = '';
          try {
            if ($scope.staticData.sourceChannels.length > 0) {
              var ga_source = '';
              var ga_campaign = '';
              var ga_medium = '';
              var ga_term = '';
              var ga_content = '';
              var ck = $cookies["__utmz"];

              /** reads GA cookie : 111872281.1488786437.1.1.utmcsr=(direct)|utmccn=(direct)|utmcmd=(none) */
              if (ck) {
                var z = ck.split('.');
                if (z.length >= 4) {
                  var y = z[4].split('|');
                  for (var i = 0; i < y.length; i++) {
                    if (y[i].indexOf('utmcsr=') >= 0)
                      ga_source = y[i].substring(y[i].indexOf('=') + 1);
                    if (y[i].indexOf('utmccn=') >= 0)
                      ga_campaign = y[i].substring(y[i].indexOf('=') + 1);
                    if (y[i].indexOf('utmcmd=') >= 0)
                      ga_medium = y[i].substring(y[i].indexOf('=') + 1);
                    if (y[i].indexOf('utmctr=') >= 0)
                      ga_term = y[i].substring(y[i].indexOf('=') + 1);
                  }

                  $scope.gaCampaign = ga_campaign;
                }
              } else {

              /** DA cookie */
                ga_source = $cookies["dac_gacsr"] || '';
                ga_campaign = $cookies["dac_gaccn"] || '';
                ga_medium = $cookies["dac_gacmd"] || '';
                ga_term = $cookies["dac_gactr"] || '';
                ga_content = $cookies["dac_gacct"] || '';
                GACIDOrigin = $cookies['_ga'];
                GAClientID = GACIDOrigin.split(".") || '';
                $scope.gaCampaign = ga_campaign;
                //Accesstrade cookie
                act_clickId = $cookies["dac_actclickid"] || '';
                act_psn = $cookies["dac_actpsn"] || '';

                if (GAClientID != '') {
                  $scope.GAClientID = GAClientID[2] + '.' + GAClientID[3];
                } else {
                  $scope.GAClientID = '';
                }
              }

              if (ga_medium != '') {
                if (ga_medium.indexOf('organic') >= 0) {
                  try {

                    var dd = $filter('filter')($scope.staticData.sourceChannels, {
                        value: 'Organic Search',
                      },
                      true
                    );

                    if (dd && dd[0]) {
                      sc.id = dd[0].key;
                      sc.cd = ga_term;
                    }

                  } catch (e) {}

                } else {
                  if (ga_medium.indexOf('cpc') >= 0) {
                    try {

                      var dd = $filter('filter')($scope.staticData.sourceChannels, {
                          value: 'Generic Paid Search',
                        }, true);
                      if (dd && dd[0]) {
                        sc.id = dd[0].key;
                        sc.cd = ga_campaign;
                      }

                    } catch (e) {}

                  } else {
                    if (ga_medium.indexOf('none') >= 0) {
                      try {

                        var dd = $filter('filter')($scope.staticData.sourceChannels, {
                            value: 'Direct',
                          }, true);
                        if (dd && dd[0]) {
                            sc.id = dd[0].key;
                            sc.cd = '';
                        }

                      } catch (e) {}

                    } else {
                      if (ga_medium.indexOf('social') >= 0) {
                        try {

                          var dd = $filter('filter')($scope.staticData.sourceChannels, {
                              value: 'Social',
                            },true);
                          if (dd && dd[0]) {
                              sc.id = dd[0].key;
                              sc.cd = ga_campaign;
                          }

                        } catch (e) {}

                      } else {
                        if (ga_medium.indexOf('display') >= 0) {

                          try {
                            var dd = $filter('filter')($scope.staticData.sourceChannels, {
                                value: 'Display',
                              }, true);
                            if (dd && dd[0]) {
                              sc.id = dd[0].key;
                              sc.cd = ga_campaign;
                            }

                          } catch (e) {}

                        } else {
                          if (ga_medium.indexOf('email') >= 0) {

                            try {

                              var dd = $filter('filter')($scope.staticData.sourceChannels, {
                                  value: 'Email',
                                }, true);
                              if (dd && dd[0]) {
                                sc.id = dd[0].key;
                                sc.cd = ga_campaign;
                              }

                            } catch (e) {}

                          } else {
                            if (ga_medium.indexOf('referral') >= 0) {

                              try {

                                var dd = $filter('filter')($scope.staticData.sourceChannels, {
                                    value: 'Referral',
                                  }, true);
                                if (dd && dd[0]) {
                                  sc.id = dd[0].key;
                                  sc.cd = ga_source;
                                }

                              } catch (e) {}

                            } else {
                              try {

                                var dd = $filter('filter')($scope.staticData.sourceChannels, {
                                    value: 'Other',
                                  }, true);
                                if (dd && dd[0]) {
                                  sc.id = dd[0].key;
                                  sc.cd = ga_source;
                                }

                              } catch (e) {}

                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          } catch (err) {}
          return sc;
        },

        //abtasty removed by Rak 14 June 2022 ----- 
        /*
        abTastyTracking: function (goal) {
          var expId = $localStorage.abTastyTrackingId;

          if (!expId) {
            // console.log("no tracking id defined");
            return;
          }

          if (typeof ABTastyClickTracking === undefined) {
            // console.log("no abtasty tracking function defined");
            return;
          }

          switch (goal) {
            case 1:
              ABTastyClickTracking('1: Get quote - click on calculate', null, expId);
              // console.log("1");
              break;
            case 2:
              ABTastyClickTracking('2: Your quote - got coverage list', null, expId);
              // console.log("2");
              break;
            case 3:
              ABTastyClickTracking('3: Your quote - click on next', null, expId);
              // console.log("3");
              break;
            case 4:
              ABTastyClickTracking('4: PH MD detail - click on next', null, expId);
              // console.log("4");
              break;
            case 5:
              ABTastyClickTracking('5: Car and address detail - click on next', null, expId);
              // console.log("5");
              break;
            case 6:
              ABTastyClickTracking('6: Review - click on buy', null, expId);
              // console.log("6");
              break;
            case 7:
              ABTastyClickTracking('7: Buy - proceed to cybesrsource', null, expId);
              // console.log("7");
              break;
            case 8:
              ABTastyClickTracking('8: Payment success', null, expId);
              // console.log("8");
              break;
            case 9:
              ABTastyClickTracking('9: Payment failed', null, expId);
              // console.log("9");
              break;
          }
        },
        */
        requestOTP: function () {
          var obj = {};
          var OTPLayer = $window.OTPLayer;
          angular.extend(obj, OTPLayer[0]);
          var requestString = JSON.stringify(obj);

          var confighead = {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              },
          };

          $http.post(config.api + '/ExternalServices/SMS/RequestOTP', requestString, confighead)
          .success(function (data) {
            return true;
          })
          .error(function (err) {
            return false;
          });
        },
        validateOTP: function (callback) {
          var obj = {};
          var OTPLayer = $window.OTPLayer;
          angular.extend(obj, OTPLayer[0]);
          var requestString = JSON.stringify(obj);

          var confighead = {
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
              },
          };

          $http.post(config.api + '/ExternalServices/SMS/VerifyOTP', requestString, confighead)
            .success(function (ret) {
              // console.log(">>" + ret.Data);
              if (ret.Data == 200) {
                callback(true);
              } else {
                callback(false);
              }
            })
            .error(function (err) {
              // console.log(err);
            });
        },

        updatesCurrentProposal: function (data) {


          angular.extend($scope.currentProposal, data);
          var saveCurrentProposal = function () {           
            var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
            $scope.totalPremium = 100;
                    if ($scope.currentProposal.IsRenewalProposal) {
                        fn.currentProposalApi[saveCurrentProposalFunc]({
                                onSuccess: function () {
                                    $scope.PaymentScope.doPayment(undefined, $scope.currentProposal.ProductId, $scope.currentProposal.IsRenewalProposal, $scope.totalPremium, callbacks);
                                },
                                onError: function () { /* do nothing */ }
                            }
                        );
                    }
                    else {
                      fn.currentProposalApi[saveCurrentProposalFunc]({
                        onSuccess: function () {
                      $scope.PaymentScope.doPayment(undefined, $scope.currentProposal.ProductId, $scope.currentProposal.IsRenewalProposal, $scope.totalPremium, callbacks);
                    },
                    onError: function () { /* do nothing */ }
                });
                    
                }

                    
            };

            saveCurrentProposal();
            $scope.FirstPayment = $scope.currentProposal.FirstPayment;
            $scope.MonthlyPremium = $scope.currentProposal.MonthlyPremium;
            $scope.TotalPremium = $scope.currentProposal.TotalPremium;
            $scope.paymentTermSelectedId = $scope.currentProposal.PaymentTermId;
            $scope.PaymentSchedules = $scope.currentProposal.PaymentSchedules;
        },

        issuePolicy: function (creditCard) {
          fn.utils.setIsSaving(false);

          if (!creditCard) {
              console.error('No credit card object provided');
              return;
          }

          //fn.setIsPageSaving(false);
          var parameters = {
              token: creditCard.MITSessionToken,
              productState: $scope.product.ProductCode
          };

          $state.go('public.' + $scope.product.ProductCode + '.quote.steps.issue', parameters);
      },

        eventHandlers: {
          onDefaultErrorHandler: function () {
            fn.utils.setIsSaving(false);
          },

          onChangeFinancialOption: function (paymentTermSelectedId) {
            if(paymentTermSelectedId !== undefined) {
            $scope.loadingSpinner4 = true;
            fn.utils.setIsSaving(true);
              var saveCurrentProposal = function () {

                $scope.currentProposal.ProductId = $scope.product.ProductId;
                $scope.currentProposal.PaymentTermId = paymentTermSelectedId;
                $scope.paymentTermSelectedId = paymentTermSelectedId;
                $scope.PaymentSchedules = $scope.currentProposal.PaymentSchedules;

                var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');


                      fn.currentProposalApi[saveCurrentProposalFunc]({
                        onSuccess: function () {
                          fn.saving.calculatePremium({
                            onSuccess: function () {
                                              fn.confirmationApi.createConfirmation({
                                                onSuccess: function (data) {
        
                                                      angular.extend($scope.currentProposal,data);
                                                      fn.updatesCurrentProposal(data);                                           
                                                      $scope.FirstPayment = $scope.currentProposal.FirstPayment;
                                                      $scope.MonthlyPremium = $scope.currentProposal.MonthlyPremium;
                                                      $scope.TotalPremium = $scope.currentProposal.TotalPremium;
                                                      $scope.paymentTermSelectedId = $scope.currentProposal.PaymentTermId;
                                                      $scope.PaymentSchedules = $scope.currentProposal.PaymentSchedules;
                                                      $scope.loadingSpinner4 = false; 
                                                      
                                                      fn.utils.setIsSaving(false); 

                                                    },
                                                    onError: function () {
                                                      fn.utils.setIsSaving(false);
                                                    },
                                                  });
                                                },
                                                onError: function () {
                                                  $scope.hasError = true;
                                                  fn.utils.setIsSaving(false);
                                                }
                                              });
                        },
                        onError: function () {
                          $scope.hasError = true;
                          fn.utils.setIsSaving(false);
                        }
                      });
  
  
            }

                saveCurrentProposal();

            }

          },

        },

      };


      var dateToday = MITDate.newDate(); 
      var dateTodayAngular = MITDate.toAngularDate(dateToday);
      fn.utils.ConvertMITDateToDisplay(dateTodayAngular);

      fn.initScopeObjects();
      fn.initApiObjects();
      fn.initStaticData();
      fn.retrieveScopeObjects();

      $scope.currentProposal.datesMonths = $scope.staticData.datesMonths;
      $scope.dLCE = marketing.dLCE;
      $scope.disablePremium = fn.disablePremium;
      $scope.hideEstimatedMsg = fn.hideEstimatedMsg;
      $scope.disableBreadcrumb = fn.disableBreadcrumb;
      $scope.setSelectedPayment = fn.utils.setSelectedPayment;
      $scope.setIsAccepted = fn.setIsAccepted;
      $scope.getIsAccepted = fn.getIsAccepted;
      $scope.getTotalAmount = fn.utils.getTotalAmount;
      $scope.isInvalidDriverAge = fn.validations.isInvalidDriverAge;
      $scope.convertDateThreeToOne = fn.utils.convertDateThreeToOne;
      $scope.convertDateOneToThree = fn.utils.convertDateOneToThree;
      $scope.setDateValidity = fn.utils.setDateValidity;
      $scope.postMaxar = fn.postMaxar;
      $scope.getMonthlyPriceStepCtrl = fn.utils.getMonthlyPriceStepCtrl;
      $scope.filterStatic = fn.filterStatic;
      $scope.filterDesc = fn.filterDesc;
      $scope.checkBLFC = fn.checkBLFC;
      $scope.checkUWFC = fn.checkUWFC;
      $scope.isUserProceedOnBuy = fn.isUserProceedOnBuy;
      $scope.divertAfterSECB = fn.utils.divertAfterSECB;
      $scope.sessionExpiredHandler = fn.sessionExpiredHandler;
      $scope.getActiveTabSPA = fn.getActiveTabSPA;
      $scope.getPriority = fn.getPriority;
      $scope.changeGlobalContactNo = fn.changeGlobalContactNo;
      $scope.sourceChanneler = fn.sourceChanneler;
      $scope.gaEvents = fn.gaEvents;
      //abtasty removed by Rak 14 June 2022 ----- $scope.abTastyTracking = fn.abTastyTracking;
      $scope.requestOTP = fn.requestOTP;
      $scope.validateOTP = fn.validateOTP;
      $scope.onChangeFinancialOption = fn.eventHandlers.onChangeFinancialOption;
      
      $scope.$on('IdleTimeout', function () {
          fn.sessionExpiredHandler();
      });

      $scope.$on('Keepalive', function () {
          var localSession = {
              MITSessionToken: $scope.sessionApi.pAndCModelStorage.session.MITSessionToken
          };
          var t = Session.validate(localSession);
          t.$promise.then(
              function (data) { },
              function (data) { fn.sessionExpiredHandler(); }
          );
      });

      $scope.$on('$locationChangeStart', function(event, next, current) {
          if (window.om93246_82887) {
              window.om93246_82887.reset();
          }
      });

      Idle.watch();
      $scope.$parent.onBannerLoad();

      ;(function () {
        let scope = $scope.$parent;
        while (scope.$parent && typeof scope.$parent.onBannerLoad === 'function') {
          scope = scope.$parent;
        }
        scope.onBannerLoad();
      })();

      if (!fn.validations.validateCurrentStep()) {
          return;
      }

      if (!fn.validations.validateToken()) {
          return;
      }

  }];