/*
 *
 * Description :
 * module.js (adWhkModule) encapsulates "Country Wide (i.e. Hong Kong)" related codes into a single unit of code and
 * contains Providers, Services, Factory, and Directives Objects.
 *
 * Version Notes :
 * 10 Jun 2015 - Initial version
 * 20 Aug 2015 - Clean up the code and re-organized.
 * 29th Nov 2016- [OB, Suhas]- Rename HK Module to TH
 */

/* Declaration of WHK Module daWhkModule */
var daWthModule = angular.module('DA.WTH.MODULE', [
    'ngStorage',
    'pascalprecht.translate'
]);

/* Initialization of WHK Module Objects i.e. Factory, Providers, Services and Directive */
var thStepsFactory = require('./quote/steps-factory.js'),
    thVehicleValidationService = require('./validation/vehicle-validation-service.js'),

    thLocaleProvider = require('./locale/locale-service.js'),
    thFormValidationService = require('./validation/hk-form-validation-service.js'),
    motorQuoteValidationService = require('./validation/motor-validation-service.js'),
    healthQuoteValidationService = require('./validation/health-validation-service.js'),
    motorcycleQuoteValidationService = require('./validation/motorcycle-validation-service.js'),
    travelQuoteValidationService = require('./validation/travel-validation-service.js'),
    thDownload = require('./services/download-service.js'),
    dataLayerService = require('./services/data-layer.js'),
    apiServiceProvider = require('./apiService/api-service.js'),
    apiServiceV2Provider = require('./apiService/api-serviceV2.js'),

    daHkid = require('./directive/da-hkid/da-hkid.js'),
    daThid = require('./directive/da-thid/da-thid.js'),
    daThCreditCardNo = require('./directive/da-th-credit-card-no/da-th-credit-card-no.js'),
    daThConfirmEmail = require('./directive/da-th-confirm-email/da-th-confirm-email.js'),
    daThPassport  = require('./directive/da-th-passport/da-th-passport.js'),
    daThPostalChange = require('./directive/da-th-postal-chanage/da-th-postal-change.js'),
    daThProvinceChange = require('./directive/da-th-province-change/da-th-province-change.js'),
    sameHeightButton = require('./directive/sameHeightButton/sameHeightButton.js'),
    preventCutCopyPasteInput = require('./directive/preventCutCopyPasteInput/preventCutCopyPasteInput.js'),
    sameHeightButtonContainer = require('./directive/sameHeightButtonContainer/sameHeightButtonContainer.js'),
    daInputAlphanum = require('./directive/da-input-alphanum/da-input-alphanum.js'),
    daInputCheckRepeating = require('./directive/da-input-check-repeating/da-input-check-repeating.js'),
    daThMobileNo = require('./directive/da-th-mobile-no/da-th-mobile-no.js'),
    daThNumberOnly = require('./directive/da-th-mobile-no/da-th-number-only.js'),
    dataLayerDirective = require('./directive/data-layer/data-layer.js')

    externalServiceProvider = require('./externalServices/externalServices.js');
    utilityProvider = require('./helpers/utility.js');
    appConstantsProvider = require('./helpers/app-constants.js');

/* Instantiation of WHK Module Objects i.e. Factory, Services and Directive */
daWthModule
    .factory('HKSteps', thStepsFactory)

    .service('HKVehicleValidationService', thVehicleValidationService)
    .service('HKLocale', thLocaleProvider)
    .service('HKFormValidation', thFormValidationService)
    .service('CountryMotorQuoteValidation', motorQuoteValidationService)
    .service('CountrHealthQuoteValidation', healthQuoteValidationService)
    .service('CountryMotorcycleQuoteValidation', motorcycleQuoteValidationService)
    .service('CountryTravelQuoteValidation', travelQuoteValidationService)
    .service('HKDownload',thDownload)
    .service('ExternalService', externalServiceProvider)
    .service('ApiService', apiServiceProvider)
    .service('ApiServiceV2', apiServiceV2Provider)
    .service('dataLayerService', dataLayerService)

    .directive('daHkid', daHkid)
    .directive('daThid', daThid)
    .directive('daThCreditCardNo', daThCreditCardNo)
    .directive('daThConfirmEmail', daThConfirmEmail)
    .directive('daThPassport', daThPassport)
    .directive('daThPostalChange',daThPostalChange)
    .directive('daThProvinceChange',daThProvinceChange)
    .directive('preventCutCopyPasteInput', preventCutCopyPasteInput)
    .directive('sameHeightButton', sameHeightButton)
    .directive('sameHeightButtonContainer', sameHeightButtonContainer)
    .directive('daInputAlphanum', daInputAlphanum)
    .directive('daInputCheckRepeating', daInputCheckRepeating)
    .directive('daThMobileNo', daThMobileNo)
    .directive('daThNumberOnly', daThNumberOnly)
    .directive('dataLayer', dataLayerDirective)

    .service('Utils', utilityProvider)
    .service('AppConstants', appConstantsProvider);

/* Export daWhkModule to be utilized in other files */
module.exports = daWthModule;