module.exports = [
    '$sessionStorage',
    'HKLocale',
    'ConfigUtils',
    '$http',

    /**
     * ApiServiceProvider
     * @returns {Function}
     * @constructor
     */
    function ApiServiceProvider($sessionStorage, HKLocale, config, $http) {

        var AGGConfig = {
            HeaderWithToken: {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + config.aggToken
                }
            },
            Header: {
                headers: {
                    'Content-Type': 'application/json'
                }
            },
        };

        var fn = {
            initStaticData: function (callback) {
                try {
                    $http.get(config.aggApi + '/quotation?lang=' + HKLocale.getLocale(), AGGConfig.HeaderWithToken)
                        .success(function (data) {
                            $sessionStorage.StaticData = data.lookupValues;

                            $sessionStorage.StaticData.Links = {};
                            for (var i = 0; i < data.links.length; i++) {
                                var href = data.links[i].href;
                                var rel = data.links[i].rel;

                                $sessionStorage.StaticData.Links[rel] = href;

                                if (!('ApiKey' in $sessionStorage.StaticData)) {
                                    var params = new URLSearchParams(href);
                                    var apiKey = params.get('apiKey');

                                    if (apiKey !== null && apiKey !== '' && apiKey !== undefined) {
                                        $sessionStorage.StaticData.ApiKey = apiKey;
                                    }
                                }
                            }

                            callback.onSuccess();
                        })
                        .error(function (err) {
                            callback.onError();
                        });
                } catch (err) {
                    callback.onError();
                }
            },
            getStaticData: function (callback, name) {
                try {
                    var data = $sessionStorage.StaticData.hasOwnProperty(name) ? $sessionStorage.StaticData[name] : null;
                    callback.onSuccess(data);
                } catch (err) {
                    callback.onError();
                }
            },
            getStaticDataFromRel: function (callback, rel) {
                try {
                    var href = $sessionStorage.StaticData.Links[rel];
                    var params = new URLSearchParams(href);
                    if (!params.has('apiKey')) {
                        href = href + "&apiKey=" + $sessionStorage.StaticData.ApiKey;
                    }

                    $http.get(href, AGGConfig.Header)
                        .success(function (data) {
                            $sessionStorage.StaticData[rel] = data;
                            callback.onSuccess(data);
                        })
                        .error(function (err) {
                            callback.onError();
                        });
                } catch (err) {
                    callback.onError();
                }
            },
            getStaticDataFromLink: function (callback, rel, href) {
                try {
                    var params = new URLSearchParams(href);
                    if (!params.has('apiKey')) {
                        href = href + "&apiKey=" + $sessionStorage.StaticData.ApiKey;
                    }

                    $http.get(href, AGGConfig.Header)
                        .success(function (data) {
                            $sessionStorage.StaticData[rel] = data;
                            callback.onSuccess(data);
                        })
                        .error(function (err) {
                            callback.onError();
                        });
                } catch (err) {
                    callback.onError();
                }
            },
            getVehicleColour: function(callback) {
                try {
                    var data = [
                        {
                            key: "#f00",
                            value: HKLocale.getLocale() === "th-th" ? "แดง" : "Red"
                        },
                        {
                            key: "#0f0",
                            value: HKLocale.getLocale() === "th-th" ? "เขียว" : "Green"
                        },
                        {
                            key: "#00f",
                            value: HKLocale.getLocale() === "th-th" ? "ฟ้า" : "Blud"
                        },
                        {
                            key: "#0ff",
                            value: HKLocale.getLocale() === "th-th" ? "เทา" : "Cyan"
                        },
                        {
                            key: "#ff0",
                            value: HKLocale.getLocale() === "th-th" ? "เหลือง" : "Yellow"
                        },
                        {
                            key: "#000",
                            value: HKLocale.getLocale() === "th-th" ? "ดำ" : "Black"
                        }
                    ];
                    callback.onSuccess(data);
                } catch (err) {
                    callback.onError();
                }
            },
            getBooleanListWithSometimes: function(callback) {
                try {
                    var data = [
                        {
                            key: true,
                            value: HKLocale.getLocale() === "th-th" ? "ใช่/บางครั้ง" : "Yes/Sometimes"
                        },
                        {
                            key: false,
                            value: HKLocale.getLocale() === "th-th" ? "ไม่" : "No"
                        }
                    ];
                    callback.onSuccess(data);
                } catch (err) {
                    callback.onError();
                }
            },
            getResidentialStatusList: function(callback) {
                try {
                    var data = [
                        {
                            key: '2000000',
                            value: HKLocale.getLocale() === "th-th" ? "ไทย" : "Thai"
                        },
                        {
                            key: '2000001',
                            value: HKLocale.getLocale() === "th-th" ? "บุคคลที่ไม่มีถิ่นที่อยู่ในไทย" : "Non-Thai Resident"
                        }
                    ];
                    callback.onSuccess(data);
                } catch (err) {
                    callback.onError();
                }
            }
        };

        this.initStaticData = fn.initStaticData;
        this.getStaticData = fn.getStaticData;
        this.getStaticDataFromRel = fn.getStaticDataFromRel;
        this.getStaticDataFromLink = fn.getStaticDataFromLink;
        this.getVehicleColour = fn.getVehicleColour;
        this.getBooleanListWithSometimes = fn.getBooleanListWithSometimes;
        this.getResidentialStatusList = fn.getResidentialStatusList;
    }];