module.exports = [
    function daInputAlphanum() {
        var fn = function (scope, element, attr, ctrl) {
            function customValidator(value) {
                var regex = /^[a-zA-Z0-9]*$/;
                var isValid = false;

                if (value) {
                    if (regex.test(value)) {
                        isValid = true;
                    }
                } else {
                    isValid = true;
                }

                ctrl.$setValidity('alphanum', isValid);
                return value;
            }

            ctrl.$parsers.unshift(customValidator);
        };

        return {
            restrict: 'A',
            require: 'ngModel',
            link: fn
        };
    }];