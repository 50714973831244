module.exports = [
    function daTHNumberOnly() {
        var fn = function (scope, element, attr, ctrl) {
            function fromUser(value) {
                if (value) {
                    var transformedInput = value.replace(/[^0-9]/g, '');

                    if (transformedInput !== value) {
                        ctrl.$setViewValue(transformedInput);
                        ctrl.$render();
                    }
                    return transformedInput;
                }
                return undefined;
            }
            ctrl.$parsers.push(fromUser);
        };

        return {
            restrict: 'A',
            require: 'ngModel',
            link: fn
        };
    }];