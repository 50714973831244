/*
 * Created by Chanon.t.
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    'WfeApiSession',
    'HKSteps',
    '$sessionStorage',
    '$localStorage',


    /**
     * Controller that assigns token to the product
     * @param $scope
     * @param $state
     * @constructor
     */
    function NewSessionTokenController($scope, $state, $stateParams, WfeApiSession, HKSteps,$sessionStorage, $localStorage) {

        var fn = {

            products: {
                motor: {
                    ProductId: 2000003,
                    ProductCode: 'motor',
                    ProductHeader: $scope.HKLocale.getResource('car_h_product_name'),
                    FirstPageState: 'public.motor.quote.steps.basic'
                }

            },

            initScopeObjects: function (){
                $scope.product = {};
                $scope.prodOption= {};
                delete $sessionStorage.tachk;
                delete $sessionStorage.cvo;
            },

            initApiObjects: function(){


                var productCode =  'motor';//$stateParams.productCode;
                $scope.prodOption = '';


                if(productCode.indexOf('-') > -1)
                {
                    $scope.prodOption = productCode.substring(productCode.indexOf('-')+1);
                    productCode = productCode.substring(0,productCode.indexOf('-'));
                }


                var product = fn.products[productCode];

                if (!product) {
                    //when productcode is product id
                    var productCodeInt = parseInt(productCode);
                    var productReverseMap = {
                        2000003: 'motor',
                        2000001: 'travel',
                        2000004: 'motorcycle',
                        2000006: 'health',
                        2000002: 'termlife'
                    };

                    var productCodeTemp = productReverseMap[productCodeInt];
                    product = fn.products[productCodeTemp];

                    if(!product){
                        return;
                    }
                }

                angular.extend($scope.product, product);
                $scope.sessionApi = WfeApiSession.instance($scope, product.ProductCode);
                $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);
//suhas1
            },


            eventHandlers: {

                onLoadingWithToken: function () {
                   /* var token = $stateParams.token;

                    if (!token) {
                        return false;
                    }

                    $scope.sessionApi.setToken(token);*/
                    $scope.isAccepted = true;
                    $scope.stepsUtils.setIsAccepted(true);
                    $scope.stepsUtils.setProdOption($scope.prodOption);
                    //set locale
                    var localeString = $stateParams.lang;
                    var callback = function(){
                        fn.navigation.goToNextStep();
                    };
                    if(!localeString)
                    {
                        localeString='en-us';
                    }
                    if(localeString){
                        //console.log(localeString);
                        if(localeString.toLowerCase() === 'th-th'){
                            $scope.HKLocale.setLocaleOnNextLoading('th-th',callback);
                            //$scope.HKLocale.setLocale('da-override-btn-red');
                        }else if(localeString.toLowerCase() === 'en-us'){
                            $scope.HKLocale.setLocaleOnNextLoading('en-us',callback);
                            //$scope.HKLocale.setLocale('en-us');
                        }

                    } else {
                        $scope.HKLocale.setLocaleOnNextLoading('th-th',callback);
                        fn.navigation.goToNextStep();
                    }
                    //fn.navigation.goToNextStep();

                    return true;
                },
                btnNextClicked: function () {

                    var isValid = true;

                    if (!isValid) {
                        return;
                    }

                    var callbacks =
                    {
                        onSuccess: function () {

                          //  fn.navigation.goToNextStep();
                            $scope.isTokenLoaded = !fn.eventHandlers.onLoadingWithToken();
                            $sessionStorage.noOfPTSave = 0;
                        }
                    };

                    $scope.sessionApi.createSession(callbacks);

                }
            },

            navigation: {
                goToNextStep: function() {
                    if($sessionStorage.maxarReference){delete $sessionStorage.maxarReference};
                    $state.go($scope.product.FirstPageState);
                }
            }

        };
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.eventHandlers.btnNextClicked();
        delete $localStorage.ppstLayer;
        delete $localStorage.maxarLayer;


    }];