/**
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$state',
    '$sessionStorage',
    '$localStorage',
    'ConfigUtils',
    'WfeApiAuth',
    'WfeApiUser',
    'DaAuthorization',
    '$stateParams',

    function LoginLandingController($scope, $state, $sessionStorage, $localStorage, ConfigUtils, WfeApiAuth, WfeApiUser, DaAuthorization,
                             $stateParams) {

        var fn = {



            initScopeObjects: function(){

                var localeString = $stateParams.lang;
                var callback = function(){
                    fn.utils.procceedToNext();
                };
                if(localeString){
                    //console.log(localeString);
                    if(localeString.toLowerCase() === 'th-th'){
                        $scope.HKLocale.setLocaleOnNextLoading('th-th',callback);
                        //if($scope.HKLocale.getLocale() !== 'da-override-btn-red'){
                        //    $scope.HKLocale.setLocale('da-override-btn-red');
                        //}
                    }else if(localeString.toLowerCase() === 'en-us'){
                        $scope.HKLocale.setLocaleOnNextLoading('en-us',callback);
                        //if($scope.HKLocale.getLocale() !== 'en-us') {
                        //    $scope.HKLocale.setLocale('en-us');
                        //}
                    }

                } else {
                    if($scope.HKLocale.getLocale!=='en-us') {
                        $scope.HKLocale.setLocale('en-us');
                    }
                }

            },





            utils: {



                procceedToNext: function()
                {
                    $state.go('public.login');
                }
            }


        };


        fn.initScopeObjects();


    }];