/**
 * Created by Gerald Garcia on 16/02/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    '$sessionStorage',
    '$localStorage',
    '$filter',
    'AccountProposal',
    'StaticDataKeyValuePair',
    'DaModal',
    'Session',
    'Confirmation',
    'MitScopeBindingUtils',
    'DaLoadingMask',
    'WfeApiStaticData',
    'WfeApiProposal',
    'HKLocale',
    'WfeApiSession',
    'WfeApiConfirmation',
    '$window',
    'HKSteps',

    function ProposalsController($scope, $state, $stateParams, $sessionStorage, $localStorage, $filter, Proposal, StaticDataKeyValuePair, DaModal,
                                 Session, Confirmation, MitScopeBindingUtils, DaLoadingMask, WfeApiStaticData, WfeApiProposal, HKLocale,
                                 WfeApiSession, WfeApiConfirmation,$window,HKSteps) {

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/myaccount/customer-access/quotes/'}
                );
                /*if(ga) { ga('send', 'pageview', '/myaccount/customer-access/quotes/');console.log('quotesga');}*/
            },

            quotesview:function()
            {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event': 'Buy',
                    'category': 'Policy',
                    'action': 'Buy',
                    'label': 'Buy'
                });
                //Buy / View button on Proposal is the same.
                /* if(ga) {  ga('send', 'event', 'MyAccount', 'click', 'view_quote', {'page': '/myaccount/customer-access/quotes/'});}*/
            }
        };

        var fn = {

            setIsLoading: function (isLoading) {
                DaLoadingMask.toggle(isLoading);
            },

            onServiceError: function () {
                fn.setIsLoading(false);
            },

            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getProductList();
                };

                var getProductList = function () {
                    wfeApiStaticData.getProductList({
                        onSuccess: function (data) {
                            $scope.staticData.products = data;
                            getQuoteStatusCodeList();
                        },
                        onError: function () {
                            getQuoteStatusCodeList();
                        }
                    });
                };

                var getQuoteStatusCodeList = function () {
                    wfeApiStaticData.getQuoteStatusCodeList({
                        onSuccess: function (data) {
                            $scope.staticData.quoteStatusCodes = data;
                        }
                    });
                };

                startRetrieval();
            },

            initApiObjects: function () {
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), undefined);
                fn.wfeApiProposal = WfeApiProposal.instance(undefined, $scope, $scope.currentUser.existingProposals);

                try {
                    marketing.ga();
                } catch (err) {
                    console.log(err);
                }
            },

            initScopeObjects: function () {

                $scope.currentUser = {
                    existingProposals: {
                        Proposals: {}
                    },
                    documentsForDownload: {}
                };
                $scope.selectedProposal = {};

                /*$scope.currentUser.existingProposals.$promise.then(function(){
                 angular.forEach($scope.currentUser.existingProposals.Proposals, function(item){
                 item.ExternalProposalNumberLink = item.ExternalProposalNumber.replace(/\//g,'-');
                 });
                 });*/

                $scope.staticData = {
                    products: []
                };

            },

            retrieveScopeObjects: function () {
                fn.wfeApiProposal.retrieveExistingProposals(
                    {
                        onSuccess: function (data) {
                            var validProducts = ['2000002'];
                            var filteredData = $filter('filter')(data.Proposals, function (item) {
                                return validProducts.indexOf(item.ProductId) < 0;
                            });

                            angular.extend($scope.currentUser.existingProposals.Proposals, filteredData);
                            angular.forEach($scope.currentUser.existingProposals.Proposals, function (item) {
                                item.ExternalProposalNumberLink = item.ExternalProposalNumber.replace(/\//g, '-');
                            });
                        }
                    }
                );
            },

            /*getStaticData: function(){
             //add static data
             $scope.staticData.products = StaticDataKeyValuePair.getProducts();
             $scope.staticData.quoteStatusCodes = StaticDataKeyValuePair.getQuoteStatusCode();
             },*/

            //isViewingPolicy: false,

            /*staticData: {
             products: []
             },*/

            showDownloadModal: function () {
                DaModal.open('#downloadDocumentsModal');
            },

            eventHandler: {

                btnViewProposalClicked: function btnViewProposalClicked(policySelected) {

                    try {
                        marketing.quotesview();
                    }
                    catch (err) {
                        console.log(err);
                    }
                   // var productCode = policySelected.ProductId;

                    var productCode='motor';
                    var sessionApi = WfeApiSession.instance($scope, productCode);

                    $scope.selectedProposal.proposalNumber = policySelected.ExternalProposalNumber;

                    var confirmationApi = WfeApiConfirmation.instance(sessionApi, $scope, $scope.selectedProposal);

                    fn.setIsLoading(true);
                    var tokenId = '';

                    sessionApi.createSession({
                        onSuccess: function (data) {

                            retrieveQuote();
                            tokenId = data.MITSessionToken;


                        }, onError: function () {
                            console.log('error');
                            fn.setIsLoading(false);
                        }
                    });


                    var retrieveQuote = function () {
                        confirmationApi.retrieveQuote({
                            onSuccess: function () {

                                fn.setIsLoading(false);
                                fn.stepsUtils = HKSteps.instance($scope, sessionApi);

                                var parameter = {
                                    token: tokenId,
                                    productCode: policySelected.ProductId
                                };

                                $scope.isAccepted = true;
                                fn.stepsUtils.setIsAccepted(true);
                                fn.stepsUtils.setProdOption('motor');

                                $state.go('public.motor.quote.steps.basic', parameter);

                            },
                            onError: function () {
                                fn.setIsLoading(false);
                                console.log('error');
                            }

                        });
                    };
                    //policySelected;
                    //do session here

                    /*var products = {
                     '1000003': {state:'public.motor.quote.steps.basic', code:'Motor Car'},
                     '1000004': {state:'public.travel.quote.steps.basic', code:'travel'},
                     '2000000': {state:'public.motorcycle.quote.steps.basic', code:'motorcycle'},
                     '2000002': {state:'public.sos.quote.steps.history', code:'SOS'},
                     '2000003': {state:'public.termlife.quote.steps.quote', code:'termlife'}
                     };

                     var productCode = products['' + proposal.ProductId].code;
                     var productState = products['' + proposal.ProductId].state;
                     var utils = new MitScopeBindingUtils($scope, $localStorage, productCode);
                     var createMotorQuote = function(){
                     var mitScopeBindingMit = utils;
                     //var pAndCModelScope = mitScopeBindingMit.getScopePAndCModel();
                     var pAndCModelStorage = mitScopeBindingMit.getPAndCModelFromStorage();

                     utils.initScopeAndStorage();
                     fn.setIsLoading(true);
                     Session.create(undefined, undefined,
                     function(data){
                     if (data.success) {
                     //fn.retrieveStatics();
                     //pAndCModelScope.session = data;
                     pAndCModelStorage.session = data;
                     utils.saveToScopePAndCModel('session', pAndCModelStorage.session);


                     data.proposalNumber = proposal.ExternalProposalNumber;

                     Confirmation.retrieveQuote(undefined, data,
                     function(response) {
                     fn.setIsLoading(false);
                     if (response.success) {
                     pAndCModelStorage.isAccepted = true;
                     //$sessionStorage.partialSave = true;
                     $state.go(productState);
                     }
                     },
                     fn.onServiceError
                     );

                     }
                     },
                     fn.onServiceError
                     );
                     };

                     createMotorQuote();*/

                },

                btnShowDownloadClicked: function btnDownloadClicked(proposal) {
                    fn.showDownloadModal();
                    $scope.currentUser.documentsForDownload = Proposal.retrievePolicyDocuments(undefined, {
                        policyNumber: proposal.ExternalProposalNumber
                    });

                }

            },

            utils: {
                /*getStaticDataDisplay: StaticDataKeyValuePair.getStaticDataDisplay*/
            }
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();
        //fn.getStaticData();

        //$scope.isViewingPolicy = fn.isViewingPolicy;

        //Event Handlers
        //$scope.btnViewProposalClicked = fn.eventHandler.btnViewProposalClicked;

        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.btnViewProposalClicked = fn.eventHandler.btnViewProposalClicked;


    }];