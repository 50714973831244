/*
 * Created by Gerald Garcia on 29/06/15.
 */

/***
 * The common THID validation directive.
 * @type {*[]}
 */
module.exports = [
    'HKFormValidation',
    function daHKID(HKFormValidation) {

        return {
            require: 'ngModel',
            link: function(scope, elm, attrs, ctrl) {
                scope.onClickIsEmpty = true;

                var el = jQuery(elm[0]);
                el.attr('type', 'text');
                //el.mask("9-9999-99999-99-9", {"placeholder": " "});
                el.mask("0-0000-00000-00-0");

                elm.focusout(function() {
                    if(elm.val().length < 17){
                        elm.val("");
                    }

                });


                /* ctrl.$parsers.unshift(function(viewValue) {

                    if (ctrl.$isEmpty(viewValue)) {
                        ctrl.$setValidity('da-thid', true);
                        return undefined;
                    }

                    var isValid = HKFormValidation.validateTHID(viewValue);

                    ctrl.$setValidity('da-thid', isValid);

                    if (isValid) {
                        return viewValue;
                    } else {
                        return undefined;
                    }
                }); */
            }
        };
    }];