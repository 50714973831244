/**
 * Created by Gerald Garcia on 15/09/14.
 */
module.exports = [
    '$scope',
    'HKLocale',
    'DaLoadingMask',

    function RootController($scope, HKLocale, DaLoadingMask) {
        $scope.rootVariable = 'declared from RootController';
        $scope.HKLocale = HKLocale;
        $scope.DaLoadingMask = DaLoadingMask;
        HKLocale.initLocale();
    }];