/**
 * Created by swng on 8/9/2015.
 */

module.exports = [
    '$state',
    '$scope',
    'AccountUser',
    'Account',
    //'Address',
    //'PolicyContact',
    'StaticDataKeyValuePair',
    'DaModal',
    'FormValidation',
    '$sessionStorage',
    '$localStorage',
    /**
     * The controller that handles the My account page.
     * This is also responsible of marking the active tab.
     * @param $scope
     * @param Contact
     * @constructor
     */
        function BrokerChangePasswordController($state, $scope, AccountUser,Account,
                                          StaticDataKeyValuePair, DaModal, FormValidation, $sessionStorage, $localStorage) {

        $scope.lblUpdatePassword = 'Update Password';

        var fn = {

            initModel: function initModel(){
                //$scope.frmUpdateCredential.$setPristine();

                $scope.passwordSuccessMessage = '';
                $scope.account = new Account();
                $scope.account.Password= '';
                $scope.account.updated= '';

                //console.log($localStorage.tmp_credential);
                if($localStorage.tmp_credential !== undefined){
                    $scope.account.currentPassword = $localStorage.tmp_credential.Password;
                    $scope.account.user = $localStorage.tmp_credential.UserIdentifier;
                }else{
                    $scope.account.currentPassword = '';
                    $scope.account.user = '';
                }
                $scope.account.newPwd= '';
                $scope.account.newPwdReenter= '';

                //$scope.showPasswordDiv = true;
                //$scope.disabled = true;
                //DaModal.open('#divUpdatePassword');
            },

            validatePwd:function(model){

                if(model.length < 5){
                    return false;
                }
                return true;
            },

            BtnUpdatePasswordClicked:function (frmUpdateCredential){
                $scope.pageSubmitted = true;

                if($scope.hasInvalidChangePassword(frmUpdateCredential)){

                    FormValidation.focusOnError();
                    return;
                }

                var onUpdatePasswordError = function () {
                    DaModal.alert({
                        header:'Error',
                        IsHtml: true,
                        message:$scope.modalUpdatePwd
                    });
                };


                $scope.account.Password= $scope.account.currentPassword;
                $scope.account.updated= $scope.account.newPassword;
                $scope.account.UserIdentifierTypeId= 1;

                Account.changePass(undefined, $scope.account, function(error){
                    if(!error.success){
                        onUpdatePasswordError();
                    }
                    else{
                        $scope.updateSuccess = true;
                        $scope.passwordSuccessMessage = 'Success! Your password has been updated!';
                        $scope.goToLogin();
                    }

                });

            },

            hasInvalidChangePassword: function hasInvalid(frmUpdateCredential){
                return   frmUpdateCredential.$invalid;
            },
            BtnBackClicked: function(){
                $scope.goToLogin();
            }
        };

        $scope.initModel = fn.initModel;
        $scope.BtnUpdatePasswordClicked = fn.BtnUpdatePasswordClicked;
        $scope.$parent.ChangePasswordScope = {
            initModel: fn.initModel
        };
        fn.initModel();
        $scope.BtnBackClicked = fn.BtnBackClicked;
        $scope.hasInvalidChangePassword = fn.hasInvalidChangePassword;
        $scope.validatePwd = fn.validatePwd;
        $scope.modalUpdatePwd = 'Oops!<br />Your new password didn\'t work. Please try a new password.';
    }];