/*
 * Created by Nuwan Amarasinghe on 02/01/15.
 */
module.exports = [
    '$scope',
    '$state',
    'StaticDataKeyValuePair',
    'WfeApiSession',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'WfeApi',

/**
* The controller that handles the Motor quote process and procedure access.
* @param $scope
* @param $localStorage
* @param MitScopeBindingUtils
* @constructor
*/
    function MotorQuoteBaseQuoteController($scope, $state, StaticDataKeyValuePair, WfeApiSession, WfeApiCurrentProposal, WfeApiAsset, WfeApiPolicyContact, WfeApiQuote, WfeApi) {

        var fn = {
            retrieveModels: function (manufacturerId) {
                $scope.models = StaticDataKeyValuePair.getModel({ ManufacturerId: manufacturerId });
                //alert($scope.motor.ManufactureId);
            },

            dropMakeChanged: function (manufacturerId) {
                fn.retrieveModels(manufacturerId);
            },

            goToPreviousPage: function () {
                $scope.wfeApi = WfeApi.instance($state);
                $scope.wfeApi.goToPage('public.car.cardetails');
                //$state.go('public.car.cardetails');
            }
        };

        $scope.motor = {};

        $scope.car = {
            year: 2014,
            make: void 0,
            model: void 0,
            modified: true,
            modifications: {
                bodykit: false,
                rimstires: false,
                suspensionstabiliser: true,
                airintakeexhaust: true
            },
            ownership: void 0,
            financed: null,
            kmsperyear: '> 15,000',
            lowmileageoption: false,
            usage: void 0,
            policy_start_date_day: '',
            policy_start_date_month: '',
            policy_start_date_year: '',
            policy_end_date_day: '',
            policy_end_date_month: '',
            policy_end_date_year: ''
        };

        $scope.proceed = {
            no_content: false
        };

        $scope.proceed.click = function () {
            $scope.proceed.no_content = true;
            console.log('asd');

            // CurrentProposal/Create
            var createCurrentProposal = function () {
                var currentProposal = {
                    ProductId: 1000003,
                    PolicyEndDate: new Date(1460764800000),
                    PolicyStartDate: new Date(1429228800000),
                    PostalCode: 420002
                };

                $scope.wfeApiCurrentProposal = WfeApiCurrentProposal.instance($scope.session.MITSessionToken, $scope, currentProposal);

                $scope.wfeApiCurrentProposal.createCurrentProposal({
                    onSuccess: function () { //onSuccess: function (data) {
                        //alert('Success');
                        createAsset();
                    },
                    onError: function () {
                        //alert('Fail');
                    }
                });
            };

            // Asset/Create
            var createAsset = function () {
                var asset = {
                    ManufactureId: $scope.motor.ManufactureId,
                    ModelId: $scope.motor.ModelId,
                    NcdLevelId: 1000004,
                    NoOfClaimsInLastThreeYearsId: 2000028,
                    NrOfClaimsInPeriodId: 2000028,
                    PurchasingYear: 2014,
                    UsageId: 1000002
                };

                $scope.wfeApiAsset = WfeApiAsset.instance($scope.session.MITSessionToken, $scope, asset, 'Motor');

                $scope.wfeApiAsset.createAsset({
                    onSuccess: function () {
                        //alert('Success');
                        createPolicyContact();
                    },
                    onError: function () {
                        //alert('Fail');
                    }
                });
            };

            // PolicyContact/Create
            var createPolicyContact = function () {
                var pc = {
                    PolicyContactRoleId: 6,
                    RoleName: 'PH',
                    ContactIdentifierList: {
                        IdValue: 'S8579957F',
                        IsMainIdentifier: true
                    },
                    DateOfBirth: new Date(206150400000),
                    GenderId: 2,
                    HasUnsubscribedMarketingEmail: false,
                    IsDemeritPoints: false,
                    IsPersonInfoChanged: false,
                    MaritalStatusId: 1,
                    OccupationId: 1000040,
                    PersonId: 261817,
                    ResidentialStatusId: 1000000,
                    IsMainDriver: true,
                    DrivingExperienceId: 1000001,
                    NoOfClaimsInLastThreeYears: 2000028,
                    NrOfClaimsInPeriodId: 2000028
                };

                $scope.wfeApiPolicyContact = WfeApiPolicyContact.instance($scope.session.MITSessionToken, $scope, pc);

                $scope.wfeApiPolicyContact.createPolicyContact({
                    onSuccess: function () {
                        //alert('Success');
                        callCalcPremium();
                    },
                    onError: function () {
                        //alert('Fail');
                    }
                });
            };

            // Quote/Calculate
            var callCalcPremium = function () {
                $scope.wfeApiQuote = WfeApiQuote.instance($scope.session.MITSessionToken, $scope);

                $scope.wfeApiQuote.calculate({
                    onSuccess: function () {
                        //alert('Success');
                    },
                    onError: function () {
                        //alert('Fail');
                    }
                });
            };


            createCurrentProposal();

        };
        /*
        ================================================
        Get options JSON from API
        
        This is how you would use the CarOptions service
        to query the API and get the year make, and model
        options with which to populate the select fields
        ================================================
        options = CarOptions.query ->
        $scope.years = options.years
        $scope.makes = options.makes
        $scope.models = options.models
        */

        /*
        Set default values for the year options - delete if getting from API
        */
        $scope.years = ['2014', '2013', '2012'];

        /*
        Set default values for the makes options - delete if getting from API
        */
        //$scope.makes = ['VW', 'Nissan', 'Toyota'];
        var param = {
            productId: 1000003
        };
        $scope.makes = StaticDataKeyValuePair.getMake(param);

        // Creating the session
        $scope.wfeApiSession = WfeApiSession.instance($scope);
        $scope.session = {};

        $scope.wfeApiSession.createSession({
            onSuccess: function (data) {
                $scope.session = data;
                angular.extend($scope.session, data);
            }
        });



        /*
        Set default values for the models options - delete if getting from API
        */
        //$scope.models = ['Golf', 'Micra', 'Sera'];

        /*
        A scope function to manually update the model from the view.
        Most often angular will handle this automatically:
        
        e.g.
        <input ng-model='car.make' value='VW'> <-- change input value to update the model
        
        But sometimes it may need to be done manually as well...
        
        e.g.
        <button ng-click="select('make', 'Nissan')">Nissan</button>
        */
        $scope.select = function (value, index, property) {
            $scope.car[property] = value;
            return $scope.car[property];
        };

        /*
        A scope function to be called when the proceed button is pressed - this function
        processes the form
        */
        //return $scope.proceed = function() {

        /*
        For testing only - logs the current state of the car model to
        the console for checking
        */
        //return console.log($scope.car);

        /*
        This function will handle sending data to the back-end API
        */
        //};

        $scope.staticData = $scope.$parent.staticData;
        $scope.dropMakeChanged = fn.dropMakeChanged;

        $scope.goToPreviousPage = fn.goToPreviousPage;


    } ];