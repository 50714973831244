/*
 * Created by Chanon Tangputthajit 2018/03/13
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    'WfeApiSession',
    'HKSteps',
    '$sessionStorage',


    /**
     * Controller that assigns token to the product
     * @param $scope
     * @param $state
     * @constructor
     */
    function WidgetSessionTokenController($scope, $state, $stateParams, WfeApiSession, HKSteps,$sessionStorage) {

        console.log('Widget session controller loaded..');
        var fn = {

            product: {
                ProductId: 2000003,
                ProductCode: 'motor',
                ProductHeader: $scope.HKLocale.getResource('car_h_product_name'),
                isRenewable: true,
                isBILogEnabled: true,
                FirstPageState: 'public.motor.quote.steps.basic'
            },

            initScopeObjects: function (){

                $scope.product = {};
                $scope.prodOption= {};
            }, 

            initApiObjects: function(){

                $scope.product = fn.product;
                $scope.sessionApi = WfeApiSession.instance($scope, fn.product.ProductCode);
                $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);
//suhas1
            },

            onLoadingWithToken: function () {

                $scope.isAccepted = true;
                $scope.stepsUtils.setIsAccepted(true);
                $scope.stepsUtils.setProdOption($scope.prodOption);
                    
                //set locale
                    var localeString = $sessionStorage.widgetItems.lang;
                    
                    /* var localeString = $stateParams.lang; */
                    var callback = function(){
                        fn.navigation.goToNextStep();
                    };
                    if(!localeString)
                    {
                        localeString='en-us';
                    }
                    if(localeString){
                        //console.log(localeString);
                        //console.log('set lang to ' + localeString.toLowerCase() );
                        if(localeString.toLowerCase() === 'th-th'){
                            
                            $scope.HKLocale.setLocaleOnNextLoading('th-th',callback);
                            //$scope.HKLocale.setLocale('da-override-btn-red');
                        }else if(localeString.toLowerCase() === 'en-us'){
                            $scope.HKLocale.setLocaleOnNextLoading('en-us',callback);
                            //$scope.HKLocale.setLocale('en-us');
                        }

                    } else {
                        fn.navigation.goToNextStep();
                    }

                    //$scope.HKLocale.setLocaleOnNextLoading('en-us');


                    return true;
            },
            btnNextClicked: function () {

                    var isValid = true;

                    if (!isValid) {
                        return;
                    }

                    var callbacks =
                    {
                        onSuccess: function () {

                            $scope.isTokenLoaded = !fn.onLoadingWithToken();
                        }
                    };

                    $scope.sessionApi.createSession(callbacks);

            },


            navigation: {
                goToNextStep: function() {
                    if($sessionStorage.maxarReference){delete $sessionStorage.maxarReference};
                    $state.go($scope.product.FirstPageState);
                }
            }, 

            unhashParam: function() {
                //console.log('Go from widget router')
                console.log(window.history.state);
                $scope.widgetItems={};

               ///widgetlanding?make&model&registrationyear&desc&cameraq
                $scope.widgetItems.make=$stateParams.make || '';
                $scope.widgetItems.model=$stateParams.model || '';
                $scope.widgetItems.registrationyear=$stateParams.registrationyear || '';
                $scope.widgetItems.desc=$stateParams.desc || '';
                $scope.widgetItems.cameraq=$stateParams.cameraq || '';
                $scope.widgetItems.lang=$stateParams.lang || '';
                $sessionStorage.widgetItems = $scope.widgetItems;

            },

        };
        fn.unhashParam();
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.btnNextClicked();
        
    }];