/*
 * Created by Chanon Tangputthajit 2018/03/13
 * 
 */
module.exports = [
    '$scope',
    '$rootScope',
    '$state',
    '$timeout',
    'MITDate',
    'HKLocale',
    'WfeApiStaticData',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'DaModal',
    'HKFormValidation',
    'CountryMotorQuoteValidation',
    'WfeApiUser',
    'WfeApiContact',
    'WfeApiAddress',
    '$filter',
    'WfeApiLog',
    'WfeApiEssential',
    '$window',
    '$cookies',
    '$sessionStorage',
    'WfeApiCoverages',
    'WfeApiSession',
    'HKSteps',

    /**
     * Controller for the Basic Details of Motor Quote
     * @constructor
     */
        function WidgetController( $scope, $rootScope, $state, $timeout, MITDate, HKLocale, WfeApiStaticData, WfeApiCurrentProposal,
                                           WfeApiAsset, WfeApiPolicyContact, WfeApiQuote, DaModal, HKFormValidation, CountryMotorQuoteValidation,
                                           WfeApiUser, WfeApiContact, WfeApiAddress, $filter, WfeApiLog, WfeApiEssential, $window, $cookies, $sessionStorage, WfeApiCoverages, WfeApiSession, HKSteps) {

        console.log("Widget controller loaded.")
        var marketing = {
            ga: function () {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/get-quote/step-1/'}
                );
            },
            ga_next: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/your-quote/step-2/'}
                );
            },
            dLCE:function(ref,v)
            {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                switch(ref)
                {
                    case 1:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'manufacture-year':v
                        });
                        break;
                    case 2:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'car-make':v
                        });
                        break;
                    case 3:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'car-model':v
                        });
                        break;
                    case 4:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'car-description':v
                        });
                        break;
                    case 5:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-date-of-birth':v
                        });
                        break;
                    case 6:
                        var s='';
                        if(v==1 || v== '1') {s='Female'};
                        if(v==2 || v== '2') {s='Male'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-gender':s
                        });
                        break;
                    case 7:
                        var s='';
                        if(v==1 || v== '1' || v== "1") {s='Single'};
                        if(v==2 || v== '2' || v== "2") {s='Married'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-marital':s
                        });
                        break;
                    case 8:
                        var s='';
                        if(v==true || v== '1' || v==1) {
                            s='Yes/Sometimes';
                            marketing.dLCE(9,undefined); //reset the value of case 9;

                        };
                        if(v==false || v== '0' || v==0) {
                            s='No';
                            marketing.dLCE(9,false); //also set case 9 to no
                        };
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-drive-to-work':s
                        });

                        break;
                    case 9:
                        var s=undefined;
                        if(v==true || v== '1' || v==1) {s='Yes/Sometimes'};
                        if(v==false || v== '0' || v==0) {s='No'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-use-for-work':s
                        });
                        break;

                    case 13:
                        var s='';
                        if(v==1000003 || v== '1000003') {s='0 %'};
                        if(v==1000005 || v== '1000005' ) {s='20%'};
                        if(v==1000006 || v== '1000006' ) {s='30%'};
                        if(v==1000007 || v== '1000007' ) {s='40%'};
                        if(v==1000008 || v== '1000008' ) {s='50%'};
                        if(v==2500000 || v== '2500000' ) {s='I don\'t know'};

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'no-claims no claims bonus':s
                        });
                        break;
                    case 14:
                        var s='';
                        if(v==1 || v== '1') {s='0'};
                        if(v==2 || v== '2' ) {s='1'};
                        if(v==3 || v== '3' ) {s='2'};
                        if(v==4 || v== '4' ) {s='3'};
                        if(v==5 || v== '5' ) {s='4'};
                        if(v==1000000 || v== '1000000' ) {s='5'};
                        if(v==1000001 || v== '1000001' ) {s='more than 5'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'driving years of driving exp':s
                        });
                        break;

                    case 45:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'policy-number':v
                        });
                        break;
                    case 46:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'equipped-with-camera':v
                        });
                        break;
                    case 47:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'MakeModelNotCovered':v
                        });
                        break;
                    case 48:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'UnderwritingError':v
                        });
                        break;
                    default: break;
                }
            }
        };

        var fn = {

            gtAnchor:function(x){
                if (!x)
                {return;}

                HKFormValidation.animateToID(x);
            },

            setSourceChannels:function(){

                /* sourcechannel details */
                var sc={};
                sc.id=0;sc.cd='';
                sc=$scope.$parent.sourceChanneler(sc);

                $scope.asset.SourceChannelId=sc.id;
                $scope.asset.ChannelDetails=sc.cd;

            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;
                var wfeApiStaticDataYear = fn.wfeApiStaticDataYear;

                var startRetrieval = function () {
                    getBooleanListWithSometimes();
                };

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getCampaignSourceList();
                        },

                        onError: function () {
                            getCampaignSourceList();
                        }
                    });
                };

                var getCampaignSourceList = function () {
                    wfeApiStaticData.getCampaignSourceList({
                        onSuccess: function (data) {
                            $scope.staticData.marketingStrategies = data;
                            getMakeList();
                        },

                        onError: function () {
                            getMakeList();
                        }
                    });
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            /* highlight few models on top */
                            if(data) {
                                var repeatedData = [];
                                repeatedData.push({key: "1000091", value: "Toyota"});
                                repeatedData.push({key: "1000015", value: "Honda"});
                                repeatedData.push({key: "1000031", value: "Nissan"});
                                repeatedData.push({key: "1000025", value: "Mazda"});
                                repeatedData.push({key: "1000080", value: "Isuzu"});
                                repeatedData.push({key: "1000028", value: "Mitsubishi"});
                                repeatedData.push({key: "0000000", value: "--------------"});

                                $scope.staticData.makes = repeatedData.concat(data);
                            }
                            else
                            {
                                $scope.staticData.makes = data;
                            }
                            
                        },

                        onError: function () {
                            console.log("Can't get data");
                        }
                    });
                };

                startRetrieval();

            },

            // default product 
            product: {
                ProductId: 2000003,
                ProductCode: 'motor',
                ProductHeader: $scope.HKLocale.getResource('car_h_product_name'),
                isRenewable: true,
                isBILogEnabled: true
            },

            initScopeObjects: function () {
                $scope.staticData = {};

                var dateTomorrow = MITDate.newDate().add(1, 'days');
                $scope.currentProposal = {
                    PolicyStartDate: MITDate.toAngularDate(dateTomorrow),
                    PolicyEndDate: MITDate.toAngularDate(dateTomorrow.add(1, 'years'))
                };
                $scope.asset = {
                    HasAntiTheftDevice: false,
                    'LobInsuranceFormulaId': 2000015, // to be removed later
                    UsageId: 7000000

                };
                $scope.product = fn.product;
                $scope.sessionApi = WfeApiSession.instance($scope, fn.product.ProductCode);
                $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);

                var dateToday = MITDate.newDate();
                $scope.yearNow=(parseInt(MITDate.getYYYY(dateToday))-9) || 1941;

                $scope.asset.ProductId = fn.product.ProductId;

                $scope.policyHolder = {};
                $scope.mainDriver = {};

                $scope.accountUser = {};
                $scope.phAddress = {};
                $scope.phContact = {};

                $scope.bidata = {};
                $scope.errdata = {};

                $scope.securityQuestion = {};
                $scope.isSecurityQuestion = false;

                $scope.DOBEmpty = false;
                $scope.loadingSpinner = true;
                $scope.partialPolicyNumber = "";

                $scope.applyNCB;

            },

            initApiObjects: function () {

                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                //fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                /* fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);
 */
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), fn.product.ProductId);
                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.yearNow);
                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);
                /* fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress); */

                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, $scope.errdata);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

            },

            /** Subscribe MKT email condition */
            initSubscribeWatcher:function()
            {
                $scope.$watch('policyHolder.HasUnsubscribedMarketingEmail', function(value) {
                    $scope.policyHolder.SubscribedMarketingEmail = !value;
                });
                $scope.$watch('policyHolder.SubscribedMarketingEmail', function(value) {
                    $scope.policyHolder.HasUnsubscribedMarketingEmail = !value;
                });

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        destroyWatcher();
                    }
                );
            },

            retrieveScopeObjects: function () {

                /** Fire to GA that customer has been visited page */
                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                try {
                                    marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveAsset();
                            },
                            onError: function () {
                                try {
                                    marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);

                                if ($scope.asset.ManufactureId) {
                                    if ($scope.asset.PurchasingYear) {
                                        fn.eventHandlers.onDropMakeChanged($scope.asset.ManufactureId);
                                        $scope.asset.PurchasingYear = $scope.asset.PurchasingYear.toString();
                                    }
                                    if ($scope.asset.ModelId) {
                                        fn.eventHandlers.onDropModelChanged($scope.asset.ManufactureId, $scope.asset.ModelId);
                                    }
                                    if ($scope.asset.PurchasingYear){
                                        fn.eventHandlers.onDropPurchasingYearChanged($scope.asset.ModelId, $scope.asset.PurchasingYear);
                                    }
                                }
                            }
                        }
                    );
                };

                //retrievePolicyHolder();
                //retrieveCurrentProposal();
                retrieveAsset();
            },

            retrieveAccountUserObjects: function () {
                var retrieveUser = function () {
                    if ($scope.$parent.isLoggedIn() && angular.equals({}, $scope.policyHolder)) {
                        fn.wfeApiUser.retrieveUser(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.accountUser, data);
                                    fn.utils.populateAccountUserModel();
                                },
                                onError: function () {
                                }
                            }
                        );
                    }
                };

                retrieveUser();
            },

            utils: {

                setSelectedCoverage: function (selectedCoverage) {
                    $scope.selectedCoverage = selectedCoverage;
                    $scope.isBtnProceedDisabled = false;
                    $scope.isCoverSelected = true;
                },

                setDefaultCoverage: function()
                {
                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000015})[0];
                    if(selectedCoverage) {
                        selectedCoverage.IsSelected = true;
                        selectedCoverage.ExcessLevelId = 7000258;
                    }
                },

                IsCompulsarySelectedFrontEndFn:function() {
                    if(!$scope.coverages)return false;
                    var status=false;
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {

                        try{
                            var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                                ProductLineGroupId: 2000161,
                            }, true);
                            if (ccb) {
                                if (ccb[0].IsSelected) status= true;
                                else status= false;
                            }

                        }
                        catch (err)
                        {
                            status=false; return status;
                        }
                    }
                    return status;
                },

                getSelectedCoverage: function () {

                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {IsSelected: true})[0];

                    if (selectedCoverage) {
                        $scope.selectedCoverage = selectedCoverage;
                    }
                    else {
                        $scope.selectedCoverage = null;
                    }

                    return selectedCoverage;
                },

                /** Doesn't use now */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                autoSetEndDate: function () {

                    if (!$scope.currentProposal.PolicyStartDate) {
                        return;
                    }

                    var startDate = MITDate.newDate($scope.currentProposal.PolicyStartDate);
                    var endDate = startDate.add(1, 'years');
                    $scope.currentProposal.PolicyEndDate = MITDate.toAngularDate(endDate);

                },

                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                    $scope.DaLoadingMask.toggle(isSaving);
                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    //$scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    /*$scope.policyHolder.OccupationId = $scope.mainDriver.OccupationId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.IsDemeritPoints = $scope.mainDriver.IsDemeritPoints;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;*/
                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    fn.utils.ConvertMITDateToDisplay();
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    //$scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    /*$scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;*/
                },

                /** To push phContact and phAddress of existing user to scope object */
                populateAccountUserModel: function () {
                    angular.extend($scope.policyHolder, $scope.accountUser.Person);
                    $scope.policyHolder.IsMainDriver = true;
                    fn.utils.copyPHFieldsToMD();

                    if ($scope.accountUser.Address) {
                        var phAddress = $scope.accountUser.Address.Addresses[0];

                        if (phAddress) {
                            angular.extend($scope.phAddress, phAddress);
                            $scope.asset.ResidentialDistrictId = phAddress.ProvinceId;
                        }
                    }

                    if ($scope.accountUser.Contact) {
                        var contact = $scope.accountUser.Contact;

                        var PrimaryPhoneNumber = $filter('filter')(contact.Contacts, {
                            ContactType: 1,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryPhoneNumber) {
                            $scope.phContact.PrimaryPhoneNumber = PrimaryPhoneNumber.Value;
                        }

                        var PrimaryEmailAddress = $filter('filter')(contact.Contacts, {
                            ContactType: 0,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryEmailAddress) {
                            $scope.phContact.PrimaryEmailAddress = PrimaryEmailAddress.Value;
                        }
                    }
                },

                convertMITDate:function(){
                    if(typeof $scope.mainDriver.DOB_DD !== "undefined" &&
                       typeof $scope.mainDriver.DOB_MON !== "undefined" &&
                       typeof $scope.mainDriver.DOB_Year !== "undefined"){

                        $scope.$parent.setDateValidity($scope.mainDriver.DOB_DD,$scope.mainDriver.DOB_MON,$scope.mainDriver.DOB_Year,$scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.mainDriver.DateOfBirth=$scope.$parent.convertDateThreeToOne($scope.mainDriver.DOB_DD,$scope.mainDriver.DOB_MON,$scope.mainDriver.DOB_Year);
                        $scope.mainDriver.DateOfBirth==null?($scope.mainDriver.DateOfBirth=''):'';
                        $scope.validations.isValidDriverAge($scope.mainDriver.DateOfBirth,$scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.DOBEmpty = false;
                    }
                },

                ConvertMITDateToDisplay:function(){
                    if($scope.mainDriver.DateOfBirth)
                    {
                        var d=$scope.$parent.convertDateOneToThree($scope.mainDriver.DateOfBirth);
                        if(d!=null || d.length>0)
                        {
                            $scope.mainDriver.DOB_DD=d[0];
                            $scope.mainDriver.DOB_MON=d[1];
                            $scope.mainDriver.DOB_Year=d[2];
                        }
                    }


                }
            },

            navigation: {
                goToNextPage: function () {
                    fn.utils.setIsSaving(false);
                    fn.populateMaxar();

                    fn.getMaxarPriority();
                    /* Default Maxar priority*/
                    // fn.postToMaxParent('No',priority,'N');
                    try {
                        marketing.ga_next();
                    } catch (err) {
                        console.log(err);
                    }
                    $state.go('public.motor.quote.steps.cover');
                }
            },

            saving: {

                setUsageId: function () {
                    if (1 == 0) {
                        /*car desc is hidden, tarrif 320 cars*/
                        $scope.asset.UsageId = '7000000';
                    }
                    else {
                        if ($scope.asset.InDriveWork == false) {
                            $scope.asset.UsageId = '6';
                            return;
                        }

                        if ($scope.asset.InDriveWork == true) {
                            if ($scope.asset.InCourseWork == true) {
                                $scope.asset.UsageId = '1000002';
                                return;
                            }
                            else {
                                $scope.asset.UsageId = '1000000';
                            }
                        }
                    }
                },

                saveCurrentProposalChildModels: function () {

                    /**
                     * Section 1 : Create necessary objects, then pre calculate.
                     * */

                    var hasError = false;
                    var triggerSave = function () {
                        saveAsset();
                    };

                    var saveAsset = function () {
                        fn.saving.setUsageId();
                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');

                        if(saveAssetFunc === "updateAsset"){
                            if($scope.asset.NcdLevelId != "1000003"){
                                $scope.asset.ReasonForNcdIsZero = "0";
                                $scope.asset.NcdLevelIdOnOtherCar = "0";

                            }
                        }

                        fn.assetApi[saveAssetFunc](
                            {
                                onSuccess: function (data) {
                                    savePH(); /*hold on to Contact PH for Email and Mobile later.*/
                                },
                                onError: function () {
                                    hasError = true;
                                    savePH();
                                }
                            }
                        );
                    };

                    var savePH = function () {
                        $scope.loc='';
                        $scope.loc=HKLocale.getLocale();

                        if($scope.loc=='en-us')
                        {
                            $scope.policyHolder.PreferredLanguageId=101;
                        }

                        if($scope.loc=='th-th')
                        {
                            $scope.policyHolder.PreferredLanguageId=2000000;
                        }

                        $scope.policyHolder.IsMainDriver = true;
                        if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }

                        if($scope.HKLocale.getLocale() === "en-us"){
                            $scope.policyHolder.PreferredLanguageId = 101;
                        }else{
                            $scope.policyHolder.PreferredLanguageId = 2000000;
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function (data) {
                                    saveMD();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveMD();
                                }
                            }
                        );
                    };

                    var saveMD = function () {
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');
                        if (!saveMDFunc) {
                            savePHContact();
                            return;
                        }

                        fn.mainDriverApi[saveMDFunc](
                            {
                                onSuccess: function () {
                                    savePHContact();
                                },
                                onError: function () {
                                    hasError = true;
                                    savePHContact()
                                }
                            }
                        );
                    };

                    var savePHContact = function () {
                        var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                        fn.phContactApi[savePHContactFunc](
                            {
                                onSuccess: function () {
                                    calculatePremium();
                                },
                                onError: function () {
                                    hasError = true;
                                    calculatePremium();
                                }
                            }
                        );
                    };

                    /** Doesn't use for now */
                    var savePHAddress = function () {
                        var savePHAddressFunc = fn.phAddressApi.utils.getSaveFunctionName($scope.phAddress, 'PHAddress');
                        fn.phAddressApi[savePHAddressFunc](
                            {
                                onSuccess: function () {

                                    if (!$scope.currentProposal.IsRenewalProposal) {
                                        fn.essentialApi.isRenewalProposalExist(
                                            {
                                                onSuccess: function (mitEssentialResponse) {
                                                    var result = mitEssentialResponse.IsRenewalProposalExist;
                                                    if (result) {
                                                        fn.utils.setIsSaving(false);
                                                        DaModal.open('#renewalProposalExistsMessage');
                                                    }
                                                    else {
                                                        calculatePremium();
                                                    }
                                                },
                                                onError: function () {
                                                    calculatePremium();
                                                }
                                            }
                                        );
                                    }
                                    else {
                                        calculatePremium();
                                    }
                                },
                                onError: function () {
                                    hasError = true;
                                    calculatePremium();
                                }
                            }
                        );
                    };

                    var calculatePremium = function () {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }

                        fn.quoteApi.calculate(
                            {
                                onSuccess: function(){
                                    retrieveCurrentProposal();
                                },
                                onError: function (data) {
                                    marketing.dLCE(48, 'Yes');
                                    fn.utils.setIsSaving(false);
                                    try {
                                        if (!$scope.currentProposal.IsRenewalProposal) {
                                            $scope.bidata = {
                                                Source: $scope.$parent.bi.whereDidYouHereUs
                                            };
                                            $scope.errdata = data;
                                            if ($scope.$parent.bi.whereDidYouHereUs) {
                                                fn.logApi.writeQuoteRejectLog();
                                            }
                                        }
                                    } catch (err) {
                                    }

                                    fn.eventHandlers.onDefaultErrorHandler();
                                }
                            }
                        );
                    };

                    triggerSave();
                },

            },

            validations: {

                checkMake:function()
                {
                  if($scope.asset.ManufactureId=="0000000" || $scope.asset.ManufactureId==0000000)
                  {
                      $scope.frmAboutCar.vehicleMake.$setValidity("valid",false);
                  }
                  else
                  {
                      $scope.frmAboutCar.vehicleMake.$setValidity("valid",true);
                  }
                },

                isStartDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidStartDate($scope.currentProposal.PolicyStartDate, formElement);
                },

                isEndDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidEndDate($scope.currentProposal.PolicyEndDate, $scope.currentProposal.PolicyStartDate, formElement);
                },

                validateForms: function () {
                    return $scope.frmAboutMainDriver.$invalid || $scope.frmAboutCar.$invalid;
                },

                hasInvalid: function hasInvalid() {
                    var isInvalid = false;
                    /*honey pot attack*/
                    if ($scope.securityQuestion && $scope.securityQuestion.length > 0) {
                        DaModal.alert('got you');
                        return true;
                    }
                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;
                },

                checkDrivingExp:function(){
                    /*lessThanTwoDrivingExp*/
                    if($scope.mainDriver.DrivingExperienceId)
                    {
                        if($scope.mainDriver.DrivingExperienceId==1 ||$scope.mainDriver.DrivingExperienceId=="1"
                            || $scope.mainDriver.DrivingExperienceId==2 ||$scope.mainDriver.DrivingExperienceId=="2")
                        {
                            //Less than 2 driving experiencee.
                            $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', false);
                            return;
                        }
                    }
                    $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', true);
                },

            },

            eventHandlers: {

                onPolicyStartDateChanged: function () {
                    fn.utils.autoSetEndDate();
                },

                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },

                onDropMakeChanged: function (manufacturerId) {

                    if(HKLocale.getLocale() == "th-th"){
                        $("#vehicleModel option:first").text("กำลังโหลด...");
                    }else{
                        $("#vehicleModel option:first").text("Loading...");
                    }

                    if (!manufacturerId) {
                        if ($scope.staticData.models) {
                            $scope.staticData.models.splice(0);
                        }
                        return;
                    }
                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    //wfeApiStaticDataModel.getModelListWithYear({
                    wfeApiStaticDataModel.getModelListV2({
                        onSuccess: function (data) {
                            $scope.staticData.models = data;
                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            }else{
                                $("#vehicleModel option:first").text("Please select...");
                            }
                            if(data.length==0) {
                                marketing.dLCE(47, 'Yes');
                                DaModal.open("#makeAndModelNotAvailable");
                            }
                        },
                        onError: function (){
                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            }else{
                                $("#vehicleModel option:first").text("Please select...");
                            }
                        }
                    });

                },

                onDropModelChanged: function (manufacturerId, modelId) {

                    if(HKLocale.getLocale() == "th-th"){
                        $("#vehicleFirstReg option:first").text("กำลังโหลด...");
                    }else{
                        $("#vehicleFirstReg option:first").text("Loading...");
                    }

                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    objParams.modelId = modelId;

                    if(typeof modelId !== "undefined") {
                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                        //wfeApiStaticDataYear.getVehicleYearListByStartYear({
                        wfeApiStaticDataModel.getYear({
                            onSuccess: function (data) {
                                $scope.staticData.yearOfRegistrations = data;
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกปีที่ผลิต");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }
                            },
                            onError: function () {
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกปีที่ผลิต");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }
                            }
                        });
                    }
                },

                onDropPurchasingYearChanged: function (modelId, purchasingYear) {
                    if(HKLocale.getLocale() == "th-th"){
                        $("#vehicleDesc option:first").text("กำลังโหลด...");
                    }else{
                        $("#vehicleDesc option:first").text("Loading...");
                    }

                    var objParams = {};
                    objParams.modelId = modelId;
                    objParams.purchasingYear = purchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getCarDescWithYear({
                        onSuccess: function (data) {
                            $scope.staticData.cardescriptions = data;
                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            }else{
                                $("#vehicleDesc option:first").text("Please select...");
                            }
                        },
                        onError: function (){
                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            }else{
                                $("#vehicleDesc option:first").text("Please select...");
                            }
                        }
                    });

                },

                onCarDescriptionChanged: function (descKey) {

                     var carTarrif=$filter('filter')($scope.staticData.cardescriptions, {
                         key: descKey
                     })[0];

                        if(carTarrif.tariffType)
                        {
                            if(carTarrif.tariffType ==='320' || carTarrif.tariffType==320)
                            {
                                // Do not respect IsDriveWork/IsUseWork/Usage is fixed.
                                $scope.asset.TariffType=320;
                            }
                            else
                            {
                                $scope.asset.TariffType=110;

                            }
                        }
                        return;

                },

            }
        };

        /** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initSubscribeWatcher();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        /** Event Handlers */
        $scope.onDropMakeChanged = fn.eventHandlers.onDropMakeChanged;
        $scope.onDropModelChanged = fn.eventHandlers.onDropModelChanged;
        $scope.onDropPurchasingYearChanged = fn.eventHandlers.onDropPurchasingYearChanged;
        $scope.onBtnInDriveWorkChange = fn.eventHandlers.onBtnInDriveWorkChange;
        $scope.onPolicyStartDateChanged = fn.eventHandlers.onPolicyStartDateChanged;
        $scope.onIsPHMDButton = fn.eventHandlers.onIsPHMDButton;
        $scope.onBtngoToLandingpage = fn.eventHandlers.onBtngoToLandingpage;
        $scope.onCarDescriptionChanged = fn.eventHandlers.onCarDescriptionChanged;


        /** Validations */
        $scope.validations = {
            checkDOBEmpty: fn.validations.checkDOBEmpty,
            isStartDateInvalid: fn.validations.isStartDateInvalid,
            isEndDateInvalid: fn.validations.isEndDateInvalid,
            isValidDriverAge: $scope.$parent.isInvalidDriverAge
        };

        $scope.checkDrivingExp = fn.validations.checkDrivingExp;
        $scope.checkNCDLevel = fn.validations.checkNCDLevel;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;

        $scope.convertMITDate = fn.utils.convertMITDate;
        $scope.checkMake=fn.validations.checkMake;

        $scope.dLCE=marketing.dLCE;
        $scope.gtAnchor=fn.gtAnchor;

        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function() {
            $timeout(function () {
                $window.optimizely = $window.optimizely || [];
                $window.optimizely.push(['activate']);

            }, 0);
        });

    }];