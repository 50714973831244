/***
 * The postal code directive.
 * @type {*[]}
 */
module.exports = [
    'WfeApiStaticData',
    function daThPostalChange(WfeApiStaticData) {

        return {
            require: 'ngModel',

            link: function(scope, elm, attrs, ctrl) {

                ctrl.$parsers.unshift(function(viewValue) {

                    if (ctrl.$isEmpty(viewValue)) {
                        ctrl.$setValidity('da-th-postal-change', true);
                        return undefined;
                    }

                    var isValid = true;
                    scope.phAddress.ProvinceId = '';
                    scope.phAddress.DistrictId = '';

                    if(viewValue.length == 5){
                        var wfeApiStaticData = WfeApiStaticData.instance(scope, scope.HKLocale.getLocale(), {"postalCode": viewValue});

                        wfeApiStaticData.getProvinceListByPostal({
                            onSuccess: function (data) {
                                scope.staticData.provincesByPostal = data;
                                if(data.length > 0){
                                    scope.phAddress.ProvinceId = scope.staticData.provincesByPostal[0]['key'];

                                    scope.txtPhAddressProvinceId = scope.staticData.provincesByPostal[0]['value'];
                                    scope.frmYourAdress.ProvinceId = scope.phAddress.ProvinceId;

                                    var wfeApiStaticData = WfeApiStaticData.instance(scope, scope.HKLocale.getLocale(), {"postalCode":scope.phAddress.PostalCode, "provinceId": scope.phAddress.ProvinceId});
                                    wfeApiStaticData.getDistrictListByPostal({
                                        onSuccess: function (data) {
                                            scope.staticData.districtByPostal = data;
                                            if(data.length > 0){
                                                scope.phAddress.DistrictId = scope.staticData.districtByPostal[0]['key'];

                                                scope.phAddress.txtPhAddressDistrictId =scope.staticData.districtByPostal[0]['value'];                                            
                                                scope.txtPhAddressDistrictId = scope.staticData.districtByPostal[0]['value'];
                                                
                                                scope.frmYourAdress.district = scope.phAddress.DistrictId;
                                            }
                                        }
                                    });

                                }
                            }
                        });
                    }

                    ctrl.$setValidity('da-th-postal-change', isValid);

                    if (isValid) {
                        return viewValue;
                    } else {
                        return undefined;
                    }
                });
            }
        };
    }];