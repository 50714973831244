module.exports = [
    '$scope',
    '$window',
    'HKLocale',
    'AccountUser',
    'WfeApiStaticData',
    'WfeApiUser',

    function MyProfileController($scope,$window, HKLocale, AccountUser, WfeApiStaticData, WfeApiUser) {

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/myaccount/customer-access/profile/'}
                );
                /*if(ga) {ga('send', 'pageview', '/myaccount/customer-access/profile/');}*/
            }
        };

        var fn = {
            initScopeObjects: function () {
                $scope.showPasswordDiv = false;
                $scope.isDisabled = true;
                $scope.hasError = false;
                $scope.isReadOnly = true;
                $scope.accountUser = {};

                $scope.BtnEditClicked = fn.eventHandlers.BtnEditClicked;
                $scope.BtnChangePass = fn.eventHandlers.BtnChangePass;
                $scope.BtnCancelClicked = fn.eventHandlers.BtnCancelClicked;

                $scope.MyProfileScope = {
                    setDisabled: fn.utils.setDisabled
                };
            },

            initApiObjects: function () {
                try {
                    marketing.ga();
                } catch (err) {
                    console.log(err);
                }

                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);
            },

            initStaticData: function () {
            },

            retrievePerson: function () {
                fn.wfeApiUser.retrieveUser(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.accountUser, data);
                        },
                        onError: function () {
                        }
                    }
                );
            },

            retrieveScopeObjects: function () {
                //fn.retrievePerson();
            },

            saving: {
                updateProfile: function () {
                    fn.wfeApiUser.updateProfile({
                        onSuccess: function () {
                            fn.retrievePerson();
                        },
                        onError: function () {
                            fn.retrievePerson();
                        }
                    });
                },
            },

            validations: {},

            utils: {
                setDisabled: function (value) {
                    $scope.isDisabled = value;
                    $scope.isReadOnly = value;
                }
            },

            eventHandlers: {
                BtnEditClicked: function () {
                    $scope.showPasswordDiv = false;
                    fn.utils.setDisabled(false);
                    //fn.saving.updateProfile();
                },
                BtnCancelClicked: function () {
                    $scope.showPasswordDiv = false;
                    fn.utils.setDisabled(true);
                    $scope.MyProfileFormDetailsScope.retrieveScopeObjects();
                },
                BtnChangePass: function () {
                    $scope.showPasswordDiv = true;
                    fn.utils.setDisabled(false);
                }
            },
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

    }];