/*
 * Created by Nuwan Amarasinghe on 02/01/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$filter',
    'DaModal',
    'FormValidation',
    'DaLoadingMask',
    'WfeApiSession',
    'HKSteps',
    'WfeApiStaticData',
    'HKLocale',
    'MITDate',
    '$window',
    '$http',
    '$sessionStorage',
    'Session',
    'Idle',
    '$cookies',
    'AppConstants',
    /**
     * The controller that handles the Motor quote current step number.
     * This is also responsible on step navigation marking of active tab.
     * @param $scope
     * @param $state
     * @constructor
     */
    function BrokerQuoteStepsController($scope, $state,$filter, DaModal, FormValidation, DaLoadingMask, WfeApiSession, HKSteps,WfeApiStaticData,HKLocale,MITDate,$window,$http,$sessionStorage, Session, Idle, $cookies, AppConstants)
    {

        var fn = {

            /** functions form base controller : WTH didn't use baseController*/
            product: {
                ProductId: AppConstants.AGGConfig.PRODUCT_ID_DA,
                ProductCode: 'motor',
                ProductHeader: $scope.HKLocale.getResource('car_h_product_name'),
                isRenewable: false,
                isBILogEnabled: true
            },

            initScopeObjects: function(){

                $scope.product = fn.product;
                $scope.sessionApi = WfeApiSession.instance($scope, fn.product.ProductCode);
                $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);

                $scope.asiapay = {};
                $scope.coverages = {};
                $scope.selectedCoverage = {};
                $scope.selectedPayment = "";
                $scope.maxarReference='';
                $scope.maxarObject={};
                $scope.maxarCallbackTrigger=false;
                $scope.bi = {};

                $scope.staticData = {};
                $scope.staticData.sourceChannels = [];
                $scope.gaCampaign = "";
                $scope.applyNCBRoot;
                $scope.applyNCBRootDontKnow;
                $scope.showPriceOnTopFlag = false;
                $scope.PlanId = 1000003; //default plan as value plus

            },

            initApiObjects: function () {
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), fn.product.ProductId);
            },

            initStaticData: function () {
                /* var getSourceChannels = function () {
                    fn.wfeApiStaticData.getSourceChannels({
                        onSuccess: function (data) {
                            $scope.staticData.sourceChannels = data;
                        },
                        onError: function () {
                            $scope.staticData.sourceChannels = {};
                        }
                    });
                }; */

                //getSourceChannels();

            },

            sessionExpiredHandler: function () {
                // $scope.$parent.baseMitScopeBindingUtils.clearPAndCModelFromStorage();
                if($sessionStorage)
                {
                    if($sessionStorage.fromTerminal)
                    {
                        if($sessionStorage.fromTerminal==1)
                        {
                            DaLoadingMask.toggle(false);
                            $scope.$parent.baseMitScopeBindingUtils.clearPAndCModelFromStorage();
                            $state.go('public.partnerterminal');
                            return;
                        }
                    }
                }
                DaLoadingMask.toggle(false);
                DaModal.open('#sessionExpired',{onClose: fn.goToStart});
            },

            setIsAccepted: function (isAcceptedParam) {
                $scope.stepsUtils.setIsAccepted(isAcceptedParam);
            },

            getIsAccepted: function () {
                return $scope.stepsUtils.getIsAccepted();
            },

            getBaseCover: function(cover)
            {
                if(cover.ProductLineGroupId === 1000087) {
                    return true;
                }

                return false;
            },

            calculateTotalAmout: function(coversges){

                if ((!coversges) || !coversges.Coverages) {
                    return 0;
                }

                var coverage = $filter('filter')(coversges.Coverages, {IsSelected:true}, true)[0];

                if (!coverage) {
                    return 0;
                }

                var baseCover = $filter('filter')(coverage.Covers, fn.getBaseCover)[0];
                if (!baseCover) {
                    return;
                }

                var runningTotal = baseCover.TotalPremiumToColl;

                var optionalCovers = $filter('filter')(coverage.Covers, {ProductLineType: 2, IsSelected: true}, true);
                if (optionalCovers.length === 0) {
                    return runningTotal;
                }

                /** "CoverName": "Accessories","ProductLineGroupId": 1000096 */
                angular.forEach(optionalCovers, function(cover) {
                    if (cover.ProductLineGroupId !== 1000096) {
                        runningTotal += cover.TotalPremiumToColl || 0.0;
                    }
                });

                return runningTotal;

            },

            goToStart: function(){
                $scope.stepsUtils.clear();
                $scope.setIsAccepted(false);
                $scope.goToHomePage();
            },

            disablePremium: function(){
                var tabName = ['basic', 'done', 'counterdone', 'issue'];

                for(var i in tabName){
                    if($state.current.name === 'public.motor.quote.steps.' + tabName[i]){
                        return 'disabled';
                    }
                }

                return 'active';
            },

            isUserProceedOnBuy: function(){
                var tabName = ['basic', 'cover', 'customise', 'quote', 'confirm']
                for(var i in tabName){
                    if($state.current.name === 'public.motor.quote.steps.' + tabName[i]){
                        if($sessionStorage.tachk == 1){
                            $state.go('public.motor.quote.steps.buy');
                        }else if($sessionStorage.tachk == 2) {
                            $state.go('public.motor.quote.steps.confirm');
                        }
                    }
                }
            },

            hideEstimatedMsg: function(){
                var tabName = ['confirm', 'buy'];

                for(var i in tabName){
                    if($state.current.name === 'public.motor.quote.steps.' + tabName[i]){
                        return true;
                    }
                }

                return false;
            },

            disableBreadcrumb: function(){
                var tabName = ['done','counterdone'];

                for(var i in tabName){
                    if($state.current.name === 'public.motor.quote.steps.' + tabName[i]){
                        return false;
                    }
                }

                return true;
            },

            getActiveTabSPA: function(tabno) {
                if(tabno == $sessionStorage.currentPage){
                    return "active";
                }
                
            },

            checkUWFC: function(){
                var obj = {};
                var UWFCLayer = $window.UWFCLayer;
                angular.extend(obj, UWFCLayer[0]);
                var requestString = JSON.stringify(obj);
                var confighead = {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                    
                $http.post(config.api + '/ExternalServices/Falcon/CheckUnderwriting',requestString, confighead)
                    .success(function(data){
                        //console.log('uwok');
                    })
                    .error(function (data, status, headers, config) {
                        DaModal.open('#daExceptionModal',{onClose: fn.goToStart});
                        /* console.log(data);
                        $scope.status = status; */
                    });
            },
            checkBLFC: function(){
                var obj = {};
                var BLFCLayer = $window.BLFCLayer;
                angular.extend(obj, BLFCLayer[0]);
                var requestString = JSON.stringify(obj)

                var confighead = {
                    withCredentials: true,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }

                $http.post(config.api + '/ExternalServices/Falcon/CheckBlacklist', requestString, confighead)
                    .success(function(data){
                        //console.log(data);
                        if(data.ErrorDescription == "Success"){
                            if(data.Data.IsAcceptable){ // Is blacklist
                                DaModal.open('#daExceptionModal',{onClose: fn.goToStart});
                            }else{
                                //console.log("nabl");
                            }
                        }
                    })
                    .error(function (data, status, headers, config) {
                        DaModal.open('#daExceptionModal',{onClose: fn.goToStart});
                        /* console.log(data);
                        $scope.status = status; */
                    });
            },

            filterStatic:function(obj,val){
                var result='';
                for( var i = 0, len = obj.length; i < len; i++ ) {
                    if( obj[i].key === val ) {
                        result = obj[i].value;
                        break;
                    }
                }
                return result;
            },

            filterDesc:function(obj,val){
                var result='';
                for( var i = 0, len = obj.length; i < len; i++ ) {
                    if( obj[i].key === val ) {
                        result = obj[i].description;
                        break;
                    }
                }
                return result;
            },

            validations: {

                validateCurrentStep: function(){
                    if (!$scope.getIsAccepted()) {
                        fn.goToStart();
                        return false;
                    }
                    return true;
                },

                validateToken: function (callbacksParam) {
                    callbacksParam = callbacksParam || {};

                    var onSuccess = callbacksParam.onSuccess;
                    var onError = callbacksParam.onError;
                    var callbacks = {
                        onSuccess: function (data) {
                            if (onSuccess) {
                                onSuccess(data);
                            }
                        },

                        onError: function (data) {
                            if (onError) {
                                onError(data);
                            }
                            $scope.showSessionExpired();
                        }
                    };

                    $scope.sessionApi.retrieveSession(callbacks);

                },

                isInvalidDriverAge: function (dateOfBirthString, formElement) {
                    if (!dateOfBirthString) {
                        if (formElement) {
                            formElement.$setValidity('custom_age', true);
                        }
                        return true;
                    }

                    var isValidAge = FormValidation.isValidAge(20, 70, dateOfBirthString);

                    if (formElement) {
                        formElement.$setValidity('custom_age', isValidAge);
                    }

                    return !isValidAge;
                },

                isInvalidYearsOfExperience: function isInvalidYearsOfExperience(yearsOfExpId, formElement) {

                    if (!yearsOfExpId) {
                        formElement.$setValidity('custom_yrs_exp', true);
                        return true;
                    }

                    var invalidExperiences = ['1','2'];

                    var isInvalidExp = invalidExperiences.indexOf(yearsOfExpId) >= 0;

                    if (formElement) {
                        formElement.$setValidity('custom_yrs_exp', !isInvalidExp);
                    }

                    return isInvalidExp;
                },

                isInvalidAccidentClaims: function isInvalidAccidentClaims(valueId, formElement) {

                    if (!valueId) {
                        formElement.$setValidity('custom_accident_claims', true);
                        return true;
                    }

                    var validValues = ['1000003', '1000004', '1000008'];

                    var isValidValue = validValues.indexOf(valueId) >= 0;

                    if (formElement) {
                        formElement.$setValidity('custom_accident_claims', isValidValue);
                    }

                    return !isValidValue;
                }

            },

            utils:{

                /** Redirect to thank you page */
                divertAfterSECB:function()
                {
                    var loc=HKLocale.getLocale() || 'th-th';
                    if(loc=='en-us') {
                        $window.location.href = config.homePageUrl + '/../en/thankyou-for-saving-proposal/';
                    } else {
                        $window.location.href = config.homePageUrl + '/../thankyou-for-saving-proposal/';
                    }

                },

                getMonthlyPriceStepCtrl:function(cc) {

                    if ((!cc) || !cc.Coverages) {
                        return 0;
                        
                    }
                    var coverage = $filter('filter')(cc.Coverages, {IsSelected:true}, true)[0];
                    if (!coverage) {
                        return 0;
                        
                    }
                    if(coverage.PlanId === $scope.PlanId){
                        var x=fn.utils.getPriceToShow(coverage);
                        $scope.totalPremium2=x;
                        return(x);
                        
                    }else{
                        var swCoverage = $filter('filter')(cc.Coverages, {IsSelected: false, PlanId: $scope.PlanId, CoverTypeId: coverage.CoverTypeId})[0];
                        if(swCoverage){
                            var y=fn.utils.getPriceToShow(swCoverage);
                            $scope.totalPremium2=y;
                            return(y);
                        }else{
                            return 0;
                        }
                    }
                },

                getPriceToShow:function(c){
                    if(!c) {
                        return 0;
                    }
                    var ccb=$filter('filter')(c.Covers, {
                        ProductLineGroupId: 2000161,
                        IsSelected:true
                    },true);
                    var total=0;
                    if(c.CalculatedPremium) {
                        total= c.CalculatedPremium;
                    }
                    if(ccb){if(ccb[0]){if(ccb[0].TotalPremiumToColl){total=total+ccb[0].TotalPremiumToColl;}}}
                    //console.log(c);
                    //console.log("return total : " + total);
                    return total;
                },

                getTotalAmount: function(coverages){
                    if (!coverages) {
                        return;
                    }
                    $scope.totalPremium = fn.calculateTotalAmout(coverages);
                    return $scope.totalPremium;
                },

                setSelectedPayment: function(payment){
                    $scope.selectedPayment = payment;
                },

                convertDateThreeToOne:function(d,m,y){
                    if(!d) return null; if(!m) return null; if(!y) return null;
                    if(typeof d !== "undefined" &&
                        typeof m !== "undefined" &&
                        typeof y !== "undefined"){
                        var day = ''+d;
                        day = (day.length == 1) ? "0" + day: day;
                        var month = ''+m;
                        month = (month.length == 1) ? "0" + month: month;
                        var strDate=y + "-" + month + "-" + day ;
                        return strDate;
                    }
                    return null;
                },

                convertDateOneToThree:function(strDate){
                    if (strDate){
                        var convertedDate = [];
                        convertedDate.push(parseInt(MITDate.getDD(strDate))); //dd
                        convertedDate.push(parseInt(MITDate.getMM(strDate))); //mm
                        convertedDate.push(parseInt(MITDate.getYYYY(strDate))); //yyyy
                        return convertedDate;
                    }
                    else return null;
                },

                setDateValidity:function(d,m,y,f){

                    if(m==2) { /*feb*/
                        if((y %4)==0) {
                            /*leap year -29 is allowed*/
                            if(d>29) {
                                f.$setValidity("valid",false);
                                return;
                            } else {
                                f.$setValidity("valid",true);
                            }
                        }
                        else {
                            /*28 days- 29/30/31 not allowed*/
                            if(d>28) {
                                f.$setValidity("valid",false);
                                return;
                            } else {
                                f.$setValidity("valid",true);
                            }
                        }
                    } else {

                        if((m==4) || (m==6) || (m==9) || (m==11)) {
                            if(d>30) {
                                f.$setValidity("valid",false);
                                return;
                            } else {
                                f.$setValidity("valid",true);
                            }
                        }
                        else {
                            f.$setValidity("valid",true);
                        }
                    }

                },

            },

            sessionExpiredHandler: function () {
                // $scope.$parent.baseMitScopeBindingUtils.clearPAndCModelFromStorage();
                /*if($sessionStorage)
                {
                    if($sessionStorage.fromTerminal)
                    {
                        if($sessionStorage.fromTerminal==1)
                        {
                            DaLoadingMask.toggle(false);
                            $scope.$parent.baseMitScopeBindingUtils.clearPAndCModelFromStorage();
                            //$window.location.href = "http://www.directasia.com/car-insurance/";
                            $state.go('public.partnerterminal');
                            return;
                        }
                    }
                }*/
                DaLoadingMask.toggle(false);
                DaModal.open('#sessionExpired',{onClose: fn.goToStart});
            },

        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();

        $scope.disablePremium = fn.disablePremium;
        $scope.hideEstimatedMsg = fn.hideEstimatedMsg;
        $scope.disableBreadcrumb = fn.disableBreadcrumb;
        $scope.setSelectedPayment = fn.utils.setSelectedPayment;
        $scope.setIsAccepted = fn.setIsAccepted;
        $scope.getIsAccepted = fn.getIsAccepted;
        $scope.getTotalAmount = fn.utils.getTotalAmount;
        $scope.isInvalidDriverAge = fn.validations.isInvalidDriverAge;
        $scope.convertDateThreeToOne=fn.utils.convertDateThreeToOne;
        $scope.convertDateOneToThree=fn.utils.convertDateOneToThree;
        $scope.setDateValidity=fn.utils.setDateValidity;

        $scope.getMonthlyPriceStepCtrl=fn.utils.getMonthlyPriceStepCtrl;
        $scope.filterStatic=fn.filterStatic;
        $scope.filterDesc=fn.filterDesc;
        $scope.checkBLFC=fn.checkBLFC;
        $scope.checkUWFC=fn.checkUWFC;
        $scope.isUserProceedOnBuy=fn.isUserProceedOnBuy;
        $scope.divertAfterSECB=fn.utils.divertAfterSECB;
        $scope.sessionExpiredHandler=fn.sessionExpiredHandler;
        $scope.getActiveTabSPA = fn.getActiveTabSPA;
        $scope.$on('IdleTimeout', function() {
            fn.sessionExpiredHandler();
        });

        $scope.$on('Keepalive', function() {
            //console.log("Keep alive from step controller");
            var localSession = {
                MITSessionToken: $scope.sessionApi.pAndCModelStorage.session.MITSessionToken
            };
            var t=Session.validate(localSession);
            t.$promise.then(
                function(data){},
                function(data){fn.sessionExpiredHandler();}
            );
        });

        Idle.watch();
        $scope.$parent.onBannerLoad();

        if (!fn.validations.validateCurrentStep()){
            return;
        }

        if (!fn.validations.validateToken()){
            return;
        }

    }];