/*
 * Created by Gerald Garcia on 04/03/15.
 */
module.exports = [
    '$location',
    '$scope',
    '$state',
    '$anchorScroll',
    '$stateParams',
    'Confirmation',
    'Policy',
    'MitLogUtils',
    'ConfigUtils',
    'WfeApiLog',
    'WfeApiConfirmation',
    /**
     * Controller for the Issue Policy
     * @constructor
     */
        function IssuePolicyController($location, $scope, $state, $anchorScroll, $stateParams,
                                       Confirmation, Policy, MitLogUtils, ConfigUtils,
                                       WfeApiLog, WfeApiConfirmation) {
        $anchorScroll();

        var fn = {
            initApiObjects: function () {
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, null, $scope.bidata, null);
            },

            issuePolicy: function () {
                var creditCard = {
                    MITSessionToken: $stateParams.token
                };

                try {
                    if ($scope.$parent.product.isBILogEnabled) {
                        $scope.bidata = {
                            Status: 'Payment Submitted'
                        };
                        fn.logApi.updateLog();
                    }
                } catch (err) {
                }

                fn.eventHandler.logEvent('{{mit}}/TransactionServices/Confirmation/IssuePolicy', 'Before calling Issue Policy', creditCard);

                var issue = function () {
                    fn.confirmationApi.issuePolicy({
                        onSuccess: function () {
                            $state.go('public.' + $stateParams.productState + '.quote.steps.done');
                        },
                        onError: function () {
                        }
                    });
                };
                issue();
            },

            initScopeObjects: function () {
                $scope.hasError = false;
                $scope.bidata = {};
            },

            retrieveScopeObjects: function () {
            },

            eventHandler: {
                logEvent: function (processName, msg, data) {
                    var dataString;
                    try {
                        if (data) {
                            dataString = angular.toJson(data);
                            msg = msg + ' data= ' + dataString;
                        }
                    }
                    catch (err) {
                    }

                    try {
                        var newObj = {
                            uniqueID: $stateParams.token,
                            serviceName: processName,
                            msg: msg
                        };

                        MitLogUtils.eventLogWrite(newObj);
                    }
                    catch (err) {
                    }
                },

                errorOnRequest: function (processName, args) {
                    try {
                        var newObj = {
                            sourceUrl: $location.url(),
                            exceptionMsg: processName,
                            stacktrace: angular.toJson(args)
                        };

                        MitLogUtils.exceptionLogWrite(newObj);
                    }
                    catch (err) {
                    }

                    $scope.hasError = true;
                }
            }
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.retrieveScopeObjects();
        fn.issuePolicy();
        fn.eventHandler.logEvent($location.url(), 'Issue Policy Page Loaded');
    }];