module.exports = [
    'AppConstants',
    '$filter',
    /**
     * UtilityProvider
     * @returns {Function}
     * @constructor
     */
    function UtilityProvider(AppConstants, $filter) {
        var PRODUCT_ID_DA = AppConstants.AGGConfig.PRODUCT_ID_DA;
        var PRODUCT_ID_MTI = AppConstants.AGGConfig.PRODUCT_ID_MTI;
        var PRODUCT_ID_AXA = AppConstants.AGGConfig.PRODUCT_ID_AXA;

        var PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA;
        var PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI;
        var PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA;

        var PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA;
        var PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI;
        var PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA;

        var fn = {
            convertThaiYear: function(dateString) {
                return parseInt(dateString.substr(0,4)) + 543;
            },
            dateThaiFull: function(dateString) {
                console.log(`dateThaiFull dateString : ${dateString}`);
                const date = new Date(dateString)

                const result = date.toLocaleDateString('th-TH', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                })
                return result;
            },
            getCoverComplusoryBasicCoverByProductId: function (sc) {
                var ccb = {};
                if(sc.ProductId == PRODUCT_ID_DA) {
                    ccb = $filter('filter')(sc.Covers, { ProductLineGroupId: PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA }, true);
                } else if (sc.ProductId == PRODUCT_ID_MTI) {
                    ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI }, true);
                } else if(sc.ProductId == PRODUCT_ID_AXA) {
                    ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA }, true);
                }
                return ccb;
            },
            getCoverVoluntaryBasicByProductId: function (sc) {
                var ccb = {};
                if(sc.ProductId == PRODUCT_ID_DA) {
                    ccb = $filter('filter')(sc.Covers, { ProductLineGroupId: PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA }, true);
                } else if (sc.ProductId == PRODUCT_ID_MTI) {
                    ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI }, true);
                } else if(sc.ProductId == PRODUCT_ID_AXA) {
                    ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA }, true);
                }
                return ccb;
            },

        }

        this.convertThaiYear = fn.convertThaiYear;
        this.dateThaiFull = fn.dateThaiFull;
        this.getCoverComplusoryBasicCoverByProductId = fn.getCoverComplusoryBasicCoverByProductId;
        this.getCoverVoluntaryBasicByProductId = fn.getCoverVoluntaryBasicByProductId;

}];