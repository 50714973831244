/*
 * Created by Gerald Garcia on 29/06/15.
 */

/***
 * The common HKID validation directive.
 * @type {*[]}
 */
module.exports = [
    'HKFormValidation',

    function daHKID(HKFormValidation) {

        return {
            require: 'ngModel',

            link: function(scope, elm, attrs, ctrl) {

                ctrl.$parsers.unshift(function(viewValue) {

                    if (ctrl.$isEmpty(viewValue)) {
                        ctrl.$setValidity('da-hkid', true);
                        return undefined;
                    }

                    var isValid = HKFormValidation.validateHKID(viewValue);

                    ctrl.$setValidity('da-hkid', isValid);

                    if (isValid) {
                        return viewValue;
                    } else {
                        return undefined;
                    }
                });
            }
        };
    }];