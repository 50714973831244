/**
 * Created by Gerald Garcia on 18/03/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$sessionStorage',
    '$localStorage',
    'ConfigUtils',
    'WfeApiAuth',
    'DaModal',
    'HKFormValidation',
    '$window',
    '$timeout',

    function ForgotPasswordController($scope, $state, $sessionStorage, $localStorage, ConfigUtils, WfeApiAuth, DaModal, HKFormValidation, $window,$timeout) {

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/myaccount/forgot-password/'}
                );
                /*if(ga){ga('send', 'pageview', '/myaccount/forgot-password/');}
                },500);*/
            },
        };

        var fn = {

            setIsSaving : function (isSaving) {
                $scope.IsSaving = isSaving;
            },

            initApiObjects: function () {
                fn.authApi = WfeApiAuth.instance(undefined, $scope, $scope.credentials);
            },

            initScopeObjects: function(){
                $scope.credentials = {
                    UserIdentifier: '',
                    Email: ''
                };

                try {
                    marketing.ga();
                } catch (err) {
                    console.log(err);
                }
            },

            validations: {
                hasInvalid: function hasInvalid() {
                    return $scope.frmLogin.$invalid;
                },

                isTHIDValid: function () {
                    if($scope.credentials.UserIdentifier){

                        return HKFormValidation.validateTHID($scope.credentials.UserIdentifier);

                    }
                },
            },

            eventHandler: {

                onSuccessCloseCallback: function () {
                    $state.go('public.login');
                },

                btnSubmitClicked: function () {
                    /*if($scope.frmLogin.$invalid) {
                        $scope.pageSubmitted = true;
                        return;
                    }*/

                    $scope.pageSubmitted = true;

                    if (fn.validations.hasInvalid()) {
                        return;
                    }
                    if($scope.credentials.UserIdentifier)
                        $scope.credentials.UserIdentifier=$scope.credentials.UserIdentifier.replace(/[^0-9\.]+/g, "");

                    fn.setIsSaving(true);

                    fn.authApi.resetPassword(
                        {
                            onSuccess: function () {
                                DaModal.open('#passwordResetSuccess', {
                                    onClose: fn.eventHandler.onSuccessCloseCallback
                                });
                            },
                            onError: function (data) {
                                $scope.loginError = true;
                                fn.util.setIsSaving(false);
                                console.log(data.ErrorInfo.StatusCode);
                            }
                        }
                    );
                    /*Login.resetPassword(undefined, $scope.credentials,
                            function(data) {
                                fn.setIsSaving(false);
                                if ((!data) || (!data.success)) {
                                    DaModal.alert(JSON.stringify(data));
                                    return;
                                }

                                DaModal.open('#passwordResetSuccess', {
                                    onClose: fn.eventHandler.onSuccessCloseCallback
                                });

                            },

                            function () {
                                fn.setIsSaving(false);
                            }
                    );*/

                }
            }
        };

        //// Initialization
        fn.initScopeObjects();
        fn.initApiObjects();

        //Scroll to top
        $window.scrollTo(0, 0);

        ////Event Handlers
        $scope.btnSubmitClicked = fn.eventHandler.btnSubmitClicked;

        $scope.IsSaving = false;

        $scope.validations = {
            isTHIDValid: fn.validations.isTHIDValid
        };

    }];