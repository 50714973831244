/*
 * Created by Gerald Garcia on 04/03/15.
 */
module.exports = [
    '$scope',
    '$state',

    /**
     * The controller that handles the My account page.
     * This is also responsible of marking the active tab.
     * @param $scope
     * @param $state
     * @constructor
     */
    function UserTabController($scope, $state
                                                ) {

        $scope.activeTab = 'cover';

        var fn = {

            tabs: [
                {
                    title: 'body_login_title_my_profile',
                    state: 'private.account.user.profile'
                },
                {
                    title: 'body_login_title_policies',
                    state: 'private.account.user.policies'
                },
                {
                    title: 'body_login_title_quote',
                    state: 'private.account.user.proposals'
                }/*,
                {
                    title: 'u_tab_my_claims',
                    state: 'private.account.user.claims'
                }*/
            ],

            getActiveTab: function(stateItem) {
                if (!stateItem){
                    return [];
                }

                var truncatedCurrentName = $state.current.name;
                if (truncatedCurrentName === stateItem.state) {
                    $scope.currentTab = stateItem;
                    return ['active', 'da-override-login-tab-active'];
                }

                if($state.current.name === "private.account.user.viewpolicy" && stateItem.state === "private.account.user.policies"){
                    return ['active', 'da-override-login-tab-active'];
                }

                return ['da-override-login-tab-inactive'];
            },

            goToTab: function(stateItem) {
                $state.go(stateItem.state);
            }
        };

        $scope.getActiveTab = fn.getActiveTab;
        $scope.goToTab = fn.goToTab;
        $scope.currentTab = {};
        $scope.tabs = fn.tabs;

    }];