/*
* Created on 10/06/15.
*/

module.exports = [

    /**
     * Country Level Service for HK
     * @returns {Function}
     * @constructor
     */
    function HKStepsFactory() {

        var fn = {

            initIsAccepted: function() {
                var motorQuote = this.pAndCModelStorage;
                if (!motorQuote)
                {
                    motorQuote = {
                        isAccepted: false
                    };
                }

                if (!motorQuote.isAccepted) {
                    motorQuote.isAccepted = false;
                }
                this.setIsAccepted(motorQuote.isAccepted);
                return motorQuote.isAccepted;
            },

            clear: function () {
                var motorQuote = this.pAndCModelStorage;
                angular.forEach(motorQuote, function (item, key) {
                    delete motorQuote[key];
                });
            },

            setIsAccepted: function (isAcceptedParam) {
                var motorQuote = this.pAndCModelStorage;
                motorQuote.isAccepted = isAcceptedParam;
            },

            getIsAccepted: function () {
                var motorQuote = this.pAndCModelStorage;
                return true && motorQuote.isAccepted;
            },

            setProdOption: function (prodOpt) {
                var motorQuote = this.pAndCModelStorage;
                motorQuote.prodOption = prodOpt;
            },

            getProdOption: function () {
                var motorQuote = this.pAndCModelStorage;
                return motorQuote.prodOption;
            },
            //End of properties


            instance: function (scope, sessionObj) {
                var api = new WfeApi(scope, sessionObj);
                fn.initIsAccepted.apply(api);
                return api;
            }

        };

        var WfeApi = function (scope, sessionObj) {
            this.pAndCModelStorage = sessionObj.pAndCModelStorage;
            this.scope = scope;

            this.setIsAccepted = fn.setIsAccepted;
            this.getIsAccepted = fn.getIsAccepted;
            this.setProdOption = fn.setProdOption;
            this.getProdOption = fn.getProdOption;

            this.clear = fn.clear;
        };

        WfeApi.instance = fn.instance;

        return WfeApi;
    } ];
