/*
 * Created by Chanon Tangputthajit.
 */
module.exports = [
    '$anchorScroll',
    '$scope',
    '$rootScope',
    '$state',
    '$timeout',
    'MITDate',
    'HKLocale',
    'WfeApiStaticData',
    'WfeApiPayment',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'DaModal',
    'HKFormValidation',
    'CountryMotorQuoteValidation',
    'WfeApiUser',
    'WfeApiContact',
    'WfeApiAddress',
    '$filter',
    'WfeApiLog',
    'WfeApiEssential',
    '$window',
    '$cookies',
    '$sessionStorage',
    'WfeApiCoverages',
    'StaticDataKeyValuePair',
    'ConfigUtils',
    'WfeApiConfirmation',
    '$localStorage',
    '$http',
    'ExternalService',
    'ApiService',
    'ApiServiceV2',
    'AppConstants',
    'Utils',
    'dataLayerService',
    /**
     * Controller for the Basic Details of Motor Quote
     * @constructor
     */
    function B2CAppController($anchorScroll, $scope, $rootScope, $state, $timeout, MITDate, HKLocale, WfeApiStaticData, WfeApiPayment, WfeApiCurrentProposal,
        WfeApiAsset, WfeApiPolicyContact, WfeApiQuote, DaModal, HKFormValidation, CountryMotorQuoteValidation,
        WfeApiUser, WfeApiContact, WfeApiAddress, $filter, WfeApiLog, WfeApiEssential, $window, $cookies, $sessionStorage, WfeApiCoverages, StaticDataKeyValuePair, config, WfeApiConfirmation, $localStorage, $http, ExternalService, ApiService, ApiServiceV2, AppConstants, Utils,
        dataLayerService
    ) {

        $anchorScroll();

        var marketing = {
            dLCE: function (ref, v) {

                var dataLayer = $window.dataLayer || []

                switch (ref) {

                    case 1:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Year of manufacture',
                            'value': v,        
                            'manufacture-year': v
                        })

                    break

                    case 2:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Make',
                            'value': v,        
                            'car-make': v
                        })

                    break

                    case 3:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Model',
                            'value': v,        
                            'car-model': v
                        })

                    break

                    case 4:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Description & Sum Insured',
                            'value': v,        
                            'car-description': v
                        })

                    break

                    case 5:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver DOB',
                            'value': v,        
                            'maindriver-date-of-birth': v
                        })

                    break

                    case 6:

                        var s = ''

                        if (v == 1 || v == '1') { s = 'Female' }
                        if (v == 2 || v == '2') { s = 'Male' }

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver Gender',
                            'value': s,        
                            'maindriver-gender': s
                        })

                    break

                    case 7:

                        var s = ''

                        if (v == 1 || v == '1' || v == "1") { s = 'Single' }
                        if (v == 2 || v == '2' || v == "2") { s = 'Married' }

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver Martial Status',
                            'value': s,        
                            'maindriver-marital': s
                        })

                    break

                    case 8:

                        var s = ''

                        if(v == 6){ s = 'personal-use' }
                        else if(v == 1000000){ s = 'drive-to-work' }
                        else if(v == 1000002){ s = 'drive-for-work' }
                        else{ s = 'commercial-use' }

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Car Usage',
                            'value': s,
                            'car-usage': s
                        })

                    break
                                       
                    case 13:

                        var s = ''

                        if (v == 1000003 || v == '1000003') { s = '0 %' }
                        if (v == 1000005 || v == '1000005') { s = '20%' }
                        if (v == 1000006 || v == '1000006') { s = '30%' }
                        if (v == 1000007 || v == '1000007') { s = '40%' }
                        if (v == 1000008 || v == '1000008') { s = '50%' }
                        if (v == 2500000 || v == '2500000') { s = 'I don\'t know' }
                        if (v == 1000009 || v == '1000009') { s = 'I don\'t know' }

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'ncd',
                            'value': s,
                            'no-claims no claims bonus': s
                        })

                    break

                    case 14:

                        var s = ''

                        if (v == 1 || v == '1') { s = '0' }
                        if (v == 2 || v == '2') { s = '1' }
                        if (v == 3 || v == '3') { s = '2' }
                        if (v == 4 || v == '4') { s = '3' }
                        if (v == 5 || v == '5') { s = '4' }
                        if (v == 1000000 || v == '1000000') { s = '5' }
                        if (v == 1000001 || v == '1000001') { s = 'more than 5' }

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver Years of Driving Exp',
                            'value': v,        
                            'driving years of driving exp': v
                            
                        })

                    break

                    case 20:

                    dataLayer.push({
                        'event':'dataLayer-initialised',
                        'category': 'B2CPortal',
                        'action': 'Input Details',
                        'label': 'Days Until Policy Start Date',
                        'value': v,
                        'days-to-policy-start-date':v
                    });

                    break

                    case 45:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Policy Number',
                            'value': v,
                            'policy-number': v
                        })

                    break

                    case 46:

                        if (v === true) { s = 'Yes' } 
                        else { s = 'No' }

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Is Your Car Equipped With Camera',
                            'value': s,
                            'equipped-with-camera': s
                        })

                    break

                    case 47:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Make Model Not Covered',
                            'value': v,
                            'MakeModelNotCovered': v
                        })

                    break

                    case 48:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Underwriting Error',
                            'value': v,
                            'UnderwritingError': v
                        })

                    break

                    case 49: 

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Type of coverage',
                            'value': v,
                            'type-of-coverage': v
                        })

                    break
                    case 50:
                        if (v === true) { s = 'Yes' } 
                        else { s = 'No' }
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Consent your quote',
                            'value': s,
                            'consent-your-quote':s
                        });
                        break;
                    case 51:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver month of birth',
                            'value': v,        
                            'maindriver-date-of-birth-month':v
                        });
                        break;
                    case 52:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Policy Start Date',
                            'value': v,
                            'policy-start-date':v
                        });

                        break;
                    case 53:

                        var s = '';
                        if (v == 1000003 || v == '1000003') { s = '0' }
                        if (v == 1000004 || v == '1000004') { s = '1 at fault' }
                        if (v == 1000008 || v == '1000008') { s = '1 not at fault' }
                        if (v == 1000005 || v == '1000005') { s = '2 at fault' }
                        if (v == 1000010 || v == '1000010') { s = '2 not at fault' }
                        if (v == 1000011 || v == '1000011') { s = '2 (1 at fault and 1 not at fault)' }
                        if (v == 1000006 || v == '1000006') { s = '3 not at fault' }
                        if (v == 1000012 || v == '1000012') { s = '3 (1 at fault, 2 not at fault)' }
                        if (v == 1000013 || v == '1000013') { s = '3 (2 at fault, 1 not at fault)' }
                        if (v == 1000014 || v == '1000014') { s = '3 at fault' }
                        if (v == 1000007 || v == '1000007') { s = 'more than 3' }                        

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver Claims In Last 6 Months',
                            'value': v,        
                            'main driver claims': v
                        });
                        break;
                    case 54:
                        var s = '';
                        if (v == 2000025 || v == '2000025') { s = 'Senior Executives' }
                        if (v == 2000026 || v == '2000026') { s = 'Middle Management' }
                        if (v == 2000027 || v == '2000027') { s = 'Civil Servant / State Enterprise Staff' }
                        if (v == 2000028 || v == '2000028') { s = 'Police / Military' }
                        if (v == 2000029 || v == '2000029') { s = 'Politician' }
                        if (v == 2000030 || v == '2000030') { s = 'Doctors / Physician' }
                        if (v == 2000031 || v == '2000031') { s = 'Nurse / Other Medical and Health-Related Professions' }
                        if (v == 2000032 || v == '2000032') { s = 'Engineer / Technician' }
                        if (v == 2000033 || v == '2000033') { s = 'Architect / Designer / Artists' }
                        if (v == 2000034 || v == '2000034') { s = 'Actors / Singers / Entertainment' }
                        if (v == 2000035 || v == '2000035') { s = 'Outdoor Sales / Marketing Person' }
                        if (v == 2000036 || v == '2000036') { s = 'Business Owner' }
                        if (v == 2000037 || v == '2000037') { s = 'Self-Employed Trades-vehicle' }
                        if (v == 2000038 || v == '2000038') { s = 'Freelance / Contractors / Consultant' }
                        if (v == 2000039 || v == '2000039') { s = 'Chauffeur / Driver' }
                        if (v == 2000040 || v == '2000040') { s = 'Farmers' }
                        if (v == 2000041 || v == '2000041') { s = 'Housewife' }
                        if (v == 2000042 || v == '2000042') { s = 'Outdoor Journalist / Media Photographer' }
                        if (v == 2000043 || v == '2000043') { s = 'Teacher and All Educators' }
                        if (v == 2000044 || v == '2000044') { s = 'Accountant / Cashier' }
                        if (v == 2000045 || v == '2000045') { s = 'Office worker / Admin. / Secretary / Service personnel' }
                        if (v == 2000046 || v == '2000046') { s = 'Retired or Unemployed' }
                        if (v == 2000047 || v == '2000047') { s = 'Other' }

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver Occupation',
                            'value': v,        
                            'maindriver-occupation': v
                        });
                    break;
                    // case 55:
                    //     dataLayer.push({
                    //         'event':'dataLayer-initialised',
                    //         'deductible-amount':v
                    //     });
                    //     break;
                    case 56:

                            if (v === true || v == undefined) { s = 'Yes' } 
                            else { s = 'No' }

                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Add compulsory insurance',
                                'value': s,
                                'add-compulsory-insurance':s
                            });
                            break;
                    case 57:

                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Deductible amount',
                                'value': v,
                                'deductible-amount':v
                            });
                            break;
                    case 58:

                        var s = '';
                        if (v == 1000003 || v == '1000003') { s = '0 %' };
                        if (v == 1000005 || v == '1000005') { s = '20%' };
                        if (v == 1000006 || v == '1000006') { s = '30%' };
                        if (v == 1000007 || v == '1000007') { s = '40%' };
                        if (v == 1000008 || v == '1000008') { s = '50%' };
                        if (v == 2500000 || v == '2500000') { s = 'I don\'t know' };
                        if (v == 1000009 || v == '1000009') { s = 'I don\'t know' };

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'ncd',
                            'value': s,
                            'no-claims no claims bonus': s
                        });
                        break;
                    case 59:
                        if (v === true || v == undefined) { s = 'Yes' } 
                        else { s = 'No' }
                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Add compulsory insurance',
                                'value': s,
                                'add-compulsory-insurance':s
                            });
                            break;

                    case 60:

                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Who do you want to cover',
                                'value': v,
                                'who-do-you-want-to-cover':v
                            });
                            break;

                    case 61:
                        if (v === true) { s = 'Yes' } 
                        else { s = 'No' }
                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Do you want to add named driver',
                                'value': v,
                                'do-you-want-to-add-named-driver':s
                            });
                            break;                            
                    case 62:

                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Is the main driver also the policyholder',
                                'value': v,
                                'is the main driver also the policyholder':v
                            });
                            break;  
                    case 63:

                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Is this a red carplate',
                                'value': v,
                                'is-this-a-red-car-plate':v
                            });
                            break;   
                    case 64:

                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'car body type',
                                'value': v,
                                'car-body-type':v
                            });
                            break;
                    case 65:
                            dataLayer.push({
                                'event':'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Main Driver DOB',
                                'value': v,
                                'maindriver-date-of-birth':v
                            });
                            break;
                            
                    case 66:

                            var s = ''
    
                            if (v == 1 || v == '1' || v == "1") { s = 'Single' }
                            if (v == 2 || v == '2' || v == "2") { s = 'Married' }
    
                            dataLayer.push({
                                'event': 'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Policy holder marital',
                                'value': s,
                                'policy-holder-marital': s
                            })
    
                            break;

                    case 67:

                            var s = ''

                            if (v == 1 || v == '1') { s = 'Female' }
                            if (v == 2 || v == '2') { s = 'Male' }

                            dataLayer.push({
                                'event': 'dataLayer-initialised',
                                'category': 'B2CPortal',
                                'action': 'Input Details',
                                'label': 'Policy holder gender',
                                'value': s,
                                'policy-holder-gender': s
                            })

                            break;

                    case 68:
                        var s = '';
                        if (v == 2000025 || v == '2000025') { s = 'Senior Executives' }
                        if (v == 2000026 || v == '2000026') { s = 'Middle Management' }
                        if (v == 2000027 || v == '2000027') { s = 'Civil Servant / State Enterprise Staff' }
                        if (v == 2000028 || v == '2000028') { s = 'Police / Military' }
                        if (v == 2000029 || v == '2000029') { s = 'Politician' }
                        if (v == 2000030 || v == '2000030') { s = 'Doctors / Physician' }
                        if (v == 2000031 || v == '2000031') { s = 'Nurse / Other Medical and Health-Related Professions' }
                        if (v == 2000032 || v == '2000032') { s = 'Engineer / Technician' }
                        if (v == 2000033 || v == '2000033') { s = 'Architect / Designer / Artists' }
                        if (v == 2000034 || v == '2000034') { s = 'Actors / Singers / Entertainment' }
                        if (v == 2000035 || v == '2000035') { s = 'Outdoor Sales / Marketing Person' }
                        if (v == 2000036 || v == '2000036') { s = 'Business Owner' }
                        if (v == 2000037 || v == '2000037') { s = 'Self-Employed Trades-vehicle' }
                        if (v == 2000038 || v == '2000038') { s = 'Freelance / Contractors / Consultant' }
                        if (v == 2000039 || v == '2000039') { s = 'Chauffeur / Driver' }
                        if (v == 2000040 || v == '2000040') { s = 'Farmers' }
                        if (v == 2000041 || v == '2000041') { s = 'Housewife' }
                        if (v == 2000042 || v == '2000042') { s = 'Outdoor Journalist / Media Photographer' }
                        if (v == 2000043 || v == '2000043') { s = 'Teacher and All Educators' }
                        if (v == 2000044 || v == '2000044') { s = 'Accountant / Cashier' }
                        if (v == 2000045 || v == '2000045') { s = 'Office worker / Admin. / Secretary / Service personnel' }
                        if (v == 2000046 || v == '2000046') { s = 'Retired or Unemployed' }
                        if (v == 2000047 || v == '2000047') { s = 'Other' }

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Policy holder occupation',
                            'value': s,
                            'policy-holder-occupation':s
                        });
                    break;

                    case 69:

                        var s = ''                                
                        if (v == 2000000 || v == '2000000' || v == "2000000") { s = 'THAI' }
                        if (v == 2000001 || v == '2000001' || v == "2000001") { s = 'NON-THAI RESIDENT' }

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Policy holder residential status',
                            'value': s,
                            'policy-holder-residential-status': s
                        })

                        break;

                    case 70:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Navigation',
                            'label': 'Back',
                            'value': v,
                            'your-quote-back': true,
                            'your-quote-next-choose-package': false
                        })
                    break

                    case 71:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Navigation',
                            'label': 'Next',
                            'value': v,
                            'your-quote-back': false,
                            'your-quote-next-choose-package': true
                        })
                    break

                    // dataLayer: Customer email
                    case 72:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Customer Email',
                            'value': v,
                            'CustomerEmail': v
                        })
                    break

                    // dataLayer: Customer phone number
                    case 73:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Customer Tel',
                            'value': v,
                            'CustomerTel': v
                        })
                    break

                    // dataLayer: MITSession
                    case 74:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Mitsesison token',
                            'value': v,
                            'mitsesison-token': v
                        })
                    break

                    case 78:

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Door type',
                            'value': v,
                            'car-door-type': v
                        });
                    break;

                    case 79:

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Car Gear Type',
                            'value': v,
                            'car-gear-type': v
                        });
                    break;

                    case 80:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Car Plate Province',
                            'value': v,
                            'car-plate-province': v
                        })

                    break

                    case 82:

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Main Driver Province',
                            'value': v,
                            'maindriver-province': v
                        })

                    break
                    
                    case 91:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'financial option',
                            'value': v,        
                            'financial-option': v
                        })

                    break;  
                    
                    case 92:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'category': 'B2CPortal',
                            'action': 'Input Details',
                            'label': 'Customer Name',
                            'value': v,
                            'CustomerName': v
                        })
                    break

                    default: break;
                }
            },

            pushDataLayerMessage: function (key, message) {
                dataLayerService.addTrigger(key, 'push', message, {});
                dataLayerService.removeTrigger(key)
            },

            dataLayerMessages: {
                viewStartPage: {
                    event: 'content-view',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Virtual Page URL',
                    value: '/b2c/quote/getquote/start',
                    virtualPageURL: '/b2c/quote/getquote/start'
                },
                viewYourQuotePage: {
                    event: 'content-view',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Virtual Page URL',
                    value: '/b2c/quote/getquote/your-quote',
                    virtualPageURL: '/b2c/quote/getquote/your-quote'
                },
                viewPolicySubmissionPage: {
                    event: 'content-view',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Virtual Page URL',
                    value: '/b2c/quote/getquote/policy-submission',
                    virtualPageURL: '/b2c/quote/getquote/policy-submission'
                },
                selectMake: () => ({
                    event: 'Make',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Make',
                    value: $scope.txtMaker,
                    'car-make': $scope.txtMaker
                }),
                selectModel: () => ({
                    event: 'Model',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Model',
                    value: $scope.txtModel,
                    'car-model': $scope.txtModel
                }),
                selectManufactureYear: () => ({
                    event: 'Year of manufacture',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Year of manufacture',
                    value: $scope.txtYear,
                    'manufacture-year': $scope.txtYear
                }),
                selectCarDescription: () => ({
                    event: 'Description',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Description',
                    value: $scope.txtDesc,
                    'car-description': $scope.txtDesc
                }),
                setDefaultDoorType: () => ({
                    event: 'Car Door Type',
                    category: 'B2CPortal',
                    action: 'Default Display',
                    label: 'Door type',
                    value: $scope.bodyType,
                    'car-door-type': $scope.bodyType
                }),
                selectDoorType: () => ({
                    event: 'Car Door Type',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Door type',
                    value: $scope.bodyType,
                    'car-door-type': $scope.bodyType
                }),
                setDefaultGearType: () => ({
                    event: 'Car Gear Type',
                    category: 'B2CPortal',
                    action: 'Default Display',
                    label: 'Car Gear Type',
                    value: $scope.txtGearType,
                    'car-gear-type': $scope.txtGearType
                }),
                selectGearType: () => ({
                    event: 'Car Gear Type',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Car Gear Type',
                    value: $scope.txtGearType,
                    'car-gear-type': $scope.txtGearType
                }),
                selectCarPlateProvince: () => ({
                    event: 'Car Plate Province',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Car Plate Province',
                    value: $scope.txtProvince,
                    'car-plate-province': $scope.txtProvince
                }),
                selectCameraEquipped: () => ({
                    event: 'Is Your Car Equipped With Camera',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Is Your Car Equipped With Camera',
                    value: $scope.txtCameraEquipped,
                    'equipped-with-camera': $scope.txtCameraEquipped
                }),
                setDefaultCarUsage: () => ({
                    event: 'Car Usage',
                    category: 'B2CPortal',
                    action: 'Default Display',
                    label: 'Car Usage',
                    value: $scope.txtCarUsage,
                    'car-usage': $scope.txtCarUsage
                }),
                selectCarUsage: () => ({
                    event: 'Car Usage',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Car Usage',
                    value: $scope.txtCarUsage,
                    'car-usage': $scope.txtCarUsage
                }),
                setDefaultPolicyStartDate: () => ({
                    event: 'Policy Start Date',
                    category: 'B2CPortal',
                    action: 'Default Display',
                    label: 'Policy Start Date',
                    value: $scope.txtPolicyStartDate,
                    'policy-start-date': $scope.txtPolicyStartDate
                }),
                selectPolicyStartDate: () => ({
                    event: 'Policy Start Date',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Policy Start Date',
                    value: $scope.txtPolicyStartDate,
                    'policy-start-date': $scope.txtPolicyStartDate
                }),
                selectPaymentType: () => ({
                    event: 'Payment Type',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Payment Type',
                    value: $scope.txtPaymentTerms,
                    'payment-type': $scope.txtPaymentTerms
                }),
                selectMainDriverProvince: () => ({
                    event: 'Main Driver Province',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Main Driver Province',
                    value: $scope.txtMDprovince,
                    'maindriver-province': $scope.txtMDprovince
                }),
                selectMainDriverDob: () => ({
                    event: 'Main Driver DOB',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Main Driver DOB',
                    value: $scope.txtMdDob.slice(0, 4),
                    'maindriver-date-of-birth': $scope.txtMdDob.slice(0, 4)
                }),
                selectMainDriverGender: () => ({
                    event: 'Main Driver Gender',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Main Driver Gender',
                    value: $scope.txtMDGender,
                    'maindriver-gender': $scope.txtMDGender
                }),
                selectMainDriverMaritalStatus: () => ({
                    event: 'Main Driver Marital Status',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Main Driver Marital Status',
                    value: $scope.txtMDMaritalStatus,
                    'maindriver-marital': $scope.txtMDMaritalStatus
                }),
                selectMainDriverOccupation: () => ({
                    event: 'Main Driver Occupation',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Main Driver Occupation',
                    value: $scope.txtOccupationId,
                    'maindriver-occupation': $scope.txtOccupationId
                }),
                selectMainDriverExperience: () => ({
                    event: 'Main Driver Years of Driving Exp',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Main Driver Years of Driving Exp',
                    value: $scope.txtDrivingExperiences,
                    'maindriver-years-driving-exp': $scope.txtDrivingExperiences
                }),
                selectMainDriverClaims: () => ({
                    event: 'Main Driver Claims In Last 6 Months',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Main Driver Claims In Last 6 Months',
                    value: $scope.txtNrOfClaimsInPeriodId,
                    'maindriver-claims': $scope.txtNrOfClaimsInPeriodId
                }),
                clickContactName: {
                    event: 'Customer Contact Name Click',
                    category: 'B2CPortal',
                    action: 'Click Contact Name',
                    label: '',
                    value: true,
                    'ph-name-click': true
                },
                typeContactName: {
                    event: 'Customer Contact Name Type',
                    category: 'B2CPortal',
                    action: 'Type Contact Name',
                    label: '',
                    value: true,
                    'ph-name-type': true
                },
                clickContactPhone: {
                    event: 'Customer Contact Phone Click',
                    category: 'B2CPortal',
                    action: 'Click Contact Phone',
                    label: '',
                    value: true,
                    'ph-phone-click': true
                },
                typeContactPhone: {
                    event: 'Customer Contact Phone Type',
                    category: 'B2CPortal',
                    action: 'Type Contact Phone',
                    label: '',
                    value: true,
                    'ph-phone-type': true
                },
                clickContactEmail: {
                    event: 'Customer Contact Email Click',
                    category: 'B2CPortal',
                    action: 'Click Contact Email',
                    label: '',
                    value: true,
                    'ph-email-click': true
                },
                typeContactEmail: {
                    event: 'Customer Contact Email Type',
                    category: 'B2CPortal',
                    action: 'Type Contact Email',
                    label: '',
                    value: true,
                    'ph-email-type': true
                },
                clickConsent: () => ({
                    event: 'Consent your quote',
                    category: 'B2CPortal',
                    action: 'Input Details',
                    label: 'Consent your quote',
                    value: $scope.asset.consentPrivacyFlag,
                    'consent-your-quote': $scope.asset.consentPrivacyFlag
                }),
                clickCalculate: {
                    event: 'Calculate',
                    category: 'B2CPortal',
                    action: 'step1-calculate',
                    label: 'Calculate',
                    value: true,
                    calculate: true
                }
            }
        };

        var aggFn = {
            initStaticData: function (callback = function(){}) {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getVehicleColour();
                };

                var getVehicleColour = function () {
                    var hasError = false;
                    ApiServiceV2.getVehicleColour({
                        onSuccess: function (data) {
                            $scope.staticData.vehicleColour = data;
                            getNoClaimDiscountList();
                            getJunkNumbers();
                            getConversionValues();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getVehicleColour', method: 'getVehicleColour'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 649'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getNoClaimDiscountList();
                            getJunkNumbers();
                            getConversionValues();
                        }
                    });
                }

                var getConversionValues = function () { 
                    $http.get('/assets/content/conversion-value.json')
                        .then(function(response) {
                            $scope.staticData.conversionValues = response.data;
                        })
                        .catch(function(err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getConversionValues', method: 'getConversionValues'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1070'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                        });
                }

                var getJunkNumbers = function () { 
                    $http.get('https://static.directasia.co.th/DirectAsiaThailandSite/media/assets/json/junk-phone-number.json')
                        .then(function(response) {
                            $scope.staticData.junkNumbers = response.data;
                        })
                        .catch(function(err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getJunkNumbers', method: 'getJunkNumbers'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1087'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                        });
                }

                var getNoClaimDiscountList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data;
                            getPaymentTermList();
                        },
                        onError: function (err) {

                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getNoClaimDiscountList', method: 'getPaymentTermList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 670'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getPaymentTermList();
                        }
                    }, 'NCD_LEVEL', 'productTypeId=1000000');
                };

                var getPaymentTermList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.paymentTerms = data;

                            if (HKLocale.getLocale() === "th-th") {
                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.SINGLE_PAYMENT)
                                if (paymentTermId) {
                                    var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                    $scope.staticData.paymentTerms[index].value = "ชำระเต็มจำนวน"
                                }

                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.INSTALLMENT_PAYMENT)
                                if (paymentTermId) {
                                    var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                    $scope.staticData.paymentTerms[index].value = "ชำระแบบผ่อน"
                                }
                            }
                            else {
                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.SINGLE_PAYMENT)
                                if (paymentTermId) {
                                    var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                    $scope.staticData.paymentTerms[index].value = "Pay in full"
                                }

                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.INSTALLMENT_PAYMENT)
                                if (paymentTermId) {
                                    var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                    $scope.staticData.paymentTerms[index].value = "Installment"
                                }
                            }

                            getBooleanListWithSometimes();
                        },
                        onError: function (err) {

                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getPaymentTermList', method: 'wfeApiStaticDataModel.getPaymentTermList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 719'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getBooleanListWithSometimes();
                        }
                    }, 'PAYMENT_TERMS', 'paymentModeId=' + $scope.currentProposal.PaymentModeId);
                };

                var getBooleanListWithSometimes = function () {
                    var hasError = false;
                    ApiServiceV2.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getBooleanListWithSometimes', method: 'getBooleanList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 739'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getMakeList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getBooleanList', method: 'getMakeList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 759'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getMakeList();
                        }
                    }, 'YES_NO');
                };

                var getMakeList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            if (data) {
                                var repeatedData = [];
                                repeatedData.push({ key: "1000091", value: "Toyota" });
                                repeatedData.push({ key: "1000015", value: "Honda" });
                                repeatedData.push({ key: "1000031", value: "Nissan" });
                                repeatedData.push({ key: "1000025", value: "Mazda" });
                                repeatedData.push({ key: "1000080", value: "Isuzu" });
                                repeatedData.push({ key: "1000028", value: "Mitsubishi" });
                                repeatedData.push({ key: "0000000", value: "--------------" });

                                var makes = data.sort((n1,n2) => {
                                    if (n1.value > n2.value) {
                                        return 1;
                                    }
                                
                                    if (n1.value < n2.value) {
                                        return -1;
                                    }
                                
                                    return 0;
                                });

                                $scope.staticData.makes = repeatedData.concat(makes);

                                if ($scope.searchParams.has('ManufactureId')) {
                                    var manufactureId = $scope.searchParams.get('ManufactureId');
                                    var found = $scope.staticData.makes.find(make => make.key === manufactureId);
                                    if (found) {
                                        $scope.asset.ManufactureId = manufactureId;
                                        $scope.txtMaker = found.value;
                                        aggFn.eventHandlers.onDropMakeChanged(manufactureId);
                                        marketing.pushDataLayerMessage('pushMake', marketing.dataLayerMessages.selectMake);
                                    }
                                }
                            }
                            else {
                                $scope.staticData.makes = data;
                            }

                            getGenderList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getMakeList', method: 'next to getGenderList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 806'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getGenderList();
                        }
                    }, 'MANUFACTURE');
                };

                var getGenderList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.genders = data;
                            getMaritalStatusList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getGenderList', method: 'next to getMaritalStatusList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 826'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getMaritalStatusList();
                        }
                    }, 'GENDER');
                };

                var getMaritalStatusList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getDatesPartsDD();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getMaritalStatusList', method: 'next to getDatesPartsDD'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 846'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDatesPartsDD();
                        }
                    }, 'FAMILY_STATUS');
                };

                // NotUse
                var getDatesPartsDD = function () {
                    var hasError = false;
                    wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsDD', method: 'next to getDatesPartsMM'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 867'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDatesPartsMM();
                        }
                    });
                };

                // NotUse
                var getDatesPartsMM = function () {
                    var hasError = false;
                    wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;

                            //Set display policy start date of month
                            var d = $scope.$parent.convertDateOneToThree($scope.currentProposal.PolicyStartDate);
                            let getTxtPolicyStartDateMON = $scope.staticData.datesMonths.find(w => w.key == d[1]);
                            if(getTxtPolicyStartDateMON && getTxtPolicyStartDateMON.value){
                                $scope.txtPolicyStartDateMON = getTxtPolicyStartDateMON.value;
                            }
                            //End

                            getDatesPartsYY();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsMM', method: 'next to getDatesPartsYY'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 888'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDatesPartsYY();
                        }
                    });
                };

                // NotUse
                var getDatesPartsYY = function () {
                    var hasError = false;
                    wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            getDatesPartsFullYY();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsYY', method: 'next to getDatesPartsFullYY'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 909'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDatesPartsFullYY();
                        }
                    });
                };

                // NotUse
                var getDatesPartsFullYY = function () {
                    var hasError = false;
                    wfeApiStaticData.getDatesPartsFullYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYearsPolicy = [data[0], { "key": data[0]["key"] + 1, "value": data[0]["value"] + 1, "valueTH": data[0]["valueTH"] + 1 }];
                            fn.utils.setIsSaving(false);
                            getDriverExperienceList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsFullYY', method: 'next to getDriverExperienceList'};
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 931'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDriverExperienceList();
                        }
                    });
                };

                var getDriverExperienceList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                            getOccupationList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDriverExperienceList', method: 'getOccupationList'};
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 951'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getOccupationList();
                        }
                    }, 'DRIVER_EXPERIENCE', 'productTypeId=1000000');
                };

                var getOccupationList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            let occupations = data.sort((a,b) => a.value.localeCompare(b.value, 'th'));
                            $scope.staticData.occupations = occupations;
                            getNoOfClaimList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getOccupationList', method: 'next to getNoOfClaimList'};
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 972'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getNoOfClaimList();
                        }
                    }, 'OCCUPATION');
                };

                var getNoOfClaimList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.nrOfClaimsInPeriods = data;
                            getProvinceList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getNoOfClaimList', method: 'next to getProvinceList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 992'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getProvinceList();
                        }
                    }, 'NO_OF_CLAIMS', 'productTypeId=1000000');
                };

                var getProvinceList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            var repeatedData = [];
                            if($scope.HKLocale.getLocale() == "en-us") {
                                repeatedData.push({ key: "2000003", value: "Bangkok" });
                                repeatedData.push({ key: "2000021", value: "Nakhon ratchasima" });
                                repeatedData.push({ key: "2000041", value: "Chiang mai" });
                                repeatedData.push({ key: "2000013", value: "Chon buri" });
                                repeatedData.push({ key: "2000070", value: "Surat thani" });
                                repeatedData.push({ key: "0000000", value: "--------------" });
                            } else {
                                repeatedData.push({ key: "2000003", value: "กรุงเทพมหานคร" });
                                repeatedData.push({ key: "2000021", value: "นครราชสีมา" });
                                repeatedData.push({ key: "2000041", value: "เชียงใหม่" });
                                repeatedData.push({ key: "2000013", value: "ชลบุรี" });
                                repeatedData.push({ key: "2000070", value: "สุราษฎร์ธานี" });
                                repeatedData.push({ key: "0000000", value: "--------------" });
                            }

                            let provinces = data.sort((a,b) => a.value.localeCompare(b.value, 'th'));
                            $scope.staticData.provinces = repeatedData.concat(provinces);
                            $scope.staticData.provincesByPostal = repeatedData.concat(provinces);

                            setTimeout(() => {
                                var dropdown = '#vehicleMake3';
                                if ($scope.asset.ManufactureId) dropdown = '#vehicleModel3';
                                if ($scope.asset.ModelId) dropdown = '#vehicleFirstReg3';
                                if ($scope.asset.PurchasingYear) return;
                                $(dropdown).addClass('open');
                                $('.scrollArrow').remove();
                                // var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                                // $("#arrowDesc").html(divArrow);
                            }, 100);
                            getDistrictList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getProvinceList', method: 'next to getDistrictList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1039'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDistrictList();
                        }
                    }, 'PROVINCE');
                };

                var getDistrictList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            $scope.staticData.districtByPostal = data;
                            getCountryList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDistrictList', method: 'next to getCountryList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1059'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getCountryList();
                        }
                    }, 'DISTRICT', 'provinceId=' + $scope.staticData.provinces[0].key);
                };

                var getCountryList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticData({
                        onSuccess: function (data) {
                            $scope.staticData.countries = data;
                            $scope.phAddress.CountryId = "200";
                            getResidentialStatusList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getCountryList', method: 'next to getResidentialStatusList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1080'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getResidentialStatusList();
                        }
                    }, 'COUNTRY');
                };

                var getResidentialStatusList = function () {
                    var hasError = false;
                    ApiServiceV2.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentialStatus = data;
                            getVehicleUsagesList();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getResidentialStatusList', method: 'wfeApiStaticData.getResidentialStatusList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1100'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getVehicleUsagesList();
                        }
                    });
                };

                var getVehicleUsagesList = function () {
                    var hasError = false;
                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            data.map((dt) => {
                                if(dt.key == 1000000) {
                                    dt.value = $scope.HKLocale.getLocale() == "en-us" ? "Private Use + For work" : "ใช้ส่วนบุคคลและไปทำงาน";
                                }
                                if(dt.key == 1000002 && $scope.HKLocale.getLocale() == "th-th") {
                                    dt.value = "ใช้ส่วนบุคคลและติดต่อธุรกิจ";
                                }
                            })
                            $scope.staticData.usages = data;
                            $scope.staticData.usagesAll = data;
                            callback();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getVehicleUsagesList', method: 'ApiServiceV2.getStaticDataWithParam'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1128'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;
                            callback();
                        }
                    }, 'VEHICLE_USAGE', 'productTypeId=1000000');
                };

                startRetrieval();
            },

            prependTopModels: function (makeId, models) {
                let topModels = []
                let divider = { key: '0000000', value: '--------------' }

                let notNull = item => item !== null
                let mapKeyToModel = key => {
                    let found = models.find(model => model.key === key)
                    return found ? Object.assign({}, found) : null
                }

                switch (makeId) {
                    case '1000091': // Toyota
                        topModels = [
                            '90000561', // Vios
                            '90000586', // Yaris
                            '90000797', // Yaris Ativ
                            '90000752', // Corolla Altis
                            '90000740', // Hilux Revo
                            '90000013', // Camry
                        ].map(mapKeyToModel).filter(notNull)
                        break
                    case '1000015': // Honda
                        topModels = [
                            '90000227', // City
                            '90000228', // Civic
                            '90000360', // Jazz
                            '90000741', // HR-V
                            '90000256', // CR-V
                            '90000152', // Accord
                        ].map(mapKeyToModel).filter(notNull)
                        break
                    case '1000031': // Nissan
                        topModels = [
                            '90000160', // Almera
                            '90000383', // March
                            '90000782', // Note
                            '90000531', // Teana
                            '90000530', // Sylphy
                            '90000893', // KICKS
                        ].map(mapKeyToModel).filter(notNull)
                        break
                    case '1000025': // Mazda
                        topModels = [
                            '90000027', // 2
                            '90000041', // 3
                            '90000753', // CX-3
                            '90000623', // CX-5
                            '90000877', // CX30
                            '90000620', // BT-50 PRO
                        ].map(mapKeyToModel).filter(notNull)
                        break
                    case '1000028': // Mitsubishi
                        topModels = [
                            '90000597', // Attrage
                            '90000387', // Mirage
                            '90000417', // Pajero Sport
                            '90000647', // Triton
                            '90000814', // Xpander
                            '90000884', // Xpandercross
                        ].map(mapKeyToModel).filter(notNull)
                        break
                }

                return topModels.length > 0 ? topModels.concat(divider, models) : models
            },

            eventHandlers: {
                onDropMakeChanged: function (manufacturerId) {
                    var hasError = false;

                    $scope.staticData.models = [];

                    if ($scope.HKLocale.getLocale() == "th-th") {
                        $("#vehicleModel option:first").text("กำลังโหลด...");
                        $scope.staticData.models = [{ "key": 0, "value": "กำลังโหลด..." }];
                    }
                    else {
                        $("#vehicleModel option:first").text("Loading...");
                        $scope.staticData.models = [{ "key": 0, "value": "Loading..." }];
                    }

                    if (!$scope.isStartQuote) {
                        $scope.isStartQuote = true;
                    }

                    if (!manufacturerId) {
                        if ($scope.staticData.models) {
                            $scope.staticData.models.splice(0);
                        }
                        return;
                    }

                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            data = aggFn.prependTopModels(manufacturerId, data)

                             $scope.staticData.models = data;

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            }
                            else {
                                $("#vehicleModel option:first").text("Please select...");
                            }

                            if (data.length == 0) {
                                fn.utils.clearLoading();
                                DaModal.open("#makeAndModelNotAvailable");
                            }

                            if ($scope.searchParams.has('ModelId')) {
                                var manufactureId = $scope.searchParams.get('ManufactureId');
                                var modelId = $scope.searchParams.get('ModelId');
                                var found = $scope.staticData.models.find(model => model.key === modelId);
                                if (found) {
                                    $scope.asset.ModelId = modelId;
                                    $scope.txtModel = found.value;
                                    aggFn.eventHandlers.onDropModelChanged(manufactureId, modelId);
                                    marketing.pushDataLayerMessage('pushModel', marketing.dataLayerMessages.selectModel);
                                }
                            }
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onDropMakeChanged', method: 'wfeApiStaticDataModel.getModelListV2'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1185'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            } else {
                                $("#vehicleModel option:first").text("Please select...");
                            }
                        }
                    }, 'MODEL', 'manufacturerId=' + manufacturerId);
                },
                onDropModelChanged: function (manufacturerId, modelId) {
                    $scope.staticData.yearOfRegistrations = [];

                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleFirstReg option:first").text("กำลังโหลด...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "กำลังโหลด..." }];
                    } else {
                        $("#vehicleFirstReg option:first").text("Loading...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "Loading..." }];
                    }

                    if (typeof modelId !== "undefined") {
                        var rel = 'years';
                        var objParams = $scope.staticData.models.find((item) => {
                            return item.key === modelId
                        });

                        var data = [];
                        if(objParams && objParams.years) {
                            for (var i = 0; i < objParams.years.length; i++) {
                                var item = {
                                    key: objParams.years[i].key,
                                    value: objParams.years[i].key
                                };
                                data.push(item);
                            }    
                        }

                        $scope.staticData.yearOfRegistrations = data;
                        if (HKLocale.getLocale() == "th-th") {
                            $("#vehicleFirstReg option:first").text("เลือกปีที่ผลิต");
                        } else {
                            $("#vehicleFirstReg option:first").text("Please select...");
                        }

                        if ($scope.searchParams.has('year')) {
                            var modelId = $scope.searchParams.get('ModelId');
                            var yearParam = $scope.searchParams.get('year');
                            var found = $scope.staticData.yearOfRegistrations.find(year => year.key === yearParam);
                            if (found) {
                                $scope.asset.PurchasingYear = yearParam;
                                $scope.txtYear = yearParam;
                                aggFn.eventHandlers.onDropPurchasingYearChanged(modelId, yearParam);
                                $scope.scrollToSection(4);
                                fn.eventHandlers.showDesc();
                            }
                        }
                    }
                },
                onDropPurchasingYearChanged: function (modelId, purchasingYear) {
                    var hasError = false;
                    $scope.staticData.cardescriptions = [];

                    var objParams = {};
                    objParams.modelId = modelId;
                    objParams.purchasingYear = purchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleDesc option:first").text("กำลังโหลด...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "กำลังโหลด..." }];
                    }
                    else {
                        $("#vehicleDesc option:first").text("Loading...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "Loading..." }];
                    }

                    ApiServiceV2.getStaticDataWithParam({
                        onSuccess: function (data) {
                            var options = [];
                            for (var i = 0; i < data.length; i++) {
                                var item = {
                                    key: data[i].key,
                                    value: data[i].value,
                                    name: data[i].name,
                                    description: data[i].name,
                                    tariffType: data[i].tariffType,
                                    groupId: data[i].groupId,
                                    classId: data[i].classId,
                                    ImportStatusId: data[i].importId,
                                    engineSizeId: data[i].engineSizeId,
                                    gearTypeId: data[i].gearTypeId,
                                    bodyTypeId: data[i].bodyTypeId
                                };
                                options.push(item);
                            }

                            $scope.staticData.cardescriptions = options;
                            $scope.staticData.arrangedcardescriptions = fn.eventHandlers.reduceDesc(options)

                            if ($scope.staticData.arrangedcardescriptions.length === 0) {
                                fn.utils.clearLoading();
                                DaModal.open("#makeAndModelNotAvailable");
                            }

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            }
                            else {
                                $("#vehicleDesc option:first").text("Please select...");
                            }

                            var callbacks = {
                                onSuccess: function () {
                                    fetchBodyType();
                                }
        
                            };
                            
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onDropPurchasingYearChanged', method: 'ApiServiceV2.getStaticDataWithParam'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1296'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            }
                            else {
                                $("#vehicleDesc option:first").text("Please select...");
                            }
                        }
                    }, 'CAR_DESCRIPTION', 'vehicleModelId=' + modelId + '&modelYear=' + purchasingYear)

                    var fetchBodyType = wfeApiStaticDataModel.getBodyTypes({
                        onSuccess: function (data) {
                            $scope.staticData.bodytypes = data;
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onDropPurchasingYearChanged', method: 'wfeApiStaticDataModel.getBodyTypes'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1320'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    

                        }
                    });

                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var NCDLVLID = "1000003";
                    var carAge = chkDate.getFullYear() - purchasingYear;
                    var ncdLevel = 0;

                    // 0% For vehicle age 0 year
                    if (carAge == 0) {
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    }

                    // 20% For vehicle age 1 year
                    else if (carAge == 1) {
                        ncdLevel = 20;
                        NCDLVLID = "1000005";
                    }

                    // 30% Vehicle age > 1 year
                    else if (carAge > 1) {
                        ncdLevel = 30;
                        NCDLVLID = "1000006";
                    }

                    else {
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    }

                    marketing.dLCE(58, NCDLVLID);
                },
            }
        };

        var fn = {

            gtAnchor: function (x) {
                
                if (!x) { return }

                //HKFormValidation.animateToID(x);
                $('html,body').animate({scrollTop:$(x).offset().top-150},1000)
                DaModal.close("#formInvalid")

            },

            gotoOnFocusAnchor: function () {
               
                if($scope.warningCounter == 1){
                    $('html,body').animate({scrollTop:$($scope.getFocusWarningID).offset().top-150},1000)
                }
                else{
                    $('html,body').animate({scrollTop:$('#body_car_make_label').offset().top-150},1000)
                }
                
                DaModal.close("#formInvalid")

            },

            goToInput: function (form, field) {
                let target = document
                    .querySelector(`form[name="${form.$name}"]`)
                    .querySelector(`[name="${field.$name}"]`)
                    .closest('.form-group')

                if (target) {
                    target.scrollIntoView({ behavior: 'smooth' })
                }
            },

            closeDaModal: function (id) {
                DaModal.close(id)
            },

            setSourceChannels: function () {

                var sc = {};
                sc.id = 0; sc.cd = '';
                sc = $scope.$parent.sourceChanneler(sc);

                $scope.asset.SourceChannelId = sc.id;
                $scope.asset.ChannelDetails = sc.cd;

                // post to "IDIT" into "ChannelDetails" columns
                if ($cookies["partner_id"]) {
                    $scope.asset.ChannelDetails = "partner_id:" + $cookies["partner_id"];
                }

            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;
                var wfeApiPaymentData = fn.wfeApiPayment;          

                var startRetrieval = function () {
                    getNoClaimDiscountList();
                };

                var getNoClaimDiscountList = function () {
                    var hasError = false;

                    wfeApiStaticData.getNoClaimDiscountList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data
                            getPaymentTermList()
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getNoClaimDiscountList', method: 'next to getPaymentTermList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1424'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getPaymentTermList()
                        }
                    })
                }

                var getPaymentTermList = function () {
                    var hasError = false;

                    var param = $scope.currentProposal.PaymentModeId;
                    
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), param);
                    wfeApiStaticDataModel.getPaymentTermList({
                        onSuccess: function (data) {

                            $scope.staticData.paymentTerms = data;

                            if ($scope.HKLocale.getLocale() === "th-th") {
                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.SINGLE_PAYMENT)
                                var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                $scope.staticData.paymentTerms[index].value = "ชำระเต็มจำนวน"
                                
                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.INSTALLMENT_PAYMENT)
                                var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                $scope.staticData.paymentTerms[index].value = "ชำระแบบผ่อน"
                            }
                            else {
                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.SINGLE_PAYMENT)
                                var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                $scope.staticData.paymentTerms[index].value = "Pay in full"
                                
                                var paymentTermId = $scope.staticData.paymentTerms.find(x => x.key == $scope.INSTALLMENT_PAYMENT)
                                var index = $scope.staticData.paymentTerms.indexOf(paymentTermId)
                                $scope.staticData.paymentTerms[index].value = "Installment"
                            }

                            getBooleanListWithSometimes()
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getPaymentTermList', method: 'wfeApiStaticDataModel.getPaymentTermList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1469'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getBooleanListWithSometimes()
                        }
                    });

                }

                var getBooleanListWithSometimes = function () {
                    var hasError = false;

                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getBooleanListWithSometimes', method: 'next to getBooleanList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1492'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    var hasError = false;
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getMakeList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getBooleanList', method: 'getMakeList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1513'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getMakeList();
                        }
                    });
                };

                var getMakeList = function () {
                    var hasError = false;
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            if (data) {
                                var repeatedData = [];
                                repeatedData.push({ key: "1000091", value: "Toyota" });
                                repeatedData.push({ key: "1000015", value: "Honda" });
                                repeatedData.push({ key: "1000031", value: "Nissan" });
                                repeatedData.push({ key: "1000025", value: "Mazda" });
                                repeatedData.push({ key: "1000080", value: "Isuzu" });
                                repeatedData.push({ key: "1000028", value: "Mitsubishi" });
                                repeatedData.push({ key: "0000000", value: "--------------" });

                                var makes = data.sort((n1,n2) => {
                                    if (n1.value > n2.value) {
                                        return 1;
                                    }
                                
                                    if (n1.value < n2.value) {
                                        return -1;
                                    }
                                
                                    return 0;
                                });
    

                                $scope.staticData.makes = repeatedData.concat(makes);
                            }
                            else {
                                $scope.staticData.makes = data;
                            }
                            getGenderList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getMakeList', method: 'getGenderList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1561'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getGenderList();
                        }
                    });
                };

                var getGenderList = function () {

                    if ($scope.HKLocale.getLocale() === "th-th") {
                        var data = [{ "key": "2", "value": "ชาย" }, { "key": "1", "value": "หญิง" }];
                    } else {
                        var data = [{ "key": "2", "value": "Male" }, { "key": "1", "value": "Female" }];
                    }
                    $scope.staticData.genders = data;
                    getMaritalStatusList();
                };

                var getMaritalStatusList = function () {

                    if ($scope.HKLocale.getLocale() === "th-th") {
                        var data = [{ "key": "1", "value": "โสด" }, { "key": "2", "value": "สมรส" }];
                    } else {
                        var data = [{ "key": "1", "value": "SINGLE" }, { "key": "2", "value": "MARRIED" }];
                    }
                    $scope.staticData.maritalStatuses = data;
                    getDatesPartsDD();
                };

                var getDatesPartsDD = function () {
                    var hasError = false;
                    wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsDD', method: 'next to getDatesPartsMM'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1604'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDatesPartsMM();
                        }
                    });
                };

                var getDatesPartsMM = function () {
                    var hasError = false;
                    wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;
                            getDatesPartsYY();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsMM', method: 'next to getDatesPartsYY'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1625'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDatesPartsYY();
                        }
                    });
                };

                var getDatesPartsYY = function () {
                    var hasError = false;
                    wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            getDatesPartsFullYY();

                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsYY', method: 'next to getDatesPartsFullYY'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1647'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDatesPartsFullYY();
                        }
                    });
                };

                var getDatesPartsFullYY = function () {
                    var hasError = false;
                    fn.wfeApiStaticData.getDatesPartsFullYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYearsPolicy = [data[0], { "key": data[0]["key"] + 1, "value": data[0]["value"] + 1, "valueTH": data[0]["valueTH"] + 1 }];
                            fn.utils.setIsSaving(false);
                            getDriverExperienceList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDatesPartsFullYY', method: 'next to getDriverExperienceList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1669'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDriverExperienceList();
                        }
                    });
                };

                var getDriverExperienceList = function () {
                    var hasError = false;
                    wfeApiStaticData.getDriverExperienceList({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data
                            getOccupationList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDriverExperienceList', method: 'next to getOccupationList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1690'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getOccupationList();
                        }
                    });
                };

                var getOccupationList = function () {
                    var hasError = false;
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            let occupations = data.sort((a,b) => a.value.localeCompare(b.value, 'th'));
                            $scope.staticData.occupations = occupations;
                            getNoOfClaimList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getOccupationList', method: 'next to getNoOfClaimList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1712'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getNoOfClaimList();
                        }
                    });
                };

                var getNoOfClaimList = function () {
                    var hasError = false;
                    wfeApiStaticData.getNoOfClaimList({
                        onSuccess: function (data) {
                            $scope.staticData.nrOfClaimsInPeriods = data;
                            getProvinceList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getNoOfClaimList', method: 'next to getProvinceList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1733'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getProvinceList();
                        }
                    });
                };

                var getProvinceList = function () {
                    var hasError = false;
                    wfeApiStaticData.getProvinceList({
                        onSuccess: function (data) {
                            var repeatedData = [];
                            if($scope.HKLocale.getLocale() == "en-us") {
                                repeatedData.push({ key: "2000003", value: "Bangkok" });
                                repeatedData.push({ key: "2000021", value: "Nakhon ratchasima" });
                                repeatedData.push({ key: "2000041", value: "Chiang mai" });
                                repeatedData.push({ key: "2000013", value: "Chon buri" });
                                repeatedData.push({ key: "2000070", value: "Surat thani" });
                                repeatedData.push({ key: "0000000", value: "--------------" });
                            } else {
                                repeatedData.push({ key: "2000003", value: "กรุงเทพมหานคร" });
                                repeatedData.push({ key: "2000021", value: "นครราชสีมา" });
                                repeatedData.push({ key: "2000041", value: "เชียงใหม่" });
                                repeatedData.push({ key: "2000013", value: "ชลบุรี" });
                                repeatedData.push({ key: "2000070", value: "สุราษฎร์ธานี" });
                                repeatedData.push({ key: "0000000", value: "--------------" });
                            }

                            let provinces = data.sort((a,b) => a.value.localeCompare(b.value, 'th'));
                            $scope.staticData.provinces = repeatedData.concat(provinces);
                            $scope.staticData.provincesByPostal = repeatedData.concat(provinces);
                            getDistrictList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getProvinceList', method: 'next to getDistrictList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1773'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getDistrictList();
                        }
                    });
                };

                var getDistrictList = function () {
                    var hasError = false;
                    wfeApiStaticData.getDistrictList({
                        onSuccess: function (data) {
                            $scope.staticData.districtByPostal = data;
                            getCountryList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getDistrictList', method: 'next to getCountryList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1794'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getCountryList();
                        }
                    });
                };

                var getCountryList = function () {
                    var hasError = false;
                    wfeApiStaticData.getCountryList({
                        onSuccess: function (data) {
                            $scope.staticData.countries = data;
                            $scope.phAddress.CountryId = "200";
                            getResidentialStatusList();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getCountryList', method: 'next to getResidentialStatusList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1816'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            getResidentialStatusList();
                        }
                    });
                }

                var getResidentialStatusList = function () {
                    var hasError = false;
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentialStatus = data;
                            //getEngineSizes();
                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getResidentialStatusList', method: 'wfeApiStaticData.getResidentialStatusList'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1837'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                            //getEngineSizes();
                        }
                    });
                };

                var getEngineSizes = function () {
                    var hasError = false;
                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getEngineSizes({
                        onSuccess: function (data) {
                            $scope.staticData.enginesizes = data;
                            getBodyTypes();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getEngineSizes', method: 'next to getBodyTypes'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1861'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;
                            getBodyTypes();
                        }
                    });
                };
                
                var getBodyTypes = function () {
                    var hasError = false;
                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getBodyTypes({
                        onSuccess: function (data) {
                            $scope.staticData.bodytypes = data;
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getBodyTypes', method: 'wfeApiStaticDataModel.getBodyTypes'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 1883'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                        }
                    });
                };

                startRetrieval();

            },

            initScopeObjects: function () {
                if($scope.HKLocale.getLocale() == "en-us"){
                    document.documentElement.lang = 'en-US'
                }
                else{
                    document.documentElement.lang = 'th-TH'
                }

                $scope.staticData = {};
                $scope.staticData.junkNumbers = [];
                $scope.staticData.conversionValues = [];
                $scope.coverageDetails = {};
                $scope.coverageDetails.show = false;
                var dateTomorrow = MITDate.newDate().add(1, 'days');
                
                $scope.currentProposal = {
                    PolicyStartDate: MITDate.toAngularDate(dateTomorrow),
                    PolicyEndDate: MITDate.toAngularDate(dateTomorrow.add(1, 'years')),
                    PaymentModeId: 4,
                    PaymentTermId: $scope.SINGLE_PAYMENT
                };

                $scope.asset = {
                    HasAntiTheftDevice: false,
                    'LobInsuranceFormulaId': AppConstants.AGGConfig.TYPE1_FALCON_DG,
                };

                $scope.plan = {
                    coversNamed: 1
                };

                var dateToday = MITDate.newDate();
                $scope.yearNow = (parseInt(MITDate.getYYYY(dateToday)) - 9) || 1941;

                $scope.asset.ProductId = $scope.PRODUCT_ID_DA;

                $scope.popUpContact = {
                    name: "",
                    phone: "",
                    consent: false
                };
                /*** regex will be false when phone number is invalid */
                $scope.phoneRegex = /^[0][6|8|9][0-9]\d{7}/;
                $scope.policyHolder = {};
                $scope.mainDriver = {};
                $scope.yourPolicy = {};
                $scope.namedDriver = {};
                $scope.namedDrivers = {
                    PolicyContacts: []
                };
                $scope.accountUser = {};
                $scope.phAddress = {};
                $scope.phContact = {};
                $scope.dynamicForm = {};
                $scope.dynamicForm.addedNamedDriver = false;
                $scope.dynamicForm.namedDriverAction = 'create';
                $scope.bidata = {};
                $scope.errdata = {};
                $scope.callbackObj = {};
                $scope.securityQuestion = {};
                $scope.isSecurityQuestion = false;
                $scope.previousPage = 0;
                $scope.DOBEmpty = false;
                $scope.loadingSpinner = true;
                $scope.loadingSpinner2 = true;
                $scope.loadingSpinner3 = true;
                $scope.contactPopUpOpened = false;
                $scope.ThankYouPopUpOpened = false;
                $scope.partialPolicyNumber = "";
                $scope.applyNCB;
                $scope.isStartQuote = false;
                $scope.isSaveAndExitShowName = true;
                $scope.txtMaker = fn.getTranslateText('maker');
                $scope.txtMakerB2C = fn.getTranslateText('maker');
                $scope.txtModel = fn.getTranslateText('model');
                $scope.txtYear = fn.getTranslateText('year');
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.txtCamera = HKLocale.getLocale() == 'th-th' ? "มีกล้องติดรถยนต์หรือไม่" : "Please select";
                $scope.txtCarUsage = HKLocale.getLocale() == 'th-th' ? "เลือกจุดประสงค์การใช้รถ" : "Please select";

                $scope.txtPolicyStartDateDD = fn.getTranslateText('policyStartDateDD');
                $scope.txtPolicyStartDateMON = fn.getTranslateText('policyStartDateMON');
                $scope.txtPolicyStartDateYear = fn.getTranslateText('policyStartDateYear');

                $scope.txtMainDriverDOBDD = fn.getTranslateText('mainDriverDOBDD');
                $scope.txtMainDriverDOBMON = fn.getTranslateText('mainDriverDOBMON');
                $scope.txtMainDriverDOBYear = fn.getTranslateText('mainDriverDOBYear');


                $scope.txtProvince = fn.getTranslateText('province');
                $scope.txtPaymentTermsId = '';
                $scope.txtPaymentTerms = HKLocale.getLocale() == 'th-th' ? "เลือกประเภทการชำระเงิน" : "Please select";
                $scope.txtMDprovince = HKLocale.getLocale() == 'th-th' ? "เลือกจังหวัด" : "Please select";
                $scope.txtMDGender = HKLocale.getLocale() == 'th-th' ? "เลือกเพศ" : "Please select";
                $scope.txtMDMaritalStatus = HKLocale.getLocale() == 'th-th' ? "เลือกสถานภาพสมรส" : "Please select";
                $scope.txtOccupationId = HKLocale.getLocale() == 'th-th' ? "เลือกอาชีพ" : "Please select";
                $scope.txtNrOfClaimsInPeriodId = HKLocale.getLocale() == 'th-th' ? "ระบุจำนวนการเกิดอุบัติเหตุหรือเคลม" : "Please select";
                $scope.txtDrivingExperiences = HKLocale.getLocale() == 'th-th' ? "เลือกอายุใบขับขี่รถยนต์" : "Please select";
                $scope.txtVehicleColour = HKLocale.getLocale() == 'th-th' ? "เลือกสียานพาหนะ" : "Please select";
                $scope.txtNationality = HKLocale.getLocale() == 'th-th' ? "เลือกสัญชาติ" : "Please select";
                $scope.txtNamedDriverOccupationId = HKLocale.getLocale() == 'th-th' ? "เลือกอาชีพ" : "Please select";
                $scope.txtNamedDriverResidentialStatusId = HKLocale.getLocale() == 'th-th' ? "เลือกภูมิลำเนาเดิม" : "Please select";
                $scope.txtNamedDriverNationalityId = HKLocale.getLocale() == 'th-th' ? "เลือกสัญชาติ" : "Please select";
                $scope.txtNamedDrivingExperienceId = HKLocale.getLocale() == 'th-th' ? "เลือกประสบการณ์การขับขี่รถยนต์" : "Please select";
                $scope.txtDriverNrOfClaimsInPeriodId = HKLocale.getLocale() == 'th-th' ? "เลือกจำนวนการเกิดอุบัติเหตุหรือการเคลม" : "Please select";
                $scope.txtPolicyHolderOccupationId = HKLocale.getLocale() == 'th-th' ? "เลือกอาชีพ" : "Please select";
                $scope.txtPolicyHolderNationalityId = HKLocale.getLocale() == 'th-th' ? "เลือกสัญชาติ" : "Please select";
                $scope.txtPhAddressProvinceId = HKLocale.getLocale() == 'th-th' ? "เลือกจังหวัด" : "Please select";
                $scope.txtPhAddressDistrictId = HKLocale.getLocale() == 'th-th' ? "เลือกอำเภอ" : "Please select";
                $scope.phAddress.txtPhAddressDistrictId = HKLocale.getLocale() == 'th-th' ? "เลือกอำเภอ" : "Please select";
                $scope.txtExpMonthId = HKLocale.getLocale() == 'th-th' ? "เดือน" : "MM";
                $scope.txtExpYearId = HKLocale.getLocale() == 'th-th' ? "ปี" : "YYYY";
                
                param = 1000003;
                $scope.showMainDriverFlag = false;
                $scope.showContactInfoFlag = false;
                $scope.firstPageShow = true;
                $scope.secondPageShow = false;
                $scope.thirdPageShow = false;
                $scope.showPlanFlag = false;
                $scope.coverNum = 0;
                $scope.sumInsured = {};
                $scope.sumInsured.value = 0;
                $scope.sumInsured.visible = false;
                $scope.chkMaker = false;
                $scope.chkModel = false;
                $scope.chkYear = false;
                $scope.chkDesc = false;
                $scope.chkCamera = false;
                $scope.bodyType = '';// set 4 doors as default
                $scope.gearType = ''; //Set manual as default
                $scope.acctImageURL = '';
                $scope.acctImageLink = '';
                $scope.sectioname = "Start new proposal";
                $scope.loc = '';
                $scope.loc = HKLocale.getLocale();
                if ($scope.loc == 'en-us') {
                    $scope.policyHolder.PreferredLanguageId = 101;
                }
                if ($scope.loc == 'th-th') {
                    $scope.policyHolder.PreferredLanguageId = 2000000;
                }

                $scope.basicCoverageInfo = {
                    "2000015":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                        ]
                };

                $scope.basicCoverageInfo_Part2 = {
                    "2000015":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_payment'
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_payment'
                        ]
                };
                if($scope.coverages && $scope.coverages.Coverages && $scope.coverages.length > 0) {
                    let selectedCoverage = $scope.coverages.Coverages.find(w => w.IsSelected == true);
                    if(selectedCoverage && selectedCoverage.CoverTypeId) {
                        $scope.getMyCoverTypeId = selectedCoverage.CoverTypeId;
                    }  else {
                        selectedCoverage = $scope.coverages.Coverages.find(w => w.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG);
                        if(!selectedCoverage) {
                            selectedCoverage = $scope.coverages.Coverages[0];
                        }
                        selectedCoverage.IsSelected = true;
                        $scope.getMyCoverTypeId = selectedCoverage.CoverTypeId;
                    }      
    
                    if(selectedCoverage && selectedCoverage.CoverTypeId) {
                        // Coverage Table ===> T1, T1S ,T2+, T3+, T2, T3,;
                        if (selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_D || selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_G) {    
                            $scope.tyepOfCoverageTable = {
                                "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                "b2c_popup_cover_item7": [true ,false , false, false, false, false],
                                "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                            };
                        } else if (selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_D || selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_G){
                            $scope.tyepOfCoverageTable = {
                                "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                "b2c_popup_cover_item6_axa": [true ,false , false, false, false, false],
                                "b2c_popup_cover_item7": [true ,false , false, false, false, false],
                                // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                            };
                        }

                        else if(selectedCoverage.CoverTypeId = $scope.TYPE1_FALCON_DG && !typeThree && !typeThreePlus){
                            $scope.tyepOfCoverageTable = {
                                // Coverage Table ===> T1s, T1, T2+, T2,;
                                "b2c_popup_cover_item1": [true ,true , true, false, false, false],
                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                "b2c_popup_cover_item3": [true ,true , true, true, true, false],
                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                "b2c_popup_cover_item6": [true ,true , true, false, false, false]
                                
                        }
                    }
                        else if(selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG && typeThreePlus)
                            {
                                $scope.tyepOfCoverageTable = {
                                    // Coverage Table ===> T2+, T3+, T2, T3,;
                                    "b2c_popup_cover_item1": [true ,true , false, false, false, false],
                                    "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                    "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                    "b2c_popup_cover_item4": [true ,false , false, false, false, false],
                                    "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                    "b2c_popup_cover_item6": [true ,true , false, false, false, false]
                                    // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                };
                            }
                            else if(selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG && !typeThreePlus){
                                $scope.tyepOfCoverageTable = {
                                    // Coverage Table ===> T2+, T3;
                                    "b2c_popup_cover_item1": [true ,false , false, false, false, false],
                                    "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                    "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                    "b2c_popup_cover_item4": [true ,false , false, false, false, false],
                                    "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                    "b2c_popup_cover_item6": [true ,false , false, false, false, false]
                                    // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                };
                            }
                            else if (selectedCoverage.CoverTypeId == $scope.TYPE2_FALCON_DG){
                                $scope.tyepOfCoverageTable = {
                                    // Coverage Table ===> T2;
                                    "b2c_popup_cover_item1": [false ,false , false, false, false, false],
                                    "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                    "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                    "b2c_popup_cover_item4": [false ,false , false, false, false, false],
                                    "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                    "b2c_popup_cover_item6": [false ,false , false, false, false, false]
                                    // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                };
                            }
                            else if(selectedCoverage.CoverTypeId == $scope.TYPE3_FALCON_DG)
                                {
                                    $scope.tyepOfCoverageTable = {
                                        // Coverage Table ===> T3,;
                                        "b2c_popup_cover_item1": [false ,true , true, true, false, false],
                                        "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                        "b2c_popup_cover_item3": [false ,false , true, false, true, false],
                                        "b2c_popup_cover_item4": [false ,false , false, false, false, false],
                                        "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                        "b2c_popup_cover_item6": [false ,true , false, false, false, false]
                                        // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                    };
                                }
                        
                        
                        else {
                            $scope.tyepOfCoverageTable = {
                                "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                };

                        }
                    } else {
                        $scope.tyepOfCoverageTable = {
                            "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                            "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                            "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                            "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                            "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                            "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                        };
                    }
                }
                $scope.customInsuranceAmount = 0;
                $scope.selectedInsureAmount = 0;
                $scope.carAge = 0;
                $scope.carUsageList = [
                                        { "key": "6", "value": "btn_car_usage1" },
                                        { "key": "1000000", "value": "btn_car_usage2" },
                                        { "key": "1000002", "value": "btn_car_usage3" },
                                        { "key": "7000000", "value": "btn_car_usage4" }
                                    ];
                $scope.policyMinStartDate = MITDate.toAngularDate(MITDate.newDate().add(0, 'days'));
                $scope.policyMaxStartDate = MITDate.toAngularDate(MITDate.newDate().add(59, 'days'));

                $scope.compulsory = {};
                $scope.compulsory.isIncluded = false;
                $scope.compulsory.isFontEndForce = true;
            
                $scope.recalculate = {};
                const currentPage = $sessionStorage.currentPage;
                if(currentPage < 3){
                    $scope.recalculate.isNeeded = true;
                }
                $scope.isNoPackage = false;
                $scope.ppst = {};


                var d = $scope.$parent.convertDateOneToThree($scope.currentProposal.PolicyStartDate);
                $scope.currentProposal.PolicyStartDate_DD = d[0];
                $scope.currentProposal.PolicyStartDate_MON = d[1];
                $scope.currentProposal.PolicyStartDate_Year = d[2];

                $scope.txtPolicyStartDateDD = d[0];
                // $scope.txtPolicyStartDateMON = d[1];
                $scope.txtPolicyStartDateYear = d[2];
            },

            initApiObjects: function () {

                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.namedDriversApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.namedDrivers);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.policyContactApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyContact);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.yearNow);
                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress);

                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, $scope.errdata);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

            },

            /** Subscribe MKT email condition */
            initSubscribeWatcher: function () {

                $scope.$watch('policyHolder.HasUnsubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.SubscribedMarketingEmail = !value;
                });
                $scope.$watch('policyHolder.SubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.HasUnsubscribedMarketingEmail = !value;
                });
                //4.P22 -> after loaded MT GetPioryty & Send
                $scope.$watch('currentProposal.ExternalProposalNumber', function (val) {             
                    if($scope.mtNumber) {
                        $scope.mtNumber = false;
                    }              
                  });

                fn.handleRightPanelPosition = function (scroll) {
                    var setInitialStaticMarginTop = 15
                    $scope.currentPos = scroll
                    var stickyHeight = $('#rightPanel').height()
                    var getTopOffset = () => $('[da-header-scroll]').height() + parseInt($('[da-header-scroll]').css('marginBottom'))

                    if (scroll === 0) {
                        setTimeout(function tryUntil () {
                            if ($('[da-header-scroll]').length && $('#rightPanel').length) {
                                $('#rightPanel').css({ top: getTopOffset() })
                                return
                            }
                            setTimeout(tryUntil, 100)
                        })
                    }

                    if($('#rightPanel').offset() && $('#rightPanel').offset().top) {
                        var offset = getTopOffset()
                        var stickyTopPosition = $('#rightPanel').offset().top

                        var stickyBottomPosition = $('#rightPanel').offset().top + (stickyHeight-18)                    
                        var leftPanelBottomPosition = $(".MainPanelOfB2C").offset().top + $(".MainPanelOfB2C").height()
                        var leftPanelBottomPositionPolicySub = $(".PolicySubmissionPanelOfB2C").offset().top + $(".PolicySubmissionPanelOfB2C").height()

                        if (scroll == 0) {
                            $('#rightPanel').css({ "z-index":"1000", "top": offset })
                        }

                        else if (scroll > 0 && scroll < offset) {

                            var recheckSpacingOnTopSticky = offset-scroll

                            if(recheckSpacingOnTopSticky >= setInitialStaticMarginTop){
                                $('#rightPanel').css({ "z-index": "1000", "top": recheckSpacingOnTopSticky })
                            }
                            else{
                                $('#rightPanel').css({ "z-index": "1000", "top": setInitialStaticMarginTop })
                            }
                        }

                        else if (scroll >= offset && scroll <= 1000) {
                            $('#rightPanel').css({ "z-index": "1000", "top": setInitialStaticMarginTop })
                        }

                        else if (scroll > 1000) {

                            // First page
                            if($scope.firstPageShow == true){

                                    if(stickyBottomPosition < leftPanelBottomPosition){

                                        var getDiffResult = leftPanelBottomPosition - stickyBottomPosition
                                        var setNewTopPositionSticky = stickyTopPosition + getDiffResult
                                        var recheckSpacingPointWhenScrollUp = setNewTopPositionSticky - setInitialStaticMarginTop

                                        // "Scroll-Up" is eual with "Top position Sticky"                                    
                                        if(scroll < recheckSpacingPointWhenScrollUp){                                      
                                            $('#rightPanel').css({"z-index":"1000","top": setInitialStaticMarginTop })
                                        }

                                        // "Scroll-Up" is not eual with "Top position Sticky"
                                        else{
                                            $('#rightPanel').offset({ top : setNewTopPositionSticky })
                                        }
                                    }

                                    if(stickyBottomPosition == leftPanelBottomPosition){
                                        /* nothing to do */                                    
                                    }

                                    if(stickyBottomPosition > leftPanelBottomPosition){
                                        var minusDistance = stickyBottomPosition - leftPanelBottomPosition                                    
                                        var newCalculate = stickyTopPosition - minusDistance
                                        $('#rightPanel').offset({ top : newCalculate})
                                    }
                            }

                            // Second page
                            if(($scope.firstPageShow == true) && ($scope.secondPageShow == true)){    
                                
                                    if(stickyBottomPosition < leftPanelBottomPosition){

                                        var getDiffResult = leftPanelBottomPosition - stickyBottomPosition
                                        var setNewTopPositionSticky = stickyTopPosition + getDiffResult
                                        var recheckSpacingPointWhenScrollUp = setNewTopPositionSticky - setInitialStaticMarginTop
                                        
                                        // "Scroll-Up" is eual with "Top position Sticky"                                    
                                        if(scroll < recheckSpacingPointWhenScrollUp){                                      
                                            $('#rightPanel').css({"z-index":"1000","top": setInitialStaticMarginTop })
                                        }

                                        // "Scroll-Up" is not eual with "Top position Sticky"
                                        else{
                                            $('#rightPanel').offset({ top : setNewTopPositionSticky })
                                        }
                                    }
                                    if(stickyBottomPosition == leftPanelBottomPosition){
                                        /* nothing to do */                                    
                                    }
                                    if(stickyBottomPosition > leftPanelBottomPosition){
                                        var minusDistance = stickyBottomPosition - leftPanelBottomPosition                                    
                                        var newCalculate = stickyTopPosition - minusDistance
                                        $('#rightPanel').offset({ top : newCalculate })
                                    }
                            }

                            // Third page
                            if($scope.thirdPageShow == true){
                                
                                    if(stickyBottomPosition < leftPanelBottomPositionPolicySub){

                                        var getDiffResult = leftPanelBottomPositionPolicySub - stickyBottomPosition
                                        var setNewTopPositionSticky = stickyTopPosition + getDiffResult
                                        var recheckSpacingPointWhenScrollUp = setNewTopPositionSticky - setInitialStaticMarginTop
                                        
                                        // "Scroll-Up" is eual with "Top position Sticky"                                    
                                        if(scroll < recheckSpacingPointWhenScrollUp){                                      
                                            $('#rightPanel').css({"z-index":"1000","top": setInitialStaticMarginTop })
                                        }

                                        // "Scroll-Up" is not eual with "Top position Sticky"
                                        else{
                                            $('#rightPanel').offset({ top : setNewTopPositionSticky })
                                        }                            
                                    }
                                    if(stickyBottomPosition == leftPanelBottomPositionPolicySub){
                                        /* nothing to do */                                    
                                    }
                                    if(stickyBottomPosition > leftPanelBottomPositionPolicySub){
                                        var minusDistance = stickyBottomPosition - leftPanelBottomPositionPolicySub                                    
                                        var newCalculate = stickyTopPosition - minusDistance
                                        $('#rightPanel').offset({ top : newCalculate })
                                    }
                            }
                        }
                    }
                }
  
                $scope.$watch(function () { return $(window).scrollTop() }, fn.handleRightPanelPosition)

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        destroyWatcher();
                    }
                );
            },

            retrieveScopeObjects: function () {
                /** Fire to GA that customer has been visited page */
                var retrieveCurrentProposal = function () {

                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {

                                angular.extend($scope.currentProposal, data);
                                fn.utils.ConvertMITDateToDisplay();

                                if (data && data.Assets && data.Assets.length > 0) {
                                    let selectedCoverage = data.Assets[0].Coverages.find(coverage => coverage.IsSelected);
                                    let compulsory = fn.utils.getIncludeCompulsoryByCoverage(selectedCoverage);
                                    if (compulsory) {
                                        $scope.compulsoryChecker = compulsory.IsSelected;
                                    }
                                }

                                retrieveAsset();
                                if ($scope.currentProposal.ExternalProposalNumber) {
                                    $scope.recalculate.isNeeded = false;                                    
                                }
                            },
                            onError: function (err) {
                                if(err) {
                                    if(!hasError) {
                                        var error = err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveScopeObjects', method: 'retrieveCurrentProposal'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 2347'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }
                                    hasError = true;
                                }
                                retrieveAsset();
                                fn.utils.ConvertMITDateToDisplay();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    var hasError = false;
                    fn.assetApi.retrieveAsset({
                        onSuccess: function (data) {

                            angular.extend($scope.asset, data);
                            $scope.asset.LicenseProvinceId2 = $sessionStorage.CarPlateProvinceID.key;
                            $scope.txtProvince = $sessionStorage.CarPlateProvinceID.value;
                            fn.updatesCoveragesSPA();
                            $scope.applyNCB = $scope.$parent.applyNCBRoot;

                            if ($scope.$parent.applyNCBRootDontKnow) {
                                $scope.asset.NcdLevelId = "1000009";
                            }
                            if ($scope.asset.ManufactureId) {
                                $scope.txtMaker = $filter('filter')($scope.staticData.makes, { key: $scope.asset.ManufactureId });
                                if($scope.txtMaker && $scope.txtMaker.length > 0) {
                                    $scope.txtMaker = $scope.txtMaker[0]
                                    if($scope.txtMaker && $scope.txtMaker.value) {
                                        $scope.txtMaker = $scope.txtMaker.value
                                    }
                                }
                                aggFn.eventHandlers.onDropMakeChanged($scope.asset.ManufactureId);
                                $scope.asset.PurchasingYear = $scope.asset.PurchasingYear.toString();
                            }
                            if ($scope.asset.ModelId) {
                                aggFn.eventHandlers.onDropModelChanged($scope.asset.ManufactureId, $scope.asset.ModelId);
                                var objParams = {};
                                objParams.manufacturerId = $scope.asset.ManufactureId;
                                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                                wfeApiStaticDataModel.getModelListV2({
                                    onSuccess: function (data) {
                                        fn.assetStaticData.model = data;
                                        $scope.txtModel = $filter('filter')(fn.assetStaticData.model, { key: $scope.asset.ModelId });
                                        if($scope.txtModel && $scope.txtModel.length > 0){
                                            $scope.txtModel = $scope.txtModel[0]
                                            if($scope.txtModel && $scope.txtModel.value){
                                                $scope.txtModel = $scope.txtModel.value
                                            }
                                        }
                                    }
                                });
                            }
                            if ($scope.asset.PurchasingYear) {
                                aggFn.eventHandlers.onDropPurchasingYearChanged($scope.asset.ModelId, $scope.asset.PurchasingYear);
                                $scope.txtYear = $scope.asset.PurchasingYear;
                            }
                            if ($scope.asset.CarDescriptionId) {
                                var objParams = {};
                                objParams.modelId = $scope.asset.ModelId;
                                objParams.purchasingYear = $scope.asset.PurchasingYear;
                                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                                wfeApiStaticDataModel.getCarDescWithYear({
                                    onSuccess: function (data) {
                                        fn.assetStaticData.description = data;
                                        $scope.txtDesc = $filter('filter')(fn.assetStaticData.description, { key: $scope.asset.CarDescriptionId });
                                        if($scope.txtDesc && $scope.txtDesc.length > 0){
                                            $scope.txtDesc = $scope.txtDesc[0]
                                            if($scope.txtDesc && $scope.txtDesc.description){
                                                $scope.txtDesc = $scope.txtDesc.description
                                            }
                                        }
                                    }
                                });
                            }

                            retrievePHAddress();
                        },
                        onError: function (err) {
                            if(err) {
                                if(!hasError) {
                                    var error = err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveAsset', method: 'fn.assetApi.retrieveAsset'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 2430'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;
                            }
                            retrievePHAddress();
                        }
                    });
                };

                var retrievePHAddress = function () {
                    var hasError = false;
                    fn.phAddressApi.retrievePHAddress({
                        onSuccess: function (data) {
                            angular.extend($scope.phAddress, data);
                            $scope.phAddress.DistrictId = $scope.phAddress.DistrictId + "";
                            retrievePHContact();
                        },
                        onError: function (err) { 
                            if(err) {
                                if(!hasError) {
                                    var error = err.ErrorInfo ? err.ErrorInfo : {fn: 'retrievePHAddress', method: 'fn.phAddressApi.retrievePHAddress'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 2452'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;
                            }
                            retrievePHContact(); 
                        }
                    });
                }

                var retrievePHContact = function () {
                    var hasError = false;
                    fn.phContactApi.retrievePHContact({
                        onSuccess: function (data) {
                            angular.extend($scope.phContact, data);
                            retrievePolicyHolder();
                        },
                        onError: function (err) {
                            if(err) {
                                if(!hasError) {
                                    var error = err.ErrorInfo ? err.ErrorInfo : {fn: 'retrievePHContact', method: 'next to retrievePolicyHolder'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 2473'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;
                            }
                            retrievePolicyHolder();
                        }
                    });
                }

                /** PolicyContact  Retrieval : this will retrieve MD if PH is not MD */
                var retrievePolicyHolder = function () {
                    var hasError = false;
                    fn.policyHolderApi.retrievePHPolicyContact({
                        onSuccess: function (data) {
                            angular.extend($scope.policyHolder, data);

                            if ($scope.policyHolder.FullName && $scope.policyHolder.FullName.indexOf(" ") > -1 && 
                                ($scope.policyHolder.LastName === null || 
                                 $scope.policyHolder.LastName === undefined || 
                                 $scope.policyHolder.LastName === "")) {
                                //New split : Pu 07/12/2022
                                const getSplitFullname = $scope.policyHolder.FullName.split(' ').map(element => element.trim()).filter(element => element !== '');    
                                $scope.firstName = getSplitFullname[0];
                                $scope.lastName = getSplitFullname[1] ? getSplitFullname[1] : '-';
                            }
                            else {
                                $scope.firstName = $scope.policyHolder.FirstName;
                                $scope.lastName = $scope.policyHolder.LastName;
                                $scope.policyHolder.FullName = $scope.policyHolder.FirstName;
                                if($scope.policyHolder.LastName || $scope.policyHolder.LastName !== 'x'){
                                    $scope.policyHolder.FullName += ` ${$scope.policyHolder.LastName}`
                                }
                            }

                            $scope.policyHolder.FirstName = $scope.firstName;
                            $scope.policyHolder.LastName = $scope.lastName;

                            if (data && data.Identifier) {
                                HKFormValidation.splitIdentifier(data, $scope.policyHolder);
                            }
                            if ($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                $scope.policyHolder.SubscribedMarketingEmail = false;
                            } 
                            else {
                                $scope.policyHolder.SubscribedMarketingEmail = true;
                            }

                            //Prefill MD Province
                            if(!$scope.phAddress.MDProvinceId && $scope.policyHolder.MDProvinceID){
                                $scope.phAddress.MDProvinceId = $scope.policyHolder.MDProvinceID;
                                const getProvince = $scope.staticData.provincesByPostal.find(w => w.key == $scope.phAddress.MDProvinceId);
                                if(getProvince){
                                    $scope.txtMDprovince = getProvince.value;
                                }
                            }

                            //Prefill DateOfBirth
                            if($scope.policyHolder.DateOfBirth && !$scope.mainDriver.DOB_DD && !$scope.mainDriver.DOB_MON && !$scope.mainDriver.DOB_Year){
                                const dateString = $scope.policyHolder.DateOfBirth;
                                const dateParts = dateString.split("-");
                                const PhYear = parseInt(dateParts[0], 10);
                                const PhMonth = parseInt(dateParts[1], 10);
                                const PhDay = parseInt(dateParts[2], 10);
    
                                $scope.txtMainDriverDOBDD = PhDay;
                                $scope.txtMainDriverDOBMON = $scope.staticData.datesMonths.find(month => month.key === PhMonth).value;
                                $scope.txtMainDriverDOBYear = $scope.staticData.datesYears.filter(year => year.key ===PhYear).map(year => `${year.value} (${year.valueTH})`).join();
                                $scope.mainDriver.DOB_DD = PhDay;
                                $scope.mainDriver.DOB_MON = PhMonth;
                                $scope.mainDriver.DOB_Year = PhYear;
                            }
                            else{
                                if(!$scope.mainDriver.DOB_DD && $scope.policyHolder.DOB_DD !== undefined){
                                    $scope.txtMainDriverDOBDD = $scope.policyHolder.DOB_DD;
                                    $scope.mainDriver.DOB_DD = $scope.policyHolder.DOB_DD;
                                }
                                if(!$scope.mainDriver.DOB_MON && $scope.policyHolder.DOB_MON !== undefined){
                                    $scope.mainDriver.DOB_MON = $scope.policyHolder.DOB_MON;
                                    $scope.txtMainDriverDOBMON = $scope.staticData.datesMonths.find(month => month.key === $scope.policyHolder.DOB_MON).value;
                                }
                                if(!$scope.mainDriver.DOB_Year && $scope.policyHolder.DOB_Year !== undefined){
                                    $scope.mainDriver.DOB_Year = $scope.policyHolder.DOB_Year;
                                    $scope.txtMainDriverDOBYear = $scope.staticData.datesYears.filter(year => year.key === $scope.policyHolder.DOB_Year).map(year => `${year.value} (${year.valueTH})`).join();
                                }
                            }

                            //Prefill MdGender
                            if(!$scope.mainDriver.GenderId && $scope.policyHolder.GenderId){
                                $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                                const mdGender = $scope.staticData.genders.find(w => w.key == $scope.mainDriver.GenderId);
                                if(mdGender) {
                                    $scope.txtMDGender = mdGender.value;
                                }
                            }

                            //Prefill MdMartialStatusId
                            if(!$scope.mainDriver.MaritalStatusId && $scope.policyHolder.MaritalStatusId){
                                $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                                const mdMaritalStatus = $scope.staticData.maritalStatuses.find(w => w.key == $scope.mainDriver.MaritalStatusId);
                                if(mdMaritalStatus) {
                                    $scope.txtMDMaritalStatus = mdMaritalStatus.value;
                                }
                            }

                            //Prefill MdOccupationId
                            if(!$scope.mainDriver.OccupationId && $scope.policyHolder.OccupationId){
                                $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                                const txtOccupationId = $scope.staticData.occupations.find(w => w.key == $scope.mainDriver.OccupationId).value;
                                if(txtOccupationId){
                                    $scope.txtOccupationId = txtOccupationId;
                                }
                            }

                            //Prefill NrOfClaimsInPeriodId
                            if(!$scope.mainDriver.NrOfClaimsInPeriodId && $scope.policyHolder.NrOfClaimsInPeriodId){
                                $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId;
                                const txtNrOfClaimsInPeriodId = $scope.staticData.nrOfClaimsInPeriods.find(w => w.key == $scope.mainDriver.NrOfClaimsInPeriodId)  
                                if(txtNrOfClaimsInPeriodId){
                                    $scope.txtNrOfClaimsInPeriodId = txtNrOfClaimsInPeriodId.value;
                                }
                            }

                            $scope.loadingSpinner = false;
                            $scope.loadingSpinner2 = false;
                            $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);


                            if ($scope.policyHolder.ConsentId == 7000000) {
                                $scope.asset.consentPrivacyFlag = true;
                            } 
                            else {
                                $scope.asset.consentPrivacyFlag = false;
                            }

                            if ($scope.policyHolder.IsMainDriver) {
                                fn.utils.copyPHFieldsToMD();
                                retrieveNamedDrivers();
                            }
                            else {
                                retrieveMainDriver();
                            }
                        },
                        onError: function (err) {
                            if(err) {
                                if(!hasError) {
                                    var error = err.ErrorInfo ? err.ErrorInfo : {fn: 'retrievePolicyHolder', method: 'fn.policyHolderApi.retrievePHPolicyContact'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 2542'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;
                            }
                            $scope.loadingSpinner = false;
                            $scope.loadingSpinner2 = false;
                            $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);
                        }
                    });
                };

                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact({
                        onSuccess: function (data) {

                            angular.extend($scope.mainDriver, data);

                            if (data && data.Identifier) {
                                HKFormValidation.splitIdentifier(data, $scope.mainDriver);
                            }
                            if ($scope.mainDriver.DateOfBirth) {
                                fn.utils.ConvertMITDateToDisplay();
                            }
                            if ($scope.asset.PlanId == $scope.PLANID_NAMED_DRIVER) {
                                retrieveNamedDrivers();
                            }
                        }
                    });
                };

                var retrieveNamedDrivers = function () {
                    var hasError = false;
                    fn.namedDriversApi.retrieveNDPolicyContacts(
                        {
                            onSuccess: function (dataArray) {
                                if (!dataArray) {
                                    return;
                                }

                                angular.forEach(dataArray,
                                    function (data) {
                                        data.isForUpdate = true;
                                    }
                                );

                                /** Need to enable this condition for VPP */
                                if (dataArray.length > 0) {
                                    $scope.dynamicForm.addedNamedDriver = true;
                                    $scope.dynamicForm.namedDriverAction = 'update';
                                }
                                /** End of condition */

                                $scope.namedDrivers.PolicyContacts.splice(0);
                                angular.extend($scope.namedDrivers.PolicyContacts, dataArray);
                                angular.forEach($scope.namedDrivers.PolicyContacts,
                                    function (obj) {
                                        if (obj.DateOfBirth) {
                                            fn.utils.ConvertNDMITDateToDisplay(obj);
                                        }

                                        $scope.namedDriver = obj;
                                        if ($scope.namedDriver.Identifier) {
                                            HKFormValidation.splitIdentifier(obj, $scope.namedDriver);
                                            /*To solve some problem on policyHolder scope*/
                                        }
                                    }
                                );
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveNamedDrivers', method: 'fn.namedDriversApi.retrieveNDPolicyContacts'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 2614'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;

                            }
                        }
                    );
                };


                retrieveCurrentProposal();
            },

            populateMaxar: function () {

                var date = new Date();
                var tmp = $scope.mainDriver.DateOfBirth.split("-");
                var dateOfBirthMaxar = tmp[2] + "-" + tmp[1] + "-" + tmp[0] + " 00:00";

                var getArrPolicyStartDate = $scope.currentProposal.PolicyStartDate.split("-")
                // var getPolicyStartDateMaxar = getArrPolicyStartDate[2] + "-" + getArrPolicyStartDate[1] + "-" + getArrPolicyStartDate[0] + " 00:00"

                var sd = '';
                let policyStartDate = "";
                try {
                    sd = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                    policyStartDate = getArrPolicyStartDate[2] + '-' + getArrPolicyStartDate[1] + "-" + getArrPolicyStartDate[0] + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                }
                catch (e) {
                    sd = "01-01-2017 00:00";
                    policyStartDate = "01-01-2017 00:00";
                }

                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                var startDate = sd;
                var yearManufacturer = $scope.asset.PurchasingYear;
                // var make = $scope.$parent.filterStatic($scope.staticData.makes, $scope.asset.ManufactureId);
                // var model = $scope.$parent.filterStatic($scope.staticData.models, $scope.asset.ModelId);
                // var description = $scope.$parent.filterDesc($scope.staticData.cardescriptions, $scope.asset.CarDescriptionId);
                var make = "";
                var model = "";
                var description = "";
                var getMake = $scope.staticData.makes.find(w => w.key == $scope.asset.ManufactureId);
                if(getMake){
                    make = getMake.value;
                }
                var getModel = $scope.staticData.models.find(w => w.key == $scope.asset.ModelId);
                if(getModel){
                    model = getModel.value;
                }

                var getDescription = $scope.staticData.cardescriptions.find(w => w.key == $scope.asset.CarDescriptionId);
                if(getDescription){
                    description = getDescription.description;
                }


                var dateBirth = dateOfBirthMaxar;
                // var gender = $scope.$parent.filterStatic($scope.staticData.genders, $scope.mainDriver.GenderId);
                // var maritalStatus = $scope.$parent.filterStatic($scope.staticData.maritalStatuses, $scope.mainDriver.MaritalStatusId);
                // var driveWork = $scope.$parent.filterStatic($scope.staticData.booleansSometimes, $scope.asset.InDriveWork)
                // var useWork = $scope.$parent.filterStatic($scope.staticData.booleansSometimes, $scope.asset.InCourseWork)
                var gender = "";
                var maritalStatus = "";
                var driveWork = "";
                var useWork = "";
                var getGender = $scope.staticData.genders.find(w => w.key == $scope.mainDriver.GenderId);
                if(getGender){
                    gender = getGender.value;
                }
                var getMaritalStatus = $scope.staticData.maritalStatuses.find(w => w.key == $scope.mainDriver.MaritalStatusId);
                if(getMaritalStatus){
                    maritalStatus = getMaritalStatus.value;
                }

                var getDriveWork = $scope.staticData.booleansSometimes.find(w => w.key == $scope.asset.InDriveWork);
                if(getDriveWork){
                    driveWork = getDriveWork.value;
                }

                var getUseWork = $scope.staticData.booleansSometimes.find(w => w.key == $scope.asset.InCourseWork);
                if(getUseWork){
                    useWork = getUseWork.value;
                }
                
                // new value sent to save to MAXAR
                // var getNCD = $scope.$parent.filterStatic($scope.staticData.ncdLevels,Number($scope.asset.NcdLevelId))
                // var getDrivingYears = $scope.$parent.filterStatic($scope.staticData.drivingExperiences,Number($scope.mainDriver.DrivingExperienceId))
                // var getNumAccidents = $scope.$parent.filterStatic($scope.staticData.nrOfClaimsInPeriods,Number($scope.mainDriver.NrOfClaimsInPeriodId))    
                var ncd = "";
                var drivingYears = "";
                var numAccidents = "";

                var getNCD = $scope.staticData.ncdLevels.find(w => w.key == $scope.asset.NcdLevelId);
                if(getNCD){
                    ncd = getNCD.value;
                }

                var getDrivingYears = $scope.staticData.drivingExperiences.find(w => w.key == $scope.mainDriver.DrivingExperienceId);
                if(getDrivingYears){
                    drivingYears = getDrivingYears.value;
                }

                var getNumAccidents = $scope.staticData.nrOfClaimsInPeriods.find(w => w.key == $scope.mainDriver.NrOfClaimsInPeriodId)    
                if(getNumAccidents){
                    numAccidents = getNumAccidents.value;
                }

                let getProvince = $scope.staticData.provincesByPostal.find(w => w.key == $scope.phAddress.MDProvinceId);
                if(getProvince){
                    $scope.getProvince = getProvince.value;

                }
                
                maxarLayer.push({
                    'startDate': startDate,
                    'yearManufacture': yearManufacturer,
                    'make': make,
                    'model': model,
                    'description': description,
                    'dateBirth': dateBirth,
                    'gender': gender,
                    'maritalStatus': maritalStatus,
                    'driveWork': driveWork,
                    'useWork': useWork,
                    'policyStartDate': policyStartDate,
                    'carPlateProvince': $scope.getCarPlateProvinceName,
                    'province': $scope.getProvince,
                    'ncd': ncd,
                    'drivingYears': drivingYears,
                    'numAccidents': numAccidents,
                });

                // 
                // *** START ***
                // 
                // Set all of value to send to "Review page" 
                // using MAXAR's value 
                // (Adding from "Policy_submission page")

                var firstObjToMaxar = {                           
                    'startDate': startDate,
                    'yearManufacture': yearManufacturer,
                    'make': make,
                    'model': model,
                    'description': description,
                    'dateBirth': dateBirth,
                    'gender': gender,
                    'maritalStatus': maritalStatus,
                    'driveWork': driveWork,
                    'useWork': useWork,
                    'policyStartDate': policyStartDate,
                    'carPlateProvince': $scope.getCarPlateProvinceName,
                    'ncd': ncd,
                    'drivingYears': drivingYears,
                    'numAccidents': numAccidents,
                }
                    
                $sessionStorage.getFirstObjToMX = firstObjToMaxar
                // *** END ***
                                
            },

            postToAWS: function (priority){
                
                const formatToUTC = (date) => {
                    const year = date.getUTCFullYear();
                    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
                    const day = String(date.getUTCDate()).padStart(2, '0');
                    const hours = String(date.getUTCHours()).padStart(2, '0');
                    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
                    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
                    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}+00:00`;
                }

                if($scope.currentProposal.ExternalProposalNumber && priority !== 17 && priority !== 22){
                    const foundConversionValue = $scope.staticData.conversionValues.filter(i => i.key == priority);
                    const payload = {
                        policy_number: $scope.currentProposal.ExternalProposalNumber,
                        priority: priority,
                        created_date: formatToUTC(new Date())
                    }
                    if(foundConversionValue.length > 0){
                        payload.value = foundConversionValue[0].value;
                    }
                    else{
                        payload.value = 678;
                    }

                    ExternalService.sendGoogleConversionAdjustment({
                        onSuccess: function (res) {
                            console.log("Send AWS Successfully");
                        },
                        onError: function (err) {
                            console.log("Failed to Send AWS");
                        }
                    }, {
                        httpMethod: "POST",
                        body: payload
                    })
                }
            },

            postToMaxParent: function (callBackStatus, Priority, FinalCall , errorLog) {
                            
                $sessionStorage.maxarReference = $scope.phContact.PrimaryPhoneNumber;

                var ref_id = '';
                $scope.firstName = '';
                $scope.lastName = '';
                var ed = '';
                var utmzz = ($cookies['__utmzz'] || '').split('|')
                    .map(string => string.split('='))
                    .reduce((map, [key, value]) => (map[key] = value, map), {})
                var ga_source = utmzz.utmcsr || '';
                var ga_campaign = utmzz.utmccn || '';
                var ga_medium = utmzz.utmcmd || '';

                var typeCoverage = '';            

                // 
                // new value sent to save to MAXAR (START)
                //

                // Red Plate
                if($scope.asset.IsRedPlate === true){
                    $scope.getVehicleRegisNumber = $scope.HKLocale.getLocale() == 'th-th' ? "ใหม่" : "New";
                }
                // White Plate
                if($scope.asset.IsRedPlate === false){
                    $scope.getVehicleRegisNumber = $scope.asset.VehicleRegistrationNumber
                }

                var getCarPlateNumber = $scope.getVehicleRegisNumber
                var getRedCarPlate = $scope.asset.IsRedPlate == false ? 'No' : 'Yes'
                var getArrPolicyStartDate = $scope.currentProposal.PolicyStartDate.split("-")
                var getPolicyStartDateMaxar = getArrPolicyStartDate[2] + "-" + getArrPolicyStartDate[1] + "-" + getArrPolicyStartDate[0] + " 00:00"

                var getWorkshop = 'Panel';
                if($scope.asset.UseMyWorkshop == false) { getWorkshop = 'Panel' }
                else { getWorkshop = 'Dealer' }

                var getCommercialUse = "No"
                            
                var getPostcode = $scope.phAddress.PostalCode

                var getHotLead,getDataFinal
                if ($scope.isHotLead) {
                    language = "th-hl"
                    getDataFinal = "Y"
                    getHotLead = 'hot quote'
                }
                else{                    
                    language = HKLocale.getLocale()
                    getDataFinal = FinalCall
                    getHotLead = ''
                }                

                // 
                // new value sent to save to MAXAR (END)
                // 
                
                
                //$scope.asset.LeadTypeId = 7000001;
                if (ga_source === 0 || ga_source === null || ga_source === "") ga_source = "-";
                if (ga_medium === 0 || ga_medium === null || ga_medium === "") ga_medium = "-";
                if (ga_campaign === 0 || ga_campaign === null || ga_campaign === "") ga_campaign = "-";

                if ($scope.coverages.Coverages !== undefined) {
                    if ($scope.coverages.Coverages.length > 0) {
                        for (var i = 0; i <= $scope.coverages.Coverages.length; i++) {
                            if ($scope.coverages.Coverages[i] && $scope.coverages.Coverages[i].IsSelected) {
                                typeCoverage = $scope.coverages.Coverages[i].Name;
                                break;
                            }
                        }
                    }
                    else {
                        return;
                    }
                } 
                else {
                    typeCoverage = 'none';
                }


                $window.maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;

                if(!$window.maxarLayer || ($window.maxarLayer && $window.maxarLayer.length === 0)){
                    $window.maxarLayer = [];
                }

                var date = new Date();
                var maxarLayer = $window.maxarLayer;

                if (!$scope.phContact.PrimaryPhoneNumber) {
                    ref_id = '0000000000';
                } 
                else {
                    ref_id = $scope.phContact.PrimaryPhoneNumber;
                }

                // get "FirstName" at "#policy_submission page"
                if (!$scope.policyHolder.FullName) {

                    $scope.firstName = '-';

                    if (!$scope.phContact.FirstName) {
                        $scope.firstName = '-';
                    } 
                    else {
                        $scope.firstName = $scope.phContact.FirstName;
                    }
                } 
                else {
                    const getSplitFullname = $scope.policyHolder.FullName.split(' ').map(element => element.trim()).filter(element => element !== '');    
                    $scope.firstName = getSplitFullname[0];
                    $scope.lastName = getSplitFullname[1] ? getSplitFullname[1] : '-';
                    $scope.getLastName = $scope.lastName;

                    $scope.policyHolder.FirstName = $scope.firstName
                    $scope.policyHolder.LastName = $scope.lastName
                }
                
                // get "LastName" start at "get-quote & your-quote"
                if(!$scope.getLastName) {
                    if($scope.mainDriver.LastName === undefined){
                        $scope.getLastName = "-"
                    }
                    // get "LastName" start at "#policy_submission page"
                    else{
                        $scope.getLastName = $scope.mainDriver.LastName
                    }    
                }
                
                let policyStartDate = "";
                try {
                    ed = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                    policyStartDate = getArrPolicyStartDate[2] + '-' + getArrPolicyStartDate[1] + "-" + getArrPolicyStartDate[0] + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                }
                catch (e) {
                    ed = "01-01-2017 00:00";
                    policyStartDate = "01-01-2017 00:00";
                }


                var b7_lead_type = "Quote lead B2C"  
                
                // set "Pre-quote B2C"
                if(Priority == 22){
                    b7_lead_type = "Pre-quote B2C";
                }

                var sourcePage = "Your Quote";
                if($sessionStorage.currentPage == 3){
                    sourcePage = "Policy Submission";
                }

                // Organic lead 
                var ck = $cookies["__utmzz"];

                if(ck == "utmcsr=google|utmcmd=organic|utmccn=(not set)|utmctr=(not provided)"){
                    ga_campaign = "Organic";
                    ga_source   = "Organic";
                    ga_medium   = "-";
                }
                //force set maxar workshop: "Panel" By Pu 20/07/2022 01:58 PM
                //getWorkshop = 'Panel';

                var gender = "";
                var maritalStatus = "";
                var carPlateProvinceName = "";
                var getGender = $scope.staticData.genders.find(w => w.key == $scope.mainDriver.GenderId);
                if(getGender){
                    gender = getGender.value;
                }

                var getMaritalStatus = $scope.staticData.maritalStatuses.find(w => w.key == $scope.mainDriver.MaritalStatusId);
                if(getMaritalStatus){
                    maritalStatus = getMaritalStatus.value;
                }

                var getCarPlateProvinceName = $scope.staticData.provinces.find(w => w.key == $scope.policyHolder.CarPlateProvinceID);
                if(getCarPlateProvinceName){
                    carPlateProvinceName = getCarPlateProvinceName.value;
                }

                let deductible = "";
                if($scope.selectedCoverage && $scope.selectedCoverage.ExcessLevelId) {
                    let excess = $scope.selectedCoverage.excess.find(w => w.level == $scope.selectedCoverage.ExcessLevelId);
                    if(excess) {
                        deductible = excess.amount;
                    }

                }
                let plan = "";
                if($scope.asset && $scope.asset.PlanId){
                    if($scope.asset.PlanId == '1000003') {
                        plan = "VALUE PLAN";
                    } else if($scope.asset.PlanId == '1000004'){
                        plan = "FLEXIBLE PLAN";
                    } else {
                        plan = "VALUE PLUS PLAN";
                    }
                }

                maxarLayer.push({                           
                    'typeCoverage': Priority == '8' ? '' : typeCoverage,
                    'compulsory': $scope.compulsory.isIncluded,
                    'totalPremium': Priority == '8' ? 0 : $scope.totalPremium2,
                    'consent': $scope.asset.consentPrivacyFlag,
                    'referenceID': ref_id,
                    'endDate': ed,
                    'firstName': $scope.firstName ? $scope.firstName : '-',
                    'lastName': $scope.lastName ? $scope.lastName : '-',
                    'gender': gender,
                    'maritalStatus': maritalStatus,
                    'email': $scope.phContact.PrimaryEmailAddress ? $scope.phContact.PrimaryEmailAddress : '-',
                    'telephone': ref_id,
                    'type': 'Quote lead',
                    'sourcePage': sourcePage,
                    'language': language,
                    'callback': callBackStatus,
                    'priority': Priority,
                    'dataFinal': getDataFinal,

                    //------- Modify for new maxar prioritize------------------
                    'quoteNumber': Priority == '8' ? '' : $scope.currentProposal.ExternalProposalNumber,
                    'sourceChannel': ga_source,
                    'channelDetail': ga_medium,
                    'gaCampaign': ga_campaign,

                    'blank2': getHotLead,
                    'blank7': b7_lead_type,
                    'workshop': getWorkshop,

                    'monthlySinglePayment': $scope.txtPaymentTerms,
                    'deductible': deductible,
                    'plan': plan,

                    'policyStartDate': policyStartDate,
                    // 'policyStartDateMaxar': getPolicyStartDateMaxar,
                    'previousInsurer': '-',
                    'carPlateNumber': getCarPlateNumber ? getCarPlateNumber : '-',
                    'redCarPlate': getRedCarPlate,
                    'carPlateProvince': carPlateProvinceName,
                    'commercialUse': getCommercialUse,
                    'postcode': getPostcode,
                    'occupation': $scope.getOccupation,
                    'district': $scope.getDistrict,
                    'province': $scope.getProvince,
                    'mainDriverPolicyholder': $scope.getMainDriverPolicyholder,
                    'carcamera': $scope.asset.IsCarCameraInstalled,
                    'otherDrivers': $scope.getOtherDrivers,

                    'blank10': errorLog ? JSON.stringify(errorLog) : ''
                })

                $localStorage.priority = Priority;
                $localStorage.maxarLayer = maxarLayer;
                
                // 
                // *** START ***
                // 
                // Set all of value to send to "Review page" 
                // using MAXAR's value 
                // (Adding from "Policy_submission page")
                //force set maxar workshop: "Panel" By Pu 20/07/2022 01:58 PM
                //getWorkshop = 'Panel';
                var secondObjToMaxar = {                           
                    'typeCoverage': Priority == '8' ? '' : typeCoverage,
                    'compulsory': $scope.compulsory.isIncluded,
                    'totalPremium': Priority == '8' ? 0 : $scope.totalPremium2,
                    'consent': $scope.asset.consentPrivacyFlag,
                    'referenceID': ref_id,
                    'endDate': ed,
                    'firstName': $scope.firstName,
                    'lastName': $scope.lastName,
                    'email': $scope.phContact.PrimaryEmailAddress,
                    'gender': gender,
                    'maritalStatus': maritalStatus,
                    'telephone': ref_id,
                    'type': 'Quote lead',
                    'sourcePage': sourcePage,
                    'language': language,
                    'callback': callBackStatus,
                    'priority': Priority,
                    'dataFinal': getDataFinal,                    
                    'quoteNumber': Priority == '8' ? '' : $scope.currentProposal.ExternalProposalNumber,
                    'sourceChannel': ga_source,
                    'channelDetail': ga_medium,
                    'gaCampaign': ga_campaign,
                    'blank2': getHotLead,
                    'blank7': b7_lead_type,
                    'workshop': getWorkshop,

                    'monthlySinglePayment': $scope.txtPaymentTerms,
                    'deductible': deductible,
                    'plan': plan,

                    'policyStartDate': policyStartDate,
                    // 'policyStartDateMaxar': getPolicyStartDateMaxar,
                    'previousInsurer': '-',
                    'carPlateNumber': getCarPlateNumber,
                    'redCarPlate': getRedCarPlate,
                    'carPlateProvince': carPlateProvinceName,
                    'commercialUse': getCommercialUse,
                    'postcode': getPostcode,
                    'occupation': $scope.getOccupation,
                    'district': $scope.getDistrict,
                    'province': $scope.getProvince,                        
                    'mainDriverPolicyholder': $scope.getMainDriverPolicyholder,
                    'carcamera': $scope.asset.IsCarCameraInstalled,
                    'otherDrivers': $scope.getOtherDrivers,

                    'blank10': errorLog ?  JSON.stringify(errorLog) : ''

                }

                $sessionStorage.getSecondObjToMX = secondObjToMaxar
                // *** END ***
                //5.P22 -> QuoteNumber null or undefined not Send
                if($scope.currentProposal.ExternalProposalNumber && $scope.currentProposal.ExternalProposalNumber != undefined) {
                    $scope.mtNumber = false;
                    
                    fn.eventHandlers.postMaxar();
                } else {
                    $scope.mtNumber = true;
                    if(Priority === 22 || Priority === 8 ){
                        fn.eventHandlers.postMaxar();
                    }
                   
                   // console.log('P22 -> Method postToMaxParent is not Send and Check quoteNumber = ' + $scope.currentProposal.ExternalProposalNumber);
                }
            },

            setAssetMaxarPriority: function (action) {

                var cbFlag, finalDataFlag;
                if (action == "cb") {
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                } else if (action == "s&e") {
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                } else {
                    cbFlag = 'No';
                    finalDataFlag = 'N'
                }
                //DATH-1137
                var firstStar, secondStar;
                if (cbFlag == 'No') {
                    firstStar = "";
                } else {
                    firstStar = "*";
                }
                if (finalDataFlag == 'Y') {
                    secondStar = "*";
                } else {
                    secondStar = "";
                }
                $scope.asset.MaxarPriority = $scope.newMaxarPrior + firstStar + secondStar;
            },

            // Customize normal flow to compatible with SPA
            updatesCoveragesSPA: function (compulsoryIsIncluded,isDeduct, firstCal) {
                var hasError = false;
                fn.coverageApi.retrieveCoverages({
                    onSuccess: function (data) {
                        if(data && data.Coverages && data.Coverages.length > 0) {
                            data.Coverages.map((d) => {
                                d.compulsoryOriginal = fn.utils.getCompulsory(d.Covers,d.ProductId,compulsoryIsIncluded);
                            });    
                            if(isDeduct) {
                                // if(firstCal && firstCal !== "No"){
                                //     $scope.setCompulsory(compulsoryIsIncluded,'firstCal');
                                // }
                                $scope.setCompulsory(compulsoryIsIncluded,firstCal && firstCal == "No" ? firstCal :'firstCal');

                                data.Coverages = $scope.coverages.Coverages;      

                            } else {
                                $scope.coverages.Coverages = data.Coverages;      

                            }
                             
                            var getCoverages = $scope.coverages.Coverages.find(w => w.CoverTypeId == true);
                            if(getCoverages && getCoverages.PaymentSchedule && getCoverages.PaymentSchedule.length > 0) {
                                $scope.getFirstPaymentToShow = getCoverages.PaymentSchedule[0].Amount
                                $scope.getMonthlyPremiumToShow = getCoverages.PaymentSchedule.length > 1 ? getCoverages.PaymentSchedule[1].Amount : 0.00
                                $sessionStorage.toSetFirstPaymentToShow = $scope.getFirstPaymentToShow
                                $sessionStorage.toSetMonthlyPremiumToShow = $scope.getMonthlyPremiumToShow
                            }

                            

                        }
                        if (data.CoverListsCount == 5 || data.CoverListsCount == 10) {
                            $scope.tyepOfCoverageTable = {
                                "b2c_popup_cover_item1": [true, true, false, false],
                                "b2c_popup_cover_item2": [true, true, true, true],
                                "b2c_popup_cover_item3": [true, false, true, false],
                                "b2c_popup_cover_item4": [true, false, false, false],
                                "b2c_popup_cover_item5": [false, false, false, false],
                                "b2c_popup_cover_item6": [true, true, false, false]
                            };
                        } 
                        else {

                            let selectedCoverage = $filter('filter')($scope.coverages.Coverages, { IsSelected: true })
                            if(selectedCoverage && selectedCoverage.length > 0) {
                                selectedCoverage = selectedCoverage[0]
                                if(selectedCoverage && selectedCoverage.CoverTypeId) {
                                    $scope.getMyCoverTypeId = selectedCoverage.CoverTypeId
                                }
                            }  
                            else
                            {
                            selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId : $scope.TYPE2PLUS_FALCON_DG })
                                if(selectedCoverage && selectedCoverage.length > 0) {
                                    selectedCoverage = selectedCoverage[0]
                                    selectedCoverage.IsSelected = true
                                    $scope.getMyCoverTypeId = selectedCoverage.CoverTypeId
                                }            
                            }

                            const firstCoverage = $scope.coverages.Coverages[0];

                            const typeThree = $filter('filter')($scope.coverages.Coverages, { CoverTypeId : $scope.TYPE3_FALCON_DG }).length > 0;
                            const typeThreePlus = $filter('filter')($scope.coverages.Coverages, { CoverTypeId : $scope.TYPE3PLUS_FALCON_DG }).length > 0;
    
                            if(selectedCoverage && selectedCoverage.CoverTypeId) {
                                // Coverage Table ===> T1, T1S ,T2+, T3+, T2, T3,;
                                if (firstCoverage.CoverTypeId == $scope.TYPE1_MTI_D || firstCoverage.CoverTypeId == $scope.TYPE1_MTI_G) {    
                                    $scope.tyepOfCoverageTable = {
                                        "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                        "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                        "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                        "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                        "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                        "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                        "b2c_popup_cover_item7": [true ,false , false, false, false, false],
                                        "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                    };
                                } else if (firstCoverage.CoverTypeId == $scope.TYPE1_AXA_D || firstCoverage.CoverTypeId == $scope.TYPE1_AXA_G){
                                    $scope.tyepOfCoverageTable = {
                                        "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                        "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                        "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                        "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                        "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                        "b2c_popup_cover_item6_axa": [true ,false , false, false, false, false],
                                        "b2c_popup_cover_item7": [true ,false , false, false, false, false],
                                        // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                    };
                                }

                                else if(firstCoverage.CoverTypeId == $scope.TYPE1_FALCON_DG && !typeThree && !typeThreePlus){
                                    $scope.tyepOfCoverageTable = {
                                        // Coverage Table ===> T1s, T1, T2+, T2,;
                                        "b2c_popup_cover_item1": [true ,true , true, false, false, false],
                                        "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                        "b2c_popup_cover_item3": [true ,true , true, true, true, false],
                                        "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                        "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                        "b2c_popup_cover_item6": [true ,true , true, false, false, false]
                                        
                                }
                            }
                                else if(firstCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG && typeThreePlus)
                                    {
                                        $scope.tyepOfCoverageTable = {
                                            // Coverage Table ===> T2+, T3+, T2, T3,;
                                            "b2c_popup_cover_item1": [true ,true , false, false, false, false],
                                            "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                            "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                            "b2c_popup_cover_item4": [true ,false , false, false, false, false],
                                            "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                            "b2c_popup_cover_item6": [true ,true , false, false, false, false]
                                            // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                        };
                                    }
                                    else if(firstCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG && !typeThreePlus){
                                        $scope.tyepOfCoverageTable = {
                                            // Coverage Table ===> T2+, T3;
                                            "b2c_popup_cover_item1": [true ,false , false, false, false, false],
                                            "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                            "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                            "b2c_popup_cover_item4": [true ,false , false, false, false, false],
                                            "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                            "b2c_popup_cover_item6": [true ,false , false, false, false, false]
                                            // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                        };
                                    }
                                    else if (firstCoverage.CoverTypeId == $scope.TYPE2_FALCON_DG){
                                        $scope.tyepOfCoverageTable = {
                                            // Coverage Table ===> T2;
                                            "b2c_popup_cover_item1": [false ,false , false, false, false, false],
                                            "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                            "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                            "b2c_popup_cover_item4": [false ,false , false, false, false, false],
                                            "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                            "b2c_popup_cover_item6": [false ,false , false, false, false, false]
                                            // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                        };
                                    }
                                    else if(firstCoverage.CoverTypeId == $scope.TYPE3_FALCON_DG)
                                        {
                                            $scope.tyepOfCoverageTable = {
                                                // Coverage Table ===> T3,;
                                                "b2c_popup_cover_item1": [false ,true , true, true, false, false],
                                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                "b2c_popup_cover_item3": [false ,false , true, false, true, false],
                                                "b2c_popup_cover_item4": [false ,false , false, false, false, false],
                                                "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                                "b2c_popup_cover_item6": [false ,true , false, false, false, false]
                                                // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                            };
                                        }
                                
                                
                                else {
                                    $scope.tyepOfCoverageTable = {
                                        "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                        "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                        "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                        "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                        "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                        "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                        };
    
                                }
                            } else {
                                $scope.tyepOfCoverageTable = {
                                    "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                    "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                    "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                    "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                    "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                    "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                };
                            }
                        }

                        angular.extend($scope.coverages, data);
                        if($scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0) {
                            $scope.currentProposal.Assets[0].Coverages = $scope.coverages.Coverages;
                        }                        
                        if($scope.asset) {
                            $scope.asset.Coverages = $scope.coverages.Coverages;
                        }


                        $scope.$parent.PlanId = $scope.asset.PlanId;
                        $scope.compulsory.isIncluded = fn.utils.IsCompulsarySelectedFrontEndFn();
                        var selectedCoverage = fn.utils.getSelectedCoverage(compulsoryIsIncluded);

                        if (selectedCoverage) {
                            fn.utils.setSelectedCoverage(selectedCoverage);
                            retrieveExcess();

                        }
                        fn.utils.setSumInsured();

                        $scope.coverages.isForUpdate = true;
                        if (selectedCoverage) {
                            if (selectedCoverage.CoverTypeId == $scope.TYPECOMPULSORY_FALCON_DG) {
                                $scope.isCompulsoryOnly = true;
                            } 
                            else {
                                $scope.isCompulsoryOnly = false;
                            }
                        } 
                        else {
                            $scope.isCompulsoryOnly = false;
                        }
                        // retrieveExcess();
                    },
                    onError: function (err) {
                        if(!hasError) {
                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updatesCoveragesSPA', method: 'fn.coverageApi.retrieveCoverages'} ;
                            var errorLog = {err: JSON.stringify(error), case: 'error : line 3139'};
                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                            fn.postToMaxParent('No', priority, 'N', errorLog)
                        }
                        hasError = true;

                        retrieveExcess();
                    }
                });

                var retrieveExcess = function () {
                    var hasError = false;

                    ApiServiceV2.getVehicleExcess({
                        onSuccess: function (data) {
                            if (data && data.length > 0) {
                                data.sort((n1,n2) => {
                                    if (n1.amount > n2.amount) {
                                        return 1;
                                    }
                                
                                    if (n1.amount < n2.amount) {
                                        return -1;
                                    }
                                
                                    return 0;
                                });
    
                                data.map((dt) =>{
                                    dt.key = dt.level.toString();
                                    dt.value = Number(dt.amount) + '.00';
                                })
                                if (data[0] && data[0].value) {
                                    $scope.allExcess = data[0].value;
                                    $scope.selectedCoverage.excess = data;        
                                    
                                    for (var i in $scope.selectedCoverage.Covers) {
                                        if($scope.selectedCoverage.ProductId  == $scope.PRODUCT_ID_DA){
                                            if ($scope.selectedCoverage.Covers[i].ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {
                                                $scope.ExcessLevelId = $scope.selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = $scope.ExcessLevelId;
                                            }    
                                        } else if($scope.selectedCoverage.ProductId  == $scope.PRODUCT_ID_AXA){
                                            if ($scope.selectedCoverage.Covers[i].ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA) {
                                                $scope.ExcessLevelId = $scope.selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = $scope.ExcessLevelId;
                                            }    
                                        }
        
                                    }        
                                }
                            }
                            $scope.loadingSpinner = false;
                            $scope.loadingSpinner2 = false;
                            $scope.loadingSpinner3 = false;
                            $scope.$parent.showPriceOnTopFlag = true;
                            $scope.allowBtnAction = true;                                       
                        },
                        onError: function (err) {

                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveExcess', method: 'ApiServiceV2.getVehicleExcess'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 3200'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;

                        }
                    }, 'VEHICLE_EXCESS', `formulaId=${$scope.selectedCoverage.CoverTypeId}&productVersionId=${$scope.selectedCoverage.ProductVersionId}`);

                };
            },

            utils: {
                setSumInsured: function (isCompulsory) {

                    $scope.sumInsured.value = 0;
                    $scope.sumInsured.visible = false;
                    var sc = fn.utils.getSelectedCoverage(isCompulsory);
 
                    if ((sc !== undefined) && (sc.PlanId !== $scope.asset.PlanId)) {

                        sc = fn.utils.getSelectedCoverage(isCompulsory)
                    }
                    
                    if (!sc) { return }
                    
                    if (sc.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG || sc.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG || sc.CoverTypeId == $scope.TYPE1_FALCON_DG ||
                        sc.CoverTypeId == $scope.TYPE1SAVE_FALCON_DG ) {                        

                        var odc = fn.utils.getCoverOwnDamageByProductId(sc);

                        if (odc && odc.length > 0) {
                            odc = odc[0]
                            if(odc && odc.InsuranceAmount) {
                                $scope.sumInsured.value = odc.InsuranceAmount
                                $scope.sumInsured.visible = true    
                            } else {
                                $scope.sumInsured.value = 0
                                $scope.sumInsured.visible = false    
                            }
                        }
                        else {
                            $scope.sumInsured.value = 0
                            $scope.sumInsured.visible = false
                        }
                        return $scope.sumInsured.value,$scope.sumInsured.visible
                    }

                    // value from own damage collision
                    if (sc.CoverTypeId == $scope.TYPE1_MTI_G || sc.CoverTypeId == $scope.TYPE1_MTI_D ) {                        

                        var odc = fn.utils.getCoverOwnDamageByProductId(sc);

                        if (odc && odc.length > 0) {
                            odc = odc[0]
                            if(odc && odc.InsuranceAmount) {
                                $scope.sumInsured.value = odc.InsuranceAmount
                                $scope.sumInsured.visible = true    
                            } else {
                                $scope.sumInsured.value = 0
                                $scope.sumInsured.visible = false
                            }
                        }
                        else {
                            $scope.sumInsured.value = 0
                            $scope.sumInsured.visible = false
                        }
                        return $scope.sumInsured.value,$scope.sumInsured.visible
                    }

                    // value from own damage collision
                    if (sc.CoverTypeId == $scope.TYPE1_AXA_G || sc.CoverTypeId == $scope.TYPE1_AXA_D ) {                        

                        var odc = fn.utils.getCoverOwnDamageByProductId(sc);
                        if (odc && odc.length > 0) {
                            odc = odc[0]
                            if(odc && odc.InsuranceAmount) {
                                $scope.sumInsured.value = odc.InsuranceAmount
                                $scope.sumInsured.visible = true    
                            } else {
                                $scope.sumInsured.value = 0
                                $scope.sumInsured.visible = false
                            }
                        }
                        else {
                            $scope.sumInsured.value = 0
                            $scope.sumInsured.visible = false
                        }
                        return $scope.sumInsured.value,$scope.sumInsured.visible
                    }


                    // own damage Fire and theft value
                    if (sc.CoverTypeId == $scope.TYPE2_FALCON_DG ) {

                        var odft = fn.utils.getCoverOwnDamageFireByProductId(sc);

                        if (odft && odft.length > 0) {
                            odft = odft[0]
                            if(odft && odft.InsuranceAmount) {
                                $scope.sumInsured.value = odft.InsuranceAmount
                                $scope.sumInsured.visible = true    
                            } else {
                                $scope.sumInsured.value = 0
                                $scope.sumInsured.visible = false
                            }
                        }
                        else {
                            $scope.sumInsured.value = 0
                            $scope.sumInsured.visible = false
                        }
                        return $scope.sumInsured.value,$scope.sumInsured.visible
                    }

                    // T3 is always NA and hidden
                    if (sc.CoverTypeId == $scope.TYPE3_FALCON_DG) {
                        $scope.sumInsured.visible = false
                        $scope.sumInsured.value = 0
                        return $scope.sumInsured.value,$scope.sumInsured.visible
                    }

                    // Compulsory only
                    if (sc.CoverTypeId == $scope.TYPECOMPULSORY_FALCON_DG) {
                        $scope.sumInsured.visible = false
                        $scope.sumInsured.value = 0
                        return $scope.sumInsured.value,$scope.sumInsured.visible
                    }

                    // Exception- Everything fails- hide
                    $scope.sumInsured.visible = false
                    $scope.sumInsured.value = 0
                },

                setSelectedCoverage: function (selectedCoverage) {
                    $scope.selectedCoverage = selectedCoverage;
                    $scope.isBtnProceedDisabled = false;
                    $scope.isCoverSelected = true;
                },

                setDefaultCoverage: function () {
                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: $scope.TYPE1_FALCON_DG, PlanId: $scope.asset.PlanId });
                    if (selectedCoverage && selectedCoverage.length > 0) {
                        selectedCoverage = selectedCoverage[0]
                        if(selectedCoverage && selectedCoverage.CoverTypeId) {
                            selectedCoverage.IsSelected = true;
                            $scope.currentSelectedCoverage = selectedCoverage.CoverTypeId;
                            marketing.dLCE(10, 'Voluntary Type 1');
                            marketing.dLCE(11, true);    
                        }
                    } else {
                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: $scope.TYPE2PLUS_FALCON_DG, PlanId: $scope.asset.PlanId });
                        if(selectedCoverage && selectedCoverage.length > 0) {
                            selectedCoverage = selectedCoverage[0]
                            if(selectedCoverage && selectedCoverage.CoverTypeId){
                                $scope.currentSelectedCoverage = selectedCoverage.CoverTypeId;
                                selectedCoverage.IsSelected = true;
                                marketing.dLCE(10, 'Voluntary Type 2+');
                                marketing.dLCE(11, true);
        
                            }
                        }
                    }
                },

                IsCompulsarySelectedFrontEndFn: function () {
                    if (!$scope.coverages) return false;
                    var status = false;
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {

                        try {
                            var ccb = Utils.getCoverComplusoryBasicCoverByProductId($scope.coverages.Coverages[i]);
                            if (ccb) {
                                if (ccb[0].IsSelected) status = true;
                                else status = false;
                            }

                        }
                        catch (err) {
                            status = false; return status;
                        }
                    }
                    return status;
                },

                getSelectedCoverage: function (compulsoryIsIncluded) {

                    if (!$scope.coverages) { return }
                    if (!$scope.coverages.Coverages) { return }
                    
                    var selectedCoverage, getCoverageID

                    try {
                        selectedCoverage = $scope.coverages.Coverages.filter(w => w.IsSelected == true)
                        if(selectedCoverage && selectedCoverage.length > 1) {
                            $scope.coverages.Coverages.map((data) => {
                                if(data.CoverTypeId != selectedCoverage[0].CoverTypeId) {
                                    data.IsSelected = false;
                                }
                            })        
                        }
                        if(selectedCoverage && selectedCoverage.length > 0) {
                            selectedCoverage = selectedCoverage[0]
                            if(selectedCoverage && selectedCoverage.CoverTypeId) {
                                getCoverageID = selectedCoverage.CoverTypeId
                            }
                        }
    
                        let selectedCoveragePrev = $scope.selectedCoverage;
                        $scope.selectedCoverage = $scope.coverages.Coverages.filter(w => w.CoverTypeId == getCoverageID && w.IsSelected == true)
                        if($scope.selectedCoverage && $scope.selectedCoverage.length > 0) {
                            $scope.selectedCoverage = $scope.selectedCoverage[0];
                            if(selectedCoveragePrev && selectedCoveragePrev.excess) {
                                $scope.selectedCoverage.excess = selectedCoveragePrev.excess;
                                $scope.selectedCoverage.ExcessLevelId = selectedCoveragePrev.ExcessLevelId;
                                $scope.selectedCoverage.ExcessAmount = selectedCoveragePrev.ExcessAmount;
                            }        
                            if($scope.selectedCoverage){
                                selectedCoverage = $scope.selectedCoverage
                            }
                        }
    
                    }

                    catch(err){
                        selectedCoverage = $scope.coverages.Coverages.filter(w => w.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG)
                        if(selectedCoverage && selectedCoverage.length > 0) {
                            selectedCoverage = selectedCoverage[0]
                            if(selectedCoverage && selectedCoverage.CoverTypeId) {
                                selectedCoverage.IsSelected = true
                                getCoverageID = selectedCoverage.CoverTypeId
                            }
                        }
                    }

                    return selectedCoverage

                },
                getCompulsory: function (covers,productKey,compulsoryIsIncluded) {
                    let compulsoryList = [$scope.PRODUCT_ID_MTI];
                    let compulsory = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA || w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI || w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA);
                    if(compulsory) {
                      return {checked: compulsory.IsSelected, allow: compulsoryList.some(w => w == productKey) ? false : true, isFontEndForce : $scope.compulsory.isFontEndForce};
                    }
                    return {checked: true, allow: true, isFontEndForce : false};
                },
                /* Doesn't use now */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                autoSetEndDate: function () {

                    if (!$scope.currentProposal.PolicyStartDate) {
                        return;
                    }
                    var startDate = MITDate.newDate($scope.currentProposal.PolicyStartDate);
                    var endDate = startDate.add(366, 'days');
                    $scope.currentProposal.PolicyEndDate = MITDate.toAngularDate(endDate);
                },

                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                },
                clearLoading: function () {
                    $scope.loadingSpinner = false;
                    $scope.loadingSpinner2 = false;
                    $scope.loadingSpinner3 = false;    
                },
                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    $scope.policyHolder.OccupationId = $scope.mainDriver.OccupationId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;
                    $scope.policyHolder.DOB_DD = $scope.mainDriver.DOB_DD;
                    $scope.policyHolder.DOB_MON = $scope.mainDriver.DOB_MON;
                    $scope.policyHolder.DOB_Year = $scope.mainDriver.DOB_Year;
                    $scope.policyHolder.FirstName = $scope.mainDriver.FirstName; //Old
                    $scope.policyHolder.LastName = $scope.mainDriver.LastName; //Old

                    $scope.policyHolder.Identifier = $scope.mainDriver.Identifier;
                    $scope.policyHolder.ResidentialStatusId = $scope.mainDriver.ResidentialStatusId;
                    if ($scope.mainDriver.ResidentialStatusId == 2000001) {
                        $scope.policyHolder.NationalityId = $scope.mainDriver.NationalityId;
                    }

                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    $scope.mainDriver.DOB_DD = $scope.policyHolder.DOB_DD;
                    $scope.mainDriver.DOB_MON = $scope.policyHolder.DOB_MON;
                    $scope.mainDriver.DOB_Year = $scope.policyHolder.DOB_Year;
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;

                    if($scope.policyHolder.FullName) {
                        const getSplitFullname = $scope.policyHolder.FullName.split(' ').map(element => element.trim()).filter(element => element !== '');    
                        $scope.firstName = getSplitFullname[0];
                        $scope.lastName = getSplitFullname[1] ? getSplitFullname[1] : '-';
                    }
                    $scope.mainDriver.FirstName = $scope.firstName;
                    $scope.mainDriver.LastName = $scope.lastName;
                    $scope.mainDriver.ResidentialStatusId = $scope.policyHolder.ResidentialStatusId;
                    $scope.mainDriver.Identifier = $scope.policyHolder.Identifier;
                    $scope.mainDriver.NationalityId = $scope.policyHolder.NationalityId;
                    $scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId
                    fn.utils.ConvertMITDateToDisplay();
                },

                convertMITDate: function () {
                    if (typeof $scope.mainDriver.DOB_DD !== "undefined" &&
                        typeof $scope.mainDriver.DOB_MON !== "undefined" &&
                        typeof $scope.mainDriver.DOB_Year !== "undefined") {

                        $scope.$parent.setDateValidity($scope.mainDriver.DOB_DD, $scope.mainDriver.DOB_MON, $scope.mainDriver.DOB_Year, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.mainDriver.DateOfBirth = $scope.$parent.convertDateThreeToOne($scope.mainDriver.DOB_DD, $scope.mainDriver.DOB_MON, $scope.mainDriver.DOB_Year);
                        $scope.mainDriver.DateOfBirth == null ? ($scope.mainDriver.DateOfBirth = '') : '';
                        $scope.validations.isValidDriverAge($scope.mainDriver.DateOfBirth, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.DOBEmpty = false;
                    }
                },

                convertMITDateClick: function (e , param) {
                    $scope.isClickBirthDateOfYear = false;
                    if(param == 'd') {
                        $scope.txtMainDriverDOBDD = e.target.textContent.trim();
                        $scope.mainDriver.DOB_DD = e.target.dataset.key;
                    } else if(param == 'm') {
                        $scope.txtMainDriverDOBMON = e.target.textContent.trim();
                        $scope.mainDriver.DOB_MON = e.target.dataset.key;
                    } else if(param == 'y') {
                        $scope.txtMainDriverDOBYear = e.target.textContent.trim();
                        $scope.mainDriver.DOB_Year = e.target.dataset.key;
                        $scope.isClickBirthDateOfYear = true;
                    }

                    if (typeof $scope.mainDriver.DOB_DD !== "undefined" &&
                        typeof $scope.mainDriver.DOB_MON !== "undefined" &&
                        typeof $scope.mainDriver.DOB_Year !== "undefined") {

                        var adjustedDate = new Date($scope.mainDriver.DOB_Year, $scope.mainDriver.DOB_MON - 1, $scope.mainDriver.DOB_DD);
                        
                        $scope.mainDriver.DOB_DD = adjustedDate.getDate();
                        $scope.mainDriver.DOB_MON = adjustedDate.getMonth() + 1;
                        $scope.mainDriver.DOB_Year = adjustedDate.getFullYear();
                        $scope.txtMainDriverDOBDD = $scope.mainDriver.DOB_DD;
                        $scope.txtMainDriverDOBMON = $scope.staticData.datesMonths.find(month => month.key === $scope.mainDriver.DOB_MON).value;
                        $scope.txtMainDriverDOBYear = $scope.staticData.datesYears.filter(year => year.key === $scope.mainDriver.DOB_Year).map(year => `${year.value} (${year.valueTH})`).join();

                        $scope.$parent.setDateValidity($scope.mainDriver.DOB_DD, $scope.mainDriver.DOB_MON, $scope.mainDriver.DOB_Year, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.mainDriver.DateOfBirth = moment()
                            .year($scope.mainDriver.DOB_Year)
                            .month($scope.mainDriver.DOB_MON - 1)
                            .date($scope.mainDriver.DOB_DD)
                            .format('YYYY-MM-DD');
                        $scope.validations.isValidDriverAge($scope.mainDriver.DateOfBirth, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.DOBEmpty = false;

                        $scope.txtMdDob = $scope.mainDriver.DateOfBirth;
                        marketing.pushDataLayerMessage('pushMainDriverDob', marketing.dataLayerMessages.selectMainDriverDob);
                    }
                    if(param == 'd') {
                        fn.eventHandlers.showCalendarBirthDateMONABTesting(e);
                    } else if(param == 'm') {
                        fn.eventHandlers.showCalendarBirthDateYearABTesting(e);
                    } else if(param == 'y') {
                        fn.eventHandlers.showGenderDropdown(e);
                    }
                },
                convertPHMITDate: function () {
                    if (typeof $scope.policyHolder.DOB_DD !== "undefined" &&
                        typeof $scope.policyHolder.DOB_MON !== "undefined" &&
                        typeof $scope.policyHolder.DOB_Year !== "undefined") {

                        $scope.$parent.setDateValidity($scope.policyHolder.DOB_DD, $scope.policyHolder.DOB_MON, $scope.policyHolder.DOB_Year, $scope.frmAboutPH.policyHolderDOBDD);
                        $scope.policyHolder.DateOfBirth = $scope.$parent.convertDateThreeToOne($scope.policyHolder.DOB_DD, $scope.policyHolder.DOB_MON, $scope.policyHolder.DOB_Year);
                        $scope.policyHolder.DateOfBirth == null ? ($scope.policyHolder.DateOfBirth = '') : '';
                        $scope.DOBEmpty = false;
                    }
                },

                convertPolicyStartMITDate: function (e,param) {
                    var policyStartDate = $("#currentProposalPolicyStartDate").val();
                    var policyStartDateSplit = '';
                    if(policyStartDate) {
                        policyStartDateSplit = policyStartDate.split('/');
                        if(policyStartDateSplit && policyStartDateSplit.length > 0) {
                            $scope.currentProposal.PolicyStartDate_DD = Number(policyStartDateSplit[0]);
                            $scope.currentProposal.PolicyStartDate_MON = Number(policyStartDateSplit[1]);
                            $scope.currentProposal.PolicyStartDate_Year = Number(policyStartDateSplit[2]);
                            
                        } else {
                            if($scope.currentProposal.PolicyStartDate_DD) {
                                $scope.currentProposal.PolicyStartDate_DD = $scope.currentProposal.PolicyStartDate_DD - 1;
                            }
                        }
                    }

                    if (typeof $scope.currentProposal.PolicyStartDate_DD !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_MON !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_Year !== "undefined") {
                            if ($scope.policyStartDate == false){
                                $scope.currentProposal.PolicyStartDateFirst = $scope.currentProposal.PolicyStartDate;
                            }
                            $scope.policyStartDate = true;
                            if($scope.currentProposal.PolicyStartDateFirst !== $scope.currentProposal.PolicyStartDate && param == "m"){
                                $scope.showPayment(e);
                            }
                        
                            $scope.$parent.setDateValidity($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year, $scope.frmAboutYourPolicy.policyStartDateDD);

                        $scope.currentProposal.PolicyStartDate = $scope.$parent.convertDateThreeToOne($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year);
                        $scope.currentProposal.PolicyStartDate == null ? ($scope.currentProposal.PolicyStartDate = '') : '';
                        fn.utils.autoSetEndDate();
                    }

                },

                convertPolicyStartMITDateClick: function(e,param) {
                    $scope.isClickPolicyOfYear = false;
                    if(param == 'd') {
                        $scope.txtPolicyStartDateDD = e.target.textContent.trim();
                        $scope.currentProposal.PolicyStartDate_DD = e.target.dataset.key;
                    } else if(param == 'm') {
                        $scope.txtPolicyStartDateMON = e.target.textContent.trim();
                        $scope.currentProposal.PolicyStartDate_MON = e.target.dataset.key;
                    } else if(param == 'y') {
                        $scope.txtPolicyStartDateYear = e.target.textContent.trim();
                        $scope.currentProposal.PolicyStartDate_Year = e.target.dataset.key;
                        $scope.isClickPolicyOfYear = true;
                    }
                    console.log("$scope.currentProposal.PolicyStartDate_DD : ",$scope.currentProposal.PolicyStartDate_DD);

                    if (typeof $scope.currentProposal.PolicyStartDate_DD !== "undefined" &&
                    typeof $scope.currentProposal.PolicyStartDate_MON !== "undefined" &&
                    typeof $scope.currentProposal.PolicyStartDate_Year !== "undefined") {

                    
                    $scope.$parent.setDateValidity($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year, $scope.frmAboutYourPolicy.policyStartDateDD);

                    $scope.currentProposal.PolicyStartDate = $scope.$parent.convertDateThreeToOne($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year);
                    $scope.currentProposal.PolicyStartDate == null ? ($scope.currentProposal.PolicyStartDate = '') : '';
                    fn.utils.autoSetEndDate();

                    // if(param == 'd') {
                    //     fn.eventHandlers.showCalendarMONABTesting(e);
                    // } else if(param == 'm') {
                    //     fn.eventHandlers.showCalendarYearABTesting(e);
                    // } else if(param == 'y') {
                    //     // fn.eventHandlers.showCalendarYearABTesting(e);
                    // }

                }

                },

                convertNDMITDate: function (nd, formElement) {
                    if (!nd) return;

                    if (typeof nd.DOB_DD !== "undefined" &&
                        typeof nd.DOB_MON !== "undefined" &&
                        typeof nd.DOB_Year !== "undefined") {
                        if(formElement) {
                            $scope.$parent.setDateValidity(nd.DOB_DD, nd.DOB_MON, nd.DOB_Year, formElement);
                        }
                        nd.DateOfBirth = $scope.$parent.convertDateThreeToOne(nd.DOB_DD, nd.DOB_MON, nd.DOB_Year);
                        nd.DateOfBirth == null ? (nd.DateOfBirth = '') : '';
                        if(formElement) {
                            $scope.$parent.isInvalidDriverAge(nd.DateOfBirth, formElement);
                        }
                    }
                },

                validateDriverAgeError: function () {
                    // if(($scope.pageSubmitted || ($scope.frmAboutMainDriver.mainDriverDOBDD.$dirty && $scope.frmAboutMainDriver.mainDriverDOBMON.$dirty && $scope.frmAboutMainDriver.mainDriverDOBYear.$dirty)) && ( $scope.frmAboutMainDriver.mainDriverDOBDD.$error.custom_age )) {
                    //     return true;
                    // } else {
                    //     return false;
                    // }
                    if($scope.frmAboutMainDriver.mainDriverDOBDD.$error.custom_age) {
                        return true;
                    } else {
                        return false;
                    }

                },

                ConvertMITDateToDisplay: function () {
                    if ($scope.currentProposal.PolicyStartDate) {
                        var d = $scope.$parent.convertDateOneToThree($scope.currentProposal.PolicyStartDate);


                        if (d != null || d.length > 0) {
                            $scope.currentProposal.PolicyStartDate_DD = d[0];
                            $scope.currentProposal.PolicyStartDate_MON = d[1];
                            $scope.currentProposal.PolicyStartDate_Year = d[2];
                        }
                    } else {

                    }
                    if ($scope.policyHolder.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree($scope.policyHolder.DateOfBirth);
                        if (d != null || d.length > 0) {
                            $scope.policyHolder.DOB_DD = d[0];
                            $scope.policyHolder.DOB_MON = d[1];
                            $scope.policyHolder.DOB_Year = d[2];
                        }
                    }

                    if ($scope.mainDriver.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree($scope.mainDriver.DateOfBirth);
                        if (d != null || d.length > 0) {
                            $scope.mainDriver.DOB_DD = d[0];
                            $scope.mainDriver.DOB_MON = d[1];
                            $scope.mainDriver.DOB_Year = d[2];
                        }
                    }

                    if ($scope.namedDriver.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree($scope.namedDriver.DateOfBirth);
                        if (d != null || d.length > 0) {
                            $scope.namedDriver.DOB_DD = d[0];
                            $scope.namedDriver.DOB_MON = d[1];
                            $scope.namedDriver.DOB_Year = d[2];
                        }
                    }

                },
                getPriceToShow: function (c) {
                    if(!c) {return 0.00;}
                    if(c) {
                        var totalPremium = c.CalculatedPremium;
                        return totalPremium;
                    }
                },
                paymentScheduleToShow: function (thirdPageShow,compulsoryIsIncluded, firstCal) {
                    let amount = 0.00;
                    var getCoverages = $scope.coverages.Coverages.find(w => w.IsSelected == true);

                    if(getCoverages) {
                      if(getCoverages.PaymentSchedule && getCoverages.PaymentSchedule.length > 0) {
                        amount = getCoverages.PaymentSchedule[0].Amount;
                      }
                  
                      if(getCoverages.PaymentSchedule && getCoverages.PaymentSchedule.length > 0) {
                        $scope.getFirstPaymentToShow = Math.round((amount + Number.EPSILON) * 100) / 100;
                        $scope.getMonthlyPremiumToShow = getCoverages.PaymentSchedule.length > 1 ? getCoverages.PaymentSchedule[1].Amount : 0.00
                        $sessionStorage.toSetFirstPaymentToShow = $scope.getFirstPaymentToShow
                        $sessionStorage.toSetMonthlyPremiumToShow = $scope.getMonthlyPremiumToShow
                      }  
                
                    }
                },

                filterOptionalButCompulsoryCovers: function (r) {

                    var status = false;

                    if (r.ProductLineType === '2' || r.ProductLineType === 2) {
                        if (r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA || r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_DA) {
                            status = false;
                        }
                        else {
                            if (r.IsSelected) {
                                status = true;
                            } else {
                                status = false;
                            }
                        }

                    } else {

                        if (r.ProductLineType === '0' || r.ProductLineType === 0) {

                            /*hides permanent disable for passenger as is merged with driver in copy*/
                            if (r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_DA || r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA || r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_2000112_DA || r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_2000107_DA) {
                                status = false;
                            }
                            else {
                                /*always hide Compulsory Core Benefits, Core Benefits*/
                                if (r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_DA || r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_2000160_DA) {
                                    status = false;
                                }
                                else {
                                    status = true;
                                }
                            }
                        } else {

                            if (r.ProductLineType === '1' || r.ProductLineType === 1) {
                                if (r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA || r.ProductLineGroupId === $scope.PRODUCT_LINE_GROUPID_FALCON_2000116) {
                                    status = false;
                                }
                                else {
                                    status = true;
                                }
                            } else {
                                status = true;
                            }
                        }
                    }
                    return status;
                },
                getAge: function (d) {
                    var today = new Date();
                    var birthDate = new Date(d);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }

                    return age;
                },
                ConvertNDMITDateToDisplay: function (nd) {
                    if (nd.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree(nd.DateOfBirth);
                        if (d != null || d.length > 0) {
                            nd.DOB_DD = d[0];
                            nd.DOB_MON = d[1];
                            nd.DOB_Year = d[2];
                            nd.DOB = `${d[0]}/${d[1]}/${d[2]}`;
                        }
                    }
                },
                getExcessValue: function (key,val) {
                    var KeyValuePairString;
                    if ($scope.selectedCoverage && $scope.selectedCoverage.excess && key) {
                        KeyValuePairString = $scope.selectedCoverage.excess;
                    } else {
                        return 0;
                    }

                    var exc = KeyValuePairString.find(w => w.key == key);

                    if (exc && exc.value) {
                        return Number(exc.value);
                    } else {
                        return 0;
                    }

                },
                setPlan: function () {
                    if($scope.totalPremium2 > 0){
                        fn.eventHandlers.updateDeductibleAsset()
                    }
                },
                customerSelectedPlan: function(getValueSelectedPlan){
                    fn.eventHandlers.onCustomerLeadDetailsChecker()

                    if($scope.warningCounter > 0){
                        // maintain the old state of button
                        fn.eventHandlers.onMaxarMissingLeadFieldsChecker()                      
                    } else {
                        // set "Selected Value on Button"
                        $scope.asset.PlanId = getValueSelectedPlan;

                        // Update deductible at first after click
                        if($scope.selectedCoverage && !$scope.selectedCoverage.ExcessAmount) {
                            if($scope.selectedCoverage.excess) {
                                let excess = $scope.selectedCoverage.excess.find(w => w.level == $scope.selectedCoverage.ExcessLevelId);
                                if(excess) {
                                    $scope.selectedCoverage.ExcessAmount = excess.amount;
                                } else {
                                    var excessId = Utils.getCoverVoluntaryBasicByProductId($scope.selectedCoverage);
                                    if (excessId && excessId.length > 0) {
                                        excessId = excessId[0]
                                        if(excessId && excessId.ExcessLevelId) {
                                            var excessAmount = $scope.selectedCoverage.excess.filter(w => Number(w.level) == excessId.ExcessLevelId)
                                            if (excessAmount && excessAmount.length > 0) {
                                                excessAmount = excessAmount[0]
                                                if(excessAmount && excessAmount.value) {
                                                    $scope.selectedCoverage.ExcessAmount = Number(excessAmount.value);
                                                    $scope.selectedCoverage.ExcessLevelId = excessId.ExcessLevelId;
                                                } 
                                            } 
                                        }     
                                    }       
                                }
                            }
                        }
                    }

                    /* Replicate from onBtnCalculatePremiumClicked */
                    $scope.isCalculateAction = true
                    $scope.allowBtnAction = false
                    $scope.loadingSpinner2 = true
                    $scope.loadingSpinner3 = true
                    $scope.$parent.showPriceOnTopFlag = false

                    fn.utils.setIsSaving(true)
                    
                    var callbacks = {
                        onSuccess: function () {
                            var params = $scope.UseMyWorkshop ? 'Dealer' : 'Panel'
                            fn.saving.saveCurrentProposalChildModels(callbackSaveCurrentProposalChildModels, params)
                        },
                        onError: function (err) {
                            fn.utils.clearLoading()
                            var error = err && err.ErrorInfo ? err.ErrorInfo : { fn: 'customerSelectedPlan' }
                            var errorLog = { err: JSON.stringify(error) }
                            let priority = $localStorage.priority ? $localStorage.priority : 22
                            fn.postToMaxParent('No', priority, 'N', errorLog)   
                            fn.eventHandlers.onDefaultErrorHandler()
                        }
                    }

                    var callbackSaveCurrentProposalChildModels = {
                        onSuccess: function (statusCode) {
                            if(statusCode == 'INPUT_INVALID' && $scope.loadingSpinner3){
                                $scope.loadingSpinner = false
                                $scope.loadingSpinner2 = false
                                $scope.loadingSpinner3 = false
                                $scope.$parent.showPriceOnTopFlag = true
                                $scope.allowBtnAction = true
                                return
                            }
                        }
                    }

                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal')                    
                    fn.currentProposalApi[saveCurrentProposalFunc](callbacks)
                },
                selectMyWorkshopOption: function (driverPlan) {
                    // console.log($scope.asset.UseMyWorkshop);

                    fn.utils.setIsSaving(true);

                    fn.updateAssetMaxarPriority({
                        onSuccess: function () {

                            fn.utils.setIsSaving(true);
                            var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                            $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                            fn.currentProposalApi[saveCurrentProposalFunc](
                                {
                                    onSuccess: function () {
                                        fn.quoteApi.calculate({
                                            onSuccess: function () {
                                                fn.retrieveScopeObjects();
                                            }
                                        });
                                    },
                                    onError: function () {
                                        $scope.hasError = true;
                                        fn.utils.setIsSaving(false);
                                    }
                                }
                            );
                        }
                    });
                },
                coverageList: function () {
                    if ($scope.coverages.Coverages) {
                        if ($scope.asset.PlanId === $scope.PLANID_NAMED_DRIVER || $scope.asset.PlanId === $scope.PLANID_ANY_DRIVER) {
                            return $filter('filter')($scope.coverages.Coverages, { PlanId: $scope.asset.PlanId });;
                        }
                    }
                },
                getInsuranceAmount: function (groupid) {
                    if ($scope.selectedCoverage) {
                        var ins = $filter('filter')($scope.selectedCoverage.Covers, { ProductLineGroupId: groupid });
                        if (ins) {
                            if (ins.length > 0) {
                                return ins[0].InsuranceAmount;
                            } 
                            else {
                                return 0;
                            }
                        }
                    } 
                    else {
                        return 0;
                    }
                },
                getIncludeCompulsory: function () {
                    if ($scope.selectedCoverage) {
                        var compulsory = Utils.getCoverComplusoryBasicCoverByProductId($scope.selectedCoverage);

                        if(compulsory && compulsory.length > 0) {
                            return compulsory[0].IsSelected;
                        }
                    } 
                    else {
                        return false;
                    }
                },
                getIncludeCompulsoryByCoverage: function (c) {
                    if (c) {
                        var compulsory = Utils.getCoverComplusoryBasicCoverByProductId(c);

                        if(compulsory && compulsory.length > 0) {
                            return compulsory[0];
                        }
                    } 
                    else {
                        return false;
                    }
                },
                onUserMismatchHandler: function (errorInformation) {
                    if (!errorInformation) {
                        return false;
                    }
                    var contactsNotUpdated = ['PrimaryPhoneNumber', 'PrimaryEmailAddress'];
                    var contactKeyDisplayMatch = {
                        PrimaryEmailAddress: 'body_user_email', 
                        PrimaryPhoneNumber: 'body_user_mobile',
                        FirstName: 'body_user_first_name', 
                        LastName: 'body_user_lastname', 
                        MaritalStatusId: 'body_policy_nd_marital',
                        HouseNumber: 'body_address_house_number', 
                        PostalCode: 'body_address_postal_code'
                    };

                    $scope.userMismatchObject = errorInformation;
                    for (var i = 0; i < errorInformation.length; i++) {
                        var personInfo = errorInformation[i];
                        if (personInfo) {
                            if (personInfo.RoleName === 'PH') {
                                personInfo.displayRoleName = 'body_title_policyholder_detail';
                            } else if (personInfo.RoleName === 'MD') {
                                personInfo.displayRoleName = 'body_title_maindriver_detail';
                            }
                            personInfo.isEmailPhoneChanged = false;
                            var contactMismatchList = personInfo.MismatchList;
                            if (contactMismatchList) {
                                for (var j = 0; j < contactMismatchList.length; j++) {
                                    var contactMismatchObject = contactMismatchList[j];
                                    contactMismatchObject.displayKey = contactKeyDisplayMatch[contactMismatchObject.Key];
                                    contactMismatchObject.additionalInfo = '';
                                    if (contactsNotUpdated.indexOf(contactMismatchObject.Key) >= 0) {
                                        personInfo.isEmailPhoneChanged = true;
                                        contactMismatchObject.additionalInfo = 'car_v_driver_mismatch_addtional_info';
                                    }
                                }
                            }
                        }
                    }
                },
                getExcessValueSummary: function () {

                    var KeyValuePairString;
                    if ($scope.selectedCoverage) {
                        KeyValuePairString = $scope.selectedCoverage.excess;
                    } else {
                        return 0;
                    }

                    if ($scope.selectedCoverage && KeyValuePairString) {
                        var excessId = Utils.getCoverVoluntaryBasicByProductId($scope.selectedCoverage);
                        if (excessId && excessId.length > 0) {
                            excessId = excessId[0]
                            if(excessId && excessId.ExcessLevelId) {
                                var excessAmount = KeyValuePairString.filter(w => Number(w.level) == excessId.ExcessLevelId)
                                if (excessAmount && excessAmount.length > 0) {
                                    excessAmount = excessAmount[0]
                                    if(excessAmount && excessAmount.value) {
                                        return Number(excessAmount.value);
                                    } else {
                                        return 0;
                                    }
                                } else {
                                    return 0;
                                }
                            } else {
                                return 0;
                            }

                        } else {
                            var excessAmt = Utils.getCoverVoluntaryBasicByProductId($scope.selectedCoverage);
                            if (excessAmt && excessAmt.length > 0) {
                                excessAmt = excessAmt[0]
                                if(excessAmt && excessAmt.ExcessAmount) {
                                    return excessAmt.ExcessAmount;
                                } else {
                                    return 0;
                                }
                            } else {
                                return 0;
                            }

                        }

                    } else {
                        return 0;
                    }
                },
                isCMIIncluded: function () {
                    if ($scope.selectedCoverage !== undefined && $scope.selectedCoverage !== null) {
                        var chk = Utils.getCoverComplusoryBasicCoverByProductId($scope.selectedCoverage);
                        if (chk !== undefined && chk.length > 0) {
                            var isCMIIncluded = chk[0].IsSelected;
                        } else {
                            return false;
                        }

                        return isCMIIncluded;
                    }

                },
                isSelectedExcess: function (eKey) {

                    var chk = $scope.selectedCoverage.excess.find(w => w.key == eKey);
                    var excessVal;
                    if(chk && chk.value) {
                        excessVal = chk.value.split(".");
                        if(excessVal && excessVal.length > 0) {
                            excessVal = excessVal[0];
                        }                              
                    }
                    
                    var chk2;
                    if($scope.selectedCoverage.ProductId == $scope.PRODUCT_ID_DA) {
                        chk2 = $scope.selectedCoverage.Covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA); //New check
                    } else if($scope.selectedCoverage.ProductId == $scope.PRODUCT_ID_MTI) {
                        chk2 = $scope.selectedCoverage.Covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI); //New check
                    } else {
                        chk2 = $scope.selectedCoverage.Covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA); //New check
                    }
                    if(chk2) { 
                        if(chk2.ExcessAmount || chk2.ExcessAmount == 0) {
                            if (excessVal == chk2.ExcessAmount) {
                                $scope.selectedCoverage.ExcessAmount = excessVal;
                                return true;
                            } else if (eKey == chk2.ExcessLevelId) {
                                return true;
                            } else {
                                return false;
                            } 
                        } else if(eKey == chk2.ExcessLevelId) {
                            return true;                      
                        } else {
                            return false;
                        }
                    } else {
                        return false;
                    }
                },
                getCoverOwnDamageByProductId: function (sc) {
                    var odc = {};
                    if(sc.ProductId == $scope.PRODUCT_ID_DA) {
                        odc = $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA }, true);
                    } else if (sc.ProductId == $scope.PRODUCT_ID_MTI) {
                        odc =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_MTI }, true);
                    } else if(sc.ProductId == $scope.PRODUCT_ID_AXA) {
                        odc =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_AXA }, true);
                    }
                    return odc;
                },
                getCoverOwnDamageFireByProductId: function (sc) {
                    var odc = {};
                    if(sc.ProductId == $scope.PRODUCT_ID_DA) {
                        odc = $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA }, true);
                    } else if (sc.ProductId == $scope.PRODUCT_ID_MTI) {
                        odc =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_MTI }, true);
                    } else if(sc.ProductId == $scope.PRODUCT_ID_AXA) {
                        odc =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_AXA }, true);
                    }
                    return odc;
                },
                getCoverComplusoryBasicCoverIndexByProductId: function (sc) {
                    var ccb = -1;
                    if(sc.ProductId == $scope.PRODUCT_ID_DA) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA);
                    } else if (sc.ProductId == $scope.PRODUCT_ID_MTI) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI);
                    } else if(sc.ProductId == $scope.PRODUCT_ID_AXA) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA);
                    }
                    return ccb;
                },
                getCoverComplusoryBasicCoverIndexByProductIds: function (sc,productId) {
                    var ccb = -1;
                    if(productId == $scope.PRODUCT_ID_DA) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA);
                    } else if (productId == $scope.PRODUCT_ID_MTI) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI);
                    } else if(productId == $scope.PRODUCT_ID_AXA) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA);
                    }
                    return ccb;
                },
                getCoverComplusoryBasicCoverByProductIds: function (sc,productId) {
                    var ccb = {};
                    if(productId == $scope.PRODUCT_ID_DA) {
                        ccb = $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA }, true);
                    } else if (productId == $scope.PRODUCT_ID_MTI) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI }, true);
                    } else if(productId == $scope.PRODUCT_ID_AXA) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA }, true);
                    }
                    return ccb;
                },

                getComplusoryBasicCoverPremium: function (covers,productId) {
                    let totalPremiumToColl = 0.00;
                    if(covers) {
                      if(productId == $scope.PRODUCT_ID_MTI) {
                        let totalPremiumToCollValue = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI);
                        if(totalPremiumToCollValue) {
                          totalPremiumToColl = totalPremiumToCollValue.TotalPremiumToColl;
                        }
                      } else if(productId == $scope.PRODUCT_ID_AXA) {
                        let totalPremiumToCollValue = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA);
                        if(totalPremiumToCollValue) {
                          totalPremiumToColl = totalPremiumToCollValue.TotalPremiumToColl;
                        }  
                      } else if(productId == $scope.PRODUCT_ID_DA) {
                        let totalPremiumToCollValue = covers.find(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA);
                        if(totalPremiumToCollValue) {
                          totalPremiumToColl = totalPremiumToCollValue.TotalPremiumToColl;
                        }  
                      }
                    }
                    return totalPremiumToColl;                
                },
                getCoverVoluntaryBasicIndexByProductId: function (sc) {
                    var ccb = -1;
                    if(sc.ProductId == $scope.PRODUCT_ID_DA) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA);
                    } else if (sc.ProductId == $scope.PRODUCT_ID_MTI) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI);
                    } else if(sc.ProductId == $scope.PRODUCT_ID_AXA) {
                        ccb = sc.Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA);
                    }
                    return ccb;
                },
                getCoverDeathDisByProductId: function (sc) {
                    var ccb = {};
                    if(sc.ProductId == $scope.PRODUCT_ID_DA) {
                        ccb = $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_DA }, true);
                    } else if (sc.ProductId == $scope.PRODUCT_ID_MTI) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_MTI }, true);
                    } else if(sc.ProductId == $scope.PRODUCT_ID_AXA) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_AXA }, true);
                    }
                    return ccb;
                },
                getCoverBodilyInjuryByProductId: function (sc) {
                    var ccb = {};
                    if(sc.ProductId == $scope.PRODUCT_ID_DA) {
                        ccb = $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA }, true);
                    } else if (sc.ProductId == $scope.PRODUCT_ID_MTI) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_MTI }, true);
                    } else if(sc.ProductId == $scope.PRODUCT_ID_AXA) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_AXA }, true);
                    }
                    return ccb;
                },
                getCoverCompulsoryCoreByProductId: function (sc) {
                    var ccb = {};
                    if(sc.ProductId == $scope.PRODUCT_ID_DA) {
                        ccb = $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_DA }, true);
                    } else if (sc.ProductId == $scope.PRODUCT_ID_MTI) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_MTI }, true);
                    } else if(sc.ProductId == $scope.PRODUCT_ID_AXA) {
                        ccb =  $filter('filter')(sc.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_AXA }, true);
                    }
                    return ccb;
                },
                computeAggregatorPriority: function (priority, currentProposal, staticData) {
                    if (parseInt(priority) === 17) return priority

                    if (
                        !currentProposal ||
                        !currentProposal.Assets ||
                        !currentProposal.Role ||
                        !currentProposal.Role.ProposalPersons
                    ) return priority

                    // BMW or Mercedes-Benz vehicles
                    if (
                        currentProposal.Assets[0].ManufactureId === 1000005 ||
                        currentProposal.Assets[0].ManufactureId === 1000082
                    ) return  10

                    // People with driving experience less than 2 years
                    if (
                        currentProposal.Role.ProposalPersons[0].Value.DrivingExperienceId === 1 ||
                        currentProposal.Role.ProposalPersons[0].Value.DrivingExperienceId === 2
                    ) return  10

                    // Pickup (4 Doors)
                    let carDescription = staticData && staticData.cardescriptions
                        ? staticData.cardescriptions.find(description =>
                            description.key.toString() === currentProposal.Assets[0].CarDescriptionId.toString()
                        ) : null
                    if (carDescription && carDescription.bodyTypeId === 2000016) return  10

                    return 11
                },
                sortInsuranceAmount: function(data) {
                    if(data && data.length > 0){
                        data.sort((n1,n2) => {
                            if (n1.value > n2.value) {
                                return 1;
                            }
                        
                            if (n1.value < n2.value) {
                                return -1;
                            }
                            return 0;
                        });
                    }
                }
            },

            saving: {

                setUsageId: function () {

                    if ($scope.asset.UsageId == "6") {
                        $scope.asset.InDriveWork = false;
                        $scope.asset.InCourseWork = false;
                        $scope.txtInCourseWork = 1;
                    }
                    else if ($scope.asset.UsageId == "1000000") {
                        $scope.asset.InDriveWork = true;
                        $scope.asset.InCourseWork = false;
                        $scope.txtInCourseWork = 0
                    }
                    else if ($scope.asset.UsageId == "1000002") {
                        $scope.asset.InDriveWork = true;
                        $scope.asset.InCourseWork = true;
                        $scope.txtInCourseWork = 2
                    }
                    else {
                        $scope.asset.InDriveWork = undefined;
                        $scope.asset.InCourseWork = undefined;
                        $scope.txtInCourseWork = null;
                    }

                },

                savePHContact: function () {
                    var hasError = false;
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc] ({
                            onSuccess: function () {
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function () {
                                        fn.confirmationApi.createConfirmation({
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                            },
                                            onError: function (err) {
                                                if(!hasError) {
                                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContact', method: 'fn.updateAssetMaxarPriority'} ;
                                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4189'};
                                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                                }
                                                hasError = true;

                                                fn.utils.setIsSaving(false);
                                            }
                                        });
                                    }
                                });
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContact', method: 'fn.phContactApi'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4203'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;

                                fn.utils.setIsSaving(false);
                            }
                    });
                },

                saveCurrentProposalChildModels: function (callbacks,params) {

                    var hasError = false;
                    var triggerSave = function () { saveAsset(params) }

                    var saveAsset = function (params) {

                        fn.saving.setUsageId();
                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');

                        if (saveAssetFunc === "updateAsset") {
                            if ($scope.asset.NcdLevelId != "1000003") {
                                $scope.asset.ReasonForNcdIsZero = "0";
                                $scope.asset.NcdLevelIdOnOtherCar = "0";
                            }
                        }

                        fn.assetApi[saveAssetFunc]({
                            onSuccess: function (data) {
                                savePH(params); /*hold on to Contact PH for Email and Mobile later.*/
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveCurrentProposalChildModels', method: 'savePH'} ;
                                    var errorLog = {err: JSON.stringify(error), case: err && err.ErrorInfo && err.ErrorInfo.StatusCode == 'INPUT_INVALID' ? `Underwriting => Message: ${err.ErrorInfo.Message} | Fields: ${err.ErrorInfo.Fields} : line 4237` : 'error : line 4237'};
                                    let priority = err && err.ErrorInfo && err.ErrorInfo.StatusCode == 'INPUT_INVALID' ? 8 : $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;
                                if(err && err.ErrorInfo && err.ErrorInfo.StatusCode == 'INPUT_INVALID') {        
                                    callbacks.onSuccess(err.ErrorInfo.StatusCode);
                                } else {
                                    savePH(params);
                                }
                            }
                        });
                    };

                    var savePH = function (params) {

                        hasError = false;
                        $scope.policyHolder.IsMainDriver = true;

                        if ($scope.policyHolder.IsMainDriver) {
                            $scope.mainDriver.FirstName = $scope.policyHolder.FirstName;
                            $scope.mainDriver.LastName = $scope.policyHolder.LastName;
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        } 
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }

                        if ($scope.HKLocale.getLocale() === "en-us") {
                            $scope.policyHolder.PreferredLanguageId = 101;
                        } 
                        else {
                            $scope.policyHolder.PreferredLanguageId = 2000000;
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function (data) {
                                    saveMD(params);
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePH', method: 'saveMD'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line: 4277'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }    
                                    hasError = true;
                                    saveMD(params);
                                }
                            }
                        );
                    };

                    var saveMD = function (params) {
                        hasError = false;
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');

                        if (!saveMDFunc) {
                            savePHContact(params);
                        }else{
                            fn.mainDriverApi[saveMDFunc](
                                {
                                    onSuccess: function () {
                                        savePHContact(params);
                                    },
                                    onError: function (err) {
                                        if(!hasError) {
                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveMD', method: 'savePHContact'} ;
                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 4302'};
                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                        }        
                                        hasError = true;
                                        savePHContact(params);
                                    }
                                }
                            );
                        }

                    };

                    var savePHContact = function (params) {
                        hasError = false;
                        var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                        fn.phContactApi[savePHContactFunc](
                            {
                                onSuccess: function () {
                                    calculatePremium(params);
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContact', method: 'calculatePremium'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 4325'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true;
                                    calculatePremium(params);
                                }
                            }
                        );
                    };

                    var calculatePremium = function (params) {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }
                        hasError = false;
                        fn.quoteApi.calculate({
                            onSuccess: function (data) {                              
                                $scope.isNoPackage = false;                    
                                if(data && data.Coverages && data.Coverages.length > 0) {

                                    var selectedCoverages = data.Coverages.find(w => w.IsSelected == true);
                                    if(!selectedCoverages) {
                                        selectedCoverages = data.Coverages[0];
                                    }
                                    if(selectedCoverages && selectedCoverages.ProductId) {
                                        let coveragesLen =  data.Coverages.length;
                                        if(coveragesLen == 1) {
                                            if(selectedCoverages.CoverTypeId == $scope.TYPE2_FALCON_DG || selectedCoverages.CoverTypeId == $scope.TYPE3_FALCON_DG){
                                                // to set "singlepayment state only" once customer select Type2 & Type3
                                                $sessionStorage.toSetSinglePaymentFromType2Type3Selected = true;
                                                $scope.type2and3RefreshingState = $sessionStorage.toSetSinglePaymentFromType2Type3Selected;
                                            } else {
                                                $sessionStorage.toSetSinglePaymentFromType2Type3Selected = false;
                                                $scope.type2and3RefreshingState = $sessionStorage.toSetSinglePaymentFromType2Type3Selected;
                                            }                    
                                        } else {
                                            if(selectedCoverages.CoverTypeId != $scope.TYPE2_FALCON_DG && selectedCoverages.CoverTypeId != $scope.TYPE3_FALCON_DG){
                                                $sessionStorage.toSetSinglePaymentFromType2Type3Selected = false;
                                                $scope.type2and3RefreshingState = $sessionStorage.toSetSinglePaymentFromType2Type3Selected;
                                            }                    
                                        }
                                        //####Check Previous Select Coverage######
                                        if(params == 'Dealer' || params == 'Panel'){
                                            if(Object.keys($scope.currentProposal).length != 0) {
                                                $scope.previousCoverages = [];
                                                if($scope.currentProposal.Assets.length > 0){
                                                    $scope.currentProposal.Assets[0].Coverages.map((data) => {
                                                        $scope.previousCoverages.push(data);
                                                    })
                                                }
                                                // $scope.previousProposal = Object.assign({}, $scope.currentProposal);
                                            }
                                        }
                                        //########################################          

                                        if($scope.currentProposal.ProductId == selectedCoverages.ProductId) {
                                            $scope.isProductSwitching = false;
                                            retrieveCurrentProposal(params);
                                            //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(2);        
                                        } else {
                                            $scope.isProductSwitching = true;
                                            $scope.$parent.product.ProductId = selectedCoverages.ProductId;
                                            $scope.$parent.asset.ProductId = selectedCoverages.ProductId;
                                            $scope.currentProposal.ProductId = selectedCoverages.ProductId;
                                            $scope.asset.LobInsuranceFormulaId = selectedCoverages.CoverTypeId;
                                            $scope.asset.ProductId = selectedCoverages.ProductId;
                                            $scope.asset.Coverages = data.Coverages;     
                                            var coverTypeIds = [];
                                            data.Coverages.map((d) => {
                                                coverTypeIds.push(d.CoverTypeId);
                                            })     
                                            if(coverTypeIds && coverTypeIds.length > 0) {
                                                $scope.asset.CoverTypeIds = coverTypeIds;                                   
                                            }
                                            $scope.coverageList = data.Coverages;
                                            // $scope.coverages.Coverages = {CoverListsCount: data.Coverages.length,Coverages: data.Coverages};
                                            angular.extend($scope.coverages, {CoverListsCount: data.Coverages.length,Coverages: data.Coverages});

                                            $scope.coverages.isForUpdate = true;

                                            // console.log($scope.currentProposal);

                                            if($scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0) {
                                                $scope.currentProposal.Assets[0].ProductId = selectedCoverages.ProductId;
                                                $scope.currentProposal.Assets[0].LobInsuranceFormulaId = selectedCoverages.CoverTypeId;
                                                $scope.currentProposal.Assets[0].BodyTypeId = $scope.asset.BodyTypeId;
                                                $scope.currentProposal.Assets[0].CarDescriptionId = $scope.asset.CarDescriptionId;
                                                $scope.currentProposal.Assets[0].CarValue = Number($scope.asset.CarValue);
                                                $scope.currentProposal.Assets[0].ChannelDetails = $scope.asset.ChannelDetails ? $scope.asset.ChannelDetails : '';
                                                $scope.currentProposal.Assets[0].ClassId = Number($scope.asset.ClassId);
                                                $scope.currentProposal.Assets[0].CoverTypeIds = $scope.asset.CoverTypeIds;
                                                $scope.currentProposal.Assets[0].Coverages = $scope.asset.Coverages;
                                                $scope.currentProposal.Assets[0].EngineSizeId = $scope.asset.EngineSizeId;
                                                $scope.currentProposal.Assets[0].FinalUsualPaymentModeId = $scope.asset.FinalUsualPaymentModeId;
                                                $scope.currentProposal.Assets[0].GearTypeId = $scope.asset.GearTypeId;
                                                $scope.currentProposal.Assets[0].GroupId = $scope.asset.GroupId;
                                                $scope.currentProposal.Assets[0].ImportStatusId = $scope.asset.ImportStatusId;
                                                $scope.currentProposal.Assets[0].InitialUsualPaymentModeId = $scope.asset.InitialUsualPaymentModeId;
                                                $scope.currentProposal.Assets[0].IsCarCameraInstalled = $scope.asset.IsCarCameraInstalled;
                                                $scope.currentProposal.Assets[0].IsCarModified = $scope.asset.IsCarModified;
                                                $scope.currentProposal.Assets[0].LeadTypeId = $scope.asset.LeadTypeId;
                                                $scope.currentProposal.Assets[0].LicenseCardProvinceId = Number($scope.asset.LicenseCardProvinceId);
                                                $scope.currentProposal.Assets[0].ManufactureId = $scope.asset.ManufactureId;
                                                $scope.currentProposal.Assets[0].ModelId = $scope.asset.ModelId;
                                                $scope.currentProposal.Assets[0].NCBNumOfCliamsInLastThreeYears = $scope.asset.NCBNumOfCliamsInLastThreeYears ? Number($scope.asset.NCBNumOfCliamsInLastThreeYears) : 0;
                                                $scope.currentProposal.Assets[0].NCBNumOfYearsBeenInsured = $scope.asset.NCBNumOfYearsBeenInsured ? Number($scope.asset.NCBNumOfYearsBeenInsured) : 0;
                                                $scope.currentProposal.Assets[0].NCDPlusYearId = $scope.asset.NCDPlusYearId;
                                                $scope.currentProposal.Assets[0].NcdLevelId = Number($scope.asset.NcdLevelId);
                                                $scope.currentProposal.Assets[0].PaymentTermId = Number($scope.asset.PaymentTermId);
                                                $scope.currentProposal.Assets[0].PrevNcdLevelId = Number($scope.asset.PrevNcdLevelId);
                                                $scope.currentProposal.Assets[0].ProductId = Number($scope.asset.ProductId);
                                                $scope.currentProposal.Assets[0].PurchasingYear = $scope.asset.PurchasingYear;
                                                $scope.currentProposal.Assets[0].TariffType = $scope.asset.TariffType;                                            
                                            }

                                            saveCurrentProposalModelToUpdateNewProduct(params);
                                        } 
                                    } else {
                                        //Modal No Package
                                        if(params == "Dealer" || params == "Panel"){
                                            $scope.asset.UseMyWorkshop = params === "Dealer" ? false : true;
                                            $scope.loadingSpinner3 = false;
                                            $scope.allowBtnAction = true;
    
                                        }
    
                                        fn.utils.clearLoading();
                                        $('#error-call-button').html(HKLocale.getLocale() == "th-th" ? "คลิก! โทรเลย" : "Click! Call now");
                                        $scope.firstPageShow = true;
                                        $scope.secondPageShow = false;
                                        $scope.thirdPageShow = false;
                                        $scope.fourthPageShow = false;   
                                        $scope.isNoPackage = true; 
                                        
                                        var error = data && data.ErrorInfo && data.ErrorInfo.Fields && data.ErrorInfo.Fields.length != 0  ? data.ErrorInfo.Fields : "" ;
                                        var errorLog = {err: error, case: 'Underwriting : line 4439'};
                                        fn.postToMaxParent('No', 8, 'N', errorLog)
        
                                        DaModal.open("#noPackage");
                                        return;
                                    }
                                } else {
                                    //Modal No Package
                                    if(params == "Dealer" || params == "Panel"){
                                        $scope.asset.UseMyWorkshop = params === "Dealer" ? false : true;
                                        $scope.loadingSpinner3 = false;
                                        $scope.allowBtnAction = true;
                                    }

                                    fn.utils.clearLoading();
                                    $('#error-call-button').html(HKLocale.getLocale() == "th-th" ? "คลิก! โทรเลย" : "Click! Call now");
                                    $scope.firstPageShow = true;
                                    $scope.secondPageShow = false;
                                    $scope.thirdPageShow = false;
                                    $scope.fourthPageShow = false;
                                    $scope.isNoPackage = true;       
                                    
                                    var error = data && data.ErrorInfo && data.ErrorInfo.Fields && data.ErrorInfo.Fields.length != 0  ? data.ErrorInfo.Fields : "" ;
                                    var errorLog = {err: error, case: 'Underwriting => No Package : 4456'};
                                    fn.postToMaxParent('No', 8, 'N', errorLog)

                                    DaModal.open("#noPackage");
                                    return;
                                }
                            },
                            onError: function (err) {
                                fn.utils.clearLoading();
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContact', method: 'calculatePremium', line: 5107} ;
                                    var errorLog = {err: JSON.stringify(error), case: err && err.ErrorInfo && err.ErrorInfo.StatusCode == 'VALIDATION_FAILED' ? 'Underwriting : line 4467' : 'error : line 4467'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;
                                fn.utils.setIsSaving(false);
                                fn.goToPage(1);
                                try {
                                    if (!$scope.currentProposal.IsRenewalProposal) {
                                        $scope.bidata = { Source: "B2C" };
                                        $scope.errdata = err;
                                    }
                                } 
                                catch (err) {}
                                fn.eventHandlers.onDefaultErrorHandler();
                            }
                        });
                    };

                    /**
                     * Section 2 : Retrieve necessary objects
                     * */
                    var saveCurrentProposalModelToUpdateNewProduct = function (params) {
                        hasError = false;
                        if($scope.currentProposal && $scope.currentProposal.Role && $scope.currentProposal.Role.ProposalPersons && $scope.currentProposal.Role.ProposalPersons.length > 0) {
                            if($scope.currentProposal.Role.ProposalPersons[0] && $scope.currentProposal.Role.ProposalPersons[0].Value) {   
                                if(!$scope.currentProposal.Role.ProposalPersons[0].Value.FirstName){
                                    $scope.currentProposal.Role.ProposalPersons[0].Value.FirstName = $scope.firstName;
                                }     
                            }
                        }
                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
        
                        fn.currentProposalApi[saveCurrentProposalFunc]({
                                onSuccess: function (data) {     
                                    if(data) {
                                        saveAssetModelToUpdateNewProduct(params);
                                    }
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updatedCurrentProposal', method: 'fn.currentProposalApi'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line: 4508'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true
                                    $scope.hasError = true;
                                    saveAssetModelToUpdateNewProduct(params);
                                }
                            }
                        );
                    };

                    var saveCoveragesModelToUpdateNewProduct = function (params) {
                        hasError = false; 
                        fn.coverageApi['updateCoverages']({
                            onSuccess: function (data) {
                                if(data) {
                                    $scope.coverages.isForUpdate = true;
                                    retrieveCurrentProposal(params);    
                                }
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveCoveragesModelToUpdateNewProduct', method: 'saveCoveragesModelToUpdateNewProduct'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line: 4531'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;                
                                retrieveCurrentProposal(params);
                            }
                        });
                    };

                    var saveAssetModelToUpdateNewProduct = function (params) {
                        fn.assetApi['updateAsset']({
                            onSuccess: function (data) {
                                if(data) {
                                    saveCoveragesModelToUpdateNewProduct(params);
                                }
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveAssetModelToUpdateNewProduct', method: 'saveAssetModelToUpdateNewProduct'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4550'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;
                                saveCoveragesModelToUpdateNewProduct(params);
                            }
                        });
                    };
                    
                    var retrieveCurrentProposal = function (params) {
                        hasError = false;
                        fn.currentProposalApi.retrieveCurrentProposal({
                            onSuccess: function (data) {
                                // //####Check Previous Select Coverage######
                                // if(params == 'Dealer' || params == 'Panel'){
                                //     if(Object.keys($scope.previousProposal).length == 0) {
                                //         $scope.previousProposal = $scope.currentProposal; 
                                //     }
                                //     if($scope.previousProposal && $scope.previousProposal.Assets && $scope.previousProposal.Assets.length > 0) {
                                //         console.log('previousProposal : ',$scope.previousProposal);
                                //         let previousSelectedCoverage = $scope.previousProposal.Assets[0].Coverages.filter(w => w.IsSelected == true);
                                //         console.log('previousSelectedCoverage : ',previousSelectedCoverage);     

                                //     } 
                                // }
                                //########################################          
                                angular.extend($scope.currentProposal, data);    
                                $scope.allowBtnAction = true;
                                try {
                                    if (!$scope.currentProposal.IsRenewalProposal) {
                                        $scope.bi.whereDidYouHereUs = 'None';
                                        angular.extend($scope.bidata, {
                                            Source: "B2C"
                                        });
                                        fn.logApi.writeLog({
                                            onSuccess: function () {},
                                            onError: function (err) {
                                                if(!hasError) {
                                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveCurrentProposal', method: 'writeLog'} ;
                                                    var errorLog = {err: JSON.stringify(error), case: 'error line 4576'};
                                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                                }        
                                                hasError = true;            
                                            }
                                        });
                                    }
                                }
                                catch (err) {
                                    if(!hasError) {
                                        var error = err ? err : {fn: 'retrieveCurrentProposal', method: 'angular.extend'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 4587'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true;    
                                }
                                retrieveAsset(params);
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveCurrentProposal', method: 'angular.extend'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4597'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;
                                retrieveAsset(params);
                            }
                        });
                    };

                    var retrieveAsset = function (params) {
                        hasError = false;
                        fn.assetApi.retrieveAsset({
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                retrieveCoverages(params);
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveAsset', method: 'retrieveCoverages'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4616'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;
                                retrieveCoverages(params);
                            }
                        });
                    };

                    var retrieveCoverages = function (params) {
                        hasError = false;
                        fn.coverageApi.retrieveCoverages({
                            onSuccess: function (data) {
                                if(data && data.Coverages && data.Coverages.length > 0) {
                                    data.Coverages.map((d) => {
                                        d.compulsoryOriginal = fn.utils.getCompulsory(d.Covers,d.ProductId);
                                    });    
                                }
                                $scope.coverages.Coverages = data.Coverages;       

                                fn.utils.paymentScheduleToShow($scope.thirdPageShow);
        
                                let selectedCoverage = $scope.coverages.Coverages.find(w => w.IsSelected == true);
                                if(selectedCoverage && selectedCoverage.CoverTypeId) {
                                    $scope.getMyCoverTypeId = selectedCoverage.CoverTypeId                  
                                }  else {
                                    selectedCoverage = $scope.coverages.Coverages.find(w => w.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG)
                                    if(!selectedCoverage) {
                                        selectedCoverage = $scope.coverages.Coverages[0];
                                    }    
                                    selectedCoverage.IsSelected = true
                                    $scope.getMyCoverTypeId = selectedCoverage.CoverTypeId
                                }      
     
                                /** Added by OB For temporary coverages sequence*/
                                var coverages = [];
                                const typeThree = $filter('filter')($scope.coverages.Coverages, { CoverTypeId : $scope.TYPE3_FALCON_DG }).length > 0;
                                const typeThreePlus = $filter('filter')($scope.coverages.Coverages, { CoverTypeId : $scope.TYPE3PLUS_FALCON_DG }).length > 0;
                                if (data.CoverListsCount == 5 || data.CoverListsCount == 10) {
                                    $scope.tyepOfCoverageTable = {
                                        "b2c_popup_cover_item1": [true, true, false, false],
                                        "b2c_popup_cover_item2": [true, true, true, true],
                                        "b2c_popup_cover_item3": [true, false, true, false],
                                        "b2c_popup_cover_item4": [true, false, false, false],
                                        "b2c_popup_cover_item5": [false, false, false, false],
                                        "b2c_popup_cover_item6": [true, true, false, false]
                                    };
                                } 
                                else {
                                    if($scope.getMyCoverTypeId) {
                                        // Coverage Table ===> T1, T1S ,T2+, T3+, T2, T3,;
                                        if ($scope.getMyCoverTypeId == $scope.TYPE1_MTI_D || $scope.getMyCoverTypeId == $scope.TYPE1_MTI_G) {    
                                            $scope.tyepOfCoverageTable = {
                                                "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                                "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                                "b2c_popup_cover_item7": [true ,false , false, false, false, false],
                                                "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                            };
                                        } else if ($scope.getMyCoverTypeId == $scope.TYPE1_AXA_D || $scope.getMyCoverTypeId == $scope.TYPE1_AXA_G){
                                            $scope.tyepOfCoverageTable = {
                                                "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                                "b2c_popup_cover_item6_axa": [true ,false , false, false, false, false],
                                                "b2c_popup_cover_item7": [true ,false , false, false, false, false],
                                                // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                            };
                                        }
        
                                        else if($scope.getMyCoverTypeId == $scope.TYPE1_FALCON_DG && !typeThree && !typeThreePlus){
                                            $scope.tyepOfCoverageTable = {
                                                // Coverage Table ===> T1s, T1, T2+, T2,;
                                                "b2c_popup_cover_item1": [true ,true , true, false, false, false],
                                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                "b2c_popup_cover_item3": [true ,true , true, true, true, false],
                                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                                "b2c_popup_cover_item6": [true ,true , true, false, false, false]
                                                
                                        }
                                    }
                                        else if($scope.getMyCoverTypeId == $scope.TYPE2PLUS_FALCON_DG && typeThreePlus)
                                            {
                                                $scope.tyepOfCoverageTable = {
                                                    // Coverage Table ===> T2+, T3+, T2, T3,;
                                                    "b2c_popup_cover_item1": [true ,true , false, false, false, false],
                                                    "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                    "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                                    "b2c_popup_cover_item4": [true ,false , false, false, false, false],
                                                    "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                                    "b2c_popup_cover_item6": [true ,true , false, false, false, false]
                                                    // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                                };
                                            }
                                            else if($scope.getMyCoverTypeId == $scope.TYPE2PLUS_FALCON_DG && !typeThreePlus){
                                                $scope.tyepOfCoverageTable = {
                                                    // Coverage Table ===> T2+, T3;
                                                    "b2c_popup_cover_item1": [true ,false , false, false, false, false],
                                                    "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                    "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                                    "b2c_popup_cover_item4": [true ,false , false, false, false, false],
                                                    "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                                    "b2c_popup_cover_item6": [true ,false , false, false, false, false]
                                                    // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                                };
                                            }
                                            else if ($scope.getMyCoverTypeId == $scope.TYPE2_FALCON_DG){
                                                $scope.tyepOfCoverageTable = {
                                                    // Coverage Table ===> T2;
                                                    "b2c_popup_cover_item1": [false ,false , false, false, false, false],
                                                    "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                    "b2c_popup_cover_item3": [true ,false , true, false, true, false],
                                                    "b2c_popup_cover_item4": [false ,false , false, false, false, false],
                                                    "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                                    "b2c_popup_cover_item6": [false ,false , false, false, false, false]
                                                    // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                                };
                                            }
                                            else if($scope.getMyCoverTypeId == $scope.TYPE3_FALCON_DG)
                                                {
                                                    $scope.tyepOfCoverageTable = {
                                                        // Coverage Table ===> T3,;
                                                        "b2c_popup_cover_item1": [false ,true , true, true, false, false],
                                                        "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                        "b2c_popup_cover_item3": [false ,false , true, false, true, false],
                                                        "b2c_popup_cover_item4": [false ,false , false, false, false, false],
                                                        "b2c_popup_cover_item5": [false ,false , false, false, false, false],
                                                        "b2c_popup_cover_item6": [false ,true , false, false, false, false]
                                                        // "b2c_popup_cover_item8": [true ,false , false, false, false, false]
                                                    };
                                                }
                                        else {
                                            $scope.tyepOfCoverageTable = {
                                                "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                                "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                                "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                                "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                                "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                                "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                                };
            
                                        }
                                    } else {
                                        $scope.tyepOfCoverageTable = {
                                            "b2c_popup_cover_item1": [true ,true , true, true, false, false],
                                            "b2c_popup_cover_item2": [true ,true , true, true, true, true],
                                            "b2c_popup_cover_item3": [true ,true , true, false, true, false],
                                            "b2c_popup_cover_item4": [true ,true , true, false, false, false],
                                            "b2c_popup_cover_item5": [true ,true , false, false, false, false],
                                            "b2c_popup_cover_item6": [true ,true , true, true, false, false],
                                        };
                                    }
                                }

                                angular.extend($scope.coverages, data);
                                $scope.compulsory.isIncluded = fn.utils.IsCompulsarySelectedFrontEndFn();
                                // $scope.compulsoryChecker = $scope.compulsory.isIncluded;   
                                // marketing.dLCE(56,$scope.compulsoryChecker);     

                                selectedCoverage = fn.utils.getSelectedCoverage();
                                
                                if (selectedCoverage) {
                                    fn.utils.setSelectedCoverage(selectedCoverage);

                                    /** Added by OB for selected coverages*/
                                    for (var i in selectedCoverage.Covers) {
                                        if(selectedCoverage.ProductId == $scope.PRODUCT_ID_DA) {
                                            if (selectedCoverage.Covers[i].ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {
                                                $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessAmount = selectedCoverage.Covers[i].ExcessAmount;
                                            }    
                                        } else if(selectedCoverage.ProductId == $scope.PRODUCT_ID_AXA){
                                            if (selectedCoverage.Covers[i].ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA) {
                                                $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessAmount = selectedCoverage.Covers[i].ExcessAmount;
                                            }    
                                        }
                                    }
                                    /** ------------------------ */
                                } 
                                else {
                                    fn.utils.setDefaultCoverage();
                                }

                                fn.utils.setSumInsured();
                                $scope.coverages.isForUpdate = true;

                                if (selectedCoverage) {
                                    if (selectedCoverage.CoverTypeId == $scope.TYPECOMPULSORY_FALCON_DG) {
                                        $scope.isCompulsoryOnly = true;
                                    } 
                                    else {
                                        $scope.isCompulsoryOnly = false;
                                    }
                                } 
                                else {
                                    $scope.isCompulsoryOnly = false;
                                }
                                retrieveExcess(params);
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveCoverages', method: 'getSelectedCoverage'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4761'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;
                                retrieveExcess(params);
                            }
                        });
                    };
                    var retrieveExcess = function (params) {
                        hasError = false;

                        ApiServiceV2.getVehicleExcess({
                        onSuccess: function (data) {
                                if (data && data.length > 0) {
                                    data.sort((n1,n2) => {
                                        if (n1.amount > n2.amount) {
                                            return 1;
                                        }
                                    
                                        if (n1.amount < n2.amount) {
                                            return -1;
                                        }
                                    
                                        return 0;
                                    });
    
                                    data.map((dt) =>{
                                        dt.key = dt.level.toString();
                                        dt.value = Number(dt.amount) + '.00';
                                    })
                                    if(data[0] && data[0].value) {
                                        $scope.allExcess = data[0].value;
                                    }
                                    $scope.selectedCoverage.excess = data;

                                    for (var i in $scope.selectedCoverage.Covers) {
                                        if($scope.selectedCoverage.ProductId  == $scope.PRODUCT_ID_DA){
                                            if ($scope.selectedCoverage.Covers[i].ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {
                                                $scope.ExcessLevelId = $scope.selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = $scope.ExcessLevelId;
                                            }    
                                        } else if($scope.selectedCoverage.ProductId  == $scope.PRODUCT_ID_AXA){
                                            if ($scope.selectedCoverage.Covers[i].ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA) {
                                                $scope.ExcessLevelId = $scope.selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = $scope.ExcessLevelId;
                                            }    
                                        }
        
                                    }        

                                    angular.forEach( $scope.selectedCoverage.excess, function (selected,key)  {                       
                                        if(fn.utils.isSelectedExcess(selected.key)){
                                            marketing.dLCE(57,selected.value);
                                        } 
                                    });
                                }
                                $scope.$parent.showPriceOnTopFlag = true;
                                retrievePolicyHolder(params);
                        },
                        onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveExcess', method: 'getVehicleExcess'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4823'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    
                                $scope.$parent.showPriceOnTopFlag = true;
                                retrievePolicyHolder(params);

                        }
                    }, 'VEHICLE_EXCESS', `formulaId=${$scope.selectedCoverage.CoverTypeId}&productVersionId=${$scope.selectedCoverage.ProductVersionId}`);

                    };

                    /** PolicyContact retrieval: this will retrieve MD if PH is not MD */
                    var retrievePolicyHolder = function (params) {
                        hasError = false;
                        fn.policyHolderApi.retrievePHPolicyContact({
                            onSuccess: function (data) {
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data, $scope.policyHolder);
                                }

                                angular.extend($scope.policyHolder, data);

                                if ($scope.policyHolder.FullName.indexOf(" ") > -1
                                    && ($scope.policyHolder.LastName === null || $scope.policyHolder.LastName === undefined || $scope.policyHolder.LastName === "")) {
                                    const getSplitFullname = $scope.policyHolder.FullName.split(' ').map(element => element.trim()).filter(element => element !== '');    
                                    $scope.firstName = getSplitFullname[0];
                                    $scope.lastName = getSplitFullname[1] ? getSplitFullname[1] : '-';
                                } 
                                else {
                                    $scope.firstName = $scope.policyHolder.FirstName;
                                    $scope.lastName = $scope.policyHolder.LastName;
                                }

                                $scope.policyHolder.FirstName = $scope.firstName;
                                $scope.policyHolder.LastName = $scope.lastName;

                                if ($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                    $scope.policyHolder.SubscribedMarketingEmail = false;
                                } 
                                else {
                                    $scope.policyHolder.SubscribedMarketingEmail = true;
                                }
                                if ($scope.policyHolder.DateOfBirth) {
                                    fn.utils.ConvertMITDateToDisplay();
                                }

                                
                                $scope.$parent.showPriceOnTopFlag = true;

                                let selectedCoverages;
                                let isIncludeCompulsory = true;
                                // let isIncludeCompulsory = $scope.compulsoryChecker;
                                $scope.compulsory.isIncluded = false;
                                $scope.compulsory.isFontEndForce = true;           
                                if($scope.coverages.Coverages && $scope.coverages.Coverages.length > 0) {
                                    selectedCoverages = $scope.coverages.Coverages.find(w => w.IsSelected == true);
                                    if(!selectedCoverages) {
                                        selectedCoverages = $scope.coverages.Coverages[0];
                                    }    
                                    let compulsory = fn.utils.getIncludeCompulsoryByCoverage(selectedCoverages);
                                    if(compulsory){
                                        $scope.compulsory.isIncluded = compulsory.IsSelected;
                                        $scope.compulsory.isFontEndForce = compulsory.IsSelected !== $scope.compulsoryChecker;
                                    }
                                }

                                // $scope.compulsoryChecker = true;

                                if (data === undefined) {
                                    retrieveMainDriver(params);
                                    if($scope.isCalculateAction) {
                                        $scope.countCalculateFirstClick = true;
                                        $scope.setCompulsory(isIncludeCompulsory,'firstCal');
                                        fn.updatedCurrentProposal(callbacksA,'firstCal',params);
                                    }
                                }
                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                    if($scope.isCalculateAction) {
                                        $scope.countCalculateFirstClick = true;
                                        $scope.setCompulsory(isIncludeCompulsory,'firstCal');
                                        fn.updatedCurrentProposal(callbacksA,'firstCal',params);
                                    }
                                } 
                                else {
                                    retrieveMainDriver(params);
                                    if($scope.isCalculateAction) {
                                        $scope.countCalculateFirstClick = true;
                                        $scope.setCompulsory(isIncludeCompulsory,'firstCal');
                                        fn.updatedCurrentProposal(callbacksA,'firstCal',params);
                                    }
                                }
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrievePolicyHolder', method: 'retrievePHPolicyContact'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4919'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    

                                $scope.loadingSpinner2 = false;
                                $scope.loadingSpinner3 = false;
                                $scope.$parent.showPriceOnTopFlag = true;
                            }
                        });
                    };

                    var retrieveMainDriver = function (params) {
                        hasError = false;
                        fn.mainDriverApi.retrieveMDPolicyContact({
                            onSuccess: function (data) {
                                angular.extend($scope.mainDriver, data);
                                if ($scope.mainDriver.DateOfBirth) {
                                    fn.utils.ConvertMITDateToDisplay();
                                }
                                if ($scope.mainDriver.IsMainDriver) {
                                    $scope.policyHolder.IsMainDriver = false;
                                }
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveMainDriver', method: 'retrieveMDPolicyContact'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 4946'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    
                            }
                        });
                    };
                    
                    var callbacksA = {
                        onSuccess: function (firstCal,params) {
                            fn.saving.savePHContactToProceed(callbacksB,firstCal,params);
                        }
                    };
                    
                    var callbacksB = {
                        onSuccess: function (firstCal,params) {
                            fn.utils.paymentScheduleToShow($scope.thirdPageShow, undefined ,undefined);
                            fn.saving.saveOtherDetailsPartSave(callbackSuccessCMIUpdate,firstCal ? false : true,params);
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'callbacksB', method: 'saveOtherDetailsPartSave'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 4968'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    

                            fn.saving.saveOtherDetailsPartSave(callbackSuccessCMIUpdate,firstCal ? false : true,params);
                        }
                    }; 
                    var callbackSuccessCMIUpdate = {
                        onSuccess: function () {
                            console.log("saveOtherDetailsPartSave is successed")
                        }
                    };
     

                    triggerSave();
                },

                getSaveFunctionName: function (model, functionName) {
                    var saveFunc = model.isForUpdate ? 'update' : 'create';
                    return saveFunc + functionName;
                },

                calculatePremium: function (callbacks) {
                    fn.quoteApi.calculate(callbacks);
                },

                savePHAddress: function (callbacks) {
                    hasError = false;
                    if($scope.phAddress.CityName){
                        $scope.phAddress.CityName = $scope.phAddress.CityName.replace(/[|]+/g, "/");
                    }
                    if($scope.phAddress.HouseNumber){
                        $scope.phAddress.HouseNumber = $scope.phAddress.HouseNumber.replace(/[|]+/g, "/");
                    }
                    if($scope.phAddress.BuildingName){
                        $scope.phAddress.BuildingName = $scope.phAddress.BuildingName.replace(/[|]+/g, "/");
                    }
                    if($scope.phAddress.RegionName){
                        $scope.phAddress.RegionName = $scope.phAddress.RegionName.replace(/[|]+/g, "/");
                    }

                    var savePHAddressFunc = fn.phAddressApi.utils.getSaveFunctionName($scope.phAddress, 'PHAddress');

                    fn.phAddressApi[savePHAddressFunc](
                        {
                            onSuccess: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHAddress', method: 'phAddressApi'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 5016'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    

                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            }
                        }
                    );
                },

                saveOtherDetailsPartSave: function (callbacks,isCMI, panelOrDealer) {
                    hasError = false;
                    // fn.utils.setIsSaving(false);

                    fn.essentialApi.partialSave({

                        onSuccess: function (data) {

                            if(!isCMI) {
                                $scope.partialPolicyNumber = data['QuoteNumber'];
                                fn.utils.setIsSaving(false);
    
                                let mitSessionTokenID = $sessionStorage.quoteSession.motor.session.MITSessionToken ? $sessionStorage.quoteSession.motor.session.MITSessionToken : null ;
                                $scope.MITSessionTokenID = mitSessionTokenID;
                                marketing.dLCE(45, $scope.partialPolicyNumber);
                                marketing.dLCE(74, $scope.MITSessionTokenID);    
                            }


                            if (callbacks && callbacks.onSuccess) {
                                callbacks.onSuccess();
                            }

                            if(!isCMI) {
                                fn.currentProposalApi.retrieveCurrentProposal({
                                    onSuccess: function (data) {
                                        if(data && data.Assets && data.Assets.length > 0){
                                            data.Assets[0].Coverages.map((d) => {
                                                d.compulsoryOriginal = fn.utils.getCompulsory(d.Covers,d.ProductId);
                                                if($scope.compulsory.isFontEndForce && $scope.recalculate.isNeeded && $scope.coverages.Coverages && $scope.coverages.Coverages.length > 0){
                                                    let getCoverage = $scope.coverages.Coverages.find(w => w.CoverTypeId == d.CoverTypeId);
                                                    if(getCoverage && getCoverage.PaymentSchedule && getCoverage.PaymentSchedule.length > 0){
                                                        d.PaymentSchedule = getCoverage.PaymentSchedule;
                                                    }
                                                    if(getCoverage && getCoverage.CalculatedPremium){
                                                        d.CalculatedPremium = getCoverage.CalculatedPremium;
                                                    }
                                                }
                                            });    
                                            if(data.Assets[0].Coverages && data.Assets[0].Coverages.length > 0){
                                                $scope.coverages.Coverages = data.Assets[0].Coverages;
                                            }
                                            if($scope.compulsory.isFontEndForce && $scope.recalculate.isNeeded && $scope.currentProposal.PaymentSchedules && $scope.currentProposal.PaymentSchedules.length > 0){
                                            data.PaymentSchedules = $scope.currentProposal.PaymentSchedules;
                                            data.FirstPayment = $scope.currentProposal.FirstPayment;
                                            data.TotalPremium = $scope.currentProposal.TotalPremium;
                                            }

                                        }
                                        angular.extend($scope.currentProposal, data);
                                        fn.utils.paymentScheduleToShow($scope.thirdPageShow, undefined ,undefined);
                                        // $scope.loadingSpinner = false;
                                        // $scope.loadingSpinner2 = false;
                                        // $scope.loadingSpinner3 = false;
                                        // fn.utils.setIsSaving(false);
                                        // $scope.recalculate.isNeeded = false;


                                        if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG || $scope.selectedCoverage.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG) {
                                            fn.quoteApi.getInsuranceAmount({
                                                onSuccess: function (data) {
                                                    if (data && data.length > 0) {
                                                        fn.utils.sortInsuranceAmount(data);
                                                        $scope.customInsuranceAmount = data;
                                                        fn.setDefaultInsureAmount();
                                                    }
                                                },
                                                onError: function (err) {
                                                    if(!hasError) {
                                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveOtherDetailsPartSave', method: 'getInsuranceAmount'} ;
                                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 5099'};
                                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                                    }
                                                    hasError = true;
            
                                                }
                                            })
                                        }
                                        // T1, T1S
                                        if (($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_FALCON_DG)||
                                            ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1SAVE_FALCON_DG) ||
                                            ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_G) ||
                                            (($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_D)) ||
                                            ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_G) ||
                                            (($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_D))
                                            ) {

                                            var defaultPriorityJudge = function () {
                                                if ($scope.thirdPageShow) {
                                                    fn.postToMaxParent('No',3,'N', '');
                                                    fn.postToAWS(3);
                                                }
                                                else {
                                                    fn.postToMaxParent('No',5,'N', '');
                                                    fn.postToAWS(5);
                                                }
                                            }
                                                                                            
                                            if($scope.isCalculateAction 
                                                ){

                                                $sessionStorage.noOfPTSave = $sessionStorage.noOfPTSave + 1;

                                                // set "GetPriority API" for Propensity-Model
                                                fn.getPriority(function (priority) {
                                                    if (priority != 0) {
                                                        if (
                                                            $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_G ||
                                                            $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_D ||
                                                            $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_G ||
                                                            $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_D
                                                        ) {
                                                            const computedPriority = fn.utils.computeAggregatorPriority(priority, $scope.currentProposal, $scope.staticData);
                                                            fn.postToMaxParent('No', computedPriority, 'N', '');
                                                            fn.postToAWS(computedPriority);
                                                        } else {
                                                            fn.postToMaxParent('No', priority, 'N', '');
                                                            fn.postToAWS(priority);
                                                        }
                                                    } 
                                                    else {
                                                        defaultPriorityJudge();                                                    
                                                    }
                                                    $scope.isCalculateAction = false;

                                                })
                                            }

                                        }

                                        // T2+
                                        if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG) {
                                            if($localStorage.priority == 20){
                                                fn.postToMaxParent('No', 26, 'N', '');
                                                fn.postToAWS(26);
                                                $scope.isCalculateAction = false;
                                            }
                                            else if($localStorage.priority == 23){
                                                fn.postToMaxParent('No', 27, 'N', '');
                                                fn.postToAWS(27);
                                                $scope.isCalculateAction = false;
                                            }
                                            else if($localStorage.priority == 24){
                                                fn.postToMaxParent('No', 28, 'N', '');
                                                fn.postToAWS(28);
                                                $scope.isCalculateAction = false;
                                            }
                                            else if($localStorage.priority == 25){
                                                fn.postToMaxParent('No', 29, 'N', '');
                                                fn.postToAWS(29);
                                                $scope.isCalculateAction = false;
                                            }
                                            else{
                                                // set "GetPriority API" for Propensity-Model
                                                fn.getPriority(function (priority) {
                                                    if(priority == 20){
                                                        fn.postToMaxParent('No', 26, 'N', '');
                                                        fn.postToAWS(26);
                                                    }
                                                    else if(priority == 23){
                                                        fn.postToMaxParent('No', 27, 'N', '');
                                                        fn.postToAWS(27);
                                                    }
                                                    else if(priority == 24){
                                                        fn.postToMaxParent('No', 28, 'N', '');
                                                        fn.postToAWS(28);
                                                    }
                                                    else if(priority == 25){
                                                        fn.postToMaxParent('No', 29, 'N', '');
                                                        fn.postToAWS(29);
                                                    }
                                                    else{
                                                        fn.postToMaxParent('No', priority ? priority : 4, 'N', '');
                                                        fn.postToAWS(priority ? priority : 4);
                                                    }
                                                    $scope.isCalculateAction = false;   
                                                });   
                                            }
                                        }

                                        // T3+
                                        if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG) {

                                            // set "GetPriority API" for Propensity-Model
                                            fn.getPriority(function (priority) { /* do nothing */ })

                                            if(($scope.firstPageShow)||($scope.secondPageShow)){
                                                fn.postToMaxParent('No',5,'N', '')
                                                fn.postToAWS(5);
                                                $scope.isCalculateAction = false;
                                            }
                                            if(($scope.thirdPageShow)&&((!$scope.goToFourthPage))){
                                                fn.postToMaxParent('No',3,'N', '')
                                                fn.postToAWS(3);
                                                $scope.isCalculateAction = false;
                                            }                                                                                                
                                            if(($scope.thirdPageShow)&&($scope.goToFourthPage)){
                                                fn.postToMaxParent('No',1,'N', '')
                                                fn.postToAWS(1);
                                                $scope.isCalculateAction = false;
                                            }
                                        }

                                        // T2, T3
                                        if (
                                            $scope.selectedCoverage.CoverTypeId == $scope.TYPE2_FALCON_DG ||
                                            $scope.selectedCoverage.CoverTypeId == $scope.TYPE3_FALCON_DG
                                        ) {
                                            if ($scope.firstPageShow || $scope.secondPageShow) {
                                                fn.postToMaxParent('No', 15, 'N', '')
                                                fn.postToAWS(15);
                                                $scope.isCalculateAction = false
                                            }
                                            if ($scope.thirdPageShow && $scope.goToFourthPage) {
                                                fn.postToMaxParent('No', 1, 'N', '')
                                                fn.postToAWS(1);
                                                $scope.isCalculateAction = false
                                            }
                                        }

                                        //####Check Previous Select Coverage######
                                        if(panelOrDealer == 'Dealer' || panelOrDealer == 'Panel'){
                                            if($scope.previousCoverages && $scope.previousCoverages.length > 0) {
                                                // console.log('$scope.$parent.previousProposal : ',$scope.$parent.previousProposal);     
                                                let previousSelectedCoverage = $scope.previousCoverages.find(w => w.IsSelected == true);
                                                let currentSelectedCoverage = $scope.currentProposal.Assets[0].Coverages.find(w => w.IsSelected == true);
                                                // console.log('previousSelectedCoverage : ',previousSelectedCoverage);     
                                                // console.log('currentSelectedCoverage : ',currentSelectedCoverage);     
                                                if(previousSelectedCoverage && previousSelectedCoverage.CoverTypeId && currentSelectedCoverage && currentSelectedCoverage.CoverTypeId && previousSelectedCoverage.CoverTypeId != currentSelectedCoverage.CoverTypeId){
                                                    let checkHasPreviousCoverage = $scope.currentProposal.Assets[0].Coverages.find(w => w.CoverTypeId == previousSelectedCoverage.CoverTypeId);
                                                    if(checkHasPreviousCoverage) {
                                                        $scope.selectCoverage(checkHasPreviousCoverage);
                                                    }

                                                }
                                            } 
                                        }
                                        //########################################

                                        $scope.loadingSpinner = false;
                                        $scope.loadingSpinner2 = false;
                                        $scope.loadingSpinner3 = false;
                                        // fn.utils.setIsSaving(false);
                                        $scope.recalculate.isNeeded = false;


                                    },
                                    onError: function (err) {
                                        if(!hasError) {
                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveOtherDetailsPartSave', method: 'retrieveCurrentProposal'} ;
                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 5193'};
                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                        }        
                                        hasError = true;        
                                        $scope.loadingSpinner = false;
                                        $scope.loadingSpinner2 = false;
                                        $scope.loadingSpinner3 = false;
                                        $scope.recalculate.isNeeded = false;
                                        fn.utils.setIsSaving(false);
                                    }
                                });
                            }
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveOtherDetailsPartSave', method: 'partialSave'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 5209'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;        
                            fn.utils.setIsSaving(false);
                        }
                    });
                },

                confirmProposal: function (callbacks) {
                    hasError = false;
                    fn.confirmationApi.createConfirmation({
                        onSuccess: function () {
                            if (callbacks && callbacks.onSuccess) {
                                callbacks.onSuccess();
                            }
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'confirmProposal', method: 'createConfirmation'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 5229'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;        
                            hasError = true;        
                            fn.utils.setIsSaving(false);
                        }
                    });
                },

                issuePolicy: function (callbacks) {
                    hasError = false;
                    fn.confirmationApi.issuePolicy({
                        onSuccess: function () {
                            if (callbacks && callbacks.onSuccess) {
                                callbacks.onSuccess();
                            }
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'issuePolicy', method: 'issuePolicy'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 5250'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;        

                        }
                    });
                },
                saveCoveragesModelToSwitchNewProductParent: function (callbacks) {
                    fn.saving.saveCoveragesModelToSwitchNewProduct({
                        onSuccess: function () {
                            fn.logApi.updateLog();
                            // DATH-1137
                            fn.updateAssetMaxarPriority();

                            fn.updatedCurrentProposalModelToUpdateNewProductSwitching();

                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'scope.watch.totalPremium2', method: 'saveCoveragesModel'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 5271'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;
                            $scope.loadingSpinner3 = false;
                        }
                    });
                },
                saveCoveragesModelParent: function (callbacks) {
                    fn.saving.saveCoveragesModel({
                        onSuccess: function () {
                            fn.logApi.updateLog();
                            // DATH-1137
                            fn.updatedCurrentProposalModelToUpdateNewProduct();

                            fn.updateAssetMaxarPriority();
    
                            if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG ||
                                $scope.selectedCoverage.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG) {
                                fn.quoteApi.getInsuranceAmount({
                                    onSuccess: function (data) {
                                        if (data && data.length > 0) {
                                            fn.utils.sortInsuranceAmount(data);
                                            $scope.customInsuranceAmount = data;
                                            fn.setDefaultInsureAmount();
                                        }
                                    },
                                    onError: function (err) {
                                        if(!hasError) {
                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'scope.watch.totalPremium2', method: 'updateAssetMaxarPriority'} ;
                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 5300'};
                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                        }
                                        hasError = true;

                                    }
                                });
                            }
                            $scope.loadingSpinner3 = false;
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'scope.watch.totalPremium2', method: 'saveCoveragesModel'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 5313'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }
                            hasError = true;
                            $scope.loadingSpinner3 = false;
                        }
                    });
                },

                saveCoveragesModel: function (callbacks) {
                    hasError = false;
                    var triggerSave = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        hasError = false;
                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');

                        fn.coverageApi[saveCoverageFunc]({
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;

                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveCoverages', method: 'getSaveFunctionName'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 5343'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true;                
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },
                saveCoveragesModelToSwitchNewProduct: function (callbacks) {
                    hasError = false;
                    var triggerSave = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        hasError = false;
                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc]({
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;

                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveCoverages', method: 'getSaveFunctionName'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 5375'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true;                
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },

                savePHContactToProceed: function (callbacks,firstCal,params) {
                    hasError = false;
                    fn.utils.setIsSaving(true);
                    var savePH = function () {
                        if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }
                        if ($scope.policyHolder.IsMainDriver) {  // PH == MD
                            $scope.policyHolder.ConsentId = 7000000;
                            $scope.mainDriver.ConsentId = 7000000;

                        } else {  // PH != MD
                            $scope.policyHolder.ConsentId = 7000002;
                            $scope.mainDriver.ConsentId = 7000000;
                        }
                        var tmpId = $scope.policyHolder.Identifier;
                        if (tmpId) {
                            $scope.policyHolder.Identifier = tmpId.replace(/-/g, "");
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function () {
                                    saveMD(callbacks,firstCal,params);
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContactToProceed', method: 'savePH'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 5421'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true;                

                                    saveMD(callbacks,firstCal,params);
                                }
                            }
                        );
                    }

                    var saveMD = function (callbacks,firstCal,params) {
                        hasError = false;
                        var tmpId = $scope.mainDriver.Identifier;
                        if (tmpId) {
                            $scope.mainDriver.Identifier = tmpId.replace(/-/g, "");
                        }

                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');

                        if (saveMDFunc === null) {
                            callbacks.onSuccess(firstCal,params);
                        } else {
                            fn.mainDriverApi[saveMDFunc](
                                {
                                    onSuccess: function () {
                                        callbacks.onSuccess(firstCal,params);
                                    },
                                    onError: function (err) {
                                        if(!hasError) {
                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveMD', method: 'mainDriverApi'} ;
                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 5452'};
                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                        }        
                                        hasError = true;
                                        callbacks.onSuccess(firstCal,params);
                                    }
                                }
                            );
                        }
                    };

                    savePH();
                },

                savePHContactCallback: function () {
                    hasError = false;
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc]({
                            onSuccess: function () {
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function () {

                                        //If Proposal
                                        if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10){
                                            fn.confirmationApi.createConfirmation({
                                                onSuccess: function () {
                                                    fn.utils.setIsSaving(false);
                                                    DaModal.close('#quotationCallback');
                                                },
                                                onError: function (err) {
                                                    if(!hasError) {
                                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContactCallback', method: 'phContactApi'} ;
                                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 5486'};
                                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                                    }        
                                                    hasError = true;
            
                                                    fn.utils.setIsSaving(false);
                                                }
                                            });
                                        }

                                        else {
                                            fn.essentialApi.partialSave({
                                                onSuccess: function (data) {
                                                    fn.utils.setIsSaving(false);
                                                    DaModal.close('#quotationCallback');
                                                },
                                                onError: function (err) {
                                                    if(!hasError) {
                                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContactCallback', method: 'partialSave'} ;
                                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 5505'};
                                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                                    }        
                                                    hasError = true;

                                                    fn.utils.setIsSaving(false);
                                                }
                                            });
                                        }

                                    }
                                });
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'savePHContactCallback', method: 'phContactApi'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 5521'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;

                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                getSaveFunctionNameContact: function (model, functionName) {
                    if (model.isForUpdate && model.isForDelete) {
                        return 'deletePolicyContact';
                    }
                    else if (model.isForDelete) {
                        return null;
                    }

                    if (model.isForUpdate && !model.isForDelete) {
                        return 'updatePolicyContact';
                    }

                    return 'create' + functionName;
                },
                isUserExists: function (callbacks) {
                    var hasError = false;
                    callbacks = callbacks || {};

                    fn.essentialApi.isUserExist({
                        onSuccess: function (data) {
                            if (!data.success) {
                                if (callbacks.onError) {
                                    callbacks.onError(data);
                                }
                                return;
                            }

                            var cvrList = [];
                            angular.forEach(data.persons, function (personItem) {
                                var person = personItem.Value;
                                if ((!person) || (!person.CVR)) {
                                    if(personItem.Key.includes("ND")){
                                        person.CVR = {};
                                    }
                                    else{
                                        return;
                                    }
                                }
                                person.CVR.RoleName = personItem.Key;
                                cvrList.push(person.CVR);
                            });

                            var isPromptNeeded = fn.essentialApi.isPromptNeeded(cvrList);

                            if (isPromptNeeded) {
                                if (callbacks.onError) {
                                    callbacks.onError(cvrList);
                                }
                                return;
                            }

                            if (callbacks.onSuccess) {
                                callbacks.onSuccess(cvrList);
                            }

                        },

                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'isUserExists', method: 'fn.essentialApi.isUserExist'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 5587'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    

                            if (callbacks.onError) {
                                fn.utils.clearLoading();  
                                DaModal.open('#userMismatchValidation');
                                //this will trigger generic oops
                                fn.utils.setIsSaving(false);
                            }
                        }
                    });

                },
                saveSingleNamedDriverModel: function (callbacks) {
                    var hasError = false;
                    if ($scope.namedDriver.Identifier) {
                        if ($scope.namedDriver.ResidentialStatusId == 2000000 || $scope.namedDriver.ResidentialStatusId === '2000000')
                            $scope.namedDriver.Identifier = $scope.namedDriver.Identifier.replace(/[^0-9\.]+/g, "");
                    }
                    var namedDriver = $scope.namedDriver;


                    var cb = function () {

                        if (callbacks && callbacks.onSuccess) {
                            callbacks.onSuccess();
                        }
                        else if (hasError) {
                            if (callbacks && callbacks.onError) {
                                callbacks.onError();
                            }
                        }

                        fn.retrieveNamedDrivers();

                        return false;
                    };

                    if (!namedDriver.RelationshipIdWithPH) {
                        namedDriver.RelationshipIdWithPH = 1000007;
                    }
                    if(namedDriver.FirstName || namedDriver.LastName) {
                        namedDriver.FullName = `${namedDriver.FirstName} ${namedDriver.LastName}`;
                    }

                    var saveFunc = fn.saving.getSaveFunctionNameContact(namedDriver, 'NDPolicyContact');

                    if (!saveFunc) {
                        cb();
                        return;
                    }

                    var namedDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, namedDriver);

                    namedDriverApi[saveFunc](
                        {
                            onSuccess: function () {
                                namedDriver.isForUpdate = true;
                                fn.gtAnchor("#named_driver");
                                cb();
                            },

                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'saveSingleNamedDriverModel', method: 'namedDriverApi'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 5654'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    

                                fn.gtAnchor("#named_driver");
                                cb();
                            }
                        }
                    );

                },

            },

            validations: {
                isCoverNotAvail: function (coverageTypeId) {
                    return HKVehicleValidationService.isCoverNotAvail($scope.asset.PurchasingYear, 1, coverageTypeId);
                },

                checkNCDLevel: function () {
                    if ($scope.asset.NcdLevelId !== '1000003' || $scope.asset.NcdLevelId != 1000003) {
                        $scope.asset.ReasonForNcdIsZero = '';
                        $scope.asset.NcdLevelIdOnOtherCar = '';
                    } else {

                    }

                    if ($scope.asset.NcdLevelId == 1000009 || $scope.asset.NcdLevelId == "1000009") {
                        $scope.$parent.applyNCBRootDontKnow = true;
                    } else {
                        $scope.$parent.applyNCBRootDontKnow = false;
                    }
                },

                checkDOBEmpty: function () {
                    if (typeof $scope.mainDriver.DOB_DD === 'undefined' || typeof $scope.mainDriver.DOB_MON === 'undefined' || typeof $scope.mainDriver.DOB_Year === 'undefined') {
                        $scope.DOBEmpty = true;
                    }
                },

                checkMake: function () {
                    if ($scope.asset.ManufactureId == "0000000" || $scope.asset.ManufactureId == 0) {
                        $scope.frmAboutCar.vehicleMake.$setValidity("valid", false);
                    }
                    else {
                        $scope.frmAboutCar.vehicleMake.$setValidity("valid", true);
                    }
                },

                isStartDateInvalid: function () {

                    if (!$scope.currentProposal.PolicyStartDate) return;

                    var startDate = MITDate.newDate($scope.currentProposal.PolicyStartDate);
                    var isMinConditionValid = startDate.diff($scope.policyMinStartDate, 'days') >= 0;
                    var isMaxConditionValid = startDate.diff($scope.policyMaxStartDate, 'days') <= 0;

                    if (!isMinConditionValid) {
                        $scope.frmAboutYourPolicy.policyStartDateDD.$setValidity('custom', true);
                        return true;
                    }

                    if (isMaxConditionValid) { // "true" for the first time
                        $scope.frmAboutYourPolicy.policyStartDateDD.$setValidity('custom', true);
                        return false;
                    } 
                    else {
                        $scope.frmAboutYourPolicy.policyStartDateDD.$setValidity('custom', false);
                        return true;
                    }
                },

                isEndDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidEndDate($scope.currentProposal.PolicyEndDate, $scope.currentProposal.PolicyStartDate, formElement);
                },

                validateForms: function () {
                    return $scope.frmAboutMainDriver.$invalid || $scope.frmAboutCar.$invalid || $scope.frmAboutYourPolicy.$invalid || $scope.frmContactInfo.$invalid;
                },

                validateForms2: function () {
                    return (
                        $scope.frmAboutMotor.$invalid ||
                        $scope.frmAboutMainDriver2.$invalid ||
                        $scope.frmAboutPH.$invalid ||
                        $scope.frmYourAdress.$invalid ||
                        (+$scope.mainDriver.ResidentialStatusId === 2000000 && !fn.validations.isTHIDValidMD()) ||
                        (+$scope.policyHolder.ResidentialStatusId === 2000000 && !fn.validations.isTHIDValidPH())
                    );
                },            

                hasInvalid: function hasInvalid() {
                    
                    var isInvalid = false;

                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if (!$scope.asset.consentPrivacyFlag) {
                        isInvalid = true;
                        $scope.policyHolder.ConsentId = 7000001;
                    } 

                    else {
                        //$('#consentpopup').popover('hide');
                        $scope.policyHolder.ConsentId = 7000000;
                    }

                    return isInvalid;
                },

                checkDrivingExp: function () {
                    $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', true)
                },
                isTHIDValidPH: function () {
                    if ($scope.policyHolder.Identifier)
                        return HKFormValidation.validateTHID($scope.policyHolder.Identifier);
                },

                isTHIDValidMD: function () {
                    if ($scope.mainDriver.Identifier)
                        return HKFormValidation.validateTHID($scope.mainDriver.Identifier);
                },

                isTHIDValidND: function () {
                    if ($scope.namedDriver.Identifier)
                        return HKFormValidation.validateTHID($scope.namedDriver.Identifier);

                },
                validateNDForms: function (frmAboutNamedDriver) {
                    return frmAboutNamedDriver.$invalid;
                },
                validateNDForms2: function (frmAboutNamedDrivers2) {
                    return $scope.frmAboutNamedDriver2.$invalid;
                },
                checkNoOfAcc: function () {
                    $scope.frmAboutMainDriver.accidentsInThreeYearsMD.$setValidity('noOfAcci', true);
                },

                checkNoOfAccND: function (nd, formElement) {
                    /*lessThanTwoDrivingExp*/
                    if (nd.NrOfClaimsInPeriodId) {
                        if (nd.NrOfClaimsInPeriodId == 1000005 || nd.NrOfClaimsInPeriodId == "1000005"
                            || nd.NrOfClaimsInPeriodId == 1000013 || nd.NrOfClaimsInPeriodId == "1000013"
                            || nd.NrOfClaimsInPeriodId == 1000014 || nd.NrOfClaimsInPeriodId == "1000014"
                            || nd.NrOfClaimsInPeriodId == 1000007 || nd.NrOfClaimsInPeriodId == "1000007"
                            || nd.NrOfClaimsInPeriodId == 1000007 || nd.NrOfClaimsInPeriodId == "1000007") {
                            /*Less than 2 driving experience.*/
                            fn.utils.clearLoading(); 

                            DaModal.open("#NoofAccidents");
                            formElement.$setValidity('noOfAcci', false);
                            return;
                        }
                    }
                    formElement.$setValidity('noOfAcci', true);
                },
                checkDrivingExpND: function () {
                    $scope.DrivingExperienceError = false;
                    if ($scope.namedDriver.DrivingExperienceId) {
                        if ($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2") {
                            //Less than 2 driving experiencee.
                            $scope.DrivingExperienceError = true;
                            return;
                        }
                    }
                    $scope.namedDriver.DrivingExperienceError = false;
                },

            },

            eventHandlers: {

                updateDeductibleAsset: function (excess,compulsoryIsIncluded,isDeduct) {
                    var hasError = false;
                    // Call custome function
                    // To check contact customer details before sent to MAXAR
                    fn.eventHandlers.onCustomerLeadDetailsChecker()

                    // Face missing fields before send to MAXAR
                    if($scope.warningCounter > 0){
                        // maintain the old state of button
                        fn.eventHandlers.onMaxarMissingLeadFieldsChecker()                       
                    } else {                

                        $scope.loadingSpinner3 = true;
                        $scope.$parent.showPriceOnTopFlag = false;

                        for (var i in $scope.coverages.Coverages) {

                            // for DA
                            if ($scope.coverages.Coverages[i].ProductId == $scope.PRODUCT_ID_DA) {
                                // for "Type 1"
                                if ($scope.coverages.Coverages[i].CoverTypeId == $scope.TYPE1_FALCON_DG) {
                                    
                                    // $scope.coverages.Coverages[i].ExcessLevelId = $scope.selectedCoverage.ExcessLevelId; //Old
                                    if(excess && excess.key) {
                                        $scope.coverages.Coverages[i].ExcessLevelId = Number(excess.key);
                                        $scope.coverages.Coverages[i].ExcessAmount = Number(excess.value);    
                                    }

                                    var getIndexOfCoverTypeId = $scope.asset.Coverages.findIndex(w => w.CoverTypeId == $scope.TYPE1_FALCON_DG);
                                    if(getIndexOfCoverTypeId != -1){
                                        var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.asset.Coverages[getIndexOfCoverTypeId]);
                                        if(getIndexOfCover != -1) {
                                            if(excess && excess.key) {
                                                $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                                $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                            }
                                        }
                                    }

                                    if($scope.currentProposal && $scope.currentProposal.Assets.length > 0) {
                                        if($scope.currentProposal.Assets[0] && $scope.currentProposal.Assets[0].Coverages) {
                                            var getIndexOfCoverTypeId = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == $scope.TYPE1_FALCON_DG);
                                            if(getIndexOfCoverTypeId != -1){
                                                var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId]);
                                                if(getIndexOfCover != -1) {
                                                    if(excess && excess.key) {
                                                        $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                                        $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                                    }
                                                }
                                            }        
                                        }
                                    }

                                    for (var j in $scope.coverages.Coverages[i].Covers) {
                                        // if ($scope.coverages.Coverages[i].Covers[j].CoverName == $scope.PRODUCT_LINE_GROUP_VALUE_VOLUNTARY_BASIC_COVER) {
                                        var productLineGroupId = $scope.coverages.Coverages[i].Covers[j].ProductLineGroupId; //New check
                                        if (productLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {            
                                            if(excess && excess.key) {
                                                $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = Number(excess.key);
                                                $scope.coverages.Coverages[i].Covers[j].ExcessAmount = Number(excess.value);
                                            }
                                        }
                                    }
                                }

                                // for "Type 1 Save"
                                if ($scope.coverages.Coverages[i].CoverTypeId == $scope.TYPE1SAVE_FALCON_DG) {
                                    if(excess && excess.key) {
                                        $scope.coverages.Coverages[i].ExcessLevelId = Number(excess.key);
                                        $scope.coverages.Coverages[i].ExcessAmount = Number(excess.value);
                                    }

                                    var getIndexOfCoverTypeId = $scope.asset.Coverages.findIndex(w => w.CoverTypeId == $scope.TYPE1SAVE_FALCON_DG);
                                    if(getIndexOfCoverTypeId != -1){
                                        var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.asset.Coverages[getIndexOfCoverTypeId]);
                                        if(getIndexOfCover != -1) {
                                            if(excess && excess.key) {
                                                $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                                $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                            }
                                        }
                                    }

                                    if($scope.currentProposal && $scope.currentProposal.Assets.length > 0) {
                                        if($scope.currentProposal.Assets[0] && $scope.currentProposal.Assets[0].Coverages) {
                                            var getIndexOfCoverTypeId = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == $scope.TYPE1SAVE_FALCON_DG);
                                            if(getIndexOfCoverTypeId != -1){
                                                var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId]);
                                                if(getIndexOfCover != -1) {
                                                    if(excess && excess.key) {
                                                        $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                                        $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                                    }
                                                }
                                            }        
                                        }
                                    }

                                    for (var j in $scope.coverages.Coverages[i].Covers) {
                                        var productLineGroupId = $scope.coverages.Coverages[i].Covers[j].ProductLineGroupId; //New check
                                        if (productLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {        
                                            if(excess && excess.key) {
                                                $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = Number(excess.key);
                                                $scope.coverages.Coverages[i].Covers[j].ExcessAmount = Number(excess.value);
                                            }
                                        }
                                    }
                                }

                                // for "Type 2+"
                                if ($scope.coverages.Coverages[i].CoverTypeId == $scope.TYPE2PLUS_FALCON_DG) {
                                    if (excess && excess.key) {
                                        $scope.coverages.Coverages[i].ExcessLevelId = Number(excess.key);
                                        $scope.coverages.Coverages[i].ExcessAmount = Number(excess.value);
                                    }

                                    var getIndexOfCoverTypeId = $scope.asset.Coverages.findIndex(w => w.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG);
                                    if (getIndexOfCoverTypeId != -1) {
                                        var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.asset.Coverages[getIndexOfCoverTypeId]);
                                        if (getIndexOfCover != -1) {
                                            if (excess && excess.key) {
                                                $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                                $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                            }
                                        }
                                    }

                                    if ($scope.currentProposal && $scope.currentProposal.Assets.length > 0) {
                                        if ($scope.currentProposal.Assets[0] && $scope.currentProposal.Assets[0].Coverages) {
                                            var getIndexOfCoverTypeId = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG);
                                            if (getIndexOfCoverTypeId != -1) {
                                                var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId]);
                                                if (getIndexOfCover != -1) {
                                                    if (excess && excess.key) {
                                                        $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                                        $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    for (var j in $scope.coverages.Coverages[i].Covers) {
                                        var productLineGroupId = $scope.coverages.Coverages[i].Covers[j].ProductLineGroupId; //New check
                                        if (productLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {
                                            if (excess && excess.key) {
                                                $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = Number(excess.key);
                                                $scope.coverages.Coverages[i].Covers[j].ExcessAmount = Number(excess.value);
                                            }
                                        }
                                    }
                                }
                            } else if($scope.coverages.Coverages[i].ProductId == $scope.PRODUCT_ID_AXA){
                                    
                                if(excess && excess.key) {
                                    $scope.coverages.Coverages[i].ExcessLevelId = Number(excess.key);
                                    $scope.coverages.Coverages[i].ExcessAmount = Number(excess.value);    
                                }

                                var getIndexOfCoverTypeId = $scope.asset.Coverages.findIndex(w => w.CoverTypeId == $scope.coverages.Coverages[i].CoverTypeId);
                                if(getIndexOfCoverTypeId != -1){
                                    var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.asset.Coverages[getIndexOfCoverTypeId]);
                                    if(getIndexOfCover != -1) {
                                        if(excess && excess.key) {
                                            $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                            $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                        }
                                    }
                                }

                                if($scope.currentProposal && $scope.currentProposal.Assets.length > 0) {
                                    if($scope.currentProposal.Assets[0] && $scope.currentProposal.Assets[0].Coverages) {
                                        var getIndexOfCoverTypeId = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == $scope.coverages.Coverages[i].CoverTypeId);
                                        if(getIndexOfCoverTypeId != -1){
                                            var getIndexOfCover = fn.utils.getCoverVoluntaryBasicIndexByProductId($scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId]);
                                            if(getIndexOfCover != -1) {
                                                if(excess && excess.key) {
                                                    $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessLevelId = Number(excess.key);
                                                    $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].ExcessAmount = Number(excess.value);
                                                }
                                            }
                                        }        
                                    }
                                }

                                for (var j in $scope.coverages.Coverages[i].Covers) {
                                    var productLineGroupId = $scope.coverages.Coverages[i].Covers[j].ProductLineGroupId; //New check
                                    if (productLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA) {
                                        if(excess && excess.key) {
                                            $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = Number(excess.key);
                                            $scope.coverages.Coverages[i].Covers[j].ExcessAmount = Number(excess.value);
                                        }
                                    }
                                }                                
                            }

                        }

                        fn.saving.saveCoveragesModel({
                            onSuccess: function () {
                                fn.assetApi.updateAsset({
                                    onSuccess: function () {
                                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');                                    
                                        fn.currentProposalApi[saveCurrentProposalFunc]({
                                            onSuccess: function () {
                                                fn.saving.calculatePremium({
                                                    onSuccess: function (data) {
                                                        // retrieve
                                                        if(compulsoryIsIncluded !== undefined) {
                                                            $scope.compulsoryIsIncluded = true;
                                                        }
                                                        if(data && data.Coverages && isDeduct) {
                                                            $scope.coverages.Coverages = data.Coverages;
                                                        }
                                                        fn.updatesCoveragesSPA(compulsoryIsIncluded,isDeduct,"No");
                                                        fn.utils.setIsSaving(false);
                                                    },
                                                    onError: function (err) {
                                                        if(!hasError) {
                                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updateDeductibleAsset', method: 'fn.saving.saveCoveragesModel'} ;
                                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 6001'};
                                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                                        }        
                                                        hasError = true;    
                                                        $scope.compulsoryIsIncluded = undefined;
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                })
                                            }
                                        })
                                    }
                                });
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updateDeductibleAsset', method: 'onMaxarMissingLeadFieldsChecker'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 6017'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    
                                // Nothing to do
                            }
                        });
                    }
                },
                onFullDetailClicked: function (totalNum) {
                    if ($scope.coverDetailsClicked)
                        $scope.coverNum = totalNum;
                    else
                        $scope.coverNum = 0;

                    $scope.coverDetailsClicked = !$scope.coverDetailsClicked;
                },
                onNCBApplyRequest: function (bool) {

                    $scope.applyNCB = bool;
                    
                    if ($scope.applyNCB) {
                        $scope.asset.NcdLevelId = "";
                        $scope.asset.ReasonForNcdIsZero = "";
                        $scope.asset.NcdLevelIdOnOtherCar = "";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    } 
                    else {
                        $scope.asset.NcdLevelId = "1000003";
                        $scope.asset.ReasonForNcdIsZero = "1000004";
                        $scope.asset.NcdLevelIdOnOtherCar = "0";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    }
                },
                onPolicyStartDateChanged: function () {
                    fn.utils.autoSetEndDate();
                },
                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },
                onCustomerLeadDetailsChecker: function(){

                    // check validation face about "Fullname, Email, Phone number" before continue to Page3

                    // to do about "warning wording"            
                    $scope.warningCounter = 0
                    $scope.getFocusWarningID = ''

                    // Warning : First name is required
                    if($scope.frmContactInfo.givenNamePolicyholder.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#givenNamePolicyholder'
                    }

                    // Warning : Telephone number is required
                    if($scope.frmContactInfo.mainContactPolicyholder2.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#mainContactPolicyholder2'
                    }

                    // Warning : Email address is required
                    if($scope.frmContactInfo.emailPolicyholder.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#emailPolicyholder'
                    }

                    // Warning : Consent is required
                    if(($scope.asset.consentPrivacyFlag === false)||($scope.asset.consentPrivacyFlag === undefined)){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#consent_message_label'
                    }
                },
                onMaxarMissingLeadFieldsChecker: function(){
                    
                    // call "Modal validation" show up
                    if (fn.validations.hasInvalid()) {
                        fn.utils.clearLoading();

                        DaModal.open("#formInvalid")
                        return
                    }
                },
                resetTimer() {
                    if ($scope.timeoutPromise) {
                        $timeout.cancel($scope.timeoutPromise);
                    }
                    if(!$scope.selectedCoverage.ProductId && !$scope.popUpContactHasOpened && !$scope.contactPopUpNotAllowed && !$scope.loadingSpinner2 && !$scope.loadingSpinner3){
                        $scope.timeoutPromise = $timeout(() => fn.eventHandlers.showContactPopUp(), 45000);
                    }   
                },
                onBtnCalculatePremiumClicked: function (params) {
                    
                    try{
                        $scope.CheckToCalculate = 2;
                        // console.log($scope.CheckToCalculate);
                        var hasError = false; 
                        $scope.countCalculateClick = $scope.countCalculateClick + 1;

                        if (!params && (!$scope.asset.PlanId || !$scope.currentProposal.ProductId)) {
                            $scope.asset.PlanId = ($scope.engineSizeId == 1000010 || $scope.asset.TariffType === 320) ? $scope.PLANID_ANY_DRIVER : $scope.PLANID_NAMED_DRIVER;
                            $scope.$parent.PlanId = $scope.asset.PlanId;
                        }

                        if(($scope.selectedCoverage.ProductId == $scope.PRODUCT_ID_MTI || $scope.selectedCoverage.ProductId == $scope.PRODUCT_ID_AXA)){
                            $scope.asset.PlanId = $scope.PLANID_ANY_DRIVER;
                            $scope.$parent.PlanId = $scope.asset.PlanId;
                        }

                        if(!params && params != "Dealer" && !$scope.asset.UseMyWorkshop) {
                            if($scope.engineSizeId && $scope.engineSizeId == 1000010){
                                $scope.asset.UseMyWorkshop = true;
                                // if($scope.currentProposal) {
                                //     if($scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0) {
                                //         if($scope.currentProposal.Assets[0] && $scope.currentProposal.Assets[0].UseMyWorkshop != undefined) {
                                //             $scope.currentProposal.Assets[0].UseMyWorkshop = $scope.asset.UseMyWorkshop;
                                //         }
                                //     }
                                // }             
                            }else {
                                $scope.asset.UseMyWorkshop = false;
                            }
                            $scope.currentProposal.ProductId = $scope.asset.ProductId;


                        } else {   
                            if($scope.currentProposal) {
                                if($scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0) {
                                    if($scope.currentProposal.Assets[0] && $scope.currentProposal.Assets[0].UseMyWorkshop != undefined) {
                                        $scope.currentProposal.Assets[0].UseMyWorkshop = $scope.asset.UseMyWorkshop;
                                    }
                                }
                            }                 
                        }

                        // DataServices/PolicyContact/Create page1 > page2
                        $scope.policyHolder.MDProvinceID = $scope.phAddress.MDProvinceId

                        // get value of "financial option page 1"
                        if($scope.currentProposal.PaymentTermId) {
                            if($scope.currentProposal.PaymentTermId == $scope.SINGLE_PAYMENT){

                                // to create "financialChecker" as session
                                $sessionStorage.toSetFinancialChecker = 'singlepayment'
                                $scope.financialChecker = $sessionStorage.toSetFinancialChecker
    
                                $scope.currentProposal.PaymentTermId = $scope.SINGLE_PAYMENT;
                                $scope.asset.PaymentTermId = $scope.SINGLE_PAYMENT;
    
                            }
                            else{
                                // to create "financialChecker" as session
                                if($sessionStorage.financialOptionSelection == 'singlepayment') {
                                    $sessionStorage.toSetFinancialChecker = 'singlepayment'
                                    $scope.financialChecker = $sessionStorage.toSetFinancialChecker
                                    $scope.financialCheckerFromInstallment = true;


                                } else {
                                    $sessionStorage.toSetFinancialChecker = 'installment'
                                    $scope.financialChecker = $sessionStorage.toSetFinancialChecker
        
                                    $scope.currentProposal.PaymentTermId = $scope.INSTALLMENT_PAYMENT;
                                    $scope.asset.PaymentTermId = $scope.INSTALLMENT_PAYMENT;    
                                }
                            }    
                        }

                        // to get "PaymentTermID"
                        $sessionStorage.getPaymentTermIDtoFourthPage = $scope.currentProposal.PaymentTermId

                        $scope.getMDProvinceID = $scope.phAddress.MDProvinceId

                        // to do about "warning wording"            
                        $scope.warningCounter = 0
                        $scope.getFocusWarningID = ''

                        // Warning : MD province is required
                        if($scope.frmAboutMainDriver.MDprovinceId.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#body_md_province_label'
                        }

                        // Warning : Make is required
                        if($scope.frmAboutCar.vehicleMake.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#body_car_make_label'
                        }

                        // Warning : Model is required
                        if($scope.frmAboutCar.vehicleModel.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#body_car_model_label'
                        }

                        // Warning : Year of registration is required
                        if($scope.frmAboutCar.vehicleFirstReg.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#body_car_year_manufac_Label'
                        }

                        // Warning : Description is required
                        if($scope.frmAboutCar.vehicleDesc.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#vehicleDescLabel'
                        }
                        
                        // Warning : Car camera status is required
                        if($scope.frmAboutCar.isCarCameraInstalled.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#body_popup_car_camera_question'
                        }

                        // Warning : Car usage purpose                
                        if(!$scope.asset.UsageId){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#vehicleUsageId'
                        }

                        // Warning : financial option is required
                        // if(!$scope.currentProposal.PaymentTermId){
                        //     $scope.warningCounter += 1
                        //     $scope.getFocusWarningID = '#financialOption'
                        // }

                        if($scope.frmAboutMainDriver.mainDriverMaritalStatus.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#financialOption'
                        }
    

                        // ############################
                        // ############################
                        // ############################
                        // A/B Testing: Payment buttons
                        // if($scope.ABtesting_OldVersionChecker == true){
                        //     // Warning : financial option is required
                        //     if($scope.frmAboutYourPolicy.financialOption.$invalid === true){
                        //         $scope.warningCounter += 1
                        //         $scope.getFocusWarningID = '#financialOption'
                        //     }
                        // }
                        // if($scope.ABtesting_OldVersionChecker == false){
                        //     // no need to check validate data for "Payment term"
                        // }
                        // ############################
                        // ############################
                        // ############################






                        // Warning : Date of birth is required
                        if( ($scope.frmAboutMainDriver.mainDriverDOBDD.$invalid === true) ||
                            ($scope.frmAboutMainDriver.mainDriverDOBMON.$invalid === true) ||
                            ($scope.frmAboutMainDriver.mainDriverDOBYear.$invalid === true)){
                                $scope.warningCounter += 1
                                $scope.getFocusWarningID = '#mainDriverDOBDD'
                        }

                        // Warning : Gender is required
                        if($scope.frmAboutMainDriver.mainDriverGender.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#mainDriverGender'
                        }

                        // Warning : Marital status is required
                        if($scope.frmAboutMainDriver.mainDriverMaritalStatus.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#mainDriverMaritalStatus'
                        }

                        if($scope.mainDriver.OccupationId == undefined){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#occupationMD'
                        }


                        // Warning : Years of driving experience is required
                        if($scope.frmAboutMainDriver.mainDriverDrivingExp.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#mainDriverDrivingExp'
                        }

                        // Warning : Number of accidents or claims in the last 12 months
                        if($scope.frmAboutMainDriver.accidentsInThreeYearsMD.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#accidentsInThreeYearsMD'
                        }

                        // Warning : First name is required
                        if($scope.frmContactInfo.givenNamePolicyholder.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#givenNamePolicyholder'
                        }

                        // Warning : Telephone number is required
                        if($scope.frmContactInfo.mainContactPolicyholder2.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#mainContactPolicyholder2'
                        }

                        // Warning : Email address is required
                        if($scope.frmContactInfo.emailPolicyholder.$invalid === true){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#emailPolicyholder'
                        }

                        // Warning : Consent is required
                        if(($scope.asset.consentPrivacyFlag === false)||($scope.asset.consentPrivacyFlag === undefined)){
                            $scope.warningCounter += 1
                            $scope.getFocusWarningID = '#consent_message_label'
                        }
                        

                        // find "2 Doors" on PORTAL
                        if($scope.twoDoorsCheckerState == true){

                            // Warning : Gear Type is required
                            if($scope.frmAboutCar.gearTypeButtonValidate.$invalid === true){
                                $scope.warningCounter += 1
                                $scope.getFocusWarningID = '#gearTypeLabel'
                            }
                        }


                        // 
                        // get "PolicyDateStart" for Propensity
                        //
                        // $scope.getPolicyDateStartPropensity = new Date($scope.currentProposal.PolicyStartDate)
                        var getArrPolicyStartDatePropen = $scope.currentProposal.PolicyStartDate.split("-")
                        $scope.getPolicyDateStartPropensity = getArrPolicyStartDatePropen[0] + "-" + getArrPolicyStartDatePropen[1] + "-" + getArrPolicyStartDatePropen[2]

                        // get "DrivingExperience" for Propensity
                        if($scope.mainDriver.DrivingExperienceId === undefined){
                            // nothing to do
                        }
                        if($scope.mainDriver.DrivingExperienceId !== undefined){
                            // $scope.getDrivingExperiencePropensity = $scope.$parent.filterStatic($scope.staticData.drivingExperiences,$scope.mainDriver.DrivingExperienceId.toString())
                            let getDrivingExperiencePropensity = $scope.staticData.drivingExperiences.find(w => w.key == $scope.mainDriver.DrivingExperienceId);   
                            if(getDrivingExperiencePropensity){
                                $scope.getDrivingExperiencePropensity = getDrivingExperiencePropensity.value;    
                            }
 
                        }

                        // get "NumOfAccident" for Propensity
                        // $scope.getNumOfAccidentPropensity = $scope.$parent.filterStatic($scope.staticData.nrOfClaimsInPeriods,$scope.mainDriver.NrOfClaimsInPeriodId)
                        let getNumOfAccidentPropensity = $scope.staticData.nrOfClaimsInPeriods.find(w => w.key == $scope.mainDriver.NrOfClaimsInPeriodId);
                        if(getNumOfAccidentPropensity){
                            $scope.getNumOfAccidentPropensity = getNumOfAccidentPropensity.value;
                        }

                        // get "Occupation" for Propensity
                        if($scope.mainDriver.OccupationId !== undefined) {
                            $scope.getOccupation = $scope.staticData.occupations.find(w => w.key == $scope.mainDriver.OccupationId).value
                            $scope.getOccupationPropensity = $scope.mainDriver.OccupationId;    
                        }

                        // "calculateCounter" checker
                        $scope.calculateCounter += 1
                        
                        $scope.isCalculateAction = true

                        // Set source channel here
                        fn.setSourceChannels()
                        
                        // Add NCD Level condition for car age.
                        var chkDate = new Date()
                        var carAge = chkDate.getFullYear() - $scope.asset.PurchasingYear
                        var ncdLevel = 0

                        if (carAge == 0 || $scope.asset.UseMyWorkshop) {  // 0% For vehicle age 0 year
                            ncdLevel = 0
                            $scope.asset.NcdLevelId = "1000003"
                        } 
                        else if (carAge == 1) { // 20% For vehicle age 1 year
                            ncdLevel = 20
                            $scope.asset.NcdLevelId = "1000005"
                        } 
                        else if (carAge > 1) {  // 30% Vehicle age > 1 year
                            ncdLevel = 30
                            $scope.asset.NcdLevelId = "1000006"
                        } 
                        else {
                            ncdLevel = 0
                            $scope.asset.NcdLevelId = "1000003"
                        }

                        $scope.carAge = carAge
                        $scope.pageSubmitted = true

                        if (fn.validations.hasInvalid()) {
                            fn.utils.clearLoading();
                            DaModal.open("#formInvalid")
                            return
                        }
                        if (fn.validations.isStartDateInvalid()) {
                            fn.utils.clearLoading();
                            return
                        }
                        if (!fn.eventHandlers.isAcceptOccu($scope.mainDriver.OccupationId)) {
                            fn.utils.clearLoading();
                            DaModal.open("#mainDriverOccuNotAccept")
                            return
                        }

                        // Commercial pop-up to show
                        if($scope.usedCommercialPopUpChecker == true){
                            fn.utils.clearLoading();
                            DaModal.open("#usedForCommercial")
                            return
                        }

                        // Commercial pop-up to hide (pass process to show premium qoute)
                        if($scope.usedCommercialPopUpChecker != true){
                            // nothing to do
                        }

                        $scope.allowBtnAction = false
                        $scope.loadingSpinner2 = true
                        $scope.loadingSpinner3 = true
                        $scope.$parent.showPriceOnTopFlag = false

                        //Turn off Popup Timer
                        $scope.contactPopUpNotAllowed = true;
                        if ($scope.timeoutPromise) {
                            $timeout.cancel($scope.timeoutPromise);
                        }
                        fn.saving.setUsageId()
                        fn.goToPage(2)
                        fn.setPreviousPage(1)
                        fn.utils.setIsSaving(true)
                        
                        var callbacks = {
                            onSuccess: function () {
                                fn.saving.saveCurrentProposalChildModels(callbackSaveCurrentProposalChildModels,params)
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    fn.utils.clearLoading();
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onBtnCalculatePremiumClicked', method: 'in function'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 6413'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    

                                fn.eventHandlers.onDefaultErrorHandler()
                            }
                        }

                        var callbackSaveCurrentProposalChildModels = {
                            onSuccess: function (statusCode) {
                                console.log("saveCurrentProposalChildModels is successed")
                                if(statusCode == 'INPUT_INVALID' && $scope.loadingSpinner3){
                                    $scope.loadingSpinner = false;
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    $scope.allowBtnAction = true; 
                                    return;       
                                }
                            }
                        };
    

                        $scope.asset.PastInsuranceContactExtNum = "90000"
                        $scope.asset.PrevNcdLevelId = $scope.asset.NcdLevelId
                        $scope.asset.LeadTypeId = 7000004

                        // new lead capturing method
                        fn.populateMaxar()
                        //2.P22 -> Send just first time
                        if(!$scope.currentProposal.ExternalProposalNumber) {
                            fn.postToMaxParent('No', 22, 'N', '')
                        }                

                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal')

                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId
                        
                        fn.currentProposalApi[saveCurrentProposalFunc](callbacks)

                        // Call function: RateAdjustment logic
                        fn.eventHandlers.onLoadRateAdjustmentLogic()
                    }
                    catch (err){
                        fn.utils.clearLoading();
                        var error = err ? err : {fn: 'onBtnCalculatePremiumClicked', method: 'in function'};
                        var errorLog = {err: JSON.stringify(error), case: 'error : line 6452'};
                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                        fn.postToMaxParent('No', priority, 'N', errorLog)            
                    }
                },
                onBtnSessionExpriedAgreeClicked: function () {
                    DaModal.close("#sessionExpired");
                },
                onPaymentSelected:function(getSelectedPaymentID){
                    $scope.currentProposal.PaymentTermId = getSelectedPaymentID
                    $scope.paymentButtonsSelectedState = $scope.currentProposal.PaymentTermId
                },
                onLoadRateAdjustmentLogic:function(){

                    // "Rate Adjustment logic"
                    // 
                    // Commit your payment term (for the first time)
                    // 
                    // check value for the first time that customer reaches.
                    if($sessionStorage.sessionInitialUsualPaymentModeId == undefined){

                        // Usual to be "Single" and then click "Calculate button"
                        if($scope.currentProposal.PaymentTermId == $scope.SINGLE_PAYMENT){
                            $sessionStorage.getFinancialOptionText = 'singlepayment'
                            $scope.asset.InitialUsualPaymentModeId = $scope.SINGLE_PAYMENT_MODE_ID
                            $scope.asset.FinalUsualPaymentModeId = $scope.SINGLE_PAYMENT_MODE_ID

                            // for the frist time
                            // set "sessionInitialUsualPaymentModeId" as "7000000"
                            $sessionStorage.sessionInitialUsualPaymentModeId = $scope.SINGLE_PAYMENT_MODE_ID

                            // Last selected from "FinalUsualPaymentModeId" as "7000000"
                            $scope.lastSelectedFinalUsualPaymentModeId = $scope.SINGLE_PAYMENT_MODE_ID
                        }

                        // Usual to be "Installment" and then click "Calculate button"
                        else{
                            $sessionStorage.getFinancialOptionText = 'installment'
                            $scope.asset.InitialUsualPaymentModeId = $scope.INSTALLMENT_PAYMENT_MODE_ID
                            $scope.asset.FinalUsualPaymentModeId = $scope.INSTALLMENT_PAYMENT_MODE_ID

                            // for the frist time
                            // set "sessionInitialUsualPaymentModeId" as "7000001"
                            $sessionStorage.sessionInitialUsualPaymentModeId = $scope.INSTALLMENT_PAYMENT_MODE_ID

                            // Last selected from "FinalUsualPaymentModeId" as "7000001"
                            $scope.lastSelectedFinalUsualPaymentModeId = $scope.INSTALLMENT_PAYMENT_MODE_ID
                        }
                    }

                    // 
                    // Commit your payment term (for the second time)
                    // 
                    // once to has existing "sessionInitialUsualPaymentModeId"
                    else {
                        // once customer "Changing options" or "Refreshing" or "Click the same link to reload browser"
                        // pull value of "InitialUsualPaymentModeId" from the old one that customer selected before refresh.
                        $scope.asset.InitialUsualPaymentModeId = $sessionStorage.sessionInitialUsualPaymentModeId

                        if($scope.currentProposal.PaymentTermId == $scope.SINGLE_PAYMENT){
                            $sessionStorage.getFinancialOptionText = 'singlepayment'
                            $scope.asset.FinalUsualPaymentModeId = $scope.SINGLE_PAYMENT_MODE_ID
                            // Last selected from "FinalUsualPaymentModeId" as "7000000"
                            $scope.lastSelectedFinalUsualPaymentModeId = $scope.SINGLE_PAYMENT_MODE_ID
                        }
                        else if($scope.currentProposal.PaymentTermId == "1000005"){
                            $sessionStorage.getFinancialOptionText = 'installment'
                            $scope.asset.FinalUsualPaymentModeId = $scope.INSTALLMENT_PAYMENT_MODE_ID
                            // Last selected from "FinalUsualPaymentModeId" as "7000001"
                            $scope.lastSelectedFinalUsualPaymentModeId = $scope.INSTALLMENT_PAYMENT_MODE_ID
                        }
                    }
                },
                onBtnBackClicked: function () {
                    $scope.backCounter += 1
                    fn.goToPage(1);
                    fn.setPreviousPage(2);
                },
                onBtnBackClicked2: function () {
                    // "backCounter" checker
                    $scope.isOnBtnBackClicked2 = true;
                    $scope.backCounter += 1
                    
                    fn.getPriority(function (priority) { /* do nothing */ })
                    fn.goToPage(2);
                    fn.setPreviousPage(3);
                },
                onBtnBackClicked3: function () {
                    $scope.backCounter += 1
                    fn.goToPage(3);
                    fn.setPreviousPage(4);
                },
                FinancialModalClose: function() {
                    // clear session of "Financial Option" after modal-close
                    // $sessionStorage.financialOptionSelection = ''
                },
                changeFinancialPaymentOption: function(){

                    // if financial option buttons ("Single" or "Installment") do select by user
                    if( ($sessionStorage.financialOptionSelection == "")||
                        ($sessionStorage.financialOptionSelection == null)||
                        ($sessionStorage.financialOptionSelection == undefined) ){

                        // the button selected by "installment"
                        if($scope.financialChecker == 'installment'){
                            $sessionStorage.getFinancialOptionText = 'installment'
                            // to create "financialChecker" as session
                            $sessionStorage.toSetFinancialChecker = 'installment'
                            $scope.financialChecker = $sessionStorage.toSetFinancialChecker
                            // to set "getPaymentTermIDtoFourthPage" as "installment"
                            $sessionStorage.getPaymentTermIDtoFourthPage = $scope.INSTALLMENT_PAYMENT

                            $scope.currentProposal.PaymentTermId = $scope.INSTALLMENT_PAYMENT;
                            $scope.asset.PaymentTermId = $scope.INSTALLMENT_PAYMENT;
                        }

                        // the button selected by "singlepayment"
                        else{
                            $sessionStorage.getFinancialOptionText = 'singlepayment'
                            // to create "financialChecker" as session
                            $sessionStorage.toSetFinancialChecker = 'singlepayment'
                            $scope.financialChecker = $sessionStorage.toSetFinancialChecker
                            // to set "getPaymentTermIDtoFourthPage" as "singlepayment"
                            $sessionStorage.getPaymentTermIDtoFourthPage = $scope.SINGLE_PAYMENT

                            $scope.currentProposal.PaymentTermId = $scope.SINGLE_PAYMENT;
                            $scope.asset.PaymentTermId = $scope.SINGLE_PAYMENT;

                        }
                        DaModal.close('#popupFinancialMethodInstallment')
                    }

                    // if financial option buttons ("Single" or "Installment") don't select by user
                    else {

                        // customer changes new Financial-Option to "singlepayment"
                        if($sessionStorage.financialOptionSelection == "singlepayment"){
                            $sessionStorage.getFinancialOptionText = 'singlepayment'
                            // to create "financialChecker" as session
                            $sessionStorage.toSetFinancialChecker = 'singlepayment'
                            $scope.financialChecker = $sessionStorage.toSetFinancialChecker
                            // to set "getPaymentTermIDtoFourthPage" as "singlepayment"
                            $sessionStorage.getPaymentTermIDtoFourthPage = $scope.SINGLE_PAYMENT

                            // $scope.currentProposal.PaymentTermId = "1000001";
                            // $scope.asset.PaymentTermId = "1000001";

                        }
                        else{
                            $sessionStorage.getFinancialOptionText = 'installment'
                            // to create "financialChecker" as session
                            $sessionStorage.toSetFinancialChecker = 'installment'
                            $scope.financialChecker = $sessionStorage.toSetFinancialChecker
                            // to set "getPaymentTermIDtoFourthPage" as "installment"
                            $sessionStorage.getPaymentTermIDtoFourthPage = $scope.INSTALLMENT_PAYMENT

                            // $scope.currentProposal.PaymentTermId = $scope.INSTALLMENT_PAYMENT;
                            // $scope.asset.PaymentTermId = $scope.INSTALLMENT_PAYMENT;

                        }
                        DaModal.close('#popupFinancialMethodInstallment')
                    }
                },
                onBtnNextClicked: function () {
                    // Call custome function
                    // To check contact customer details before sent to MAXAR
                    $scope.isOnBtnBackClicked2 = false;
                    fn.eventHandlers.onCustomerLeadDetailsChecker()
                    // Face missing fields before send to MAXAR
                    if($scope.warningCounter > 0){
                        fn.eventHandlers.onMaxarMissingLeadFieldsChecker()
                    } else {
                        // close modal "RateAdjustment Sticky Popup"
                        DaModal.close('#popupTotalPriceRateAdjustment')

                        // to get "FirstPayment,MonthlyPremium" after calculate immediately                    
                        fn.utils.paymentScheduleToShow($scope.thirdPageShow);


                        // DataServices/PolicyContact/Update page2 > page3
                        $scope.policyHolder.MDProvinceID = $scope.phAddress.MDProvinceId

                        // close "financial option popup" when you clicked on "Select this option"
                        DaModal.close('#popupFinancialMethodInstallment')
                        // set bottom-sticky to default (hide state)
                        // $scope.coverageDetails.show = false

                        // "selectThisPlanCounter" checker
                        $scope.selectThisPlanCounter += 1

                        // get "Occupation (for click forward only)"
                        // $scope.getOccupation = $scope.$parent.filterStatic($scope.staticData.occupations,$scope.policyHolder.OccupationId)
                        $scope.getOccupation = $scope.staticData.occupations.find(w => w.key == $scope.policyHolder.OccupationId).value

                        // $scope.isHotLead = true
                        fn.utils.setIsSaving(true);

                        if (fn.validations.hasInvalid()) { // Consent was checked (TRUE) only to pass next step
                            return;
                        }

                        if (fn.validations.isStartDateInvalid()) {
                            return;
                        }

                        if (!fn.eventHandlers.isAcceptOccu($scope.mainDriver.OccupationId)) {
                            fn.utils.clearLoading();
                            DaModal.open("#mainDriverOccuNotAccept");
                            return
                        }

                        if (!fn.eventHandlers.isAcceptOccu($scope.namedDriver.OccupationId)) {
                            fn.utils.clearLoading();
                            DaModal.open("#namedDriverOccuNotAccept");
                            return
                        }

                        if ($scope.asset.PlanId == $scope.PLANID_NAMED_DRIVER) {  // Named driver
                            if ($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2") {
                                return;
                            }
                        }
                        marketing.dLCE(63,$scope.asset.IsRedPlate);
                        marketing.dLCE(61,false);
                        fn.goToPage(3);
                        fn.setPreviousPage(2);
                        $scope.pageSubmitted = true;
                        $scope.loadingSpinner = true;
                        $scope.$parent.showPriceOnTopFlag = false;

                        var preCallbacks1 = {
                            onSuccess: function () {
                                if($scope.asset && $scope.asset.Coverages && $scope.asset.Coverages.length > 0){
                                    $scope.asset.Coverages.map((d) => {
                                        d.compulsoryOriginal = fn.utils.getCompulsory(d.Covers,d.ProductId);
                                    });        

                                    $scope.coverageList =$scope.asset.Coverages;
                                    angular.extend($scope.coverages, {CoverListsCount: $scope.asset.Coverages.length,Coverages: $scope.asset.Coverages});    
                                }        
                                fn.saving.saveCoveragesModel(preCallbacks2);
                                fn.eventHandlers.postUWFC();
                            }
                        };

                        var preCallbacks2 = {
                            onSuccess: function () {
                                fn.updateAssetMaxarPriority(callbacks1);
                            }
                        };

                        var callbacks1 = {
                            onSuccess: function () {
                                fn.saving.savePHContactToProceed(callbacks2);
                            }
                        };

                        var callbacks2 = {
                            onSuccess: function () {
                                hasError = false;
                                fn.saving.calculatePremium({
                                    onSuccess: function (data) {
                                        if(data){
                                            if(($scope.selectedCoverage.ProductId == $scope.PRODUCT_ID_MTI || $scope.selectedCoverage.ProductId == $scope.PRODUCT_ID_AXA)  && data.Coverages) {
                                                $scope.coverages.Coverages = data.Coverages;
                                            }
                                            fn.saving.saveOtherDetailsPartSave(callbacks3);
                                        }
                                    },
                                    onError: function (err) {
                                        if(!hasError) {
                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onBtnNextClicked', method: 'callbacks2'} ;
                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 6718'};
                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                        }        
                                        hasError = true;    
                                        fn.saving.saveOtherDetailsPartSave(callbacks3);
                                    }
                                });
                            }
                        };

                        var callbacks3 = {
                            onSuccess: function () {
                                $scope.loadingSpinner = false;
                            }
                        };

                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;

                        if($scope.policyHolder.IsMainDriver){
                            marketing.dLCE(62,$scope.policyHolder.IsMainDriver);
                            marketing.dLCE(67,$scope.mainDriver.GenderId);
                            marketing.dLCE(66,$scope.mainDriver.MaritalStatusId);
                            marketing.dLCE(68,$scope.mainDriver.OccupationId);
                            marketing.dLCE(69,$scope.mainDriver.ResidentialStatusId);
                        }

                        fn.currentProposalApi[saveCurrentProposalFunc](preCallbacks1);

                    }
                },

                onDropMakeChanged: function (manufacturerId) {

                    $scope.staticData.models = [];

                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleModel option:first").text("กำลังโหลด...");
                        $scope.staticData.models = [{ "key": 0, "value": "กำลังโหลด..." }];
                    }
                    else {
                        $("#vehicleModel option:first").text("Loading...");
                        $scope.staticData.models = [{ "key": 0, "value": "Loading..." }];
                    }

                    if (!$scope.isStartQuote) {
                        $scope.isStartQuote = true;
                    }

                    if (!manufacturerId) {
                        if ($scope.staticData.models) {
                            $scope.staticData.models.splice(0);
                        }
                        return;
                    }

                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getModelListV2({
                        onSuccess: function (data) {

                            filteredModel = $filter('filter')(data, function (r) {
                                var notAcceptModel = [
                                    "90000148",
                                    "90000784",
                                    "90000546",
                                    "90000214",
                                    "90000810",
                                    "90000406",
                                    "90000402",
                                    "90000456",
                                    "90000747",
                                    "90000043",
                                    "90000667",
                                    "90000737",
                                    "90000185",
                                    "90000186",
                                    "90000189",
                                    "90000194",
                                    "90000720",
                                    "90000203",
                                    "90000772",
                                    "90000773",
                                    "90000229",
                                    "90000230",
                                    "90000231",
                                    "90000598",
                                    "90000676",
                                    "90000232",
                                    "90000234",
                                    "90000235",
                                    "90000236",
                                    "90000237",
                                    "90000238",
                                    "90000723",
                                    "90000809",
                                    "90000242",
                                    "90000270",
                                    "90000274",
                                    "90000278",
                                    "90000280",
                                    "90000279",
                                    "90000282",
                                    "90000283",
                                    "90000286",
                                    "90000287",
                                    "90000288",
                                    "90000289",
                                    "90000790",
                                    "90000778",
                                    "90000801",
                                    "90000802",
                                    "90000781",
                                    "90000794",
                                    "90000465",
                                    "90000812",
                                    "90000469",
                                    "90000490",
                                    "90000491",
                                    "90000492",
                                    "90000762",
                                    "90000493",
                                    "90000494",
                                    "90000495",
                                    "90000496",
                                    "90000785",
                                    "90000786",
                                    "90000497",
                                    "90000498",
                                    "90000499",
                                    "90000500",
                                    "90000501",
                                    "90000502",
                                    "90000503",
                                    "90000504",
                                    "90000505",
                                    "90000042",
                                    "90000165",
                                    "90000243",
                                    "90000029",
                                    "90000046",
                                    "90000066",
                                    "90000067",
                                    "90000807",
                                    "90000031",
                                    "90000174",
                                    "90000182",
                                    "90000303",
                                    "90000353",
                                    "90000438",
                                    "90000440",
                                    "90000527",
                                    "90000162",
                                    "90000217",
                                    "90000296",
                                    "90000529",
                                    "90000122",
                                    "90000010",
                                    "90000249"
                                ];

                                if (!notAcceptModel.includes(r.key)) {
                                    //console.log(r.key);
                                    return r.key;
                                }
                            });
                            $scope.staticData.models = filteredModel;

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            } 
                            else {
                                $("#vehicleModel option:first").text("Please select...");
                            }
                            
                            if (data.length == 0) {
                                fn.utils.clearLoading();
                                DaModal.open("#makeAndModelNotAvailable");
                            }

                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onDropMakeChanged', method: 'wfeApiStaticDataModel.getModelListV2'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 6905'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            } else {
                                $("#vehicleModel option:first").text("Please select...");
                            }
                        }
                    });

                },
                onDisabledButtons: function (yourComponentID){
                    
                    // to disabled buttons
                    $(yourComponentID).prop("disabled", true)
                    $(yourComponentID).css({"background-color": "#aab2bd",
                                            "box-shadow":"none",
                                            "opacity":".45",
                                            "border":"none",
                                            "color":"#1E1E1E"})

                },
                onEnabledButtons: function (yourComponentID){

                    // to enabled buttons
                    $(yourComponentID).prop("disabled", false)
                    $(yourComponentID).css({"background-color": "",
                                            "box-shadow":"",
                                            "opacity":"",
                                            "border":"",
                                            "color":""})

                },
                onDropModelChanged: function (manufacturerId, modelId) {
                    var hasError = false;

                    // 2 Doors checker after "onDropModelChanged" has changed
                    fn.isPopularTruck()

                    $scope.staticData.yearOfRegistrations = [];

                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleFirstReg option:first").text("กำลังโหลด...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "กำลังโหลด..." }];
                    } 
                    else {
                        $("#vehicleFirstReg option:first").text("Loading...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "Loading..." }];
                    }

                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    objParams.modelId = modelId;

                    if (typeof modelId !== "undefined") {

                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                        wfeApiStaticDataModel.getYear({
                            onSuccess: function (data) {
                                $scope.staticData.yearOfRegistrations = data;
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกปีที่ผลิต");
                                } 
                                else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onDropModelChanged', method: 'wfeApiStaticDataModel.getYear'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 6979'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    

                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกปีที่ผลิต");
                                } 
                                else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }
                            }
                        });
                    }
                },

                onDropPurchasingYearChanged: function (modelId, purchasingYear) {
                    var hasError = false;

                    if (HKLocale.getLocale() == "th-th") {
                        $("#carDescriptionButton option:first").text("กำลังโหลด...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "กำลังโหลด..." }];
                    } 
                    else {
                        $("#carDescriptionButton option:first").text("Loading...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "Loading..." }];
                    }

                    $scope.staticData.cardescriptions = [];
                    var objParams = {};
                    objParams.modelId = modelId;
                    objParams.purchasingYear = purchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getCarDescWithYear({
                        
                        onSuccess: function (data) {

                            $scope.staticData.cardescriptions = data;


                            // get all "2 doors & 4 doors" to show
                            $scope.staticData.arrangedcardescriptions = fn.eventHandlers.reduceDesc(data)

                            // If car type filter and got zero, return not available message.
                            if ($scope.staticData.arrangedcardescriptions.length === 0) {
                                fn.utils.clearLoading();
                                DaModal.open("#makeAndModelNotAvailable");
                            }
                            if (HKLocale.getLocale() == "th-th") {
                                $("#carDescriptionButton option:first").text("เลือกรุ่นย่อย");
                            }
                            else {
                                $("#carDescriptionButton option:first").text("Please select...");
                            }

                            //fetchBodyType();
                            var callbacks = {
                                onSuccess: function () {
                                    fetchBodyType();
                                }
        
                            };
                            
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onDropPurchasingYearChanged', method: 'wfeApiStaticDataModel.getCarDescWithYear'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 7047'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    

                            if (HKLocale.getLocale() == "th-th") {
                                $("#carDescriptionButton option:first").text("เลือกรุ่นย่อย");
                            } 
                            else {
                                $("#carDescriptionButton option:first").text("Please select...");
                            }
                        }
                    });

                    var fetchBodyType = wfeApiStaticDataModel.getBodyTypes({
                        onSuccess: function (data) {
                            $scope.staticData.bodytypes = data;
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onDropPurchasingYearChanged', method: 'wfeApiStaticDataModel.getBodyTypes'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 7073'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    
                        }
                    });

                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var NCDLVLID = "1000003";
                    var carAge = chkDate.getFullYear() - purchasingYear;
                    var ncdLevel = 0;

                    // 0% For vehicle age 0 year
                    if (carAge == 0) {
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    }

                    // 20% For vehicle age 1 year
                    else if (carAge == 1) {
                        ncdLevel = 20;
                        NCDLVLID = "1000005";
                    }

                    // 30% Vehicle age > 1 year
                    else if (carAge > 1) {
                        ncdLevel = 30;
                        NCDLVLID = "1000006";
                    }

                    else {
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    }
                },

                onCarDescriptionChanged: function (descKey) {

                    var carTarrif = $filter('filter')($scope.staticData.cardescriptions, {
                        key: descKey
                    });

                    //console.log(`onCarDescriptionChanged carTarrif : ${JSON.stringify(carTarrif)}`);
                    if(carTarrif && carTarrif.length > 0) {
                        carTarrif = carTarrif[0];
                        if (carTarrif && carTarrif.engineSizeId) {
                            $scope.engineSizeId = carTarrif.engineSizeId;
                        }
                        if (carTarrif && carTarrif.tariffType) {
                            $scope.asset.TariffType = Number(carTarrif.tariffType);
                            if(carTarrif.tariffType == 110 || carTarrif.tariffType == 210) {
                                $scope.usageIdDefault = 6
                                $scope.asset.UsageId = 6
                                $scope.txtCarUsage = $scope.staticData.usagesAll.find(usage => usage.key === 6).value
                                $scope.staticData.usages = $filter('filter')($scope.staticData.usagesAll, function (r) {
                                    var notAcceptUsages = [7000000];
                                    if (!notAcceptUsages.includes(r.key)) {
                                        return r.key;
                                    }
                                })
                                // $scope.frmAboutCar.vehicleUsageId.value = 6
                            } else if(carTarrif.tariffType == 320) {
                                $scope.usageIdDefault = 7000000
                                $scope.asset.UsageId = 7000000
                                $scope.txtCarUsage = $scope.staticData.usagesAll.find(usage => usage.key === 7000000).value
                                $scope.staticData.usages = $filter('filter')($scope.staticData.usagesAll, {
                                    key: 7000000
                                })
                                // $scope.frmAboutCar.vehicleUsageId.value = 7000000
                            } else {
                                $scope.usageIdDefault = 6
                                $scope.asset.UsageId = 6
                                $scope.txtCarUsage = $scope.staticData.usagesAll.find(usage => usage.key === 6).value
                                $scope.staticData.usages = $scope.staticData.usagesAll
                                // $scope.frmAboutCar.vehicleUsageId.value = 6
                            }
                            // $scope.frmAboutCar.vehicleUsageId.$setValidity("valid", true);
                        }    
                    }
                    //Add new field for agg by Pu 2023/01/10
                    if (carTarrif && carTarrif.classId) {
                        $scope.asset.ClassId = carTarrif.classId;
                    }
                    if (carTarrif && carTarrif.groupId) {
                        $scope.asset.GroupId = carTarrif.groupId;
                    }
                    if (carTarrif && carTarrif.value) {
                        $scope.asset.CarValue = carTarrif.value;
                    }
                    if (carTarrif && carTarrif.ImportStatusId) {
                        $scope.asset.ImportStatusId = carTarrif.ImportStatusId;
                    }
                    //End

                    if ($scope.HKLocale.getLocale() == "th-th") {
                        $("#LicenseProvince2 option:first").text("เลือกจังหวัดที่รถจดทะเบียน");
                        // $scope.staticData.province = [{ "key": 0, "value": "กำลังโหลด..." }];

                    }
                    else {
                        $("#LicenseProvince2 option:first").text("Please select...");
                        // $scope.staticData.province = [{ "key": 0, "value": "Loading..." }];
                    }


                    return;
                },

                onBtnInDriveWorkChange: function () {
                    var inDriveWork = $scope.asset.InDriveWork;
                    if (!inDriveWork) {
                        $scope.asset.InCourseWork = false;
                    } else {
                        $scope.asset.InCourseWork = undefined;
                    }

                },

                onIsPHMDButton: function () {

                    if ($scope.policyHolder.IsMainDriver) {
                        fn.utils.copyMDFieldsToPH();
                        $scope.$parent.setDateValidity($scope.policyHolder.DOB_DD, $scope.policyHolder.DOB_MON, $scope.policyHolder.DOB_Year, $scope.frmAboutPH.policyHolderDOBDD);

                    } else {
                        $scope.policyHolder.DOB_DD = null;
                        $scope.policyHolder.DOB_MON = null;
                        $scope.policyHolder.DOB_Year = null;
                        $scope.policyHolder.DateOfBirth = null;
                        $scope.policyHolder.GenderId = null;
                        $scope.policyHolder.MaritalStatusId = null;
                        $scope.policyHolder.OccupationId = null;
                        $scope.policyHolder.DrivingExperienceId = null;
                        $scope.policyHolder.NrOfClaimsInPeriodId = null;
                        $scope.policyHolder.InDriveWork = null;
                        $scope.policyHolder.InCourseWork = null;
                        $scope.policyHolder.ResidentialStatusId = null;
                        $scope.policyHolder.FullName = null;
                        $scope.policyHolder.FirstName = null;
                        $scope.policyHolder.LastName = null;
                        // $scope.policyHolder.PreferredName = null;
                        $scope.policyHolder.Identifier = null;
                        $scope.policyHolder.NationalityId = null;
                    }
                },

                onBtnNCDClicked: function () {
                    fn.utils.clearLoading();
                    DaModal.open('#checkNCD');
                },

                onBtnNCDSelected: function () {
                    fn.utils.clearLoading();
                    DaModal.close('#checkNCD');
                },

                reduceDesc: function (descData) {
                    if (descData) {
                        var p;
                        var header;
                        var descObj2 = new Object();
                        var descKeyObj = new Object();
                        var descTxtObj = new Object();
                        var optArr = new Array;
                        var headArr = new Array;
                        let bodyTypes = ['2doors','4doors'];

                        for (var key in descData) {

                            if (!isNaN(descData[key].key)) {
                                var optSel = new Object();
                                p = descData[key].description.split(" ");
                                HGroup = p[0] + " " + p[1] + " ";

                                HGroupNew = bodyTypes.some(w => w == p[1]) ? HGroup : HGroup.trim() + (descData[key].bodyTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG ? '(2doors) ' : '(4doors) ');

                                descTxt = descData[key].description.replace(HGroup, "").replace("?", " ");
                                descKey = descData[key].key;
                                optSel.key = descKey;
                                optSel.txt = descTxt;
                                optSel.hgroup = HGroupNew;
                                optSel.gearTypeId = descData[key].gearTypeId;
                                optSel.oriDesc = descData[key].description;
                                optArr.push(optSel);

                                if (headArr.indexOf(HGroupNew) == -1) {
                                    headArr.push(HGroupNew);
                                }
                                //}
                            }
                        }

                        var z = Array();
                        for (var i in headArr) {

                            var x = new Array;
                            var descObj = new Object();

                            for (var j in optArr) {
                                if (headArr[i] == optArr[j].hgroup) {
                                    x.push(optArr[j]);
                                }
                            }

                            descObj.HGroup = headArr[i];
                            descObj.Data = x;
                            z.push(descObj);
                        }

                        return z;
                    }
                },
                isMoreThanFive: function () {
                    let moreThanFive = true;
                    if($scope.asset && !$scope.asset.PurchasingYear) {
                        return moreThanFive;
                    }
                    var chkDate = new Date();
                    var carAge = chkDate.getFullYear() - $scope.asset.PurchasingYear + 1;
                    if (carAge > 5) {
                        if($scope.selectedCoverage && $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_D && $scope.asset && $scope.asset.UseMyWorkshop == true) {
                            moreThanFive = true;
                        } else {
                            moreThanFive = false;
                        }
                        // moreThanFive = false;
                    } 
                    return moreThanFive;
                },
                isEvCar: function () {
                    let returnValue = $scope.engineSizeId && $scope.engineSizeId == 1000010
                    return returnValue
                },
                setMaker: function (e) {
                    $scope.txtMaker = e.target.textContent.trim();
                    $scope.asset.ManufactureId = e.target.dataset.key;
                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].ManufactureId = e.target.dataset.key;
                    }
                    fn.eventHandlers.showModel(e);
                    $scope.txtModel = fn.getTranslateText('model');
                    $scope.txtYear = fn.getTranslateText('year');
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setModel: function (e) {
                    $scope.txtModel = e.target.textContent.trim();
                    $scope.asset.ModelId = e.target.dataset.key;
                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].ModelId = e.target.dataset.key;
                    }
                    fn.eventHandlers.showYear(e);
                    $scope.txtYear = fn.getTranslateText('year');
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setYear: function (e) {
                    $scope.txtYear = e.target.textContent.trim();
                    $scope.asset.PurchasingYear = e.target.dataset.key;
                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].PurchasingYear = e.target.dataset.key;
                    }
                    fn.eventHandlers.showDesc(e);
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setDesc: function (e,desc) {
                    $scope.txtDesc = e.target.parentNode.dataset.head + " " + e.target.textContent.trim();
                    $scope.asset.CarDescriptionId = e.target.dataset.key;
                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].CarDescriptionId = e.target.dataset.key;
                    }
                    fn.eventHandlers.showProvince(e);
                    $scope.gearType = desc.gearTypeId == 1000003 ? 'Man' : 'Auto';
                    $scope.txtGearType = desc.gearTypeId == 1000003 ? 'Manual' : 'Auto';
                },
                setProvince: function (e) {
                    $scope.txtProvince = e.target.textContent.trim();
                    $scope.asset.LicenseProvinceId2 = e.target.dataset.key;
                    $scope.asset.LicenseCardProvinceId = e.target.dataset.key;

                    let getCarPlateProvinceID = e.target.dataset.key;
                    $scope.policyHolder.CarPlateProvinceID = getCarPlateProvinceID;
                    $sessionStorage.CarPlateProvinceID = {key: getCarPlateProvinceID, value: e.target.textContent.trim()};
                    if((getCarPlateProvinceID == '2000080') || (getCarPlateProvinceID == 2000080)) {
                        $scope.getCarPlateProvinceName = 'NA'
                    } 
                    else {
                        // $scope.getCarPlateProvinceName = $scope.$parent.filterStatic($scope.staticData.provinces,getCarPlateProvinceID)   
                        let getCarPlateProvinceName =  $scope.staticData.provinces.find(w => w.key == getCarPlateProvinceID);   
                        if(getCarPlateProvinceName){
                            $scope.getCarPlateProvinceName =  getCarPlateProvinceName.value;          
                        }
                    }

                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].LicenseProvinceId2 = e.target.dataset.key;
                        $scope.currentProposal.Assets[0].LicenseCardProvinceId = e.target.dataset.key;
                    }
                    fn.eventHandlers.showCamera();            
                },
                setCamera: function (hasCamera) {
                    $scope.asset.IsCarCameraInstalled = hasCamera;
                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].IsCarCameraInstalled = hasCamera;
                    }
                    $scope.txtCamera = $filter('translate')(hasCamera ? 'b2c_car_with_camera' : 'b2c_car_with_no_camera')
                    $scope.txtCameraEquipped = hasCamera ? 'Yes' : 'No'
                    fn.eventHandlers.showFinancialOptionDropdown()
                },
                setCarUsage: function (usage) {
                    $scope.asset.UsageId = usage.key;           
                    $scope.txtCarUsage = usage.value
                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].UsageId = usage.key;
                    }

                    let policyStartDateOriginal = document.getElementById("policyStartDateOriginal");
                    if(policyStartDateOriginal && policyStartDateOriginal.style.display == "none"){
                        // fn.eventHandlers.showCalendarDDABTesting(e);
                    } else {
                        fn.eventHandlers.showCalendar();
                    }
                },
                setPaymentTerms: function (e, k = null) {
                    console.log("Enter to setPaymentTerms function")
                    $scope.isClickPolicyOfYear = false;
                    $scope.txtPaymentTerms = e.target.textContent.trim();
                    $scope.txtPaymentTermsId = e.target.dataset.key;
                    let initialUsualPaymentModeId = $sessionStorage.sessionInitialUsualPaymentModeId ;
                    if($scope.currentProposal && $scope.currentProposal.PaymentTermId && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0 && $scope.currentProposal.PaymentTermId == $scope.INSTALLMENT_PAYMENT && (initialUsualPaymentModeId && initialUsualPaymentModeId != $scope.SINGLE_PAYMENT_MODE_ID)) {
                    } else {
                        $scope.currentProposal.PaymentTermId = e.target.dataset.key;

                        $scope.asset.PaymentTermId = e.target.dataset.key;
                        if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                            $scope.currentProposal.Assets[0].PaymentTermId = e.target.dataset.key;
                        }  
                    }
                    if(e.target.dataset.key){
                        if(e.target.dataset.key == $scope.SINGLE_PAYMENT) {
                            $sessionStorage.financialOptionSelection = 'singlepayment'
                        } else {
                            $sessionStorage.financialOptionSelection = 'installment'
                        }
                    }
                    else{
                        if(k == $scope.SINGLE_PAYMENT) {
                            $sessionStorage.financialOptionSelection = 'singlepayment'
                        } else {
                            $sessionStorage.financialOptionSelection = 'installment'
                        }
                    }

                    fn.eventHandlers.showMDProvince(e);
                },
                setMDProvince: function (e) {
                    $scope.txtMDprovince = e.target.textContent.trim();
                    $scope.phAddress.MDProvinceId = e.target.dataset.key;
                    fn.eventHandlers.showBirthDate(e);
                },
                setMDGender: function (key) {
                    $scope.isClickBirthDateOfYear = false;
                    $scope.mainDriver.GenderId = key;
                    let mdGender = $scope.staticData.genders.find(w => w.key == $scope.mainDriver.GenderId);
                    if(mdGender) {
                        $scope.txtMDGender = mdGender.value;
                    }
                    fn.eventHandlers.showMaritalStatusDropdown();
                },
                setMDMaritalStatusId: function (key) {
                    $scope.mainDriver.MaritalStatusId = key;
                    let mdMaritalStatus = $scope.staticData.maritalStatuses.find(w => w.key == $scope.mainDriver.MaritalStatusId);
                    if(mdMaritalStatus) {
                        $scope.txtMDMaritalStatus = mdMaritalStatus.value;
                    }
                },
                setOccupationId: function (e) {
                    $scope.txtOccupationId = e.target.textContent.trim();
                    $scope.mainDriver.OccupationId = e.target.dataset.key;
                    fn.eventHandlers.showDriverExperienceDropdown();
                },
                setNrOfClaimsInPeriodId: function (e) {
                    $scope.txtNrOfClaimsInPeriodId = e.target.textContent.trim();
                    $scope.mainDriver.NrOfClaimsInPeriodId = e.target.dataset.key;
                    fn.validations.checkNoOfAcc();
                },
                setDrivingExperienceId: function (e) {
                    $scope.txtDrivingExperiences = e.target.textContent.trim();
                    $scope.mainDriver.DrivingExperienceId = e.target.dataset.key;
                    checkDrivingExp();
                },
                setDrivingExperienceIdByButton: function (key) {
                    $scope.txtDrivingExperiences = fn.wfeApiStaticData.utils.getStaticDataDisplay(key, $scope.staticData.drivingExperiences);
                    $scope.mainDriver.DrivingExperienceId = key;
                },
                setVehicleColour: function (e) {
                    $scope.txtVehicleColour = e.target.textContent.trim();
                    $scope.asset.vehicleColourId = e.target.dataset.key;
                },
                setNationality: function (e) {
                    $scope.txtNationality = e.target.textContent.trim();
                    $scope.mainDriver.NationalityId = e.target.dataset.key;
                },
                setNamedDriverOccupationId: function (e) {
                    $scope.txtNamedDriverOccupationId = e.target.textContent.trim();
                    $scope.namedDriver.OccupationId = e.target.dataset.key;
                    if($scope.txtNamedDriverOccupationId) {
                        marketing.dLCE(3,$scope.txtNamedDriverOccupationId);
                    }
                },
                setNamedDriverResidentialStatusId: function (e) {
                    $scope.namedDriver.Identifier = '';
                    $scope.txtNamedDriverResidentialStatusId = e.target.textContent.trim();
                    $scope.namedDriver.ResidentialStatusId = e.target.dataset.key;
                    if($scope.txtNamedDriverResidentialStatusId) {
                        marketing.dLCE(3, $scope.txtNamedDriverResidentialStatusId);
                    }
                },
                setNamedDriverNationalityId: function (e) {
                    $scope.txtNamedDriverNationalityId = e.target.textContent.trim();
                    $scope.namedDriver.NationalityId = e.target.dataset.key;
                    $scope.frmAboutNamedDriver.nationalityND = e.target.dataset.key;
                },
                setNamedDriverDrivingExperienceId: function (e) {
                    $scope.txtNamedDrivingExperienceId = e.target.textContent.trim();
                    $scope.namedDriver.DrivingExperienceId = e.target.dataset.key;
                    if($scope.txtNamedDrivingExperienceId) {
                        marketing.dLCE(3, $scope.txtNamedDrivingExperienceId);
                    }
                },
                setNamedDriverNrOfClaimsInPeriodId: function (e) {
                    $scope.txtDriverNrOfClaimsInPeriodId = e.target.textContent.trim();
                    $scope.namedDriver.NrOfClaimsInPeriodId = e.target.dataset.key;
                    if($scope.txtDriverNrOfClaimsInPeriodId) {
                        marketing.dLCE(3, $scope.txtDriverNrOfClaimsInPeriodId);
                    }
                },
                setPolicyHolderOccupationId: function (e) {
                    $scope.txtPolicyHolderOccupationId = e.target.textContent.trim();
                    $scope.policyHolder.OccupationId = e.target.dataset.key;
                    $scope.frmAboutPH.occupationPH = e.target.dataset.key;
                },
                setPolicyHolderNationalityId: function (e) {
                    $scope.txtPolicyHolderNationalityId = e.target.textContent.trim();
                    $scope.policyHolder.NationalityId = e.target.dataset.key;
                    $scope.frmAboutPH.nationalityPH = e.target.dataset.key;
                },
                setPhAddressProvinceId: function (e) {
                    $scope.txtPhAddressProvinceId = e.target.textContent.trim();
                    $scope.phAddress.ProvinceId = e.target.dataset.key;
                    $scope.frmYourAdress.ProvinceId = e.target.dataset.key;
                },
                setPhAddressDistrictId: function (e) {
                    $scope.txtPhAddressDistrictId = e.target.textContent.trim();
                    $scope.phAddress.txtPhAddressDistrictId = e.target.textContent.trim();

                    $scope.phAddress.DistrictId = e.target.dataset.key;
                    $scope.frmYourAdress.district = e.target.dataset.key;
                },


                showModel: function (e) {
                    setTimeout(() => {
                        $("#vehicleMake3").removeClass('open');
                        $("#vehicleModel3").addClass('open');
                        $('.scrollArrow').remove();
                        var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                        $("#arrowModel").html(divArrow);
                    });
                },
                showYear: function (e) {
                    setTimeout(() => {
                        $("#vehicleModel3").removeClass('open');
                        $("#vehicleFirstReg3").addClass('open');
                        $('.scrollArrow').remove();
                        var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                        $("#arrowYear").html(divArrow);
                    });
                },
                showDesc: function (e) {
                    setTimeout(() => {
                        $("#vehicleFirstReg3").removeClass('open');
                        $("#vehiceDesc3").addClass('open');
                        $('.scrollArrow').remove();
                        var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                        $("#arrowDesc").html(divArrow);
                    })
                },
                getFormatDateText: function () {
                    if(HKLocale.getLocale() == 'th-th') {
                        return 'วว/ดด/ปปปป';
                    } else {
                        return 'DD/MM/YYYY';
                    }
                },    
                showProvince: function (e) {
                    if($scope.asset && !$scope.asset.LicenseProvinceId2){
                        setTimeout(() => {
                            $("#vehiceDesc3").removeClass('open');
                            $("#vehicleProvince3").addClass('open');
                            $('.scrollArrow').remove();
                            var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                            $("#arrowDesc").html(divArrow);
                        })
                    }
                },
                showCamera: function () {
                    setTimeout(() => {
                        $("#vehicleProvince3").removeClass('open');
                        $("#vehicleCameraDropdown").addClass('open');
                    })
                },
                showCameraStyle: function () {
                    if ($scope.pageSubmitted && $scope.frmAboutCar.isCarCameraInstalled.$invalid){
                        return '1px solid #da4453';
                    }
                    else {
                        if( $scope.asset && $scope.asset.IsCarCameraInstalled == true){
                            return'1px solid rgb(225, 228, 235)';
                        }
                        else if( $scope.asset && $scope.asset.IsCarCameraInstalled == false)  {
                            return'1px solid rgb(225, 228, 235)';
                        } 
                        else{
                            if($scope.asset && $scope.asset.LicenseProvinceId2){
                                return '2px solid #25A8E0';
                            }
                        }
                    }
                },
                showUsage: function() {
                    if($scope.CheckToCalculate == "2"){
                        return'1px solid rgb(225, 228, 235)'; 
                    }
                    else if ($scope.PutDataDone == "1"){
                        return'1px solid rgb(225, 228, 235)'; 
                    }
                    else{
                        if($scope.asset && !$scope.asset.UsageId){
                            if ($scope.asset.IsCarCameraInstalled == true || $scope.asset.IsCarCameraInstalled == false){
                                return '2px solid #25A8E0';
                            }
                        }
                        return'1px solid rgb(225, 228, 235)';
                    }
                },
                showCalendar: function(){
                    var windowsize = $(window).width();
                    if($scope.CheckToCalculate != "2") {
                        if ($scope.PutDataDone != "1") {
                            var OpenCalendar = document.getElementById("currentProposalPolicyStartDate");
                            if( $scope.asset.UsageId >= "1" ){
                                if(windowsize > 599){
                                    OpenCalendar.focus();
                                }
                            }
                        }
                    }
                },
                showCalendarDDABTesting: function(e){
                    if($scope.currentProposal && $scope.currentProposal.PolicyStartDate_DD){
                        e.stopPropagation();
                        $("#policyStartDateDD3").removeClass('open');
                        $("#policyStartDateDD3").addClass('open');

                        $('.scrollArrow').remove();
                        var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                        $("#arrowModel").html(divArrow);

                    }
                },
                showCalendarMONABTesting: function(e){
                    if($scope.currentProposal && $scope.currentProposal.PolicyStartDate_MON){
                        e.stopPropagation();
                        $("#policyStartDateDD3").removeClass('open');
                        $("#policyStartDateMON3").addClass('open');

                        $('.scrollArrow').remove();
                        var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                        $("#arrowModel").html(divArrow);
                    }
                },
                showCalendarYearABTesting: function(e){
                    if($scope.currentProposal && $scope.currentProposal.PolicyStartDate_Year){
                        e.stopPropagation();
                        $("#policyStartDateMON3").removeClass('open');
                        $("#policyStartDateYear3").addClass('open');

                        $('.scrollArrow').remove();
                        var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                        $("#arrowModel").html(divArrow);

                    }
                },
                showCalendarBirthDateDDABTesting: function(e){
                    if($scope.mainDriver && !$scope.mainDriver.DOB_DD){
                        e.stopPropagation();
                        $("#MDProvince3").removeClass('open');
                        $("#mainDriverDOBDD3").addClass('open');

                        $('.scrollArrow').remove();
                        var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                        $("#arrowModel").html(divArrow);
                    }
                },
                showCalendarBirthDateMONABTesting: function(e){
                    if($scope.mainDriver && !$scope.mainDriver.DOB_MON){
                        setTimeout(() => {
                            $("#mainDriverDOBMON3").addClass('open');
                        })
                    }
                },
                showCalendarBirthDateYearABTesting: function(e){
                    if($scope.mainDriver && !$scope.mainDriver.DOB_Year){
                        setTimeout(() => {
                            $("#mainDriverDOBYear3").addClass('open');
                        })
                    }
                },
                showPayment: function (e) {
                },
                showMDProvince: function(e){
                    if($scope.phAddress && !$scope.phAddress.MDProvinceId){
                        setTimeout(() => {
                            $("#MDProvince3").addClass('open');
                        })
                    }
                },
                showBirthDate: function(){
                    if ($scope.mainDriver.DateOfBirth) return

                    if ($('#mainDriverDOB').is(':visible')) {
                        var windowsize = $(window).width();
                        var OpenCalendar = document.getElementById("mainDriverDOB");
                        if( $scope.mainDriver && !$scope.mainDriver.GenderId ){
                            if(windowsize > 599){
                                OpenCalendar.focus();
                            }
                            $("#MDProvince3").removeClass('open');
                            $('.scrollArrow').remove();
                        }
                    } else {
                        setTimeout(() => {
                            $('#mainDriverDOBDD3').addClass('open');
                        })
                    }
                },
                showGender: function () {
                    if (($scope.pageSubmitted || $scope.frmAboutMainDriver.mainDriverGender.$dirty) && $scope.frmAboutMainDriver.mainDriverGender.$invalid){
                        return '1px solid #da4453';
                    }
                    else {
                        if ( $scope.mainDriver.GenderId >= "1"){
                            return'1px solid rgb(225, 228, 235)';
                        }
                        else {
                            if ($scope.mainDriver.DateOfBirth && $scope.isClickBirthDateOfYear){
                                return '2px solid #25A8E0';
                            }
                        }
                    }

                },
                showGenderDropdown: function () {
                    if (!$scope.mainDriver.GenderId) {
                        setTimeout(() => {
                            $('#mainDriverGenderDropdown').addClass('open');
                        })
                    }
                },
                showMaritalStatusDropdown: function () {
                    if (!$scope.mainDriver.MaritalStatusId) {
                        setTimeout(() => {
                            $('#mainDriverMaritalStatusDropdown').addClass('open');
                        })
                    }
                },         
                showPayments: function() {
                    if (($scope.pageSubmitted || $scope.frmAboutYourPolicy.financialOption.$dirty) && $scope.frmAboutYourPolicy.financialOption.$invalid){
                        console.log("enter to this functions");
                        return '1px solid #da4453';
                    }
                    // else {
                    //     if ( $scope.mainDriver.MaritalStatusId >= "1"){
                    //         return'1px solid rgb(225, 228, 235)';
                    //     }
                    //     else {
                    //         if ( $scope.mainDriver.GenderId >= "1"){
                    //             return '2px solid #25A8E0';
                    //         }
                    //     }
                    // }
    
                },
                showStatus: function () {
                    if (($scope.pageSubmitted || $scope.frmAboutMainDriver.mainDriverMaritalStatus.$dirty) && $scope.frmAboutMainDriver.mainDriverMaritalStatus.$invalid){
                        return '1px solid #da4453';
                    }
                    else {
                        if ( $scope.mainDriver.MaritalStatusId >= "1"){
                            return'1px solid rgb(225, 228, 235)';
                        }
                        else {
                            if ( $scope.mainDriver.GenderId >= "1"){
                                return '2px solid #25A8E0';
                            }
                        }
                    }

                },
                showFinancialOption: function () {
                    if (($scope.pageSubmitted || $scope.frmAboutYourPolicy.financialOption.$dirty) && $scope.frmAboutYourPolicy.financialOption.$invalid){
                        return '1px solid #da4453';
                    }
                    else {
                        if ( $scope.currentProposal.PaymentTermId >= "1"){
                            return'1px solid rgb(225, 228, 235)';
                        }
                        else {
                            if (typeof $scope.asset.IsCarCameraInstalled === 'boolean' && $scope.asset.UsageId){
                                return '2px solid #25A8E0';
                            }
                        }
                    }

                },
                showFinancialOptionDropdown: function () {
                    if (!$scope.currentProposal.PaymentTermId) {
                        setTimeout(() => {
                            $("#vehicleCameraDropdown").removeClass('open');
                            $("#financialOptionDropdown").addClass('open');
                        })
                    }
                },
                OnClickMaritalStatusId: function(e){
                    fn.eventHandlers.showOccupation(e);
                },
                showOccupation: function(e){
                    if($scope.mainDriver && !$scope.mainDriver.OccupationId){
                        setTimeout(() => {
                            $("#Occupation3").addClass('open');
                        });
                    }
                },
                showDriverExperience: function () {
                    if($scope.pageSubmitted && $scope.frmAboutMainDriver.mainDriverDrivingExp.$invalid){
                        return '1px solid #da4453';
                    }
                    else {
                        if( $scope.mainDriver && $scope.mainDriver.DrivingExperienceId > "0"){
                            return'1px solid rgb(225, 228, 235)';
                        }
                        else {
                            if ($scope.mainDriver && $scope.mainDriver.OccupationId > "1") {
                                return '2px solid #25A8E0';
                            }
                        }
                    }
                },
                showDriverExperienceDropdown: function () {
                    setTimeout(() => {
                        if (!$scope.mainDriver.DrivingExperienceId) {
                            $("#mainDriverDrivingExpDropdown").addClass('open');
                        }
                    });
                },
                OnClickAccident: function(e){
                    fn.eventHandlers.showAccident(e);
                },
                showAccident: function (e) {
                    if($scope.mainDriver && !$scope.mainDriver.NrOfClaimsInPeriodId){
                        setTimeout(() => {
                            $("#accident3").addClass('open');
                        });
                    }
                },
                OnClickClaims: function (e) {
                    $scope.showNameBorder(e);
                },
                showNameBorder: function(e){
                        if($scope.frmContactInfo && $scope.frmContactInfo.givenNamePolicyholder.$invalid === true){
                            $("#givenNamePolicyholder").focus();
                        }
                },
                showFirstBorder: function (e,sectionNumber){
                    if(e.key === "Enter"){
                        $scope.showNumberPhone(e);
                    }
                },
                scrollToSection: function(sectionNumber) {
                    var section = document.querySelector('[data-section="' + sectionNumber + '"]');
                    if (section) {
                      section.scrollIntoView({ behavior: 'smooth' });
                    }
                },
                showNumberPhone: function (e,sectionNumber) {
                    if($scope.frmContactInfo && $scope.frmContactInfo.mainContactPolicyholder2.$invalid){
                        $("#mainContactPolicyholder2").focus();
                    }
                    if($scope.frmContactInfo && !$scope.frmContactInfo.mainContactPolicyholder2.$invalid){
                        if(e.key === "Enter"){
                            $scope.showEmail(e);
                        }
                    }
                },
                showEmail: function (e,sectionNumber) {
                    if($scope.frmContactInfo && $scope.frmContactInfo.emailPolicyholder.$invalid){
                        $("#emailPolicyholder").focus();
                    }
                    if($scope.frmContactInfo && !$scope.frmContactInfo.emailPolicyholder.$invalid){
                        if(e.key === "Enter"){
                            e.preventDefault(); 
                            $("#emailPolicyholder").removeClass("openBorder");
                            document.getElementById("emailPolicyholder").blur();
                            document.getElementById("consent_message").focus();
                        }
                    }
                },
                selectCoverage: function (c) {
                    var hasError = false;
                    // Call custome function
                    // To check contact customer details before sent to MAXAR
                    fn.eventHandlers.onCustomerLeadDetailsChecker()

                    // Face missing fields before send to MAXAR
                    if($scope.warningCounter > 0){
                        // maintain the old state of button
                        fn.eventHandlers.onMaxarMissingLeadFieldsChecker()
                    } else {
                        $scope.loadingSpinner3 = true;
                        $scope.isCalculateAction = false;
                        // to get currently "CoverTypeId"
                        $scope.getMyCoverTypeId = c.CoverTypeId
                        $scope.selectedCoverage = c;

                        if($scope.getMyCoverTypeId == $scope.TYPE2_FALCON_DG || $scope.getMyCoverTypeId == $scope.TYPE3_FALCON_DG){
                            // to set "singlepayment state only" once customer select Type2 & Type3
                            $sessionStorage.toSetSinglePaymentFromType2Type3Selected = true
                            $scope.type2and3RefreshingState = $sessionStorage.toSetSinglePaymentFromType2Type3Selected



                            if($scope.currentProposal && $scope.currentProposal.PaymentTermId && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0 && $scope.currentProposal.PaymentTermId == $scope.INSTALLMENT_PAYMENT) {
                            } else {
                                $scope.currentProposal.PaymentTermId = $scope.SINGLE_PAYMENT;
            
                                $scope.asset.PaymentTermId = $scope.SINGLE_PAYMENT;
                                if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                                    $scope.currentProposal.Assets[0].PaymentTermId = $scope.SINGLE_PAYMENT;
                                }  
                            }
                            // $sessionStorage.financialOptionSelection = 'singlepayment'
                            if($scope.currentProposal.PaymentTermId == $scope.SINGLE_PAYMENT) {
                                $sessionStorage.toSetFinancialChecker = 'singlepayment';
                                $scope.financialChecker = $sessionStorage.toSetFinancialChecker;
    
                            } else {
                                $sessionStorage.toSetFinancialChecker = 'installment';
                                $scope.financialChecker = $sessionStorage.toSetFinancialChecker;

                            }
            
                        }
                        else{
                            // to set "singlepayment state only" once customer select Type2 & Type3
                            $sessionStorage.toSetSinglePaymentFromType2Type3Selected = false
                            $scope.type2and3RefreshingState = $sessionStorage.toSetSinglePaymentFromType2Type3Selected

                            if($scope.currentProposal && $scope.currentProposal.PaymentTermId && $sessionStorage.financialOptionSelection) {
                                
                                if($sessionStorage.financialOptionSelection == "singlepayment") {
                                    $sessionStorage.toSetFinancialChecker = 'singlepayment';
                                    $scope.financialChecker = 'singlepayment';
                                    if($scope.currentProposal && $scope.currentProposal.PaymentTermId && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0 && $scope.currentProposal.PaymentTermId == $scope.INSTALLMENT_PAYMENT) {
                                    } else {              
                                        $scope.currentProposal.PaymentTermId = $scope.SINGLE_PAYMENT;
                                        $scope.asset.PaymentTermId = $scope.SINGLE_PAYMENT;    
                                    }
                                } else {
                                    $sessionStorage.toSetFinancialChecker = 'installment';
                                    $scope.financialChecker = 'installment';
                                    $scope.currentProposal.PaymentTermId = $scope.INSTALLMENT_PAYMENT;
                                    $scope.asset.PaymentTermId = $scope.INSTALLMENT_PAYMENT;    
                                }
                            }

                        }

                        for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                            $scope.coverages.Coverages[i].IsSelected = false;
                        }
                        c.IsSelected = true;
                        $scope.$parent.PlanId = $scope.asset.PlanId;      
                        
                        if($scope.asset) {
                            $scope.asset.LobInsuranceFormulaId = c.CoverTypeId;
                            if($scope.asset.Coverages && $scope.asset.Coverages.length > 0) {
                                for (var i = 0; i < $scope.asset.Coverages.length; i++) {
                                    $scope.asset.Coverages[i].IsSelected = false;
                                }
                                var coveragesIndex = $scope.asset.Coverages.findIndex(w => w.CoverTypeId == c.CoverTypeId);
                                if(coveragesIndex != -1){
                                    $scope.asset.Coverages[coveragesIndex].IsSelected = true;
                                }
                            }
                        }
                        
                        ApiServiceV2.getVehicleExcess({
                            onSuccess: function (data) {
                                if (data && data.length > 0) {
                                    data.sort((n1,n2) => {
                                        if (n1.amount > n2.amount) {
                                            return 1;
                                        }
                                    
                                        if (n1.amount < n2.amount) {
                                            return -1;
                                        }
                                    
                                        return 0;
                                    });
        
                                    data.map((dt) =>{
                                        dt.key = dt.level.toString();
                                        dt.value = Number(dt.amount) + '.00';
                                    })
                                    if (data[0] && data[0].value) {
                                        $scope.allExcess = data[0].value;
                                        $scope.selectedCoverage.excess = data;                            
                                        c.Covers.forEach(function (xx, n) {
                                            if(c.ProductId == $scope.PRODUCT_ID_DA) {
                                                if (xx.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA) {
                                                    $scope.selectedCoverage.ExcessLevelId = xx.ExcessLevelId;
                                                }    
                                            } else if(c.ProductId == $scope.PRODUCT_ID_AXA) {
                                                if (xx.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA) {
                                                    $scope.selectedCoverage.ExcessLevelId = xx.ExcessLevelId;
                                                }    
                                            }
                                        });                
                                    }
                                }
                            },
                            onError: function (err) {
    
                                if(!hasError) {
                                    $scope.loadingSpinner3 = false;
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'selectCoverage', method: 'selectCoverage'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 7803'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }
                                hasError = true;
    
                            }
                        }, 'VEHICLE_EXCESS', `formulaId=${$scope.selectedCoverage.CoverTypeId}&productVersionId=${$scope.selectedCoverage.ProductVersionId}`);
    
                        if (c.CoverTypeId == $scope.TYPE3_FALCON_DG || c.CoverTypeId == $scope.TYPE2_FALCON_DG) {
                            
                            /*disable and default MyWorkshop for Types 2 and 3*/
                            $scope.asset.UseMyWorkshop = false; //sets to Panel
                            //$scope.IsMyWorkshopDisabled=true;
                        } 
                        else if (c.CoverTypeId == $scope.TYPE1_FALCON_DG || c.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG || c.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG) {
                            var chk = $filter('filter')(c.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUPID_FALCON_2000116 }, true);
                            if (chk) {
                                if (chk[0]) {
                                    if (chk[0].IsSelected === true) {
                                        $scope.asset.UseMyWorkshop = true;
                                    }
                                }
                            } else {
                                $scope.asset.UseMyWorkshop = false;
                            }
                        }

                        var cc = fn.utils.getSelectedCoverage();
                        $scope.coverNum = cc.Covers.length;
                        var deductibleAmount = Utils.getCoverVoluntaryBasicByProductId(cc);
                        if(deductibleAmount && deductibleAmount.length > 0) {
                            deductibleAmount = deductibleAmount[0]
                            if(deductibleAmount && deductibleAmount.ExcessLevelId) {
                                if(deductibleAmount.ExcessAmount || deductibleAmount.ExcessAmount == 0) {
                                    marketing.dLCE(57,deductibleAmount.ExcessAmount.toFixed(2));
                                } else {
                                    if($scope.selectedCoverage && $scope.selectedCoverage.excess) {
                                        let excess =  $scope.selectedCoverage.excess.find(w => w.level == deductibleAmount.ExcessLevelId);
                                        if(excess) {
                                            deductibleAmount.ExcessAmount = excess.amount;
                                            marketing.dLCE(57,deductibleAmount.ExcessAmount.toFixed(2));
                                        } else {
                                            marketing.dLCE(57,'0.00');
                                        }       
                                    } else {
                                        marketing.dLCE(57,'0.00');
                                    }
                                }
                            }
                        }
                        //$scope.coverNum = 0;
                        $scope.coverDetailsClicked = true;
                        $scope.isCompulsoryOnly = false;
                        $scope.totalPremium = fn.calculateTotalAmout();

                        $scope.totalPremium2 = $scope.totalPremium;

                        if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0) {
                            $scope.currentProposal.Assets[0].LobInsuranceFormulaId = c.CoverTypeId;
                            if($scope.currentProposal.Assets[0].Coverages && $scope.currentProposal.Assets[0].Coverages.length > 0) {
                                for (var i = 0; i < $scope.currentProposal.Assets[0].Coverages.length; i++) {
                                    $scope.currentProposal.Assets[0].Coverages[i].IsSelected = false;
                                }
                                var coveragesIndex = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == c.CoverTypeId);
                                if(coveragesIndex != -1){
                                    $scope.currentProposal.Assets[0].Coverages[coveragesIndex].IsSelected = true;
                                    $scope.currentProposal.Assets[0].LobInsuranceFormulaId = $scope.selectedCoverage.CoverTypeId;
                                    $scope.currentProposal.Assets[0].ProductId = $scope.selectedCoverage.ProductId;

                                }
                            }
                        }

                        //Switching Product
                        if($scope.currentProposal && $scope.selectedCoverage && $scope.currentProposal.ProductId &&  $scope.selectedCoverage.ProductId) {
                            if($scope.currentProposal.ProductId == $scope.selectedCoverage.ProductId) {
        
                                fn.saving.saveCoveragesModelParent();
                            } else {

                                $scope.$parent.product.ProductId = $scope.selectedCoverage.ProductId;
                                $scope.$parent.asset.ProductId = $scope.selectedCoverage.ProductId;
                                $scope.currentProposal.ProductId = $scope.selectedCoverage.ProductId;
                                $scope.asset.LobInsuranceFormulaId = $scope.selectedCoverage.CoverTypeId;
                                $scope.asset.ProductId = $scope.selectedCoverage.ProductId;
                                $scope.asset.Coverages = $scope.coverages.Coverages;     
                                var coverTypeIds = [];
                                $scope.coverages.Coverages.map((d) => {
                                    coverTypeIds.push(d.CoverTypeId);
                                })     
                                if(coverTypeIds && coverTypeIds.length > 0) {
                                    $scope.asset.CoverTypeIds = coverTypeIds;                                   
                                }
                                $scope.coverageList = $scope.coverages.Coverages;
                                angular.extend($scope.coverages, {CoverListsCount: $scope.coverages.Coverages.length,Coverages: $scope.coverages.Coverages});

                                $scope.coverages.isForUpdate = true;

                                fn.saving.saveCoveragesModelToSwitchNewProductParent();
                            }
                        } else {
                            fn.saving.saveCoveragesModelParent();
                        }
                        
                        // Get priority by action trigger here
                        if(!$scope.isCalculateAction){
                            fn.eventHandlers.getPriorityByAction();
                        }


                        /*slide down to correct type , if only its display on small screen*/
                        if ($window.matchMedia) {
                            var mq = $window.matchMedia("(max-width:991px)");
                            if (mq) {
                                if (mq.matches) {
                                    var anc = '#dacover_' + c.CoverTypeId;

                                    /*timeout cos the box expands and thus creates problems with top-margin calc*/
                                    $timeout(function () {
                                        fn.gtAnchor(anc);
                                    }, 100);
                                }
                            }
                        }
                        fn.utils.setSumInsured();
                    }
                },
                showContactPopUp: function(){
                    $scope.contactPopUpOpened = true;
                    $rootScope.contactPopUpOpened = true;
                    $scope.popUpContact.name = $scope.policyHolder.FullName;
                    $scope.popUpContact.phone = $scope.phContact.PrimaryPhoneNumber;
                    DaModal.open('#contactPopUp', {onClose: () => {
                        $scope.contactPopUpOpened = false;
                        $rootScope.contactPopUpOpened = false;
                        $scope.popUpContactSubmitted = false;
                        $scope.popUpContactHasOpened = true;
                        if($scope.sessionExpired && !$scope.isThankYouPopUpOpended){
                            DaModal.open('#sessionExpired', { onClose: fn.goToStart });    
                        }
                    }});
                },
                sendPopUpContactToMaxar: function(){

                    let setUpData = obj => {
                        let utmzz = ($cookies['__utmzz'] || '').split('|')
                        .map(string => string.split('='))
                        .reduce((map, [key, value]) => (map[key] = value, map), {})
                        let ga_source = utmzz.utmcsr || '';
                        let ga_campaign = utmzz.utmccn || '';
                        if (ga_campaign === 0 || ga_campaign === null || ga_campaign === "") ga_campaign = "-";
                        if (ga_source === 0 || ga_source === null || ga_source === "") ga_source = "-";

                        let ck = $cookies["__utmzz"];

                        if(ck == "utmcsr=google|utmcmd=organic|utmccn=(not set)|utmctr=(not provided)"){
                            ga_campaign = "Organic";
                            ga_soruce = "Organic";
                        }

                        obj.gaCampaign = ga_campaign;
                        obj.sourceChannel = ga_source;
                    }

                    $scope.popUpContactSubmitted = true;
                    if($scope.popUpContact.name && $scope.popUpContact.phone && $scope.popUpContact.consent && $scope.phoneRegex.test($scope.popUpContact.phone)){
                        let obj = {};
                        let maxarRequest = {};
                        let maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;
                        if(!maxarLayer){
                            maxarLayer = [];
                            setUpData(obj);
                        } 
                        for (let i = 0; i <= maxarLayer.length; i++) {
                            angular.extend(obj, maxarLayer[i]);
                        }

                        const sourcePage = "Your Quote";
                        const date = new Date();

                        const getSplitFullname = $scope.popUpContact.name.split(' ').map(element => element.trim()).filter(element => element !== '');
                        maxarRequest.blank10 = "";
                        maxarRequest.callback = "no";
                        maxarRequest.channelDetail = obj.channelDetail ? obj.channelDetail: "-";
                        maxarRequest.consent = "true";
                        maxarRequest.dataFinal = obj.dataFinal ? obj.dataFinal: "Y";
                        maxarRequest.email = obj.email ? obj.email : "";
                        maxarRequest.endDate = obj.endDate ? obj.endDate : ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                        maxarRequest.firstName = getSplitFullname[0];
                        maxarRequest.lastName = getSplitFullname[1] ? getSplitFullname[1] : '-';
                        maxarRequest.gaCampaign = obj.gaCampaign ? obj.gaCampaign : "-";
                        maxarRequest.language = "th";
                        maxarRequest.priority = $scope.staticData.junkNumbers.includes($scope.popUpContact.phone) ? 17 : 2;
                        maxarRequest.referenceID = $scope.popUpContact.phone;
                        maxarRequest.sourceChannel = obj.sourceChannel ? obj.sourceChannel: "-";
                        maxarRequest.sourcePage = sourcePage;
                        maxarRequest.telephone = $scope.popUpContact.phone;
                        maxarRequest.type = "Web lead";
                        maxarRequest.email = "guest@directasia.com"

                        let requestString = JSON.stringify(maxarRequest);
                        $scope.isPopUpLoading = true;

                        ExternalService.relaySend( {
                            onSuccess: function (data, status) {
                                $scope.isPopUpLoading = false;
                                $scope.closeDaModal('#contactPopUp');
                                $scope.isThankYouPopUpOpended = true;
                                $rootScope.isThankYouPopUpOpended = true;
                                DaModal.open('#thankYouPopUp', {onClose: () => {
                                    $scope.isThankYouPopUpOpended = false
                                    $rootScope.isThankYouPopUpOpended = false;
                                    if($scope.sessionExpired){
                                        DaModal.open('#sessionExpired', { onClose: fn.goToStart });    
                                    }
                                }})
                                
                            },
                            onError: function (err, status) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'sendPopUpContactToMaxar', method: 'ExternalService.relaySend'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'sendPopUpContactToMaxar : line 9552'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true
                                $scope.status = status;
                            }
                        }, requestString);
                    }
                },
                onBtnSaveAndExitClicked: function () {

                    if ($scope.phContact.PrimaryPhoneNumber) {
                        if (!$scope.phoneRegex.test($scope.phContact.PrimaryPhoneNumber)) {
                            $scope.phContact.PrimaryPhoneNumber = "";
                        } 
                        else {
                        }
                    }

                    fn.utils.setIsSaving(true);

                    var precallback = {
                        onSuccess: function () {
                            fn.saving.saveCoveragesModel(callbacks1);
                        }
                    };
                    var callbacks1 = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacks2);
                        }
                    };

                    var callbacks2 = {
                        onSuccess: function () {
                            hasError = false;
                            fn.saving.calculatePremium({
                                onSuccess: function () {
                                    fn.saving.saveOtherDetailsPartSave(callbacks3);
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onBtnSaveAndExitClicked', method: 'fn.saving.calculatePremium'} ;
                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 7965'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true;    
                                    fn.saving.saveOtherDetailsPartSave(callbacks3);
                                }
                            });
                        }
                    };

                    var callbacks3 = {
                        onSuccess: function () {
                            fn.eventHandlers.onBtnExitClicked();
                        }

                    };


                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                    $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                    fn.currentProposalApi[saveCurrentProposalFunc](precallback);
                },
                setCompulsory: function (val,firstCal) {
                    var hasError = false;
                    let isCompulsory;
                    // Call custome function
                    // To check contact customer details before sent to MAXAR
                    fn.eventHandlers.onCustomerLeadDetailsChecker()

                    // Face missing fields before send to MAXAR
                    if(!firstCal) {
                        $scope.compulsoryChecker = !val;
                        isCompulsory = val;    
                        
                    } else if(firstCal == "No") {
                        $scope.compulsoryChecker = true;
                        isCompulsory = !val;    
                    }
                     else {
                        isCompulsory = !val;
                    }
                    $scope.compulsory.isIncluded = $scope.compulsoryChecker;

                    if($scope.warningCounter > 0){
                        // maintain the old state of "Compulsory button"
                        if(!firstCal) {
                            marketing.dLCE(56,$scope.compulsoryChecker)
                        }

                        fn.eventHandlers.onMaxarMissingLeadFieldsChecker(); 

                    } else {                   
                        // change state of "Compulsory button"
                        if(!firstCal) {
                            marketing.dLCE(56,$scope.compulsoryChecker)
                        }

                        // To set compulsory values (new version)
                        if (!$scope.coverages) return
                        for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                            /* CCB */
                            var ccb = Utils.getCoverComplusoryBasicCoverByProductId($scope.coverages.Coverages[i]);
                            if (ccb && ccb.length > 0) { ccb[0].IsSelected = $scope.compulsoryChecker }

                            /* Death & Dismemberment or permanent disability */
                            var ddpd = fn.utils.getCoverDeathDisByProductId($scope.coverages.Coverages[i]);
                            if (ddpd && ddpd.length > 0) { ddpd[0].IsSelected = $scope.compulsoryChecker }

                            /* Bodily Injury or affecting health */
                            var bih = fn.utils.getCoverBodilyInjuryByProductId($scope.coverages.Coverages[i]);
                            if (bih && bih.length > 0) { bih[0].IsSelected = $scope.compulsoryChecker }

                            /* Compulsory Core Benefits */
                            var bih = fn.utils.getCoverCompulsoryCoreByProductId($scope.coverages.Coverages[i]);
                            if (bih && bih.length > 0) { bih[0].IsSelected = $scope.compulsoryChecker }
                        }

                        $scope.loadingSpinner3 = true
                        $scope.$parent.showPriceOnTopFlag = false;

                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId

                        if($scope.coverages.Coverages && $scope.coverages.Coverages.length > 0) {
                            $scope.coverages.Coverages.map((data) => {
                                var getIndexOfCoverTypeId = $scope.coverages.Coverages.findIndex(w => w.CoverTypeId == data.CoverTypeId);
                                if(getIndexOfCoverTypeId != -1){
                                    var getIndexOfCover = fn.utils.getCoverComplusoryBasicCoverIndexByProductId($scope.coverages.Coverages[getIndexOfCoverTypeId]);
                                    if(getIndexOfCover != -1) {
                                        $scope.coverages.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].IsSelected = $scope.compulsoryChecker;

                                        let firstPayment = fn.eventHandlers.getPaymentScheduleIncludeCompusoly($scope.coverages.Coverages[getIndexOfCoverTypeId],isCompulsory);
                                        if(firstPayment && $scope.coverages.Coverages[getIndexOfCoverTypeId].PaymentSchedule && $scope.coverages.Coverages[getIndexOfCoverTypeId].PaymentSchedule.length > 0) {
                                            $scope.coverages.Coverages[getIndexOfCoverTypeId].PaymentSchedule[0].Amount = firstPayment;
                                        }

                                        let totalAmount = fn.eventHandlers.getTotalAmountIncludeCompusoly($scope.coverages.Coverages[getIndexOfCoverTypeId],isCompulsory, firstCal);
                                        if(totalAmount && $scope.coverages.Coverages[getIndexOfCoverTypeId].CalculatedPremium) {
                                            $scope.coverages.Coverages[getIndexOfCoverTypeId].CalculatedPremium = totalAmount;

                                        }


                                    }
                                }        
                            })
                        }

                        if($scope.asset.Coverages && $scope.asset.Coverages.length > 0){
                            $scope.asset.Coverages.map((data) => {
                                var getIndexOfCoverTypeId = $scope.asset.Coverages.findIndex(w => w.CoverTypeId == data.CoverTypeId);
                                if(getIndexOfCoverTypeId != -1){
                                    var getIndexOfCover = fn.utils.getCoverComplusoryBasicCoverIndexByProductId($scope.asset.Coverages[getIndexOfCoverTypeId]);
                                    if(getIndexOfCover != -1) {

                                        $scope.asset.Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].IsSelected = $scope.compulsoryChecker;

                                        // let firstPayment = fn.eventHandlers.getPaymentScheduleIncludeCompusoly($scope.asset.Coverages[getIndexOfCoverTypeId],isCompulsory);
                                        // if(firstPayment && $scope.asset.Coverages[getIndexOfCoverTypeId].PaymentSchedule && $scope.asset.Coverages[getIndexOfCoverTypeId].PaymentSchedule.length > 0) {
                                        //     $scope.asset.Coverages[getIndexOfCoverTypeId].PaymentSchedule[0].Amount = firstPayment;
                                        // }
                                        // let totalAmount = fn.eventHandlers.getTotalAmountIncludeCompusoly($scope.asset.Coverages[getIndexOfCoverTypeId],isCompulsory);
                                        // if(totalAmount && $scope.asset.Coverages[getIndexOfCoverTypeId].CalculatedPremium) {
                                        //     $scope.asset.Coverages[getIndexOfCoverTypeId].CalculatedPremium = totalAmount;
                                        // }


                                    }
                                }        
                            })
                        }

                        if($scope.currentProposal && $scope.currentProposal.Assets.length > 0) {
                            if($scope.currentProposal.Assets[0].Coverages && $scope.currentProposal.Assets[0].Coverages.length > 0) {
                                $scope.currentProposal.Assets[0].Coverages.map((data) => {
                                    var getIndexOfCoverTypeId = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == data.CoverTypeId);
                                    if(getIndexOfCoverTypeId != -1){
                                        var getIndexOfCover = fn.utils.getCoverComplusoryBasicCoverIndexByProductIds($scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId],$scope.asset.Coverages[getIndexOfCoverTypeId].ProductId);
                                        if(getIndexOfCover != -1) {
                                            $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].Covers[getIndexOfCover].IsSelected = $scope.compulsoryChecker;

                                            // if(firstCal) {
                                            //     let firstPayment = fn.eventHandlers.getPaymentScheduleIncludeCompusoly($scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId],isCompulsory);
                                            //     if(firstPayment && $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].PaymentSchedule && $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].PaymentSchedule.length > 0) {
                                            //         $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].PaymentSchedule[0].Amount = firstPayment;
                                            //     }

                                            //     let totalAmount = fn.eventHandlers.getTotalAmountIncludeCompusoly($scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId],isCompulsory);
                                            //     if(totalAmount && $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].CalculatedPremium) {
                                            //         $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].CalculatedPremium = totalAmount;
                                            //     }    
                                            // }
                                        }
                                    }            
                                })
                            }
                        }
                        if($scope.currentProposal && $scope.currentProposal.PaymentSchedules && $scope.currentProposal.PaymentSchedules.length > 0) {
                            if($scope.currentProposal && $scope.currentProposal.Assets.length > 0) {
                              if($scope.currentProposal.Assets[0].Coverages && $scope.currentProposal.Assets[0].Coverages.length > 0 && $scope.selectedCoverage) {
                                var getIndexOfCoverTypeId = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == $scope.selectedCoverage.CoverTypeId);
                                if(getIndexOfCoverTypeId != -1){
                                    if($scope.currentProposal.PaymentSchedules && $scope.currentProposal.PaymentSchedules.length > 0) {
                                        $scope.currentProposal.PaymentSchedules[0].Amount = $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].PaymentSchedule[0].Amount;
                                        if($scope.currentProposal.FirstPayment) {
                                            $scope.currentProposal.FirstPayment = $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].PaymentSchedule[0].Amount;
                                        }    
                                    }
                                    if($scope.currentProposal.TotalPremium) {
                                        $scope.currentProposal.TotalPremium = $scope.currentProposal.Assets[0].Coverages[getIndexOfCoverTypeId].CalculatedPremium;
                                    }
                                }
                              }
                            }
                        }

                        fn.utils.setSumInsured(isCompulsory);
                        fn.utils.paymentScheduleToShow($scope.thirdPageShow, isCompulsory ,firstCal);

                        if(!firstCal) {

                            var callbackBiLog = {
                                onSuccess: function () {
                                    fn.eventHandlers.biLog(callbackPartialSave);
                                }
                            };
                            var callbackPartialSave = {
                                onSuccess: function () {
                                    fn.saving.saveOtherDetailsPartSave(callbackSuccessCMIUpdate,firstCal ? false : true);
                                }
                            };
                            var callbackSuccessCMIUpdate = {
                                onSuccess: function () {
                                    if($scope.loadingSpinner3){
                                        $scope.loadingSpinner = false;
                                        $scope.loadingSpinner2 = false;
                                        $scope.loadingSpinner3 = false;
                                        $scope.$parent.showPriceOnTopFlag = true;
                                        $scope.allowBtnAction = true; 
                                    }            
                                }
                            };
                            fn.updatedCurrentProposal(callbackBiLog,firstCal ? false : true);
                        }


                    }
                },
                biLog: function (callbacks) {
                    fn.logApi.writeLog({
                        onSuccess: function () {
                            callbacks.onSuccess();
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveCurrentProposal', method: 'writeLog'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 8173'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;            
                        }
                    });
                },
                getPaymentScheduleIncludeCompusoly: function (c,isIncluded){
                    let amount = 0.00;
                    let amountIns  = 0.00;
                    let firstPayment = 0.00;
                    if(!c) { return firstPayment}
                    if(c) {
                        let totalPremiumToColl =  fn.utils.getComplusoryBasicCoverPremium(c.Covers,c.ProductId);
                      if(c.PaymentSchedule && c.PaymentSchedule.length > 0) {
                        amountIns = c.PaymentSchedule[0].Amount;
                      }
                      amount = isIncluded ? amountIns - totalPremiumToColl : amountIns + totalPremiumToColl;
                  
                      if(c.PaymentSchedule && c.PaymentSchedule.length > 0) {
                        firstPayment = parseFloat((Math.round((amount + Number.EPSILON) * 100) / 100).toFixed(2));
                      }  
                      return firstPayment;
                    }

                }, 
                getTotalAmountIncludeCompusoly: function (c,isIncluded, firstCal){
                    let amount = 0.00;
                    let amountIns  = 0.00;
                    if(!c) { return amount}
                    if(c) {
                      let totalPremiumToColl =  fn.utils.getComplusoryBasicCoverPremium(c.Covers,c.ProductId);
                      if(c.CalculatedPremium) {
                        amountIns = c.CalculatedPremium;
                      }
                    //   if($scope.isProductSwitching){
                    //     if(!isIncluded){
                    //         isIncluded = !isIncluded;
                    //     }
                    //   }
                    if(firstCal == "No") {
                        if(!isIncluded) {
                            amount = amountIns;
                        } else {
                            amount = amountIns + totalPremiumToColl;
                        }
                    } else {
                        amount = isIncluded ? amountIns - totalPremiumToColl : amountIns + totalPremiumToColl;
                    }
                  
                      return amount;
                    }

                },            
                onBtnCoverExplainedClicked: function () {
                    DaModal.open('#coverExplained');
                },

                onBtnCoverExplainedSelected: function () {
                    DaModal.close('#coverExplained');
                },

                redPlateClickedOnB2C: function (getStatusRedPlate) {
                    
                    // "Red Plate"
                    if (getStatusRedPlate == true) {
                        $scope.asset.IsRedPlate = true
                        $scope.asset.LicenseCardProvinceId = "2000080"

                        // Property value to send to IDIT only (TH/ENG)
                        $scope.asset.VehicleRegistrationNumber = $scope.HKLocale.getLocale() == 'th-th' ? "ใหม่" : "New";
                    }

                    // "Not Red Plate"
                    if (getStatusRedPlate == false) {
                        $scope.asset.IsRedPlate = false         
                        
                        // "Set NULL" to property value to send to IDIT only (TH/ENG) 
                        $scope.asset.VehicleRegistrationNumber = ""
                    }

                },
                onIsCommercialPurposeClicked: function () {
                    if ($scope.asset.IsCommercialPurpose) {
                        DaModal.open('#usedForCommercial');
                    }
                },
                isCommercialUsage: function (getTypeOfCarDoors) {

                    // in case "2 Doors car"
                    if($scope.twoDoorsChecker == true){
                        // pass to process premium qoute
                        $scope.usedCommercialPopUpChecker = false
                    }

                    // in case "4 Doors car" or "undefined"
                    if($scope.twoDoorsChecker != true){

                        // private use
                        if(getTypeOfCarDoors == 6){
                            // pass to process premium qoute
                            $scope.usedCommercialPopUpChecker = false
                        }

                        // private work
                        if(getTypeOfCarDoors == 1000000){
                            // pass to process premium qoute
                            $scope.usedCommercialPopUpChecker = false
                        }

                        // private business
                        if(getTypeOfCarDoors == 1000002){
                            // pass to process premium qoute
                            $scope.usedCommercialPopUpChecker = false
                        }

                        // commercial special
                        if(getTypeOfCarDoors == 7000000){
                            // DaModal.open("#usedForCommercial")
                            $scope.usedCommercialPopUpChecker = true
                            // return
                        }
                    }
                },
                postBLFC: function () {
                    $window.BLFCLayer = [];
                    var BLFCLayer = $window.BLFCLayer;
                    var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();
                    var idNumber = $scope.policyHolder.Identifier
                    var identifier = idNumber.replace(/-/g, "");
                    var cardType = "I"
                    if ($scope.policyHolder.ResidentialStatusId == 2000001) {
                        cardType = "P"
                    }

                    BLFCLayer.push({
                        mitBaseInitialParam: { MITSessionToken: sessionToken },
                        CardType: cardType,
                        Identification: identifier,
                        FirstName: $scope.policyHolder.FirstName,
                        LastName: $scope.policyHolder.LastName
                    });

                    if ($scope.policyHolder.FirstName !== '' && $scope.policyHolder.LastName !== '' && identifier.length === 13) {
                        $scope.$parent.checkBLFC();
                    }
                },
                /** Falcon UW API */
                postUWFC: function () {
                    hasError = false;
                    $window.UWFCLayer = [];
                    var UWFCLayer = $window.UWFCLayer;
                    var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();
                    fn.utils.setSumInsured();
                    if ($scope.asset.ModelId) {
                        var objParams = {};
                        objParams.manufacturerId = $scope.asset.ManufactureId;
                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                        wfeApiStaticDataModel.getModelListV2({
                            onSuccess: function (data) {
                                $scope.txtModel = $filter('filter')(data, { key: $scope.asset.ModelId });
                                if($scope.txtModel && $scope.txtModel.length > 0){
                                    $scope.txtModel = $scope.txtModel[0]
                                    if($scope.txtModel && $scope.txtModel.value) {
                                        $scope.txtModel = $scope.txtModel.value
                                    } else {
                                        $scope.txtModel = ''
                                    }
                                } else {
                                    $scope.txtModel = ''
                                }
                                $scope.txtMake = $filter('filter')($scope.staticData.makes, { key: $scope.asset.ManufactureId });
                                if($scope.txtMake && $scope.txtMake.length > 0){
                                    $scope.txtMake = $scope.txtMake[0]
                                    if($scope.txtMake && $scope.txtMake.value) {
                                        $scope.txtMake = $scope.txtMake.value
                                    } else {
                                        $scope.txtMake = ''
                                    }
                                } else {
                                    $scope.txtMake = ''
                                }
                                var getExcessLevelId = Utils.getCoverVoluntaryBasicByProductId($scope.selectedCoverage);

                                if(getExcessLevelId && getExcessLevelId.length > 0) {
                                    getExcessLevelId = getExcessLevelId[0]
                                    if(getExcessLevelId && getExcessLevelId.ExcessLevelId) {
                                        getExcessLevelId = getExcessLevelId.ExcessLevelId;
                                    } else {
                                        getExcessLevelId = 0
                                    }
                                } else {
                                    getExcessLevelId = 0
                                }
                                UWFCLayer.push({
                                    mitBaseInitialParam: { MITSessionToken: sessionToken },
                                    Make: $scope.txtMake,
                                    Model: $scope.txtModel,
                                    YearOfRegistration: $scope.asset.PurchasingYear.toString(),
                                    SumInsured: $scope.sumInsured.value,
                                    Deductible: parseInt(fn.wfeApiStaticData.utils.getStaticDataDisplay(getExcessLevelId, $scope.selectedCoverage.excess)),
                                    Premium: $scope.totalPremium2.toFixed(2)
                                });

                                // $scope.$parent.checkUWFC();
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'postUWFC', method: 'fn.utils.setSumInsured'} ;
                                    var errorLog = {err: JSON.stringify(error), case: err && err.ErrorInfo && err.ErrorInfo.StatusCode == 'VALIDATION_FAILED' ? 'Underwriting : line 8369' : 'error : line 8369'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true;    
                            }
                        });
                    }
                },
                onBtnConvertPolicyClicked: function () {
                    DaModal.open("#convertPolicyModal");
                },
                onBtnToSummary: function () {

                    // to check if customer select Type2 or Type3 (set singlePayment only)
                    if($sessionStorage.toSetSinglePaymentFromType2Type3Selected == true){
                        $sessionStorage.getPaymentTermIDtoFourthPage = $scope.SINGLE_PAYMENT
                    }

                    $scope.currentProposal.PaymentTermId = $sessionStorage.getPaymentTermIDtoFourthPage;
                    if($scope.asset && $scope.asset.PaymentTermId) {
                        $scope.asset.PaymentTermId =  $sessionStorage.getPaymentTermIDtoFourthPage;    
                   }

                    // "confirmCounter" checker
                    $scope.confirmCounter += 1

                    $scope.page2Submitted = true

                    // Set value before go to page4                
                    $scope.goToFourthPage = true

                    if ($scope.mainDriver.LastName == '-') {
                        $scope.mainDriver.LastName = '';
                    }

                    if (fn.validations.validateForms2()) {
                        DaModal.open('#thirdPageFormInvalid')
                        return
                    }

                    if (!fn.validations.isTHIDValidMD() && $scope.mainDriver.ResidentialStatusId == 2000000) {
                        fn.utils.clearLoading();
                        DaModal.open("#MDIDNotValid")
                        return
                    }

                    else if (!fn.validations.isTHIDValidND() && $scope.namedDriver.ResidentialStatusId == 2000000) {
                        fn.utils.clearLoading();
                        DaModal.open("#NDIDNotValid")
                        return
                    }

                    else if (!fn.validations.isTHIDValidPH() && $scope.policyHolder.ResidentialStatusId == 2000000) {
                        fn.utils.clearLoading();
                        DaModal.open("#PHIDNotValid")
                        return
                    }

                    if (!fn.eventHandlers.isAcceptOccu($scope.policyHolder.OccupationId)) {
                        fn.utils.clearLoading();
                        DaModal.open("#policyHolderOccuNotAccept")
                        return
                    }

                    $scope.loadingSpinner = true
                    fn.utils.setIsSaving(true)

                    var callbacks1 = {
                        onSuccess: function () {
                            for (var i = 0; i < $scope.coverages.Coverages.length; i++){
                                if($scope.coverages.Coverages[i].IsSelected == true){
                                    $scope.getFirstPaymentToShow = $scope.coverages.Coverages[i].PaymentSchedule[0].Amount
                                    $scope.getMonthlyPremiumToShow = $scope.coverages.Coverages[i].PaymentSchedule.length > 1 ? $scope.coverages.Coverages[i].PaymentSchedule[1].Amount : 0.00

                                    $sessionStorage.toSetFirstPaymentToShow = $scope.getFirstPaymentToShow
                                    $sessionStorage.toSetMonthlyPremiumToShow = $scope.getMonthlyPremiumToShow
                                }
                            }

                            fn.eventHandlers.postUWFC()
                            fn.saving.savePHContactToProceed(callbacks2)

                            // if MD == PH
                            if($scope.policyHolder.IsMainDriver === true){
                                $sessionStorage.MDPHSChecker = true
                            }

                            // if MD <> PH
                            else{
                                // to create the latest "MDProvinceID" 
                                $sessionStorage.MDPHSChecker = false
                                if($scope.currentProposal.Role.ProposalPersons && $scope.currentProposal.Role.ProposalPersons.length > 0) {
                                    $sessionStorage.getTheLatestMDProvinceIDSelected = $scope.currentProposal.Role.ProposalPersons[0].Value.MDProvinceID
                                }
                            }
                        }
                    }
                    var callbacks2 = {
                        onSuccess: function () {
                            fn.saving.savePHAddress(checkUserExist)
                        }
                    }
                    var checkUserExist = {
                        onSuccess: function () {
                            fn.saving.isUserExists({
                                onSuccess: function (cvrList) {
                                    processContact(cvrList)
                                },
                                onError: function (err) {
                                    if(!hasError) {
                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'onBtnToSummary', method: 'checkUserExist'} ;
                                        var errorLog = {err: JSON.stringify(error), case: err && err.ErrorInfo && err.ErrorInfo.StatusCode == 'VALIDATION_FAILED' ? 'Underwriting : line 8480' : 'error : line 8480'};
                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                    }        
                                    hasError = true;    

                                    fn.utils.onUserMismatchHandler(err)
                                    $scope.cvrList = cvrList
                                    DaModal.open('#userMismatchValidation')
                                    hasError = true
                                }
                            })
                        }
                    }
                    var processContact = function (cvrList) {
                        hasError = false;
                        fn.policyContactApi.processPolicyContact({
                            onSuccess: function () {
                                fn.saving.calculatePremium({
                                    onSuccess: function(data){
                                        if(data) {
                                            fn.saving.saveOtherDetailsPartSave({
                                                onSuccess: function () {                                                
                                                        
                                                    // get "MainDriverPolicyholder"
                                                    // $scope.getMainDriverPolicyholder = $scope.$parent.filterStatic($scope.staticData.booleans,$scope.policyHolder.IsMainDriver)
                                                    let getMainDriverPolicyholder = $scope.staticData.booleans.find(w => w.key == $scope.policyHolder.IsMainDriver);    
                                                    if(getMainDriverPolicyholder){
                                                        $scope.getMainDriverPolicyholder = getMainDriverPolicyholder.value;                                
                                                    }                            

                                                    // get "CarPlateProvinceName"
                                                    var getCarPlateProvinceID = $scope.asset.LicenseCardProvinceId;
                                                    $scope.policyHolder.CarPlateProvinceID = getCarPlateProvinceID;
                                                    $scope.getCarPlateProvinceName = ''
    
                                                    if( (getCarPlateProvinceID != "")||
                                                        (getCarPlateProvinceID != "undefined")||
                                                        (getCarPlateProvinceID != null)){
                                                            
                                                        if((getCarPlateProvinceID == '2000080') || (getCarPlateProvinceID == 2000080)) {
                                                            $scope.getCarPlateProvinceName = 'NA'
                                                        } 
                                                        else {
                                                            // $scope.getCarPlateProvinceName = $scope.$parent.filterStatic($scope.staticData.provinces,getCarPlateProvinceID)  
                                                            let getCarPlateProvinceName = $scope.staticData.provinces.find(w => w.key == getCarPlateProvinceID);   
                                                            if(getCarPlateProvinceName){
                                                                $scope.getCarPlateProvinceName = getCarPlateProvinceName.value;                               
                                                            }
                           
                                                        }
                                                    }
                                                    else{
                                                        $scope.getCarPlateProvinceName = '-'
                                                    }
    
                                                    // get "District"
                                                    // $scope.getDistrict = $scope.$parent.filterStatic($scope.staticData.districtByPostal,$scope.phAddress.DistrictId)
                                                    let getDistrict = $scope.staticData.districtByPostal.find(w => w.key == $scope.phAddress.DistrictId);
                                                    if(getDistrict){
                                                        $scope.getDistrict = getDistrict.value;
                                                    }

                                                    // get "Province"
                                                    // $scope.getProvince = $scope.$parent.filterStatic($scope.staticData.provincesByPostal,$scope.phAddress.ProvinceId)
                                                    let getProvince = $scope.staticData.provincesByPostal.find(w => w.key == $scope.phAddress.ProvinceId);
                                                    if(getProvince){
                                                        $scope.getProvince = getProvince.value;
                                                    }
                                                                                                    
                                                    // get "OtherDriver"
                                                    $scope.getOtherDrivers = ''
                                                    if($scope.plan.coversNamed == 0){ $scope.getOtherDrivers = 'No' }
                                                    if($scope.plan.coversNamed == 1){ $scope.getOtherDrivers = 'Yes' }
                                                    //
                                                    // new value sent to save to MAXAR (END)
                                                    //
    
                                                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(4)
                                                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(5)

                                                    // $state.go("publicB2C.motor.quote.steps.confirm")
                                                    let ExternalProposalNumberArr = $scope.currentProposal.ExternalProposalNumber.split('/');
                                                    if (ExternalProposalNumberArr[0] == 'MT') {
                                                        $state.go("publicB2C.motor.quote.steps.confirm")
                                                    } else {
                                                        $state.go("publicB2C.motor.quote.steps.mticonfirm")
                                                    }
    
                                                }
                                            })    
                                        }
                                    },
                                    onError: function(err){
                                        if(!hasError) {
                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'processContact', method: 'fn.saving.calculatePremium'} ;
                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 8561'};
                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                        }        
                                        hasError = true;    

                                        fn.saving.saveOtherDetailsPartSave({
                                            onSuccess: function () {
                                                $scope.loadingSpinner = false
                                                fn.utils.setIsSaving(false)
                                            }
                                        })
                                    }
                                })
                            },
                            onError: function (err) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'processContact', method: 'fn.saving.calculatePremium'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'error : line 8578'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true
                            }
                        }, cvrList)
                    }
                    // fn.saving.saveCoveragesModel(callbacks1)
                    fn.updatedCurrentProposal(callbacks1)
                    // if MD == PH
                    if($scope.policyHolder.IsMainDriver === true){
                        // DataServices/PolicyContact/Update page3 > page4
                        $scope.policyHolder.MDProvinceID = $scope.phAddress.ProvinceId
                    }

                    // if MD <> PH
                    else{
                        $scope.policyHolder.MDProvinceID = "0"
                    }
                },
                onBtnConvertPolicyCancel: function () {
                    DaModal.close("#convertPolicyModal");
                },
                
                isAcceptOccu: function (val) {
                    return true;
                },
                addNamedDriver: function () {
                    $scope.dynamicForm.addedNamedDriver = true;
                },
                removeNamedDriver: function () {
                    $scope.dynamicForm.addedNamedDriver = false;
                    $scope.namedDriver = {};
                },
                isPHMDSync: function () {
                    if ($scope.policyHolder.IsMainDriver) {
                        fn.utils.copyPHFieldsToMD();
                    }
                },
                isMDPHSync: function () {
                    if ($scope.policyHolder.IsMainDriver) {
                        fn.utils.copyMDFieldsToPH();
                    }
                },
                needRecalculate: function () {
                    return false;
                },
                onBtnNDAddClicked: function () {
                    $scope.isNDModalOpen = true;
                },
                onBtnNDSaveClicked: function (frmAboutNamedDriver) {

                    $scope.modalSubmitted = true;
                    $scope.dynamicForm.addedNamedDriver = true;
                    var isInvalid = false;
                    if (fn.validations.validateNDForms(frmAboutNamedDriver)) {
                        isInvalid = true;
                    }

                    if (!fn.validations.isTHIDValidND() && $scope.isNDModalOpen && $scope.namedDriver.ResidentialStatusId == 2000000) {
                        fn.gtAnchor('#IdentifierCardND');
                        isInvalid = true;
                    }

                    if ($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2") {
                        isInvalid = true;
                    }

                    if (isInvalid) {
                        return;
                    }

                    $scope.namedDriver.ConsentId = 7000002;  // default as follow up

                    $scope.modalSubmitted = false;
                    var callbacks = {
                        onSuccess: function () {
                            $scope.isEditing = false;
                            $scope.isNDModalOpen = false;
                            $scope.namedDriver = {};
                            $scope.modalSubmitted = false;
                            marketing.dLCE(61,true);

                        }
                    };

                    fn.saving.saveSingleNamedDriverModel(callbacks);
                    window.location.href = window.location.href + "#named_driver";
                },
                onBtnNamedDriverModalUpdateClicked: function (nd) {
                    $scope.namedDriver = nd;
                    fn.eventHandlers.onBtnNDAddClicked();
                },

                onBtnNamedDriverModalRemoveClicked: function (nd, $event) {
                    $event.stopPropagation();
                    $scope.showRemoveWarning = true;
                    $scope.choosenND = nd;
                },

                onBtnNamedDriverModalRemoveConfirm: function onBtnNamedDriverModalRemoveConfirm() {
                    var e;
                    $scope.showRemoveWarning = false;
                    $scope.namedDriver = $scope.choosenND;

                    $scope.namedDriver.isForDelete = true;

                    var callbacks = {
                        onSuccess: function () {

                            $scope.namedDriver = {};
                            $scope.showRemoveWarning = false;

                        }
                    };

                    fn.saving.saveSingleNamedDriverModel(callbacks);

                    $scope.choosenND = {};

                },

                onBtnNDCancelClicked: function (frmAboutNamedDriver) {

                    $scope.modalSubmitted = false;
                    $scope.isNDModalOpen = false;
                    $scope.namedDriver = {};

                    /** VPP: Add this condition to replace VPP by Flex if ND age is less than 30 */
                    var age = fn.utils.getAge(frmAboutNamedDriver.namedDriverDOBYear.$viewValue + "-" + frmAboutNamedDriver.namedDriverDOBMON.$viewValue + "-" + frmAboutNamedDriver.namedDriverDOBDD.$viewValue);
                    if (age < 30) {
                        //$scope.addNamedDriver = false;
                        $scope.asset.PlanId = $scope.PLANID_ANY_DRIVER;
                        $scope.plan.coversNamedWho = 1;
                        $scope.addNamedDriverAgeLessThan30_2nd = true;
                        $scope.addNamedDriverAgeLessThan30 = false;
                        $scope.isEditing = false;
                        return;
                    } else {
                        $scope.addNamedDriverAgeLessThan30_2nd = false;
                    }
                    /** VPP: Add this condition to replace VPP by Flex if ND age is less than 30 */
                },

                onBtnNamedDriverModalRemoveCancelled: function onBtnNamedDriverModalRemoveCancelled() {
                    $scope.choosenND = {};
                    $scope.showRemoveWarning = false;
                },
                getPriority(callback) {
                    hasError = false;
                    var obj = {};
                    $window.ppstLayer = $window.ppstLayer || $localStorage.ppstLayer;
                    var ppstLayer = $window.ppstLayer;

                    for (var i = 0; i <= ppstLayer.length; i++) {
                        angular.extend(obj, ppstLayer[i]);
                    }
    
                    obj.mitBaseInitialParam = {
                        "MITSessionToken": $scope.sessionApi.pAndCModelStorage.session.MITSessionToken
                    };
    
                    var requestString = JSON.stringify(obj);
                    //New change to ExternalServices (10/11/2022 BY PU)
                    ExternalService.getPriority({
                        onSuccess: function (data, status) {
                            if (data.ErrorID === 0) {
                                //For Testing
                                //console.log("Hardcoding Priority to 20")
                                //callback("20"); 
                                callback(data.Data.LeadPriority); 
                            } else {
                                callback(0); 
                            }
                        },
                        onError: function (err, status) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'getPriority', method: 'ExternalService.getPriority'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'Priority : line 8754'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true

                        }
                    }, requestString);

                },
                postMaxar: function postMaxar(){
                    var hasError = false;
                    var obj = {};
                    $window.maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;
                    var maxarLayer = $window.maxarLayer;
                    for (var i = 0; i <= maxarLayer.length; i++) {
                        angular.extend(obj, maxarLayer[i]);
                    }    
    
                    if (!$sessionStorage.maxarReference) {  /* ref is blank */
                        if (obj.referenceID) {
                            $scope.maxarReference = obj.referenceID;
                            $sessionStorage.maxarReference = obj.referenceID;
                        }
                    } 
                    else {  /* maxar ref exist */
                        if (!obj.referenceID) {
                            obj.referenceID = $sessionStorage.maxarReference || '-';
                        }
                    }

                    if (obj.referenceID.substr(0, 2) === '66') {
                        obj.referenceID = '0' + obj.referenceID.substr(2);
                    }
    
                    if(obj.priority == 0){
                        return;
                    }
    
                    if(obj.priority == 99 ){
                        obj.priority = 17;
                    }
    
                    if (!obj['endDate']) {
                        obj['endDate'] = "01-01-2017 00:00";
                    }

                    //3.P22 -> For check quoteNumber emtry
                    // if(!obj?.quoteNumber){
                    //     $scope.mtNumber = true;
                    //     console.log('P22 -> Check quoteNumber = ' + obj?.quoteNumber);
                    // }  
                    if($scope.mtNumber){
                        obj.quoteNumber = $scope.currentProposal.ExternalProposalNumber;
                    }                
    
                    var requestString = JSON.stringify(obj)
                    $scope.retData = {};
    
                    // var confighead = {
                    //     headers: {
                    //         'Content-Type': '"application/json; charset=utf-8";',
                    //         'dataType': '"json"'
                    //     }
                    // }
    
                    if (!config.dontSendMaxar) {
                        ExternalService.relaySend( {
                            onSuccess: function (data, status) {
                                $scope.retData.result = data;
                            },
                            onError: function (err, status) {
                                if(!hasError) {
                                    var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'postMaxar', method: 'ExternalService.relaySend'} ;
                                    var errorLog = {err: JSON.stringify(error), case: 'postMaxar : line 8827'};
                                    let priority = $localStorage.priority ? $localStorage.priority : 22;
                                    fn.postToMaxParent('No', priority, 'N', errorLog)
                                }        
                                hasError = true

                                $scope.status = status;
                            }
                        }, requestString);
                        
                    }
                },

                getPriorityByAction: function(){

                    // Dynamic propensity function trigger here.
                    var defaultPriorityJudge = function () {
                            if ($scope.thirdPageShow) {
                                fn.postToMaxParent('No', 3, 'N', '');
                                fn.postToAWS(3);
                            } 
                            else {
                                fn.postToMaxParent('No', 5, 'N', '');
                                fn.postToAWS(5);
                            }

                    }

                    // "Type1 & Type1Save" to POST to Maxar with "Propensity Priority"
                    if(($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_FALCON_DG)||
                        ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1SAVE_FALCON_DG) ||
                        ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_G) ||
                        ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_D) ||
                        ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_G) ||
                        ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_D) 
                        ){

                        $sessionStorage.noOfPTSave = $sessionStorage.noOfPTSave + 1;
                        
                        fn.getPriority(function (priority) {
                            if (priority != 0 ){
                                if (
                                    $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_G ||
                                    $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_D ||
                                    $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_G ||
                                    $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_D
                                ) {
                                    const computedPriority = fn.utils.computeAggregatorPriority(priority, $scope.currentProposal, $scope.staticData);
                                    fn.postToMaxParent('No', computedPriority, 'N', '');
                                    fn.postToAWS(computedPriority);
                                } else {
                                    fn.postToMaxParent('No', priority, 'N', '');
                                    fn.postToAWS(priority);
                                }
                            } 
                            else {
                                defaultPriorityJudge();
                            }

                            $scope.isCalculateAction = false;  
                        });
                    }

                    // "Type2+ to POST to Maxar with "Propensity Priority"
                    else if($scope.selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG){
                        if($localStorage.priority == 20){
                            fn.postToMaxParent('No', 26, 'N', '');
                            fn.postToAWS(26);
                            $scope.isCalculateAction = false;
                        }
                        else if($localStorage.priority == 23){
                            fn.postToMaxParent('No', 27, 'N', '');
                            fn.postToAWS(27);
                            $scope.isCalculateAction = false;
                        }
                        else if($localStorage.priority == 24){
                            fn.postToMaxParent('No', 28, 'N', '');
                            fn.postToAWS(28);
                            $scope.isCalculateAction = false;
                        }
                        else if($localStorage.priority == 25){
                            fn.postToMaxParent('No', 29, 'N', '');
                            fn.postToAWS(29);
                            $scope.isCalculateAction = false;
                        }
                        else{
                            fn.getPriority(function (priority) {
                                if(priority == 20){
                                    fn.postToMaxParent('No', 26, 'N', '');
                                    fn.postToAWS(26);
                                }
                                else if(priority == 23){
                                    fn.postToMaxParent('No', 27, 'N', '');
                                    fn.postToAWS(27);
                                }
                                else if(priority == 24){
                                    fn.postToMaxParent('No', 28, 'N', '');
                                    fn.postToAWS(28);
                                }
                                else if(priority == 25){
                                    fn.postToMaxParent('No', 29, 'N', '');
                                    fn.postToAWS(29);
                                }
                                else{
                                    fn.postToMaxParent('No', priority ? priority : 4, 'N', '');   
                                    fn.postToAWS(priority ? priority : 4);
                                }
                                $scope.isCalculateAction = false;
                            });
                        }

                                                
                    }

                    // Type2, Type3
                    else if (
                        $scope.selectedCoverage.CoverTypeId == $scope.TYPE2_FALCON_DG ||
                        $scope.selectedCoverage.CoverTypeId == $scope.TYPE3_FALCON_DG
                    ) {
                        if ($scope.firstPageShow || $scope.secondPageShow) {
                            fn.postToMaxParent('No', 15, 'N', '')
                            fn.postToAWS(15);
                        }
                        if ($scope.thirdPageShow && $scope.goToFourthPage) {
                            fn.postToMaxParent('No', 1, 'N', '')
                            fn.postToAWS(1);
                        }
                    }

                    // "Type3+ to POST to Maxar with "Propensity Priority"
                    else{
                        fn.getPriority(function (priority) {})
                        defaultPriorityJudge();
                    }
                }

            },// fn.eventHandler end
            getTranslateText: function (part) {
                if (HKLocale.getLocale() == 'th-th') {
                    switch (part) {
                        case "maker":
                            return "เลือกยี่ห้อรถ";
                            break;
                        case "model":
                            return "เลือกรุ่นรถ";
                            break;
                        case "year":
                            return "เลือกรุ่นปีรถยนต์";
                            break;
                        case "desc":
                            return "เลือกรุ่นย่อย";
                            break;
                        case "province":
                            return "เลือกจังหวัดที่รถจดทะเบียน";
                            break;
                        case "policyStartDateDD":
                            return "วัน";
                            break;
                        case "policyStartDateMON":
                            return "เดือน";
                            break;
                        case "policyStartDateYear":
                            return "ปี";
                            break;
                        case "mainDriverDOBDD":
                            return "วัน";
                            break;
                        case "mainDriverDOBMON":
                            return "เดือน";
                            break;
                        case "mainDriverDOBYear":
                            return "ปี";
                            break;
                        }

                } else {
                    switch (part) {
                        case "maker":
                            return "Please select";
                            break;
                        case "model":
                            return "Please select";
                            break;
                        case "year":
                            return "Please select";
                            break;
                        case "desc":
                            return "Please select";
                            break;
                        case "province":
                            return "Please select";
                            break;
                        case "policyStartDateDD":
                            return "Day";
                            break;
                        case "policyStartDateMON":
                            return "Month";
                            break;
                        case "policyStartDateYear":
                            return "Year";
                            break;   
                        case "mainDriverDOBDD":
                            return "Day";
                            break;
                        case "mainDriverDOBMON":
                            return "Month";
                            break;
                        case "mainDriverDOBYear":
                            return "Year";
                            break;             
                        }
                }
            },
              //updated CurrentProposal
            updatedCurrentProposal: function (callbacks,isCMI,params) {
                hasError = false;
                if($scope.currentProposal && $scope.currentProposal.Role && $scope.currentProposal.Role.ProposalPersons && $scope.currentProposal.Role.ProposalPersons.length > 0) {
                    if($scope.currentProposal.Role.ProposalPersons[0] && $scope.currentProposal.Role.ProposalPersons[0].Value) {   
                        if(!$scope.currentProposal.Role.ProposalPersons[0].Value.FirstName){
                            $scope.currentProposal.Role.ProposalPersons[0].Value.FirstName = $scope.firstName;
                            // $scope.currentProposal.Role.ProposalPersons[0].Value.PreferredName = $scope.firstName;
                        }     
                        if($scope.currentProposal.Role.ProposalPersons[0].Value.LastName == '-'){
                            $scope.currentProposal.Role.ProposalPersons[0].Value.LastName = $scope.mainDriver.LastName;
                            // $scope.currentProposal.Role.ProposalPersons[0].Value.PreferredName = $scope.firstName;
                        }     
                    }
                }
                var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;

                fn.currentProposalApi[saveCurrentProposalFunc]({
                        onSuccess: function () {    
                            if(callbacks){      
                                fn.updateCoveragesCallbacks(callbacks,isCMI,params);      
                                // callbacks.onSuccess();                         
                            } else {
                                fn.updateCoverages();                               
                            }
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updatedCurrentProposal', method: 'fn.currentProposalApi'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 8963'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true
                            $scope.hasError = true;
                        }
                    }
                );
            },

            calculateTotalAmout: function () {
                
                if ((!$scope.coverages) || !$scope.coverages.Coverages) {
                    return 0;
                }

                var coverage = $scope.coverages.Coverages.filter(w => w.IsSelected == true);
                if(coverage && coverage.length > 0) {
                    coverage = coverage[0]
                }

                if (!coverage) {
                    return 0;
                }

                var x = fn.utils.getPriceToShow(coverage);
                return (x);
            },

            getTotalAmout: function () {
                $scope.totalPremium2 = fn.calculateTotalAmout();
                return $scope.totalPremium2;
            },
            checkAllCarInfoCompleted: function () {
                if (($scope.asset.ManufactureId) &&
                    ($scope.asset.ModelId) &&
                    ($scope.asset.PurchasingYear) &&
                    ($scope.asset.CarDescriptionId)) {
                    return true;
                } else {
                    return false;
                }
            },
            checkAllMainDriverCompleted: function () {
                if (
                    ($scope.mainDriver.DOB_DD && $scope.mainDriver.DOB_MON && $scope.mainDriver.DOB_Year)
                    && ($scope.mainDriver.GenderId) && ($scope.mainDriver.MaritalStatusId) &&
                    (($scope.asset.InDriveWork == false) || (($scope.asset.InDriveWork == true) && ($scope.asset.InCourseWork !== undefined)))
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            checkAllContactInfoCompleted: function () {
                if ($scope.phContact.PrimaryPhoneNumber && $scope.policyHolder.FirstName && $scope.phContact.PrimaryEmailAddress) {
                    $scope.contactInfoComplete = true;
                } else {
                    $scope.contactInfoComplete = false;
                }
            },

            nextToMainDriver: function () {

                if (fn.checkAllCarInfoCompleted()) {
                    $scope.showMainDriverFlag = true;                    
                    $('#yourCar').collapse('hide');
                    $('#maindriver').collapse('show');
                }
            },
            nextToContactInfo: function () {
                if (fn.checkAllMainDriverCompleted() && fn.checkAllCarInfoCompleted()) {
                    $scope.showContactInfoFlag = true;
                    $('#maindriver').collapse('hide');
                    $('#contactInfoBody').collapse('show');
                }
            },
            assetStaticData: {
                make: [],
                model: [],
                year: [],
                description: []
            },
            disablePremium: function () {
                return "enabled";
            },
            goToPage: function (page) {
                window.scrollTo(0, 0);
                if (page == 1) {
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = true;
                    $scope.secondPageShow = false;
                    $scope.thirdPageShow = false;
                    $scope.fourthPageShow = false;
                    window.location.hash = "#start";
                    $sessionStorage.currentPage = 1;
                    $rootScope.$broadcast('SetSeoCampaign', $sessionStorage.seoCampaign);
                    setTimeout(() => fn.handleRightPanelPosition(document.documentElement.scrollTop || 0));
                } 
                else if (page == 2) {
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = true;
                    $scope.secondPageShow = true;
                    $scope.thirdPageShow = false;
                    $scope.fourthPageShow = false;
                    $scope.isSaveAndExitShowName = true;
                    window.location.hash = "#your-quote";
                    $sessionStorage.currentPage = 2;
                    $rootScope.$broadcast('SetSeoCampaign', $sessionStorage.seoCampaign);
                    setTimeout(() => fn.handleRightPanelPosition(document.documentElement.scrollTop || 0));
                } 
                else if (page == 3) {
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = false;
                    $scope.secondPageShow = false;
                    $scope.thirdPageShow = true;
                    $scope.fourthPageShow = false;
                    $scope.isSaveAndExitShowName = false;
                    window.location.hash = "#policy-submission";
                    $sessionStorage.currentPage = 3;
                    $scope.recalculate.isNeeded = false;
                    $rootScope.$broadcast('SetSeoCampaign');
                    setTimeout(() => fn.handleRightPanelPosition(document.documentElement.scrollTop || 0));
                } 
                else if (page == 4) {
                    let externalProposalNumberArr = 'MT';
                    let getSecondObjToMX = $sessionStorage.getSecondObjToMX;
                    if(getSecondObjToMX && getSecondObjToMX.quoteNumber){
                        if (getSecondObjToMX.quoteNumber != 'MT') {
                            let getExternalProposalNumberArr = getSecondObjToMX.quoteNumber.split('/');
                            if (getExternalProposalNumberArr[0] != 'MT') {
                                externalProposalNumberArr = getExternalProposalNumberArr[0];
                            }
                        }
                    }
                    if(externalProposalNumberArr == 'MT') {
                        $state.go('publicB2C.motor.quote.steps.confirm')
                    } else {
                        $state.go('publicB2C.motor.quote.steps.mticonfirm')
                    }

                    //prevent redirecting to cybersource until the external issue is fixed.
                    //$state.go('publicB2C.motor.quote.steps.mticonfirm')
                } 
                else {  // default page 1
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = true;
                    $scope.secondPageShow = false;
                    $scope.thirdPageShow = false;
                    window.location.hash = "#start";
                    $sessionStorage.currentPage = 1;
                }
            },
            setPreviousPage: function (val) {
                $scope.previousPage = val;
            },
            refreshCurrentPage: function (page) {
                fn.goToPage(page);
            },
            updateAssetMaxarPriority: function (callbacks,isCMI, param) {
                var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');
                fn.assetApi[saveAssetFunc]({
                    onSuccess: function (data) {
                        if(callbacks){
                            if(!isCMI) {
                                if($scope.loadingSpinner3){
                                    $scope.loadingSpinner = false;
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    $scope.allowBtnAction = true; 
                                }        
                            }
                            callbacks.onSuccess(isCMI, param);
                        }
                    },
                    onError: function (err) {
                        if(!hasError) {
                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updateCoverages', method: 'updateCoverages'} ;
                            var errorLog = {err: JSON.stringify(error), case: 'error : line 9123'};
                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                            fn.postToMaxParent('No', priority, 'N', errorLog)
                        }        
                        hasError = true;          

                        $scope.loadingSpinner = false;
                            $scope.loadingSpinner2 = false;
                            $scope.loadingSpinner3 = false;
                            $scope.$parent.showPriceOnTopFlag = true;
                            $scope.allowBtnAction = true;        
                    }
                });
            },
            updateCoveragesCallbacks: function (callbacks,isCMI,params) {
                hasError = false;
                // fn.coverageApi['updateCoverages'](callbacks);    
                fn.coverageApi['updateCoverages']({
                    onSuccess: function (data) {
                        fn.updateAssetMaxarPriority(callbacks,isCMI,params);
                    },
                    onError: function (err) {
                        if(!hasError) {
                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updateCoverages', method: 'updateCoverages'} ;
                            var errorLog = {err: JSON.stringify(error), case: 'error : line 9146'};
                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                            fn.postToMaxParent('No', priority, 'N', errorLog)
                        }        
                        hasError = true;          

                        $scope.loadingSpinner = false;
                            $scope.loadingSpinner2 = false;
                            $scope.loadingSpinner3 = false;
                            $scope.$parent.showPriceOnTopFlag = true;
                            $scope.allowBtnAction = true;        
                    }
                });
            },
            updateCoverages: function () {
                hasError = false;

                fn.coverageApi['updateCoverages']({
                    onSuccess: function (data) {
                        fn.updateAssetMaxarPriority();
                        if($scope.loadingSpinner3){
                            $scope.loadingSpinner = false;
                            $scope.loadingSpinner2 = false;
                            $scope.loadingSpinner3 = false;
                            $scope.$parent.showPriceOnTopFlag = true;
                            $scope.allowBtnAction = true; 
                        }
                    },
                    onError: function (err) {
                        if(!hasError) {
                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updateCoverages', method: 'updateCoverages'} ;
                            var errorLog = {err: JSON.stringify(error), case: 'error : line 9176'};
                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                            fn.postToMaxParent('No', priority, 'N', errorLog)
                        }        
                        hasError = true;          

                        $scope.loadingSpinner = false;
                            $scope.loadingSpinner2 = false;
                            $scope.loadingSpinner3 = false;
                            $scope.$parent.showPriceOnTopFlag = true;
                            $scope.allowBtnAction = true;        
                    }
                });
    
            },

            updatedCurrentProposalModelToUpdateNewProduct: function () {
                hasError = false;
                if($scope.currentProposal && $scope.currentProposal.Role && $scope.currentProposal.Role.ProposalPersons && $scope.currentProposal.Role.ProposalPersons.length > 0) {
                    if($scope.currentProposal.Role.ProposalPersons[0] && $scope.currentProposal.Role.ProposalPersons[0].Value) {   
                        if(!$scope.currentProposal.Role.ProposalPersons[0].Value.FirstName){
                            $scope.currentProposal.Role.ProposalPersons[0].Value.FirstName = $scope.firstName;
                        }     
                    }
                }
                var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                fn.currentProposalApi[saveCurrentProposalFunc]({
                        onSuccess: function (data) {     
                            if(data) {    
                            }
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updatedCurrentProposal', method: 'fn.currentProposalApi'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 9210'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true
                            $scope.hasError = true;
                        }
                    }
                );
            },
            updatedCurrentProposalModelToUpdateNewProductSwitching: function () {
                hasError = false;
                if($scope.currentProposal && $scope.currentProposal.Role && $scope.currentProposal.Role.ProposalPersons && $scope.currentProposal.Role.ProposalPersons.length > 0) {
                    if($scope.currentProposal.Role.ProposalPersons[0] && $scope.currentProposal.Role.ProposalPersons[0].Value) {   
                        if(!$scope.currentProposal.Role.ProposalPersons[0].Value.FirstName){
                            $scope.currentProposal.Role.ProposalPersons[0].Value.FirstName = $scope.firstName;
                        }     
                    }
                }
                var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                fn.currentProposalApi[saveCurrentProposalFunc]({
                        onSuccess: function (data) {     
                            if(data) {
                                fn.partialSaveSwitchNewProduct();
    
                                if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG ||
                                    $scope.selectedCoverage.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG) {
                                    fn.quoteApi.getInsuranceAmount({
                                        onSuccess: function (data) {
                                            if (data && data.length > 0) {
                                                fn.utils.sortInsuranceAmount(data);
                                                $scope.customInsuranceAmount = data;
                                                fn.setDefaultInsureAmount();
                                            }
                                        },
                                        onError: function (err) {
                                            if(!hasError) {
                                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'scope.watch.totalPremium2', method: 'updateAssetMaxarPriority'} ;
                                                var errorLog = {err: JSON.stringify(error), case: 'error : line 9247'};
                                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                fn.postToMaxParent('No', priority, 'N', errorLog)
                                            }
                                            hasError = true;
    
                                        }
                                    });
                                }
                                $scope.loadingSpinner3 = false;
    
                            }
                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'updatedCurrentProposal', method: 'fn.currentProposalApi'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 9262'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true
                            $scope.hasError = true;
                        }
                    }
                );
            },
            partialSaveSwitchNewProduct: function () {
                fn.essentialApi.partialSave({
                    onSuccess: function (data) {
                        fn.utils.setIsSaving(false);
                        if(data && data.QuoteNumber) {
                            $scope.currentProposal.ExternalProposalNumber = data.QuoteNumber;
                            fn.eventHandlers.getPriorityByAction();
                        }
                    },
                    onError: function (err) {
                        if(!hasError) {
                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'partialSave', method: 'partialSave'} ;
                            var errorLog = {err: JSON.stringify(error), case: 'error : line 9283'};
                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                            fn.postToMaxParent('No', priority, 'N', errorLog)
                        }        
                        hasError = true;
    
                        fn.utils.setIsSaving(false);
                    }
                });
    
            },
            retrieveCurrentProposal: function () {
                hasError = false;
                fn.currentProposalApi.retrieveCurrentProposal({
                    onSuccess: function (data) {
                        angular.extend($scope.currentProposal, data);
                        $scope.allowBtnAction = true;
                        try {
                            if (!$scope.currentProposal.IsRenewalProposal) {
                                $scope.bi.whereDidYouHereUs = 'None';
                                angular.extend($scope.bidata, {
                                    //Source: $scope.$parent.bi.whereDidYouHereUs // Use GA source instead
                                    Source: "B2C"
                                });
                                fn.logApi.writeLog({
                                    onSuccess: function () {},
                                    onError: function (err) {
                                        if(!hasError) {
                                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveCurrentProposal', method: 'writeLog'} ;
                                            var errorLog = {err: JSON.stringify(error), case: 'error : line 9311'};
                                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                                            fn.postToMaxParent('No', priority, 'N', errorLog)
                                        }        
                                        hasError = true;            
                                    }
                                });
                            }
                        }
                        catch (err) {
                            if(!hasError) {
                                var error = err ? err : {fn: 'retrieveCurrentProposal', method: 'angular.extend'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 9322'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true;    
                        }
                    },
                    onError: function (err) {
                        if(!hasError) {
                            var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveCurrentProposal', method: 'angular.extend'} ;
                            var errorLog = {err: JSON.stringify(error), case: 'error : line 9332'};
                            let priority = $localStorage.priority ? $localStorage.priority : 22;
                            fn.postToMaxParent('No', priority, 'N', errorLog)
                        }        
                        hasError = true;
                        retrieveAsset();
                    }
                });
            },
            selectIAmount: function (iKey, iAmount,isSumInsure) {
                var hasError = false;
                // Call custome function
                // To check contact customer details before sent to MAXAR
                fn.eventHandlers.onCustomerLeadDetailsChecker()

                // Face missing fields before send to MAXAR
                if($scope.warningCounter > 0){
                    // maintain the old state of button
                    fn.eventHandlers.onMaxarMissingLeadFieldsChecker()
                } else {               
                    $scope.selectedInsureAmount = iKey;
                    $scope.selectedInsureAmountValue = iAmount;
                    $scope.coverages.Coverages.forEach(function (cc, xx) {
                        if ((cc.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG || cc.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG) && cc.IsSelected == true) {
                            $scope.coverages.Coverages[xx].Covers.forEach(function (plo, yy) {
                                if (plo.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA) {

                                    $scope.coverages.Coverages[xx].Covers[yy].InsuranceAmountLevelId = iKey;
                                    $scope.coverages.Coverages[xx].Covers[yy].InsuranceAmount = iAmount;

                                    if($scope.asset && $scope.asset.Coverages && $scope.asset.Coverages.length > 0){
                                        var coveragesIndex = $scope.asset.Coverages.findIndex(w => w.CoverTypeId == $scope.coverages.Coverages[xx].CoverTypeId);
                                        if(coveragesIndex != -1){
                                            var coversIndex = $scope.asset.Coverages[coveragesIndex].Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA);
                                            if(coversIndex != -1){
                                                $scope.asset.Coverages[coveragesIndex].Covers[coversIndex].InsuranceAmountLevelId = iKey;
                                                $scope.asset.Coverages[coveragesIndex].Covers[coversIndex].InsuranceAmount = iAmount;        
                                            }
                                        }    
                                    }

                                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0) {
                                        if($scope.currentProposal.Assets[0].Coverages && $scope.currentProposal.Assets[0].Coverages.length > 0) {
                                            var coveragesIndex = $scope.currentProposal.Assets[0].Coverages.findIndex(w => w.CoverTypeId == $scope.coverages.Coverages[xx].CoverTypeId);
                                            if(coveragesIndex != -1){
                                                var coversIndex = $scope.currentProposal.Assets[0].Coverages[coveragesIndex].Covers.findIndex(w => w.ProductLineGroupId == $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA);
                                                if(coversIndex != -1){
                                                    $scope.currentProposal.Assets[0].Coverages[coveragesIndex].Covers[coversIndex].InsuranceAmountLevelId = iKey;
                                                    $scope.currentProposal.Assets[0].Coverages[coveragesIndex].Covers[coversIndex].InsuranceAmount = iAmount;        
                                                }            
                                            }
                                        }
                                    }
            

                                    fn.saving.saveCoveragesModel({
                                        onSuccess: function () {
                                            $scope.loadingSpinner = true;
                                            $scope.loadingSpinner2 = true;
                                            $scope.loadingSpinner3 = true;

                                            fn.quoteApi.calculate({
                                                onSuccess: function (data) {
                                                    if(data && data.Coverages && isSumInsure) {
                                                        $scope.coverages.Coverages = data.Coverages;
                                                    }
                                                    fn.updatesCoveragesSPA($scope.compulsoryChecker,isSumInsure,"No");
                                                },
                                                onError: function (err) {
                                                    if(!hasError) {
                                                        var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'selectIAmount', method: 'fn.saving.saveCoveragesModel'} ;
                                                        var errorLog = {err: JSON.stringify(error), case: 'error : line 9398'};
                                                        let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                        fn.postToMaxParent('No', priority, 'N', errorLog)
                                                    }        
                                                    hasError = true
                                                }
                                            });
                                        },
                                        onError: function (err) {
                                            if(!hasError) {
                                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'selectIAmount', method: 'fn.saving.saveCoveragesModel'} ;
                                                var errorLog = {err: JSON.stringify(error), case: 'error : line 9408'};
                                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                                fn.postToMaxParent('No', priority, 'N', errorLog)
                                            }        
                                            hasError = true
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            },

            setDefaultInsureAmount: function () {
                var sc = fn.utils.getSelectedCoverage();
                if (sc.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG || sc.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG) {
                    var plo = fn.utils.getCoverOwnDamageByProductId(sc);

                    if (plo && plo.length > 0) {
                        $scope.selectedInsureAmount = plo[0].InsuranceAmountLevelId;
                    }
                }
            },
            setBodyType: function (val) {
                
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.bodyType = val;
                $scope.gearType = '';

                if ($scope.bodyType === '2doors') {
                    $scope.twoDoorsChecker = true
                }
                if ($scope.bodyType === '4doors') {
                    $scope.twoDoorsChecker = false
                }
            },
            setGearType: function (val) {
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.gearType = val;
                $scope.txtGearType = val == 'Man' ? 'Manual' : 'Auto';
            },
            isPopularTruck: function () {

                var popularTruckModel = [ 
                    'Colorado',
                    'Ranger',
                    'D-Max',
                    'BT-50',
                    'BT-50 PRO',
                    'Fighter',
                    'Extender',
                    'Strada',
                    'Triton',
                    'Frontier',
                    'Frontier Navara',
                    'Navara',
                    'NP 300 Navara',
                    'Hilux Revo',
                    'Hilux Tiger',
                    'Hilux Vigo'
                ];

                if (popularTruckModel.indexOf($scope.txtModel) < 0) {

                    $scope.twoDoorsCheckerState = false
                    return false
                } 
                else {


                    $scope.twoDoorsCheckerState = true
                    return true
                }
            },

            setSumInsured: function () {
                $scope.sumInsured.value = 0;
                $scope.sumInsured.visible = false;

                if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE3_FALCON_DG) {
                    //Type 3 is always NA and hidden
                    $scope.sumInsured.visible = false;
                    $scope.sumInsured.value = 0;
                    return;
                }
                if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE3PLUS_FALCON_DG || 
                    $scope.selectedCoverage.CoverTypeId == $scope.TYPE2PLUS_FALCON_DG || 
                    $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_FALCON_DG) {
                    //Type 2+/ 3+ / Type 1 - value from own damage collision
                    //Type 1- own damage collision value.
                    var odc = fn.utils.getCoverOwnDamageByProductId($scope.selectedCoverage);
                    if (odc && odc.length > 0) {
                        odc = odc[0]
                        if(odc && odc.InsuranceAmount) {
                            $scope.sumInsured.value = odc.InsuranceAmount;
                            $scope.sumInsured.visible = true;
                        } else {
                            $scope.sumInsured.value = 0;
                            $scope.sumInsured.visible = false;
                        }
                    }
                    else {
                        $scope.sumInsured.value = 0;
                        $scope.sumInsured.visible = false;
                    }
                    return;
                }

                // T1(MTI)
                if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_G || $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_MTI_D ) {                        

                    var odc = $filter('filter')($scope.selectedCoverage.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI }, true);

                    if (odc && odc.length > 0) {
                        odc = odc[0]
                        if(odc && odc.InsuranceAmount) {
                            $scope.sumInsured.value = odc.InsuranceAmount
                            $scope.sumInsured.visible = true    
                        } else {
                            $scope.sumInsured.value = 0
                            $scope.sumInsured.visible = false
                        }
                    }
                    else {
                        $scope.sumInsured.value = 0
                        $scope.sumInsured.visible = false
                    }
                    return;
                }
                
                // T1(AXA)
                if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_G || $scope.selectedCoverage.CoverTypeId == $scope.TYPE1_AXA_D ) {                        

                    var odc = $filter('filter')($scope.selectedCoverage.Covers, { ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA }, true);

                    if (odc && odc.length > 0) {
                        odc = odc[0]
                        if(odc && odc.InsuranceAmount) {
                            $scope.sumInsured.value = odc.InsuranceAmount
                            $scope.sumInsured.visible = true    
                        } else {
                            $scope.sumInsured.value = 0
                            $scope.sumInsured.visible = false
                        }
                    }
                    else {
                        $scope.sumInsured.value = 0
                        $scope.sumInsured.visible = false
                    }
                    return;
                }              

                if ($scope.selectedCoverage.CoverTypeId == $scope.TYPE2_FALCON_DG) {

                    //Type 2- own damage Fire and theft value
                    var odft = $filter('filter')($scope.selectedCoverage.Covers, {ProductLineGroupId: $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA}, true);
   
                    if (odft && odft.length > 0) {
                        odft = odft[0]
                        if(odft && odft.InsuranceAmount) {
                            $scope.sumInsured.value = odft.InsuranceAmount;
                            $scope.sumInsured.visible = true;    
                        } else {
                            $scope.sumInsured.value = 0;
                            $scope.sumInsured.visible = false;    
                        }                     
                    }
                    
                    else {
                        $scope.sumInsured.value = 0;
                        $scope.sumInsured.visible = false;
                    }
                    return;
                }

                if ($scope.selectedCoverage.CoverTypeId == $scope.TYPECOMPULSORY_FALCON_DG) {

                    //Type Compulsory only
                    $scope.sumInsured.visible = false;
                    $scope.sumInsured.value = 0;

                    return;
                }

                //Exception- Everything fails- hide
                $scope.sumInsured.visible = false;
                $scope.sumInsured.value = 0;

            },
            getTariffType: function () {
                if (!$scope.staticData.cardescriptions) return false;

                try {
                    var a = $filter('filter')($scope.staticData.cardescriptions, { key: $scope.asset.CarDescriptionId });
                    return a.length > 0 ? a[0].tariffType : 0;
                } 
                catch (err) {
                    return 0;
                }
            },
            retrieveNamedDrivers: function () {
                hasError = false;
                fn.namedDriversApi.retrieveNDPolicyContacts(
                    {
                        onSuccess: function (dataArray) {

                            if (!dataArray) {
                                return;
                            }

                            angular.forEach(dataArray,
                                function (data) {
                                    data.isForUpdate = true;
                                }
                            );

                            /** Need to enable this condition for VPP */
                            if ($scope.asset.PlanId == $scope.PLANID_NAMED_DRIVER && dataArray.length > 0) {
                                $scope.plan.coversNamed = 1;
                                $scope.plan.coversNamedWho = 0;
                                $scope.addNamedDriver = true;
                            }
                            /** End of condition */

                            $scope.namedDrivers.PolicyContacts.splice(0);
                            angular.extend($scope.namedDrivers.PolicyContacts, dataArray);
                            angular.forEach($scope.namedDrivers.PolicyContacts,
                                function (obj) {
                                    if (obj.DateOfBirth) {

                                        fn.utils.ConvertNDMITDateToDisplay(obj);
                                        fn.utils.convertNDMITDate(obj, null);
                                    }
                                    if (obj.Identifier) {
                                        HKFormValidation.splitIdentifier(obj, obj);
                                        /*To solve some problem on policyHolder scope*/
                                    }
                                }
                            );
                            $scope.isEditing = true;
                            if ($scope.namedDrivers.PolicyContacts.length) {
                                $scope.isEditing = false;
                            }
                            marketing.dLCE(100, '');


                        },
                        onError: function (err) {
                            if(!hasError) {
                                var error = err && err.ErrorInfo ? err.ErrorInfo : {fn: 'retrieveNamedDrivers', method: 'retrieveNDPolicyContacts'} ;
                                var errorLog = {err: JSON.stringify(error), case: 'error : line 9659'};
                                let priority = $localStorage.priority ? $localStorage.priority : 22;
                                fn.postToMaxParent('No', priority, 'N', errorLog)
                            }        
                            hasError = true

                        }
                    }
                );
            },
            showCoverageDetails: function () {
                DaModal.open('#popupCoverageDetails');
            },
            showInformationTotalDueTodayRateAdjustment: function () {
                DaModal.open('#popupTotalPriceRateAdjustment')
            },
            showInformationFinancialOptionRateAdjustment: function () {
                DaModal.open('#popupFinancialOptionRateAdjustment')
            },
            showFinancialMethodInstallmentPopup: function (thirdPageShow) {
                $scope.thirdPageShowNo = thirdPageShow;

                fn.utils.paymentScheduleToShow(thirdPageShow);

                // to initial selected button "Financial Option" on StickyRateAdjustment popup 
                if($sessionStorage.getFinancialOptionText == 'singlepayment'){ 
                    // SinglePayment : Active
                    $(".BlockOfMainFinancialOptionSingleButton").css({'background-color':'#FFE900','border': '3px solid #FFE900'})
                    $(".BlockOfHighlightColorTextSinglePayment").css('color','#CC1A21')
                    $('head').append('<style>.financialSinglePaymentCheckedState:before{ color: #CC1A21; }</style>')
                    // InstallmentPayment : Not-active
                    $(".BlockOfMainFinancialOptionInstallmentButton").css({'background-color':'#E5E9EE','border': '3px solid #E5E9EE'})
                    $(".BlockOfHighlightColorTextInstallment").css('color','#1E1E1E')
                    $('head').append('<style>.financialInstallmentPaymentCheckedState:before{ color: #E5E9EE; }</style>')
                }
                else {
                    if(($scope.financialCheckerFromInstallment && thirdPageShow !== 3) && ($scope.financialCheckerFromInstallment && thirdPageShow !== 8)){
                        // SinglePayment: Active
                        $(".BlockOfMainFinancialOptionSingleButton").css({'background-color':'#FFE900','border': '3px solid #FFE900'})
                        $(".BlockOfHighlightColorTextSinglePayment").css('color','#CC1A21')
                        $('head').append('<style>.financialSinglePaymentCheckedState:before{ color: #CC1A21; }</style>')
                        // InstallmentPayment: Not-active
                        $(".BlockOfMainFinancialOptionInstallmentButton").css({'background-color':'#E5E9EE','border': '3px solid #E5E9EE'})
                        $(".BlockOfHighlightColorTextInstallment").css('color','#1E1E1E')
                        $('head').append('<style>.financialInstallmentPaymentCheckedState:before{ color: #E5E9EE; }</style>')
   
                        // to create session when "SinglePayment" is hold on
                        // $sessionStorage.financialOptionSelection = 'singlepayment'
                   } else {
                    // InstallmentPayment : Active
                    $(".BlockOfMainFinancialOptionInstallmentButton").css({'background-color':'#FFE900','border': '3px solid #FFE900'})
                    $(".BlockOfHighlightColorTextInstallment").css('color','#CC1A21')
                    $('head').append('<style>.financialInstallmentPaymentCheckedState:before{ color: #CC1A21; }</style>')
                    // SinglePayment : Not-active
                    $(".BlockOfMainFinancialOptionSingleButton").css({'background-color':'#E5E9EE','border': '3px solid #E5E9EE'})
                    $(".BlockOfHighlightColorTextSinglePayment").css('color','#1E1E1E')
                    $('head').append('<style>.financialSinglePaymentCheckedState:before{ color: #E5E9EE; }</style>')

                   }
   
                }
                DaModal.open('#popupFinancialMethodInstallment')
            },
            financialSingleClicked: function(){
                // SinglePayment: Active
                $(".BlockOfMainFinancialOptionSingleButton").css({'background-color':'#FFE900','border': '3px solid #FFE900'})
                $(".BlockOfHighlightColorTextSinglePayment").css('color','#CC1A21')
                $('head').append('<style>.financialSinglePaymentCheckedState:before{ color: #CC1A21; }</style>')
                // InstallmentPayment: Not-active
                $(".BlockOfMainFinancialOptionInstallmentButton").css({'background-color':'#E5E9EE','border': '3px solid #E5E9EE'})
                $(".BlockOfHighlightColorTextInstallment").css('color','#1E1E1E')
                $('head').append('<style>.financialInstallmentPaymentCheckedState:before{ color: #E5E9EE; }</style>')

                // to create session when "SinglePayment" is hold on

                if($scope.currentProposal && $scope.currentProposal.PaymentTermId && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0 && $scope.currentProposal.PaymentTermId == $scope.INSTALLMENT_PAYMENT) {
                } else {
                    $scope.currentProposal.PaymentTermId = $scope.SINGLE_PAYMENT;

                    $scope.asset.PaymentTermId = $scope.SINGLE_PAYMENT;
                    if($scope.currentProposal && $scope.currentProposal.Assets && $scope.currentProposal.Assets.length > 0){
                        $scope.currentProposal.Assets[0].PaymentTermId = $scope.SINGLE_PAYMENT;
                    }  
                }
                $sessionStorage.financialOptionSelection = 'singlepayment'
                $scope.isFinancialSingleClicked = true;

            },
            financialInstallmentClicked: function(){
                // InstallmentPayment: Active
                $(".BlockOfMainFinancialOptionInstallmentButton").css({'background-color':'#FFE900','border': '3px solid #FFE900'})
                $(".BlockOfHighlightColorTextInstallment").css('color','#CC1A21')
                $('head').append('<style>.financialInstallmentPaymentCheckedState:before{ color: #CC1A21; }</style>')
                // SinglePayment: Not-active
                $(".BlockOfMainFinancialOptionSingleButton").css({'background-color':'#E5E9EE','border': '3px solid #E5E9EE'})
                $(".BlockOfHighlightColorTextSinglePayment").css('color','#1E1E1E')
                $('head').append('<style>.financialSinglePaymentCheckedState:before{ color: #E5E9EE; }</style>')

                // to create session when "Installment" is hold on
                $sessionStorage.financialOptionSelection = 'installment'
                if($scope.currentProposal && $scope.currentProposal.PaymentTermId) {
                    // $scope.currentProposal.PaymentTermId = $scope.INSTALLMENT_PAYMENT;
                    // $scope.financialCheckerFromInstallment = false;
                }
            },
            showFinancialMethodSinglePaymentPopup: function () {
                DaModal.open('#popupFinancialMethodSinglePayment')
            },
            getPriority: function (callback) {
                
                $window.ppstLayer = $window.ppstLayer || [];
                var ppstLayer = $window.ppstLayer;

                if ($scope.mainDriver.GenderId !== undefined) {
                    var gender = $scope.mainDriver.GenderId == 1 ? "FEMALE" : "MALE";
                }
                else {
                    var gender = $scope.policyHolder.GenderId == 1 ? "FEMALE" : "MALE";
                }

                if ($scope.mainDriver.MaritalStatusId !== undefined) {
                    var marital = $scope.mainDriver.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }
                else {
                    var marital = $scope.policyHolder.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }

                if ($scope.asset.UsageId == 6) {
                    var carUsage = "PRIVATE_USE";
                } 
                else if ($scope.asset.UsageId == 1000000) {
                    var carUsage = "PRIVATE_USE_AND_COMMUTING_TO_WORK";
                } 
                else if ($scope.asset.UsageId == 1000002) {
                    var carUsage = "PRIVATE_AND_BUSINESS_USE";
                } 
                else {
                    var carUsage = "COMMERCIAL_SPECIAL";
                }
                
                // 
                // to set "TypeCoverageName" into "GetPriority API"
                var getTypeCoverageNameToPropensity;
                var getTypeCoverageNameToPropensityId;
                if ($scope.coverages.Coverages !== undefined) {
                    if ($scope.coverages.Coverages.length > 0) {
                        for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                            if ($scope.coverages.Coverages[i].IsSelected) {
                                getTypeCoverageNameToPropensity = $scope.coverages.Coverages[i].Name;
                                getTypeCoverageNameToPropensityId = $scope.coverages.Coverages[i].CoverTypeId;
                                break
                            }
                        }
                    }
                    else { /*do nothing*/ }
                } 
                else { getTypeCoverageNameToPropensity = 'none' }

                // 
                // to set "NameDriver" into "GetPriority API"
                var getIDNameDriver = $scope.asset.PlanId
                var getNameDriverToString

                if(getIDNameDriver == 1000003){
                    getNameDriverToString = "Named Driver"
                }
                if(getIDNameDriver == 1000004){
                    getNameDriverToString = "Any Driver"
                }
                              
                var premium = fn.getTotalAmout();                
                var getDeductibleToPropensity = Utils.getCoverVoluntaryBasicByProductId($scope.selectedCoverage);

                if(getDeductibleToPropensity && getDeductibleToPropensity.length > 0) {
                    getDeductibleToPropensity = getDeductibleToPropensity[0]
                    if(getDeductibleToPropensity && getDeductibleToPropensity.ExcessAmount) {
                        getDeductibleToPropensity = getDeductibleToPropensity.ExcessAmount
                    }
                }

                if(getDeductibleToPropensity === undefined){ 
                    getDeductibleToPropensity = "0" 
                }
                else{ 
                    getDeductibleToPropensity = getDeductibleToPropensity 
                }
                
                ppstLayer.push({
                    "Proposal": $scope.partialPolicyNumber,
                    "PhoneNumber": $scope.phContact.PrimaryPhoneNumber,
                    "DateOfBirth": $scope.mainDriver.DateOfBirth,
                    "Gender": gender,
                    "MaritalStatus": marital,
                    "VehicleUsage": carUsage,
                    "MakeID": $scope.asset.ManufactureId,
                    "ModelID": $scope.asset.ModelId,
                    "YearOfRegistration": $scope.asset.PurchasingYear,
                    "CarDescriptionID": $scope.asset.CarDescriptionId,
                    "Premium": premium,
                    "NumberOfDataChanges": $sessionStorage.noOfPTSave,
                    "PORTAL_TYPE": "B2C",

                    // add new properties for "Propensity #1"
                    "Coverage Type": getTypeCoverageNameToPropensity,
                    "Sum insured": $scope.sumInsured.value,
                    "Deductible Amount": getDeductibleToPropensity,
                    "Named Driver": getNameDriverToString,

                    // add new properties for "Propensity #2"
                    "Calculate": $scope.calculateCounter,
                    "SelectThisPlan": $scope.selectThisPlanCounter,
                    "Back": $scope.backCounter,
                    "Confirm": $scope.confirmCounter,

                    // add new properties for "Propensity #3"
                    "Occupation": $scope.getOccupationPropensity,
                    "NumberOfDrivingExperience": $scope.getDrivingExperiencePropensity,
                    "NumberOfClaim": $scope.getNumOfAccidentPropensity,
                    "PolicyStartDate": $scope.getPolicyDateStartPropensity,

                    // add new properties for "Propensity #4 (10/11/2022 BY PU)"
                    "ProductTypeId": getTypeCoverageNameToPropensityId,
                    "ProductType": getTypeCoverageNameToPropensity,
                    "Occupation": $scope.getOccupationPropensity,
                    "ClaimExperience": $scope.getNumOfAccidentPropensity,
                    "ClaimExperienceId": $scope.mainDriver.NrOfClaimsInPeriodId,
                    "SourceURL": window.location.href,
                    "NextButton": $scope.selectThisPlanCounter,
                    "ConfirmButton": $scope.confirmCounter,
                    "BackButton": $scope.backCounter                          
                });

                $localStorage.ppstLayer = ppstLayer;

                fn.eventHandlers.getPriority(function (res) {
                    if (res) { callback(res); } 
                    else { callback(0); }
                });

            }
        };

        $scope.dataLayerMessages = marketing.dataLayerMessages;

        angular.element(document).on('mousemove keypress click scroll touchstart', function() {
            if(!$scope.contactPopUpOpened && !$scope.isThankYouPopUpOpended){
                $scope.$apply(() => $scope.resetTimer());
            }
        });

        /** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initSubscribeWatcher();
        //fn.initStaticData();
        aggFn.initStaticData(fn.retrieveScopeObjects);
        //fn.retrieveScopeObjects();


        // RegEx : Check except letter first of CarPlateNumber
        $scope.invalidationWordingCarplateNumber = /^((([^ฮ])([a-zA-Zก-๛0-9_]{1,9}))?)$/

        // Event Handlers
        $scope.resetTimer = fn.eventHandlers.resetTimer;
        $scope.onBtnCalculatePremiumClicked = fn.eventHandlers.onBtnCalculatePremiumClicked;
        $scope.onBtnSessionExpriedAgreeClicked = fn.eventHandlers.onBtnSessionExpriedAgreeClicked;
        $scope.onDropMakeChanged = aggFn.eventHandlers.onDropMakeChanged;
        $scope.onDropModelChanged = aggFn.eventHandlers.onDropModelChanged;
        $scope.onDropPurchasingYearChanged = aggFn.eventHandlers.onDropPurchasingYearChanged;
        $scope.onPaymentSelected = fn.eventHandlers.onPaymentSelected;
        
        //Starts popup timer
        $scope.resetTimer();






        
        // ############################
        // ############################
        // ############################
        // ############################
        // ############################
        // A/B Testing: Payment buttons

        // "Payment buttons" state checker
        if($sessionStorage.getFinancialOptionText == "singlepayment"){
            // to set "Single"
            $scope.paymentButtonsSelectedState = $scope.SINGLE_PAYMENT
        }
        else if($sessionStorage.getFinancialOptionText == "installment"){
            // to set "Installment"
            $scope.paymentButtonsSelectedState = $scope.INSTALLMENT_PAYMENT
        }
        else{
            // to not selecting anything
            // try to force as "Single"
            $scope.paymentButtonsSelectedState = $scope.SINGLE_PAYMENT
        }
        // ############################
        // ############################
        // ############################
        // ############################
        // ############################







        // ############################
        // ############################
        // ############################
        // A/B testing: Payment buttons
        $scope.ABtesting_OldVersionChecker = true;
        $scope.ABtesting_NewVersionChecker = false;
        // ############################
        // ############################
        // ############################






        

        $scope.onBtnInDriveWorkChange = fn.eventHandlers.onBtnInDriveWorkChange;
        $scope.onPolicyStartDateChanged = fn.eventHandlers.onPolicyStartDateChanged;
        $scope.onIsPHMDButton = fn.eventHandlers.onIsPHMDButton;
        $scope.onCarDescriptionChanged = fn.eventHandlers.onCarDescriptionChanged;
        $scope.onBtnNCDClicked = fn.eventHandlers.onBtnNCDClicked;
        $scope.onBtnNCDSelected = fn.eventHandlers.onBtnNCDSelected;
        $scope.onNCBApplyRequest = fn.eventHandlers.onNCBApplyRequest;
        $scope.getTotalAmout = fn.getTotalAmout;
        $scope.nextToMainDriver = fn.nextToMainDriver;
        $scope.checkAllCarInfoCompleted = fn.checkAllCarInfoCompleted;
        $scope.nextToContactInfo = fn.nextToContactInfo;
        $scope.checkAllContactInfoCompleted = fn.checkAllContactInfoCompleted;
        $scope.onBtnExitClicked = fn.eventHandlers.onBtnExitClicked;
        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.selectCoverage = fn.eventHandlers.selectCoverage;
        $scope.setCompulsory = fn.eventHandlers.setCompulsory;
        $scope.onBtnCoverExplainedClicked = fn.eventHandlers.onBtnCoverExplainedClicked;
        $scope.onBtnCoverExplainedSelected = fn.eventHandlers.onBtnCoverExplainedSelected;
        $scope.isCoverNotAvail = fn.validations.isCoverNotAvail;
        $scope.onFullDetailClicked = fn.eventHandlers.onFullDetailClicked;
        $scope.updateDeductibleAsset = fn.eventHandlers.updateDeductibleAsset;
        $scope.disablePremium = fn.disablePremium;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnBackClicked2 = fn.eventHandlers.onBtnBackClicked2;
        $scope.onBtnBackClicked3 = fn.eventHandlers.onBtnBackClicked3;
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.changeFinancialPaymentOption = fn.eventHandlers.changeFinancialPaymentOption;
        $scope.FinancialModalClose = fn.eventHandlers.FinancialModalClose;
        $scope.setBodyType = fn.setBodyType;
        $scope.setGearType = fn.setGearType;
        $scope.isPopularTruck = fn.isPopularTruck;
        $scope.onBtnNDAddClicked = fn.eventHandlers.onBtnNDAddClicked;
        $scope.onBtnNDCancelClicked = fn.eventHandlers.onBtnNDCancelClicked;
        $scope.onBtnNamedDriverModalUpdateClicked = fn.eventHandlers.onBtnNamedDriverModalUpdateClicked;
        $scope.onBtnNamedDriverModalRemoveClicked = fn.eventHandlers.onBtnNamedDriverModalRemoveClicked;
        $scope.onBtnNamedDriverModalRemoveCancelled = fn.eventHandlers.onBtnNamedDriverModalRemoveCancelled;
        $scope.onBtnNamedDriverModalRemoveConfirm = fn.eventHandlers.onBtnNamedDriverModalRemoveConfirm;
        $scope.onBtnToSummary = fn.eventHandlers.onBtnToSummary;
        $scope.onIsRedPlateClicked = fn.eventHandlers.onIsRedPlateClicked;
        $scope.checkRedPlateClickedOnB2C = fn.eventHandlers.redPlateClickedOnB2C;
        $scope.onIsCommercialPurposeClicked = fn.eventHandlers.onIsCommercialPurposeClicked;
        $scope.isCommercialUsage = fn.eventHandlers.isCommercialUsage;
        $scope.convertPHMITDate = fn.utils.convertPHMITDate;
        $scope.convertNDMITDate = fn.utils.convertNDMITDate;
        $scope.convertPolicyStartMITDate = fn.utils.convertPolicyStartMITDate;
        $scope.convertPolicyStartMITDateClick = fn.utils.convertPolicyStartMITDateClick;
        $scope.validateDriverAgeError = fn.utils.validateDriverAgeError;
        $scope.validateNDForms = fn.validations.validateNDForms;
        $scope.getExcessValueSummary = fn.utils.getExcessValueSummary;
        $scope.isSelectedExcess = fn.utils.isSelectedExcess;
        $scope.getTariffType = fn.getTariffType;
        $scope.isPHMDSync = fn.eventHandlers.isPHMDSync;
        $scope.isMDPHSync = fn.eventHandlers.isMDPHSync;
        $scope.showCoverageDetails = fn.showCoverageDetails;
        $scope.showInformationTotalDueTodayRateAdjustment = fn.showInformationTotalDueTodayRateAdjustment;
        $scope.showInformationFinancialOptionRateAdjustment = fn.showInformationFinancialOptionRateAdjustment;
        $scope.showFinancialMethodInstallmentPopup = fn.showFinancialMethodInstallmentPopup;
        $scope.showFinancialMethodSinglePaymentPopup = fn.showFinancialMethodSinglePaymentPopup;
        $scope.financialSingleClicked = fn.financialSingleClicked;
        $scope.financialInstallmentClicked = fn.financialInstallmentClicked;
        /** Validations */
        $scope.validations = {
            checkDOBEmpty: fn.validations.checkDOBEmpty,
            isEndDateInvalid: fn.validations.isEndDateInvalid,
            isValidDriverAge: $scope.$parent.isInvalidDriverAge,
            isTHIDValidPH: fn.validations.isTHIDValidPH,
            isTHIDValidMD: fn.validations.isTHIDValidMD,
            isTHIDValidND: fn.validations.isTHIDValidND
        };

        $scope.isStartDateInvalid = fn.validations.isStartDateInvalid,
        $scope.checkNoOfAcc = fn.validations.checkNoOfAcc;
        $scope.checkNoOfAccND = fn.validations.checkNoOfAccND;
        $scope.checkDrivingExpND = fn.validations.checkDrivingExpND;
        $scope.dLCE = marketing.dLCE;

        $scope.filterOptionalButCompulsoryCovers = fn.utils.filterOptionalButCompulsoryCovers;

        $scope.checkDrivingExp = fn.validations.checkDrivingExp;
        $scope.checkNCDLevel = fn.validations.checkNCDLevel;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.getPriceToShow = fn.utils.getPriceToShow;
        $scope.getComplusoryBasicCoverPremium = fn.utils.getComplusoryBasicCoverPremium;
        $scope.paymentScheduleToShow = fn.utils.paymentScheduleToShow;
        $scope.convertMITDate = fn.utils.convertMITDate;
        $scope.convertMITDateClick = fn.utils.convertMITDateClick;
        $scope.coverageList = fn.utils.coverageList;
        $scope.checkMake = fn.validations.checkMake;
        $scope.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.getExcessValue = fn.utils.getExcessValue;
        $scope.isMoreThanFive = fn.eventHandlers.isMoreThanFive;
        $scope.isEvCar = fn.eventHandlers.isEvCar;
        $scope.setMaker = fn.eventHandlers.setMaker;
        $scope.setModel = fn.eventHandlers.setModel;
        $scope.setYear = fn.eventHandlers.setYear;
        $scope.setDesc = fn.eventHandlers.setDesc;
        $scope.showModel = fn.eventHandlers.showModel;
        $scope.showYear = fn.eventHandlers.showYear;
        $scope.showDesc = fn.eventHandlers.showDesc;
        $scope.getFormatDateText = fn.eventHandlers.getFormatDateText;


        $scope.setProvince = fn.eventHandlers.setProvince;
        $scope.setCamera = fn.eventHandlers.setCamera;
        $scope.setCarUsage = fn.eventHandlers.setCarUsage;

        $scope.setPaymentTerms = fn.eventHandlers.setPaymentTerms;
        $scope.setMDProvince = fn.eventHandlers.setMDProvince;
        $scope.setMDGender = fn.eventHandlers.setMDGender;
        $scope.setMDMaritalStatusId = fn.eventHandlers.setMDMaritalStatusId;
        $scope.setOccupationId = fn.eventHandlers.setOccupationId;
        $scope.setNrOfClaimsInPeriodId = fn.eventHandlers.setNrOfClaimsInPeriodId;
        $scope.setDrivingExperienceId = fn.eventHandlers.setDrivingExperienceId;
        $scope.setDrivingExperienceIdByButton = fn.eventHandlers.setDrivingExperienceIdByButton;
        $scope.setVehicleColour = fn.eventHandlers.setVehicleColour;
        $scope.setNationality = fn.eventHandlers.setNationality;
        $scope.setNamedDriverOccupationId = fn.eventHandlers.setNamedDriverOccupationId;
        $scope.setNamedDriverResidentialStatusId = fn.eventHandlers.setNamedDriverResidentialStatusId;
        $scope.setNamedDriverNationalityId = fn.eventHandlers.setNamedDriverNationalityId;
        $scope.setNamedDriverDrivingExperienceId = fn.eventHandlers.setNamedDriverDrivingExperienceId;
        $scope.setNamedDriverNrOfClaimsInPeriodId = fn.eventHandlers.setNamedDriverNrOfClaimsInPeriodId;
        $scope.setPolicyHolderOccupationId = fn.eventHandlers.setPolicyHolderOccupationId;
        $scope.setPolicyHolderNationalityId = fn.eventHandlers.setPolicyHolderNationalityId;
        $scope.setPhAddressProvinceId = fn.eventHandlers.setPhAddressProvinceId;
        $scope.setPhAddressDistrictId = fn.eventHandlers.setPhAddressDistrictId;

        $scope.postBLFC = fn.eventHandlers.postBLFC;
        $scope.postUWFC = fn.eventHandlers.postUWFC;
        $scope.toThaiYear = fn.toThaiYear;
        $scope.gtAnchor = fn.gtAnchor;
        $scope.gotoOnFocusAnchor = fn.gotoOnFocusAnchor;
        $scope.goToInput = fn.goToInput;
        $scope.closeDaModal = fn.closeDaModal;
        $scope.int = parseInt;
        $scope.selectIAmount = fn.selectIAmount;
        $scope.setPlan = fn.utils.setPlan;
        $scope.customerSelectedPlan = fn.utils.customerSelectedPlan;
        $scope.selectMyWorkshopOption = fn.utils.selectMyWorkshopOption;
        $scope.getInsuranceAmount = fn.utils.getInsuranceAmount;
        $scope.getIncludeCompulsory = fn.utils.getIncludeCompulsory;
        $scope.onBtnConvertPolicyClicked = fn.eventHandlers.onBtnConvertPolicyClicked;
        $scope.onBtnConvertPolicyCancel = fn.eventHandlers.onBtnConvertPolicyCancel;
        $scope.addNamedDriver = fn.eventHandlers.addNamedDriver;
        $scope.removeNamedDriver = fn.eventHandlers.removeNamedDriver;
        $scope.isCMIIncluded = fn.utils.isCMIIncluded;
        $scope.testSaveContact = fn.saving.savePHContact;
        $scope.needRecalculate = fn.eventHandlers.needRecalculate;
        $scope.onBtnNDSaveClicked = fn.eventHandlers.onBtnNDSaveClicked;
        $scope.gaEvents = $scope.$parent.gaEvents;
        $scope.onDisabledButtons = fn.eventHandlers.onDisabledButtons;
        $scope.onEnabledButtons = fn.eventHandlers.onEnabledButtons;
        $scope.showCameraStyle = fn.eventHandlers.showCameraStyle;
        $scope.showUsage = fn.eventHandlers.showUsage;
        $scope.showPayment = fn.eventHandlers.showPayment;
        $scope.showGender = fn.eventHandlers.showGender;
        $scope.showPayments = fn.eventHandlers.showPayments;
        $scope.showStatus = fn.eventHandlers.showStatus;
        $scope.showFinancialOption = fn.eventHandlers.showFinancialOption;
        $scope.OnClickMaritalStatusId = fn.eventHandlers.OnClickMaritalStatusId;
        $scope.OnClickAccident = fn.eventHandlers.OnClickAccident;
        $scope.OnClickClaims = fn.eventHandlers.OnClickClaims;
        $scope.showDriverExperience = fn.eventHandlers.showDriverExperience;
        $scope.showAccident = fn.eventHandlers.showAccident;
        $scope.showNameBorder = fn.eventHandlers.showNameBorder;
        $scope.showFirstBorder = fn.eventHandlers.showFirstBorder;
        $scope.showNumberPhone = fn.eventHandlers.showNumberPhone;
        $scope.showEmail = fn.eventHandlers.showEmail;
        $scope.scrollToSection = fn.eventHandlers.scrollToSection;
        $scope.sendPopUpContactToMaxar = fn.eventHandlers.sendPopUpContactToMaxar;

        // $scope.onDoorsTypeModelCheck = fn.eventHandlers.onDoorsTypeModelCheck;

        // initial value
        //1.P22 -> parameter for check MT emtry
        $scope.PACKAGE_TYPE1 = AppConstants.AGGConfig.PACKAGE_TYPE1;
        $scope.PACKAGE_TYPE1_SAVE = AppConstants.AGGConfig.PACKAGE_TYPE1_SAVE;
        $scope.PACKAGE_TYPE2 = AppConstants.AGGConfig.PACKAGE_TYPE2;
        $scope.PACKAGE_TYPE2_PLUS = AppConstants.AGGConfig.PACKAGE_TYPE2_PLUS;
        $scope.PACKAGE_TYPE3 = AppConstants.AGGConfig.PACKAGE_TYPE3;
        $scope.PACKAGE_TYPE3_PLUS  = AppConstants.AGGConfig.PACKAGE_TYPE3_PLUS;

        $scope.TYPE1_MTI_G = AppConstants.AGGConfig.TYPE1_MTI_G; //Type 1 (MTI) Garage
        $scope.TYPE1_MTI_D = AppConstants.AGGConfig.TYPE1_MTI_D; //Type 1 (MTI) Dealer

        $scope.TYPE1_AXA_G = AppConstants.AGGConfig.TYPE1_AXA_G; //Type 1 (AXA) Garage
        $scope.TYPE1_AXA_D = AppConstants.AGGConfig.TYPE1_AXA_D; //Type 1 (AXA) Dealer

        $scope.TYPE1_FALCON_DG = AppConstants.AGGConfig.TYPE1_FALCON_DG; //Type 1 (Falcon) Garage/Dealer
        $scope.TYPE1SAVE_FALCON_DG = AppConstants.AGGConfig.TYPE1SAVE_FALCON_DG; //Type 1 Save (Falcon) Garage/Dealer
        $scope.TYPE2_FALCON_DG = AppConstants.AGGConfig.TYPE2_FALCON_DG; //Type 2 (Falcon) Garage/Dealer
        $scope.TYPE2PLUS_FALCON_DG = AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG; //Type 2+ (Falcon) Garage/Dealer
        $scope.TYPE3_FALCON_DG = AppConstants.AGGConfig.TYPE3_FALCON_DG; //Type 3 (Falcon) Garage/Dealer
        $scope.TYPE3PLUS_FALCON_DG = AppConstants.AGGConfig.TYPE3PLUS_FALCON_DG; //Type 3+ (Falcon) Garage/Dealer
        $scope.TYPECOMPULSORY_FALCON_DG = AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG; //พ.ร.บ.

        $scope.PRODUCT_ID_DA = AppConstants.AGGConfig.PRODUCT_ID_DA;
        $scope.PRODUCT_ID_MTI = AppConstants.AGGConfig.PRODUCT_ID_MTI;
        $scope.PRODUCT_ID_AXA = AppConstants.AGGConfig.PRODUCT_ID_AXA;

        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_DA;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_DA;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_OWN_DAMAGE_FIRE_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_DA;
        $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_VOLUNTARY_MOTOR_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_DEATH_DIS_DA;
        $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_DEATH_DIS_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_DEATH_DIS_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_DEATH_DIS_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_DA;
        $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_BODILY_INJURY_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_DA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_MTI = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_MTI;
        $scope.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_AXA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPULSORY_CORE_AXA;
        $scope.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER2_DA;
        $scope.PRODUCT_LINE_GROUP_ID_2000112_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_2000112_DA;
        $scope.PRODUCT_LINE_GROUP_ID_2000107_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_2000107_DA;
        $scope.PRODUCT_LINE_GROUP_ID_2000160_DA = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_2000160_DA;
        $scope.VALUE_PLAN = AppConstants.AGGConfig.VALUE_PLAN;
        $scope.FLEXIBLE_PLAN = AppConstants.AGGConfig.FLEXIBLE_PLAN;
        $scope.VALUE_PLUS_PLAN = AppConstants.AGGConfig.VALUE_PLUS_PLAN;
        $scope.SINGLE_PAYMENT = AppConstants.AGGConfig.SINGLE_PAYMENT;
        $scope.INSTALLMENT_PAYMENT = AppConstants.AGGConfig.INSTALLMENT_PAYMENT;
        $scope.SINGLE_PAYMENT_MODE_ID = AppConstants.AGGConfig.SINGLE_PAYMENT_MODE_ID;
        $scope.INSTALLMENT_PAYMENT_MODE_ID = AppConstants.AGGConfig.INSTALLMENT_PAYMENT_MODE_ID;

        $scope.PRODUCT_LINE_GROUPID_FALCON_2000116 = AppConstants.AGGConfig.PRODUCT_LINE_GROUPID_FALCON_2000116;

        $scope.PLANID_NAMED_DRIVER = AppConstants.AGGConfig.PLANID_NAMED_DRIVER;
        $scope.PLANID_ANY_DRIVER = AppConstants.AGGConfig.PLANID_ANY_DRIVER;

        $scope.PRODUCT_LINE_GROUP_VALUE_VOLUNTARY_BASIC_COVER = AppConstants.AGGConfig.PRODUCT_LINE_GROUP_VALUE_VOLUNTARY_BASIC_COVER;

        $scope.compulsoryChecker = true;
        $scope.mtNumber = true;
        $scope.calculateCounter = 0
        $scope.selectThisPlanCounter = 0        
        $scope.backCounter = 0
        $scope.confirmCounter = 0
        $scope.checkStateFinancialMethod = 'singlepayment'

        $scope.firstName = null
        $scope.lastName = null
        $scope.policyHolder.FirstName = null
        $scope.policyHolder.LastName = null
        $scope.policyStartDate = false;
        $scope.currentProposal.PolicyStartDateFirst = "";
        $scope.loadingSpinner = false;
        $scope.loadingSpinner2 = false;
        $scope.loadingSpinner3 = false;
        $scope.isNoPackage = false;

        $scope.isPolicyStartDateOriginal = false;
        $scope.isPolicyStartDateABTesting = true;
        $scope.isClickPolicyOfYear = false;
        $scope.isClickBirthDateOfYear = false;

        // $scope.policyHolder.PreferredName = null

        //Detect width of screen
        $scope.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

        // initial value of "Financial option checker"
        $scope.financialChecker = $sessionStorage.toSetFinancialChecker
        console.log(`Onload toSetFinancialChecker : ${$sessionStorage.toSetFinancialChecker}`)

        // get value from sesssion into FirstPayment & MonthlyPremium if refreshing browser (page 3)
        $scope.getFirstPaymentToShow = $sessionStorage.toSetFirstPaymentToShow
        $scope.getMonthlyPremiumToShow = $sessionStorage.toSetMonthlyPremiumToShow
        console.log(`Onload toSetFirstPaymentToShow : ${$sessionStorage.toSetFirstPaymentToShow}`)
        console.log(`Onload toSetMonthlyPremiumToShow : ${$sessionStorage.toSetMonthlyPremiumToShow}`)

        // clear session of "Financial Option" after browser refreshing
        $sessionStorage.financialOptionSelection = ''

        // Type2 or Type3 state checker when refreshing state
        $scope.type2and3RefreshingState = $sessionStorage.toSetSinglePaymentFromType2Type3Selected

        $scope.staticData.usagesAll = null;
        $scope.usageIdDefault = 6;
        $scope.countCalculateClick = 0;
        $scope.countCalculateFirstClick = false;
        $scope.isProductSwitching = false;
        $scope.CheckToCalculate = 1;
        $scope.PutDataDone = "";
        $scope.ShowPayment = undefined;
        $scope.currentProposal.PaymentTermId = '';

        $scope.previousCoverages = [];

        $scope.thirdPageShow = false;
        $scope.thirdPageShowNo = false;
        $scope.financialCheckerFromInstallment = false;
        $scope.isOnBtnBackClicked2 = false;
        $scope.isFinancialSingleClicked = false;

        $scope.compulsoryIsIncluded = undefined;

        $scope.getCarPlateProvinceName = '';
        $scope.mainDriver.GenderId = "";
        $scope.policyHolder.CarPlateProvinceID = "";
        $scope.getProvince = "";
        $scope.engineSizeId = undefined;

        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function () {
            $timeout(function () {
                // Post
                fn.refreshCurrentPage($sessionStorage.currentPage);
            }, 0);
            $timeout(function () {
                $scope.$parent.changeGlobalContactNo();
            }, 1000);
        });
        $(window).resize(function(){
            $scope.$apply(function(){
                $scope.width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
            });
        });

      
                                    const currentProposalPolicyStartDate = document.getElementById('currentProposalPolicyStartDate');
                                    if(HKLocale.getLocale() === 'th-th') {
                                        currentProposalPolicyStartDate.placeholder = "วว/ดด/ปปปป";
                                        currentProposalPolicyStartDate.className = "FontBodyParagraph-B2C-TH";
                                    } else {
                                        currentProposalPolicyStartDate.placeholder = "DD/MM/YYYY";
                                        currentProposalPolicyStartDate.className = "FontBodyParagraph-B2C-EN";
                                    }

                                    const currentProposalPolicyStartDatepicker = new TheDatepicker.Datepicker(currentProposalPolicyStartDate);
                                    currentProposalPolicyStartDatepicker.options.setInputFormat('d/m/Y');

                                    if(HKLocale.getLocale() === 'th-th') {
                                        currentProposalPolicyStartDatepicker.options.setTitle("วันเริ่มความคุ้มครอง");
                                    } else{
                                        currentProposalPolicyStartDatepicker.options.setTitle("Policy start date");
                                    }
                                    
                                    let currentProposalPolicyStartDate_now = new Date();
                                    let currentProposalPolicyStartDate_year = currentProposalPolicyStartDate_now.getFullYear();
                                    currentProposalPolicyStartDatepicker.options.setMinDate(currentProposalPolicyStartDate_year.toString() + '-01-01');
                                    currentProposalPolicyStartDatepicker.options.setMaxDate((currentProposalPolicyStartDate_year + 1).toString() + '-12-31');
                                    currentProposalPolicyStartDatepicker.options.setInitialDate(moment().add(1,'d').format('YYYY-MM-DD'));

                                    currentProposalPolicyStartDatepicker.options.onSelect(function (event, day, previousDay) {
                                        let policyStartDateDD = document.getElementById('policyStartDateDD');
                                        policyStartDateDD.value = day.dayNumber;

                                        let policyStartDateMON = document.getElementById('policyStartDateMON');
                                        policyStartDateMON.value = day.month;

                                        let policyStartDateYear = document.getElementById('policyStartDateYear');
                                        policyStartDateYear.value = day.year - currentProposalPolicyStartDate_year

                                        policyStartDateDD.dispatchEvent(new Event('change'));
                                        policyStartDateMON.dispatchEvent(new Event('change'));
                                        policyStartDateYear.dispatchEvent(new Event('change'));

                                        if (event) {
                                            fn.eventHandlers.scrollToSection(8)
                                            fn.eventHandlers.showFinancialOptionDropdown()
                                        }
                                        // let showPayment = sessionStorage.getItem("ngStorage-ShowPayment");
                                        // let showPayment = $scope.ShowPayment;
                                        // $scope.ShowPayment = true;
                                        // if(showPayment){
                                        //     $("#Financial3").addClass("open");
                                        // }

                                        $scope.txtPolicyStartDate = moment().year(day.year).month(day.month - 1).date(day.dayNumber).format('YYYY-MM-DD');
                                        if (event) {
                                            marketing.pushDataLayerMessage('pushPolicyStartDate', marketing.dataLayerMessages.selectPolicyStartDate);
                                        }
                                    });

                                    currentProposalPolicyStartDatepicker.render();

                                    function currentProposalPolicyStartDate_default() {
                                        let policyStartDateDD = document.getElementById('policyStartDateDD');
                                        policyStartDateDD.value = parseInt(moment().format('DD')) - 1;
                                        // console.log(policyStartDateDD.value);
                                        let policyStartDateMON = document.getElementById('policyStartDateMON');
                                        policyStartDateMON.value = parseInt(moment().format('MM')) - 1;

                                        let policyStartDateYear = document.getElementById('policyStartDateYear');
                                        policyStartDateYear.value = 0;

                                        policyStartDateDD.dispatchEvent(new Event('change'));
                                        policyStartDateMON.dispatchEvent(new Event('change'));
                                        policyStartDateYear.dispatchEvent(new Event('change'));

                                        currentProposalPolicyStartDate.value = moment().format('DD/MM/YYYY');
                                    }
                                    // setTimeout(function() {
                                    //     currentProposalPolicyStartDate_default();
                                    // }, 3000);


                                    const mainDriverDOB = document.getElementById('mainDriverDOB');

                                    // console.log("HKLocale.getLocale() : ",HKLocale.getLocale());
                                    if(HKLocale.getLocale() === 'th-th') {
                                        mainDriverDOB.placeholder = "วว/ดด/ปปปป";
                                        mainDriverDOB.className = "FontBodyParagraph-B2C-TH";
                                    } else {
                                        mainDriverDOB.placeholder = "DD/MM/YYYY";
                                        mainDriverDOB.className = "FontBodyParagraph-B2C-EN";
                                    }
                                    // console.log("mainDriverDOB placeholder format : ",mainDriverDOB.placeholder);

                                    const mainDriverDOBDatepicker = new TheDatepicker.Datepicker(mainDriverDOB);
                                    mainDriverDOBDatepicker.options.setInputFormat('d/m/Y');

                                    if(HKLocale.getLocale() === 'th-th') {
                                        mainDriverDOBDatepicker.options.setTitle("วันเกิด");
                                    } else{
                                        mainDriverDOBDatepicker.options.setTitle("Date of birth");
                                    }


                                    let mainDriverDOBDatepicker_now = new Date();
                                    let mainDriverDOBDatepicker_year = mainDriverDOBDatepicker_now.getFullYear();
                                    mainDriverDOBDatepicker.options.setMinDate((mainDriverDOBDatepicker_year - 71).toString() + '-01-01');
                                    mainDriverDOBDatepicker.options.setMaxDate((mainDriverDOBDatepicker_year - 18).toString() + '-12-31');

                                    mainDriverDOBDatepicker.options.onSelect(function (event, day, previousDay) {
                                        let mainDriverDOBDD = document.getElementById('mainDriverDOBDD');
                                        mainDriverDOBDD.value = day.dayNumber - 1;

                                        let mainDriverDOBMON = document.getElementById('mainDriverDOBMON');
                                        mainDriverDOBMON.value = day.month - 1;

                                        let mainDriverDOBYear = document.getElementById('mainDriverDOBYear');
                                        mainDriverDOBYear.value = (mainDriverDOBDatepicker_year - 18) - day.year;

                                        mainDriverDOBDD.dispatchEvent(new Event('change'));
                                        mainDriverDOBMON.dispatchEvent(new Event('change'));
                                        mainDriverDOBYear.dispatchEvent(new Event('change'));

                                        $scope.txtMdDob = moment().year(day.year).month(day.month - 1).date(day.dayNumber).format('YYYY-MM-DD');
                                        if (event) {
                                            marketing.pushDataLayerMessage('pushMainDriverDob', marketing.dataLayerMessages.selectMainDriverDob);
                                        }
                                    });

                                    mainDriverDOBDatepicker.render();

        $scope.searchParams = new URLSearchParams(location.search);

        if ($scope.searchParams.has('Campaign') || $sessionStorage.seoCampaign) {
            let campaign = $scope.searchParams.has('Campaign')
                ? $scope.searchParams.get('Campaign').toLowerCase()
                : $sessionStorage.seoCampaign;
            if (campaign === 'type2+') {
                $sessionStorage.seoCampaign = campaign;
                $rootScope.$broadcast('SetSeoCampaign', campaign);
            }
        }
    }];