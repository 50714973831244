module.exports = [
    'HKLocale',
    'ConfigUtils',
    '$http',

    /**
     * ApiServiceProvider
     * @returns {Function}
     * @constructor
     */
    function ApiServiceV2Provider(HKLocale, config, $http) {

        var AGGConfig = {
            Header: {
                headers: {
                    'CF-Access-Client-Id': config.aggCFAccessClientId,
                    'CF-Access-Client-Secret': config.aggCFAccessClientSecret,
                    'apiKey': config.aggApiKey,
                    'Content-Type': 'application/json',
                }
            }
        };

        var fn = {
            getStaticData: function (callback, name) {
                try {
                    // var apiUrl = config.aggApiV2 + '/' + name.toUpperCase() + '?lang=' + HKLocale.getLocale() + '&apiKey=' + config.aggApiKey; //Old
                    var apiUrl = config.aggApiV2 + '/' + name.toUpperCase() + '?lang=' + HKLocale.getLocale();
                    $http.get(apiUrl, AGGConfig.Header)
                        .success(function (data) {
                            callback.onSuccess(data);
                        })
                        .error(function (err) {
                            callback.onError();
                        });
                } catch (err) {
                    callback.onError();
                }
            },
            getStaticDataWithParam: function (callback, name, params) {
                try {
                    // var apiUrl = config.aggApiV2 + '/' + name.toUpperCase() + '?lang=' + HKLocale.getLocale() + '&apiKey=' + config.aggApiKey + '&' + params; //Old
                    var apiUrl = config.aggApiV2 + '/' + name.toUpperCase() + '?lang=' + HKLocale.getLocale() + '&' + params;
                    $http.get(apiUrl, AGGConfig.Header)
                        .success(function (data) {
                            callback.onSuccess(data);
                        })
                        .error(function (err) {
                            callback.onError();
                        });
                } catch (err) {
                    callback.onError();
                }
            },
            getVehicleExcess: function (callback, name, params) {
                try {
                    // var apiUrl = config.aggApiV2 + '/' + name.toUpperCase() + '?lang=' + HKLocale.getLocale() + '&apiKey=' + config.aggApiKey + '&' + params; //Old
                    var apiUrl = config.aggApiV2 + '/' + name.toUpperCase() + '?lang=' + HKLocale.getLocale() + '&' + params;
                    $http.get(apiUrl, AGGConfig.Header)
                        .success(function (data) {
                            callback.onSuccess(data);
                        })
                        .error(function (err) {
                            callback.onError();
                        });
                } catch (err) {
                    callback.onError();
                }
            },
            getVehicleColour: function(callback) {
                try {
                    var data = [
                        {
                            key: "#f00",
                            value: HKLocale.getLocale() === "th-th" ? "แดง" : "Red"
                        },
                        {
                            key: "#0f0",
                            value: HKLocale.getLocale() === "th-th" ? "เขียว" : "Green"
                        },
                        {
                            key: "#00f",
                            value: HKLocale.getLocale() === "th-th" ? "ฟ้า" : "Blud"
                        },
                        {
                            key: "#0ff",
                            value: HKLocale.getLocale() === "th-th" ? "เทา" : "Cyan"
                        },
                        {
                            key: "#ff0",
                            value: HKLocale.getLocale() === "th-th" ? "เหลือง" : "Yellow"
                        },
                        {
                            key: "#000",
                            value: HKLocale.getLocale() === "th-th" ? "ดำ" : "Black"
                        }
                    ];
                    callback.onSuccess(data);
                } catch (err) {
                    callback.onError();
                }
            },
            getBooleanListWithSometimes: function(callback) {
                try {
                    var data = [
                        {
                            key: true,
                            value: HKLocale.getLocale() === "th-th" ? "ใช่/บางครั้ง" : "Yes/Sometimes"
                        },
                        {
                            key: false,
                            value: HKLocale.getLocale() === "th-th" ? "ไม่" : "No"
                        }
                    ];
                    callback.onSuccess(data);
                } catch (err) {
                    callback.onError();
                }
            },
            getResidentialStatusList: function(callback) {
                try {
                    var data = [
                        {
                            key: '2000000',
                            value: HKLocale.getLocale() === "th-th" ? "ไทย" : "Thai"
                        },
                        {
                            key: '2000001',
                            value: HKLocale.getLocale() === "th-th" ? "บุคคลที่ไม่มีถิ่นที่อยู่ในไทย" : "Non-Thai Resident"
                        }
                    ];
                    callback.onSuccess(data);
                } catch (err) {
                    callback.onError();
                }
            },
            retrieveCoverageMockup: function (callback, name, params) {
                try {
                    $http.get('/assets/mockup/responseMtiDaModel.json')
                        .success(function (data) {
                            callback.onSuccess(data);
                        })
                        .error(function (err) {
                            callback.onError();
                        });
                } catch (err) {
                    callback.onError();
                }
            },

        };

        this.getStaticData = fn.getStaticData;
        this.getStaticDataWithParam = fn.getStaticDataWithParam;
        this.getVehicleColour = fn.getVehicleColour;
        this.getBooleanListWithSometimes = fn.getBooleanListWithSometimes;
        this.getResidentialStatusList = fn.getResidentialStatusList;
        this.getVehicleExcess = fn.getVehicleExcess;
        this.retrieveCoverageMockup = fn.retrieveCoverageMockup;
    }];