module.exports = [
    '$scope',
    'Account',
    'FormValidation',
    'DaModal',

    function FormPasswordController($scope,
                                    Account,
                                    FormValidation, DaModal) {
        var fn = {
            initScopeObjects: function () {
                $scope.passwordSuccessMessage = '';
                $scope.account = new Account();
                $scope.account.Password = '';
                $scope.account.updated = '';

                $scope.account.currentPassword = '';
                $scope.account.newPwd = '';
                $scope.account.newPwdReenter = '';

                $scope.hasInvalidChangePassword = fn.eventHandlers.hasInvalidChangePassword;
                $scope.BtnUpdatePasswordClicked = fn.eventHandlers.BtnUpdatePasswordClicked;
                $scope.BtnBackClicked=fn.eventHandlers.BtnBackClicked;
            },

            eventHandlers: {
                BtnBackClicked:function(){
                    $scope.$parent.$parent.MyProfileScope.setDisabled(true);
                    $scope.$parent.$parent.showPasswordDiv = false;
                },
                BtnUpdatePasswordClicked: function (frmUpdateCredential) {
                    $scope.pageSubmitted = true;

                    if ($scope.hasInvalidChangePassword(frmUpdateCredential)) {

                        FormValidation.focusOnError();
                        return;
                    }

                    var onUpdatePasswordError = function () {
                        DaModal.open('#modalUpdatePasswordError');
                    };

                    $scope.account.Password = $scope.account.currentPassword;
                    $scope.account.updated = $scope.account.newPassword;
                    Account.changePass(undefined, $scope.account, function (error) {
                        if (!error.success) {
                            onUpdatePasswordError();
                        } else {
                            $scope.$parent.$parent.MyProfileScope.setDisabled(true);
                            $scope.$parent.$parent.showPasswordDiv = false;
                        }
                    });
                },

                hasInvalidChangePassword: function hasInvalid(frmUpdateCredential) {
                    return frmUpdateCredential.$invalid;
                }
            }
        };

        fn.initScopeObjects();
    }];