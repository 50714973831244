/*
 * Created on 10/06/15.
 */
module.exports = [
    '$scope',
    'HKLocale',
    'WfeApiSession',
    'HKSteps',
    'AppConstants',
    /**
     * The controller that handles the Motor quote process and procedure access.
     * @param $scope
     * @constructor
     */
    function MotorQuoteBaseQuoteController($scope, HKLocale, WfeApiSession, HKSteps, AppConstants) {

        var fn = {
            product: {
                ProductId: AppConstants.AGGConfig.PRODUCT_ID_DA,
                //ProductId: 1000003, //changed to SG code temporary.
                ProductCode: 'motor',
                ProductHeader: HKLocale.getResource('car_h_product_name')
            },


            initScopeObjects: function(){
                $scope.product = fn.product;
                $scope.sessionApi = WfeApiSession.instance($scope, fn.product.ProductCode);
                $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);
            },

            setIsAccepted: function (isAcceptedParam) {
                $scope.stepsUtils.setIsAccepted(isAcceptedParam);
            },

            getIsAccepted: function () {
                return $scope.stepsUtils.getIsAccepted();
            }
        };

        fn.initScopeObjects();

        $scope.setIsAccepted = fn.setIsAccepted;
        $scope.getIsAccepted = fn.getIsAccepted;
    }];