module.exports = [
    '$scope',
    '$filter',
    'DaModal',
    'HKLocale',
    'AccountUser',
    'FormValidation',
    'CountryMotorcycleQuoteValidation',
    'WfeApiStaticData',
    'WfeApiUser',
    '$window',

    function MyProfileFormPersonalDetailsController($scope, $filter,
                                                    DaModal,
                                                    HKLocale, AccountUser, FormValidation, CountryMotorcycleQuoteValidation,
                                                    WfeApiStaticData, WfeApiUser,$window) {

        var fn = {
            initScopeObjects: function () {
                $scope.staticData = {};
                $scope.accountUser = {};
                $scope.contact = {};
                $scope.phAddress = {};
                $scope.policyContact = {};
                $scope.policyDOB = {};
                //$scope.contactNoErr = false;

                $scope.addNewPhoneNumber = fn.eventHandlers.addNewPhoneNumber;
                $scope.removePhoneNumber = fn.eventHandlers.removePhoneNumber;
                $scope.BtnSaveClicked = fn.eventHandlers.BtnSaveClicked;
                $scope.hasInvalid = fn.validations.hasInvalid;
                $scope.checkPreferredPhone = fn.eventHandlers.checkPreferredPhone;

                $scope.$parent.$parent.MyProfileFormDetailsScope = {
                    retrieveScopeObjects: fn.retrieveScopeObjects
                };
            },

            initApiObjects: function () {
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), '1000009');
                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);
            },

            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getOccupationList();
                };

                var getOccupationList = function () {
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getMaritalStatusList();
                        },
                        onError: function () {
                            getMaritalStatusList();
                        }
                    });
                };
                var getMaritalStatusList = function () {
                    wfeApiStaticData.getMaritalStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getResidentialStatusList();
                        },
                        onError: function () {
                            getResidentialStatusList();
                        }
                    });
                };

                var getResidentialStatusList = function () {
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentStatuses = data;
                            getDistrictList();
                        },
                        OnError: function () {
                            getDistrictList();
                        }
                    });
                };

                var getDistrictList = function () {
                    wfeApiStaticData.getDistrictList({
                        onSuccess: function (data) {
                            $scope.staticData.districtByPostal = data;
                            getProvinceList();
                        },
                        onError: function () {
                            getProvinceList();
                        }
                    });
                };

                var getProvinceList = function () {
                    wfeApiStaticData.getProvinceList({
                        onSuccess: function (data) {
                            $scope.staticData.provincesByPostal = data;
                            getCountryList();
                        },
                        onError: function () {
                            getCountryList();
                        }
                    });
                };

                var getCountryList = function () {
                    wfeApiStaticData.getCountryList({
                        onSuccess: function (data) {
                            $scope.staticData.countries = data;
                        }
                    });
                }

                startRetrieval();
            },

            retrievePerson : function () {
                fn.wfeApiUser.retrieveUser(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.accountUser, data);
                        },
                        onError: function () {
                        }
                    }
                );
            },

            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrievePerson();
                };

                var retrievePerson = function () {
                    fn.wfeApiUser.retrieveUser(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.accountUser, data);
                                populateAccountUserModel();
                            },
                            onError: function () {
                            }
                        }
                    );
                };

                startRetrievalScopeObject();

                var populateAccountUserModel = function () {
                    $scope.contact = $scope.accountUser.Contact;

                    var PrimaryEmailAddress = $filter('filter')($scope.contact.Contacts, {
                        ContactType: 0,
                        IsPreferred: 'true'
                    })[0];
                    if (PrimaryEmailAddress) {
                        $scope.contact.PrimaryEmailAddress = PrimaryEmailAddress.Value;
                    }

                    var PrimaryPhoneNumber = $filter('filter')($scope.contact.Contacts, {
                        ContactType: 1,
                        IsPreferred: 'true'
                    })[0];
                    if (PrimaryPhoneNumber) {
                        $scope.contact.PrimaryPhoneNumber = PrimaryPhoneNumber.Value;
                    }

                    if ($scope.accountUser.Address) {
                        $scope.phAddress = $scope.accountUser.Address.Addresses[0];
                        $scope.phAddress.CountryId = $scope.phAddress.CountryId.toString();
                        $scope.phAddress.DistrictId = $scope.phAddress.DistrictId.toString();
                    }

                    $scope.policyContact = $scope.accountUser.Person;
                    $scope.policyDOB = $scope.policyContact.DateOfBirth.split("-");
                };
            },

            saving: {
                updateProfile: function () {
                    fn.wfeApiUser.updateProfile({
                        onSuccess: function () {
                            $scope.MyProfileScope.setDisabled(true);
                            fn.retrievePerson();
                        },
                        onError: function () {
                            $scope.MyProfileScope.setDisabled(false);
                            fn.retrievePerson();
                        }
                    });
                },

                setPrimaryEmailAddress: function () {
                    angular.forEach($scope.contact.Contacts, function (item) {
                        if (item.ContactType === 0 && item.IsPreferred) {
                            item.Value = $scope.contact.PrimaryEmailAddress;
                        }
                    });
                },

                setPrimaryPhoneNumber: function () {
                    angular.forEach($scope.contact.Contacts, function (item) {
                        if (item.ContactType === 1) {
                            if ($scope.contact.PrimaryPhoneNumber === item.Value) {
                                item.IsPreferred = true;
                            } else {
                                item.IsPreferred = false;
                            }
                        }
                    });
                }
            },

            validations: {
                validateForms: function () {
                    return $scope.frmAboutPerson.$invalid ||
                           $scope.frmAboutContact.$invalid ||
                           $scope.frmYourAdrees.$invalid;
                },

                hasInvalid: function hasInvalid() {
                    var isInvalid = false;
                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    } else if (CountryMotorcycleQuoteValidation.isInvalidOccupation($scope.policyContact.OccupationId)) {
                        isInvalid = true;
                        DaModal.open('#occupationValidation');
                    }

                    return isInvalid;
                }
            },

            eventHandlers: {
                addNewPhoneNumber: function () {
                    var len = $scope.contact.Contacts.length - 1;
                    if(typeof $scope.contact.Contacts[len].Value === "undefined"){
                        //$scope.contactNoErr = true;
                        DaModal.open('#mobileNotComplete');
                        return;
                    }else if($scope.contact.Contacts[len].Value.length < 10){
                        //$scope.contactNoErr = true;
                        DaModal.open('#mobileNotComplete');
                        return;
                    }

                    AccountUser.addNewPhoneNumber($scope.contact, {});
                },
                removePhoneNumber: function (i) {
                    var counter = 0;
                    var index = 0;
                    var preferredPhoneDeleted = false;

                    angular.forEach($scope.contact.Contacts, function (item) {
                        if (item.ContactType === 1) {
                            if (counter === i) {
                                $scope.contact.Contacts.splice(index, 1);
                                if(item.IsPreferred === $scope.contact.PrimaryPhoneNumber){
                                    preferredPhoneDeleted = true;
                                }
                            }
                            counter++;
                        }
                        index++;
                    });

                    var activePhoneNo = $filter('filter')($scope.contact.Contacts, {
                        ContactType: 1,
                    });

                    if (activePhoneNo.length === 1 || preferredPhoneDeleted){
                        $scope.contact.PrimaryPhoneNumber = activePhoneNo[0].Value;
                    }
                },
                BtnSaveClicked: function () {
                    $scope.pageSubmitted = true;

                    if (fn.validations.hasInvalid()) {
                        FormValidation.focusOnError();
                        return;
                    }
                    fn.saving.setPrimaryPhoneNumber();
                    fn.saving.setPrimaryEmailAddress();
                    fn.saving.updateProfile();
                    try{
                        $window.dataLayer = $window.dataLayer || [];
                        var dataLayer = $window.dataLayer;
                        dataLayer.push({
                            'event': 'Save',
                            'category': 'Policy',
                            'action': 'Save',
                            'label': 'Save'
                        });
                    }
                    catch(err){

                    }
                },
                checkPreferredPhone: function(r) {
                    var phoneNoTotal = $filter('filter')($scope.contact.Contacts, {
                        ContactType: 1,
                    });

                    if (phoneNoTotal.length === 1){
                        $scope.contact.PrimaryPhoneNumber = r;
                    }
                }
            }
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();
    }];