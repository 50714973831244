/*
 * Created by Chanon Tangputthajit.xx
 */
module.exports = [
    '$anchorScroll',
    '$scope',
    '$rootScope',
    '$state',
    '$timeout',
    'MITDate',
    'HKLocale',
    'WfeApiStaticData',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'DaModal',
    'HKFormValidation',
    'CountryMotorQuoteValidation',
    'WfeApiUser',
    'WfeApiContact',
    'WfeApiAddress',
    '$filter',
    'WfeApiLog',
    'WfeApiEssential',
    '$window',
    '$cookies',
    '$sessionStorage',
    'WfeApiCoverages',
    'StaticDataKeyValuePair',
    'AppConstants',
    /**
     * Controller for the Basic Details of Motor Quote
     * @constructor
     */
        function SinglePageAppController($anchorScroll, $scope, $rootScope, $state, $timeout, MITDate, HKLocale, WfeApiStaticData, WfeApiCurrentProposal,
                                           WfeApiAsset, WfeApiPolicyContact, WfeApiQuote, DaModal, HKFormValidation, CountryMotorQuoteValidation,
                                           WfeApiUser, WfeApiContact, WfeApiAddress, $filter, WfeApiLog, WfeApiEssential, $window, $cookies, $sessionStorage, WfeApiCoverages, StaticDataKeyValuePair, AppConstants) {
        $anchorScroll();
        var marketing = {
            ga: function () {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/get-quote/step-1/'}
                );
            },
            ga_next: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/your-quote/step-2/'}
                );
            },
            ga_next_review: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/your-policy/step-3/'}
                );
            },
            dLCE:function(ref,v)
            {

                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                switch(ref)
                {
                    case 1:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'manufacture-year':v
                        });
                        break;
                    case 2:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'car-make':v
                        });
                        break;
                    case 3:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'car-model':v
                        });
                        break;
                    case 4:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'car-description':v
                        });
                        break;
                    case 5:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-date-of-birth':v
                        });
                        break;
                    case 6:
                        var s='';
                        if(v==1 || v== '1') {s='Female'};
                        if(v==2 || v== '2') {s='Male'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-gender':s
                        });
                        break;
                    case 7:
                        var s='';
                        if(v==1 || v== '1' || v== "1") {s='Single'};
                        if(v==2 || v== '2' || v== "2") {s='Married'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-marital':s
                        });
                        break;
                    case 8:
                        var s='';
                        if(v==true || v== '1' || v==1) {
                            s='Yes/Sometimes';
                            marketing.dLCE(9,undefined); //reset the value of case 9;

                        };
                        if(v==false || v== '0' || v==0) {
                            s='No';
                            marketing.dLCE(9,false); //also set case 9 to no
                        };
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-drive-to-work':s
                        });

                        break;
                    case 9:
                        var s=undefined;
                        if(v==true || v== '1' || v==1) {s='Yes/Sometimes'};
                        if(v==false || v== '0' || v==0) {s='No'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'maindriver-use-for-work':s
                        });
                        break;

                    case 10:
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'type-of-coverage':v
                        });
                        break;

                    case 11:
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'add-compulsory-insurance':v
                        });
                        break;

                    case 12:
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'deductible-amount':v
                        });
                        break;

                    case 13:
                        var s='';
                        if(v==1000003 || v== '1000003') {s='0 %'};
                        if(v==1000005 || v== '1000005' ) {s='20%'};
                        if(v==1000006 || v== '1000006' ) {s='30%'};
                        if(v==1000007 || v== '1000007' ) {s='40%'};
                        if(v==1000008 || v== '1000008' ) {s='50%'};
                        if(v==2500000 || v== '2500000' ) {s='I don\'t know'};
                        if(v==1000009 || v== '1000009' ) {s='I don\'t know'};

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'no-claims no claims bonus':s
                        });
                        break;
                    case 14:
                        var s='';
                        if(v==1 || v== '1') {s='0'};
                        if(v==2 || v== '2' ) {s='1'};
                        if(v==3 || v== '3' ) {s='2'};
                        if(v==4 || v== '4' ) {s='3'};
                        if(v==5 || v== '5' ) {s='4'};
                        if(v==1000000 || v== '1000000' ) {s='5'};
                        if(v==1000001 || v== '1000001' ) {s='more than 5'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'driving years of driving exp':s
                        });
                        break;

                    case 45:dataLayer.push({
                            'event':'dataLayer-initialised',
                            'policy-number':v
                        });
                        break;
                    case 46:
                        if(v === true){
                            s = 'Yes';
                        }else{ 
                            s = 'No';
                        }
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'equipped-with-camera':s
                        });
                        break;
                    case 47:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'MakeModelNotCovered':v
                        });
                        break;
                    case 48:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'UnderwritingError':v
                        });
                        break;
                    case 50:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'Sum-Insure-level':v
                        });
                        break;
                    default: break;
                }
            }
        };

        var fn = {

            gtAnchor:function(x){
                if (!x)
                {return;}

                HKFormValidation.animateToID(x);
            },

            setSourceChannels:function(){

                /* sourcechannel details */
                var sc={};
                sc.id=0;sc.cd='';
                sc=$scope.$parent.sourceChanneler(sc);

                $scope.asset.SourceChannelId=sc.id;
                $scope.asset.ChannelDetails=sc.cd;
                if($scope.asset.ChannelDetails === 'gobear'){
                    $scope.asset.LeadTypeId = 7000009;
                }else if($scope.asset.ChannelDetails === 'interspace' ){
                    $scope.asset.LeadTypeId = 7000017;
                }else if($scope.asset.ChannelDetails === 'TrueAnalytics' ){
                    $scope.asset.LeadTypeId = 7000018;
                }else if($scope.asset.ChannelDetails === 'FacebookLeadAds' ){
                    $scope.asset.LeadTypeId = 7000019;
                }else if($scope.asset.ChannelDetails === 'GoogleLeadAds' ){
                    $scope.asset.LeadTypeId = 7000020;
                }else if($scope.asset.ChannelDetails === 'ResponseConcepts' ){
                    $scope.asset.LeadTypeId = 7000021;
                }else if($scope.asset.ChannelDetails === 'Chobrod' ){
                    $scope.asset.LeadTypeId = 7000022;
                }else if($scope.asset.ChannelDetails === 'Unseencar' ){
                    $scope.asset.LeadTypeId = 7000023;
                }else if($scope.asset.ChannelDetails === '3BB' ){
                    $scope.asset.LeadTypeId = 7000024;
                }else if($scope.asset.ChannelDetails === 'Shell' ){
                    $scope.asset.LeadTypeId = 7000025;
                }else if($scope.asset.ChannelDetails === 'SUSCO' ){
                    $scope.asset.LeadTypeId = 7000026;
                }else{  
                    $scope.asset.LeadTypeId = 7000004;
                }

            },

            getMaxarPriority:function(action){

                var cbFlag, finalDataFlag;
                if(action == "cb"){
                    cbFlag = 'Yes';                    
                    finalDataFlag = 'N'
                }else if(action == "s&e"){
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                }else{
                    cbFlag = 'No';
                    finalDataFlag = 'N'
                }

                fn.postToMaxParent(cbFlag, $scope.newMaxarPrior ,finalDataFlag);
                //console.log("priority= " + $scope.newMaxarPrior + ", cb= " + cbFlag + ", finalData= " + finalDataFlag);
            },

            setAssetMaxarPriority:function(action){

                var cbFlag, finalDataFlag;
                if(action == "cb"){
                    cbFlag = 'No';                    
                    finalDataFlag = 'Y'
                }else if(action == "s&e"){
                    cbFlag = 'No';
                    finalDataFlag = 'Y'
                }else{
                    cbFlag = 'No';
                    finalDataFlag = 'N'
                }
                //DATH-1137
                var firstStar, secondStar;
                if(cbFlag == 'No'){
                    firstStar = "";
                }else{
                    firstStar = "*";
                }
                if(finalDataFlag == 'Y'){
                    secondStar = "*";
                }else{
                    secondStar = "";
                }
                $scope.asset.MaxarPriority = $scope.newMaxarPrior + firstStar + secondStar;
            },

            populateMaxar:function(){
                var date = new Date();
                var tmp = $scope.mainDriver.DateOfBirth.split("-");
                var dateOfBirthMaxar = tmp[2] + "-" + tmp[1] + "-" + tmp[0] + " 00:00";

                var sd='';
                try{
                    sd=('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear()+ " " + ('0'+date.getHours()).slice(-2) + ":" + ('0'+date.getMinutes()).slice(-2);
                }
                catch(e)
                {
                    sd="01-01-2017 00:00";
                }

                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                var startDate= sd;
                var yearManufacturer=$scope.asset.PurchasingYear;
                var make=$scope.$parent.filterStatic($scope.staticData.makes,$scope.asset.ManufactureId);
                var model=$scope.$parent.filterStatic($scope.staticData.models,$scope.asset.ModelId);
                var description=$scope.$parent.filterDesc($scope.staticData.cardescriptions,$scope.asset.CarDescriptionId);
                var dateBirth=dateOfBirthMaxar;
                var Gender=$scope.$parent.filterStatic($scope.staticData.genders,$scope.mainDriver.GenderId);
                var maritalStatus=$scope.$parent.filterStatic($scope.staticData.maritalStatuses,$scope.mainDriver.MaritalStatusId);
                var driveWork=$scope.$parent.filterStatic($scope.staticData.booleansSometimes,$scope.asset.InDriveWork);
                var useWork=$scope.$parent.filterStatic($scope.staticData.booleansSometimes,$scope.asset.InCourseWork);

                maxarLayer.push(
                    {
                        'startDate':startDate,
                        'yearManufacture':yearManufacturer,
                        'make':make,
                        'model':model,
                        'description':description,
                        'dateBirth':dateBirth,
                        'gender':Gender,
                        'maritalStatus':maritalStatus,
                        'driveWork':driveWork,
                        'useWork':useWork
                    }
                );
            },

            postToMaxParent:function(callBackStatus,Priority,FinalCall){
                $sessionStorage.maxarReference=$scope.phContact.PrimaryPhoneNumber; 
                var ref_id='';
                var firstname='';
                var ed='';
                var ga_source='';var ga_campaign='';var ga_medium='';
                var ck=$cookies["__utmz"];
                if(ck)
                {
                    var z = ck.split('.');
                    if(z.length >= 4){
                        var y = z[4].split('|');
                        for(var i=0; i<y.length; i++){
                            if(y[i].indexOf('utmcsr=') >= 0) ga_source = y[i].substring(y[i].indexOf('=')+1);
                            if(y[i].indexOf('utmccn=') >= 0) ga_campaign = y[i].substring(y[i].indexOf('=')+1);
                            if(y[i].indexOf('utmcmd=') >= 0) ga_medium = y[i].substring(y[i].indexOf('=')+1);
                        }
                    }

                } else{
                    // Use DA Cookie instead for maxar 
                    ga_source=$cookies["dac_gacsr"] || '';
                    ga_campaign=$cookies["dac_gaccn"] || '';
                    ga_medium=$cookies["dac_gacmd"] || '';

                    
                    /* ga_source = $scope.asset.SourceChannelId;
                    ga_medium = $scope.asset.ChannelDetails;
                    ga_campaign = $scope.$parent.gaCampaign;  */
                } 
                
                //$scope.asset.LeadTypeId = 7000001;
                if(ga_source === 0 || ga_source === null || ga_source === "") ga_source = "-";
                if(ga_medium === 0 || ga_medium === null || ga_medium === "") ga_medium = "-";
                if(ga_campaign === 0 || ga_campaign === null || ga_campaign === "") ga_campaign = "-";

                var typeCoverage='';
                if($scope.coverages.Coverages)
                {
                    for(var i=0;i<=$scope.coverages.Coverages.length;i++)
                    {
                        if($scope.coverages.Coverages[i].IsSelected)
                        {
                            typeCoverage=$scope.coverages.Coverages[i].Name;
                            break;
                        }
                    }
                }
                $window.maxarLayer = $window.maxarLayer || [];
                var date = new Date();
                var maxarLayer = $window.maxarLayer;

                if(!$scope.phContact.PrimaryPhoneNumber){
                    ref_id = '0000000000';
                }else{
                    ref_id = $scope.phContact.PrimaryPhoneNumber;
                }
                
                if(!$scope.policyHolder.FirstName){
                    firstname = '-';
                }else{
                    firstname = $scope.policyHolder.FirstName;
                }
                try{
                   ed=('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear()+ " " + ('0'+date.getHours()).slice(-2) + ":" + ('0'+date.getMinutes()).slice(-2);
                }
                catch(e)
                {
                   ed="01-01-2017 00:00";
                }
                var sourcePageVal = '';
                var language = HKLocale.getLocale();
                if($sessionStorage.currentPage == 2){
                    sourcePageVal = 'Your Quote';
                }else if($sessionStorage.currentPage == 3){
                    sourcePageVal = 'Review';
                }else{
                    sourcePageVal = 'Your Quote';
                }

                if(callBackStatus == 'Yes' || sourcePageVal == 'Review'){
                    var currentHour = date.getHours();
                    var currentDay  = date.getDay();
                    console.log("h:" + currentHour + "/ d: " + currentDay);
                    if(((currentHour > 8 && currentHour < 12) || (currentHour > 13 && currentHour < 18))
                        && (currentDay >= 1 && currentDay <= 5)){ 
                        // 9:00 - 11:59, 14:00 - 17:59
                        // Mon - Fri only
                        language = 'th-hl';
                    }
                }

                maxarLayer.push(
                    {
                        typeCoverage:typeCoverage,
                        compulsory:$scope.IsCompulsarySelectedFrontEnd,
                        totalPremium: $scope.totalPremium2,
                        consent:$scope.consentPrivacyFlag,
                        message:$scope.callbackObj.message,
                        commercial:$scope.callbackObj.commercial,
                        referenceID:ref_id,
                        endDate: ed,
                        firstName:firstname,
                        lastName:'-',
                        email:$scope.phContact.PrimaryEmailAddress,
                        telephone:ref_id,
                        type:'Quote lead',
                        sourcePage: sourcePageVal,
                        language:language,
                        callback:callBackStatus,

                        blank1:$scope.asset.IsCarCameraInstalled,
                        priority:Priority,
                        dataFinal:FinalCall,

                        //------- Modify for new maxar prioritize------------------
                        quoteNumber: $scope.currentProposal.ExternalProposalNumber,
                        sourceChannel:ga_source,
                        channelDetail:ga_medium,
                        gaCampaign:ga_campaign,

                    }
                );
                /* fn.assetApi.updateAsset(
                    {
                        onSuccess: function(){
                            console.log($scope.asset);
                            fn.essentialApi.partialSave();
                        },
                        onError: function(){
    
                        }
                    }
                ); */
                fn.postACT();
                $scope.$parent.postMaxar();
            },

            postACT: function(){
                var identifier = $scope.currentProposal.ExternalProposalNumber;
                var clickid = $cookies["dac_actclickid"];
                if(clickid){
                    var ACTLayer =  { 
                                        'clickid': clickid,
                                        'identifier': identifier.substr(5)
                                        };
                    $scope.acctImageURL = "https://cv.accesstrade.in.th/cv.php?mcn=8d34201a5b85900908db6cae92723617&result_id=1&rk=" + ACTLayer.clickid + "&identifier=ACT" + ACTLayer.identifier
                    $scope.acctImageLink = '<img src="' + $scope.acctImageURL + '" style="display: none;" />';
                    /* $.get("https://cv.accesstrade.in.th/cv.php?mcn=8d34201a5b85900908db6cae92723617&result_id=1&rk=" + ACTLayer.clickid + "&identifier=ACT" + ACTLayer.identifier
                        ,function(data){
                            console.log(data);
                        }); */

                }

            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;
                var wfeApiStaticDataYear = fn.wfeApiStaticDataYear;

                var startRetrieval = function () {
                    getBooleanListWithSometimes();
                };

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getCampaignSourceList();
                        },

                        onError: function () {
                            getCampaignSourceList();
                        }
                    });
                };

                var getCampaignSourceList = function () {
                    wfeApiStaticData.getCampaignSourceList({
                        onSuccess: function (data) {
                            $scope.staticData.marketingStrategies = data;
                            getMakeList();
                        },

                        onError: function () {
                            getMakeList();
                        }
                    });
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            /* highlight few models on top */
                            filteredMake = $filter('filter')(data, function(r){
                                var notAcceptMake = ["1000001",
                                "1000046",
                                "1000007",
                                "1000047",
                                "1000008",
                                "1000071",
                                "1000009",
                                "1000011",
                                "1000092",
                                "1000050",
                                "1000018",
                                "1000099",
                                "1000101",
                                "1000093",
                                "1000032",
                                "1000035",
                                "1000053",
                                "1000036",
                                "1000086",
                                "1000038",
                                "1000066",
                                "1000039",
                                "1000087",
                                "1000088",
                                "1000041",
                                "1000098",
                                "1000005",
                                "1000027",
                                "1000002",
                                "1000004",
                                "1000102",
                                "1000096",
                                "1000010",
                                "1000097",
                                "1000103",
                                "1000020",
                                "1000023",
                                "1000024",
                                "1000104",
                                "1000094",
                                "1000065",
                                "1000100",
                                "1000105"
                                ];
                                if(!notAcceptMake.includes(r.key)){
                                    //console.log(r.key);
                                    return r.key;
                                }
                            });
                            
                            data = filteredMake;
                            if(data) {
                                var repeatedData = [];
                                repeatedData.push({key: "1000091", value: "Toyota"});
                                repeatedData.push({key: "1000015", value: "Honda"});
                                repeatedData.push({key: "1000031", value: "Nissan"});
                                repeatedData.push({key: "1000025", value: "Mazda"});
                                repeatedData.push({key: "1000080", value: "Isuzu"});
                                repeatedData.push({key: "1000028", value: "Mitsubishi"});
                                repeatedData.push({key: "0000000", value: "--------------"});

                                $scope.staticData.makes = repeatedData.concat(data);
                            }
                            else
                            {
                                $scope.staticData.makes = data;
                            }
                            getGenderList();
                            fn.loadWidgetValue();
                        },

                        onError: function () {
                            getGenderList();
                        }
                    });
                };

                var getGenderList = function () {

                    if($scope.HKLocale.getLocale() === "th-th"){
                        var data = [{"key":"2","value":"ชาย"}, {"key":"1","value":"หญิง"}];
                    }else{
                        var data = [{"key":"2","value":"Male"}, {"key":"1","value":"Female"}];
                    }
                    $scope.staticData.genders = data;
                    getMaritalStatusList();
                };

                var getMaritalStatusList = function () {

                    if($scope.HKLocale.getLocale() === "th-th"){
                        var data = [{"key":"1","value":"โสด"},{"key":"2","value":"สมรส"}];
                    }else{
                        var data = [{"key":"1","value":"SINGLE"},{"key":"2","value":"MARRIED"}];
                    }
                    $scope.staticData.maritalStatuses = data; 
                    getDatesPartsDD();
                };

                var getDatesPartsDD = function () {

                    wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },

                        onError: function () {
                            getDatesPartsMM();
                        }
                    });
                };
                var getDatesPartsMM = function () {

                    wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;
                            getDatesPartsYY();
                        },

                        onError: function () {
                            getDatesPartsYY();
                        }
                    });
                };
                var getDatesPartsYY = function () {

                    wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            getDriverExperienceList();
                        },

                        onError: function () {
                            getDriverExperienceList();
                        }
                    });
                };

                var getDriverExperienceList = function () {
                    wfeApiStaticData.getDriverExperienceList({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                            //getNoClaimDiscountList();
                            //getGenderList();
                        },

                        onError: function () {
                            //getNoClaimDiscountList();
                            //getGenderList();
                        }
                    });
                };

                startRetrieval();

            },

            initScopeObjects: function () {
                $scope.staticData = {};

                var dateTomorrow = MITDate.newDate().add(1, 'days');
                $scope.currentProposal = {
                    PolicyStartDate: MITDate.toAngularDate(dateTomorrow),
                    PolicyEndDate: MITDate.toAngularDate(dateTomorrow.add(1, 'years'))
                };
                $scope.asset = {
                    HasAntiTheftDevice: false,
                    'LobInsuranceFormulaId': 2000015, // to be removed later
                    UsageId: 7000000

                };

                var dateToday = MITDate.newDate();
                $scope.yearNow=(parseInt(MITDate.getYYYY(dateToday))-9) || 1941;

                $scope.asset.ProductId = $scope.$parent.product.ProductId;

                $scope.policyHolder = {};
                $scope.mainDriver = {};

                $scope.accountUser = {};
                $scope.phAddress = {};
                $scope.phContact = {};

                $scope.bidata = {};
                $scope.errdata = {};
                $scope.callbackObj={};
                $scope.securityQuestion = {};
                $scope.isSecurityQuestion = false;
                $scope.previousPage = 0;
                $scope.DOBEmpty = false;
                $scope.loadingSpinner = true;
                $scope.loadingSpinner2 = true;
                $scope.loadingSpinner3 = true;
                $scope.partialPolicyNumber = "";
                $scope.applyNCB;
                $scope.isStartQuote = false;
                $scope.isSaveAndExitShowName = true;
                $scope.txtMaker = fn.getTranslateText('maker');
                $scope.txtModel = fn.getTranslateText('model');
                $scope.txtYear  = fn.getTranslateText('year');
                $scope.txtDesc  = fn.getTranslateText('desc');
                param = 1000003;
                $scope.showMainDriverFlag = false;
                $scope.showContactInfoFlag = false;
                $scope.firstPageShow = true;
                $scope.secondPageShow = false;
                $scope.thirdPageShow = false;
                $scope.showPlanFlag = false;
                $scope.coverNum = 0;
                $scope.sumInsured={};
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;
                $scope.chkMaker = false;
                $scope.chkModel = false;
                $scope.chkYear  = false;
                $scope.chkDesc  = false;
                $scope.chkCamera = false;
                $scope.bodyType = '';// set 4 doors as default
                $scope.gearType = ''; //Set manual as default
                $scope.acctImageURL = '';
                $scope.acctImageLink = '';
                //$scope.hash = window.location.hash;
                //$sessionStorage.currentPage = 1;
                $scope.loc='';
                $scope.loc=HKLocale.getLocale();
                if($scope.loc=='en-us') {
                    $scope.policyHolder.PreferredLanguageId=101;
                }
                if($scope.loc=='th-th') {
                    $scope.policyHolder.PreferredLanguageId=2000000;
                }

                $scope.basicCoverageInfo = {
                    "2000015":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',

                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',

                        ]};

                $scope.basicCoverageInfo_Part2 = {
                    "2000015":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000016":
                        [

                            'body_basic_coverage_info_payment'
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_payment'
                        ]};
                $scope.tyepOfCoverageTable = {
                    "body_popup_cover_item1":[true, true, true, false, false],
                    "body_popup_cover_item2":[true, true, true, true, true],
                    "body_popup_cover_item3":[true, true, false, true, false],
                    "body_popup_cover_item4":[true, true, false, false, false],
                    "body_popup_cover_item5":[true, false, false, false, false],
                    "body_popup_cover_item6":[true, true, true, false, false]
                };

                $scope.customInsuranceAmount = 0;
                $scope.selectedInsureAmount = 0;
                $scope.carAge = 0;
                $scope.consentPrivacyFlag = false;
            },

            initApiObjects: function () {
                /* console.log($scope.$parent.sessionApi);
                console.log($scope.currentProposal); */

                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.yearNow);
                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress);

                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, $scope.errdata);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

            },

            /** Subscribe MKT email condition */
            initSubscribeWatcher:function()
            {
                $scope.$watch('policyHolder.HasUnsubscribedMarketingEmail', function(value) {
                    $scope.policyHolder.SubscribedMarketingEmail = !value;
                });
                $scope.$watch('policyHolder.SubscribedMarketingEmail', function(value) {
                    $scope.policyHolder.HasUnsubscribedMarketingEmail = !value;
                });

                $scope.$watch('totalPremium2', function(val){  //$scope.totalPremium2
                    if($sessionStorage.currentPage == 2 || $sessionStorage.currentPage == 3){
                        if(val > 0 ){
                            //fn.utils.sendMaxarNewPrior(val);
                            fn.utils.sendMaxarNewPriorV2();
                            fn.getMaxarPriority();  
                            fn.saving.saveCoveragesModel(
                                {
        
                                    onSuccess: function () {
                                        fn.logApi.updateLog();
                                        // DATH-1137
                                        fn.setAssetMaxarPriority();
                                        fn.updateAssetMaxarPriority();
                                        if($scope.selectedCoverage.CoverTypeId == 2000017 || 
                                            $scope.selectedCoverage.CoverTypeId == 2000019){
                                            fn.quoteApi.getInsuranceAmount(
                                                {
                                                    onSuccess: function(data){
                                                        if(data && data[0].value){
                                                            $scope.customInsuranceAmount = data;
                                                            fn.setDefaultInsureAmount();
                                                        }
                                                    },
                                                    onError: function(){
                                                    }
                                                }
                                            );
                                        }
                                        
                                    },
                                    onError: function () {
                                    }
                                }
                            );
                            
                        } 
                    }          
                });


                $scope.$watch(function(){return window.location.hash }, function(val){
                    if(val == "#your-quote"){
                        if($sessionStorage.currentPage == 1 && $scope.previousPage != 0){

                            fn.eventHandlers.onBtnCalculatePremiumClicked();
                        }else{
                            fn.goToPage(2);
                        }
                    }else if(val == "#review"){
                        if($sessionStorage.currentPage == 1 && $scope.previousPage != 0){

                            fn.eventHandlers.onBtnCalculatePremiumClicked();
                        }else{
                            fn.goToPage(3);
                        }
                    }
                    var currPage = $sessionStorage.currentPage;
                    var prevPage = $scope.previousPage;

                });

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        destroyWatcher();
                    }
                );
            },

            retrieveScopeObjects: function () {

                /** Fire to GA that customer has been visited page */
                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                try {

                                    marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveAsset();
                            },
                            onError: function () {
                                try {
                                    marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);

                                $scope.applyNCB = $scope.$parent.applyNCBRoot;
                                if($scope.$parent.applyNCBRootDontKnow){
                                    $scope.asset.NcdLevelId="1000009";
                                }
                                    
                                    if ($scope.asset.ManufactureId) {
                                        $scope.txtMaker = $filter('filter')($scope.staticData.makes, {key: $scope.asset.ManufactureId})[0].value;
                                        fn.eventHandlers.onDropMakeChanged($scope.asset.ManufactureId);
                                        $scope.asset.PurchasingYear = $scope.asset.PurchasingYear.toString();
                                    }
                                    
                                    if ($scope.asset.ModelId) {
                                        fn.eventHandlers.onDropModelChanged($scope.asset.ManufactureId, $scope.asset.ModelId);
                                        
                                        var objParams = {};
                                        objParams.manufacturerId = $scope.asset.ManufactureId;
                                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                                        wfeApiStaticDataModel.getModelListV2({
                                            onSuccess: function (data) {
                                                fn.assetStaticData.model = data;
                                                $scope.txtModel = $filter('filter')(fn.assetStaticData.model, {key: $scope.asset.ModelId})[0].value;
                                            }
                                        });
                                        
                                    }
                                    if ($scope.asset.PurchasingYear){
                                        fn.eventHandlers.onDropPurchasingYearChanged($scope.asset.ModelId, $scope.asset.PurchasingYear);
                                        $scope.txtYear = $scope.asset.PurchasingYear;
                                    }
                                    if ($scope.asset.CarDescriptionId){
                                        var objParams = {};
                                        objParams.modelId = $scope.asset.ModelId;
                                        objParams.purchasingYear = $scope.asset.PurchasingYear;
                                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                    
                                        wfeApiStaticDataModel.getCarDescWithYear({
                                            onSuccess: function (data) {
                                                fn.assetStaticData.description = data;
                                                $scope.txtDesc = $filter('filter')(fn.assetStaticData.description, {key: $scope.asset.CarDescriptionId})[0].description;
                                            }
                                        });
                                    }
                                
                                retrievePHAddress();
                                
                            },
                            onError: function () {
                                retrievePHAddress();
                            }
                        }
                    );
                };

                var retrievePHAddress = function () {
                    fn.phAddressApi.retrievePHAddress(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phAddress, data);
                                retrievePHContact();
                            },
                            onError: function () {
                                retrievePHContact();
                            }
                        }
                    );
                }

                var retrievePHContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                retrievePolicyHolder();
                                // for recover coverage in SPA 
                                if($sessionStorage.currentPage == 2 || $sessionStorage.currentPage == 3){
                                    fn.updatesCoveragesSPA();
                                }
                            },
                            onError: function () {
                                retrievePolicyHolder();
                            }
                        }
                    );
                }

                /** PolicyContact  Retrieval : this will retrieve MD if PH is not MD */
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data, $scope.policyHolder);
                                }

                                angular.extend($scope.policyHolder, data);
                                if($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                    $scope.policyHolder.SubscribedMarketingEmail=false;
                                } else {
                                    $scope.policyHolder.SubscribedMarketingEmail=true;
                                }

                                $scope.loadingSpinner = false;
                                $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);

                                if (data === undefined) {
                                    retrieveMainDriver();
                                }

                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                }
                                else {
                                    retrieveMainDriver();
                                }

                                fn.retrieveAccountUserObjects();
                            },
                            onError: function () {
                                $scope.loadingSpinner = false;
                                $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);
                                fn.retrieveAccountUserObjects();
                            }
                        }
                    );
                };

                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.mainDriver, data);
                                
                                if($scope.mainDriver.DateOfBirth)
                                {
                                    fn.utils.ConvertMITDateToDisplay();
                                }
                                if ($scope.mainDriver.IsMainDriver) {
                                    $scope.policyHolder.IsMainDriver = false;
                                }
                            }
                        }
                    );
                };

                //retrievePolicyHolder();
                retrieveCurrentProposal();
            },

            // Customize normal flow to compatible with SPA
            updatesCoveragesSPA: function () {
                fn.coverageApi.retrieveCoverages(
                    {
                        onSuccess: function (data) {

                            /** Added by OB For temporary coverages sequence*/
                            var coverages = [];
                            if(data.CoverListsCount == 5){
                                var index = [2000017, 2000019, 2000016, 2000018, 2000020];
                                $scope.tyepOfCoverageTable = {
                                    "body_popup_cover_item1":[true, true, false, false],
                                    "body_popup_cover_item2":[true, true, true, true],
                                    "body_popup_cover_item3":[true, false, true, false],
                                    "body_popup_cover_item4":[true, false, false, false],
                                    "body_popup_cover_item5":[false, false, false, false],
                                    "body_popup_cover_item6":[true, true, false, false]
                                };
                            }else{
                                var index = [2000015, 2000017, 2000019, 2000016, 2000018, 2000020];
                                $scope.tyepOfCoverageTable = {
                                    "body_popup_cover_item1":[true, true, true, false, false],
                                    "body_popup_cover_item2":[true, true, true, true, true],
                                    "body_popup_cover_item3":[true, true, false, true, false],
                                    "body_popup_cover_item4":[true, true, false, false, false],
                                    "body_popup_cover_item5":[true, false, false, false, false],
                                    "body_popup_cover_item6":[true, true, true, false, false]
                                };
                            }
                            for(var j in index){
                                for(var i in data.Coverages){
                                    if(data.Coverages[i].CoverTypeId === index[j]){
                                        coverages[j] = data.Coverages[i];
                                    }
                                }
                            }

                            data.Coverages = coverages;
                            /** ------------------------ */

                            angular.extend($scope.coverages, data);
                            $scope.IsCompulsarySelectedFrontEnd=fn.utils.IsCompulsarySelectedFrontEndFn();
                            var selectedCoverage = fn.utils.getSelectedCoverage();
                            if (selectedCoverage) {
                                fn.utils.setSelectedCoverage(selectedCoverage);

                                /** Added by OB for selected coverages*/
                                //Test remove by jack 
                                
                                for(var i in selectedCoverage.Covers){
                                    if(selectedCoverage.Covers[i].CoverName === "Voluntary Basic Cover"){
                                        $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                        $scope.selectedCoverage.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                        if($scope.selectedCoverage.ExcessLevelId == undefined){
                                            
                                            // et default in case of reselect car info
                                            $scope.ExcessLevelId = 7000310;
                                            $scope.selectedCoverage.ExcessLevelId = 7000310;
                                            
                                        }
                                    }
                                }
                                /** ------------------------ */

                                
                            }
                            else
                            {
                                
                                fn.utils.setDefaultCoverage();
                                if($scope.coverages.CoverListsCount == 6){
                                    $scope.selectedCoverage.ExcessLevelId = 7000310;
                                }
                                
                            }

                            //fn.setDefaultInsureAmount();

                            fn.utils.setSumInsured();

                            $scope.coverages.isForUpdate = true;
                            if(selectedCoverage){
                                if(selectedCoverage.CoverTypeId == 2000020){
                                    $scope.isCompulsoryOnly = true;
                                }else{
                                    $scope.isCompulsoryOnly = false;
                                }

                            }else{
                                $scope.isCompulsoryOnly = false;
                            }
                            retrieveCurrentProposal();
                        },
                        onError: function () {
                            retrieveCurrentProposal();
                        }
                    }
                );

                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                try {
                                    //marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveExcess();
                            },
                            onError: function () {
                                try {
                                    //marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveExcess();
                            }
                        }
                    );
                };

                var retrieveExcess=function(){
                    fn.coverageApi.getVehicleExcess(
                        {
                            onSuccess: function (data) {
                                if(data)
                                {
                                    if($scope.coverages.CoverListsCount == 6){
                                        if(data[0].value){
                                            $scope.allExcess = data[0].value;
                                            $scope.selectedCoverage.excess = data;
                                        }
                                    }
                                    
                                }

                                $scope.loadingSpinner = false;
                                $scope.loadingSpinner2 = false;
                                $scope.loadingSpinner3 = false;
                                $scope.$parent.showPriceOnTopFlag = true;
                                $scope.allowBtnAction = true;
                                fn.populateMaxar();
                                fn.getMaxarPriority();
                                fn.eventHandlers.sendDefaultExcessGTM();
                            },
                            onError: function(){
                                $scope.loadingSpinner = false;
                                $scope.loadingSpinner2 = false;
                                $scope.loadingSpinner3 = false;
                                $scope.$parent.showPriceOnTopFlag = true;
                                $scope.allowBtnAction = true;
                            }
                        }
                    );
                };
            },

            retrieveAccountUserObjects: function () {
                var retrieveUser = function () {
                    if ($scope.$parent.isLoggedIn() && angular.equals({}, $scope.policyHolder)) {
                        fn.wfeApiUser.retrieveUser(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.accountUser, data);
                                    fn.utils.populateAccountUserModel();
                                },
                                onError: function () {
                                }
                            }
                        );
                    }
                };

                retrieveUser();
            },

            utils: {
                setSumInsured:function() {
                    
                                        $scope.sumInsured.value=0;
                                        $scope.sumInsured.visible=false;
                                        var sc = fn.utils.getSelectedCoverage();
                                        if(!sc) {return;}
                                        if(sc.CoverTypeId==2000018 || sc.CoverTypeId=='2000018')
                                        {
                                            /*Type 3 is always NA and hidden*/
                                            $scope.sumInsured.visible=false;
                                            $scope.sumInsured.value=0;
                    
                                            return;
                                        }
                    
                                        if(sc.CoverTypeId==2000019 || sc.CoverTypeId=='2000019'
                                            || sc.CoverTypeId==2000017 || sc.CoverTypeId=='2000017'
                                            || sc.CoverTypeId==2000015 || sc.CoverTypeId=='2000015') {
                    
                                            /*Type 2+/ 3+ / Type 1 - value from own damage collision*/
                                            /*Type 1- own damage collision value.*/
                                            var odc=$filter('filter')(sc.Covers, {
                                                ProductLineGroupId: 2000105
                                            },true);
                                            if(odc && odc[0].InsuranceAmount){
                    
                                                $scope.sumInsured.value=odc[0].InsuranceAmount;
                                                $scope.sumInsured.visible=true;
                                            }
                                            else {
                                                $scope.sumInsured.value=0;
                                                $scope.sumInsured.visible=false;
                                            }
                    
                                            return;
                                        }
                    
                                        if(sc.CoverTypeId==2000016 || sc.CoverTypeId=='2000016'){
                    
                                            /*Type 2- own damage Fire and theft value*/
                                            var odft=$filter('filter')(sc.Covers, {
                                                ProductLineGroupId: 2000106
                                            },true);
                                            if(odft && odft[0].InsuranceAmount){
                    
                                                $scope.sumInsured.value=odft[0].InsuranceAmount;
                                                $scope.sumInsured.visible=true;
                                            }
                                            else {
                                                $scope.sumInsured.value=0;
                                                $scope.sumInsured.visible=false;
                                            }
                                            return;
                                        }
                    
                                        if(sc.CoverTypeId==2000020 || sc.CoverTypeId=='2000020')
                                        {
                                            /*Type Compulsory only*/
                                            $scope.sumInsured.visible=false;
                                            $scope.sumInsured.value=0;
                    
                                            return;
                                        }
                    
                                        /*Exception- Everything fails- hide*/
                                        $scope.sumInsured.visible=false;
                                        $scope.sumInsured.value=0;
                    
                                    },

                setSelectedCoverage: function (selectedCoverage) {
                    $scope.selectedCoverage = selectedCoverage;
                    $scope.isBtnProceedDisabled = false;
                    $scope.isCoverSelected = true;
                },

                setDefaultCoverage: function()
                {
                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000015})[0];
                    if(selectedCoverage && $scope.carAge < 8) {
                        selectedCoverage.IsSelected = true;
                        selectedCoverage.ExcessLevelId = 7000310;
                        marketing.dLCE(10, 'Voluntary Type 1');
                        marketing.dLCE(11, true);
                    }else{
                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000017})[0];
                        selectedCoverage.IsSelected = true;
                        marketing.dLCE(10, 'Voluntary Type 2+');
                        marketing.dLCE(11, true);
                    }
                },

                IsCompulsarySelectedFrontEndFn:function() {
                    if(!$scope.coverages)return false;
                    var status=false;
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {

                        try{
                            var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                                ProductLineGroupId: 2000161,
                            }, true);
                            if (ccb) {
                                if (ccb[0].IsSelected) status= true;
                                else status= false;
                            }

                        }
                        catch (err)
                        {
                            status=false; return status;
                        }
                    }
                    return status;
                },

                getSelectedCoverage: function () {

                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {IsSelected: true})[0];

                    if (selectedCoverage) {
                        $scope.selectedCoverage = selectedCoverage;
                    }
                    else {
                        $scope.selectedCoverage = null;
                    }

                    return selectedCoverage;
                },

                /** Doesn't use now */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                autoSetEndDate: function () {

                    if (!$scope.currentProposal.PolicyStartDate) {
                        return;
                    }

                    var startDate = MITDate.newDate($scope.currentProposal.PolicyStartDate);
                    var endDate = startDate.add(1, 'years');
                    $scope.currentProposal.PolicyEndDate = MITDate.toAngularDate(endDate);

                },

                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                    //$scope.DaLoadingMask.toggle(isSaving);
                    //$scope.loadingSpinner = true;
                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;

                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    fn.utils.ConvertMITDateToDisplay();
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                },

                /** To push phContact and phAddress of existing user to scope object */
                populateAccountUserModel: function () {
                    angular.extend($scope.policyHolder, $scope.accountUser.Person);
                    $scope.policyHolder.IsMainDriver = true;
                    fn.utils.copyPHFieldsToMD();

                    if ($scope.accountUser.Address) {
                        var phAddress = $scope.accountUser.Address.Addresses[0];

                        if (phAddress) {
                            angular.extend($scope.phAddress, phAddress);
                            $scope.asset.ResidentialDistrictId = phAddress.ProvinceId;
                        }
                    }

                    if ($scope.accountUser.Contact) {
                        var contact = $scope.accountUser.Contact;

                        var PrimaryPhoneNumber = $filter('filter')(contact.Contacts, {
                            ContactType: 1,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryPhoneNumber) {
                            $scope.phContact.PrimaryPhoneNumber = PrimaryPhoneNumber.Value;
                        }

                        var PrimaryEmailAddress = $filter('filter')(contact.Contacts, {
                            ContactType: 0,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryEmailAddress) {
                            $scope.phContact.PrimaryEmailAddress = PrimaryEmailAddress.Value;
                        }
                    }
                },

                convertMITDate:function(){
                    if(typeof $scope.mainDriver.DOB_DD !== "undefined" &&
                       typeof $scope.mainDriver.DOB_MON !== "undefined" &&
                       typeof $scope.mainDriver.DOB_Year !== "undefined"){

                        $scope.$parent.setDateValidity($scope.mainDriver.DOB_DD,$scope.mainDriver.DOB_MON,$scope.mainDriver.DOB_Year,$scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.mainDriver.DateOfBirth=$scope.$parent.convertDateThreeToOne($scope.mainDriver.DOB_DD,$scope.mainDriver.DOB_MON,$scope.mainDriver.DOB_Year);
                        $scope.mainDriver.DateOfBirth==null?($scope.mainDriver.DateOfBirth=''):'';
                        $scope.validations.isValidDriverAge($scope.mainDriver.DateOfBirth,$scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.DOBEmpty = false;
                    }
                },

                ConvertMITDateToDisplay:function(){
                    if($scope.mainDriver.DateOfBirth)
                    {
                        var d=$scope.$parent.convertDateOneToThree($scope.mainDriver.DateOfBirth);
                        if(d!=null || d.length>0)
                        {
                            $scope.mainDriver.DOB_DD=d[0];
                            $scope.mainDriver.DOB_MON=d[1];
                            $scope.mainDriver.DOB_Year=d[2];
                        }
                    }


                },
                getPriceToShow:function(c){
                    if(!c) {return 0;}

                    var ccb=$filter('filter')(c.Covers, {
                        ProductLineGroupId: 2000161,
                        IsSelected:true
                    },true);
                    var total=0;
                    if(c.CalculatedPremium) {
                        total= c.CalculatedPremium;
                    }
                    if(ccb){if(ccb[0]){if(ccb[0].TotalPremiumToColl){total=total+ccb[0].TotalPremiumToColl;}}}

                    return total;
                },
                sendMaxarNewPrior: function(total){
                    var maxPrior = "";
                    /* leadtypeID
                    7000000 Website
                    7000001 Portal 
                    
                    PriorityIDs:
                    7000000 1
                    7000001 2
                    7000002 3
                    7000003 4
                    7000004 5*/
                    var selectedCoverage = fn.utils.getSelectedCoverage();

                    if( $scope.loc == 'th-th'){ // th-th
                        if(total > 0 && total <10000 && selectedCoverage.CoverTypeId != 2000015){
                            maxPrior = 4;
                            //$scope.asset.PriorityId = 7000003;
                        }else if(total >= 10000 && total < 15000){
                            maxPrior = 3;
                            //$scope.asset.PriorityId = 7000002;
                        }else if((total >= 15000) || (total > 0 && total <10000 && selectedCoverage.CoverTypeId == 2000015) ){
                            maxPrior = 5;
                            //$scope.asset.PriorityId = 7000004;
                        }
                    }else{ // en-us
                        maxPrior = 3;
                    }
                    if($sessionStorage.currentPage == 3){
                        maxPrior = 1;
                    }
                    if(total != 0 && total != undefined ){
                        //console.log('Total premium : ' + total + ' - Maxar Priority : ' + maxPrior);
                        $scope.newMaxarPrior = maxPrior;
                    }
                    
                },
                sendMaxarNewPriorV2: function(){
                    var sourceId = $scope.asset.SourceChannelId;
                    var selectedCoverage = fn.utils.getSelectedCoverage();
                    var priority_3 = [7000000, 7000002, 7000005, 7000006]; // Organic, Direct, Branded paid search, Email 
                    //var priority_5 = [7000001, 7000003, 7000004, 7000007, 7000008]; // Generic paid search, Social, Display, Referral, Other
    
                    if(selectedCoverage.CoverTypeId == 2000017){
                        maxPrior = 4;
                    }else{
                        if($sessionStorage.currentPage == 3){
                            maxPrior = 1;
                        }else if($sessionStorage.currentPage == 2){
                            if(priority_3.indexOf(sourceId) > -1){
                                maxPrior = 3
                            }else{
                                maxPrior = 5
                            }
                            /* else if(priority_5.indexOf(sourceId) > -1){
                                maxPrior = 5
                            } */
                        }
                    }
                    console.log("current priority: " + maxPrior);
                    $scope.newMaxarPrior = maxPrior;
                },
            },
            
            saving: {

                setUsageId: function () {
                    if (1 == 0) {
                        /*car desc is hidden, tarrif 320 cars*/
                        $scope.asset.UsageId = '7000000';
                    }
                    else {
                        if ($scope.asset.InDriveWork == false) {
                            $scope.asset.UsageId = '6';
                            return;
                        }

                        if ($scope.asset.InDriveWork == true) {
                            if ($scope.asset.InCourseWork == true) {
                                $scope.asset.UsageId = '1000002';
                                return;
                            }
                            else {
                                $scope.asset.UsageId = '1000000';
                            }
                        }
                    }
                },

                savePHContact: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                fn.setAssetMaxarPriority("s&e");
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function(){
                                        if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                        {
                                            fn.confirmationApi.createConfirmation(
                                                {
                                                    onSuccess: function () {
                                                        fn.utils.setIsSaving(false);
                                                        $scope.$parent.maxarCallbackTrigger = true;
                                                        /* Next action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.utils.sendMaxarNewPriorV2();
                                                        fn.getMaxarPriority("s&e");
                                                        DaModal.close('#quotationPartialSave');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        } else {
        
                                            fn.essentialApi.partialSave(
                                                {
                                                    onSuccess: function (data) {
                                                        fn.utils.setIsSaving(false);
                                                        $scope.$parent.maxarCallbackTrigger = true;
                                                        /* Next action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.utils.sendMaxarNewPriorV2();
                                                        fn.getMaxarPriority("s&e");
                                                        DaModal.close('#quotationPartialSave');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                });

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                saveCurrentProposalChildModels: function () {

                    /**
                     * Section 1 : Create necessary objects, then pre calculate.
                     * */

                    var hasError = false;
                    var triggerSave = function () {
                        saveAsset();
                    };

                    var saveAsset = function () {
                        // Force my workshop as false;
                        $scope.asset.UseMyWorkshop = false;
                        
                        fn.saving.setUsageId();
                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');

                        if(saveAssetFunc === "updateAsset"){
                            if($scope.asset.NcdLevelId != "1000003"){
                                $scope.asset.ReasonForNcdIsZero = "0";
                                $scope.asset.NcdLevelIdOnOtherCar = "0";

                            }
                        }

                        fn.assetApi[saveAssetFunc](
                            {
                                onSuccess: function (data) {
                                    savePH(); /*hold on to Contact PH for Email and Mobile later.*/
                                },
                                onError: function () {
                                    hasError = true;
                                    savePH();
                                }
                            }
                        );
                    };

                    var savePH = function () {
                        $scope.loc='';
                        $scope.loc=HKLocale.getLocale();

                        if($scope.loc=='en-us')
                        {
                            $scope.policyHolder.PreferredLanguageId=101;
                        }

                        if($scope.loc=='th-th')
                        {
                            $scope.policyHolder.PreferredLanguageId=2000000;
                        }

                        $scope.policyHolder.IsMainDriver = true;
                        if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }

                        if($scope.HKLocale.getLocale() === "en-us"){
                            $scope.policyHolder.PreferredLanguageId = 101;
                        }else{
                            $scope.policyHolder.PreferredLanguageId = 2000000;
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function (data) {
                                    saveMD();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveMD();
                                }
                            }
                        );
                    };

                    var saveMD = function () {
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');
                        if (!saveMDFunc) {
                            savePHContact();
                            return;
                        }

                        fn.mainDriverApi[saveMDFunc](
                            {
                                onSuccess: function () {
                                    savePHContact();
                                },
                                onError: function () {
                                    hasError = true;
                                    savePHContact()
                                }
                            }
                        );
                    };

                    var savePHContact = function () {
                        var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                        fn.phContactApi[savePHContactFunc](
                            {
                                onSuccess: function () {
                                    calculatePremium();
                                },
                                onError: function () {
                                    hasError = true;
                                    calculatePremium();
                                }
                            }
                        );
                    };

                    /** Doesn't use for now */
                    var savePHAddress = function () {
                        var savePHAddressFunc = fn.phAddressApi.utils.getSaveFunctionName($scope.phAddress, 'PHAddress');
                        fn.phAddressApi[savePHAddressFunc](
                            {
                                onSuccess: function () {

                                    if (!$scope.currentProposal.IsRenewalProposal) {
                                        fn.essentialApi.isRenewalProposalExist(
                                            {
                                                onSuccess: function (mitEssentialResponse) {
                                                    var result = mitEssentialResponse.IsRenewalProposalExist;
                                                    if (result) {
                                                        fn.utils.setIsSaving(false);
                                                        DaModal.open('#renewalProposalExistsMessage');
                                                    }
                                                    else {
                                                        calculatePremium();
                                                    }
                                                },
                                                onError: function () {
                                                    calculatePremium();
                                                }
                                            }
                                        );
                                    }
                                    else {
                                        calculatePremium();
                                    }
                                },
                                onError: function () {
                                    hasError = true;
                                    calculatePremium();
                                }
                            }
                        );
                    };

                    var calculatePremium = function () {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }

                        fn.quoteApi.calculate(
                            {
                                onSuccess: function(){
                                    // GA trigger only when Calculate success
                                    marketing.ga_next();
                                    retrieveCurrentProposal();
                                },
                                onError: function (data) {
                                    marketing.dLCE(48, 'Yes');
                                    fn.utils.setIsSaving(false);
                                    fn.goToPage(1);
                                    try {
                                        if (!$scope.currentProposal.IsRenewalProposal) {
                                            $scope.bidata = {
                                                Source: $scope.$parent.bi.whereDidYouHereUs
                                            };
                                            $scope.errdata = data;
                                            if ($scope.$parent.bi.whereDidYouHereUs) {
                                                fn.logApi.writeQuoteRejectLog();
                                            }
                                        }
                                    } catch (err) {

                                    }

                                    fn.eventHandlers.onDefaultErrorHandler();
                                }
                                
                            }
                        );

                    };

                    /**
                     * Section 2 : Retrieve necessary objects
                     * */

                    var retrieveCurrentProposal = function () {
                        fn.currentProposalApi.retrieveCurrentProposal(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.currentProposal, data);

                                    try {
                                        if (!$scope.currentProposal.IsRenewalProposal) {
                                            $scope.bi.whereDidYouHereUs='None';
                                            angular.extend($scope.bidata, {
                                                //Source: $scope.$parent.bi.whereDidYouHereUs // Use GA source instead
                                                Source: $cookies["dac_gacsr"] || 'None'
                                            });
                                            if ($scope.$parent.bi.whereDidYouHereUs && !$scope.$parent.bi.isDone) {
                                                fn.logApi.writeLog(
                                                    {
                                                        onSuccess: function () {
                                                        },
                                                        onError: function () {
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    } catch (err) {
                                    }

                                    // Try to send maxar after calculate
                                    fn.populateMaxar();

                                    retrieveAsset();
                                },
                                onError: function(){
                                    retrieveAsset();
                                }
                            }
                        );
                    };

                    var retrieveAsset = function () {
                        fn.assetApi.retrieveAsset(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.asset, data);
                                    retrieveCoverages();
                                },
                                onError: function () {
                                    retrieveCoverages();
                                }
                            }
                        );
                    };

                    var retrieveCoverages = function () {
                        fn.coverageApi.retrieveCoverages(
                            {
                                onSuccess: function (data) {

                                    /** Added by OB For temporary coverages sequence*/
                                    var coverages = [];
                                    if(data.CoverListsCount == 5){
                                        var index = [2000017, 2000019, 2000016, 2000018, 2000020];
                                        $scope.tyepOfCoverageTable = {
                                            "body_popup_cover_item1":[true, true, false, false],
                                            "body_popup_cover_item2":[true, true, true, true],
                                            "body_popup_cover_item3":[true, false, true, false],
                                            "body_popup_cover_item4":[true, false, false, false],
                                            "body_popup_cover_item5":[false, false, false, false],
                                            "body_popup_cover_item6":[true, true, false, false]
                                        };
                                    }else{
                                        var index = [2000015, 2000017, 2000019, 2000016, 2000018, 2000020];
                                        $scope.tyepOfCoverageTable = {
                                            "body_popup_cover_item1":[true, true, true, false, false],
                                            "body_popup_cover_item2":[true, true, true, true, true],
                                            "body_popup_cover_item3":[true, true, false, true, false],
                                            "body_popup_cover_item4":[true, true, false, false, false],
                                            "body_popup_cover_item5":[true, false, false, false, false],
                                            "body_popup_cover_item6":[true, true, true, false, false]
                                        };
                                    }
                                    
                                    for(var j in index){
                                        for(var i in data.Coverages){
                                            if(data.Coverages[i].CoverTypeId === index[j]){
                                                coverages[j] = data.Coverages[i];
                                            }
                                        }
                                    }

                                    data.Coverages = coverages;
                                    /** ------------------------ */

                                    angular.extend($scope.coverages, data);
                                    $scope.IsCompulsarySelectedFrontEnd=fn.utils.IsCompulsarySelectedFrontEndFn();
                                    var selectedCoverage = fn.utils.getSelectedCoverage();
                                    if (selectedCoverage) {
                                        fn.utils.setSelectedCoverage(selectedCoverage);

                                        /** Added by OB for selected coverages*/
                                        for(var i in selectedCoverage.Covers){
                                            if(selectedCoverage.Covers[i].CoverName === "Voluntary Basic Cover"){
                                                $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                            }
                                        }
                                        /** ------------------------ */
                                    }
                                    else
                                    {
                                        fn.utils.setDefaultCoverage();
                                        $scope.ExcessLevelId = 7000310;
                                    }
                                    fn.utils.setSumInsured();

                                    $scope.coverages.isForUpdate = true;
                                    if(selectedCoverage){
                                        if(selectedCoverage.CoverTypeId == 2000020){
                                            $scope.isCompulsoryOnly = true;
                                        }else{
                                            $scope.isCompulsoryOnly = false;
                                        }

                                    }else{
                                        $scope.isCompulsoryOnly = false;
                                    }

                                    //fn.utils.closeIsSaving();
                                    //retrieveInsuranceAmount();
                                    retrievePHContact();
                                },
                                onError: function () {
                                    retrievePHContact();
                                }
                            }
                        );
                    };

                    var retrievePHContact = function () {
                        fn.phContactApi.retrievePHContact(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.phContact, data);
                                    getBooleanList();

                                },
                                onError: function () {
                                    getBooleanList();
                                }
                            }
                        );
                    };

                    var getBooleanList = function () {
                        fn.wfeApiStaticData.getBooleanList({
                            onSuccess: function (data) {
                                $scope.staticData.booleans = data;

                                //retrieveDescStaticData(); //jj
                                
                                if($scope.coverages.CoverListsCount == 5){
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000017})[0];
                                }else{
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000015})[0];
                                }
                                
                                if(selectedCoverage.IsSelected){
                                    retrieveExcess();
                                }else{
                                    $scope.loadingSpinner = false;
                                    retrievePolicyHolder();
                                }

                            },

                            onError: function () {
                                //retrieveDescStaticData();

                                //var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000015})[0];
                                if($scope.coverages.CoverListsCount == 5){
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000017})[0];
                                }else{
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: 2000015})[0];
                                }
                                if(selectedCoverage.IsSelected){
                                    retrieveExcess();
                                }else{
                                    $scope.loadingSpinner = false;
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    retrievePolicyHolder();
                                }
                            }
                        });
                    };

                    var retrieveExcess=function(){
                        fn.coverageApi.getVehicleExcess(
                            {
                                onSuccess: function (data) {
                                    if(data && data[0].value)
                                    {
                                        $scope.allExcess = data[0].value;
                                        $scope.selectedCoverage.excess = data;
                                    }

                                    $scope.loadingSpinner = false;
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    retrievePolicyHolder();
                                    fn.eventHandlers.sendDefaultExcessGTM();
                                },
                                onError: function(){
                                    $scope.loadingSpinner = false;
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    retrievePolicyHolder();
                                }
                            }
                        );
                    };

                    /** PolicyContact retrieval: this will retrieve MD if PH is not MD */
                    var retrievePolicyHolder = function () {
                        fn.policyHolderApi.retrievePHPolicyContact(
                            {
                                onSuccess: function (data) {

                                    angular.extend($scope.policyHolder, data);
                                    if($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                        $scope.policyHolder.SubscribedMarketingEmail=false;
                                    } else {
                                        $scope.policyHolder.SubscribedMarketingEmail=true;
                                    }

                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    if (data === undefined) {
                                        retrieveMainDriver();
                                        fn.saving.saveCoveragesModel(preCallbacksA);
                                    }

                                    if (data.IsMainDriver) {
                                        fn.utils.copyPHFieldsToMD();
                                        fn.saving.saveCoveragesModel(preCallbacksA);
                                    } else{
                                        retrieveMainDriver();
                                        fn.saving.saveCoveragesModel(preCallbacksA);
                                    }

                                },
                                onError: function () {
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                }
                            }
                        );
                    };

                    var retrieveMainDriver = function () {
                        fn.mainDriverApi.retrieveMDPolicyContact(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.mainDriver, data);
                                    if($scope.mainDriver.DateOfBirth)
                                    {
                                        fn.utils.ConvertMITDateToDisplay();
                                    }
                                    if ($scope.mainDriver.IsMainDriver) {
                                        $scope.policyHolder.IsMainDriver = false;
                                    }

                                }
                            }
                        );
                    };


                    /**
                     * Section 3 : Update & Final calculate & Partial Save
                     * */
                    var preCallbacksA = {
                        onSuccess: function () {
                            fn.updateAssetMaxarPriority(callbacksA);
                        }
                    }

                    var callbacksA = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacksB);
                           
                        }
                    };

                    var callbacksB = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPH(callbacksC);
                                    },
                                    onError: function () {
                                        fn.saving.saveOtherDetailsPH(callbacksC);
                                    }
                                }
                            );
                        }
                    };

                    var callbacksC = {
                        onSuccess: function () {
                            fn.updatesCoveragesSPA();
                        }
                    };

                    triggerSave();
                },

                getSaveFunctionName: function (model, functionName) {
                    var saveFunc = model.isForUpdate ? 'update' : 'create';
                    return saveFunc + functionName;
                },

                calculatePremium: function (callbacks) {
                    fn.quoteApi.calculate(callbacks);
                },

                saveOtherDetailsPH:function(callbacks)
                {
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                /*proceed if pnumber else ps.*/
                                if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                } else {

                                    fn.essentialApi.partialSave(
                                        {
                                            onSuccess: function (data) {
                                                $scope.partialPolicyNumber = data['QuoteNumber'];
                                                fn.utils.setIsSaving(false);
                                                $scope.$parent.maxarCallbackTrigger = false;
                                                marketing.dLCE(45, $scope.partialPolicyNumber);

                                                if (callbacks && callbacks.onSuccess) {
                                                    callbacks.onSuccess();
                                                }

                                                fn.saving.saveCoveragesModel(
                                                    {
                            
                                                        onSuccess: function () {
                                                            fn.logApi.updateLog();
                                                        },
                                                        onError: function () {
                                                        }
                                                    }
                                                );
                                            },
                                            onError: function(){
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                }

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                saveCoveragesModel: function (callbacks) {

                    var triggerSave = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc](
                            {
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;

                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },

                savePHContactToProceed: function (callbacks) {

                    fn.utils.setIsSaving(true);
                    var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                    fn.policyHolderApi[savePHFunc](
                        {
                            onSuccess: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            }
                        }
                    );

                },

                savePHContactCallback: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                //if ($scope.$parent.isLoggedIn())
                                fn.setAssetMaxarPriority("cb");
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function(){
                                        if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                        {
                                            fn.confirmationApi.createConfirmation(
                                                {
                                                    onSuccess: function () {
                                                        fn.utils.setIsSaving(false);
                                                        if($sessionStorage.currentPage == 2){
                                                            try{
                                                                $scope.$parent.gaEvents(2,3);
                                                            }catch(e){}
                                                        }else if($sessionStorage.currentPage == 3){
                                                            try{
                                                                $scope.$parent.gaEvents(5,3);
                                                            }catch(e){}
                                                        }
                                                        
                                                        $scope.$parent.maxarCallbackTrigger = true;
                                                        /* Callback action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.utils.sendMaxarNewPriorV2();
                                                        fn.getMaxarPriority("cb");
                                                        DaModal.close('#quotationCallback');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        } else {
        
                                            fn.essentialApi.partialSave(
                                                {
                                                    onSuccess: function (data) {
        
                                                        fn.utils.setIsSaving(false);
                                                        try {
                                                            $scope.$parent.gaEvents(2, 3);
                                                        }
                                                        catch (e) {
                                                        }
        
                                                        $scope.$parent.maxarCallbackTrigger = true;
                                                        /* Callback action priority */
                                                        //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                                                        fn.utils.sendMaxarNewPriorV2();
                                                        fn.getMaxarPriority("cb");
                                                        DaModal.close('#quotationCallback');
                                                    },
        
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                });
                                
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

            },

            validations: {
                isCoverNotAvail: function (coverageTypeId) {
                    return HKVehicleValidationService.isCoverNotAvail($scope.asset.PurchasingYear, 1, coverageTypeId);
                },

                checkNCDLevel: function(){
                    if($scope.asset.NcdLevelId!=='1000003' || $scope.asset.NcdLevelId!=1000003 ){
                        $scope.asset.ReasonForNcdIsZero = '';
                        $scope.asset.NcdLevelIdOnOtherCar = '';
                    }else{

                    }

                    if($scope.asset.NcdLevelId==1000009 ||$scope.asset.NcdLevelId=="1000009"){
                        $scope.$parent.applyNCBRootDontKnow = true;
                    }else{
                        $scope.$parent.applyNCBRootDontKnow = false;
                    }
                },

                checkDOBEmpty: function(){
                    if(typeof $scope.mainDriver.DOB_DD === 'undefined' || typeof $scope.mainDriver.DOB_MON === 'undefined' || typeof $scope.mainDriver.DOB_Year === 'undefined'){
                        $scope.DOBEmpty = true;
                    }
                },

                checkMake:function()
                {
                  if($scope.asset.ManufactureId=="0000000" || $scope.asset.ManufactureId==0000000)
                  {
                      $scope.frmAboutCar.vehicleMake.$setValidity("valid",false);
                  }
                  else
                  {
                      $scope.frmAboutCar.vehicleMake.$setValidity("valid",true);
                  }
                },

                isStartDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidStartDate($scope.currentProposal.PolicyStartDate, formElement);
                },

                isEndDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidEndDate($scope.currentProposal.PolicyEndDate, $scope.currentProposal.PolicyStartDate, formElement);
                },

                validateForms: function () {
                    return $scope.frmAboutMainDriver.$invalid || $scope.frmAboutCar.$invalid || $scope.frmAboutMainDriver2.$invalid;
                },

                hasInvalid: function hasInvalid() {
                    var isInvalid = false;
                    /*honey pot attack*/
                    if ($scope.securityQuestion && $scope.securityQuestion.length > 0) {
                        DaModal.alert('got you');
                        return true;
                    }
                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if(!$scope.consentPrivacyFlag){
                        isInvalid = true;
                        $('#consentpopup').popover('show');
                    }else{
                        $('#consentpopup').popover('hide');
                    }

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;
                },

                checkDrivingExp:function(){
                    /*lessThanTwoDrivingExp*/
                    if($scope.mainDriver.DrivingExperienceId)
                    {
                        if($scope.mainDriver.DrivingExperienceId==1 ||$scope.mainDriver.DrivingExperienceId=="1"
                            || $scope.mainDriver.DrivingExperienceId==2 ||$scope.mainDriver.DrivingExperienceId=="2")
                        {
                            //Less than 2 driving experiencee.
                            $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', false);
                            return;
                        }
                    }
                    $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', true);
                },

            },

            eventHandlers: {

                onBtnCallbackContinueClicked: function () {
                    
                    $scope.pageSubmitted = true;
                    $scope.callBackSubmitted = true;
                    
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }
                    
                    if ($scope.policyHolder.FirstName.invalid ||$scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }
                    fn.saving.savePHContactCallback();
                },
                onBtnCallbackCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        $scope.phContact.PrimaryPhoneNumber = "";
                    }

                    DaModal.close('#quotationCallback');
                },
                onBtnPartialSaveContinueClicked: function () {

                    $scope.pageSubmitted = true;
                    $scope.partialSaveSubmitted = true;

                    /*** to check first name */
                    if (!$scope.policyHolder.FirstName) {
                        return;
                    }

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    /*** To check email */
                    if ($scope.phContact.PrimaryEmailAddress.invalid) {
                        return;
                    }

                    fn.saving.savePHContact();
                },
                onBtnPartialSaveCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if ($scope.phContact.PrimaryPhoneNumber) {
                        if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                            $scope.phContact.PrimaryPhoneNumber = "";
                        }else{
                        }
                    }
                    DaModal.close('#quotationPartialSave');
                },
                updateDeductibleAsset: function() {

                    $scope.loadingSpinner3 = true;
                    $scope.$parent.showPriceOnTopFlag = false;
                    for(var i in $scope.coverages.Coverages){
                        if($scope.coverages.Coverages[i].CoverTypeId === 2000015){
                            $scope.coverages.Coverages[i].CoverTypeId.ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                            for(var j in $scope.coverages.Coverages[i].Covers){
                                if($scope.coverages.Coverages[i].Covers[j].CoverName === "Voluntary Basic Cover"){
                                    $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                                }
                            }
                        }
                    }

                    fn.assetApi.updateAsset(
                        {
                            onSuccess: function () {

                                var callbacks = {
                                    onSuccess: function () {
                                        //fn.saving.saveCurrentProposalChildModels();
                                        fn.saving.calculatePremium(
                                            {
                                                onSuccess: function () {
                                                    fn.updatesCoveragesSPA();
                                                    //fn.retrieveScopeObjects();
                                                    fn.utils.setIsSaving(false);
                                                    //$scope.loadingSpinner3 = false;
                                                },

                                                onError: function () {
                                                    fn.utils.setIsSaving(false);
                                                   // $scope.loadingSpinner3 = false;
                                                }
                                            }
                                        ); 
                                    }
                                };

                                fn.saving.saveCoveragesModel(callbacks);

                            },
                            onError: function () {

                            }
                        }
                    );
                },
                onFullDetailClicked: function(totalNum){
                    if($scope.coverDetailsClicked)
                        $scope.coverNum = totalNum;
                    else
                        $scope.coverNum = 0;

                    $scope.coverDetailsClicked = !$scope.coverDetailsClicked;
                    //marketing.gaEvents(1);
                },
                onBtnCallbackClicked :function(){
                    
                    if($sessionStorage.currentPage == 2){
                        try{
                            $scope.$parent.gaEvents(2,3);
                        }catch(e){}
                    }else if($sessionStorage.currentPage == 3){
                        try{
                            $scope.$parent.gaEvents(5,3);
                        }catch(e){}
                    }
                    
                                        /*** regex will be false when phone number is invalid */
                                        var regex = /^[0][6|8|9][0-9]\d{7}/;
                                        if ($scope.phContact.PrimaryPhoneNumber) {
                                            if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                                                $scope.phContact.PrimaryPhoneNumber = "";
                                            }else{
                                            }
                                        }
                    
                                        fn.saving.saveCoveragesModel(
                                            {
                                                onSuccess: function () {
                                                    DaModal.open('#quotationCallback');
                                                },
                                                onError: function () {
                                                }
                                            }
                                        );
                                    },
                onNCBApplyRequest: function(bool){
                    $scope.applyNCB = bool;
                    if($scope.applyNCB){
                        $scope.asset.NcdLevelId = "";
                        $scope.asset.ReasonForNcdIsZero = "";
                        $scope.asset.NcdLevelIdOnOtherCar = "";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    }else{
                        $scope.asset.NcdLevelId = "1000003";
                        $scope.asset.ReasonForNcdIsZero = "1000004";
                        $scope.asset.NcdLevelIdOnOtherCar = "0";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    }
                },

                onPolicyStartDateChanged: function () {
                    fn.utils.autoSetEndDate();
                },

                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },

                onBtnCalculatePremiumClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,0);
                    }
                    catch(e){}
                    fn.utils.setIsSaving(false);

                    $scope.allowBtnAction = false;
                    

                    $scope.loadingSpinner2 = true;
                    $scope.loadingSpinner3 = true;
                    $scope.$parent.showPriceOnTopFlag = false;
                    
                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var carAge = chkDate.getFullYear() - $scope.asset.PurchasingYear;
                    var ncdLevel = 0;
                    if(carAge == 0 ){  // 0% For vehicle age 0 year
                        ncdLevel = 0;
                        $scope.asset.NcdLevelId = "1000003";          
                    }else if (carAge == 1){ // 20% For vehicle age 1 year
                        ncdLevel = 20;
                        $scope.asset.NcdLevelId = "1000005";
                    }else if (carAge > 1){  // 30% Vehicle age > 1 year
                        ncdLevel = 30;
                        $scope.asset.NcdLevelId = "1000006";
                    }else{
                        ncdLevel = 0;
                        $scope.asset.NcdLevelId = "1000003";
                    }

                    $scope.carAge = carAge;

                    $scope.pageSubmitted = true;
                    fn.setSourceChannels();

                    if (fn.validations.hasInvalid()) {
                        return;
                    }else{
                        fn.goToPage(2);
                        fn.setPreviousPage(1);
                        
                    }

                    fn.utils.setIsSaving(true);
                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.saveCurrentProposalChildModels();
                        },
                        onError: function (){
                            fn.eventHandlers.onDefaultErrorHandler();
                        }
                    };
                    
                    $scope.asset.PastInsuranceContactExtNum = "90000";
                    $scope.asset.PrevNcdLevelId = $scope.asset.NcdLevelId;
                    $scope.asset.MaxarPriority = "";
                    //$scope.asset.LeadTypeId = 7000004;

                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                    $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                    // Add GCLID , IRIS project
                    $scope.currentProposal.GAClientID = $scope.$parent.GAClientID;
                    fn.currentProposalApi[saveCurrentProposalFunc](callbacks);
                },

                onBtnBackClicked: function(){
                    fn.goToPage(1);
                    fn.setPreviousPage(2);
                    marketing.ga();
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){}
                },
                onBtnBackClicked2: function(){
                    marketing.ga_next();
                    fn.goToPage(2);
                    fn.setPreviousPage(3);
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){}
                    //fn.utils.sendMaxarNewPrior($scope.totalPremium2);
                    fn.utils.sendMaxarNewPriorV2();
                    fn.getMaxarPriority();
                },
                onBtnNextClicked: function(){
                    fn.goToPage(3);
                    fn.setPreviousPage(2);
                    $scope.newMaxarPrior = 1;
                    fn.setAssetMaxarPriority();
                    fn.getMaxarPriority();
                    fn.updateAssetMaxarPriority();
                    // GA track for 3rd page.
                    marketing.ga_next_review();
                    
                    try{
                        $scope.$parent.gaEvents(0,0);
                    } catch(e){}
                    $scope.pageSubmitted = true;

                    fn.utils.setIsSaving(true);

                    var preCallbacks1 = {
                        onSuccess: function (){
                            fn.updateAssetMaxarPriority(callbacks1);
                        }
                    };

                    var callbacks1 = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacks2);
                        }
                    };

                    var callbacks2 = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPH(callbacks3);
                                    },
                                    onError: function () {
                                        fn.saving.saveOtherDetailsPH(callbacks3);
                                    }
                                }
                            );
                        }
                    };

                    var callbacks3 = {
                        onSuccess: function(){
                            //console.log("save quote");
                        }
                        
                    };

                    // Test for navigate next instantaneous -- roll back test
                    fn.saving.saveCoveragesModel(preCallbacks1);
                },

                onDropMakeChanged: function (manufacturerId) {
                    $scope.staticData.models = [];
                    if(HKLocale.getLocale() == "th-th"){
                        $("#vehicleModel option:first").text("กำลังโหลด...");
                        $scope.staticData.models = [{"key": 0, "value": "กำลังโหลด..."}];
                    }else{
                        $("#vehicleModel option:first").text("Loading...");
                        $scope.staticData.models = [{"key": 0, "value": "Loading..."}];
                    }
                    //$scope.staticData.models = [];
                    if(!$scope.isStartQuote){
                        $scope.$parent.gaEvents(0, 13);
                        $scope.isStartQuote = true;

                    }

                    if (!manufacturerId) {
                        if ($scope.staticData.models) {
                            $scope.staticData.models.splice(0);
                        }
                        return;
                    }
                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    //wfeApiStaticDataModel.getModelListWithYear({
                    wfeApiStaticDataModel.getModelListV2({
                        onSuccess: function (data) {
                            

                            filteredModel = $filter('filter')(data, function(r){
                                var notAcceptModel = ["90000148",
                                "90000784",
                                "90000546",
                                "90000214",
                                "90000810",
                                "90000406",
                                "90000402",
                                "90000456",
                                "90000747",
                                "90000043",
                                "90000667",
                                "90000737",
                                "90000185",
                                "90000186",
                                "90000189",
                                "90000194",
                                "90000720",
                                "90000203",
                                "90000772",
                                "90000773",
                                "90000229",
                                "90000230",
                                "90000231",
                                "90000598",
                                "90000676",
                                "90000232",
                                "90000234",
                                "90000235",
                                "90000236",
                                "90000237",
                                "90000238",
                                "90000723",
                                "90000809",
                                "90000242",
                                "90000270",
                                "90000274",
                                "90000278",
                                "90000280",
                                "90000279",
                                "90000282",
                                "90000283",
                                "90000286",
                                "90000287",
                                "90000288",
                                "90000289",
                                "90000790",
                                "90000778",
                                "90000801",
                                "90000802",
                                "90000781",
                                "90000794",
                                "90000465",
                                "90000812",
                                "90000469",
                                "90000490",
                                "90000491",
                                "90000492",
                                "90000762",
                                "90000493",
                                "90000494",
                                "90000495",
                                "90000496",
                                "90000785",
                                "90000786",
                                "90000497",
                                "90000498",
                                "90000499",
                                "90000500",
                                "90000501",
                                "90000502",
                                "90000503",
                                "90000504",
                                "90000505",
                                "90000042",
                                "90000165",
                                "90000243",
                                "90000029",
                                "90000046",
                                "90000066",
                                "90000067",
                                "90000807",
                                "90000031",
                                "90000174",
                                "90000182",
                                "90000303",
                                "90000353",
                                "90000438",
                                "90000440",
                                "90000527",
                                "90000162",
                                "90000217",
                                "90000296",
                                "90000529",
                                "90000122",
                                "90000010",
                                "90000249"];
                                if(!notAcceptModel.includes(r.key)){
                                    console.log(r.key);
                                    return r.key;
                                }
                            });
                            $scope.staticData.models = filteredModel;

                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            }else{
                                $("#vehicleModel option:first").text("Please select...");
                            }
                            if(data.length==0) {
                                marketing.dLCE(47, 'Yes');
                                DaModal.open("#makeAndModelNotAvailable");
                            }

                        },
                        onError: function (){
                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            }else{
                                $("#vehicleModel option:first").text("Please select...");
                            }
                        }
                    });

                },

                onDropModelChanged: function (manufacturerId, modelId) {
                    $scope.staticData.yearOfRegistrations = [];
                    if(HKLocale.getLocale() == "th-th"){
                        $("#vehicleFirstReg option:first").text("กำลังโหลด...");
                        $scope.staticData.yearOfRegistrations = [{"key": 0, "value": "กำลังโหลด..."}];
                    }else{
                        $("#vehicleFirstReg option:first").text("Loading...");
                        $scope.staticData.yearOfRegistrations = [{"key": 0, "value": "Loading..."}];
                    }
                    
                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    objParams.modelId = modelId;

                    if(typeof modelId !== "undefined") {
                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                        //wfeApiStaticDataYear.getVehicleYearListByStartYear({
                        wfeApiStaticDataModel.getYear({
                            onSuccess: function (data) {
                                $scope.staticData.yearOfRegistrations = data;
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกรุ่นปีรถยนต์");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }

                            },
                            onError: function () {
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกรุ่นปีรถยนต์");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }
                            }
                        });
                    }
                },

                onDropPurchasingYearChanged: function (modelId, purchasingYear) {
                    if(HKLocale.getLocale() == "th-th"){
                        $("#vehicleDesc option:first").text("กำลังโหลด...");
                        $scope.staticData.arrangedcardescriptions = [{"key": 0, "HGroup": "กำลังโหลด..."}];
                    }else{
                        $("#vehicleDesc option:first").text("Loading...");
                        $scope.staticData.arrangedcardescriptions = [{"key": 0, "HGroup": "Loading..."}];
                    }
                    $scope.staticData.cardescriptions = [];
                    var objParams = {};
                    objParams.modelId = modelId;
                    objParams.purchasingYear = purchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getCarDescWithYear({
                        onSuccess: function (data) {
                            $scope.staticData.cardescriptions = data;
                            $scope.staticData.arrangedcardescriptions = fn.eventHandlers.reduceDesc(data);
                            if($scope.staticData.arrangedcardescriptions.length === 0){  // If car type filter and got zero, return not available message.
                                marketing.dLCE(47, 'Yes');
                                DaModal.open("#makeAndModelNotAvailable");
                            }
                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            }else{
                                $("#vehicleDesc option:first").text("Please select...");
                            }
                            
                        },
                        onError: function (){
                            if(HKLocale.getLocale() == "th-th"){
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            }else{
                                $("#vehicleDesc option:first").text("Please select...");
                            }
                        }
                    });

                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var NCDLVLID = "1000003";
                    var carAge = chkDate.getFullYear() - purchasingYear;
                    var ncdLevel = 0;
                    if(carAge == 0 ){  // 0% For vehicle age 0 year
                        ncdLevel = 0;
                        NCDLVLID = "1000003";          
                    }else if (carAge == 1){ // 20% For vehicle age 1 year
                        ncdLevel = 20;
                        NCDLVLID = "1000005";
                    }else if (carAge > 1){  // 30% Vehicle age > 1 year
                        ncdLevel = 30;
                        NCDLVLID = "1000006";
                    }else{
                        ncdLevel = 0;
                        NCDLVLID = "1000003";   
                    }
                    //console.log('x: NCD Level = ' + ncdLevel);
                    // Fire GA here
                    marketing.dLCE(1, purchasingYear);
                    marketing.dLCE(13, NCDLVLID);
                },

                onCarDescriptionChanged: function (descKey) {

                     var carTarrif=$filter('filter')($scope.staticData.cardescriptions, {
                         key: descKey
                     })[0];

                        if(carTarrif.tariffType)
                        {
                            if(carTarrif.tariffType ==='320' || carTarrif.tariffType==320)
                            {
                                // Do not respect IsDriveWork/IsUseWork/Usage is fixed.
                                $scope.asset.TariffType=320;

                                // Block for tarifftype 320 : 2 door pick up
                                marketing.dLCE(47, 'Yes');
                                DaModal.open("#makeAndModelNotAvailable");
                                $scope.txtDesc = fn.getTranslateText('desc');
                                $scope.asset.CarDescriptionId = null;
                            }
                            else
                            { 
                                $scope.asset.TariffType=110;

                            }
                        }
                        return;
                },

                onBtnInDriveWorkChange: function () {
                    var inDriveWork = $scope.asset.InDriveWork;
                    if (!inDriveWork) {
                        $scope.asset.InCourseWork = false;
                    } else {
                        $scope.asset.InCourseWork = undefined;
                    }

                },

                /** Doesn't use for now (renewal function) */
                onBtngoToLandingpage: function () {
                    DaModal.close('#renewalProposalExistsMessage');
                    if ($scope.$parent.isLoggedIn()) {
                        $timeout(function () {
                            $state.go('private.account.user.policies');
                        }, 300);
                    }
                    else {
                        $timeout(function () {
                            $state.go('public.login');
                        }, 300);

                    }

                },

                /** Doesn't use for now */
                onIsPHMDButton: function (key) {
                    $scope.policyHolder.IsMainDriver = key;

                    if (key) {
                        fn.utils.copyPHFieldsToMD();

                        if ($scope.accountUser.Address) {
                            var phAddress = $scope.accountUser.Address.Addresses[0];

                            if (phAddress) {
                                $scope.asset.ResidentialDistrictId = phAddress.ProvinceId;
                            }
                        }
                    } else {
                        $scope.mainDriver.DateOfBirth = null;
                        $scope.mainDriver.GenderId = null;
                        $scope.mainDriver.MaritalStatusId = null;
                        $scope.mainDriver.OccupationId = null;
                        $scope.mainDriver.DrivingExperienceId = null;
                        $scope.mainDriver.NrOfClaimsInPeriodId = null;
                        $scope.asset.ResidentialDistrictId = {};
                    }
                },

                onBtnNCDClicked: function() {
                    DaModal.open('#checkNCD');
                },

                onBtnNCDSelected: function() {
                    DaModal.close('#checkNCD');
                },

                reduceDesc: function(descData){
                    //console.log(descData);
                    if(descData)
                    {       var p;
                            var header;
                            
                            var descObj2 = new Object();
                            
                            var descKeyObj = new Object();
                            var descTxtObj = new Object();
                            var optArr = new Array;
                            var headArr = new Array;
                            
                            for(var key in descData){
                                    if(!isNaN(descData[key].key)){
                                        //if(descData[key].description.substr(0, 2) != "Z_"){
                                            var optSel = new Object();
                                            p = descData[key].description.split(" ");
                                            HGroup = p[0] + " " + p[1] + " ";
                                            descTxt = descData[key].description.replace(HGroup, "").replace("?", " ");
                                            descKey = descData[key].key;
                                            optSel.key = descKey;
                                            optSel.txt = descTxt;
                                            optSel.hgroup = HGroup;
                                            optSel.oriDesc = descData[key].description;
                                            optArr.push(optSel);
                                            if(headArr.indexOf(HGroup) == -1){
                                                    headArr.push(HGroup);
                                            }
                                        //}
                                    }
                                    
                            }
                            var z = Array();
                            for(var i in headArr){
                                    var x = new Array;
                                    var descObj = new Object();
                                    for(var j in optArr){
                                            if(headArr[i] == optArr[j].hgroup){
                                                    x.push(optArr[j]);                
                                            }
                                    }
                                    descObj.HGroup = headArr[i];
                                    descObj.Data   = x;
                                    z.push(descObj);
                            }
                            return z;
                    }
                },
                setMaker: function(e){
                    $scope.txtMaker = e.target.textContent.trim();
                    $scope.asset.ManufactureId = e.target.dataset.key;
                    fn.eventHandlers.showModel(e);
                    $scope.txtModel = fn.getTranslateText('model');
                    $scope.txtYear  = fn.getTranslateText('year');
                    $scope.txtDesc  = fn.getTranslateText('desc');
                },
                setModel: function(e){
                    $scope.txtModel = e.target.textContent.trim();
                    $scope.asset.ModelId = e.target.dataset.key;
                    fn.eventHandlers.showYear(e);
                    $scope.txtYear  = fn.getTranslateText('year');
                    $scope.txtDesc  = fn.getTranslateText('desc');
                },
                setYear: function(e){
                    $scope.txtYear = e.target.textContent.trim();
                    $scope.asset.PurchasingYear = e.target.dataset.key;
                    fn.eventHandlers.showDesc(e);
                    $scope.txtDesc  = fn.getTranslateText('desc');
                },
                setDesc: function(e){
                    $scope.txtDesc = e.target.parentNode.dataset.head + " " + e.target.textContent.trim();
                    $scope.asset.CarDescriptionId = e.target.dataset.key;
                },
                showModel: function(e){
                    e.stopPropagation();
                    $("#vehicleMake3").removeClass('open');
                    $("#vehicleModel3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowModel").html(divArrow);               
                },
                showYear: function(e){
                    e.stopPropagation();
                    $("#vehicleModel3").removeClass('open');
                    $("#vehicleFirstReg3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowYear").html(divArrow);
                },
                showDesc: function(e){
                    e.stopPropagation();
                    $("#vehicleFirstReg3").removeClass('open');
                    $("#vehiceDesc3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowDesc").html(divArrow);
                },
                selectCoverage:function(c) {
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                        $scope.coverages.Coverages[i].IsSelected = false;
                    }
                    c.IsSelected = true;
                    if(c.CoverTypeId==2000015){
                        /*don't need to show excess for type 1 in desc.*/
                        fn.coverageApi.getVehicleExcess(
                            {
                                onSuccess: function (data) {
                                    if (data && data[0].value){
                                        $scope.selectedCoverage.excess = data;
                                        //$scope.selectedCoverage.ExcessLevelId = 7000310;
                                    }
                                },
                                onError: function () {
                                }
                            }
                        );
                    }

                    $scope.coverNum = 0;
                    $scope.coverDetailsClicked = true;
                    $scope.isCompulsoryOnly = false;
                    $scope.totalPremium = fn.calculateTotalAmout();

                    /*slide down to correct type , if only its display on small screen*/
                    if($window.matchMedia) {
                        var mq = $window.matchMedia("(max-width:991px)");
                        if (mq) {
                            if (mq.matches) {
                                var anc = '#dacover_' + c.CoverTypeId;

                                /*timeout cos the box expands and thus creates problems with top-margin calc*/
                                $timeout(function () {
                                    fn.gtAnchor(anc);
                                }, 100);
                            }

                        }
                    }
                    fn.utils.setSumInsured();
                },
                onBtnSaveAndExitClicked: function () {
                    
                                        /*** regex will be false when phone number is invalid */
                                        var regex = /^[0][6|8|9][0-9]\d{7}/;
                                        if ($scope.phContact.PrimaryPhoneNumber) {
                                            if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                                                $scope.phContact.PrimaryPhoneNumber = "";
                                            }else{
                                            }
                                        }
                    
                                        try{
                                            $scope.$parent.gaEvents(0,2);
                                        }
                                        catch(e){}
                                        fn.saving.saveCoveragesModel(
                                            {
                    
                                                onSuccess: function () {
                                                    DaModal.open('#quotationPartialSave');
                                                },
                                                onError: function () {
                                                }
                                            }
                                        );
                                    },
                                    resetCompulsory:function(){
                                        if(!$scope.coverages) return;
                                        for (var i = 0; i < $scope.coverages.Coverages.length; i++)
                                        {
                                            /*CCB*/
                                            var ccb=$filter('filter')($scope.coverages.Coverages[i].Covers, {
                                                ProductLineGroupId: 2000161
                                            },true);
                                            if(ccb){
                                                ccb[0].IsSelected=!ccb[0].IsSelected;
                                            }
                    
                                            /*Death & Dismemberment or permanent disability*/
                                            var ddpd=$filter('filter')($scope.coverages.Coverages[i].Covers, {
                                                ProductLineGroupId: 2000101
                                            },true);
                                            if(ddpd){
                                                ddpd[0].IsSelected=!ddpd[0].IsSelected;
                                            }
                    
                                            /*Bodily Injury or affecting health*/
                                            var bih=$filter('filter')($scope.coverages.Coverages[i].Covers, {
                                                ProductLineGroupId: 2000102
                                            },true);
                                            if(bih){
                                                bih[0].IsSelected=!bih[0].IsSelected;
                                            }
                                        }
                                    },
                                    onBtnCoverExplainedClicked: function() {
                                        //marketing.gaEvents(2);
                                        DaModal.open('#coverExplained');
                                    },
                    
                                    onBtnCoverExplainedSelected: function() {
                                        DaModal.close('#coverExplained');
                                    },
                sendDefaultExcessGTM:function()
                {
                    if(!$scope.selectedCoverage) { return;}
                    try{
                        if($scope.selectedCoverage.CoverTypeId!= 2000020) {
                            if($scope.selectedCoverage.excess && $scope.selectedCoverage.ExcessLevelId) {
                                var exc = $filter('filter')($scope.selectedCoverage.excess, {
                                    key: $scope.selectedCoverage.ExcessLevelId,

                                }, true);
                                if(exc && exc[0].value)
                                    marketing.dLCE(12, exc[0].value);
                                else
                                    marketing.dLCE(12,'NA');
                            } else {
                                marketing.dLCE(12,'NA');
                            }
                        } else { //Compulsory
                            marketing.dLCE(12,'NA');
                        }

                    }
                    catch(e) {
                        console.log(e);
                    }
                },

                resetCompulsory:function(){
                    if(!$scope.coverages) return;
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++)
                    {
                        /*CCB*/
                        var ccb=$filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000161
                        },true);
                        if(ccb){
                            ccb[0].IsSelected=!ccb[0].IsSelected;
                        }

                        /*Death & Dismemberment or permanent disability*/
                        var ddpd=$filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000101
                        },true);
                        if(ddpd){
                            ddpd[0].IsSelected=!ddpd[0].IsSelected;
                        }

                        /*Bodily Injury or affecting health*/
                        var bih=$filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000102
                        },true);
                        if(bih){
                            bih[0].IsSelected=!bih[0].IsSelected;
                        }
                    }
                },

            },
            loadWidgetValue: function() 
            {
                                // Widget parameter value set
                                if($sessionStorage.widgetItems)
                                {
                                    //console.log("get widget val");


                                    var maker = StaticDataKeyValuePair.getMake({'productId' : AppConstants.AGGConfig.PRODUCT_ID_DA});
                                    
                                    if($sessionStorage.widgetItems.make)
                                    {
                                        $scope.asset.ManufactureId = $sessionStorage.widgetItems.make.toString();
                                        fn.eventHandlers.onDropMakeChanged($scope.asset.ManufactureId);
                                        maker.$promise.then(function(){
                                            $scope.txtMaker = $filter('filter')(maker, {key: $scope.asset.ManufactureId})[0].value;
                                            marketing.dLCE(2, $scope.txtMaker);
                                        })
                                        var models = StaticDataKeyValuePair.getModelV2({'ManufacturerId' : $sessionStorage.widgetItems.make});
                                        
                                        $scope.chkMaker = true;
                                    }
                                    
                                    if($sessionStorage.widgetItems.model)
                                    {
                                        $scope.asset.ModelId = $sessionStorage.widgetItems.model.toString();
                                        fn.eventHandlers.onDropModelChanged($scope.asset.ManufactureId, $scope.asset.ModelId);
                                        models.$promise.then(function(){
                                            $scope.txtModel = $filter('filter')(models, {key: $scope.asset.ModelId})[0].value;
                                            marketing.dLCE(3, $scope.txtModel); 
                                        })
                                        var years = StaticDataKeyValuePair.getYear({'ManufacturerId' : $sessionStorage.widgetItems.make, 'Model' : $sessionStorage.widgetItems.model})
                                        
                                        $scope.chkModel = true;                       
                                    }
                
                                    if($sessionStorage.widgetItems.registrationyear)
                                    {
                                        $scope.asset.PurchasingYear = $sessionStorage.widgetItems.registrationyear.toString();
                                        fn.eventHandlers.onDropPurchasingYearChanged($scope.asset.ModelId, $scope.asset.PurchasingYear);
                                        $scope.txtYear = $scope.asset.PurchasingYear;
                                        marketing.dLCE(1, $scope.asset.PurchasingYear);
                                        var desc = StaticDataKeyValuePair.getCarDescWithYear({'ModelId' : $sessionStorage.widgetItems.model, 'y' : $sessionStorage.widgetItems.registrationyear});
                                        
                                        $scope.chkYear = true;
                                    }
                
                                    if($sessionStorage.widgetItems.desc)
                                    {
                                        $scope.asset.CarDescriptionId = $sessionStorage.widgetItems.desc.toString();
                                        desc.$promise.then(function(){
                                            $scope.txtDesc = $filter('filter')(desc, {key: $scope.asset.CarDescriptionId})[0].description;
                                            marketing.dLCE(4, $scope.txtDesc);
                                        })
                                        $scope.chkDesc = true;
                                    }
                
                                    if($sessionStorage.widgetItems.cameraq)
                                    {
                                        var cameraQ = true;
                                        if($sessionStorage.widgetItems.cameraq === "false")
                                        {
                                            cameraQ = false;
                                        } 

                                        $scope.asset.IsCarCameraInstalled = cameraQ;
                                        marketing.dLCE(46, $scope.asset.IsCarCameraInstalled);
                                        $scope.chkCamera = true;
                                    }
                                }else{
                                    //console.log("can't get widget item");
                                }
            },
            checkFromWidget: function() {
                //if($sessionStorage.widgetItems)
                if($scope.chkCamera && $scope.chkMaker && $scope.chkModel && $scope.chkYear && $scope.chkDesc)
                {
                    $scope.fromWidgetMsg = true;
                    $('html, body').animate({scrollTop: $("#body_title_driver_main").offset().top - 90}, 'slow');
                    delete $sessionStorage.widgetItems;
                }else if(!$scope.chkMaker){
                    /* $('html, body').animate({scrollTop: $("#body_car_make").offset().top - 90}, 'slow');
                    $('#makeLabel').dropdown('toggle');
                    delete $sessionStorage.widgetItems; */
                }else if(!$scope.chkModel){
                    $('html, body').animate({scrollTop: $("#body_car_model").offset().top - 90}, 'slow');
                    $('#modelLabel').dropdown('toggle');
                    delete $sessionStorage.widgetItems;
                }else if(!$scope.chkYear){
                    $('html, body').animate({scrollTop: $("#body_car_year_manufac").offset().top - 90}, 'slow');
                    $('#yearLabel').dropdown('toggle');
                    delete $sessionStorage.widgetItems;
                }else if(!$scope.chkDesc){
                    $('html, body').animate({scrollTop: $("#body_car_sum_insured").offset().top - 90}, 'slow');
                    $('#descLabel').dropdown('toggle');
                    delete $sessionStorage.widgetItems;
                }else if(!$scope.chkCamera){
                    $('html, body').animate({scrollTop: $("#body_car_camera_installed").offset().top - 90}, 'slow');
                    delete $sessionStorage.widgetItems;
                }else{
                    //console.log("Can't load modal from widget message");
                }
            },
            scrollingTo: function(elemId){
                if($('html, body').width() <= 990){  // Scrolling only responsive size < 990 PX
                    $('html, body').animate({scrollTop: $("#"+elemId).offset().top - 90}, 'slow');
                }
            },
            getTranslateText: function(part) {
                if(HKLocale.getLocale() == 'th-th'){
                    switch(part){
                        case "maker":
                            return "เลือกยี่ห้อรถ";
                        break;
                        case "model":
                            return "เลือกรุ่นรถ";
                            break;
                        case "year":
                            return "เลือกรุ่นปีรถยนต์";
                            break;
                        case "desc":
                            return "เลือกรุ่นย่อย";
                            break;
                    }
                        
                }else{
                    switch(part){
                        case "maker":
                            return "Please select";
                        break;
                        case "model":
                            return "Please select";
                            break;
                        case "year":
                            return "Please select";
                            break;
                        case "desc":
                            return "Please select";
                            break;
                    }
                }
            },
            calculateTotalAmout: function(){
                if ((!$scope.coverages) || !$scope.coverages.Coverages) {
                    return 0;
                }
                var coverage = $filter('filter')($scope.coverages.Coverages, {IsSelected:true}, true)[0];
                if (!coverage) {
                    return 0;
                }
                var x=fn.utils.getPriceToShow(coverage);
                return(x);

            },

            getTotalAmout: function(){
                $scope.totalPremium2 = fn.calculateTotalAmout();
                return $scope.totalPremium2;
            },
            checkAllCarInfoCompleted: function(){
                if(($scope.asset.ManufactureId ) && 
                ($scope.asset.ModelId )&& 
                ($scope.asset.PurchasingYear )&& 
                ($scope.asset.CarDescriptionId )){
                    return true;
                }else{
                    return false;
                }
            },
            checkAllMainDriverCompleted: function(){
                if(
                    ($scope.mainDriver.DOB_DD  && $scope.mainDriver.DOB_MON && $scope.mainDriver.DOB_Year )
                    && ($scope.mainDriver.GenderId) && ($scope.mainDriver.MaritalStatusId) && 
                    (($scope.asset.InDriveWork == false) || (($scope.asset.InDriveWork == true) && ($scope.asset.InCourseWork !== undefined))) 
                )
                {
                    return true;
                }else{
                    return false;
                }
            },
            checkAllContactInfoCompleted: function(){
                if($scope.phContact.PrimaryPhoneNumber  && $scope.policyHolder.FirstName && $scope.phContact.PrimaryEmailAddress )
                {
                    $scope.contactInfoComplete = true;
                }else{
                    $scope.contactInfoComplete = false;
                }
            },

            nextToMainDriver: function(){
                
                if(fn.checkAllCarInfoCompleted()){
                        $scope.showMainDriverFlag = true;
                        fn.scrollingTo("body_title_driver_main_part");
                        $('#yourCar').collapse('hide');
                        $('#maindriver').collapse('show');
                    }
            },
            nextToContactInfo : function(){
                if(fn.checkAllMainDriverCompleted() && fn.checkAllCarInfoCompleted()){
                    $scope.showContactInfoFlag = true;
                    fn.scrollingTo("body_contact_info_part");
                    $('#maindriver').collapse('hide');
                    $('#contactInfoBody').collapse('show');
                }
            },
            assetStaticData : {
                make : [],
                model : [],
                year: [],
                description : []
            },
            disablePremium: function(){
                if($sessionStorage.currentPage == 1){
                    return "disabled";
                }else{
                    return "active";
                }
                
            },
            goToPage: function(page){
                window.scrollTo(0, 0);
                    if(page == 1){
                        $scope.firstPageShow = true;
                        $scope.secondPageShow = false;
                        $scope.thirdPageShow = false;
                        window.location.hash = "#start";
                        $sessionStorage.currentPage = 1;
                        //marketing.ga();
                    }else if(page == 2){
                        $scope.firstPageShow = false;
                        $scope.secondPageShow = true;
                        $scope.thirdPageShow = false; 
                        $scope.isSaveAndExitShowName = true;
                        window.location.hash = "#your-quote";
                        $sessionStorage.currentPage = 2;
                    }else if(page == 3){
                        $scope.firstPageShow = false;
                        $scope.secondPageShow = false;
                        $scope.thirdPageShow = true;
                        $scope.isSaveAndExitShowName = false;
                        window.location.hash = "#review";
                        $sessionStorage.currentPage = 3;
                    }else{  // default page 1
                        $scope.firstPageShow = true;
                        $scope.secondPageShow = false;
                        $scope.thirdPageShow = false;
                        window.location.hash = "#start";
                        $sessionStorage.currentPage = 1;
                    }
            },
            setPreviousPage: function(val){
                $scope.previousPage = val;
            },
            refreshCurrentPage: function(page){
                fn.goToPage(page);
                
            },
            updateAssetMaxarPriority: function(callbacks){
                var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');
                fn.assetApi[saveAssetFunc](callbacks);
            },
            selectIAmount: function(iAmount){
                $scope.selectedInsureAmount = iAmount;
                $scope.coverages.Coverages.forEach(function(cc, xx){
                    if((cc.CoverTypeId == 2000017 || cc.CoverTypeId == 2000019) && cc.IsSelected == true){
                        $scope.coverages.Coverages[xx].Covers.forEach(function(plo, yy){
                            if(plo.ProductLineGroupId == 2000105){
                                $scope.coverages.Coverages[xx].Covers[yy].InsuranceAmountLevelId = iAmount;
                                fn.saving.saveCoveragesModel({
                                    onSuccess: function(){
                                        $scope.loadingSpinner = true;
                                        $scope.loadingSpinner2 = true;
                                        $scope.loadingSpinner3 = true;
                                        fn.quoteApi.calculate({
                                            onSuccess: function(){
                                                //console.log("re-calculate success");
                                                fn.updatesCoveragesSPA();
                                            },
                                            onError: function(){
                                                //console.log("re-calculate failed");
                                                
                                            }
                                        });
                                    },
                                    onError: function(){
                                        //console.log("Save coverage failed.");
                                    }
                                });
                                
                            }
                        });
                    }

                });
            },
            setGAIAmount: function(iAmount){
                marketing.dLCE(50, iAmount);
            },
            setDefaultInsureAmount: function(){
                var sc = fn.utils.getSelectedCoverage();
                if(sc.CoverTypeId == 2000017 || sc.CoverTypeId == 2000019){
                    var plo = $filter('filter')(sc.Covers, {ProductLineGroupId : 2000105});
                    if(plo && plo[0]){
                        $scope.selectedInsureAmount = plo[0].InsuranceAmountLevelId;
                        fn.setGAIAmount(plo[0].InsuranceAmount);
                    }
                }
            },
            setBodyType: function(val){
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.bodyType = val;
                $scope.gearType = '';
                if($scope.bodyType === '2doors'){
                    DaModal.open("#makeAndModelNotAvailable");
                }
                //console.log('bodytype : ' + $scope.bodyType);
            },
            setGearType: function(val){
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.gearType = val;
                //console.log('gearType : ' + $scope.gearType);
            },
            isPopularTruck: function(){
                var popularTruckModel = ['D-Max', 'Hilux Revo', 'Hilux Vigo', 'Triton'];
                if(popularTruckModel.indexOf($scope.txtModel) < 0){
                    return false;
                }else{
                    return true;
                }
            }
            
        };

        /** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initSubscribeWatcher();
        fn.initStaticData();
        fn.retrieveScopeObjects();
        //fn.loadWidgetValue();
        // if ($scope.HKLocale.getLocale() === "th-th") { 
        //     $window.cwcCookieBanner.setLang('th'); 
        // }else{
        //     $window.cwcCookieBanner.setLang('en');
        // }
        /** Event Handlers */
        $scope.onBtnCalculatePremiumClicked = fn.eventHandlers.onBtnCalculatePremiumClicked;
        $scope.onDropMakeChanged = fn.eventHandlers.onDropMakeChanged;
        $scope.onDropModelChanged = fn.eventHandlers.onDropModelChanged;
        $scope.onDropPurchasingYearChanged = fn.eventHandlers.onDropPurchasingYearChanged;
        $scope.onBtnInDriveWorkChange = fn.eventHandlers.onBtnInDriveWorkChange;
        $scope.onPolicyStartDateChanged = fn.eventHandlers.onPolicyStartDateChanged;
        $scope.onIsPHMDButton = fn.eventHandlers.onIsPHMDButton;
        $scope.onBtngoToLandingpage = fn.eventHandlers.onBtngoToLandingpage;
        $scope.onCarDescriptionChanged = fn.eventHandlers.onCarDescriptionChanged;
        $scope.onBtnNCDClicked = fn.eventHandlers.onBtnNCDClicked;
        $scope.onBtnNCDSelected = fn.eventHandlers.onBtnNCDSelected;
        $scope.onNCBApplyRequest = fn.eventHandlers.onNCBApplyRequest;
        $scope.getTotalAmout=fn.getTotalAmout;
        $scope.nextToMainDriver = fn.nextToMainDriver;
        $scope.checkAllCarInfoCompleted = fn.checkAllCarInfoCompleted;
        $scope.nextToContactInfo = fn.nextToContactInfo;
        $scope.checkAllContactInfoCompleted = fn.checkAllContactInfoCompleted;

        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.selectCoverage=fn.eventHandlers.selectCoverage;
        $scope.resetCompulsory=fn.eventHandlers.resetCompulsory;
        $scope.onBtnCoverExplainedClicked = fn.eventHandlers.onBtnCoverExplainedClicked;
        $scope.onBtnCoverExplainedSelected = fn.eventHandlers.onBtnCoverExplainedSelected;
        $scope.isCoverNotAvail = fn.validations.isCoverNotAvail;
        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onFullDetailClicked = fn.eventHandlers.onFullDetailClicked;
        $scope.updateDeductibleAsset = fn.eventHandlers.updateDeductibleAsset;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;
        $scope.disablePremium = fn.disablePremium;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnBackClicked2 = fn.eventHandlers.onBtnBackClicked2;
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.setBodyType = fn.setBodyType;
        $scope.setGearType = fn.setGearType;
        $scope.isPopularTruck = fn.isPopularTruck;
        /** Validations */
        $scope.validations = {
            checkDOBEmpty: fn.validations.checkDOBEmpty,
            isStartDateInvalid: fn.validations.isStartDateInvalid,
            isEndDateInvalid: fn.validations.isEndDateInvalid,
            isValidDriverAge: $scope.$parent.isInvalidDriverAge
        };

        $scope.checkDrivingExp = fn.validations.checkDrivingExp;
        $scope.checkNCDLevel = fn.validations.checkNCDLevel;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.getPriceToShow=fn.utils.getPriceToShow;
        $scope.getTotalAmout=fn.getTotalAmout;
        $scope.convertMITDate = fn.utils.convertMITDate;
        $scope.checkMake=fn.validations.checkMake;
        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;

        $scope.setMaker = fn.eventHandlers.setMaker;
        $scope.setModel = fn.eventHandlers.setModel;
        $scope.setYear  = fn.eventHandlers.setYear;
        $scope.setDesc  = fn.eventHandlers.setDesc;
        $scope.showModel = fn.eventHandlers.showModel;
        $scope.showYear = fn.eventHandlers.showYear;
        $scope.showDesc = fn.eventHandlers.showDesc;
        $scope.toThaiYear = fn.toThaiYear;
        $scope.dLCE=marketing.dLCE;
        $scope.gtAnchor=fn.gtAnchor;
        $scope.int = parseInt;
        $scope.gaEvents = $scope.$parent.gaEvents;
        $scope.scrollingTo = fn.scrollingTo;
        $scope.selectIAmount = fn.selectIAmount;
        /* $scope.postBLFC = fn.postBLFC;
        $scope.postUWFC = fn.postUWFC; */
        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function() {
            $timeout(function () {
                // Post
                fn.refreshCurrentPage($sessionStorage.currentPage);
            }, 0);
            $timeout(function () {
                // Post
                fn.checkFromWidget();
            }, 3500);
            
        });
        
        
    }];