module.exports = [
    '$window',
    'Policy',


    function DownloadService($window,Policy) {
        var fn = {


            downloadDocument: function btnDownloadClicked(scope, docObj) {
                Policy.downloadDocument(undefined, {
                    DocumentId: docObj.DocumentID
                });
            }
        };

        this.downloadDocument = fn.downloadDocument;
    }];