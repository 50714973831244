/*
 * Created by Chanon Tangputthajit 2018/03/20.
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    '$sessionStorage',
    /**
     * Controller for the Partial Save Router
     * @constructor
     */
    function WidgetRouterVariantController($scope, $state, $stateParams, $sessionStorage) {


        var fn = {

            unhashParam: function() {
                //console.log('Go from widget router')
                
                $scope.widgetItems={};

               ///widgetlanding?make&model&registrationyear&desc&cameraq
                $scope.widgetItems.make=$stateParams.make || '';
                $scope.widgetItems.model=$stateParams.model || '';
                $scope.widgetItems.registrationyear=$stateParams.registrationyear || '';
                $scope.widgetItems.desc=$stateParams.desc || '';
                $scope.widgetItems.cameraq=$stateParams.cameraq || '';
                $scope.widgetItems.lang=$stateParams.lang || '';
                $sessionStorage.widgetItems = $scope.widgetItems;
                var productState = 'widget.sessiontoken';
                $state.go(productState);

            },

        };

        fn.unhashParam();

    }];