/*
 * Created by Gerald Garcia on 03/03/15.
 */
module.exports = [
    '$scope',
    'CurrentProposal',
    'DaModal',
    'DaLoadingMask',
    /**
     * The controller that handles the Partial Save action.
     * @param $scope
     * @param CurrentProposal
     * @param DaModal
     * @param Policy
     * @constructor
     */
    function PartialSaveQuoteController($scope, CurrentProposal, DaModal, DaLoadingMask) {

        var fn = {

            initScope: function() {

                var pAndCModel =  $scope.$parent.baseMitScopeBindingUtils.getScopePAndCModel();

                $scope.phContact = {};
                angular.extend($scope.phContact, pAndCModel.phContact);

                $scope.policyHolder = {};
                angular.extend($scope.policyHolder, pAndCModel.policyHolder);

                $scope.currentProposal = {};
                angular.extend($scope.currentProposal, pAndCModel.currentProposal);

                $scope.confirmation = {};
                angular.extend($scope.confirmation, pAndCModel.confirmation);
                
                $scope.IsSavingPartialSave = false;
            },

            initWatchers: function(){
                var
                    //currentProposalWatcher, assetWatcher, policyHolderWatcher,
                    //namedDriverWatcher, mainDriverWatcher, phAddressWatcher,
                    //phContactWatcher, sessionWatcher,
                    confirmationWatcher, confirmationCreateWatcher;
                    //policyHolderLocalWatcher;

                var pAndCCallBack = function(newValueModel){
                    //console.log('PAndCModelWatcher loadTime: ' + loadTime);
                    if (!newValueModel) {
                        return;
                    }

                    //sessionWatcher = $scope.$parent.$on('Session/Bind', sessionCallBack);
                    //
                    //currentProposalWatcher = $scope.$parent.$on('CurrentProposal/Bind', currentProposalCallBack);
                    //assetWatcher = $scope.$parent.$on('Asset/Bind', motorcycleCallBack);
                    //policyHolderWatcher = $scope.$parent.$on('PolicyHolder/Bind', phCallBack);
                    //mainDriverWatcher = $scope.$parent.$on('MainDriver/Bind', mdCallBack);
                    //namedDriverWatcher = $scope.$parent.$on('NamedDriver/Bind', ndCallBack);
                    //
                    //phAddressWatcher = $scope.$parent.$on('PHAddress/Bind', phAddressCallBack);
                    //phContactWatcher = $scope.$parent.$on('PHContact/Bind', phContactCallBack);

                    confirmationWatcher = $scope.$parent.$on('Confirmation/Bind', confirmationCallBack);
                    confirmationCreateWatcher = $scope.$parent.$on('Confirmation/Create', confirmationCreateCallBack);

                    //policyHolderLocalWatcher = $scope.$watch('policyHolder', fn.phLocalCallBack, true);

                    if ($scope.$parent.baseMitScopeBindingUtils) {
                        $scope.$parent.baseMitScopeBindingUtils.firstBind();
                    }
                };

                //var sessionCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.currentProposal;
                //    //console.log('sessionCallBack loadTime: ' + loadTime);
                //    angular.extend($scope.session, newValue);
                //};

                var confirmationCallBack = function(eventObj, newValue){
                    //newValue = $scope.$parent.stepPAndCModel.currentProposal;
                    //console.log('currentProposalCallBack loadTime: ' + loadTime);
                    angular.extend($scope.confirmation, newValue);
                };

                var confirmationCreateCallBack = function(eventObj, newValue){

                    //newValue = $scope.$parent.stepPAndCModel.currentProposal;
                    //console.log('confirmationCreateCallBack loadTime: ' + new Date().getTime());
                    //console.log(newValue);
                    angular.extend($scope.policy, newValue);
                    $scope.$parent.baseMitScopeBindingUtils.saveToScopePAndCModel('currentProposal', $scope.policy);
                    //console.log('end - confirmationCreateCallBack loadTime: ' + new Date().getTime());
                };

                //var currentProposalCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.currentProposal;
                //    //console.log('currentProposalCallBack loadTime: ' + loadTime);
                //    //angular.extend($scope.policy, newValue);
                //    if (newValue.PolicyStartDate) {
                //
                //    }
                //};
                //
                //var motorcycleCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.asset;
                //    //console.log('motorcycleCallBack loadTime: ' + loadTime);
                //    if (newValue.ManufactureId){
                //        $scope.$parent.dropMakeChanged(newValue.ManufactureId);
                //    }
                //    angular.extend($scope.motorcycle, newValue);
                //
                //    if($scope.motorcycle.UsageId === '2000001'){
                //        $scope.motorcycle.TempUsageId = '0' ;
                //        $scope.motorcycle.TempUsageId2 = '0';
                //    }
                //
                //    if($scope.motorcycle.UsageId === '2000000'){
                //        $scope.motorcycle.TempUsageId = '1' ;
                //        $scope.motorcycle.TempUsageId2 = '1';
                //    }
                //
                //    if($scope.motorcycle.UsageId === '2000002'){
                //        $scope.motorcycle.TempUsageId = '1' ;
                //        $scope.motorcycle.TempUsageId2 = '0';
                //    }
                //};
                //
                //var phCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.policyHolder;
                //    //console.log('phCallBack loadTime: ' + loadTime);
                //    angular.extend($scope.policyHolder, newValue);
                //    //if (newValue && newValue.IsMainDriver) {
                //    //    $scope.mainDriver.Identifier = newValue.Identifier;
                //    //}
                //};
                //
                //var mdCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.mainDriver;
                //    //console.log('mdCallBack loadTime: ' + loadTime);
                //    angular.extend($scope.mainDriver, newValue);
                //};
                //
                //var ndCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.namedDriver;
                //    //console.log('ndCallBack loadTime: ' + loadTime);
                //    angular.extend($scope.namedDriver, newValue);
                //};
                //
                //var phAddressCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.namedDriver;
                //    //console.log('phAddressCallBack loadTime: ' + loadTime);
                //    angular.extend($scope.phAddress, newValue);
                //};
                //
                //var phContactCallBack = function(eventObj, newValue){
                //    //newValue = $scope.$parent.stepPAndCModel.namedDriver;
                //    //console.log('phContactCallBack loadTime: ' + loadTime);
                //    angular.extend($scope.phContact, newValue);
                //};

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        //console.log('quote is destroyed');
                        //sessionWatcher();
                        //currentProposalWatcher();
                        //assetWatcher();
                        //policyHolderWatcher();
                        //namedDriverWatcher();
                        //mainDriverWatcher();
                        //
                        //phContactWatcher();
                        //phAddressWatcher();

                        confirmationWatcher();
                        confirmationCreateWatcher();


                        //policyHolderLocalWatcher();

                        destroyWatcher();
                    }
                );

                pAndCCallBack({});

            },

            confirmQuote: function () {
                var onSuccess = function () {
                    var msgObj = {
                        header: 'Success',
                        message: 'Thank you! Your quote has been saved. Please check your email to retrieve your quote.'
                    };

                    DaModal.alert(msgObj);
                };

                $scope.$parent.baseMitScopeBindingUtils.saveConfirmation($scope.confirmation, onSuccess);
            },

            IsSavingPartialSave: false,

            setIsSavingPartialSave: function (isSaving) {
                $scope.IsSavingPartialSave = isSaving;
                DaLoadingMask.toggle(isSaving);
            },

            policyHolderSave: function(onSuccess){

                var objectsToSave = ['policyHolder'];

                $scope.$parent.baseMitScopeBindingUtils.setSavingObjects($scope, objectsToSave, function () {

                        $scope.$parent.baseMitScopeBindingUtils.saveToScopePAndCModel('policyHolder', $scope.policyHolder);
                        $scope.$parent.baseMitScopeBindingUtils.triggerBind('PolicyHolder', 'policyHolder');

                        fn.phContactSave(onSuccess);
                    },
                    fn.partialSaveHandler.onSavingErrorCallback
                );

                $scope.$parent.baseMitScopeBindingUtils.savePolicyHolder($scope.policyHolder);

            },

            phContactSave: function(onSuccess){

                var objectsToSave = ['phContact'];

                $scope.$parent.baseMitScopeBindingUtils.setSavingObjects($scope, objectsToSave, function () {

                        $scope.$parent.baseMitScopeBindingUtils.saveToScopePAndCModel('phContact', $scope.phContact);
                        $scope.$parent.baseMitScopeBindingUtils.triggerBind('PHContact', 'phContact');

                        if (onSuccess) {
                            onSuccess();
                        }
                    },
                    fn.partialSaveHandler.onSavingErrorCallback
                );

                $scope.$parent.baseMitScopeBindingUtils.savePHContact($scope.phContact);

            },

            doPartialSave: function(){
                $scope.$parent.baseMitScopeBindingUtils.partialSave(fn.partialSaveHandler.onSavingCallback, fn.partialSaveHandler.onSavingErrorCallback);
            },

            hasInvalid: function (frmPartialSave) {
                return frmPartialSave.$invalid;
            },

            partialSaveHandler: {

                onSavingCallback: function onSavingCallback(data){

                    if (data && data.success) {
                        DaModal.open('#partialSaveSuccessModal');
                    }

                    fn.setIsSavingPartialSave(false);
                    fn.partialSaveHandler.onBtnPartialSaveCancelClicked();
                },

                onSavingErrorCallback: function onSavingErrorCallback(data) {
                    fn.partialSaveHandler.onSavingCallback(data);
                },

                onBtnSaveAndExitClicked: function onBtnSaveAndExitClicked() {
                    fn.initScope();

                    if($scope.currentProposal.ExternalProposalNumber){
                        //fn.initWatchers();
                        fn.confirmQuote();
                    }else{

                        DaModal.open('#quotationPartialSave');
                    }


                },

                onBtnPartialSaveCancelClicked: function onBtnPartialSaveCancelClicked() {
                    DaModal.close('#quotationPartialSave');
                },

                onBtnPartialSaveContinueClicked: function onBtnPartialSaveContinueClicked(frmPartialSave) {

                    if (fn.hasInvalid(frmPartialSave)) {
                        return;
                    }

                    fn.setIsSavingPartialSave(true);

                    // If BI log needed for Save Quote only.
                    //if ($scope.proposal.productId === Policy.constants.ProductTypes.TERM_LIFE) {
                    //    if (!$scope.stepFn.isRenewalProposal()) {
                    //        BILogging.writeLog($scope.coverages);
                    //    }
                    //}

                    fn.policyHolderSave(fn.doPartialSave);

                    //fn.phContactSave(fn.doPartialSave);
                },

                onBtnSaveAndExitIsVisible: function onBtnSaveAndExitIsVisible() {
                    var pAndCModel =  $scope.$parent.baseMitScopeBindingUtils.getScopePAndCModel();

                    var isPartialSavePossible = function () {
                        if (!pAndCModel.currentProposal.StatusCodeId) {
                            return true;
                        }

                        var statusCode = pAndCModel.currentProposal.StatusCodeId || '';

                        return statusCode === '' ||
                        (statusCode) === CurrentProposal.QuoteStatusCodes.PARTIAL_SAVE;
                    };

                    return pAndCModel.currentProposal.success && isPartialSavePossible();
                }
            }

        };

        $scope.$parent.partialSaveHandler = fn.partialSaveHandler;
        $scope.eventHandler = fn.partialSaveHandler;
        $scope.IsSavingPartialSave = fn.IsSavingPartialSave;
        $scope.hasInvalid = fn.hasInvalid;

    }];