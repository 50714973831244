module.exports = [
  'dataLayerService',
  function dataLayer (dataLayerService) {
    return {
      restrict: 'AE',

      scope: {
        onClick: '&dataLayerOnClick',
        onChange: '&dataLayerOnChange',
        onInput: '&dataLayerOnInput',
        onView: '&dataLayerOnView',
        onHook: '&dataLayerOnHook'
      },

      link: function (scope, element, attrs) {
        let triggers = {
          click: null,
          change: null,
          input: null,
          view: null,
          hook: null
        }

        let updateTrigger = function (key, type, message, options) {
          let trigger = triggers[type]

          if (trigger && trigger.key === key) {
            trigger.message = message
            return
          }

          if (trigger && trigger.key !== key) {
            dataLayerService.removeTrigger(trigger)
          }

          triggers[type] = dataLayerService.addTrigger(key, type, message, options)
        }

        scope.$watch('onClick', value => {
          if (!value()) return
          let [key, message] = value()
          let type = 'click'
          let options = { element: element.get(0) }
          updateTrigger(key, type, message, options)
        })

        scope.$watch('onChange', value => {
          if (!value()) return
          let [key, message] = value()
          let type = 'change'
          let options = { element: element.get(0) }
          updateTrigger(key, type, message, options)
        })

        scope.$watch('onInput', value => {
          if (!value()) return
          let [key, message] = value()
          let type = 'input'
          let options = { element: element.get(0), once: true }
          updateTrigger(key, type, message, options)
        })

        scope.$watch('onView', value => {
          if (!value()) return
          let [key, message] = value()
          let type = 'view'
          let options = { element: element.get(0), once: true }
          updateTrigger(key, type, message, options)
        })

        scope.$watch('onHook', value => {
          if (!value()) return
          let [key, after, message] = value()
          let type = 'hook'
          let options = { element: element.get(0), after }
          updateTrigger(key, type, message, options)
        })

        element.on('$destroy', () => {
          if (triggers.click) {
            dataLayerService.removeTrigger(triggers.click.key)
          }

          if (triggers.change) {
            dataLayerService.removeTrigger(triggers.change.key)
          }

          if (triggers.input) {
            dataLayerService.removeTrigger(triggers.input.key)
          }

          if (triggers.view) {
            dataLayerService.removeTrigger(triggers.view.key)
          }

          if (triggers.hook) {
            dataLayerService.removeTrigger(triggers.hook.key)
          }
        })
      }
    }
  }
]