/**
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$localStorage',
    '$window',
    '$state',
    '$stateParams',
    'MITDate',
    'DaModal',
    'AccountProposal',
    'MitScopeBindingUtils',
    'WfeApiPolicy',
    'HKLocale',
    'WfeApiStaticData',
    'HKDownload',
    'WfeApiProposal',
    'WfeApiSession',
    'DaLoadingMask',
    'Policy',

    function BrokerPoliciesController($scope, $localStorage, $window, $state, $stateParams,
                                MITDate, DaModal, AccountProposal, MitScopeBindingUtils, WfeApiPolicy,
                                HKLocale, WfeApiStaticData, HKDownload, WfeApiProposal, WfeApiSession, DaLoadingMask, Policy) {

        var fn = {
            setIsLoading: function (isLoading) {
                DaLoadingMask.toggle(isLoading);
            },
            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getProductList();
                };

                var getProductList = function () {
                    wfeApiStaticData.getProductList({
                        onSuccess: function (data) {
                            $scope.staticData.products = data;
                        }
                    });
                };

                startRetrieval();
            },

            initApiObjects: function () {
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), undefined);
                fn.policyApi = WfeApiPolicy.instance(undefined, $scope, $scope.currentUser.existingPolicies);
                fn.policyRetrieveApi = WfeApiPolicy.instance(undefined, $scope, $scope.policy);
                fn.policyDownloadApi = WfeApiPolicy.instance(undefined, $scope, $scope.Document);

            },

            initScopeObjects: function () {
                fn.renewalProposalApi = WfeApiProposal.instance(undefined, $scope, $scope.renewalProposal);
                $scope.selectedDocument = {};
                $scope.renewalProposal = {};
                $scope.currentUser = {
                    existingPolicies: {},
                    documentsForDownload: {},
                    renewalProposals: {}//Proposal.retrieveExistingRenewalProposals()
                };

                $scope.policy = {};
                $scope.Document = {};
                $scope.agentPolicies = {};

                $scope.staticData = {
                    products: []
                };
                $scope.pagination = {};
                $scope.pagination.offset = 0;
                $scope.pagination.pageSize = 25;
                $scope.pagination.pageList = [];
                $scope.search = {};
                $scope.search.text = "";
                $scope.isLoading = true;
            },

            retrieveScopeObjects: function () {
                fn.policyApi.RetrieveAgentPolicies(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.agentPolicies, data.AgentPolicies);
                            var ap = $scope.agentPolicies?$scope.agentPolicies:[] ;
                            $scope.pagination.total = Object.keys(ap).length;
                            var noOfPage = Math.ceil($scope.pagination.total / $scope.pagination.pageSize);
                            for(var i = 0; i < noOfPage; i++ ){
                                $scope.pagination.pageList.push(i + 1);
                            }
                            $scope.isLoading = false;
                        }
                    }
                );


            },

            isViewingPolicy: false,


            showDownloadModal: function () {
                DaModal.open('#downloadDocumentsModal');
            },

            utils: {
                isRenewalProposal: function (currentProposal) {
                    var renewalList = $scope.currentUser.renewalProposals;
                    if (renewalList && currentProposal) {
                        for (var i = 0; i < renewalList.length; i++) {
                            var renewalProposal = renewalList[i];
                            var renewalNumber = renewalProposal.ExternalProposalNumber;
                            var ProductId = renewalProposal.ProductId;
                            if (ProductId === '1000009' && renewalNumber.indexOf(currentProposal.ExternalPolicyNumberDisplay) >= 0) {
                                return true;
                            }
                        }
                    }
                    return false;
                },
                getRenewalProposalNo: function (currentProposal) {
                    var renewalList = $scope.currentUser.renewalProposals;
                    if (renewalList && currentProposal) {
                        for (var i = 0; i < renewalList.length; i++) {
                            var renewalProposal = renewalList[i];
                            var renewalNumber = renewalProposal.ExternalProposalNumber;
                            if (renewalNumber.indexOf(currentProposal.ExternalPolicyNumberDisplay) >= 0) {
                                return renewalNumber;
                            }
                        }
                    }
                    return false;
                }

            },
            eventHandler: {

                btnRenewPolicyClicked: function (policySelected) {
                    //do session here
                    var productCode = policySelected.ProductId;
                    var sessionApi = WfeApiSession.instance($scope, productCode);
                    var tokenId = '';
                    $scope.renewalProposal.policyNumber = fn.utils.getRenewalProposalNo(policySelected);
                    fn.renewalProposalApi = WfeApiProposal.instance(undefined, $scope, $scope.renewalProposal);
                    var retrieveRenewal = function () {
                        fn.renewalProposalApi.retrieveRenewal({
                            onSuccess: function (data) {


                                tokenId = data.MITSessionToken;
                                fn.setIsLoading(false);

                                var parameter = {
                                    token: tokenId,

                                    productCode: productCode
                                };


                                $state.go('private.account.user.viewRenewConfirm', parameter);
                            },
                            onError: function () {
                                fn.setIsLoading(false);
                                console.log('error');
                            }
                        });
                    };

                    var todayDate = MITDate.newDate();
                    var endDate = MITDate.newDate(policySelected.PolicyEndDate || policySelected.EndorsEndDate).add(1, 'days');

                    if (todayDate.isBefore(endDate)) {
                        fn.setIsLoading(true);
                        sessionApi.createSession({
                            onSuccess: function () {

                                retrieveRenewal();


                            }, onError: function () {
                                fn.setIsLoading(false);
                                console.log('error');
                            }
                        });
                    } else {
                        DaModal.open('#expiredRenewal');
                    }

                },

                btnViewPolicyClicked: function btnDownloadClicked(policy) {

                    var parameter = {
                        policyNumber: policy.PolicyNumber,
                        productId: 2000003
                    };
                    
                    $state.go('publicB2B.motor.manage.steps.viewpolicy', parameter);
                },

                btnShowDownloadClicked: function btnDownloadClicked(policy) {
                    if (!(policy.PolicyNumber || policy.PolicyNumber)) {
                        return;
                    }
                    $scope.policy.policyNumber = policy.PolicyNumber;
                    //console.log($scope.policy);
                    fn.showDownloadModal();

                    fn.policyRetrieveApi.retrievePolicyDocuments({
                        onSuccess: function (data) {
                            angular.extend($scope.currentUser.documentsForDownload, data);
                        },
                        onError: function () {
                            console.log('errors');
                        }
                    });
                    
                },

                btnDownloadDocumentClicked: function btnDownloadClicked(docObj) {
                    // call api
                    Policy.downloadDocument(undefined, {
                        DocumentId: docObj.DocumentID
                    });

                },
                showListAgentPolicies: function(){                   
                    
                    var proposals = [];
                    var start = $scope.pagination.offset * $scope.pagination.pageSize;
                    var end   = start + $scope.pagination.pageSize;
                    for(var i = start; i < end; i++){
                        if($scope.agentPolicies[i]){
                            if($scope.search.text !== ""){
                                var firstname = $scope.agentPolicies[i].FirstName;
                                var lastname = $scope.agentPolicies[i].LastName;
                                var carplate = $scope.agentPolicies[i].CarPlate;
                                var phonenumber = $scope.agentPolicies[i].Phone;
                                var policynumber = $scope.agentPolicies[i].PolicyNumber;

                                if(firstname.includes($scope.search.text) || 
                                   lastname.includes($scope.search.text) ||
                                   carplate.includes($scope.search.text) ||
                                   phonenumber.includes($scope.search.text) ||
                                   policynumber.includes($scope.search.text)){
                                    proposals.push($scope.agentPolicies[i]);
                                }
                            }else{
                                proposals.push($scope.agentPolicies[i]);
                            }
                            
                        }
                    }

                    return proposals;

                },
                goPage: function(page){
                    $scope.pagination.offset = page - 1;
                }
            }


        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        //Scroll to top
        $window.scrollTo(0, 0);

        $scope.isViewingPolicy = fn.isViewingPolicy;

        //Event Handlers
        $scope.btnShowDownloadClicked = fn.eventHandler.btnShowDownloadClicked;
        $scope.btnViewPolicyClicked = fn.eventHandler.btnViewPolicyClicked;
        $scope.btnDownloadDocumentClicked = fn.eventHandler.btnDownloadDocumentClicked;
        $scope.btnRenewPolicyClicked = fn.eventHandler.btnRenewPolicyClicked;
        $scope.formatDate = MITDate.toAngularDate;
        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.utils.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.showListAgentPolicies = fn.eventHandler.showListAgentPolicies;
        $scope.goPage = fn.eventHandler.goPage;

    }];