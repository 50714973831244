/*
 * Created by Chanon Tangputthajit 07/05/2021
 */
module.exports = [
    'ConfigUtils',
    '$location',
    '$scope',
    '$state',
    '$stateParams',
    '$anchorScroll',
    'DaModal',
    'Confirmation',
    'Payment',
    'WfeApiLog',
    'MitLogUtils',
    'WfeApiCurrentProposal',
    'WfeApiPayment',
    '$localStorage',
    '$window',
    /**
     * Controller for the Payment
     * @constructor
     */
        function B2CCybersourceCallbackController(ConfigUtils, $location, $scope, $state, $stateParams, $anchorScroll, DaModal,
                                               Confirmation, Payment, WfeApiLog, MitLogUtils,WfeApiCurrentProposal, WfeApiPayment, $localStorage, $window) {
        $anchorScroll();

        var fn = {

            goToNextPage: function (){

                var parameters = {
                    token: $scope.payment.MITSessionToken,
                    productState: $scope.product.ProductCode
                };
                //console.log($stateParams);
                //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(8);
                $state.go('publicB2C.motor.quote2.steps.issue', parameters);

            },

            restart: function(){
                $state.go('public.dashboard');
            },

            savePayment: function(creditCard, callbacks) {
                callbacks = callbacks || {};
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return false;
                }

                var onSuccess = function (data) {
                    creditCard.PaRes = data.pares;
                    creditCard.XidRes = data.md;
                    fn.updatePayment(creditCard, callbacks);
                };

                fn.eventHandler.logEvent('/app/ui/code/cybersource.response.aspx', 'Before getting data from cybersource.response.aspx');

                Payment.getCybersourcePaymentResponse(creditCard, onSuccess,
                    function () {
                        fn.eventHandler.errorOnRequest('getCybersourcePaymentResponse', arguments);
                    }
                );

                return true;
            },

            createPayment: function(creditCard, callbacks) {

                return fn.updatePayment(creditCard, callbacks);
            },

            updatePayment: function(creditCard, callbacks) {
               // alert("update payment");

                var dummyCreditCard = {
                    PaRes: creditCard.PaRes,
                    XidRes: creditCard.XidRes
                };

                /*var dummyCreditCard = {
                    PaRes: "123",
                    XidRes: 123
                };*/

                fn.eventHandler.logEvent(ConfigUtils.api + '/DataServices/Payment/UpdatePaymentStatus', 'Before update of PARes and MD', dummyCreditCard);

                Payment.updatePaymentStatus(undefined, creditCard,
                    function createMotorcyclePaymentSuccess(data){
                        if (!data.success) {
                            fn.eventHandler.errorOnRequest('updatePaymentStatus-successblock', arguments);
                            return;
                        }

                        fn.confirmPayment(creditCard, callbacks);

                    },

                    function () {
                        fn.eventHandler.errorOnRequest('updatePaymentStatus', arguments);
                    }
                );

                return true;
            },

            confirmPayment: function(creditCard, callbacks) {
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return;
                }

                fn.eventHandler.logEvent(ConfigUtils.api + '/TransactionServices/Confirmation/ProcessPayment', 'Before 2nd process payment');
                Confirmation.processPayment(undefined, creditCard,
                    function confirmMotorcycleSuccess(data){

                        if (!data.success) {
                            fn.eventHandler.errorOnRequest('processPayment-successblock', arguments);
                            return;
                        }

                        fn.eventHandler.logEvent(ConfigUtils.api + '/TransactionServices/Confirmation/ProcessPayment', '2nd process payment - Successful');
                        fn.postPaymentConfirmation(creditCard, data, callbacks);
                    },


                    function () {
                        fn.eventHandler.errorOnRequest('processPayment', arguments);
                    }
                );
            },

            postPaymentConfirmation: function(creditCard, data) {

                var isSuccess = true;
                //validate data here

                if (!data.success) {
                    $scope.hasError = true;
                    return;
                }


                if (isSuccess) {
                    fn.goToNextPage(data);
                }

            },

            hasInvalid: function hasInvalid(){
                return false;
            },

            initScopeObjects: function () {

                $scope.session = {};
                $scope.currentProposal = {};
                $scope.creditCard = {};
                $scope.payment = {};
                $scope.bidata = {};
            },

            initApiObjects: function () {
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.paymentApi = WfeApiPayment.instance($scope.$parent.sessionApi, $scope, $scope.payment);
                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, undefined);
            },

            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrieveCurrentProposal();
                };

                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                $scope.currentProposal.isForUpdate = true;
                                retrievePayment();
                            },
                            onError: function () {
                                retrievePayment();
                            }
                        }
                    );
                };

                var retrievePayment = function () {
                    fn.paymentApi.retrievePayment(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.payment, data);
                                $scope.payment.isForUpdate = true;

                                $scope.payment.CreditCard.MITSessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken;
                                fn.savePayment($scope.payment.CreditCard);
                            }
                        }
                    );
                };

                startRetrievalScopeObject();
            },

            eventHandler: {

                logEvent: function (processName, msg, data) {

                    var dataString;
                    try {
                        if (data) {
                            dataString = angular.toJson(data);
                            msg = msg + ' data= ' + dataString;
                        }
                    }
                    catch(err){}

                    try {
                        var newObj = {
                            uniqueID: $scope.session.MITSessionToken,
                            serviceName: processName,
                            msg: msg
                        };

                        MitLogUtils.eventLogWrite(newObj);
                    }
                    catch(err){}

                },

                errorOnRequest: function (processName, args) {

                    try {
                        var newObj = {
                            sourceUrl: $location.url(),
                            exceptionMsg: processName,
                            stacktrace: angular.toJson(args)
                        };

                        MitLogUtils.exceptionLogWrite(newObj);
                    }
                    catch(err){}

                    $scope.isPaymentSaving = false;
                    $scope.hasError = true;
                    console.error('ERR: There was a problem when saving information.');
                    $window.dataLayer = $window.dataLayer || [];var dataLayer = $window.dataLayer;
                    fn.forcePostToMaxParent(args);
                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(9);
                }

            },

            onBtnCallbackClicked :function(){
                DaModal.open('#quotationCallback');

            },

            onBtnCallbackContinueClicked: function () {

                $scope.pageSubmitted = true;
                $scope.callBackSubmitted = true;

                var regex = /^[0][6|8|9][0-9]\d{7}/;
                if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                    return;
                }

                if ($scope.policyHolder.FirstName.invalid ||$scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryPhoneNumber.invalid) {
                    return;
                }
                fn.postToMaxParent();
            },
            postToMaxParent:function(){
                $window.maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;
                var maxarLayer = $window.maxarLayer;
                maxarLayer.push( {

                    type:'Quote lead',
                    sourcePage:'BuyError',
                    callback:'Yes',
                    priority:1,
                    dataFinal:'Y',
                    language:'th-pf',
                    blank2: 'payment failed'
                });

                $scope.$parent.postMaxar();
            },
            forcePostToMaxParent:function(args){
                $window.maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;
                var maxarLayer = $window.maxarLayer;
                if(args){
                    var msg = args;
                }else{
                    var msg = 'payment failed';
                }
                maxarLayer.push( {

                    type:'Quote lead',
                    sourcePage:'BuyError',
                    callback:'No',
                    priority:1,
                    dataFinal:'Y',
                    language:'th-pf',
                    blank2: JSON.stringify(msg[0].ErrorInfo.Message)

                });

                $scope.$parent.postMaxar();
            },
            onBtnCallbackCancelClicked: function(){
                DaModal.close('#quotationCallback');
            }
        };


        fn.initScopeObjects();
        fn.initApiObjects();
        fn.retrieveScopeObjects();
        
        $scope.restart = fn.restart;
        $scope.onBtnCallbackClicked = fn.onBtnCallbackClicked;
        $scope.onBtnCallbackCancelClicked = fn.onBtnCallbackCancelClicked;

    }];