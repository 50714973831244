/*
 * Description :
 * routes.js defines the pages accessibility using "Dependency Injection" to inject various Route Providers
 * e.g. ConfigUtilProvider, $stateProvider, $translateProvider, $urlRouterProvider and etc. to configure route for
 * various view and bind to respective Controllers.
 *
 * Version Notes :
 * 13 Apr 2015 - [Gerald] Initial version
 * 20 Aug 2015 - [C.K.] Clean up the code and re-organized.
 *
 */

/*global config*/
module.exports = [
    'ConfigUtilsProvider',
    '$stateProvider',
    '$urlRouterProvider',
    '$locationProvider',
    'HttpUtilsProvider',
    '$translateProvider',
    'KeepaliveProvider',
    'IdleProvider',
    function (ConfigUtilsProvider, $stateProvider, $urlRouterProvider, $locationProvider, HttpUtilsProvider, $translateProvider, KeepaliveProvider, IdleProvider) {
        ConfigUtilsProvider.loadConfig(config);

        // *** To set warning message before session timeout ***
        IdleProvider.idle(300);
        IdleProvider.timeout(120); //number of seconds the message is shown
        KeepaliveProvider.interval(480);

        //For test
        // IdleProvider.idle(10);
        // IdleProvider.timeout(120); //number of seconds the message is shown
        // KeepaliveProvider.interval(20);


        // *** To configure language resource file using $translateProvider ***
        $translateProvider.useStaticFilesLoader({
            prefix: '/assets/content/',
            suffix: '.json'
        });

        $translateProvider.preferredLanguage('en-us');

        // *** To configure HTML5 History APIs using $locationProvider ***
        $locationProvider.html5Mode(true);

        // *** To configure redirection using $urlRouterProvider to redirect invalid URLs path e.g. /fire to root '/' ***
        $urlRouterProvider

            .when('/', ['$injector', function ($injector) {
                var $window = $injector.get('$window');
                var ConfigUtils = $injector.get('ConfigUtils');

                var homePageUrl = ConfigUtils.dashboardPageUrl || '/';

                if (homePageUrl.indexOf('http://') >= 0) {
                    $window.location.href = homePageUrl;
                    return;
                }

                return false;

            }])

            .otherwise('/login');

        // *** To configure both public and private states using $stateProvider ***
        $stateProvider
            .state('root', {
                abstract: true,
                template: '<div ui-view></div>',
                controller: 'RootController'
            })

            .state('public', {
                parent: 'root',
                abstract: true,
                url: '',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.html'),
                        controller: 'UserstateController'
                    },
                    'nav@public': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.nav.html'),
                        controller: null//'NavController'
                    },
                    'footer@public': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.footer.html'),
                        controller: 'UserstateController'
                    }
                }
            })

            .state('publicERroot', {
                parent: 'root',
                abstract: true,
                url: '',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.easyrenew.html'),
                        controller: 'UserstateController'
                    },
                    'nav@publicERroot': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.nav.html'),
                        controller: null//'NavController'
                    },
                    'footer@publicERroot': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.footer.html'),
                        controller: 'UserstateController'
                    }
                }
            })

            // PRIVATE VIEW
            /* .state('private', {
                parent: 'root',
                abstract: true,
                url: '/customer-access',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.html'),
                        controller: 'UserstateController'
                    },
                    'nav@private': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.nav.html'),
                        controller: null//'NavController'
                    },
                    'footer@private': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.footer.html')
                    }
                }
            }) */

            .state('private.changepassword', {
                title: 'DirectAsia | Change Password',
                url: '/change-password',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/account/password/change.password.html'),
                controller: 'ChangePasswordController'
            })

            .state('private.otppassword', {
                title: 'DirectAsia | OTP Password',
                url: '/otp-password',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/password/otp.password.html'),
                controller: null//'OtpPasswordController'
            })

            .state('private.account', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/account/account.html'),
                controller: 'AccountController'
            })

            .state('private.account.user', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/account/user/user.tab.html'),
                controller: 'PolicyUserTabController'
            })

            .state('private.account.user.profile', {
                title: 'DirectAsia | My Profile',
                url: '/profile',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/profile/my.profile.html'),
                        controller: 'MyProfileController'
                    },
                    'viewPersonalDetails@private.account.user.profile': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/profile/form.personal.details.html'),
                        controller: 'MyProfileFormPersonalDetailsController'
                    },
                    'viewPassword@private.account.user.profile': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/profile/form.password.html'),
                        controller: 'MyProfileFormPasswordController'
                    }
                }
            })

            .state('private.account.user.policies', {
                title: 'DirectAsia | Policy Listing',
                url: '/policies',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/policy/list/policy.list.html'),
                        controller: 'PolicyAccountPoliciesController'
                    },
                    'downloadContainer@private.account.user.policies': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/policy/download/download.html'),
                        controller: null//'NavController'
                    }
                }
            })

            .state('private.account.user.viewpolicy', {
                title: 'DirectAsia | View Policy',
                url: '/policies/:policyNumber/:productId',
                views: {
                    '': {
                        templateUrl:
                            function (params) {

                                var products = {
                                    '2000003': { url: '' }
                                };

                                var obj = products[params.productId];

                                var url = '';
                                if (obj) {
                                    url = obj.url;
                                }
                                if (url) {
                                    url = '.' + url;
                                }

                                return HttpUtilsProvider.getUrl('/app/ui/partial/policy/view/view' + url + '.html');
                            },
                        controller: 'PolicyViewPolicyController'
                    },
                    'downloadContainer@private.account.user.viewpolicy': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/policy/download/download.html'),
                        controller: null//'NavController'
                    }
                }
            })
            .state('private.account.user.viewQuote', {
                title: 'DirectAsia | View Policy',
                url: '/start/:token/:productCode',
                views: {
                    '': {
                        templateUrl: function (params) {
                            var products = {
                                '1000009': { url: 'motor' }
                            };

                            var obj = products[params.productCode];

                            var url = '';
                            if (obj) {
                                url = obj.url;
                            }

                            return HttpUtilsProvider.getUrl('/app/ui/partial/' + url + '/quote/steps.html');
                        },
                        controller: 'SessionTokenController'
                    }
                }
            })
            .state('public.pagerouter', {
                abstract: false,
                url: '/pagerouter?pm&lang',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/pagerouter/pagerouter.html'),
                controller: 'PageRouterController'
            })

            //below states for renewal journey- kept for future implementation.
            .state('public.motor.quote.steps.renewalLanding', {
                title: 'DirectAsia | Car - Quote Confirmation',
                url: '/confirm-quote/:isRenewalLanding',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/confirm.quote.html'),
                controller: 'MotorQuoteConfirmController'
            })
            .state('public.motor.quote.steps.declinerenewal', {
                url: '/decline-renewal',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/decline.renewal.html'),
                controller: 'DeclineRenewalController'
            })
            .state('public.motor.quote.steps.declinerenewalsuccess', {
                url: '/decline-renewal-success',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/decline.renewal.sucess.html'),
                        controller: 'DeclineRenewalSuccessController'
                    },
                    'marketingView@public.motor.quote.steps.declinerenewalsuccess': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/confirmation.marketing.html'),
                        controller: null
                    }
                }
            })
            .state('private.account.user.viewRenewConfirm', {
                title: 'DirectAsia | View Policy',
                url: '/confirm-quote/:token/:productCode',
                views: {
                    '': {
                        templateUrl: function (params) {
                            var products = {
                                '1000009': { url: 'motor' }
                            };

                            var obj = products[params.productCode];

                            var url = '';
                            if (obj) {
                                url = obj.url;
                            }


                            return HttpUtilsProvider.getUrl('/app/ui/partial/' + url + '/quote/steps.html');
                            //HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/confirm.quote.html')
                        },
                        controller: 'ViewRenewalProposalController'
                    }
                }
            })
            //end of renewal states


            .state('private.account.user.claims', {
                title: 'DirectAsia | Claims',
                url: '/claims',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/claims/my.claim.html'),
                controller: null//'PolicyAccountPoliciesController'
            })

            .state('private.account.user.proposals', {
                title: 'DirectAsia | Proposal Listing',
                url: '/quotes',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/proposal/list/proposal.list.html'),
                controller: 'PolicyAccountProposalsController'
            })

            // *** Add temporary Login section for OIC issue ***
            .state('public.login', {
                title: 'DirectAsia | Login',
                url: '/login',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/login/login.block.html'),
            })

            //PUBLIC VIEW
            // *** Remove Login section for OIC issue ***
            /* .state('public.loginlanding', {
                title: 'DirectAsia | Login',
                url: '/loginlanding/:lang',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.html'),
                controller: 'LoginLandingController'
            })
            .state('public.login', {
                title: 'DirectAsia | Login',
                url: '/login',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/login/login.html'),
                controller: 'LoginController'
            })
            .state('public.forgotpassword', {
                title: 'DirectAsia | Forgotten Password',
                url: '/forgot-password',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/login/forgot.password.html'),
                controller: 'ForgotPasswordController'
            }) */


            .state('public.motor', {
                abstract: true,
                url: '/motor',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/product.html')
            })

            /* .state('public.motor.sessiontoken', {
                 title: 'DirectAsia',
                 url: '/start/:token/:productCode/:lang',
                 templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps.html'),
                 controller: 'SessionTokenController'
             })*/

            .state('public.motor.quote', {
                abstract: true,
                url: '/quote',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/quote.html'),
                        controller: null//'MotorQuoteBaseQuoteController'
                    }
                }
            })



            /** Maintenance page */
            /*.state('public.motor.sessiontoken', {
                title: 'เช็คเบี้ยประกันภัยรถยนต์ออนไลน์ทันที - ไดเร็ค เอเชีย ประเทศไทย',
                url: '/quote/start/:lang',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/login/maintenance.html'),
            })*/
            /** Maintenance page */

            // Can be use to switch the journey version.
            // Full Online sale version
            .state('public.motor.quote.steps', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps.html'),
                controller: 'MotorQuoteStepsController'
            })

            // Online quote version
            .state('public.motor.quote.steps2', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps2.html'),
                controller: 'MotorQuoteStepsController'
            })
            // Easy Renew version
            .state('public.motor.quote.steps3', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps3.html'),
                controller: 'MotorQuoteStepsController'
            })

            .state('public.motor.quote.steps.cover', {
                title: 'ไดเร็ค เอเชีย | เลือกแผนประกันภัยรถยนต์',
                //url: '/cover-details',
                url: '/your-quote',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/cover.details.html'),
                controller: 'MotorQuoteCoverController'
            })

            .state('public.motor.quote.steps.customise', {
                title: 'ไดเร็ค เอเชีย | ปรับแต่งกรมธรรม์',
                //url: '/customise-cover',
                url: '/your-policy',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/customise.cover.html'),
                controller: 'MotorQuoteCustomiseCoverController'
            })

            .state('public.motor.quote.steps.quote', {
                title: 'ไดเร็ค เอเชีย | กรอกข้อมูลรถยนต์และผู้ขับขี่',
                //url: '/quote-details',
                url: '/car-and-you',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/quote.details.html'),
                controller: 'MotorQuoteQuoteController'
            })

            // *** Remove confirm & buy for OIC issue *** , Remove for experiment
            .state('public.motor.quote.steps.confirm', {
                title: 'ไดเร็ค เอเชีย | ตรวจสอบข้อมูลกรมธรรม์',
                //url: '/confirm-quote',
                url: '/review',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/confirm.quote.html'),
                controller: 'MotorQuoteConfirmController'
            })

            // *** Remove confirm & buy for OIC issue *** , Remove for experiment
            .state('public.motor.quote.steps.confirm2', {
                title: 'ไดเร็ค เอเชีย | ตรวจสอบข้อมูลกรมธรรม์',
                //url: '/confirm-quote',
                url: '/review2',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/confirm.buy.mix.html'),
                controller: 'MotorQuoteConfirmBuyMixController'
            })

            .state('public.motor.quote.steps.buy', {
                title: 'ไดเร็ค เอเชีย | ชำระเบี้ยประกัน',
                url: '/buy',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/buy.html'),
                        controller: 'ProductQuoteBuyController'
                    },
                    'productBuy@public.motor.quote.steps.buy': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/payment/payment.html'),
                        controller: 'PaymentController'
                    }
                }
            })

            .state('public.motor.quote.steps.buycallback', {
                url: '/buy-evaluation?pares&md',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/issue.policy.html'),
                controller: 'CybersourceCallbackController'
            })

            .state('public.motor.quote.steps3.buycallback', {
                url: '/buy-evaluation2',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/issue.policy.html'),
                controller: 'CybersourceCallbackController'
            })

            .state('public.motor.quote.steps.buyerrorcallback', {
                url: '/buy-error?src&prc',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/issue.policy.html'),
                controller: 'AsiapayErrorCallbackController'
            })

            .state('public.motor.quote.steps.issue', {
                url: '/issue-policy/:productState',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/issue.policy.html'),
                controller: 'IssuePolicyController'
            })

            .state('public.motor.quote.steps.done', {
                title: 'ไดเร็ค เอเชีย | ยืนยันความคุ้มครอง',
                url: '/confirm-payment',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/confirm.payment.v2.html'),
                controller: 'ConfirmPaymentController'
            })

            // For edit confirm-payment page only.
            .state('public.motor.quote.steps.done2', {
                title: 'ไดเร็ค เอเชีย | ยืนยันความคุ้มครอง',
                url: '/confirm-payment-v2',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/issue.policy.v2.html'),
                controller: 'MotorQuoteCoverController'
            })

            // For edit confirm-payment page only.

            .state('public.motor.quote.steps.counterdone', {
                title: 'ไดเร็ค เอเชีย | ยืนยันความคุ้มครอง',
                url: '/confirm-counter-buy',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/confirm.counter.html'),
                controller: 'ConfirmCounterController' //'TravelQuoteConfirmPaymentController'
            })
            // Remove for experiment end 

            .state('widget', {
                parent: 'root',
                abstract: true,
                url: '/widget',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/jtest/container.html'),
                        controller: 'UserstateController'
                    }
                }
            })

            .state('widget.sessiontoken', {
                abstract: false,
                url: '/landing?make&model&registrationyear&desc&cameraq&lang',
                //templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/pagerouter/pagerouter.html'),
                controller: 'WidgetSessionTokenController'
            })

            // online sale journey start page
            .state('public.motor.newsessiontoken', {
                title: 'เช็คเบี้ยประกันภัยรถยนต์ออนไลน์ทันที - ไดเร็ค เอเชีย ประเทศไทย',
                url: '/quote/start/:lang',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps.html'),
                controller: 'NewSessionTokenController'
            })

            // short version
            /* .state('public.motor.sessiontoken', {
                title: 'เช็คเบี้ยประกันภัยรถยนต์ออนไลน์ทันที - ไดเร็ค เอเชีย ประเทศไทย',
                url: '/quote/start-old/:lang',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps.html'),
                controller: 'SessionTokenController'
             }) */

            // online start version 1st page
            .state('public.motor.quote.steps.basic', {
                title: 'เช็คเบี้ยประกันภัยรถยนต์ออนไลน์ทันที - ไดเร็ค เอเชีย ประเทศไทย',
                //url: '/basic-details',
                url: '/get-quote',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/basic.details.html'),
                controller: 'MotorQuoteBasicController'
            })

            // Old short version 1st page
            /* .state('public.motor.quote.steps2.spa2', {
                title: 'เช็คเบี้ยประกันภัยรถยนต์ออนไลน์ทันที - ไดเร็ค เอเชีย ประเทศไทย',
                url: '/get-quote-old',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/spa2.html'),
                controller: 'SinglePageAppController' 
            }) */

            .state('public.motor.quote.steps3.easyrenew', {
                title: 'ต่ออายุกรมธรรม์ - ไดเร็ด เอเชีย ประเทศไทย',
                url: '/easyrenew',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/easy.renew.html'),
                        controller: 'EasyRenewController'
                    },
                    'productBuy@public.motor.quote.steps3.easyrenew': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/payment/easyrenew_payment.html'),
                        controller: 'EasyRenewPaymentController'
                    }
                }
            })

            .state('publicERroot.easyrenewrouter', {
                abstract: false,
                url: '/easyrenewlanding?token&lang',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/pagerouter/pagerouter.easyrenew.html'),
                controller: 'EasyRenewRouterController'
            })

            .state('public.dashboard', {
                title: 'DirectAsia | Dashboard',
                url: '/',
                templateUrl: '/app/ui/partial/dashboard/dashboard.html'
            })

            // For B2B project, route configuration start here.
            .state('publicB2B', {
                parent: 'root',
                abstract: true,
                url: '',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/publicB2B.html'),
                        controller: 'B2BUserstateController'
                    }
                }
            })

            .state('publicB2B.dashboard3', {
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/b2b-login?user&temppass',
                templateUrl: '/app/ui/partial/login/broker-login.html',
                controller: 'BrokerLoginController'
            })

            .state('publicB2B.changepassword', {
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/b2b-change-password',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/account/password/b2b-change.password.html'),
                controller: 'BrokerChangePasswordController'
            })

            .state('publicB2B.forgotpassword', {
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/b2b-forgot-password',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/login/b2b-forgot.password.html'),
                controller: 'BrokerForgotPasswordController'
            })

            .state('publicB2B.motor', {
                abstract: true,
                url: '/broker',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/b2b-product.html')
            })

            .state('publicB2B.motor.newbrokersession', {
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/quote/start-b2b/?lang&token&productCode',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps.html'),
                controller: 'BrokerSessionTokenController'
            })

            .state('publicB2B.motor.quote', {
                abstract: true,
                url: '/quote',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/b2b-quote.html'),
                        controller: null
                    }
                }
            })
            .state('publicB2B.motor.manage', {
                abstract: true,
                url: '/manage',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/b2b-quote.html'),
                        controller: null
                    }
                }
            })
            .state('publicB2B.motor.manage.steps', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2b.manage.step.html'),
                controller: null
            })

            .state('publicB2B.motor.quote.steps', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2b.steps4.html'),
                controller: 'BrokerQuoteStepsController'
            })

            .state('publicB2B.motor.quote.steps.b2b', {
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/getquote',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2b.html'),
                controller: 'BrokerAppController'
            })

            .state('publicB2B.motor.manage.steps.proposal', { // temporary use
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/proposal?page',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/proposal/list/b2b-proposal.list.html'),
                controller: 'BrokerPolicyAccountProposalsController'
            })

            .state('publicB2B.motor.manage.steps.policy', {
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/policy',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/policy/list/b2b-policy.list.html'),
                        controller: 'BrokerPolicyAccountPoliciesController'
                    }
                }
            })

            .state('publicB2B.motor.manage.steps.viewpolicy', {
                title: 'Broker portal - Direct Asia (Thailand) Co.,Ltd.',
                url: '/viewpolicy/?policyNumber&productId',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/policy/view/b2b-view.html'),
                        controller: 'BrokerPolicyViewPolicyController'
                    }
                }
            })

            // MGM promotion landing
            .state('public.mgmrouter', {
                abstract: false,
                url: '/mgmpromo?lang&MGM_id&utm_medium&utm_source&utm_campaign&utm_content',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/pagerouter/pagerouter.html'),
                controller: 'MGMRouterController'
            })

            .state('public.partner', {
                abstract: false,
                url: '/partner?lang&partner_id&utm_medium&utm_source&utm_campaign&utm_content',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/pagerouter/pagerouter.html'),
                controller: 'MGMRouterController'
            })

            // B2C
            .state('publicB2C', {
                parent: 'root',
                abstract: true,
                url: '',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/publicB2C.html'),
                        controller: 'UserstateController'
                    },
                    'footer@publicB2C': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/userstate/public.footer.html'),
                        controller: null
                    }
                }
            })

            .state('publicB2C.motor', {
                abstract: true,
                url: '/b2c',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/b2b-product.html')
            })

            .state('publicB2C.motor.newsession', {
                title: 'DirectAsia (Thailand) Co.,Ltd.',
                url: '/start/?lang&token&productCode',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/steps.html'),
                controller: 'B2CSessionTokenController'
            })

            .state('publicB2C.motor.quote', {
                abstract: true,
                url: '/quote',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.quote.html'),
                        //controller: null
                        controller: 'UserstateController'
                    }
                }
            })

            .state('publicB2C.motor.quote2', {
                abstract: true,
                url: '/quote2',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.quote2.html'),
                        controller: null
                    }
                }
            })

            .state('publicB2C.motor.quote.steps', {

                // abstract: true,
                // url: '',
                // templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.steps4.html'),
                // controller: 'B2CQuoteStepsController'

                abstract: true,
                url : '',
                views : {
                        '' : {
                            templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.steps4.html'),
                            controller: 'B2CQuoteStepsController'
                        }
                        // ,
                        // 'PolicyNumberTag@publicB2C.motor.quote.steps' : {
                        //     controller: 'B2CAppController'
                        // }
                }
            })

            .state('publicB2C.motor.quote2.steps', {
                abstract: true,
                url: '',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.steps4.html'),
                controller: 'B2CQuoteStepsController'
            })

            .state('publicB2C.motor.quote.steps.b2c', {
                title: 'DirectAsia (Thailand) Co.,Ltd.',
                url: '/getquote',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.html'),
                controller: 'B2CAppController'
            })

            .state('publicB2C.motor.quote.steps.b2cv2', {
                title: 'DirectAsia (Thailand) Co.,Ltd.',
                url: '/getquotev2',
                controller: 'B2CV2AppController',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2cv2.html')
            })

            .state('publicB2C.motor.quote.steps.confirm', {
                title: 'ไดเร็คเอเชีย | ตรวจสอบข้อมูลกรมธรรม์',
                url: '/review',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.confirm.quote.html'),
                controller: 'B2CConfirmController'
            })

            .state('publicB2C.motor.quote.steps.mticonfirm', {
                title: 'ไดเร็คเอเชีย | ตรวจสอบข้อมูลกรมธรรม์',
                url: '/mtireview',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.confirm.quote.mti.html'),
                controller: 'B2CConfirmController'
            })

            .state('publicB2C.motor.quote2.steps.mtisuccess', {
                title: 'ไดเร็คเอเชีย | รอการติดต่อกลับ',
                url: '/mtisuccess',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/motor/quote/b2c.success.mti.html'),
                controller: 'B2CMTISuccessController'
            })

            .state('publicB2C.motor.quote2.steps.buy', {
                title: 'ไดเร็คเอเชีย | ตรวจสอบข้อมูลกรมธรรม์',
                url: '/buy',
                views: {
                    '': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/b2c.buy.html'),
                        controller: 'B2CBuyController'
                    },
                    'productBuy@publicB2C.motor.quote2.steps.buy': {
                        templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/payment/b2c.payment.html'),
                        controller: 'B2CPaymentController'
                    }
                }
            })

            .state('publicB2C.motor.quote2.steps.buycallback', {
                url: '/buy-evaluation',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/b2c.issue.policy.html'),
                controller: 'B2CCybersourceCallbackController'
            })

            .state('publicB2C.motor.quote2.steps.issue', {
                url: '/issue-policy/:productState',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/b2c.issue.policy.html'),
                controller: 'B2CIssuePolicyController'
            })

            .state('publicB2C.motor.quote2.steps.done', {
                title: 'ไดเร็คเอเชีย | ยืนยันความคุ้มครอง',
                url: '/confirm-payment',
                templateUrl: HttpUtilsProvider.getUrl('/app/ui/partial/product/quote/b2c.confirm.payment.v2.html'),
                controller: 'B2CConfirmPaymentController'
            })

    }
];