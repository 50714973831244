/*
 * Created by Chanon Tangputthajit 2018/03/13
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    'WfeApiSession',
    'HKSteps',
    '$sessionStorage',
    '$cookies',

    /**
     * Controller that assigns token to the product
     * @param $scope
     * @param $state
     * @constructor
     */
    function MGMRouterController($scope, $state, $stateParams, WfeApiSession, HKSteps,$sessionStorage, $cookies) {

        console.log('Widget session controller loaded..');
        var fn = {

            product: {
                ProductId: 2000003,
                ProductCode: 'motor',
                ProductHeader: $scope.HKLocale.getResource('car_h_product_name'),
                isRenewable: true,
                isBILogEnabled: true,
                FirstPageState: 'public.motor.quote.steps.basic'
            },

            initScopeObjects: function (){

                $scope.product = {};
                $scope.prodOption= {};
            }, 

            initApiObjects: function(){

                $scope.product = fn.product;
                $scope.sessionApi = WfeApiSession.instance($scope, fn.product.ProductCode);
                $scope.stepsUtils = HKSteps.instance($scope, $scope.sessionApi);
//suhas1
            },

            onLoadingWithToken: function () {

                $scope.isAccepted = true;
                $scope.stepsUtils.setIsAccepted(true);
                $scope.stepsUtils.setProdOption($scope.prodOption);
                    
                //set locale
                    var localeString = $stateParams.lang;
                    
                    /* var localeString = $stateParams.lang; */
                    var callback = function(){
                        fn.navigation.goToNextStep();
                    };
                    if(!localeString)
                    {
                        localeString='en-us';
                    }
                    if(localeString){
                        if(localeString.toLowerCase() === 'th-th'){
                            $scope.HKLocale.setLocaleOnNextLoading('th-th',callback);
                        }else if(localeString.toLowerCase() === 'en-us'){
                            $scope.HKLocale.setLocaleOnNextLoading('en-us',callback);
                        }

                    } else {
                        fn.navigation.goToNextStep();
                    }

                    return true;
            },
            btnNextClicked: function () {

                    var isValid = true;

                    if (!isValid) {
                        return;
                    }

                    var callbacks =
                    {
                        onSuccess: function () {

                            $scope.isTokenLoaded = !fn.onLoadingWithToken();
                        }
                    };

                    $scope.sessionApi.createSession(callbacks);

            },


            navigation: {
                goToNextStep: function() {
                    if($sessionStorage.maxarReference){delete $sessionStorage.maxarReference};
                    $state.go($scope.product.FirstPageState);
                }
            }, 

            unhashParam: function() {
                if($stateParams.partner_id){
                    var lead_pt_name = decodeURIComponent($stateParams.partner_id);
                }else{
                    var lead_pt_name = "none";
                }
                
                var cm_id = lead_pt_name.replace(/\s/g, "_").replace(/[^\w\s]/gi, '').toUpperCase();
                $cookies["dac_gacmd"] = $stateParams.utm_medium
                $cookies["dac_gacsr"] = $stateParams.utm_source
                $cookies["dac_gaccn"] = $stateParams.utm_campaign
                $cookies["dac_gacct"] = $stateParams.utm_content
                $cookies["MGM_id"]    = $stateParams.MGM_id
                $cookies["partner_id"] = cm_id

            },

        };
        fn.unhashParam();
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.btnNextClicked();
        
    }];