/*
 * Created by Gerald Garcia on 06/03/15.
 */
module.exports = [
    '$scope',
    '$rootScope',
    '$state',
    '$localStorage',
    '$window',
    '$stateParams',
    '$anchorScroll',
    'MitScopeBindingUtils',
    'Confirmation',
    'Essential',
    'Session',
    'WfeApiEssential',
    'WfeApiSession',
    'DaLoadingMask',
    'WfeApiConfirmation',
    'HKSteps',
    'DaModal',
    '$sessionStorage',
    '$http',
    'HKLocale',
    'ExternalService',
    /**
     * Controller for the Partial Save Router
     * @constructor
     */
    function EasyRenewRouterController($scope, $rootScope, $state, $localStorage, $window, $stateParams, $anchorScroll,
                                         MitScopeBindingUtils, Confirmation, Essential, Session,WfeApiEssential,
                                         WfeApiSession,DaLoadingMask,WfeApiConfirmation,HKSteps,DaModal, $sessionStorage,$http, HKLocale, ExternalService) {
        $anchorScroll();

        var fn = {
            setIsLoading: function (isLoading) {
                DaLoadingMask.toggle(isLoading);
            },

            onServiceError: function () {
                fn.setIsLoading(false);
            },
            unhashParam: function() {
                var tokenHashObj = {
                    qrQuoteNumber: encodeURIComponent($stateParams.token)
                };
                /* console.log(tokenHashObj);
                console.log("Convert again"); */
                //console.log(encodeURIComponent($stateParams.token));
                
                var unhashObj = Essential.getPolicyExtNoByHash(undefined, tokenHashObj);
                unhashObj.$promise.then(function (data) {
                    if (!data.success) {
                        fn.eventHandler.errorOnRequest(data);
                        return;
                    }else{
                        if(data.QuoteNumber === null ){
                            fn.eventHandler.errorOnRequest(data);
                            return;
                        }else{
                            fn.initApiObjects(data.QuoteNumber);
                        }
                    }
                    


                },
                    fn.eventHandler.errorOnRequest
                );
            },
            postToMaxParent:function(callBackStatus,Priority,FinalCall){

                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                maxarLayer.push( {
                    message:$scope.callbackObj.message,
                    type:'EasyRenew',
                    sourcePage:'loader',
                    callback: 'No',
                    priority: 1,
                    dataFinal: 'Y',
                    referenceID:$scope.phContact.PrimaryPhoneNumber,
                    telephone:$scope.phContact.PrimaryPhoneNumber,
                    firstName: $scope.policyHolder.FirstName,
                    email:$scope.phContact.PrimaryEmailAddress,
                    lastName:'-',
                    sourceChannel: '-',
                    channelDetail: '-',
                    gaCampaign: '-',
                    language:HKLocale.getLocale(),
                });

                fn.postMaxar();
            },
            postMaxar:function() {

                var obj={};
                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                for(var i=0;i<=maxarLayer.length;i++) {
                    angular.extend(obj,maxarLayer[i]);
                }


                if(!$sessionStorage.maxarReference){  /* ref is blank */
                    if(obj.referenceID)
                    {
                        $scope.maxarReference=obj.referenceID;
                        $sessionStorage.maxarReference=obj.referenceID;
                    }
                } else {  /* maxar ref exist */
                    if(!obj.referenceID) {
                        obj.referenceID=$sessionStorage.maxarReference||'-';
                    }
                }

                if(!obj['endDate']){
                    obj['endDate'] = "01-01-2017 00:00";
                }

                var requestString = JSON.stringify(obj)
                $scope.retData = {};

                // var confighead = {
                //     headers: {
                //         'Content-Type': '"application/json; charset=utf-8";',
                //         'dataType': '"json"'
                //     }
                // }

                ExternalService.relaySend({
                    onSuccess: function (data, status) {
                        $scope.retData.result = data;
                            fn.utils.divertAfterSECB();
                    },
                    onError: function (err, status) {
                        $scope.status = status;
                    }
                }, requestString);

                // $http.post(config.api + '/ExternalServices/Relay/Send', requestString, confighead)
                //     .success(function (data, status, headers, config) {
                //         $scope.retData.result = data.d;
                //         fn.utils.divertAfterSECB();
                //     })
                //     .error(function (data, status, headers, config) {
                //         $scope.status = status;
                //     });
                
            },
            utils:{
                divertAfterSECB:function()
                {
                    var loc=HKLocale.getLocale() || 'th-th';
                    if(loc=='en-us') {
                        $window.location.href = config.homePageUrl + '/../en/thankyou-for-saving-proposal/';
                    } else {
                        $window.location.href = config.homePageUrl + '/../thankyou-for-saving-proposal/';
                    }

                },
                getProductCode : function(quoteNumber){
                    var productMap = {
                        'MT': 'motor',
                        'TR': 'travel',
                        'MC': 'motorcycle',
                        'HS': 'health'
                    };
                    var productCodeAbbrev = quoteNumber;
                    productCodeAbbrev = productCodeAbbrev.substring(0,2);
                    return productMap[productCodeAbbrev];

                }
            },
            initApiObjects: function(quoteNumber){


                var productCode = fn.utils.getProductCode(quoteNumber);

                if(!productCode){
                    return;
                }


                fn.sessionApi = WfeApiSession.instance($scope, productCode);

                fn.essentialApi = WfeApiEssential.instance(fn.sessionApi, $scope);
                fn.stepsUtils = HKSteps.instance($scope, fn.sessionApi);

                $scope.savedProposal.proposalNumber =quoteNumber;
                fn.confirmationApi = WfeApiConfirmation.instance(fn.sessionApi, $scope, $scope.savedProposal);
                fn.issuePolicy();
            },

            issuePolicy: function() {
                var localeString = $stateParams.lang? $stateParams.lang: "th-th";
                var products = {
                    '2000003': {state:'public.motor.quote.steps3.easyrenew', code:'motor'},  // test customize flow
                };


                var createMotorQuote = function(){

                    //fn.setIsLoading(true);
                    var tokenId ='';
                    fn.sessionApi.createSession({
                        onSuccess: function (data) {

                            retrieveQuote();
                            tokenId = data.MITSessionToken;


                        }, onError: function () {
                            console.log('error');
                            fn.eventHandler.errorOnRequest();
                        }
                    });
                    var retrieveQuote = function () {
                        fn.confirmationApi.retrieveQuote({
                            onSuccess: function (data) {
                                //console.log(data);
                                fn.setIsLoading(false);
                                var parameter = {
                                    token: tokenId,
                                    productCode: data.ProductId
                                };
                                $scope.isAccepted = true;
                                fn.stepsUtils.setIsAccepted(true);
                                fn.stepsUtils.setProdOption('motor');
                                var productState = 'public.motor.quote.steps3.easyrenew';
                                var callback = function(){
                                    $state.go(productState, parameter);
                                    
                                };
                                if(localeString){

                                    $scope.HKLocale.setLocaleOnNextLoading(localeString.toLowerCase(),callback);
                                } else {
                                    callback();
                                }


                            },
                            onError: function (err) {
                                fn.eventHandler.errorOnRequest();
                                //console.log(err.ErrorInfo.StatusCode);
                                if(err.ErrorInfo.StatusCode === 'CONVERTED_TO_POLICY'){
                                    $scope.errorType = 2
                                }else{
                                    $scope.errorType = 1
                                }
                            }

                        });
                    };

                };

                createMotorQuote();
            },

            initScopeObjects: function () {
                $scope.hasError = false;
                $scope.session = {};
                $scope.savedProposal = {};
                $scope.currentProposal = {};
                $scope.creditCard = {};
                $scope.coverages = {};
                $scope.phContact = {};
                $scope.policyHolder = {};
                $scope.callbackObj = {};
            },

            eventHandler: {
                errorOnRequest: function () {
                    $scope.hasError = true;
                    fn.onServiceError();
                },
                
                onBtnCallbackClicked :function(){
                    DaModal.open('#quotationCallback');

                },
                onBtnCallbackContinueClicked: function () {

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    $scope.pageSubmitted = true;

                    if ($scope.phContact.PrimaryEmailAddress.invalid
                        || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }
                    fn.postToMaxParent("No", 1, "Y");
                },
                onBtnCallbackCancelClicked: function () {
                    DaModal.close('#quotationCallback');
                },
            }
        };
        
        fn.initScopeObjects();
        fn.unhashParam();
        
        $scope.onBtnCallbackClicked = fn.eventHandler.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandler.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandler.onBtnCallbackCancelClicked;

    }];