/**
 * Created by pathai.s on 5/8/2017.
 */
module.exports = [
    function daTHConfirmEmail() {

        return {
            require: 'ngModel',
            scope: {
                fieldToCompare: '=daConfirmEmailFieldToCompare'
            },

            controller: [
                '$scope',
                function($scope){
                    var fn = {
                        isMatched: function(scope, ctrl, viewValue) {

                            if (ctrl.$isEmpty(viewValue)) {

                                // consider empty models to be valid
                                ctrl.$setValidity($scope.literals.validityKey, true);
                                return undefined;
                            }

                            var isValid = true;
                            if($scope.fieldToCompare.length === viewValue.length){
                                isValid = $scope.fieldToCompare === viewValue && true;
                            }else if($scope.fieldToCompare.length < viewValue.length){
                                isValid = false;
                            }

                            ctrl.$setValidity($scope.literals.validityKey, isValid);

                            return isValid ? viewValue : undefined;
                        },

                        literals: {
                            validityKey: 'da-th-confirm-email'
                        }
                    };

                    $scope.literals = fn.literals;
                    $scope.isMatched = fn.isMatched;
                }
            ],

            link: function(scope, element, attrs, ctrl) {

                //call $setViewValue to trigger validation
                var fieldToCompareWatcher = scope.$watch('fieldToCompare', function (event, fieldToCompare) {
                    if (fieldToCompare) {
                        ctrl.$setViewValue(ctrl.$viewValue);
                    }
                });

                element.on('$destroy', function(){
                    if (fieldToCompareWatcher) {
                        fieldToCompareWatcher();
                    }
                });

                ctrl.$parsers.unshift(function(viewValue) {
                    return scope.isMatched(scope, ctrl, viewValue);
                    //if (ctrl.$isEmpty(viewValue)) {
                    //
                    //    // consider empty models to be valid
                    //    ctrl.$setValidity(scope.literals.validityKey, true);
                    //    return undefined;
                    //}
                    //
                    //var isValid = scope.fieldToCompare === viewValue && true;
                    //
                    //ctrl.$setValidity(scope.literals.validityKey, isValid);
                    //
                    //return isValid ? viewValue : undefined;
                });
            }
        };
    }];