/**
 * Created by localsunil on 9/16/2015.
 */
/*
 * Created by Gerald Garcia on 26/03/15.
 */
module.exports = [
    'ConfigUtils',
    '$location',
    '$scope',
    '$state',
    '$stateParams',
    '$anchorScroll',
    'DaModal',
    'Confirmation',
    'Payment',
    'MitLogUtils',
    'WfeApiCurrentProposal',
    'WfeApiPayment',
    'WfeApiLog',
    /**
     * Controller for the Payment
     * @constructor
     */
        function AsiaPayCallbackController(ConfigUtils, $location, $scope, $state, $stateParams, $anchorScroll, DaModal,
                                           Confirmation, Payment, MitLogUtils, WfeApiCurrentProposal, WfeApiPayment, WfeApiLog) {
        $anchorScroll();

        var fn = {

            goToNextPage: function () {

                var parameters = {
                    productState: $scope.product.ProductCode
                };

                var stateStr = 'public.' + $scope.product.ProductCode + '.quote.steps.issue';
                $state.go(stateStr, parameters);

            },

            savePayment: function (creditCard, callbacks) {
                callbacks = callbacks || {};
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return false;
                }

                var onSuccess = function (data) {
                    //creditCard.PaRes = data.pares;
                    //creditCard.XidRes = data.md;

                    creditCard.SuccessCode = data.SuccessCode;
                    creditCard.SRC = data.SRC;
                    creditCard.PRC = data.PRC;
                    creditCard.ORD = data.ORD;
                    creditCard.Holder = data.Holder;
                    creditCard.PayRef = data.PayRef;
                    creditCard.Remark = data.Remark;
                    creditCard.PayerAuth = data.PayerAuth;
                    creditCard.SecureHash = data.SecureHash;
                    creditCard.CurrencyCode = data.CurrencyCode;
                    //fn.updatePayment(creditCard, callbacks);
                    fn.goToNextPage(data);
                };

                fn.eventHandler.logEvent('/app/ui/code/asiapay.response.aspx', 'Before getting data from asiapay.response.aspx');

                Payment.getAsiaPayPaymentResponse(creditCard, onSuccess,
                    function () {
                        fn.eventHandler.errorOnRequest('getAsiaPayPaymentResponse', arguments);
                    }
                );

                return true;
            },

            createPayment: function (creditCard, callbacks) {
                return fn.updatePayment(creditCard, callbacks);
            },

            /*updatePayment: function(creditCard, callbacks) {
             var dummyCreditCard = {
             PaRes: creditCard.PaRes,
             XidRes: creditCard.XidRes
             };

             fn.eventHandler.logEvent(ConfigUtils.api + '/DataServices/Payment/UpdatePaymentStatus', 'Before update of PARes and MD', dummyCreditCard);

             /!*Payment.updatePaymentStatus(undefined, creditCard,
             function createMotorcyclePaymentSuccess(data){
             if (!data.success) {
             fn.eventHandler.errorOnRequest('updatePaymentStatus-successblock', arguments);
             return;
             }

             fn.confirmPayment(creditCard, callbacks);

             },

             function () {
             fn.eventHandler.errorOnRequest('updatePaymentStatus', arguments);
             }
             );*!/



             return true;
             },*/

            //var savePayment = function () {
            //    var savePaymentFunc = fn.paymentApi.utils.getSaveFunctionName($scope.payment, 'PaymentStatus');
            //    fn.paymentApi[savePaymentFunc](
            //        {
            //            onSuccess: function (data) {
            //                console.log(data);
            //                console.log('success: paymentApi.' + savePaymentFunc);
            //                savePH();
            //            },
            //            onError: function () {
            //                hasError = true;
            //                savePH();
            //            }
            //        }
            //    );
            //};

            confirmPayment: function (creditCard, callbacks) {
                if (!creditCard) {
                    console.error('No credit card object provided');
                    return;
                }

                fn.eventHandler.logEvent(ConfigUtils.api + '/TransactionServices/Confirmation/ProcessPayment', 'Before 2nd process payment');
                Confirmation.processPayment(undefined, creditCard,
                    function confirmMotorcycleSuccess(data) {

                        if (!data.success) {
                            fn.eventHandler.errorOnRequest('processPayment-successblock', arguments);
                            return;
                        }

                        fn.eventHandler.logEvent(ConfigUtils.api + '/TransactionServices/Confirmation/ProcessPayment', '2nd process payment - Successful');
                        fn.postPaymentConfirmation(creditCard, data, callbacks);
                    },


                    function () {
                        fn.eventHandler.errorOnRequest('processPayment', arguments);
                    }
                );
            },

            initScopeObjects: function () {
                $scope.session = {};
                $scope.currentProposal = {};
                $scope.creditCard = {};
                $scope.payment = {};
                $scope.bidata = {};
            },

            initApiObjects: function () {
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.paymentApi = WfeApiPayment.instance($scope.$parent.sessionApi, $scope, $scope.payment);
                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, undefined);
            },
            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrieveCurrentProposal();
                };

                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                $scope.currentProposal.isForUpdate = true;
                                retrievePayment();
                            },
                            onError: function () {
                                retrievePayment();
                            }
                        }
                    );
                };

                var retrievePayment = function () {
                    fn.paymentApi.retrievePayment(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.payment, data);
                                $scope.payment.isForUpdate = true;


                                //update payment and supply the asiapay value then updat payment
                                //$scope.payment.CreditCard.MITSessionToken = $scope.payment.MITSessionToken;
                                fn.savePayment($scope.payment.CreditCard);
                            }
                        }
                    );
                };

                startRetrievalScopeObject();
            },

            postPaymentConfirmation: function (creditCard, data) {

                var isSuccess = true;
                //validate data here

                if (!data.success) {
                    $scope.hasError = true;
                    return;
                }


                if (isSuccess) {
                    fn.goToNextPage(data);
                }

                //if (callbacks.onPaymentSuccess) {
                //    callbacks.onPaymentSuccess(creditCard, data);
                //    //$scope.isPaymentSaving = false;
                //}
            },


            saving: {
                saveAllModels: function (callbacks) {
                    callbacks = callbacks || {};

                    var hasError = false;
                    var triggerSave = function () {
                        saveCurrentProposal();
                    };

                    var saveCurrentProposal = function () {
                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                        fn.currentProposalApi[saveCurrentProposalFunc](
                            {
                                onSuccess: function () {
                                    //saveAsset();
                                },
                                onError: function () {
                                    hasError = true;
                                    //saveAsset();
                                }
                            }
                        );
                    };
                    triggerSave();
                },
            },

            initWatchers: function () {
                var sessionWatcher, currentProposalWatcher,
                    paymentWatcher;
                //confirmationWatcher, confirmationCreateWatcher;

                var pAndCCallBack = function (newValueModel) {
                    if (!newValueModel) {
                        return;
                    }
                    sessionWatcher = $scope.$parent.$parent.$on('Session/Bind', sessionCallBack);
                    currentProposalWatcher = $scope.$parent.$parent.$on('CurrentProposal/Bind', currentProposalCallBack);
                    paymentWatcher = $scope.$parent.$parent.$on('Payment/Bind', paymentCallBack);
                };

                var sessionCallBack = function (eventObj, newValue) {
                    angular.extend($scope.session, newValue);
                };

                var currentProposalCallBack = function (eventObj, newValue) {
                    angular.extend($scope.currentProposal, newValue);
                };

                var paymentCallBack = function (eventObj, newValue) {
                    angular.extend($scope.payment, newValue);

                    if (!$scope.payment.CreditCard) {
                        return;
                    }

                    if (!$scope.payment.CreditCard.AccountNumber) {
                        return;
                    }

                    try {
                        if (!$scope.currentProposal.IsRenewalProposal) {
                            $scope.bidata = {
                                Status: 'Redirected from Bank'
                            };
                            fn.logApi.updateLog();
                        }
                    }
                    catch (err) {
                    }

                    //update payment and supply the asiapay value then updat payment
                    $scope.payment.CreditCard.MITSessionToken = $scope.payment.MITSessionToken;
                    fn.savePayment($scope.payment.CreditCard);


                };

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        sessionWatcher();
                        currentProposalWatcher();
                        paymentWatcher();
                        destroyWatcher();
                    }
                );

                pAndCCallBack({});

            },

            eventHandler: {

                logEvent: function (processName, msg, data) {

                    var dataString;
                    try {
                        if (data) {
                            dataString = angular.toJson(data);
                            msg = msg + ' data= ' + dataString;
                        }
                    }
                    catch (err) {
                    }

                    try {
                        var newObj = {
                            uniqueID: $scope.session.MITSessionToken,
                            serviceName: processName,
                            msg: msg
                        };

                        MitLogUtils.eventLogWrite(newObj);
                    }
                    catch (err) {
                    }

                },

                errorOnRequest: function (processName, args) {

                    try {
                        var newObj = {
                            sourceUrl: $location.url(),
                            exceptionMsg: processName,
                            stacktrace: angular.toJson(args)
                        };

                        MitLogUtils.exceptionLogWrite(newObj);
                    }
                    catch (err) {
                    }

                    $scope.isPaymentSaving = false;
                    $scope.hasError = true;
                    console.error('ERR: There was a problem when saving information.');
                }

            }
        };

        fn.initScopeObjects();
        //fn.initWatchers();
        fn.initApiObjects();
        fn.retrieveScopeObjects();

    }];