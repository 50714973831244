module.exports = [
  function dataLayerService () {
    let triggers = []
    let dataLayer = window.dataLayer = window.dataLayer || []

    let pushDataLayer = function (trigger) {
      let { message, options, state } = trigger

      if (state.done) return

      if (options.once) {
        let key = trigger.key.split(':')[0]
        let regex = new RegExp(`^${key}$|^${key}:.`)
        let relatedTriggers = triggers.filter(trigger => regex.test(trigger.key))
        for (let trigger of relatedTriggers) {
          trigger.state.done = true
        }
      }

      dataLayer.push(typeof message === 'function' ? message() : message)
      runNextTriggers(trigger)
    }

    let runNextTriggers = function (target) {
      let key = target.key.split(':')[0]
      let regex = new RegExp(`^${key}$|^${key}:.`)

      let nextTriggers = triggers.filter(
        trigger => trigger.type === 'hook' && regex.test(trigger.options.after)
      )

      for (let trigger of nextTriggers) {
        pushDataLayer(trigger)
      }
    }

    let addEventListener = function (event, trigger) {
      let state = trigger.state
      let element = trigger.options.element

      state.listener = function () {
        pushDataLayer(trigger)
      }

      element.addEventListener(event, state.listener)
    }

    let removeEventListener = function (event, trigger) {
      let state = trigger.state
      let element = trigger.options.element
      element.removeEventListener(event, state.listener)
    }

    let viewObserver = new IntersectionObserver(entries => {
      for (entry of entries) {
        if (!entry.isIntersecting) continue

        let trigger = triggers.find(
          trigger => trigger.options.element === entry.target
        )

        if (trigger) {
          pushDataLayer(trigger)
        }
      }
    })

    let observeElement = function (trigger) {
      let element = trigger.options.element
      viewObserver.observe(element)
    }

    let unobserveElement = function (trigger) {
      let element = trigger.options.element
      viewObserver.unobserve(element)
    }

    return {
      addTrigger: function (key, type, message, options) {
        if (triggers.find(trigger => trigger.key === key)) return

        let state = {}
        let trigger = { key, type, message, options, state }

        switch (type) {
          case 'click':
            addEventListener('click', trigger)
            break
          case 'change':
            addEventListener('change', trigger)
            break
          case 'input':
            addEventListener('input', trigger)
            break
          case 'view':
            observeElement(trigger)
            break
          case 'push':
            pushDataLayer(trigger)
            break
        }

        triggers.push(trigger)

        return trigger
      },

      removeTrigger: function (key) {
        let trigger = triggers.find(trigger => trigger.key === key)

        if (!trigger) return

        switch (trigger.type) {
          case 'click':
            removeEventListener('click', trigger)
            break
          case 'change':
            removeEventListener('change', trigger)
            break
          case 'input':
            removeEventListener('input', trigger)
            break
          case 'view':
            unobserveElement(trigger)
        }

        triggers.splice(triggers.indexOf(trigger), 1)
      }
    }
  }
]