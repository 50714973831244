/*
* Created by Gerald Garcia (28/07/2015) Jul2015
*/

/***
 *
 * @type {*[]}
 */
module.exports = [
    'HKFormValidation',
    'MITDate',
    /**
     * MotorQuoteValidationService provides utility functions for page validation of Motor Car Quote
     * @returns {{}}
     * @constructor
     */
    function MotorQuoteValidationService(HKFormValidation, MITDate) {

        var fn = {

            validations: {
                validateCurrentStep: function ($scope) {
                    if (!$scope.$parent.getIsAccepted()) {
                        fn.goToStart();
                        return false;
                    }
                    return true;
                },

                validateToken: function ($scope, callbacksParam) {
                    callbacksParam = callbacksParam || {};

                    var onSuccess = callbacksParam.onSuccess;
                    var onError = callbacksParam.onError;

                    var callbacks =
                    {
                        onSuccess: function (data) {
                            if (onSuccess) {
                                onSuccess(data);
                            }
                        },

                        onError: function (data) {
                            console.log(data);
                            if (onError) {
                                onError(data);
                            }
                            //fn.eventHandlers.sessionExpiredHandler();
                        }
                    };

                    $scope.$parent.sessionApi.retrieveSession(callbacks);

                },

                isInvalidDriverAge: function (dateOfBirthString, dateToCompare, formElement) {

                    if (!dateOfBirthString) {
                        if (formElement) {
                            formElement.$setValidity('custom_age', true);
                        }
                        return true;
                    }

                    var isValidAge = HKFormValidation.isValidAge(20, 70, dateOfBirthString, dateToCompare);

                    if (formElement) {
                        formElement.$setValidity('custom_age', isValidAge);
                    }

                    return !isValidAge;
                }, isInvalidOccupation: function (occupationId, formElement) {

                    if (!occupationId) {
                        if (formElement) {
                            formElement.$setValidity('occupation', true);
                        }
                        return true;
                    }
                    var isInValidOccupationId = false;

                    var invalidOccupations = ['1000050','1000049']; //diplomat and entertainer
                    if (invalidOccupations.indexOf(occupationId)>=0){
                        isInValidOccupationId = true;
                        if (formElement) {
                            formElement.$setValidity('occupation', true);
                        }
                    }


                    return isInValidOccupationId;
                },

                isInvalidYearsOfExperience: function isInvalidYearsOfExperience(yearsOfExpId, formElement) {

                    if (!yearsOfExpId) {
                        formElement.$setValidity('custom_yrs_exp', true);
                        return true;
                    }

                    var invalidExperiences = ['1', '2'];

                    var isInvalidExp = invalidExperiences.indexOf(yearsOfExpId) >= 0;

                    if (formElement) {
                        formElement.$setValidity('custom_yrs_exp', !isInvalidExp);
                    }

                    return isInvalidExp;
                },

                isInvalidAccidentClaims: function isInvalidAccidentClaims(valueId, formElement) {

                    if (!valueId) {
                        formElement.$setValidity('custom_accident_claims', true);
                        return true;
                    }

                    var validValues = ['1000003', '1000004', '1000008'];

                    var isValidValue = validValues.indexOf(valueId) >= 0;

                    if (formElement) {
                        formElement.$setValidity('custom_accident_claims', isValidValue);
                    }

                    return !isValidValue;
                },



                isValidMinDate: function (dateObj, minDate) {
                    return dateObj.diff(minDate, 'days') >= 0;
                },

                isValidMaxDate: function (dateObj, maxDate) {
                    return dateObj.diff(maxDate, 'days') <= 0;
                },

                isInvalidDateInRange: function (dateObj, minDate, maxDate) {

                    if (!dateObj) {
                        return false;
                    }

                    var startDate = dateObj;
                    var isValidMin = fn.validations.isValidMinDate(startDate, minDate);

                    if (!isValidMin) {
                        return true;
                    }

                    return !fn.validations.isValidMaxDate(startDate, maxDate);

                },

                isInvalidStartDate: function (dateString, formElement) {
                    if (!dateString) {
                        if (formElement) {
                            formElement.$setValidity('custom_start_date', true);
                        }
                        return false;
                    }

                    var startDate = MITDate.newDate(dateString);

                    var minDate = MITDate.makeDate(MITDate.toAngularDate( MITDate.newDate().add(1, 'days')));
                    var maxDate = MITDate.makeDate(MITDate.toAngularDate( MITDate.newDate().add(3, 'months')));

                    var isInvalid = fn.validations.isInvalidDateInRange(startDate, minDate, maxDate);

                    if (formElement) {
                        formElement.$setValidity('custom_start_date', !isInvalid);
                    }

                    return isInvalid;
                },

                isInvalidEndDate: function (dateString, startDateString, formElement) {

                    if ((!dateString) || (!startDateString)) {
                        if (formElement) {
                            formElement.$setValidity('custom_end_date', true);
                        }
                        return false;
                    }

                    var endDate = MITDate.newDate(dateString);
                    var minDate = MITDate.newDate(startDateString).add(7, 'months');
                    var maxDate = MITDate.newDate(startDateString).add(18, 'months');

                    var isInvalid = fn.validations.isInvalidDateInRange(endDate, minDate, maxDate);

                    if (formElement) {
                        formElement.$setValidity('custom_end_date', !isInvalid);
                    }

                    return isInvalid;
                }
            }

        };

        this.isInvalidDriverAge = fn.validations.isInvalidDriverAge;
        this.isInvalidYearsOfExperience = fn.validations.isInvalidYearsOfExperience;
        this.isInvalidAccidentClaims = fn.validations.isInvalidAccidentClaims;
        this.isInvalidStartDate = fn.validations.isInvalidStartDate;
        this.isInvalidEndDate = fn.validations.isInvalidEndDate;
        this.isInvalidOccupation = fn.validations.isInvalidOccupation;

    }];