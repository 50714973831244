
module.exports = [
    function daThCreditCardNo() {

        return {
            require: 'ngModel',

            link: function(scope, elm, attrs, ctrl) {
                scope.onClickIsEmpty = true;
                var el = jQuery(elm[0]);
                el.attr('type', 'text');
                el.mask("0000-0000-0000-0000");

                elm.focusout(function() {
                    if(elm.val().length < 19){
                        elm.val("");
                    }
                    ctrl.$setValidity('restricted', !/^5270-69/.test(elm.val()));
                });

                //el.mask("9999-9999-9999-9999",{placeholder:"XXXX-XXXX-XXXX-XXXX"});

                /*ctrl.$parsers.unshift(function(viewValue) {

                    if (ctrl.$isEmpty(viewValue)) {
                        ctrl.$setValidity('thCreditCardNo', true);
                        return undefined;
                    };

                    var isValid = true;

                    ctrl.$setValidity('thCreditCardNo', isValid);

                    if (isValid) {
                        return viewValue;
                    } else {
                        return undefined;
                    }
                });*/
            }
        };
    }];
