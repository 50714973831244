/*
 * Created by Gerald Garcia on 05/06/15.
 */
module.exports = [
    '$state',
    '$anchorScroll',
    '$scope',
    '$timeout',
    '$filter',
    'DaModal',
    'HKFormValidation',
    'WfeApiStaticData',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'WfeApiCoverages',
    'WfeApiAddress',
    'WfeApiContact',
    'WfeApiEssential',
    'WfeApiConfirmation',
    'CountryMotorQuoteValidation',
    'WfeApiLog',
    '$window',
    'HKLocale',
    '$sessionStorage',
    '$localStorage',
    'AppConstants',
    /**
     * Controller for the Quote Details of Motor Quote
     * @constructor
     */
        function MotorQuoteQuoteController($state, $anchorScroll, $scope, $timeout, $filter, DaModal,
                                           HKFormValidation, WfeApiStaticData,
                                           WfeApiCurrentProposal, WfeApiAsset, WfeApiPolicyContact, WfeApiQuote,
                                           WfeApiCoverages, WfeApiAddress, WfeApiContact, WfeApiEssential,
                                           WfeApiConfirmation, CountryMotorQuoteValidation, WfeApiLog, $window, HKLocale, $sessionStorage, $localStorage, AppConstants) {
        $anchorScroll();

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/motor/quote/review/step-5/'}
                );
            },
            dLCE:function(ref,v)
            {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                switch(ref)
                {
                    case 28:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'is-this-a-red-car-plate':s
                    });
                        break;
                    case 29:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'car-plate-province':v
                    });
                        break;
                    case 30:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'do-you-have-any-car-modification-or-accessories-that-you-want-to-cover':s
                    });
                        break;
                    case 31:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                        'event':'dataLayer-initialised',
                        'is-your-car-used-for-commercial-purpose':s
                    });
                        break;
                    case 32:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'is-your-car-registered-under-a-company-name':s
                        });
                        break;
                    case 33:
                        var s='';
                        if(v==0 || v== '0' || v==false) {s='No'};
                        if(v==1 || v== '1' ||v ==true) {s='Yes'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'do-you-have-a-load-for-your-car':s
                        });
                        break;
                    case 34:
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'please-tell-us-the-bank-name-or-loan-company':v
                        });
                        break;
                    case 35:
                        var s='';
                        if(v==2000000 || v== '2000000' ) {s='Thai Resident'};
                        if(v==2000001 || v== '2000001' ) {s='Non-Thai Resident'};
                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'policy-holder-residential-status':s
                        });

                        break;
                    case 36:

                        dataLayer.push({
                            'event':'dataLayer-initialised',
                            'policy-holder-occupation':v
                        });
                        break;
                    case 37:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'policy-holder-district':v
                    });
                        break;
                    case 38:dataLayer.push({
                        'event':'dataLayer-initialised',
                        'policy-holder-province':v
                    });
                        break;

                    default: break;
                }
            }
        };

        var fn = {

            postToMaxParent:function(callBackStatus,Priority,FinalCall){

                var carPlateNumber=$scope.asset.VehicleRegistrationNumber;
                var carPlateProvince='';
                if($scope.asset.LicenseCardProvinceId=='2000080' || $scope.asset.LicenseCardProvinceId==2000080) {
                    carPlateProvince='NA';
                } 
                else {
                    carPlateProvince=$scope.$parent.filterStatic($scope.staticData.provinces,$scope.asset.LicenseCardProvinceId);
                }

                var redCarPlate=$scope.asset.IsRedPlate==false?'No':'Yes';
                var modifications=$scope.asset.IsCarModified==false?'No':'Yes';
                var commercialUse=$scope.asset.IsCommercialPurpose==false?'No':'Yes';
                var bankLoanCompany=$scope.asset.FinanceCompanyContact;
                var chassisNumber=$scope.asset.ChassisNumber;
                // var occupation=$scope.$parent.filterStatic($scope.staticData.occupations,$scope.policyHolder.OccupationId);
                var occupation = $scope.staticData.occupations.find(w => w.key == $scope.policyHolder.OccupationId).value


                var postcode=$scope.phAddress.PostalCode;
                var district=$scope.$parent.filterStatic($scope.staticData.districtByPostal,$scope.phAddress.DistrictId);
                var province=$scope.$parent.filterStatic($scope.staticData.provincesByPostal,$scope.phAddress.ProvinceId);
                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                var lastname = '';
                if(!$scope.policyHolder.LastName){
                    lastname = '-'
                }else{
                    lastname = $scope.policyHolder.LastName;
                }

                if (!$scope.policyHolder.FirstName) {
                    firstname = '-';

                    if (!$scope.phContact.FirstName) {
                        firstname = '-';
                    } else {
                        firstname = $scope.phContact.FirstName;
                    }

                } else {
                    firstname = $scope.policyHolder.FirstName;
                }
                maxarLayer.push( {
                    firstName: firstname,
                    lastName:lastname,
                    carPlateNumber:carPlateNumber,
                    carPlateProvince:carPlateProvince,
                    redCarPlate:redCarPlate,
                    modifications:modifications,
                    commercialUse:commercialUse,
                    bankLoanCompany:bankLoanCompany,
                    chassisNumber:chassisNumber,
                    occupation:occupation,
                    postcode:postcode,
                    district:district,
                    province:province,
                    totalPremium:$scope.totalPremium2,
                    message:$scope.callbackObj.message,
                    commercial:$scope.callbackObj.commercial,
                    type:'Quote lead',
                    sourcePage:'Your car and you',
                    callback:callBackStatus,
                    priority:Priority,
                    dataFinal:FinalCall,
                    referenceID:$scope.phContact.PrimaryPhoneNumber,
                    telephone:$scope.phContact.PrimaryPhoneNumber,
                    consent:true

                });

                if($scope.$parent !== null){
                    $scope.$parent.postMaxar();
                }
            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;
                var wfeApiStaticDataPaymentPlan = fn.wfeApiStaticDataPaymentPlan;

                var startRetrieval = function () {
                    getProvinceList();
                };

                var getProvinceList = function () {
                    wfeApiStaticData.getProvinceList({
                        onSuccess: function (data) {
                            $scope.staticData.provinces = data;
                            $scope.staticData.provincesByPostal = data;
                            getProvinceListEnglish();
                        },

                        onError: function () {
                            getProvinceListEnglish();
                        }
                    });
                };



                var getProvinceListEnglish = function () {
                    fn.wfeApiStaticDataEnglishOnly.getProvinceList({
                        onSuccess: function (data) {
                            $scope.staticData.provincesEnglish = data;
                            $scope.staticData.provincesByPostalEnglish = data;
                            getDistrictList();
                        },

                        onError: function () {
                            getDistrictList();
                        }
                    });
                };

                var getDistrictList = function () {
                    wfeApiStaticData.getDistrictList({
                        onSuccess: function (data) {
                            $scope.staticData.districtByPostal = data;
                            getDistrictListEnglish();
                        },

                        onError: function () {
                            getDistrictListEnglish();
                        }
                    });
                };

                var getDistrictListEnglish = function () {
                    fn.wfeApiStaticDataEnglishOnly.getDistrictList({
                        onSuccess: function (data) {
                            $scope.staticData.districtByPostalEnglish = data;
                            getCountryList();
                        },

                        onError: function () {
                            getCountryList();
                        }
                    });
                };

                var getCountryList = function () {
                    wfeApiStaticData.getCountryList({
                        onSuccess: function (data) {
                            $scope.staticData.countries = data;
                            $scope.phAddress.CountryId = "200";
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                }

                /*var getContactRelationshipTypeList = function () {
                    wfeApiStaticData.getContactRelationshipTypeList({
                        onSuccess: function (data) {
                            $scope.staticData.relationships = data;
                            getBooleanListWithSometimes();
                        },

                        onError: function () {
                            getBooleanListWithSometimes();
                        }
                    });
                };*/

                /*var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };*/

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getNoClaimDiscountList();
                        },

                        onError: function () {
                            getNoClaimDiscountList();
                        }
                    });
                };

                /*var getPaymentTermList = function () {
                    wfeApiStaticDataPaymentPlan.getPaymentTermList({
                        onSuccess: function (data) {
                            $scope.staticData.paymentTerms = data;
                            getInsurerList();
                        },

                        onError: function () {
                            getInsurerList();
                        }
                    });
                };*/

                /*var getInsurerList = function () {
                    wfeApiStaticData.getInsurerList({
                        onSuccess: function (data) {
                            $scope.staticData.otherInsurances = data;
                            getNoClaimDiscountList();
                        },

                        onError: function () {
                            getNoClaimDiscountList();
                        }
                    });
                };*/

                var getNoClaimDiscountList = function () {
                    wfeApiStaticData.getNoClaimDiscountList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data;
                            getOccupationList();
                        },

                        onError: function () {
                            getOccupationList();
                        }
                    });
                };

                var getOccupationList = function () {
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getOccupationListEnglish();
                        },

                        onError: function () {
                            getOccupationListEnglish();
                        }
                    });
                };

                var getOccupationListEnglish = function () {
                    fn.wfeApiStaticDataEnglishOnly.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupationsEnglish = data;
                            getDatesPartsDD();
                        },

                        onError: function () {
                            getDatesPartsDD();
                        }
                    });
                };

                /*var getGenderList = function () {
                    wfeApiStaticData.getGenderList({
                        onSuccess: function (data) {
                            $scope.staticData.genders = data;
                            getDatesPartsDD();
                        },

                        onError: function () {
                            getDatesPartsDD();
                        }
                    });
                };*/

                var getDatesPartsDD = function () {

                    wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },

                        onError: function () {
                            getDatesPartsMM();
                        }
                    });
                };

                var getDatesPartsMM = function () {

                    wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;
                            getDatesPartsYY();
                        },

                        onError: function () {
                            getDatesPartsYY();
                        }
                    });
                };

                var getDatesPartsYY = function () {

                    wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            getMaritalStatusList();
                        },

                        onError: function () {
                            getMaritalStatusList();
                        }
                    });
                };

                var getMaritalStatusList = function () {
                    wfeApiStaticData.getMaritalStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getResidentialStatusList();
                        },

                        onError: function () {
                            getResidentialStatusList();
                        }
                    });
                };

                var getResidentialStatusList = function () {
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentialStatus = data;
                        },

                        onError: function () {
                        }
                    });
                };

                /*var getNcdReasonList = function () {
                    wfeApiStaticData.getNcdReasonList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdReasons = data;
                        }
                    });
                };*/

                startRetrieval();

            },

            initScopeObjects: function () {
                $scope.$parent.isUserProceedOnBuy();
                $scope.staticData = {};

                $scope.currentProposal = {};
                $scope.asset = {};
                $scope.policyContact = {};
                $scope.policyHolder = {};
                $scope.mainDriver = {};
                $scope.namedDrivers = {
                    PolicyContacts: []
                };
                $scope.phAddress = {};
                $scope.phContact = {};
                $scope.userMismatchObject = {};
                $scope.coverages = {};
                $scope.selectedCoverage = {};
                $scope.cvrList = [];
                $scope.loc='';
                $scope.loc=HKLocale.getLocale();
                if($scope.loc=='en-us')
                {
                    $scope.policyHolder.PreferredLanguageId=101;
                }
                if($scope.loc=='th-th')
                {
                    $scope.policyHolder.PreferredLanguageId=2000000;
                }
                $scope.callbackObj={};
                $scope.IsReEmailMismatch = false;
                $scope.loadingSpinner = true;

                $scope.partialSaveSubmitted = false;
                $scope.callBackSubmitted = false;

                $scope.asset.ProductId = $scope.$parent.product.ProductId;
                $scope.houseNoAndNameLimit = 50;


                $scope.showConfirmBuyPageB2C=false;
                $scope.showConfirmBuyPageOnlineSelling=true;

            },

            initApiObjects: function () {
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);

                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.policyContactApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyContact);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.namedDriversApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.namedDrivers);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);
                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);

                //fn.wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataEnglishOnly = WfeApiStaticData.instance($scope, 'en-us', $scope.$parent.product.ProductId);

                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), 1941);

                fn.wfeApiStaticData = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataPaymentPlan = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), 4);

                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.coverages, undefined);
            },

            retrieveScopeObjects: function () {

                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                retrieveAsset();
                            },
                            onError: function () {
                                retrieveAsset();
                            }
                        }
                    );
                }

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                if ($scope.asset.ReasonForNcdIsZero > 0) {
                                    $scope.asset.ReasonForNcdIsZero = $scope.asset.ReasonForNcdIsZero + '';
                                }
                                //Set PlanId for parent
                                $scope.$parent.PlanId = $scope.asset.PlanId;

                                /* Initial state */
                                if (typeof $scope.asset.VehicleRegistrationNumber === "undefined") {
                                    $scope.asset.IsRedPlate = false;
                                } 
                                else {
                                    /* Retrieve state : in case that user select "YES" fo the first time */
                                    if ($scope.asset.VehicleRegistrationNumber === "-" || $scope.asset.VehicleRegistrationNumber === "ใหม่ ป้ายแดง") {
                                        $scope.asset.IsRedPlate = true;

                                        /* Retrieve state : in case that user select "NO" fo the first time */
                                    } else {
                                        /* defaulting to false */
                                        $scope.asset.IsRedPlate = false;
                                    }
                                }

                                if ($scope.asset.FinanceCompanyContact == "NULL" || $scope.asset.FinanceCompanyContact == undefined
                                    || $scope.asset.FinanceCompanyContact == null) {
                                } else {
                                    $scope.asset.HaveLoanForCar = true;
                                }

                                if ($scope.asset.IsCarModified != null) {
                                    $scope.asset.IsCommercialPurpose = false;
                                    $scope.asset.IsRegisterUnderCompany = false;
                                }

                                var objParams = {};

                                objParams.modelId = $scope.asset.ModelId;
                                objParams.purchasingYear = $scope.asset.PurchasingYear;
                                var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                                wfeApiStaticDataModel.getCarDescWithYear({
                                    onSuccess: function (data) {
                                        for (var i in data)
                                            if (data[i]['key'] == $scope.asset.CarDescriptionId)
                                                $scope.staticData.cardescriptions = data[i];

                                        retrieveCoverages();
                                    },
                                    onError: function (){
                                        retrieveCoverages();
                                    }
                                });


                            }
                        }
                    );
                }

                var retrieveCoverages = function () {
                    fn.coverageApi.retrieveCoverages(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.coverages, data);
                                //$scope.$parent.getMonthlyPriceStepCtrl($scope.coverages); //gets the price on floating.

                                var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true})[0];

                                if (selectedCoverage) {
                                    angular.extend($scope.selectedCoverage, selectedCoverage);
                                }

                                retrievePHAddress();
                            },
                            onError: function(){
                                retrievePHAddress();
                            }
                        }
                    );
                }

                var retrievePHAddress = function (){
                    fn.phAddressApi.retrievePHAddress(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phAddress, data);
                                $scope.phAddress.DistrictId = $scope.phAddress.DistrictId + "";
                                retrievePHContact();
                            },
                            onError: function (){
                                retrievePHContact();
                            }
                        }
                    );
                }

                var retrievePHContact = function(){
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                retrievePolicyHolder();
                            },
                            onError: function (){
                                retrievePolicyHolder();
                            }
                        }
                    );
                }

                /** PolicyContact Retrieval : this will retrieve MD if PH is not MD */
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data){
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data,$scope.policyHolder);

                                    if($scope.policyHolder.DateOfBirth)
                                    {
                                        fn.utils.ConvertMITDateToDisplay();
                                    }

                                    /*To solve some problem on policyHolder scope*/
                                    //ST  $scope.policyHolder.OccupationIdTmp = data.OccupationId;
                                    
                                }


                                angular.extend($scope.policyHolder, data);
                                /* if($scope.policyHolder.FirstName.indexOf(" ") > -1 ){
                                    var firstName = $scope.policyHolder.FirstName.split(" ")[0];
                                    var lastName = $scope.policyHolder.FirstName.split(" ")[1];
                                }else{
                                    var firstName = $scope.policyHolder.FirstName;
                                }
                                
                                $scope.policyHolder.FirstName = firstName; */
                                $scope.loadingSpinner = false;

                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                }
                                else {
                                    retrieveMainDriver();
                                }

                            },
                            onError: function(){
                                $scope.loadingSpinner = false;
                            }
                        }
                    );
                };

                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact(
                        {
                            onSuccess: function (data) {
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data,$scope.mainDriver);

                                    /*To solve some problem on policyHolder scope*/
                                    //ST   $scope.mainDriver.OccupationIdTmp = data.OccupationId;
                                }

                                angular.extend($scope.mainDriver, data);
                            }
                        }
                    );
                };

                //retrievePolicyHolder();
                retrieveCurrentProposal();

                fn.retrieveNamedDrivers();

            },

            retrieveNamedDrivers: function () {

                fn.namedDriversApi.retrieveNDPolicyContacts(
                    {
                        onSuccess: function (dataArray) {

                            if (!dataArray) {
                                return;
                            }

                            angular.forEach(dataArray,
                                function (data) {
                                    if (data && data.Identifier) {
                                        HKFormValidation.splitIdentifier(data,data);
                                    }
                                }
                            );

                            $scope.namedDrivers.PolicyContacts.splice(0);
                            angular.extend($scope.namedDrivers.PolicyContacts, dataArray);
                            $scope.isEditing = true;
                            if ($scope.namedDrivers.PolicyContacts.length) {
                                $scope.isEditing = false;
                            }

                        }
                    }
                );

            },


            utils: {

                onUserMismatchHandler: function (errorInformation) {
                    if(!errorInformation){
                        return false;
                    }
                    var contactsNotUpdated = ['PrimaryPhoneNumber','PrimaryEmailAddress'];
                    var contactKeyDisplayMatch = {PrimaryEmailAddress:'body_user_email',PrimaryPhoneNumber:'body_user_mobile',
                        FirstName:'body_user_first_name',LastName:'body_user_lastname',MaritalStatusId:'body_policy_nd_marital',
                        HouseNumber:'body_address_house_number',PostalCode:'body_address_postal_code'};

                    $scope.userMismatchObject = errorInformation;
                    for (var i = 0; i<errorInformation.length;i++){
                        var personInfo = errorInformation[i];
                        if(personInfo){
                            if(personInfo.RoleName==='PH'){
                                personInfo.displayRoleName = 'body_title_policyholder_detail';
                            } else if(personInfo.RoleName==='MD'){
                                personInfo.displayRoleName = 'body_title_maindriver_detail';
                            }
                            personInfo.isEmailPhoneChanged = false;
                            var contactMismatchList = personInfo.MismatchList;
                            if(contactMismatchList){
                                for (var j = 0; j<contactMismatchList.length;j++) {
                                    var contactMismatchObject = contactMismatchList[j];
                                    contactMismatchObject.displayKey = contactKeyDisplayMatch[contactMismatchObject.Key];
                                    contactMismatchObject.additionalInfo = '';
                                    if(contactsNotUpdated.indexOf(contactMismatchObject.Key)>=0){
                                        personInfo.isEmailPhoneChanged = true;
                                        contactMismatchObject.additionalInfo = 'car_v_driver_mismatch_addtional_info';
                                    }
                                }
                            }
                        }
                    }
                },

                /*** Currently use for disable some mandatory fields, however it's possible to remove it */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                /*** Doesn't use now */
                isThaiResidentPH: function () {
                    if($scope.policyHolder.ResidentialStatusId == 2000000 || policyHolder.ResidentialStatusId ==='2000000'){
                        return true;
                    }
                    return false;
                },

                isThaiResidentMD: function () {
                    if($scope.mainDriver.ResidentialStatusId == 2000000 || mainDriver.ResidentialStatusId ==='2000000'){
                        return true;
                    }
                    return false;
                },

                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                    if($scope.partialSaveSubmitted){
                        DaModal.open("#daLoadingMaskPartialSave");
                    }else if($scope.callBackSubmitted){
                        DaModal.open("#daLoadingMaskCallBack");
                    }else{
                        $scope.DaLoadingMask.toggle(isSaving);
                    }
                },

                closeIsSaving: function () {
                    if($scope.partialSaveSubmitted){
                        DaModal.close("#daLoadingMaskPartialSave");
                    }else if($scope.callBackSubmitted){
                        DaModal.close("#daLoadingMaskCallBack");
                    }else{
                        DaModal.close("#daLoadingMask");
                    }
                },


                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.IsDemeritPoints = $scope.mainDriver.IsDemeritPoints;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;
                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;
                },

                /*** Doesn't use now */
                isPrevInsurerRequired: function(prevNcdLevelId) {
                    var result = true;

                    angular.forEach($scope.staticData.ncdLevels,
                        function (data) {
                            if (data.key === prevNcdLevelId) {
                                if(data.key === '1000003' || data.value === '0 %'){
                                    result = false;
                                }
                            }
                        }
                    );

                    return result;
                },

            },

            navigation: {

                goToNextStep: function () {
                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(5);
                    
                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {IsSelected : true})[0];
                    $sessionStorage.noOfPTSave = $sessionStorage.noOfPTSave + 1;
                    var goNext = function(){
                        fn.utils.setIsSaving(false);
                        try {
                            marketing.ga();
                        } catch (err) {
                            console.log(err);
                        }
                        $state.go('public.motor.quote.steps.confirm');
                    }

                    var defaultPriorityJudge = function () {
                        if (selectedCoverage.CoverTypeId === 2000017) {
                            fn.postToMaxParent('No', 4,'N')
                            goNext();
                        } else {
                            fn.postToMaxParent('No', 1,'N')
                            goNext();
                        }
                    }

                    if (selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE1_FALCON_DG && $scope.$parent.ppstEnable) {
                        fn.getPriority(function (priority) {
                            
                                if (priority != 0) {
                                    fn.postToMaxParent('No', priority, 'N');
                                    goNext();
                                } 
                                else {
                                    defaultPriorityJudge();
                                }
                            
                        });
                    } 
                    else {
                        defaultPriorityJudge();
                    }

                },

                goToPrevStep: function () {
                    fn.utils.setIsSaving(false);
                    $state.go('public.motor.quote.steps.customise');
                }

            },

            saving: {

                saveAllModels: function (callbacks) {
                    callbacks = callbacks || {};

                    var hasError = false;
                    var triggerSave = function () {
                        saveCurrentProposal();
                    };

                    var saveCurrentProposal = function () {
                        $scope.currentProposal.IsRequiredToSendEmail = true;
                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                        /*default to monthly to get monthly pre at buy page.*/
                        /* if($scope.selectedCoverage.CoverTypeId==2000020
                            || $scope.selectedCoverage.CoverTypeId==2000018
                            || $scope.selectedCoverage.CoverTypeId==2000016)
                        { */
                            /*comp/ type 3 /type 2*/
                        $scope.currentProposal.PaymentTermId=1000001; //single Only

                        /* } else {
                            $scope.currentProposal.PaymentTermId=1000005; //monthly
                        } */
                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                        fn.currentProposalApi[saveCurrentProposalFunc](
                            {
                                onSuccess: function () {
                                    saveAsset();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveAsset();
                                }
                            }
                        );
                    };

                    var saveAsset = function () {
                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');

                        fn.assetApi[saveAssetFunc](
                            {
                                onSuccess: function () {
                                    savePHContact();
                                },
                                onError: function () {
                                    hasError = true;
                                    savePHContact();
                                }
                            }
                        );
                    };

                    var savePHContact = function () {
                        var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                        fn.phContactApi[savePHContactFunc](
                            {
                                onSuccess: function () {
                                    savePHAddress();
                                },
                                onError: function () {
                                    hasError = true;
                                    savePHAddress();
                                }
                            }
                        );
                    };

                    var savePHAddress = function () {
                        if($scope.phAddress.CityName){
                            $scope.phAddress.CityName = $scope.phAddress.CityName.replace(/[|]+/g, "/");
                        }
                        if($scope.phAddress.HouseNumber){
                            $scope.phAddress.HouseNumber = $scope.phAddress.HouseNumber.replace(/[|]+/g, "/");
                        }
                        if($scope.phAddress.BuildingName){
                            $scope.phAddress.BuildingName = $scope.phAddress.BuildingName.replace(/[|]+/g, "/");
                        }
                        if($scope.phAddress.RegionName){
                            $scope.phAddress.RegionName = $scope.phAddress.RegionName.replace(/[|]+/g, "/");
                        }
                        

                        var savePHAddressFunc = fn.phAddressApi.utils.getSaveFunctionName($scope.phAddress, 'PHAddress');
                        fn.phAddressApi[savePHAddressFunc](
                            {
                                onSuccess: function () {
                                    savePH();
                                },
                                onError: function () {
                                    hasError = true;
                                    savePH();
                                }
                            }
                        );
                    };


                    var savePH = function () {
                        if($scope.policyHolder.Identifier){
                            if($scope.policyHolder.ResidentialStatusId == 2000000 || $scope.policyHolder.ResidentialStatusId ==='2000000')
                            $scope.policyHolder.Identifier=$scope.policyHolder.Identifier.replace(/[^0-9\.]+/g, "");
                        }

                        if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');

                        /*Add by Ob*/
                        /*To solve some problem on policyHolder element*/
                        //ST   $scope.policyHolder.OccupationId = $scope.policyHolder.OccupationIdTmp;
                        /*To solve some problem on policyHolder element*/

                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function () {
                                    saveMD();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveMD();
                                }
                            }
                        );
                    };

                    var saveMD = function () {
                        if($scope.mainDriver.Identifier){
                            if($scope.mainDriver.ResidentialStatusId == 2000000 || $scope.mainDriver.ResidentialStatusId ==='2000000')
                                $scope.mainDriver.Identifier=$scope.mainDriver.Identifier.replace(/[^0-9\.]+/g, "");
                        }
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');

                        /*Add by Ob*/
                        /*To solve some problem on mainDriver element*/
                        //ST  $scope.mainDriver.OccupationId = $scope.mainDriver.OccupationIdTmp;
                        /*To solve some problem on mainDriver element*/

                        if (!saveMDFunc) {
                            savingComplete();
                            return;
                        }

                        fn.mainDriverApi[saveMDFunc](
                            {
                                onSuccess: function () {
                                    savingComplete();
                                },
                                onError: function () {
                                    hasError = true;
                                    console.log('fails: mainDriverApi.' + saveMDFunc);
                                    savingComplete();
                                }
                            }
                        );
                    };

                    var savingComplete = function () {
                        if (hasError) {
                            if (callbacks.onError) {
                                callbacks.onError();
                            }
                            else {
                                fn.eventHandlers.onDefaultErrorHandler();
                            }
                            return;
                        }

                        if (callbacks.onSuccess) {
                            callbacks.onSuccess();
                        }
                    };

                    triggerSave();
                },

                partialSave: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    fn.confirmationApi.createConfirmation(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                DaModal.close('#quotationPartialSave');
                                                $scope.$parent.divertAfterSECB();
                                            },
                                            onError: function(){
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                } else {

                                    fn.essentialApi.partialSave(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                DaModal.close('#quotationPartialSave');
                                                $scope.$parent.divertAfterSECB();
                                            },
                                            onError: function(){
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                }
                                fn.postToMaxParent('No',1,'Y')

                            },
                            onError: function(){
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                /** Doesn't use now */
                saveNamedDriversModel: function (callbacks) {
                    var hasError = false;
                    var namedDrivers = [].concat($scope.namedDrivers.PolicyContacts);

                    var saveNextDriver = function () {
                        var nextNamedDriver = namedDrivers.pop();
                        if (nextNamedDriver) {
                            saveNamedDriver(nextNamedDriver);
                            return true;
                        }

                        if (callbacks && callbacks.onSuccess) {
                            callbacks.onSuccess();
                        }
                        else if (hasError) {
                            if (callbacks && callbacks.onError) {
                                callbacks.onError();
                            }
                        }

                        return false;
                    };

                    var saveNamedDriver = function (namedDriver) {

                        var namedDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, namedDriver);
                        var saveFunc = namedDriverApi.utils.getSaveFunctionName(namedDriver, 'NDPolicyContact');

                        if (!saveFunc) {
                            saveNextDriver();
                            return;
                        }

                        namedDriver.Identifier = namedDriver.PreIdentifier + namedDriver.IdentifierNumber + namedDriver.PostIdentifier;
                        namedDriverApi[saveFunc](
                            {
                                onSuccess: function () {
                                    saveNextDriver();
                                },

                                onError: function () {
                                    saveNextDriver();
                                    hasError = true;
                                }
                            }
                        );
                    };

                    saveNextDriver();

                },

                isUserExists: function (callbacks) {
                    callbacks = callbacks || {};

                    fn.essentialApi.isUserExist({
                        onSuccess: function (data) {
                            if (!data.success) {
                                if (callbacks.onError) {
                                    callbacks.onError(data);
                                }
                                return;
                            }

                            var cvrList = [];
                            angular.forEach(data.persons, function (personItem) {
                                var person = personItem.Value;
                                if ((!person) || (!person.CVR)) {
                                    return;
                                }
                                person.CVR.RoleName = personItem.Key;
                                cvrList.push(person.CVR);
                            });

                            var isPromptNeeded = fn.essentialApi.isPromptNeeded(cvrList);

                            if (isPromptNeeded) {
                                if (callbacks.onError) {
                                    callbacks.onError(cvrList);
                                }
                                return;
                            }

                            if (callbacks.onSuccess) {
                                callbacks.onSuccess(cvrList);
                            }

                        },

                        onError: function () {
                            if (callbacks.onError) {
                                DaModal.open('#userMismatchValidation');
                                //this will trigger generic oops
                                fn.utils.setIsSaving(false);
                            }
                        }
                    });

                },

                savePHContactCallback: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    var callMaxar= function(){

                        DaModal.close('#quotationCallback');
                        try{
                            $scope.$parent.gaEvents(4,3);
                        }
                        catch(e){}
                        $scope.$parent.maxarCallbackTrigger = true;
                        fn.postToMaxParent('No',1,'Y');
                    };

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                //if ($scope.$parent.isLoggedIn())
                                if ($scope.currentProposal.StatusCodeId !==undefined &&  $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    fn.confirmationApi.createConfirmation(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                callMaxar();
                                            },
                                            onError: function () {
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                } else {
                                    fn.essentialApi.partialSave(
                                        {
                                            onSuccess: function () {
                                                fn.utils.setIsSaving(false);
                                                callMaxar();
                                            },
                                            onError: function () {
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                }
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

            },

            validations: {

                isTHIDValidPH: function () {
                    if($scope.policyHolder.Identifier)
                        return HKFormValidation.validateTHID($scope.policyHolder.Identifier);

                },

                isTHIDValidMD: function (){
                    if($scope.mainDriver.Identifier)
                        return HKFormValidation.validateTHID($scope.mainDriver.Identifier);
                },

                validateForms: function () {
                    return  $scope.frmAboutMotor.$invalid ||
                        /* $scope.frmAboutPH.$invalid ||
                        $scope.frmAboutMD.$invalid || */
                        $scope.frmYourAdrees.$invalid;
                },

                hasInvalid: function hasInvalid() {
                    var isInvalid = false;

                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    /* if($scope.asset.IsCommercialPurpose){
                        isInvalid = true;
                        DaModal.open('#usedForCommercial');
                    } 

                    else */if($scope.asset.IsRegisterUnderCompany){
                        isInvalid = true;
                        DaModal.open('#regisUnderCompanyName');
                    }

                    /* else if (CountryMotorQuoteValidation.isInvalidDriverAge($scope.policyHolder.DateOfBirth, $scope.currentProposal.PolicyStartDate)) {
                        isInvalid = true;
                        DaModal.open('#ageValidation');
                    } */
                    /* else if($scope.asset.IsCarModified)
                    {
                        isInvalid = true;
                        DaModal.open('#modifiedWarning');
                    } */

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;
                }

            },

            eventHandlers: {
                checkCharLimit:function(event){
                    var BuildingNameChar = $('#BuildingName').val().length;
                    var VillageChar = $('#Village').val().length;
                    var sum = BuildingNameChar + VillageChar;
                    //console.log(sum);
                    if(sum === 50){
                        $('#BuildingName').attr('maxlength', BuildingNameChar);
                        $('#Village').attr('maxlength', VillageChar);
                        //console.log('reach max');
                    }else{
                        $('#BuildingName').attr('maxlength', 50);
                        $('#Village').attr('maxlength', 50);
                    }
                },
                popModifiedYeswarning:function(){
                    if($scope.asset.IsCarModified==true)
                    DaModal.open("#modifiedWarning");
                },

                /*** Doesn't use now */
                /* onBtnConfirmUpdateConflictedContact: function () {

                    DaModal.close('#userMismatchValidation');
                    var hasError = false;
                    fn.utils.setIsSaving(true);
                    var processContact = function (cvrList) {

                        fn.policyContactApi.processPolicyContact({
                            onSuccess: function () {
                                createConfirmation();
                            },
                            onError: function () {
                                hasError = true;
                                savingComplete();
                            }
                        }, cvrList);

                    };

                    var createConfirmation = function () {
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                savingComplete();
                            },
                            onError: function () {
                                hasError = true;
                                savingComplete();
                            }
                        });
                    };

                    var savingComplete = function () {
                        try {
                            if (!$scope.currentProposal.IsRenewalProposal) {
                                fn.logApi.writeLog();
                            }
                        } catch (err) {
                        }

                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }

                        fn.navigation.goToNextStep();
                    };
                    processContact($scope.cvrList);
                }, */
                ////////////////////////////////////

                onBtnCancelUpdateConflictedContact: function () {
                    DaModal.close('#userMismatchValidation');
                },

                onBtnPartialSaveContinueClicked: function () {
                    $scope.pageSubmitted = true;
                    $scope.partialSaveSubmitted = true;
                    if ($scope.phContact.PrimaryEmailAddress.invalid) {
                        return;
                    }
                    fn.saving.partialSave();
                },

                onBtnCallbackClicked :function(){
                    DaModal.open('#quotationCallback');

                },

                onBtnCallbackContinueClicked: function () {

                    $scope.pageSubmitted = true;
                    $scope.callBackSubmitted = true;

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    if ($scope.policyHolder.FirstName.invalid ||$scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }

                    fn.saving.savePHContactCallback();

                },

                onBtnCallbackCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        $scope.phContact.PrimaryPhoneNumber = "";
                    }

                    DaModal.close('#quotationCallback');
                },

                onBtnSaveAndExitClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,2);
                    }
                    catch(e){}
                    DaModal.open('#quotationPartialSave');
                },

                onBtnPartialSaveCancelClicked: function () {
                    DaModal.close('#quotationPartialSave');
                },

                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },

                onBtnNextClicked: function () {
    
                    try{
                        $scope.$parent.gaEvents(0,0);
                    }
                    catch(e){}
                    $scope.pageSubmitted = true;

                    if (fn.validations.hasInvalid()) {
                        return;
                    }

                    fn.utils.setIsSaving(true);
                    var hasError = false;
                    var triggerSave = function () {
                        saveDataModels();
                    };

                    var saveDataModels = function () {
                        fn.saving.saveAllModels(
                            {
                                onSuccess: function () {
                                    checkUserExist();
                                },
                                onError: function () {
                                    hasError = true;
                                    savingComplete();
                                }
                            }
                        );
                    };

                    var checkUserExist = function () {
                        fn.saving.isUserExists({
                            onSuccess: function (cvrList) {
                                processContact(cvrList);
                                //calc();
                            },
                            onError: function (cvrList) {

                                fn.utils.onUserMismatchHandler(cvrList);
                                $scope.cvrList = cvrList;
                                DaModal.open('#userMismatchValidation');
                                hasError = true;
                                savingComplete();
                            }
                        });

                    };

                    var processContact = function (cvrList) {

                        fn.policyContactApi.processPolicyContact({
                            onSuccess: function () {
                                //calc();
                                //createConfirmation();
                                $sessionStorage.tachk = 2;
                                fn.navigation.goToNextStep();
                            },
                            onError: function () {
                                hasError = true;
                                savingComplete();
                            }
                        }, cvrList);

                    };

                    var calc=function()
                    {
                        fn.quoteApi.calculate(
                            {
                                onSuccess: function () {
                                    //createConfirmation();
                                },
                                onError: function () {
                                    hasError = true;
                                    savingComplete();
                                }
                            }
                        );
                    };

                    /* var createConfirmation = function () {
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                savingComplete();
                            },
                            onError: function () {
                                hasError = true;
                                savingComplete();
                            }
                        });
                    }; */


                    var savingComplete = function () {
                        try {
                            if (!$scope.currentProposal.IsRenewalProposal) {
                                fn.logApi.writeLog();
                            }
                        } catch (err) {
                        }

                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }

                        fn.navigation.goToNextStep();
                    };

                    triggerSave();
                },

                onBtnBackClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){}
                    fn.utils.setIsSaving(true);
                    fn.quoteApi.calculate(
                        {
                            onSuccess: function () {
                                fn.navigation.goToPrevStep();
                            },
                            onError: function () {
                                fn.navigation.goToPrevStep();
                            }
                        }
                    );
                },

                onIsRedPlateClicked: function () {
                    /*Reset when user selected "YES"*/
                    if ($scope.asset.IsRedPlate) {
                        if($scope.HKLocale.getLocale === "en-us"){
                            $scope.asset.VehicleRegistrationNumber = "-";
                        }else{
                            $scope.asset.VehicleRegistrationNumber = "ใหม่ ป้ายแดง";
                        }
                        $scope.asset.LicenseCardProvinceId = "2000080";
                    }

                    /*Show value when user selected "NO"*/
                    if((typeof $scope.asset.VehicleRegistrationNumber !== "undefined") && !$scope.asset.IsRedPlate){
                        $scope.asset.VehicleRegistrationNumber = "";
                    }
                },

                onHaveLoanForCarClicked: function() {
                    if (!$scope.asset.HaveLoanForCar) {
                        $scope.asset.FinanceCompanyContact = null;
                    }

                    if(($scope.asset.FinanceCompanyContact == "NULL" || $scope.asset.FinanceCompanyContact == undefined
                        || $scope.asset.FinanceCompanyContact == null) && $scope.asset.HaveLoanForCar){
                        $scope.asset.FinanceCompanyContact = "";
                    }
                },

                onIsCommercialPurposeClicked: function() {
                    if($scope.asset.IsCommercialPurpose){
                        DaModal.open('#usedForCommercial');
                    }
                },

                onIsRegisterUnderCompanyClicked: function() {
                    if($scope.asset.IsRegisterUnderCompany){
                        DaModal.open('#regisUnderCompanyName');
                    }
                },

                onFocusReEmail: function(){
                    $scope.IsReEmailMismatch = false;
                }
            },
            getPriority: function(callback){
                $window.ppstLayer = $window.ppstLayer || $localStorage.ppstLayer;
                var ppstLayer = $window.ppstLayer;
                if($scope.mainDriver.GenderId !== undefined){
                    var gender = $scope.mainDriver.GenderId == 1 ? "FEMALE" : "MALE";
                }else{
                    var gender = $scope.policyHolder.GenderId == 1 ? "FEMALE" : "MALE";
                }
                
                if($scope.mainDriver.MaritalStatusId !== undefined){
                    var marital = $scope.mainDriver.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }else{
                    var marital = $scope.policyHolder.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }
                if($scope.asset.UsageId == 6){
                    var carUsage = "PRIVATE_USE";
                }else if ($scope.asset.UsageId == 1000000){
                    var carUsage = "PRIVATE_USE_AND_COMMUTING_TO_WORK";
                }else if ($scope.asset.UsageId == 1000002){
                    var carUsage = "PRIVATE_AND_BUSINESS_USE";
                }else{
                    var carUsage = "COMMERCIAL_SPECIAL";
                } 

                if($scope.mainDriver.DateOfBirth !== undefined){
                    var dob = $scope.mainDriver.DateOfBirth;
                }else{
                    var dob = $scope.policyHolder.DateOfBirth;
                }

                var premium = $scope.$parent.getMonthlyPriceStepCtrl($scope.coverages);

                ppstLayer.push({
                    "Proposal": $scope.currentProposal.ExternalProposalNumber,
                    "PhoneNumber": $sessionStorage.maxarReference,
                    "DateOfBirth": dob,
                    "Gender": gender,
                    "MaritalStatus": marital,
                    "VehicleUsage": carUsage,
                    "MakeID": $scope.asset.ManufactureId,
                    "ModelID": $scope.asset.ModelId,
                    "YearOfRegistration": $scope.asset.PurchasingYear,
                    "CarDescriptionID": $scope.asset.CarDescriptionId,
                    "Premium": premium,
                    "NumberOfDataChanges": $sessionStorage.noOfPTSave
                });

                $localStorage.ppstLayer = ppstLayer;

                $scope.$parent.getPriority(function(res){
                    if(res){
                        callback(res);
                    }else{
                        callback(0);
                    }
                    
                });
            }

        };

        /** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        // if ($scope.HKLocale.getLocale() === "th-th") { 
        //     $window.cwcCookieBanner.setLang('th'); 
        // }else{
        //     $window.cwcCookieBanner.setLang('en');
        // }

        /** Event Handlers */
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;
        $scope.onBtnConfirmUpdateConflictedContact = fn.eventHandlers.onBtnConfirmUpdateConflictedContact;
        $scope.onBtnCancelUpdateConflictedContact = fn.eventHandlers.onBtnCancelUpdateConflictedContact;
        $scope.onIsRedPlateClicked = fn.eventHandlers.onIsRedPlateClicked;
        $scope.onHaveLoanForCarClicked = fn.eventHandlers.onHaveLoanForCarClicked;
        $scope.onIsCommercialPurposeClicked = fn.eventHandlers.onIsCommercialPurposeClicked;
        $scope.onIsRegisterUnderCompanyClicked = fn.eventHandlers.onIsRegisterUnderCompanyClicked;

        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.popModifiedYeswarning=fn.eventHandlers.popModifiedYeswarning;
        $scope.onFocusReEmail = fn.eventHandlers.onFocusReEmail;
        $scope.checkCharLimit = fn.eventHandlers.checkCharLimit;

        /** Utils */
        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.isPrevInsurerRequired = fn.utils.isPrevInsurerRequired;

        /** Validations */
        $scope.validations = {
            isTHIDValidPH: fn.validations.isTHIDValidPH,
            isTHIDValidMD: fn.validations.isTHIDValidMD,
            isPrevNcdInvalid: fn.validations.isPrevNcdInvalid
        };
        $scope.stepFn = {};

        $scope.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.isThaiResidentPH = fn.utils.isThaiResidentPH;
        $scope.isThaiResidentMD = fn.utils.isThaiResidentMD;

        $scope.dLCE=marketing.dLCE;

        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function() {
            $timeout(function(){
                $scope.$parent.changeGlobalContactNo();
            }, 1000);
        });

    }];