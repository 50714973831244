module.exports = [
    '$scope',
    '$window',
    '$location',
    '$timeout',
    'ConfigUtils',

    function AccountController($scope, $window, $location, $timeout, ConfigUtils) {

        var fn = {

            initScopeObjects: function () {
                $scope.publicSite = '';

                $scope.goToPublicSite = fn.navigations.goToPublicSite;
            },

            initApiObjects: function () {
            },

            initStaticData: function () {
            },

            retrieveScopeObjects: function () {
            },

            navigations: {
                goToPublicSite: function () {
                    var publicPageUrl = {};

                    switch ($scope.publicSite) {
                        case 'health':
                            publicPageUrl = ConfigUtils.healthPageUrl || '/';
                            break;
                        case 'motorcycle':
                            publicPageUrl = ConfigUtils.motorcyclePageUrl || '/';
                            break;
                        case 'motor':
                            publicPageUrl = ConfigUtils.motorcarPageUrl || '/';
                            break;
                        case 'travel':
                            publicPageUrl = ConfigUtils.travelPageUrl || '/';
                            break;
                        default:
                            return;
                    }

                    console.log(publicPageUrl);
                    console.log(publicPageUrl.indexOf('http'));

                    if (publicPageUrl.indexOf('http') >= 0) {
                        $window.location.href = publicPageUrl;
                        return;
                    }

                    var goToPublicPage = function () {
                        $location.path(publicPageUrl);
                        $timeout.cancel(timeoutGoToPublicPage);
                    };

                    var timeoutGoToPublicPage = $timeout(goToPublicPage, 10);
                }
            }
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();
    }];