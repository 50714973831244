/*
 * Created by Gerald Garcia on 29/06/15.
 */

/***
 * The common same height button.
 * @type {*[]}
 */
module.exports = [

    function preventCutCopyPasteInput() {



            function link(scope,element) {
                element.on('cut copy paste',function(event){
                    event.preventDefault();
                });
            }

        return {
            restrict: 'AE',
            link: link
        };
    }];