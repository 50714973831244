/*
 * Created by Gerald Garcia on 06/03/15.
 */
module.exports = [
    '$scope',
    '$rootScope',
    '$state',
    '$localStorage',
    '$window',
    '$stateParams',
    '$anchorScroll',
    'MitScopeBindingUtils',
    'Confirmation',
    'Essential',
    'Session',
    'WfeApiEssential',
    'WfeApiSession',
    'DaLoadingMask',
    'WfeApiConfirmation',
    'HKSteps',
    /**
     * Controller for the Partial Save Router
     * @constructor
     */
    function PartialSaveRouterController($scope, $rootScope, $state, $localStorage, $window, $stateParams, $anchorScroll,
                                         MitScopeBindingUtils, Confirmation, Essential, Session,WfeApiEssential,
                                         WfeApiSession,DaLoadingMask,WfeApiConfirmation,HKSteps) {
        $anchorScroll();

        var fn = {
            setIsLoading: function (isLoading) {
                DaLoadingMask.toggle(isLoading);
            },

            onServiceError: function () {
                fn.setIsLoading(false);
            },
            unhashParam: function() {
                var quoteObj = {
                    quoteNumber: 'pm=' + $stateParams.pm
                };
                var unhashObj = Essential.getDecodeQuote(undefined, quoteObj);
                unhashObj.$promise.then(function (data) {
                    if (!data.success) {
                        fn.eventHandler.errorOnRequest(data);
                        return;
                    }
                    fn.initApiObjects(data.QuoteNumber);


                },
                    fn.eventHandler.errorOnRequest
                );
            },
            utils:{
                getProductCode : function(quoteNumber){
                    var productMap = {
                        'MT': 'motor',
                        'TR': 'travel',
                        'MC': 'motorcycle',
                        'HS': 'health'
                    };
                    var productCodeAbbrev = quoteNumber;
                    productCodeAbbrev = productCodeAbbrev.substring(0,2);
                    return productMap[productCodeAbbrev];

                }
            },
            initApiObjects: function(quoteNumber){


                var productCode = fn.utils.getProductCode(quoteNumber);

                if(!productCode){
                    return;
                }


                fn.sessionApi = WfeApiSession.instance($scope, productCode);

                fn.essentialApi = WfeApiEssential.instance(fn.sessionApi, $scope);
                fn.stepsUtils = HKSteps.instance($scope, fn.sessionApi);

                $scope.savedProposal.proposalNumber =quoteNumber;
                fn.confirmationApi = WfeApiConfirmation.instance(fn.sessionApi, $scope, $scope.savedProposal);
                fn.issuePolicy();
            },

            issuePolicy: function() {
                var localeString = $stateParams.lang? $stateParams.lang: "th-th";
                var products = {
                    '2000003': {state:'public.motor.quote.steps.basic', code:'motor'}, //Old
                    // '2000003': {state:'publicB2C.motor.quote.steps.b2c', code:'motor'},
                    '2000001': {state:'public.travel.sessiontoken', code:'travel'},
                    '2000004': {state:'public.motorcycle.sessiontoken', code:'motorcycle'},
                    '2000006': {state:'public.health.sessiontoken', code:'health'},
                    '2000002': {state:'public.termlife.sessiontoken', code:'termlife'}
                };


                var createMotorQuote = function(){

                    //fn.setIsLoading(true);
                    var tokenId ='';
                    fn.sessionApi.createSession({
                        onSuccess: function (data) {

                            retrieveQuote();
                            tokenId = data.MITSessionToken;


                        }, onError: function () {
                            console.log('error');
                            fn.eventHandler.errorOnRequest();
                        }
                    });
                    var retrieveQuote = function () {
                        fn.confirmationApi.retrieveQuote({
                            onSuccess: function (data) {
                                console.log(data);
                                fn.setIsLoading(false);
                                var parameter = {
                                    token: tokenId,
                                    productCode: data.ProductId
                                };
                                $scope.isAccepted = true;
                                fn.stepsUtils.setIsAccepted(true);
                                fn.stepsUtils.setProdOption('motor');
                                var productState = products['' + data.ProductId].state;
                                var callback = function(){
                                    $state.go(productState,parameter);
                                    
                                };
                                if(localeString){

                                    $scope.HKLocale.setLocaleOnNextLoading(localeString.toLowerCase(),callback);
                                } else {
                                    callback();
                                }


                            },
                            onError: function () {
                                fn.eventHandler.errorOnRequest();
                                console.log('error');
                            }

                        });
                    };

                };

                createMotorQuote();
            },

            initScopeObjects: function () {
                $scope.hasError = false;
                $scope.session = {};
                $scope.savedProposal = {};
                $scope.currentProposal = {};
                $scope.creditCard = {};

                $scope.coverages = {};

            },

            eventHandler: {
                errorOnRequest: function () {
                    $scope.hasError = true;
                    fn.onServiceError();
                }
            }
        };

        fn.initScopeObjects();
        fn.unhashParam();

    }];