/**
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$state',
    '$sessionStorage',
    '$localStorage',
    'ConfigUtils',
    'WfeApiAuth',
    'WfeApiUser',
    'DaAuthorization',
    'HKFormValidation',
    '$window',
    '$timeout',

    function LoginController($scope, $state, $sessionStorage, $localStorage, ConfigUtils, WfeApiAuth, WfeApiUser, DaAuthorization, HKFormValidation, $window,$timeout) {

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/myaccount/login/'}
                );
                /*if(ga){ga('send', 'pageview', '/myaccount/login/');}
                },500);*/
            },
        };

        var fn = {

            initApiObjects: function () {
                fn.authApi = WfeApiAuth.instance(undefined, $scope, $scope.credentials);
                fn.userApi = WfeApiUser.instance(undefined, $scope, $scope.person);
            },

            initScopeObjects: function(){
                $scope.credentials = {
                    UserIdentifier: '',
                    Password: ''
                };
                $scope.person = {};
                $scope.UserIdentifierTmp = '';

                try {
                    marketing.ga();
                } catch (err) {
                    console.log(err);
                }
            },

            validations: {
                hasInvalid: function hasInvalid() {
                    return $scope.frmLogin.$invalid;
                },


                isTHIDValid: function () {
                    if($scope.UserIdentifierTmp){

                        return HKFormValidation.validateTHID($scope.UserIdentifierTmp);

                    }
                },

            },

            eventHandlers: {
                onBtnLoginClicked: function () {
                    //fn.util.procceedToNext();
                    //OYSTERremove above added to bypass login #tofix
                    $scope.pageSubmitted = true;

                    if (fn.validations.hasInvalid()) {
                        return;
                    }

                    if($scope.UserIdentifierTmp)
                        $scope.credentials.UserIdentifier=$scope.UserIdentifierTmp.replace(/[^0-9\.]+/g, "");

                    fn.util.setIsSaving(true);

                    fn.authApi.authenticateUser(
                        {
                            onSuccess: function () {
                                fn.util.retrieveAccUser();
                                /* Enable change password when it's required by API */
                                //$state.go('private.changepassword');
                            },
                            onError: function (data) {
                                $scope.loginError = true;
                                fn.util.setIsSaving(false);
                                console.log(data.ErrorInfo.StatusCode);
                            }
                        }
                    );
                }
            },

            util: {

                setIsSaving: function (isSaving) {
                    $scope.IsSaving = isSaving;
                },

                setCurrentUser: function () {
                    //var person = data.Person;
                    var authPerson = DaAuthorization.getAuthorizedPerson();
                    authPerson.FirstName = $scope.person.FirstName;
                    authPerson.LastName = $scope.person.LastName;
                    $scope.setLoggedInUser(authPerson);
                },

                retrieveAccUser: function()
                {
                    fn.userApi.retrieveUser(
                        {
                            onSuccess: function(data) {
                                angular.extend($scope.person, data.Person);
                                fn.util.setCurrentUser();
                                fn.util.setIsSaving(false);
                                fn.util.procceedToNext();
                            },
                            onError: function () {
                                fn.util.setIsSaving(false);
                            }
                        }
                    );
                },

                procceedToNext: function()
                {
                    $state.go('private.account.user.policies');
                }
            }

             /*
            eventHandler: {

                onLoginCallback: function onLoginCallback() {
                    $scope.accountUser = new AccountUser();
                    $scope.accountUser.$retrieve().then(
                        function (data) {
                            fn.setCurrentUser(data);

                            //Functions are in userstate-controller
                            if ($scope.getRedirectUrl()) {
                                $scope.goToRedirectUrl();
                                $scope.clearRedirectUrl();
                                return;
                            }

                            $scope.clearRedirectUrl();
                            $state.go('private.account.user.policies');
                        }
                    );
                }
            }*/
        };

        //// Initialization
        fn.initScopeObjects();
        fn.initApiObjects();

        ////Event Handlers
        $scope.onBtnLoginClicked = fn.eventHandlers.onBtnLoginClicked;

       //fn.initWatchers();
        $scope.IsSaving = false;

        $scope.validations = {
            isTHIDValid: fn.validations.isTHIDValid
        };

    }];