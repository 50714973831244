/**
 * Created by Gerald Garcia on 16/02/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    '$sessionStorage',
    '$localStorage',
    '$filter',
    'AccountProposal',
    'StaticDataKeyValuePair',
    'DaModal',
    'Session',
    'Confirmation',
    'MitScopeBindingUtils',
    'DaLoadingMask',
    'WfeApiStaticData',
    'WfeApiProposal',
    'HKLocale',
    'WfeApiSession',
    'WfeApiConfirmation',
    '$window',
    'HKSteps',
    'MITDate',

    function BrokerProposalsController($scope, $state, $stateParams, $sessionStorage, $localStorage, $filter, Proposal, StaticDataKeyValuePair, DaModal,
                                 Session, Confirmation, MitScopeBindingUtils, DaLoadingMask, WfeApiStaticData, WfeApiProposal, HKLocale,
                                 WfeApiSession, WfeApiConfirmation,$window,HKSteps,MITDate) {

        var fn = {

            initStaticData: function () {

            },

            initApiObjects: function () {
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), undefined);
                fn.wfeApiProposal = WfeApiProposal.instance(undefined, $scope, $scope.AgentProposals);
            },

            initScopeObjects: function () {

                $scope.selectedProposal = {};
                $scope.AgentProposals = {};

                $scope.isLoading = true;
                $scope.pagination = {};
                $scope.pagination.offset = 0;
                $scope.pagination.pageSize = 25;
                $scope.pagination.pageList = [];
                $scope.search = {};
                $scope.search.text = "";
            },

            retrieveScopeObjects: function () {
                
                fn.wfeApiProposal.RetrieveAgentProposals(
                    {
                        onSuccess: function (data) {

                            angular.extend($scope.AgentProposals, data.AgentProposals);
                            $scope.isLoading = false;
                            var ap = $scope.AgentProposals?$scope.AgentProposals:[] ;
                            $scope.pagination.total = Object.keys(ap).length;
                            var noOfPage = Math.ceil($scope.pagination.total / $scope.pagination.pageSize);
                            for(var i = 0; i < noOfPage; i++ ){
                                $scope.pagination.pageList.push(i + 1);
                            }

                        }
                    }
                );
            },

            eventHandler: {

                btnViewProposalClicked: function btnViewProposalClicked(policySelected) {

                    var productCode='2000003';
                    var sessionApi = WfeApiSession.instance($scope, productCode);

                    $scope.selectedProposal.proposalNumber = policySelected.ProposalNumber;

                    var confirmationApi = WfeApiConfirmation.instance(sessionApi, $scope, $scope.selectedProposal);

                    var tokenId = '';

                    sessionApi.createSession({
                        onSuccess: function (data) {

                            retrieveQuote();
                            tokenId = data.MITSessionToken;


                        }, onError: function () {
                            console.log('error');
                        }
                    });


                    var retrieveQuote = function () {
                        confirmationApi.retrieveQuote({
                            onSuccess: function () {

                                fn.stepsUtils = HKSteps.instance($scope, sessionApi);

                                var parameter = {
                                    token: tokenId,
                                    productCode: 2000003
                                };

                                $scope.isAccepted = true;
                                fn.stepsUtils.setIsAccepted(true);
                                fn.stepsUtils.setProdOption('motor');

                                $state.go('publicB2B.motor.newbrokersession', parameter);

                            },
                            onError: function () {

                            }

                        });
                    };

                },
                showListAgentProposals: function(){
                    //console.log($scope.AgentProposals);
                    
                    
                    var proposals = [];
                    var start = $scope.pagination.offset * $scope.pagination.pageSize;
                    var end   = start + $scope.pagination.pageSize;
                    for(var i = start; i < end; i++){
                        if($scope.AgentProposals[i] ){
                            if($scope.search.text !== ""){
                                var firstname = $scope.AgentProposals[i].FirstName;
                                var lastname = $scope.AgentProposals[i].LastName;
                                var carplate = $scope.AgentProposals[i].CarPlate;
                                var phonenumber = $scope.AgentProposals[i].Phone===undefined ? "": $scope.AgentProposals[i].Phone;
                                var proposalnumber = $scope.AgentProposals[i].ProposalNumber;

                                if(firstname.includes($scope.search.text) || 
                                   lastname.includes($scope.search.text) ||
                                   carplate.includes($scope.search.text) ||
                                   phonenumber.includes($scope.search.text) ||
                                   proposalnumber.includes($scope.search.text)){
                                    proposals.push($scope.AgentProposals[i]);
                                }
                            }else{
                                proposals.push($scope.AgentProposals[i]);
                            }
                            
                            
                        }
                    }
                    //console.log(proposals);
                    return proposals;

                },
                goPage: function(page){
                    $scope.pagination.offset = page - 1;
                    //console.log($scope.pagination);
                },
                startQuote: function(){
                    $state.go('publicB2B.motor.newbrokersession');
                }

            },
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        $scope.formatDate = MITDate.toAngularDate;

        $scope.utils = {};
        $scope.btnViewProposalClicked = fn.eventHandler.btnViewProposalClicked;
        $scope.showListAgentProposals = fn.eventHandler.showListAgentProposals;
        $scope.goPage = fn.eventHandler.goPage;
        $scope.startQuote = fn.eventHandler.startQuote;
    }];