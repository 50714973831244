/*
* Created on 10/06/15.
*/

module.exports = [
    '$sessionStorage',
    '$window',
    '$timeout',
    '$translate',

    /**
     * Country Level Locale for HK
     * @returns {Function}
     * @constructor
     */
    function HKLocaleProvider($sessionStorage, $window, $timeout, $translate) {
        //var me = this;

        var fn = {

            initLocale: function () {
                var me = this;

                var locale = this.getLocale();
                $translate.use(locale)
                    .then(function () {
                        me.isInitialized = true;
                        fn.callPendingGetResourceCall.apply(me);
                        $(document).ready(function(){
                            if(locale === 'th-th'){
                                /**
                                 * We are able to trigger some class from this point but it will not impact all elements in project
                                 * $('select').addClass('da-override-font-size1');
                                 * */
                            }
                        });

                    });
            },

            getLocale: function () {
                return $sessionStorage.pageLocale || 'en-us';
            },

            setLocale: function (lang) {
                this.lang = lang;
                $sessionStorage.pageLocale = lang;
                var reload = function () {
                    $timeout.cancel(reloadWatcher);
                    $window.location.reload();
                };

                var reloadWatcher = $timeout(reload, 100);
            },
            setLocaleOnNextLoading: function (lang,callback) {
                this.lang = lang;
                $sessionStorage.pageLocale = lang;
                var me = this;

                var translateFun = function(){
                    if(me.isInitialized === true){
                        $timeout.cancel(timeoutWatcher);
                        $translate.use(lang).then(function () {

                            me.isInitialized = true;
                            fn.callPendingGetResourceCall.apply(me);
                            if(callback){
                                callback();
                            }

                        });
                    } else {
                        timeoutWatcher = $timeout(translateFun, 100);
                    }
                };
                var timeoutWatcher = $timeout(translateFun, 100);


            },

            /**
             * Gets directly the item from the currently loaded resource file.
             * @param key
             * @param inOutVariable
             * @returns {*|{key: *, value: string}}
             */
            getResourceItem: function (key, inOutVariable) {
                inOutVariable = inOutVariable ||
                {'key': key, 'value':''};

                var callbackFn = function (translation) {
                    inOutVariable.value = translation;
                };
                $translate(key).then(callbackFn, callbackFn);

                return inOutVariable;
            },

            /**
             * Gets the item from the language resource by @param key
             * @param key - value to find in resource file
             * @param inOutVariable - variable to set the result
             * @returns {*} @param inOutVariable
             */
            getResource: function (key, inOutVariable) {

                //Immediately calls the getResourceItem if the locale is already initialized
                if (this.isInitialized) {
                    inOutVariable = fn.getResourceItem(key, inOutVariable);
                    return inOutVariable;
                }

                return fn.pushGetResourceToPending.apply(this, [key, inOutVariable]);
            },

            /**
             * Queue up all the calls for getResource until the locale is initialized
             * @param key
             * @param inOutVariable
             * @returns {*|{key: *, value: string}}
             */
            pushGetResourceToPending: function (key, inOutVariable) {

                var me = this;
                inOutVariable = inOutVariable ||
                {'key': key, 'value':''};

                me.pendingGetResourceCall.push(function () {
                    inOutVariable = fn.getResourceItem(key, inOutVariable);
                });
                return inOutVariable;
            },

            /**
             * Calls all the functions inside the pendingGetResourceCall
             */
            callPendingGetResourceCall: function () {
                var me = this;
                angular.forEach(me.pendingGetResourceCall, function (pendingTranslateFunction){
                    pendingTranslateFunction();
                });
                me.pendingGetResourceCall.splice(0);
            }

        };

        //var LocaleService = function () {
            this.isInitialized = false;
            this.initLocale = fn.initLocale;
            this.getLocale = fn.getLocale;
            this.setLocale = fn.setLocale;
            this.setLocaleOnNextLoading = fn.setLocaleOnNextLoading;
            this.getResource = fn.getResource;
            this.pendingGetResourceCall = [];
        //};


        //this.$get = function () {
        //    return me;
        //};

        //return LocaleService;
    } ];
