/*
 * Created by Gerald Garcia on 05/06/15.
 */
module.exports = [
    '$anchorScroll',
    '$scope',
    '$rootScope',
    '$state',
    '$timeout',
    'MITDate',
    'HKLocale',
    'WfeApiStaticData',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'DaModal',
    'HKFormValidation',
    'CountryMotorQuoteValidation',
    'WfeApiUser',
    'WfeApiContact',
    'WfeApiAddress',
    '$filter',
    'WfeApiLog',
    'WfeApiEssential',
    '$window',
    '$cookies',
    '$sessionStorage',
    'WfeApiCoverages',
    'StaticDataKeyValuePair',
    '$localStorage',
    'AppConstants',
    /**
     * Controller for the Basic Details of Motor Quote
     * @constructor
     */
    function MotorQuoteBasicController($anchorScroll, $scope, $rootScope, $state, $timeout, MITDate, HKLocale, WfeApiStaticData, WfeApiCurrentProposal,
        WfeApiAsset, WfeApiPolicyContact, WfeApiQuote, DaModal, HKFormValidation, CountryMotorQuoteValidation,
        WfeApiUser, WfeApiContact, WfeApiAddress, $filter, WfeApiLog, WfeApiEssential, $window, $cookies, $sessionStorage, WfeApiCoverages, StaticDataKeyValuePair,$localStorage, AppConstants) {
        $anchorScroll();
        var marketing = {
            ga: function () {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event': 'content-view',
                    'virtualPageURL': '/motor/quote/get-quote/step-1/'
                }
                );
            },
            ga_next: function () {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event': 'content-view',
                    'virtualPageURL': '/motor/quote/your-quote/step-2/'
                }
                );
            },
            dLCE: function (ref, v) {

                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                switch (ref) {
                    case 1: dataLayer.push({
                        'event': 'dataLayer-initialised',
                        'manufacture-year': v
                    });
                        break;
                    case 2:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'car-make': v
                        });
                        break;
                    case 3:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'car-model': v
                        });
                        break;
                    case 4:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'car-description': v
                        });
                        break;
                    case 5: dataLayer.push({
                        'event': 'dataLayer-initialised',
                        'maindriver-date-of-birth': v
                    });
                        break;
                    case 6:
                        var s = '';
                        if (v == 1 || v == '1') { s = 'Female' };
                        if (v == 2 || v == '2') { s = 'Male' };
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'maindriver-gender': s
                        });
                        break;
                    case 7:
                        var s = '';
                        if (v == 1 || v == '1' || v == "1") { s = 'Single' };
                        if (v == 2 || v == '2' || v == "2") { s = 'Married' };
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'maindriver-marital': s
                        });
                        break;
                    case 8:
                        var s = '';
                        if (v == true || v == '1' || v == 1) {
                            s = 'Yes/Sometimes';
                            marketing.dLCE(9, undefined); //reset the value of case 9;

                        };
                        if (v == false || v == '0' || v == 0) {
                            s = 'No';
                            marketing.dLCE(9, false); //also set case 9 to no
                        };
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'maindriver-drive-to-work': s
                        });

                        break;
                    case 9:
                        var s = undefined;
                        if (v == true || v == '1' || v == 1) { s = 'Yes/Sometimes' };
                        if (v == false || v == '0' || v == 0) { s = 'No' };
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'maindriver-use-for-work': s
                        });
                        break;

                    case 13:
                        var s = '';
                        if (v == 1000003 || v == '1000003') { s = '0 %' };
                        if (v == 1000005 || v == '1000005') { s = '20%' };
                        if (v == 1000006 || v == '1000006') { s = '30%' };
                        if (v == 1000007 || v == '1000007') { s = '40%' };
                        if (v == 1000008 || v == '1000008') { s = '50%' };
                        if (v == 2500000 || v == '2500000') { s = 'I don\'t know' };
                        if (v == 1000009 || v == '1000009') { s = 'I don\'t know' };

                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'no-claims no claims bonus': s
                        });
                        break;
                    case 14:
                        var s = '';
                        if (v == 1 || v == '1') { s = '0' };
                        if (v == 2 || v == '2') { s = '1' };
                        if (v == 3 || v == '3') { s = '2' };
                        if (v == 4 || v == '4') { s = '3' };
                        if (v == 5 || v == '5') { s = '4' };
                        if (v == 1000000 || v == '1000000') { s = '5' };
                        if (v == 1000001 || v == '1000001') { s = 'more than 5' };
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'driving years of driving exp': s
                        });
                        break;

                    case 45: dataLayer.push({
                        'event': 'dataLayer-initialised',
                        'policy-number': v
                    });
                        break;
                    case 46:
                        if (v === true) {
                            s = 'Yes';
                        } else {
                            s = 'No';
                        }
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'equipped-with-camera': s
                        });
                        break;
                    case 47:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'MakeModelNotCovered': v
                        });
                        break;
                    case 48:
                        dataLayer.push({
                            'event': 'dataLayer-initialised',
                            'UnderwritingError': v
                        });
                        break;
                    default: break;
                }
            }
        };

        var fn = {

            gtAnchor: function (x) {
                //console.log(x);
                $('html,body').animate({
                    scrollTop: $(x).offset().top - 150
                }, 1000);
                DaModal.close("#formInvalid");
            },

            setSourceChannels: function () {

                /* sourcechannel details */
                var sc = {};
                sc.id = 0; sc.cd = '';
                sc = $scope.$parent.sourceChanneler(sc);

                $scope.asset.SourceChannelId = sc.id;
                $scope.asset.ChannelDetails = sc.cd;
                if ($cookies["MGM_id"]) {
                    $scope.asset.ChannelDetails = "MGM_id:" + $cookies["MGM_id"];
                }

                if ($cookies["partner_id"]) {
                    $scope.asset.ChannelDetails = "partner_id:" + $cookies["partner_id"];
                }

            },

            populateMaxar: function () {
                var date = new Date();
                var tmp = $scope.mainDriver.DateOfBirth.split("-");
                var dateOfBirthMaxar = tmp[2] + "-" + tmp[1] + "-" + tmp[0] + " 00:00";

                var sd = '';
                try {
                    sd = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                }
                catch (e) {
                    sd = "01-01-2017 00:00";
                }

                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                var startDate = sd;
                var yearManufacturer = $scope.asset.PurchasingYear;
                var make = $scope.$parent.filterStatic($scope.staticData.makes, $scope.asset.ManufactureId);
                var model = $scope.$parent.filterStatic($scope.staticData.models, $scope.asset.ModelId);
                var description = $scope.$parent.filterDesc($scope.staticData.cardescriptions, $scope.asset.CarDescriptionId);
                var dateBirth = dateOfBirthMaxar;
                var Gender = $scope.$parent.filterStatic($scope.staticData.genders, $scope.mainDriver.GenderId);
                var maritalStatus = $scope.$parent.filterStatic($scope.staticData.maritalStatuses, $scope.mainDriver.MaritalStatusId);
                var driveWork = $scope.$parent.filterStatic($scope.staticData.booleansSometimes, $scope.asset.InDriveWork);
                var useWork = $scope.$parent.filterStatic($scope.staticData.booleansSometimes, $scope.asset.InCourseWork);

                maxarLayer.push(
                    {
                        'startDate': startDate,
                        'yearManufacture': yearManufacturer,
                        'make': make,
                        'model': model,
                        'description': description,
                        'dateBirth': dateBirth,
                        'gender': Gender,
                        'maritalStatus': maritalStatus,
                        'driveWork': driveWork,
                        'useWork': useWork
                    }
                );
            },

            postToMaxParent: function (callBackStatus, Priority, FinalCall) {
                $sessionStorage.maxarReference = $scope.phContact.PrimaryPhoneNumber;
                var ga_source = ''; var ga_campaign = ''; var ga_medium = '';
                var ref_id = ''; var firstname = '';
                var ed = '';

                var date = new Date();
                var maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;

                if($window.maxarLayer.length === 0 ){
                    $window.maxarLayer = [];
                }
                ga_source = $cookies["dac_gacsr"] || '';
                ga_campaign = $cookies["dac_gaccn"] || '';
                ga_medium = $cookies["dac_gacmd"] || '';

                if (ga_source === 0 || ga_source === null || ga_source === "") ga_source = "-";
                if (ga_medium === 0 || ga_medium === null || ga_medium === "") ga_medium = "-";
                if (ga_campaign === 0 || ga_campaign === null || ga_campaign === "") ga_campaign = "-";

                var typeCoverage = '';
                if ($scope.coverages.Coverages) {
                    for (var i = 0; i <= $scope.coverages.Coverages.length; i++) {
                        if ($scope.coverages.Coverages[i].IsSelected) {
                            typeCoverage = $scope.coverages.Coverages[i].Name;
                            break;
                        }
                    }
                }

                if (!$scope.phContact.PrimaryPhoneNumber) {
                    ref_id = '0000000000';
                } else {
                    ref_id = $scope.phContact.PrimaryPhoneNumber;
                }
                if (!$scope.policyHolder.FirstName) {
                    firstname = '-';

                    if (!$scope.phContact.FirstName) {
                        firstname = '-';
                    } else {
                        firstname = $scope.phContact.FirstName;
                    }

                } else {
                    firstname = $scope.policyHolder.FirstName;
                }
                try {
                    ed = ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear() + " " + ('0' + date.getHours()).slice(-2) + ":" + ('0' + date.getMinutes()).slice(-2);
                }
                catch (e) {
                    ed = "01-01-2017 00:00";
                }

                if ($scope.gaCampaign == 'CAT') {
                    ga_source = $cookies["dac_gacsr"] + "/" + $cookies["dac_gacmd"];
                    ga_medium = $cookies["dac_gactr"] + "/" + $cookies["dac_gacct"];
                }

                var b7_lead_type = "Quote lead"  // 2.	Pre-quote B2C
                if(Priority == 22){
                    b7_lead_type = "Pre-quote";
                }

                // Organic lead 
                var ck = $cookies["__utmzz"];

                if(ck == "utmcsr=google|utmcmd=organic|utmccn=(not set)|utmctr=(not provided)"){
                    ga_campaign = "Organic";
                    ga_source   = "Organic";
                    ga_medium   = "-";
                }

                var premium = $scope.totalPremium ? $scope.totalPremium : '-';
                maxarLayer.push(
                    {
                        quoteNumber: $scope.partialPolicyNumber,
                        typeCoverage: typeCoverage,
                        compulsory: true,
                        totalPremium: premium,
                        consent: $scope.asset.consentPrivacyFlag,
                        referenceID: ref_id,
                        endDate: ed,
                        firstName: firstname,
                        lastName: '-',
                        email: $scope.phContact.PrimaryEmailAddress,
                        telephone: ref_id,
                        type: "Quote lead",
                        sourcePage: 'Your Quote',
                        sourceChannel: ga_source,
                        channelDetail: ga_medium,
                        language: HKLocale.getLocale(),
                        callback: callBackStatus,
                        gaCampaign: ga_campaign,

                        blank1: false,
                        priority: Priority,
                        dataFinal: FinalCall,
                        blank7: b7_lead_type
                    }
                );

                if ($scope.$parent !== null) {
                    $scope.$parent.postMaxar();
                }

                $localStorage.maxarLayer = maxarLayer

            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;
                var wfeApiStaticDataYear = fn.wfeApiStaticDataYear;

                var startRetrieval = function () {
                    getBooleanListWithSometimes();
                };

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getCampaignSourceList();
                        },

                        onError: function () {
                            getCampaignSourceList();
                        }
                    });
                };

                var getCampaignSourceList = function () {
                    wfeApiStaticData.getCampaignSourceList({
                        onSuccess: function (data) {
                            $scope.staticData.marketingStrategies = data;
                            getMakeList();
                        },

                        onError: function () {
                            getMakeList();
                        }
                    });
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            /* highlight few models on top */
                            if (data) {
                                var repeatedData = [];
                                filteredMake = $filter('filter')(data, function (r) {
                                    var notAcceptMake = ["1000001",
                                        "1000046",
                                        // "1000007", Chev
                                        "1000047",
                                        "1000008",
                                        "1000071",
                                        "1000009",
                                        "1000011",
                                        "1000092",
                                        "1000050",
                                        "1000018",
                                        "1000099",
                                        // "1000101", MG
                                        "1000093",
                                        "1000032",
                                        "1000035",
                                        "1000053",
                                        "1000036",
                                        "1000086",
                                        "1000038",
                                        "1000066",
                                        "1000039",
                                        "1000087",
                                        "1000088",
                                        "1000041",
                                        "1000098",
                                        "1000005",
                                        "1000027",
                                        "1000002",
                                        "1000004",
                                        "1000102",
                                        "1000096",
                                        "1000010",
                                        "1000097",
                                        "1000103",
                                        "1000020",
                                        "1000023",
                                        "1000024",
                                        "1000104",
                                        "1000094",
                                        "1000065",
                                        "1000100",
                                        "1000105"];
                                    if (!notAcceptMake.includes(r.key)) {
                                        //console.log(r.key);
                                        return r.key;
                                    }
                                });
                                data = filteredMake;

                                repeatedData.push({ key: "1000091", value: "Toyota" });
                                repeatedData.push({ key: "1000015", value: "Honda" });
                                repeatedData.push({ key: "1000031", value: "Nissan" });
                                repeatedData.push({ key: "1000025", value: "Mazda" });
                                repeatedData.push({ key: "1000080", value: "Isuzu" });
                                repeatedData.push({ key: "1000028", value: "Mitsubishi" });
                                repeatedData.push({ key: "0000000", value: "--------------" });

                                $scope.staticData.makes = repeatedData.concat(data);
                            }
                            else {
                                $scope.staticData.makes = data;
                            }
                            getGenderList();
                        },

                        onError: function () {
                            getGenderList();
                        }
                    });
                };

                var getGenderList = function () {

                    if ($scope.HKLocale.getLocale() === "th-th") {
                        var data = [{ "key": "2", "value": "ชาย" }, { "key": "1", "value": "หญิง" }];
                        $scope.staticData.genders = data;
                    } else {
                        var data = [{ "key": "1", "value": "Female" }, { "key": "2", "value": "Male" }];
                        $scope.staticData.genders = data.reverse();
                    }
                    getMaritalStatusList();
                };

                var getMaritalStatusList = function () {

                    if ($scope.HKLocale.getLocale() === "th-th") {
                        var data = [{ "key": "1", "value": "โสด" }, { "key": "2", "value": "สมรส" }];
                    } else {
                        var data = [{ "key": "1", "value": "SINGLE" }, { "key": "2", "value": "MARRIED" }];
                    }
                    $scope.staticData.maritalStatuses = data;
                    getDatesPartsDD();
                };

                var getDatesPartsDD = function () {

                    wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },

                        onError: function () {
                            getDatesPartsMM();
                        }
                    });
                };
                var getDatesPartsMM = function () {

                    wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;
                            getDatesPartsYY();
                        },

                        onError: function () {
                            getDatesPartsYY();
                        }
                    });
                };
                var getDatesPartsYY = function () {

                    wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            //getDriverExperienceList();
                        },

                        onError: function () {
                            //getDriverExperienceList();
                        }
                    });
                };

                startRetrieval();

            },

            initScopeObjects: function () {
                $scope.$parent.isUserProceedOnBuy();
                $scope.staticData = {};

                var dateTomorrow = MITDate.newDate().add(1, 'days');
                $scope.currentProposal = {
                    PolicyStartDate: MITDate.toAngularDate(dateTomorrow),
                    PolicyEndDate: MITDate.toAngularDate(dateTomorrow.add(1, 'years'))
                };
                $scope.asset = {
                    HasAntiTheftDevice: false,
                    'LobInsuranceFormulaId': AppConstants.AGGConfig.TYPE1_FALCON_DG 

                };

                var dateToday = MITDate.newDate();
                $scope.yearNow = (parseInt(MITDate.getYYYY(dateToday)) - 9) || 1941;

                $scope.asset.ProductId = $scope.$parent.product.ProductId;

                $scope.policyHolder = {};
                $scope.mainDriver = {};

                $scope.accountUser = {};
                $scope.phAddress = {};
                $scope.phContact = {};

                $scope.bidata = {};
                $scope.errdata = {};

                $scope.securityQuestion = {};
                $scope.isSecurityQuestion = false;

                $scope.DOBEmpty = false;
                $scope.loadingSpinner = true;
                $scope.partialPolicyNumber = "";

                $scope.applyNCB;

                $scope.txtMaker = fn.getTranslateText('maker');
                $scope.txtModel = fn.getTranslateText('model');
                $scope.txtYear = fn.getTranslateText('year');
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.fromWidgetMsg = false;
                $scope.searchText = "";
                $scope.jsonData;
                $scope.policyHolder.HasUnsubscribedMarketingEmail = true;
                $scope.progress = 10;
                $scope.UserExpandAboutCar = false;
                $scope.UserExpandAboutMainDriver = false;
                $scope.aboutCarCompleted = false;
                $scope.aboutMainDriverCompleted = false;
                $scope.contactInfoCompleted = false;
                $scope.acceptClicked = false;
                $scope.carAge = 0;
                $scope.bodyType = '';// set 4 doors as default
                $scope.gearType = ''; //Set manual as default
                $scope.carUsageList = [{ "key": "6", "value": "btn_car_usage1" },
                { "key": "1000000", "value": "btn_car_usage2" },
                { "key": "1000002", "value": "btn_car_usage3" },
                { "key": "0", "value": "btn_car_usage4" }]
            },

            initApiObjects: function () {

                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.yearNow);
                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress);

                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, $scope.errdata);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

            },

            /** Subscribe MKT email condition */
            initSubscribeWatcher: function () {
                $scope.$watch('policyHolder.HasUnsubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.SubscribedMarketingEmail = !value;
                });
                $scope.$watch('policyHolder.SubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.HasUnsubscribedMarketingEmail = !value;
                });
                $scope.$watch('asset.ManufactureId', function (val) {
                    if (val) { $scope.pmake = fn.eventHandlers.getStepProgress(); } else { $scope.pmake = 0; }
                });
                $scope.$watch('asset.ModelId', function (val) {
                    if (val) { $scope.pmodel = fn.eventHandlers.getStepProgress(); } else { $scope.pmodel = 0; }
                });
                $scope.$watch('asset.PurchasingYear', function (val) {
                    if (val) { $scope.pyear = fn.eventHandlers.getStepProgress(); } else { $scope.pyear = 0; }
                });
                $scope.$watch('asset.CarDescriptionId', function (val) {
                    if (val) { $scope.pdesc = fn.eventHandlers.getStepProgress(); } else { $scope.pdesc = 0; }
                });
                $scope.$watch('asset.IsCarCameraInstalled', function (val) {
                    if (val !== undefined) { $scope.pcamera = fn.eventHandlers.getStepProgress(); } else { $scope.pcamera = 0; }
                });
                $scope.$watch('mainDriver.DateOfBirth', function (val) {
                    if (val !== undefined) { $scope.pmddob = fn.eventHandlers.getStepProgress(); } else { $scope.pmddob = 0; }
                });
                $scope.$watch('mainDriver.GenderId', function (val) {
                    if (val !== undefined) { $scope.pgender = fn.eventHandlers.getStepProgress(); } else { $scope.pgender = 0; }
                });
                $scope.$watch('mainDriver.MaritalStatusId', function (val) {
                    if (val !== undefined) { $scope.pmarital = fn.eventHandlers.getStepProgress(); } else { $scope.pmarital = 0; }
                });
                $scope.$watch('asset.UsageId', function (val) {
                    if (val !== undefined && val === false) { $scope.pindrivework = fn.eventHandlers.getStepProgress(); } else { $scope.pindrivework = 0; }
                });

                $scope.$watch('policyHolder.FirstName', function (val) {
                    if (val !== undefined && val !== '') { $scope.pfirstname = fn.eventHandlers.getStepProgress(); } else { $scope.pfirstname = 0; }
                });
                $scope.$watch('phContact.PrimaryPhoneNumber', function (val) {
                    if (val !== undefined && val !== '') { $scope.pmobileno = fn.eventHandlers.getStepProgress(); } else { $scope.pmobileno = 0; }
                });
                $scope.$watch('phContact.PrimaryEmailAddress', function (val) {
                    if (val !== undefined && val !== '') { $scope.pemail = fn.eventHandlers.getStepProgress(); } else { $scope.pemail = 0; }
                });

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        destroyWatcher();
                    }
                );
            },

            retrieveScopeObjects: function () {

                /** Fire to GA that customer has been visited page */
                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                try {
                                    marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveAsset();
                            },
                            onError: function () {
                                try {
                                    marketing.ga();
                                } catch (err) {
                                    console.log(err);
                                }
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);

                                $scope.applyNCB = $scope.$parent.applyNCBRoot;
                                if ($scope.$parent.applyNCBRootDontKnow) {
                                    $scope.asset.NcdLevelId = "1000009";
                                }

                                if ($scope.asset.ManufactureId) {
                                    $scope.txtMaker = $filter('filter')($scope.staticData.makes, { key: $scope.asset.ManufactureId })[0].value;
                                    fn.eventHandlers.onDropMakeChanged($scope.asset.ManufactureId);
                                    $scope.asset.PurchasingYear = $scope.asset.PurchasingYear.toString();
                                }

                                if ($scope.asset.ModelId) {
                                    fn.eventHandlers.onDropModelChanged($scope.asset.ManufactureId, $scope.asset.ModelId);

                                    var objParams = {};
                                    objParams.manufacturerId = $scope.asset.ManufactureId;
                                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                                    wfeApiStaticDataModel.getModelListV2({
                                        onSuccess: function (data) {
                                            fn.assetStaticData.model = data;
                                            $scope.txtModel = $filter('filter')(fn.assetStaticData.model, { key: $scope.asset.ModelId })[0].value;
                                        }
                                    });

                                }
                                if ($scope.asset.PurchasingYear) {
                                    fn.eventHandlers.onDropPurchasingYearChanged($scope.asset.ModelId, $scope.asset.PurchasingYear);

                                    $scope.txtYear = $scope.asset.PurchasingYear;
                                }

                                if ($scope.asset.CarDescriptionId) {
                                    var objParams = {};
                                    objParams.modelId = $scope.asset.ModelId;
                                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                                    wfeApiStaticDataModel.getCarDescWithYear({
                                        onSuccess: function (data) {
                                            fn.assetStaticData.description = data;
                                            $scope.txtDesc = $filter('filter')(fn.assetStaticData.description, { key: $scope.asset.CarDescriptionId })[0].description;

                                            var hgroup = $scope.txtDesc.split(" ");

                                            $scope.asset.selectedEngineSize = hgroup[0] + " " + hgroup[1] + " ";
                                            fn.eventHandlers.setEngineSize($scope.asset.selectedEngineSize,);
                                        }
                                    });
                                }

                                retrievePHContact();
                            },
                            onError: function () {
                                retrievePHContact();
                            }
                        }
                    );
                };

                var retrievePHContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                retrievePolicyHolder();
                            },
                            onError: function () {
                                retrievePolicyHolder();
                            }
                        }
                    );
                }

                /** PolicyContact  Retrieval : this will retrieve MD if PH is not MD */
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data, $scope.policyHolder);
                                }

                                angular.extend($scope.policyHolder, data);
                                if ($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                    $scope.policyHolder.SubscribedMarketingEmail = false;
                                } else {
                                    $scope.policyHolder.SubscribedMarketingEmail = true;
                                }

                                if ($scope.policyHolder.ConsentId == 7000000) {
                                    $scope.asset.consentPrivacyFlag = true;
                                } else {
                                    $scope.asset.consentPrivacyFlag = false;
                                }


                                $scope.loadingSpinner = false;
                                $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);

                                if (data === undefined) {
                                    retrieveMainDriver();
                                }

                                if (data.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                }
                                else {
                                    retrieveMainDriver();
                                }

                                fn.retrieveAccountUserObjects();
                            },
                            onError: function () {
                                $scope.loadingSpinner = false;
                                $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);
                                fn.retrieveAccountUserObjects();
                            }
                        }
                    );
                };

                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.mainDriver, data);
                                if ($scope.mainDriver.DateOfBirth) {
                                    fn.utils.ConvertMITDateToDisplay();
                                }
                                if ($scope.mainDriver.IsMainDriver) {
                                    $scope.policyHolder.IsMainDriver = false;
                                }
                            }
                        }
                    );
                };

                retrieveCurrentProposal();
            },

            retrieveAccountUserObjects: function () {
                var retrieveUser = function () {
                    if ($scope.$parent.isLoggedIn() && angular.equals({}, $scope.policyHolder)) {
                        fn.wfeApiUser.retrieveUser(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.accountUser, data);
                                    fn.utils.populateAccountUserModel();
                                },
                                onError: function () {
                                }
                            }
                        );
                    }
                };

                retrieveUser();
            },

            utils: {

                setSelectedCoverage: function (selectedCoverage) {
                    $scope.selectedCoverage = selectedCoverage;
                    $scope.isBtnProceedDisabled = false;
                    $scope.isCoverSelected = true;
                },

                setDefaultCoverage: function () {
                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE1_FALCON_DG })[0];
                    if (selectedCoverage && $scope.carAge < 8) {
                        selectedCoverage.IsSelected = true;
                        $scope.currentSelectedCoverage = selectedCoverage.CoverTypeId;
                        marketing.dLCE(10, 'Voluntary Type 1');
                        marketing.dLCE(11, true);
                    } else {
                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG })[0];
                        $scope.currentSelectedCoverage = selectedCoverage.CoverTypeId;
                        selectedCoverage.IsSelected = true;
                        marketing.dLCE(10, 'Voluntary Type 2+');
                        marketing.dLCE(11, true);
                    }
                },

                IsCompulsarySelectedFrontEndFn: function () {
                    if (!$scope.coverages) return false;
                    var status = false;
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {

                        try {
                            var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                                ProductLineGroupId: AppConstants.AGGConfig.PRODUCT_LINE_GROUP_ID_COMPLUSORY_BASIC_COVER_DA,
                            }, true);
                            if (ccb) {
                                if (ccb[0].IsSelected) status = true;
                                else status = false;
                            }

                        }
                        catch (err) {
                            status = false; return status;
                        }
                    }
                    return status;
                },

                getSelectedCoverage: function () {

                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { IsSelected: true })[0];

                    if (selectedCoverage) {
                        $scope.selectedCoverage = selectedCoverage;
                    }
                    else {
                        $scope.selectedCoverage = null;
                    }

                    return selectedCoverage;
                },

                /** Doesn't use now */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                autoSetEndDate: function () {

                    if (!$scope.currentProposal.PolicyStartDate) {
                        return;
                    }

                    var startDate = MITDate.newDate($scope.currentProposal.PolicyStartDate);
                    var endDate = startDate.add(1, 'years');
                    $scope.currentProposal.PolicyEndDate = MITDate.toAngularDate(endDate);

                },

                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                    $scope.DaLoadingMask.toggle(isSaving);
                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;

                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    fn.utils.ConvertMITDateToDisplay();
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;

                },

                /** To push phContact and phAddress of existing user to scope object */
                populateAccountUserModel: function () {
                    angular.extend($scope.policyHolder, $scope.accountUser.Person);
                    $scope.policyHolder.IsMainDriver = true;
                    fn.utils.copyPHFieldsToMD();

                    if ($scope.accountUser.Address) {
                        var phAddress = $scope.accountUser.Address.Addresses[0];

                        if (phAddress) {
                            angular.extend($scope.phAddress, phAddress);
                            $scope.asset.ResidentialDistrictId = phAddress.ProvinceId;
                        }
                    }

                    if ($scope.accountUser.Contact) {
                        var contact = $scope.accountUser.Contact;

                        var PrimaryPhoneNumber = $filter('filter')(contact.Contacts, {
                            ContactType: 1,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryPhoneNumber) {
                            $scope.phContact.PrimaryPhoneNumber = PrimaryPhoneNumber.Value;
                        }

                        var PrimaryEmailAddress = $filter('filter')(contact.Contacts, {
                            ContactType: 0,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryEmailAddress) {
                            $scope.phContact.PrimaryEmailAddress = PrimaryEmailAddress.Value;
                        }
                    }
                },

                convertMITDate: function () {
                    if (typeof $scope.mainDriver.DOB_DD !== "undefined" &&
                        typeof $scope.mainDriver.DOB_MON !== "undefined" &&
                        typeof $scope.mainDriver.DOB_Year !== "undefined") {

                        $scope.$parent.setDateValidity($scope.mainDriver.DOB_DD, $scope.mainDriver.DOB_MON, $scope.mainDriver.DOB_Year, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.mainDriver.DateOfBirth = $scope.$parent.convertDateThreeToOne($scope.mainDriver.DOB_DD, $scope.mainDriver.DOB_MON, $scope.mainDriver.DOB_Year);
                        $scope.mainDriver.DateOfBirth == null ? ($scope.mainDriver.DateOfBirth = '') : '';
                        $scope.validations.isValidDriverAge($scope.mainDriver.DateOfBirth, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.DOBEmpty = false;
                    } else {
                        $scope.mainDriver.DateOfBirth = undefined;
                    }
                },

                ConvertMITDateToDisplay: function () {
                    if ($scope.mainDriver.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree($scope.mainDriver.DateOfBirth);
                        if (d != null || d.length > 0) {
                            $scope.mainDriver.DOB_DD = d[0];
                            $scope.mainDriver.DOB_MON = d[1];
                            $scope.mainDriver.DOB_Year = d[2];
                        }
                    }


                },

                setDefaultMainDriverDrivingExp: function () {
                    // For issue BAT-4529 : Set default driving experience as more than 5 years
                    if ($scope.mainDriver.DrivingExperienceId == null) {
                        $scope.mainDriver.DrivingExperienceId = 1000001;
                    }
                }
            },

            navigation: {
                goToNextPage: function () {
                    fn.utils.setIsSaving(false);

                    try {
                        marketing.ga_next();
                    } catch (err) {
                        console.log(err);
                    }
                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(2);
                    $state.go('public.motor.quote.steps.cover');
                }
            },

            saving: {

                saveCurrentProposalChildModels: function () {

                    /**
                     * Section 1 : Create necessary objects, then pre calculate.
                     * */

                    var hasError = false;
                    var triggerSave = function () {
                        saveAsset();
                    };

                    var saveAsset = function () {
                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');

                        if (saveAssetFunc === "updateAsset") {
                            if ($scope.asset.NcdLevelId != "1000003") {
                                $scope.asset.ReasonForNcdIsZero = "0";
                                $scope.asset.NcdLevelIdOnOtherCar = "0";

                            }
                        }

                        fn.assetApi[saveAssetFunc](
                            {
                                onSuccess: function (data) {
                                    savePH(); //hold on to Contact PH for Email and Mobile later.

                                },
                                onError: function () {
                                    hasError = true;
                                    savePH();
                                }
                            }
                        );
                    };

                    var savePH = function () {
                        $scope.loc = '';
                        $scope.loc = HKLocale.getLocale();

                        if ($scope.loc == 'en-us') {
                            $scope.policyHolder.PreferredLanguageId = 101;
                        }

                        if ($scope.loc == 'th-th') {
                            $scope.policyHolder.PreferredLanguageId = 2000000;
                        }

                        $scope.policyHolder.IsMainDriver = true;   // Note: test change policy holder is maindriver or not
                        if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }

                        if ($scope.HKLocale.getLocale() === "en-us") {
                            $scope.policyHolder.PreferredLanguageId = 101;
                        } else {
                            $scope.policyHolder.PreferredLanguageId = 2000000;
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');

                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function (data) {
                                    saveMD();

                                },
                                onError: function () {
                                    hasError = true;
                                    saveMD();
                                }
                            }
                        );
                    };

                    var saveMD = function () {
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');
                        if (!saveMDFunc) {
                            savePHContact();
                            return;
                        }

                        fn.mainDriverApi[saveMDFunc](
                            {
                                onSuccess: function () {
                                    savePHContact();
                                },
                                onError: function () {
                                    hasError = true;
                                    savePHContact()
                                }
                            }
                        );
                    };

                    var savePHContact = function () {
                        var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                        fn.phContactApi[savePHContactFunc](
                            {
                                onSuccess: function () {
                                    calculatePremium();
                                },
                                onError: function () {
                                    hasError = true;
                                    calculatePremium();
                                }
                            }
                        );
                    };

                    var calculatePremium = function () {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }
                        fn.quoteApi.calculate(
                            {
                                onSuccess: function () {
                                    retrieveCurrentProposal();
                                },
                                onError: function (data) {
                                    marketing.dLCE(48, 'Yes');
                                    fn.utils.setIsSaving(false);
                                    try {
                                        if (!$scope.currentProposal.IsRenewalProposal) {
                                            $scope.bidata = {
                                                Source: $scope.$parent.bi.whereDidYouHereUs
                                            };
                                            $scope.errdata = data;
                                            if ($scope.$parent.bi.whereDidYouHereUs) {
                                                fn.logApi.writeQuoteRejectLog();
                                            }
                                        }
                                    } catch (err) {
                                    }

                                    fn.eventHandlers.onDefaultErrorHandler();
                                }
                            }
                        );
                    };

                    /**
                     * Section 2 : Retrieve necessary objects
                     * */

                    var retrieveCurrentProposal = function () {
                        fn.currentProposalApi.retrieveCurrentProposal(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.currentProposal, data);

                                    try {
                                        if (!$scope.currentProposal.IsRenewalProposal) {
                                            $scope.bi.whereDidYouHereUs = 'None';
                                            $scope.bidata = {
                                                Source: $scope.$parent.bi.whereDidYouHereUs
                                            };
                                            if ($scope.$parent.bi.whereDidYouHereUs && !$scope.$parent.bi.isDone) {
                                                fn.logApi.writeLog(
                                                    {
                                                        onSuccess: function () {
                                                        },
                                                        onError: function () {
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    } catch (err) {
                                    }

                                    retrieveAsset();
                                },
                                onError: function () {
                                    retrieveAsset();
                                }
                            }
                        );
                    };

                    var retrieveAsset = function () {
                        fn.assetApi.retrieveAsset(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.asset, data);
                                    retrieveCoverages();
                                },
                                onError: function () {
                                    retrieveCoverages();
                                }
                            }
                        );
                    };

                    var retrieveCoverages = function () {
                        fn.coverageApi.retrieveCoverages(
                            {
                                onSuccess: function (data) {

                                    // Added by OB For temporary coverages sequence
                                    var coverages = [];
                                    if (data.CoverListsCount == 5) {
                                        var index = [2000017, 2000019, 2000016, 2000018, 2000020];
                                        $scope.tyepOfCoverageTable = {
                                            "body_popup_cover_item1": [true, true, false, false],
                                            "body_popup_cover_item2": [true, true, true, true],
                                            "body_popup_cover_item3": [true, false, true, false],
                                            "body_popup_cover_item4": [true, false, false, false],
                                            "body_popup_cover_item5": [false, false, false, false],
                                            "body_popup_cover_item6": [true, true, false, false]
                                        };
                                    } else {
                                        var index = [2000015, 2000017, 2000019, 2000016, 2000018, 2000020];
                                        $scope.tyepOfCoverageTable = {
                                            "body_popup_cover_item1": [true, true, true, false, false],
                                            "body_popup_cover_item2": [true, true, true, true, true],
                                            "body_popup_cover_item3": [true, true, false, true, false],
                                            "body_popup_cover_item4": [true, true, false, false, false],
                                            "body_popup_cover_item5": [true, false, false, false, false],
                                            "body_popup_cover_item6": [true, true, true, false, false]
                                        };
                                    }
                                    for (var j in index) {
                                        for (var i in data.Coverages) {
                                            if (data.Coverages[i].CoverTypeId === index[j]) {
                                                coverages[j] = data.Coverages[i];
                                            }
                                        }
                                    }

                                    data.Coverages = coverages;
                                    // ------------------------ 

                                    angular.extend($scope.coverages, data);
                                    $scope.IsCompulsarySelectedFrontEnd = fn.utils.IsCompulsarySelectedFrontEndFn();
                                    var selectedCoverage = fn.utils.getSelectedCoverage();
                                    if (selectedCoverage) {
                                        fn.utils.setSelectedCoverage(selectedCoverage);

                                        // Added by OB for selected coverages
                                        for (var i in selectedCoverage.Covers) {
                                            if (selectedCoverage.Covers[i].CoverName === "Voluntary Basic Cover") {
                                                $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                            }
                                        }
                                        // ------------------------ 
                                    }
                                    else {
                                        fn.utils.setDefaultCoverage();
                                        $scope.ExcessLevelId = 7000154;
                                    }
                                    //fn.utils.setSumInsured();

                                    $scope.coverages.isForUpdate = true;
                                    if (selectedCoverage) {
                                        if (selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPECOMPULSORY_FALCON_DG) {
                                            $scope.isCompulsoryOnly = true;
                                        } else {
                                            $scope.isCompulsoryOnly = false;
                                        }

                                    } else {
                                        $scope.isCompulsoryOnly = false;
                                    }

                                    //fn.utils.closeIsSaving();
                                    retrievePHContact();
                                },
                                onError: function () {
                                    retrievePHContact();
                                }
                            }
                        );
                    };

                    var retrievePHContact = function () {
                        fn.phContactApi.retrievePHContact(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.phContact, data);
                                    getBooleanList();

                                },
                                onError: function () {
                                    getBooleanList();
                                }
                            }
                        );
                    };

                    var getBooleanList = function () {
                        fn.wfeApiStaticData.getBooleanList({
                            onSuccess: function (data) {
                                $scope.staticData.booleans = data;

                                if ($scope.coverages.CoverListsCount == 5) {
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG })[0];
                                } else {
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE1_FALCON_DG })[0];
                                }
                                if (selectedCoverage.IsSelected) {
                                    retrieveExcess();
                                } else {
                                    $scope.loadingSpinner = false;
                                    retrievePolicyHolder();
                                }

                            },

                            onError: function () {
                                //retrieveDescStaticData();

                                if ($scope.coverages.CoverListsCount == 5) {
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG })[0];
                                } else {
                                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: AppConstants.AGGConfig.TYPE1_FALCON_DG })[0];
                                }
                                if (selectedCoverage.IsSelected) {
                                    retrieveExcess();
                                } else {
                                    $scope.loadingSpinner = false;
                                    retrievePolicyHolder();
                                }
                            }
                        });
                    };

                    var retrieveExcess = function () {
                        fn.coverageApi.getVehicleExcess(
                            {
                                onSuccess: function (data) {
                                    if (data && data[0].value)
                                        $scope.allExcess = data[0].value;

                                    $scope.loadingSpinner = false;
                                    retrievePolicyHolder();
                                },
                                onError: function () {
                                    $scope.loadingSpinner = false;
                                    retrievePolicyHolder();
                                }
                            }
                        );
                    };

                    /** PolicyContact retrieval: this will retrieve MD if PH is not MD */
                    var retrievePolicyHolder = function () {

                        fn.policyHolderApi.retrievePHPolicyContact(
                            {
                                onSuccess: function (data) {

                                    angular.extend($scope.policyHolder, data);
                                    if ($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                        $scope.policyHolder.SubscribedMarketingEmail = false;
                                    } else {
                                        $scope.policyHolder.SubscribedMarketingEmail = true;
                                    }

                                    if (data === undefined) {
                                        retrieveMainDriver();
                                        fn.saving.saveCoveragesModel(callbacksA);
                                    }

                                    if (data.IsMainDriver) {
                                        fn.utils.copyPHFieldsToMD();
                                        fn.saving.saveCoveragesModel(callbacksA);
                                    } else {
                                        retrieveMainDriver();
                                        fn.saving.saveCoveragesModel(callbacksA);
                                    }

                                },
                                onError: function () {
                                }
                            }
                        );
                    };

                    var retrieveMainDriver = function () {
                        fn.mainDriverApi.retrieveMDPolicyContact(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.mainDriver, data);
                                    if ($scope.mainDriver.DateOfBirth) {
                                        fn.utils.ConvertMITMDDateToDisplay();
                                    }
                                    if ($scope.mainDriver.IsMainDriver) {
                                        $scope.policyHolder.IsMainDriver = false;
                                    }

                                }
                            }
                        );
                    };

                    /**
                     * Section 3 : Update & Final calculate & Partial Save
                     * */

                    var callbacksA = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacksB);
                        }
                    };

                    var callbacksB = {
                        /* onSuccess: function () {
                            fn.saving.calculatePremium(
                                { */
                        onSuccess: function () {
                            fn.saving.saveOtherDetailsPH(callbacksC);
                        },
                        onError: function () {
                            fn.saving.saveOtherDetailsPH(callbacksC);
                        }
                        /*}
                     );
                } */
                    };

                    var callbacksC = {
                        onSuccess: function () {
                            fn.navigation.goToNextPage();
                        }
                    };

                    triggerSave();
                },

                getSaveFunctionName: function (model, functionName) {
                    var saveFunc = model.isForUpdate ? 'update' : 'create';
                    return saveFunc + functionName;
                },

                calculatePremium: function (callbacks) {
                    fn.quoteApi.calculate(callbacks);
                },

                saveOtherDetailsPH: function (callbacks) {
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                /*proceed if pnumber else ps.*/
                                if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                } else {

                                    fn.essentialApi.partialSave(
                                        {
                                            onSuccess: function (data) {
                                                $scope.partialPolicyNumber = data['QuoteNumber'];
                                                fn.utils.setIsSaving(false);
                                                $scope.$parent.maxarCallbackTrigger = false;
                                                marketing.dLCE(45, $scope.partialPolicyNumber);
                                                $sessionStorage.noOfPTSave = $sessionStorage.noOfPTSave + 1;

                                                var goNext = function () {

                                                    fn.saving.saveCoveragesModel(
                                                        {

                                                            onSuccess: function () {
                                                                fn.logApi.updateLog();
                                                            },
                                                            onError: function () {
                                                            }
                                                        }
                                                    );

                                                    if (callbacks && callbacks.onSuccess) {
                                                        callbacks.onSuccess();
                                                    }

                                                }

                                                var defaultPriorityJudge = function () {
                                                    if ($scope.selectedCoverage.CoverTypeId == AppConstants.AGGConfig.TYPE2PLUS_FALCON_DG) {
                                                        fn.getPriority(function (priority) {
                                                            fn.postToMaxParent('No', priority ? priority : 4, 'N');
                                                            goNext();
                                                        });
                                                        //fn.postToMaxParent('No', 4, 'N');
                                                        // goNext();
                                                    } else {
                                                        fn.postToMaxParent('No', 5, 'N');
                                                        goNext();
                                                    }
                                                }

                                                if ($scope.selectedCoverage.CoverTypeId == stants.AGGConfig.TYPE1_FALCON_DG && $scope.$parent.ppstEnable) {
                                                    fn.getPriority(function (priority) {
                                                        
                                                            if (priority != 0) {
                                                                fn.postToMaxParent('No', priority, 'N');
                                                                goNext();
                                                            } else {
                                                                defaultPriorityJudge();
                                                            }
                                                        


                                                    });
                                                } else {
                                                    defaultPriorityJudge();
                                                }



                                            },
                                            onError: function () {
                                                fn.utils.setIsSaving(false);
                                            }
                                        }
                                    );
                                }

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                saveCoveragesModel: function (callbacks) {

                    var triggerSave = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc](
                            {
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;

                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },

                savePHContactToProceed: function (callbacks) {

                    fn.utils.setIsSaving(true);
                    var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                    fn.policyHolderApi[savePHFunc](
                        {
                            onSuccess: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            }
                        }
                    );

                },

            },

            validations: {

                checkNCDLevel: function () {
                    if ($scope.asset.NcdLevelId !== '1000003' || $scope.asset.NcdLevelId != 1000003) {
                        $scope.asset.ReasonForNcdIsZero = '';
                        $scope.asset.NcdLevelIdOnOtherCar = '';
                    } else {

                    }

                    if ($scope.asset.NcdLevelId == 1000009 || $scope.asset.NcdLevelId == "1000009") {
                        $scope.$parent.applyNCBRootDontKnow = true;
                    } else {
                        $scope.$parent.applyNCBRootDontKnow = false;
                    }
                },

                checkDOBEmpty: function () {
                    if (typeof $scope.mainDriver.DOB_DD === 'undefined' || typeof $scope.mainDriver.DOB_MON === 'undefined' || typeof $scope.mainDriver.DOB_Year === 'undefined') {
                        $scope.DOBEmpty = true;
                    }
                },

                checkMake: function () {
                    if ($scope.asset.ManufactureId == "0000000" || $scope.asset.ManufactureId == 0) {
                        $scope.frmAboutCar.vehicleMake.$setValidity("valid", false);
                    }
                    else {
                        $scope.frmAboutCar.vehicleMake.$setValidity("valid", true);
                    }
                },

                isStartDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidStartDate($scope.currentProposal.PolicyStartDate, formElement);
                },

                isEndDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidEndDate($scope.currentProposal.PolicyEndDate, $scope.currentProposal.PolicyStartDate, formElement);
                },

                validateForms: function () {
                    return $scope.frmAboutMainDriver.$invalid || $scope.frmAboutCar.$invalid || $scope.frmAboutMainDriver2.$invalid;
                },

                hasInvalid: function hasInvalid() {
                    var isInvalid = false;
                    /*honey pot attack*/
                    if ($scope.securityQuestion && $scope.securityQuestion.length > 0) {
                        DaModal.alert('got you');
                        return true;
                    }
                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    fn.validations.checkDOBEmpty();

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };


                    if (!$scope.asset.consentPrivacyFlag) {
                        isInvalid = true;
                        //$('#consentpopup').popover('show');
                        $scope.policyHolder.ConsentId = 7000001;
                    } else {
                        //$('#consentpopup').popover('hide');
                        $scope.policyHolder.ConsentId = 7000000;
                    }

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;
                },

                checkDrivingExp: function () {
                    /*lessThanTwoDrivingExp*/
                    if ($scope.mainDriver.DrivingExperienceId) {
                        if ($scope.mainDriver.DrivingExperienceId == 1 || $scope.mainDriver.DrivingExperienceId == "1"
                            || $scope.mainDriver.DrivingExperienceId == 2 || $scope.mainDriver.DrivingExperienceId == "2") {
                            //Less than 2 driving experiencee.
                            $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', false);
                            return;
                        }
                    }
                    $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', true);
                },

            },

            eventHandlers: {

                onNCBApplyRequest: function (bool) {
                    $scope.applyNCB = bool;
                    if ($scope.applyNCB) {
                        $scope.asset.NcdLevelId = "";
                        $scope.asset.ReasonForNcdIsZero = "";
                        $scope.asset.NcdLevelIdOnOtherCar = "";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    } else {
                        $scope.asset.NcdLevelId = "1000003";
                        $scope.asset.ReasonForNcdIsZero = "1000004";
                        $scope.asset.NcdLevelIdOnOtherCar = "0";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    }
                },

                onPolicyStartDateChanged: function () {
                    fn.utils.autoSetEndDate();
                },

                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },

                onBtnCalculatePremiumClicked: function () {
                    try {
                        $scope.$parent.gaEvents(0, 0);
                    }
                    catch (e) { }



                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var carAge = chkDate.getFullYear() - $scope.asset.PurchasingYear;
                    var ncdLevel = 0;
                    if (carAge == 0) {  // 0% For vehicle age 0 year
                        ncdLevel = 0;
                        $scope.asset.NcdLevelId = "1000003";
                    } else if (carAge == 1) { // 20% For vehicle age 1 year
                        ncdLevel = 20;
                        $scope.asset.NcdLevelId = "1000005";
                    } else if (carAge > 1) {  // 30% Vehicle age > 1 year
                        ncdLevel = 30;
                        $scope.asset.NcdLevelId = "1000006";
                    } else {
                        ncdLevel = 0;
                        $scope.asset.NcdLevelId = "1000003";
                    }
                    $scope.carAge = carAge;

                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(1);

                    $scope.pageSubmitted = true;
                    fn.setSourceChannels();

                    if (fn.validations.hasInvalid()) {
                        DaModal.open("#formInvalid");
                        return;
                    }

                    if ($scope.asset.UsageId === "0") {
                        DaModal.open("#usedForCommercial");
                        return;
                    }

                    fn.utils.setIsSaving(true);

                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.saveCurrentProposalChildModels();
                        },
                        onError: function () {
                            fn.eventHandlers.onDefaultErrorHandler();
                        }
                    };

                    $scope.asset.PastInsuranceContactExtNum = "90000";
                    $scope.asset.PrevNcdLevelId = $scope.asset.NcdLevelId;

                    // New Maxarpriority and leadtype
                    $scope.asset.MaxarPriority = "";
                    $scope.asset.LeadTypeId = 7000004;   // 7000010

                    fn.eventHandlers.setUsageId();

                    // new lead capturing method
                    fn.populateMaxar();
                    fn.postToMaxParent('No', 22, 'N');

                    // new propensity priority method
                    console.log();

                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                    $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                    // Project Iris
                    $scope.currentProposal.GAClientID = $scope.$parent.GAClientID;
                    fn.currentProposalApi[saveCurrentProposalFunc](callbacks);
                },

                onDropMakeChanged: function (manufacturerId) {

                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleModel option:first").text("กำลังโหลด...");
                        $scope.staticData.models = [{ "key": 0, "value": "กำลังโหลด..." }];
                    } else {
                        $("#vehicleModel option:first").text("Loading...");
                        $scope.staticData.models = [{ "key": 0, "value": "Loading..." }];
                    }

                    if (!manufacturerId) {
                        if ($scope.staticData.models) {
                            $scope.staticData.models.splice(0);
                        }
                        return;
                    }
                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    //wfeApiStaticDataModel.getModelListWithYear({
                    wfeApiStaticDataModel.getModelListV2({
                        onSuccess: function (data) {
                            filteredModel = $filter('filter')(data, function (r) {
                                var notAcceptModel = ["90000148",
                                    "90000784",
                                    "90000546",
                                    "90000214",
                                    "90000810",
                                    "90000406",
                                    "90000402",
                                    "90000456",
                                    "90000747",
                                    "90000043",
                                    "90000667",
                                    "90000737",
                                    "90000185",
                                    "90000186",
                                    "90000189",
                                    "90000194",
                                    "90000720",
                                    "90000203",
                                    "90000772",
                                    "90000773",
                                    "90000229",
                                    "90000230",
                                    "90000231",
                                    "90000598",
                                    "90000676",
                                    "90000232",
                                    "90000234",
                                    "90000235",
                                    "90000236",
                                    "90000237",
                                    "90000238",
                                    "90000723",
                                    "90000809",
                                    "90000242",
                                    "90000270",
                                    "90000274",
                                    "90000278",
                                    "90000280",
                                    "90000279",
                                    "90000282",
                                    "90000283",
                                    "90000286",
                                    "90000287",
                                    "90000288",
                                    "90000289",
                                    "90000790",
                                    "90000778",
                                    "90000801",
                                    "90000802",
                                    "90000781",
                                    "90000794",
                                    "90000465",
                                    "90000812",
                                    "90000469",
                                    "90000490",
                                    "90000491",
                                    "90000492",
                                    "90000762",
                                    "90000493",
                                    "90000494",
                                    "90000495",
                                    "90000496",
                                    "90000785",
                                    "90000786",
                                    "90000497",
                                    "90000498",
                                    "90000499",
                                    "90000500",
                                    "90000501",
                                    "90000502",
                                    "90000503",
                                    "90000504",
                                    "90000505",
                                    "90000042",
                                    "90000165",
                                    "90000243",
                                    "90000029",
                                    "90000046",
                                    "90000066",
                                    "90000067",
                                    "90000807",
                                    "90000031",
                                    "90000174",
                                    "90000182",
                                    "90000303",
                                    "90000353",
                                    "90000438",
                                    "90000440",
                                    "90000527",
                                    "90000162",
                                    "90000217",
                                    "90000296",
                                    "90000529",
                                    "90000122",
                                    "90000010",
                                    "90000249",
                                    "90000026",
                                    "90000028",
                                    "90000034",
                                    "90000035",
                                    "90000037",
                                    "90000038",
                                    "90000747",
                                    "90000039",
                                    "90000044",
                                    "90000045",
                                    "90000068",
                                    "90000080"];
                                if (!notAcceptModel.includes(r.key)) {
                                    //console.log(r.key);
                                    return r.key;
                                }
                            });
                            $scope.staticData.models = filteredModel;
                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            } else {
                                $("#vehicleModel option:first").text("Please select...");
                            }
                            if (data.length == 0) {
                                marketing.dLCE(47, 'Yes');
                                DaModal.open("#makeAndModelNotAvailable");
                            }

                        },
                        onError: function () {
                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            } else {
                                $("#vehicleModel option:first").text("Please select...");
                            }
                        }
                    });

                },

                onDropModelChanged: function (manufacturerId, modelId) {

                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleFirstReg option:first").text("กำลังโหลด...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "กำลังโหลด..." }];
                    } else {
                        $("#vehicleFirstReg option:first").text("Loading...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "Loading..." }];
                    }
                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    objParams.modelId = modelId;

                    if (typeof modelId !== "undefined") {
                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                        wfeApiStaticDataModel.getYear({
                            onSuccess: function (data) {
                                $scope.staticData.yearOfRegistrations = data;
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกปีที่ผลิต");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }

                            },
                            onError: function () {
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกปีที่ผลิต");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }
                            }
                        });
                    }
                },

                onDropPurchasingYearChanged: function (modelId, purchasingYear) {
                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleDesc option:first").text("กำลังโหลด...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "กำลังโหลด..." }];
                        $scope.staticData.engineSizeLoaded = false
                    } else {
                        $("#vehicleDesc option:first").text("Loading...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "Loading..." }];
                        $scope.staticData.engineSizeLoaded = false
                    }
                    var objParams = {};
                    objParams.modelId = modelId;
                    objParams.purchasingYear = purchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getCarDescWithYear({
                        onSuccess: function (data) {
                            $scope.staticData.cardescriptions = data;
                            $scope.staticData.engineSizeLoaded = true
                            var acceptTypeOnly = $filter('filter')(data, { tariffType: 110 });  // accept tariff type 110 only
                            $scope.asset.selectedEngineSize = null;
                            $scope.staticData.arrangedcardescriptions = fn.eventHandlers.reduceDesc(acceptTypeOnly); // Add filter for fleet car type
                            if ($scope.staticData.arrangedcardescriptions.length === 0) {  // If car type filter and got zero, return not available message.
                                marketing.dLCE(47, 'Yes');
                                DaModal.open("#makeAndModelNotAvailable");
                            }

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            } else {
                                $("#vehicleDesc option:first").text("Please select...");
                            }

                        },
                        onError: function () {
                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            } else {
                                $("#vehicleDesc option:first").text("Please select...");
                            }
                        }
                    });

                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var NCDLVLID = "1000003";
                    var carAge = chkDate.getFullYear() - purchasingYear;
                    var ncdLevel = 0;
                    if (carAge == 0) {  // 0% For vehicle age 0 year
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    } else if (carAge == 1) { // 20% For vehicle age 1 year
                        ncdLevel = 20;
                        NCDLVLID = "1000005";
                    } else if (carAge > 1) {  // 30% Vehicle age > 1 year
                        ncdLevel = 30;
                        NCDLVLID = "1000006";
                    } else {
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    }
                    // Fire GA here
                    marketing.dLCE(1, purchasingYear);
                    marketing.dLCE(13, NCDLVLID);
                },

                onCarDescriptionChanged: function (descKey) {

                    var carTarrif = $filter('filter')($scope.staticData.cardescriptions, {
                        key: descKey
                    })[0];

                    if (carTarrif.tariffType) {
                        if (carTarrif.tariffType === '320' || carTarrif.tariffType == 320) {
                            // Do not respect IsDriveWork/IsUseWork/Usage is fixed.
                            $scope.asset.TariffType = 320;

                            // Block for tarifftype 320 : 2 door pick up
                            marketing.dLCE(47, 'Yes');
                            DaModal.open("#makeAndModelNotAvailable");
                            $scope.txtDesc = fn.getTranslateText('desc');
                            $scope.asset.CarDescriptionId = null;

                        }
                        else {
                            $scope.asset.TariffType = 110;

                        }
                    }
                    return;
                },

                onBtnNCDClicked: function () {
                    DaModal.open('#checkNCD');
                },

                onBtnNCDSelected: function () {
                    DaModal.close('#checkNCD');
                },

                reduceDesc: function (descData) {
                    if (descData) {
                        var p;
                        var optArr = new Array;
                        var headArr = new Array;

                        for (var key in descData) {
                            if (!isNaN(descData[key].key)) {
                                var optSel = new Object();
                                p = descData[key].description.split(" ");
                                HGroup = p[0] + " " + p[1] + " ";
                                descTxt = descData[key].description.replace(HGroup, "").replace("?", " ");
                                descKey = descData[key].key;
                                //console.log(descKey);
                                optSel.key = descKey;
                                optSel.txt = descTxt;
                                optSel.hgroup = HGroup;
                                optSel.oriDesc = descData[key].description;
                                optArr.push(optSel);
                                if (headArr.indexOf(HGroup) == -1) {
                                    headArr.push(HGroup);
                                }
                            }

                        }
                        var z = Array();
                        for (var i in headArr) {
                            var x = new Array;
                            var descObj = new Object();
                            var dup = false;
                            for (var j in optArr) {
                                if (headArr[i] == optArr[j].hgroup) {
                                    for (var y in x) {
                                        if (x[y].key == optArr[j].key) {
                                            dup = true;
                                        } else {
                                            dup = false;
                                        }
                                    }
                                    if (!dup) {
                                        x.push(optArr[j]);
                                    }
                                }
                            }
                            descObj.HGroup = headArr[i];
                            descObj.Data = x;
                            descObj.defaultKey = x[0].key;
                            z.push(descObj);
                        }
                        return z;
                    }
                },
                setMaker: function (e) {
                    $scope.txtMaker = e.target.textContent;
                    $scope.asset.ManufactureId = e.target.dataset.key;
                    fn.eventHandlers.showModel(e);
                    $scope.txtModel = fn.getTranslateText('model');
                    $scope.txtYear = fn.getTranslateText('year');
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setModel: function (e) {
                    $scope.txtModel = e.target.textContent;
                    $scope.asset.ModelId = e.target.dataset.key;
                    fn.eventHandlers.showYear(e);
                    $scope.txtYear = fn.getTranslateText('year');
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setYear: function (e) {
                    $scope.txtYear = e.target.textContent;
                    $scope.asset.PurchasingYear = e.target.dataset.key;
                    fn.eventHandlers.showDesc(e);
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setDesc: function (e) {
                    $scope.txtDesc = e.target.parentNode.dataset.head + " " + e.target.textContent;
                    $scope.asset.CarDescriptionId = e.target.dataset.key;
                    /* $scope.txtDesc = e.target.textContent;
                    $scope.asset.CarDescriptionId = e.target.dataset.key; */
                },
                showModel: function (e) {
                    e.stopPropagation();
                    $("#vehicleMake3").removeClass('open');
                    $("#vehicleModel3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowModel").html(divArrow);
                },
                showYear: function (e) {
                    e.stopPropagation();
                    $("#vehicleModel3").removeClass('open');
                    $("#vehicleFirstReg3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowYear").html(divArrow);
                },
                showDesc: function (e) {
                    e.stopPropagation();
                    $("#vehicleFirstReg3").removeClass('open');
                    $("#vehiceDesc3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowDesc").html(divArrow);
                },
                showDescAlt: function (e) {
                    if (e) {
                        e.stopPropagation();
                    }

                    $("#vehiceDesc4").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowDesc").html(divArrow);
                },
                getProgress: function () {
                    if ($scope.pmake !== 0 && $scope.pmodel !== 0 && $scope.pyear !== 0 && $scope.pcamera !== 0 && $scope.pdesc !== 0) {
                        $scope.aboutCarCompleted = true;
                    } else {
                        $scope.aboutCarCompleted = false;
                    }

                    if ($scope.asset.TariffType === 110) {
                        if ($scope.pmddob !== 0 && $scope.pgender !== 0 && $scope.pmarital !== 0 && $scope.pindrivework !== 0) {
                            $scope.aboutMainDriverCompleted = true;
                        } else {
                            $scope.aboutMainDriverCompleted = false;
                        }
                    } else {
                        if ($scope.pmddob !== 0 && $scope.pgender !== 0 && $scope.pmarital !== 0) {
                            $scope.aboutMainDriverCompleted = true;
                        } else {
                            $scope.aboutMainDriverCompleted = false;
                        }
                    }


                    if ($scope.pfirstname !== 0 && $scope.pmobileno !== 0 && $scope.pemail !== 0) {
                        $scope.contactInfoCompleted = true;
                    } else {
                        $scope.contactInfoCompleted = false;
                    }


                    return $scope.pmake + $scope.pmodel + $scope.pyear + $scope.pdesc + $scope.pcamera + $scope.pmddob + $scope.pgender + $scope.pmarital + $scope.pindrivework + $scope.pfirstname + $scope.pmobileno + $scope.pemail;
                },
                getStepProgress: function () {
                    if ($scope.asset.TariffType === 110) {
                        var noOfInput = 12;
                    } else {
                        var noOfInput = 11;
                    }

                    return 100 / noOfInput;
                },
                isCommercialUsage: function () {
                    if ($scope.asset.UsageId === "0") {
                        DaModal.open("#usedForCommercial");
                        return true;
                    }
                    return false;
                },
                setEngineSize: function (hGroup, e) {
                    $scope.asset.selectedEngineSize = hGroup;
                    $scope.staticData.arrangedcardescriptionsbyengine = $filter('filter')($scope.staticData.arrangedcardescriptions, { HGroup: hGroup });
                    fn.eventHandlers.showDescAlt(e);

                },
                resetDescription: function () {
                    $scope.asset.CarDescriptionId = null;
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setUsageId: function () {

                    if ($scope.asset.UsageId === "6") {
                        $scope.asset.InDriveWork = false;
                        $scope.asset.InCourseWork = false;
                        $scope.txtInCourseWork = 1;
                    } else if ($scope.asset.UsageId === "1000000") {
                        $scope.asset.InDriveWork = true;
                        $scope.asset.InCourseWork = false;
                        $scope.txtInCourseWork = 0
                    } else if ($scope.asset.UsageId === "1000002") {
                        $scope.asset.InDriveWork = true;
                        $scope.asset.InCourseWork = true;
                        $scope.txtInCourseWork = 2
                    } else {
                        $scope.asset.InDriveWork = undefined;
                        $scope.asset.InCourseWork = undefined;
                        $scope.txtInCourseWork = null;
                    }

                }

            },
            loadWidgetValue: function () {
                if ($sessionStorage.widgetItems) {
                    var maker = StaticDataKeyValuePair.getMake({ 'productId': AppConstants.AGGConfig.PRODUCT_ID_DA });
                    var models = StaticDataKeyValuePair.getModelV2({ 'ManufacturerId': $sessionStorage.widgetItems.make });
                    var years = StaticDataKeyValuePair.getYear({ 'ManufacturerId': $sessionStorage.widgetItems.make, 'Model': $sessionStorage.widgetItems.model })
                    var desc = StaticDataKeyValuePair.getCarDescWithYear({ 'ModelId': $sessionStorage.widgetItems.model, 'y': $sessionStorage.widgetItems.registrationyear });
                    if ($sessionStorage.widgetItems.make) {
                        $scope.asset.ManufactureId = $sessionStorage.widgetItems.make.toString();
                        fn.eventHandlers.onDropMakeChanged($scope.asset.ManufactureId);
                        maker.$promise.then(function () {
                            $scope.txtMaker = $filter('filter')(maker, { key: $scope.asset.ManufactureId })[0].value;
                            marketing.dLCE(2, $scope.txtMaker);
                        })
                    }

                    if ($sessionStorage.widgetItems.model) {
                        $scope.asset.ModelId = $sessionStorage.widgetItems.model.toString();
                        fn.eventHandlers.onDropModelChanged($scope.asset.ManufactureId, $scope.asset.ModelId);
                        models.$promise.then(function () {
                            $scope.txtModel = $filter('filter')(models, { key: $scope.asset.ModelId })[0].value;
                            marketing.dLCE(3, $scope.txtModel);
                        })
                    }

                    if ($sessionStorage.widgetItems.registrationyear) {
                        $scope.asset.PurchasingYear = $sessionStorage.widgetItems.registrationyear.toString();
                        fn.eventHandlers.onDropPurchasingYearChanged($scope.asset.ModelId, $scope.asset.PurchasingYear);
                        $scope.txtYear = $scope.asset.PurchasingYear;
                        marketing.dLCE(1, $scope.asset.PurchasingYear);
                    }

                    if ($sessionStorage.widgetItems.desc) {
                        $scope.asset.CarDescriptionId = $sessionStorage.widgetItems.desc.toString();
                        desc.$promise.then(function () {

                            $scope.txtDesc = $filter('filter')(desc, { key: $scope.asset.CarDescriptionId })[0].description;
                            console.log($scope.txtDesc);
                            marketing.dLCE(4, $scope.txtDesc);
                        })
                    }

                    if ($sessionStorage.widgetItems.cameraq) {
                        var cameraQ = true;
                        if ($sessionStorage.widgetItems.cameraq === "false") {
                            cameraQ = false;
                        }

                        $scope.asset.IsCarCameraInstalled = cameraQ;
                        marketing.dLCE(46, $scope.asset.IsCarCameraInstalled);
                    }
                }
            },
            checkFromWidget: function () {
                if ($sessionStorage.widgetItems) {
                    $scope.fromWidgetMsg = true;
                    $('html, body').animate({ scrollTop: $("#body_title_driver_main").offset().top - 90 }, 'slow');
                    delete $sessionStorage.widgetItems;
                }
            },

            checkPageSize: function () {
                if ($('html, body').width() <= 991) {
                    return "mobile";
                } else {
                    return "web";
                }
            },
            getTranslateText: function (part) {
                if (HKLocale.getLocale() == 'th-th') {
                    switch (part) {
                        case "maker":
                            return "เลือกยี่ห้อรถ";
                            break;
                        case "model":
                            return "เลือกรุ่นรถ";
                            break;
                        case "year":
                            return "เลือกรุ่นปีรถยนต์";
                            break;
                        case "desc":
                            return "เลือกรุ่นย่อย";
                            break;
                    }

                } else {
                    switch (part) {
                        case "maker":
                            return "Please select";
                            break;
                        case "model":
                            return "Please select";
                            break;
                        case "year":
                            return "Please select";
                            break;
                        case "desc":
                            return "Please select";
                            break;
                    }
                }
            },
            assetStaticData: {
                make: [],
                model: [],
                year: [],
                description: []
            },
            loadDict: function () {
                console.log("load dict file");
                var dataFile = '/testDict.json';
                $.getJSON(dataFile, function (d) {
                    console.log("dict file loaded");
                    $scope.jsonData = d.data;
                });
            },
            userExpand: function (val) {
                if (val === 'aboutCar') {
                    if ($scope.aboutCarCompleted) {
                        $scope.UserExpandAboutCar = true;
                    } else {
                        $scope.UserExpandAboutCar = false;
                    }

                } else if (val === 'mainDriver') {
                    if ($scope.aboutMainDriverCompleted) {
                        $scope.UserExpandAboutMainDriver = true;
                    } else {
                        $scope.UserExpandAboutMainDriver = false;
                    }
                }
            },
            getMonthTxt: function (val) {
                if ($scope.HKLocale.getLocale() === "th-th") {
                    switch (val) {
                        case 1:
                            return "มกราคม"
                            break;
                        case 2:
                            return "กุมภาพันธ์"
                            break;
                        case 3:
                            return "มีนาคม"
                            break;
                        case 4:
                            return "เมษายน"
                            break;
                        case 5:
                            return "พฤษภาคม"
                            break;
                        case 6:
                            return "มิถุนายน"
                            break;
                        case 7:
                            return "กรกฎาคม"
                            break;
                        case 8:
                            return "สิงหาคม"
                            break;
                        case 9:
                            return "กันยายน"
                            break;
                        case 10:
                            return "ตุลาคม"
                            break;
                        case 11:
                            return "พฤศจิกายน"
                            break;
                        case 12:
                            return "ธันวาคม"
                            break;
                    }
                } else {
                    switch (val) {
                        case 1:
                            return "January"
                            break;
                        case 2:
                            return "February"
                            break;
                        case 3:
                            return "March"
                            break;
                        case 4:
                            return "April"
                            break;
                        case 5:
                            return "May"
                            break;
                        case 6:
                            return "June"
                            break;
                        case 7:
                            return "July"
                            break;
                        case 8:
                            return "August"
                            break;
                        case 9:
                            return "September"
                            break;
                        case 10:
                            return "October"
                            break;
                        case 11:
                            return "November"
                            break;
                        case 12:
                            return "December"
                            break;
                    }
                }
            },
            setBodyType: function (val) {
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.bodyType = val;
                $scope.gearType = '';
                if ($scope.bodyType === '2doors') {
                    DaModal.open("#makeAndModelNotAvailable");
                }
            },
            setGearType: function (val) {
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.gearType = val;
            },
            isPopularTruck: function () {
                var popularTruckModel = ['D-Max', 'Hilux Revo', 'Hilux Vigo', 'Triton'];
                if (popularTruckModel.indexOf($scope.txtModel) < 0) {
                    return false;
                } else {
                    return true;
                }
            },
            getPriority: function (callback) {
                $window.ppstLayer = $window.ppstLayer || [];
                var ppstLayer = $window.ppstLayer;

                if ($scope.mainDriver.GenderId !== undefined) {
                    var gender = $scope.mainDriver.GenderId == 1 ? "FEMALE" : "MALE";
                } else {
                    var gender = $scope.policyHolder.GenderId == 1 ? "FEMALE" : "MALE";
                }

                if ($scope.mainDriver.MaritalStatusId !== undefined) {
                    var marital = $scope.mainDriver.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                } else {
                    var marital = $scope.policyHolder.MaritalStatusId == 1 ? "SINGLE" : "MARRIED";
                }

                if ($scope.asset.UsageId == 6) {
                    var carUsage = "PRIVATE_USE";
                } else if ($scope.asset.UsageId == 1000000) {
                    var carUsage = "PRIVATE_USE_AND_COMMUTING_TO_WORK";
                } else if ($scope.asset.UsageId == 1000002) {
                    var carUsage = "PRIVATE_AND_BUSINESS_USE";
                } else {
                    var carUsage = "COMMERCIAL_SPECIAL";
                }
                var selectedCoverage = fn.utils.getSelectedCoverage();
                var compulsory = $filter('filter')(selectedCoverage.Covers, { 'ProductLineGroupId': 2000161 })[0];
                if (compulsory.IsSelected) {
                    var cc = compulsory.TotalPremiumToColl;
                } else {
                    var cc = 0;
                }
                var premium = selectedCoverage.CalculatedPremium + cc;
                $scope.totalPremium = premium;
                ppstLayer.push({
                    "Proposal": $scope.partialPolicyNumber,
                    "PhoneNumber": $scope.phContact.PrimaryPhoneNumber,
                    "DateOfBirth": $scope.mainDriver.DateOfBirth,
                    "Gender": gender,
                    "MaritalStatus": marital,
                    "VehicleUsage": carUsage,
                    "MakeID": $scope.asset.ManufactureId,
                    "ModelID": $scope.asset.ModelId,
                    "YearOfRegistration": $scope.asset.PurchasingYear,
                    "CarDescriptionID": $scope.asset.CarDescriptionId,
                    "Premium": premium,
                    "NumberOfDataChanges": $sessionStorage.noOfPTSave,
                    "PORTAL_TYPE": "OnlineSale"
                });

                $localStorage.ppstLayer = ppstLayer;

                $scope.$parent.getPriority(function (res) {
                    if (res) {
                        callback(res);
                    } else {
                        callback(0);
                    }

                });
            }
        };

        /** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initSubscribeWatcher();
        fn.initStaticData();
        fn.retrieveScopeObjects();
        fn.loadWidgetValue();

        /** Event Handlers */
        $scope.onBtnCalculatePremiumClicked = fn.eventHandlers.onBtnCalculatePremiumClicked;
        $scope.onDropMakeChanged = fn.eventHandlers.onDropMakeChanged;
        $scope.onDropModelChanged = fn.eventHandlers.onDropModelChanged;
        $scope.onDropPurchasingYearChanged = fn.eventHandlers.onDropPurchasingYearChanged;

        $scope.onPolicyStartDateChanged = fn.eventHandlers.onPolicyStartDateChanged;
        $scope.onCarDescriptionChanged = fn.eventHandlers.onCarDescriptionChanged;
        $scope.onBtnNCDClicked = fn.eventHandlers.onBtnNCDClicked;
        $scope.onBtnNCDSelected = fn.eventHandlers.onBtnNCDSelected;
        $scope.onNCBApplyRequest = fn.eventHandlers.onNCBApplyRequest;
        $scope.checkPageSize = fn.checkPageSize;
        $scope.gaEvents = $scope.$parent.gaEvents;
        $scope.int = parseInt;
        $scope.preventClick = fn.preventClick;
        $scope.setUsageId = fn.eventHandlers.setUsageId;

        /** Validations */
        $scope.validations = {
            checkDOBEmpty: fn.validations.checkDOBEmpty,
            isStartDateInvalid: fn.validations.isStartDateInvalid,
            isEndDateInvalid: fn.validations.isEndDateInvalid,
            isValidDriverAge: $scope.$parent.isInvalidDriverAge
        };

        $scope.checkDrivingExp = fn.validations.checkDrivingExp;
        $scope.checkNCDLevel = fn.validations.checkNCDLevel;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;

        $scope.convertMITDate = fn.utils.convertMITDate;
        $scope.checkMake = fn.validations.checkMake;

        $scope.setMaker = fn.eventHandlers.setMaker;
        $scope.setModel = fn.eventHandlers.setModel;
        $scope.setYear = fn.eventHandlers.setYear;
        $scope.setDesc = fn.eventHandlers.setDesc;
        $scope.showModel = fn.eventHandlers.showModel;
        $scope.showYear = fn.eventHandlers.showYear;
        $scope.showDesc = fn.eventHandlers.showDesc;
        $scope.getProgress = fn.eventHandlers.getProgress;
        $scope.isCommercialUsage = fn.eventHandlers.isCommercialUsage;
        $scope.userExpand = fn.userExpand;
        $scope.getMonthTxt = fn.getMonthTxt;
        $scope.dLCE = marketing.dLCE;
        $scope.gtAnchor = fn.gtAnchor;
        $scope.setBodyType = fn.setBodyType;
        $scope.setGearType = fn.setGearType;
        $scope.isPopularTruck = fn.isPopularTruck;
        $scope.setEngineSize = fn.eventHandlers.setEngineSize;
        $scope.resetDescription = fn.eventHandlers.resetDescription;

        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function () {
            $timeout(function () {
                dataLayer.push({ 'event': 'optimize.activate' });

            }, 0);
            $timeout(function () {
                $scope.$parent.changeGlobalContactNo();
            }, 1000);
            $timeout(function () {
                // Post
                fn.checkFromWidget();

            }, 3500);
        });
    }];