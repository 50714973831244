/***
 * The common THID validation directive.
 * @type {*[]}
 */
module.exports = [
    'WfeApiStaticData',
    function daThProvinceChange(WfeApiStaticData) {

        return {
            require: 'ngModel',

            link: function(scope, elm, attrs, ctrl) {

                ctrl.$parsers.unshift(function(viewValue) {

                    if (ctrl.$isEmpty(viewValue)) {
                        ctrl.$setValidity('da-th-province-change', true);
                        return undefined;
                    }

                    var isValid = true;
                    var wfeApiStaticData = WfeApiStaticData.instance(scope, scope.HKLocale.getLocale(), {"postalCode":scope.phAddress.PostalCode, "provinceId": viewValue});
                    scope.phAddress.DistrictId = '';

                    wfeApiStaticData.getDistrictListByPostal({
                        onSuccess: function (data) {
                            // console.log(data);
                            scope.staticData.districtByPostal = data;
                            if(data.length == 1){
                                scope.phAddress.DistrictId = scope.staticData.districtByPostal[0]['key'];
                                
                                scope.txtPhAddressDistrictId = scope.staticData.districtByPostal[0]['value'];
                                scope.frmYourAdress.district = scope.phAddress.DistrictId;

                            }
                        }
                    });


                    ctrl.$setValidity('da-th-province-change', isValid);

                    if (isValid) {
                        return viewValue;
                    } else {
                        return undefined;
                    }
                });
            }
        };
    }];