/**
 * Created by pathai.s on 2/22/2017.
 */

/***
 * The common THID validation directive.
 * @type {*[]}
 */
module.exports = [
    function daThPassport() {

        return {
            require: 'ngModel',

            link: function(scope, elm, attrs, ctrl) {

                ctrl.$parsers.unshift(function(viewValue) {

                    if (ctrl.$isEmpty(viewValue)) {
                        ctrl.$setValidity('da-th-passport', true);
                        return undefined;
                    }

                    var isValid = true;

                    if(viewValue.length < 8){isValid = false;}

                    ctrl.$setValidity('da-th-passport', isValid);

                    if (isValid) {
                        return viewValue;
                    } else {
                        return undefined;
                    }
                });
            }
        };
    }];