/*
 * Created by Gerald Garcia on 19/06/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$anchorScroll',
    'DaModal',
    'StaticDataKeyValuePair',
    'WfeApiCurrentProposal',
    'WfeApiStaticData',
    'WfeApiPayment',
    'WfeApiConfirmation',
    'WfeApiPolicyContact',
    '$window',
    'HKFormValidation',
    'WfeApiContact',
    'WfeApiCoverages',
    '$filter',
    'WfeApiAsset',
    'WfeApiEssential',
    

    /**
     * Controller for the Buy of Quote
     * @constructor
     */
    function EasyRenewController($scope, $state, $anchorScroll, DaModal, StaticDataKeyValuePair, 
                                        WfeApiCurrentProposal,  WfeApiStaticData, 
                                        WfeApiPayment, WfeApiConfirmation, WfeApiPolicyContact,
                                        $window, HKFormValidation,
                                        WfeApiContact, WfeApiCoverages, $filter, WfeApiAsset, WfeApiEssential) {

        $anchorScroll();

        var marketing = {


        dLCE:function(ref,v)
        {
            $window.dataLayer = $window.dataLayer || [];
            var dataLayer = $window.dataLayer;
            switch(ref)
            {
                case 39:
                    if(v==1000001 || v== '1000001' ) {s='Single Payment'};
                    if(v==1000005 || v== '1000005' ) {s='Monthly Payment'};
                    dataLayer.push({
                    'event':'dataLayer-initialised',
                    'how-do-you-want-to-pay':s
                });
                    break;
                case 40:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'payment-method':v
                });
                    break;
                case 41:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'issuing-bank':v
                });
                    break;
                case 42:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'card-type':v
                });
                    break;
                case 43:
                    if(v==0 || v== '0' || v==false) {s='No'};
                    if(v==1 || v== '1' ||v ==true) {s='Yes'};
                    dataLayer.push({
                    'event':'dataLayer-initialised',
                    'would-you-like-to-choose-easy-renew':s
                });
                    break;
                case 44:
                    dataLayer.push({
                        'event':'dataLayer-initialised',
                        'callback-Priority':v
                    });
                    break;
                case 45:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'credit-card-type':v
                });
                    break;
                default: break;
            }
        }
        };

        var fn = {
            postToMaxParent:function(callBackStatus,Priority,FinalCall){

                var monthlySinglePayment=($scope.currentProposal.PaymentTermId==1000005 || $scope.currentProposal.PaymentTermId=="1000005")?'Monthly':'Single Payment';
                var creditCardCounterService=($scope.currentProposal.PaymentModeId==4 || $scope.currentProposal.PaymentModeId=="4")?'Credit Card':'Counter Payment';
                var creditCardType=$scope.creditCard.CreditCardTypeString;
                var easyRenew=$scope.$parent.filterStatic($scope.staticData.booleans,$scope.currentProposal.AutoRenewalFlag);
                var sale=undefined;

                $window.maxarLayer = $window.maxarLayer || [];
                var maxarLayer = $window.maxarLayer;
                maxarLayer.push( {
                    consent: $scope.essential.consentPrivacyFlag,
                    totalPremium:$scope.currentProposal.TotalPremium,
                    monthlySinglePayment:monthlySinglePayment,
                    creditCardCounterService:creditCardCounterService,
                    creditCardType:creditCardType,
                    easyRenew:easyRenew,
                    sale:sale,
                    message:$scope.callbackObj.message,
                    commercial:$scope.callbackObj.commercial,
                    type:'Quote lead',
                    sourcePage:'Buy',
                    callback:callBackStatus,
                    priority:Priority,
                    dataFinal:FinalCall,
                    referenceID:$scope.phContact.PrimaryPhoneNumber,
                    telephone:$scope.phContact.PrimaryPhoneNumber,

                });

                //console.log(maxarLayer);

                $scope.$parent.postMaxar();
            },

            gtAnchor:function(x){
                if (!x)
                {return;}

                //alert(x);
                HKFormValidation.animateToID(x);
            },


            goToNextStep: function () {
                $state.go('public.' + $scope.product.ProductCode + '.quote.steps.done');
            },

            goToPrevStep: function () {
                fn.utils.setIsSaving(false);
                $state.go('public.' + $scope.product.ProductCode + '.quote.steps.confirm');
            },
            goToCounterConfirmStep: function () {
                $state.go('public.' + $scope.product.ProductCode + '.quote.steps.counterdone');
            },

            ShowPaymentErrorMsg: function () {
               /* var asiapayResponseId;
                var statusFlag = true;

                if ($scope.$parent.asiapay.prc === undefined && $scope.$parent.asiapay.src === undefined) {
                    statusFlag = false;
                }
                if (statusFlag) {
                    asiapayResponseId = $scope.$parent.asiapay.prc + $scope.$parent.asiapay.src;
                    $scope.asiapayResponseId = asiapayResponseId;
                    DaModal.open('#asiapayResponse');
                }
                */

                var validationNumberString = (data.ErrorInfo.ValidationNumber || '') + '';
                $scope.cyberResponseId = validationNumberString;

                DaModal.open('#cyberSourceResponse');

            },


            initScopeObjects: function () {
                $scope.isBtnSaveHide = true;
                $scope.currentProposal = {};
                $scope.creditCard = {};
                $scope.isBtnBackHide = true;  // Hide back button
                $scope.buyControllerStaticData = {
                    allPaymentTerms: [],
                    products: [],
                    localePaymentTerms:[]
                };
                $scope.phContact = {};
                $scope.currentProposal.agreeInstallments=false;
                $scope.policyHolder={};
                $scope.coverages = {};
                $scope.selectedCoverage = {};
                $scope.changeNextCopy=true;
                $scope.proceedBuy=true;
                $scope.staticData={};
                $scope.callbackObj={};
                $scope.showSaveAndExitOnBuyPage=false;
                $scope.asset = {};
                $scope.QRisValid = true;
                $scope.essential = {};
                //$scope.essential.ConsentId = 7000000;  // default consent to yes;
                $scope.essential.consentPrivacyFlag = false;
                $scope.showConfirmBuyPageB2C=false;
                $scope.showConfirmBuyPageOnlineSelling=true;
            },

            initApiObjects: function () {
                
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                $scope.buyControllerStaticData.products = StaticDataKeyValuePair.getProducts();
                $scope.buyControllerStaticData.allPaymentTerms = StaticDataKeyValuePair.getAllPaymentTerms();
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataPaymentPlan = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), 4);
                fn.paymentApi = WfeApiPayment.instance($scope.$parent.sessionApi, $scope, $scope.creditCard);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi,$scope, $scope.essential);

            },
            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;
                var wfeApiStaticDataPaymentPlan = fn.wfeApiStaticDataPaymentPlan;

                var startRetrieval = function () {
                    getBooleanList();
                };
                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getPaymentTermList();
                        },

                        onError: function () {
                            getPaymentTermList();
                        }
                    });
                };

                var getPaymentTermList = function () {
                    wfeApiStaticDataPaymentPlan.getPaymentTermList({
                        onSuccess: function (data) {
                            $scope.buyControllerStaticData.localePaymentTerms = data;
                            //console.log(data);
                            // getMakeList();
                        },

                        onError: function () {
                            console.log("Error");
                            // getMakeList();
                        }
                    });
                };
                // var getMakeList = function () {
                //     wfeApiStaticData.getMakeList({
                //         onSuccess: function (data) {
                //             $scope.staticData.makes = data;
                //             // console.log(`getMakeList : ${JSON.stringify(data)}`);
                //         },

                //         onError: function () {
                //             console.log("Error");
                //         }
                //     });
                // };
    
                startRetrieval();

            },

            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrieveCoverages();
                    
                   // retrieveProposal();
                };

                var retrieveCoverages = function () {
                    fn.coverageApi.retrieveCoverages(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.coverages, data);
                            //$scope.$parent.getMonthlyPriceStepCtrl($scope.coverages); //gets the price on floating.
                            if(data.Coverages.length === 0){
                                $scope.hasError = true;
                                $scope.QRisValid = false;
                            }else{
                                var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true})[0];

                                if (selectedCoverage) {
                                    angular.extend($scope.selectedCoverage, selectedCoverage);
                                }
    
                                retrieveProposal();
                            }
                            
                        },
                        onError: function () {
                            retrieveProposal();
                        }
                    }
                    );
                };

                var retrieveProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                $scope.currentProposal.isForUpdate = true;
                                $scope.isRenewable = $scope.$parent.product.isRenewable;
                                // $scope.currentProposal.AutoRenewalFlag = $scope.isRenewable;
                                                                
                                //$scope.currentProposal.PaymentTermId="1000001"; //single only
                                if($scope.currentProposal.PaymentModeId == 4){
                                    marketing.dLCE(42, 'Credit Card')
                                }else{
                                    marketing.dLCE(42, 'Debit Card')
                                }

                                if ($scope.currentProposal.PaymentModeId == '7000001' || $scope.currentProposal.PaymentModeId == 7000001) {
                                    if ($scope.currentProposal.PaymentTermId == 7000000 || $scope.currentProposal.PaymentTermId == '7000000') {
                                        $scope.currentProposal.PaymentModeId = 4;
                                        $scope.currentProposal.PaymentTermId = 1000001;
                                    }
                                    else {
                                        $scope.currentProposal.PaymentModeId = 4;
                                        $scope.currentProposal.PaymentTermId = 1000005;
                                    }
                                } else if ($scope.currentProposal.PaymentModeId != 4 && $scope.currentProposal.PaymentModeId != '4') {
                                    $scope.currentProposal.PaymentModeId = 4;
                                    $scope.currentProposal.PaymentTermId = 1000001;
                                }

                                retrievePolicyHolder();
                                if ($scope.currentProposal.Assets[0].ManufactureId) {
                                    retrieveModelStaticData();
                                }
        
                            },
                            onError: function () {

                            }
                        }
                    );
                };

                var updatedCurrentProposalPayment = function () {
                    fn.wfeApiStaticDataUpdatedPayment = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), $scope.currentProposal.PaymentModeId);
                    var wfeApiStaticDataUpdatedPayment = fn.wfeApiStaticDataUpdatedPayment;
                    wfeApiStaticDataUpdatedPayment.getPaymentTermList({
                        onSuccess: function (data) {

                            // console.log(data);
                        }
                    });
                };

                fn.phContactApi.retrievePHContact(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.phContact, data);
                        }
                    }
                );

                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.policyHolder, data);
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveModelStaticData = function () {

                    var objParams = {};
                    objParams.manufacturerId = $scope.currentProposal.Assets[0].ManufactureId;
                    objParams.purchasingYear = $scope.currentProposal.Assets[0].PurchasingYear;
    
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
    
                    
                    wfeApiStaticDataModel.getModelListWithYear({
                        onSuccess: function (data) {
                            // $scope.staticData.models = data;

                            getMakeAndModel(data);
                            // fn.retrieveDescStaticData();
                            if(data.length==0) {
                                DaModal.alert('The Make and Model you choose is not available. Call us at 02-767-7777 to talk with an agent.');
                            }
                        },
                        onError: function () {
                            // console.log("retrieveModelStaticData Error");
                            retrieveModelStaticData();
                        }
                    })
                };

                var getMakeAndModel = function(modelsData) {
                    fn.wfeApiStaticData.getMakeList({
                        onSuccess: function (makeData) {
                            // console.log(`retrieveModelStaticData makeData : ${JSON.stringify(makeData)}`);
                            var makeData = $filter('filter')(makeData, { key: $scope.currentProposal.Assets[0].ManufactureId });
                            // console.log(`retrieveModelStaticData make : ${JSON.stringify(makeData)}`);
                            if (makeData) {
                                // console.log(`retrieveModelStaticData make.value : ${make[0].value}`);
                                $scope.staticData.makeDisplay = makeData[0].value;
                            }
                            var modelData = $filter('filter')(modelsData, { key: $scope.currentProposal.Assets[0].ModelId });
                            // console.log(`retrieveModelStaticData model : ${JSON.stringify(modelData)}`);
                            if (modelData) {
                                // console.log(`retrieveModelStaticData model.value : ${model[0].value}`);
                                $scope.staticData.modelDisplay = modelData[0].value;
                            }


                        },

                        onError: function () {
                            console.log("Error");
                        }
                    });

                }
    
                var retrieveAsset = function() {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                //Set PlanId for parent
                                $scope.$parent.PlanId = $scope.asset.PlanId;
                            }
                        }
                    );
                };

                startRetrievalScopeObject();
            },

            issuePolicy: function (creditCard) {
                fn.utils.setIsSaving(false);

                if (!creditCard) {
                    console.error('No credit card object provided');
                    return;
                }

                //fn.setIsPageSaving(false);
                var parameters = {
                    token: creditCard.MITSessionToken,
                    productState: $scope.product.ProductCode
                };

                $state.go('public.' + $scope.product.ProductCode + '.quote.steps.issue', parameters);
            },

            utils: {
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },
                isAutoRenewal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.AutoRenewalFlag) {
                            result = $scope.currentProposal.AutoRenewalFlag;
                        }
                    }
                    return result;

                },
                getInsuranceAmount: function (groupid) {
                    if ($scope.currentProposal.Assets[0].Coverages[0]) {
                        var ins = $filter('filter')($scope.currentProposal.Assets[0].Coverages[0].Covers, { ProductLineGroupId: groupid });
                        if (ins) {
                            if (ins.length > 0) {
                                return ins[0].InsuranceAmount;
                            } 
                            else {
                                return 0;
                            }
                        }
                    } 
                    else {
                        return 0;
                    }
                }, 
                getMake: function (ManufactureId) {
                    if ($scope.staticData.makes.length > 0) {
                        var make = $filter('filter')($scope.staticData.makes, { key: ManufactureId });
                        if (make) {
                          return make.value;
                        }
                    } 
                    else {
                        return '';
                    }
                },       
                setIsSaving: function (isSaving) {
                    $scope.DaLoadingMask.toggle(isSaving);
                }
            },

            saving:{
                savePHContactCallback: function () {
                    //onsuccess of MAXAR
                        try{
                            $scope.$parent.gaEvents(6,3);
                        }
                        catch(e){}

                    $scope.$parent.maxarCallbackTrigger = true;
                    fn.postToMaxParent('No',1,'Y');
                    //DaModal.open('#callbackSaveSuccessfully');
                    //$scope.$parent.divertAfterSECB();
                    //Implement MAXAR
                },
            },

            eventHandlers: {

                onBtnCallbackClicked :function(){
                    DaModal.open('#quotationCallback');

                },
                onBtnCallbackContinueClicked: function () {
                    /* if($scope.phContact.PrimaryEmailAddress != $scope.phContact.PrimaryEmailAddressReenter){
                        $scope.IsReEmailMismatch = true;
                        return;
                    } */

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    $scope.pageSubmitted = true;
                    /* if ($scope.policyHolder.FirstName.invalid ||$scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryEmailAddressReenter.invalid
                        || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    } */

                    if ($scope.phContact.PrimaryEmailAddress.invalid
                        || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }
                    fn.saving.savePHContactCallback();
                },
                onBtnCallbackCancelClicked: function () {

                    DaModal.close('#quotationCallback');
                },

                onBtnSaveAndExitClicked: function () {
                    try{

                        $scope.$parent.gaEvents(0,2);
                    }
                    catch(e){}
                    $scope.$parent.maxarCallbackTrigger = true;
                    fn.postToMaxParent('No',1,'Y')
                    //  DaModal.open('#quotationPartialSave');
                    //The quote is already confirmed.
                    //DaModal.open('#partialSaveSuccessfully');
                    //$scope.$parent.divertAfterSECB();

                },
                onBtnPartialSaveContinueClicked: function () {
                    if($scope.phContact.PrimaryEmailAddress != $scope.phContact.PrimaryEmailAddressReenter){
                        $scope.IsReEmailMismatch = true;
                        return;
                    }

                    $scope.pageSubmitted = true;
                    if ($scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryEmailAddressReenter.invalid) {
                        return;
                    }
                    fn.saving.partialSave();

                },
                onBtnPartialSaveCancelClicked: function () {
                    DaModal.close('#quotationPartialSave');
                },

                doBuyFormValidation:function()
                {
                    return $scope.frmPaymentMethods.$invalid;
                },
                errorOnRequest: function () {
                    fn.utils.setIsSaving(false);
                    console.error('ERR: There was a problem when saving information. ');
                },

                onBtnNextClicked: function () {

                    $scope.pageSubmitted=true;
                    //var newMaxarPrior = 1;
                    
                    //fn.postToMaxParent('Yes', newMaxarPrior,'Y')
                    //confirmation again- as PaymentMethod might have changed.

                    //return to counter done- if was counter services.
                    //public.motor.quote.steps.counterdone
                    var setPHConsent = function(){
                        /* Temporary remove 
                        
                        if($scope.essential.consentPrivacyFlag){
                            $scope.essential.ConsentId = 7000000;
                        }else{
                            $scope.essential.ConsentId = 7000001;
                        }
                        
                        fn.essentialApi.SetPHConsent({
                            onSuccess: function(){
                                saveCurrentProposal();
                            },
                            onError: function(){
                                //saveCurrentProposal();
                            }
                        }); */
                        saveCurrentProposal();
                    };

                    var saveCurrentProposal = function () {
                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                        fn.currentProposalApi[saveCurrentProposalFunc](
                            {
                                onSuccess: function () {
                                    proceedWhere();

                                },
                                onError: function () {
                                    $scope.hasError=true;
                                    proceedWhere();                                }
                            }
                        );
                    };

                    var proceedWhere =function()
                    {
                        if($scope.currentProposal.PaymentModeId==2000003) //counter
                        {
                            createConfirmation();
                        }
                        else
                        {
                            fn.eventHandlers.onBtnNextProceed();
                        }
                    };

                    var createConfirmation = function () {
                        fn.utils.setIsSaving(true);
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                savingComplete();
                            },
                            onError: function () {
                                $scope.hasError = true;
                                savingComplete();
                            }
                        });
                    };

                    var savingComplete= function()
                    {
                        fn.utils.setIsSaving(false);
                        if($scope.currentProposal.PaymentModeId==2000003) //counter
                        {
                            if(!$scope.hasError)
                            {
                                fn.goToCounterConfirmStep();
                            }
                            else
                            {
                                //error. Counter didnt updated.
                                DaModal.open('#failedCounter');
                            }
                        }
                        else
                        {
                            fn.eventHandlers.onBtnNextProceed();
                        }

                    };



                   //update first for Payment term Id
                    //if counter payment- the also have to change-PaymentTermId ( Diff From Single/ Monthly for Credit card)
                    if($scope.currentProposal.PaymentModeId==2000003) //counter
                    {
                        $scope.currentProposal.PaymentTermId=2000001;
                    }
                    //saveCurrentProposal();
                    setPHConsent();
                },

                onBtnNextProceed: function(){
                    var callbacks = {
                        enableLoadingMask: function () {
                            fn.utils.setIsSaving(true);
                        },
                        onPaymentSuccess: fn.issuePolicy,
                        onPaymentError: fn.eventHandlers.errorOnRequest
                    };

                    $scope.totalPremium = 100;
                    //update currentproposal
                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                    if ($scope.currentProposal.IsRenewalProposal) {
                        fn.currentProposalApi[saveCurrentProposalFunc](
                            {
                                onSuccess: function () {
                                    $scope.PaymentScope.doPayment(undefined, $scope.currentProposal.ProductId, $scope.currentProposal.IsRenewalProposal, $scope.totalPremium, callbacks);
                                },
                                onError: function () {
                                }
                            }
                        );
                    } else {
                        $scope.PaymentScope.doPayment(undefined, $scope.currentProposal.ProductId, $scope.currentProposal.IsRenewalProposal, $scope.totalPremium, callbacks);
                    }
                },

                onBtnBackClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){}
                    fn.utils.setIsSaving(true);
                    fn.goToPrevStep();
                },

                openPanelview:function(p1){
                    if (!p1)
                        return;

                    fn.gtAnchor(p1);
                },

                onShowPanel:function(x) {
                    $(x).show();
                },

                onHidePanel:function(x) {
                    $(x).hide();
                },

                setPaymentSchedule:function(key){
                    $scope.currentProposal.PaymentTermId = key;
                    if(key=='1000005' || key==1000005)
                    {
                        //monthly
                        $scope.currentProposal.PaymentModeId=null;
                    }
                    else
                    { //single

                    }
                    // $scope.paymentSchedule = key;
                    //console.log($scope.paymentSchedule);
                },
                setPaymentMethod:function(method){
                    if(method=='credit')
                    {
                        $scope.currentProposal.PaymentModeId=4;
                        marketing.dLCE(42, 'Credit Card')
                    }
                    else
                    {
                        $scope.currentProposal.PaymentModeId=7000001;
                        marketing.dLCE(42, 'Debit Card')
                    }
                    //$scope.paymentMethod = method;
                   // console.log($scope.paymentMethod);
                    $scope.$parent.setSelectedPayment(method);
                },

                seeFullPayment:function(flag){
                    $scope.fullPayment = !flag;
                },

                onFocusReEmail: function(){
                    $scope.IsReEmailMismatch = false;
                }
            },


        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        //For oyster demo
        $scope.staticData.makes = {};
        $scope.staticData.models = {};
        $scope.onShowPanel = fn.eventHandlers.onShowPanel;
        $scope.onHidePanel = fn.eventHandlers.onHidePanel;
        $scope.onOpenPanelview = fn.eventHandlers.openPanelview;

        $scope.setPaymentSchedule = fn.eventHandlers.setPaymentSchedule; //used this in development.
        $scope.setPaymentMethod = fn.eventHandlers.setPaymentMethod;

        //For oyster demo

        $scope.seeFullPayment = fn.eventHandlers.seeFullPayment;

        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        //fn.initScopeObjects();
        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.getInsuranceAmount = fn.utils.getInsuranceAmount;
        $scope.isAutoRenewal = fn.utils.isAutoRenewal;
        $scope.getMake = fn.utils.getMake;

        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;
        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.divertAfterSECB=$scope.$parent.divertAfterSECB;
        $scope.onFocusReEmail = fn.eventHandlers.onFocusReEmail;

        $scope.dLCE=marketing.dLCE;
        $scope.$on('$viewContentLoaded', function() {

        });
        
    }];