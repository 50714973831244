/**
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$localStorage',
    '$window',
    '$state',
    '$stateParams',
    'MITDate',
    'DaModal',
    'AccountProposal',
    'MitScopeBindingUtils',
    'WfeApiPolicy',
    'HKLocale',
    'WfeApiStaticData',
    'HKDownload',
    'WfeApiProposal',
    'WfeApiSession',
    'DaLoadingMask',
    'Policy',

    function PoliciesController($scope, $localStorage, $window, $state, $stateParams,
                                MITDate, DaModal, AccountProposal, MitScopeBindingUtils, WfeApiPolicy,
                                HKLocale, WfeApiStaticData, HKDownload, WfeApiProposal, WfeApiSession, DaLoadingMask, Policy) {

        var marketing = {
            ga: function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event':'content-view',
                    'virtualPageURL':'/myaccount/customer-access/policies/'}
                );
                /*if(ga) { ga('send', 'pageview', '/myaccount/customer-access/policies/');console.log('policiesga');}*/
            },

            polview:function()
            {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event': 'View',
                    'category': 'Policy',
                    'action': 'View',
                    'label': 'View'
                });
            },
            poldwld:function()
            {
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event': 'Download',
                    'category': 'Policy',
                    'action': 'Download',
                    'label': 'Download'
                });
                /*if(ga) { ga('send', 'event', 'MyAccount', 'click', 'download_policy', {'page': '/myaccount/customer-access/policies/'});}*/
            },
            polrenew:function()
            {
                // Renewal Not applicable for Oyster
                /*if(ga) { ga('send', 'event', 'MyAccount', 'click', 'renew_policy', {'page': '/myaccount/customer-access/policies/'});}*/
            }
        };
        var fn = {
            setIsLoading: function (isLoading) {
                DaLoadingMask.toggle(isLoading);
            },
            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getProductList();
                };

                var getProductList = function () {
                    wfeApiStaticData.getProductList({
                        onSuccess: function (data) {
                            $scope.staticData.products = data;
                        }
                    });
                };

                startRetrieval();
            },

            initApiObjects: function () {
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), undefined);
                fn.policyApi = WfeApiPolicy.instance(undefined, $scope, $scope.currentUser.existingPolicies);
                fn.policyRetrieveApi = WfeApiPolicy.instance(undefined, $scope, $scope.policy);
                fn.policyDownloadApi = WfeApiPolicy.instance(undefined, $scope, $scope.Document);

                try {
                    marketing.ga();
                } catch (err) {
                    console.log(err);
                }

            },

            initScopeObjects: function () {
                fn.renewalProposalApi = WfeApiProposal.instance(undefined, $scope, $scope.renewalProposal);
                $scope.selectedDocument = {};
                $scope.renewalProposal = {};
                $scope.currentUser = {
                    existingPolicies: {},
                    documentsForDownload: {},
                    renewalProposals: {}//Proposal.retrieveExistingRenewalProposals()
                };
                //retrieve renewal supply the renewal number = '+/01'
                fn.renewalProposalApi.retrieveExistingRenewalProposals({
                    onSuccess: function (data) {
                        $scope.currentUser.renewalProposals = data.Proposals;
                    }, onError: function () {
                        console.log('error');
                    }
                });
                //$scope.policyNumber = '';
                $scope.policy = {};
                $scope.Document = {};


                $scope.staticData = {
                    products: []
                };
            },

            retrieveScopeObjects: function () {
                fn.policyApi.retrieveExistingPolicies(
                    {
                        onSuccess: function (data) {
                            //console.log(data);
                            /*var validProducts = [];
                            var filteredData = $filter('filter')(data.Policies, function (item) {
                                return validProducts.indexOf(item.ProductId) < 0;
                            });

                            angular.extend($scope.currentUser.existingPolicies, filteredData);*/
                            angular.extend($scope.currentUser.existingPolicies, data);
                            //filter term lifes
                            //console.log($scope.currentUser.existingPolicies.Policies);
                            //alert($scope.currentUser.existingPolicies.Policies);
                            for (var i = $scope.currentUser.existingPolicies.Policies.length - 1; i >= 0; i--) {
                                if ($scope.currentUser.existingPolicies.Policies[i].ExternalPolicyNumber.indexOf('TL') == 0) {
                                    $scope.currentUser.existingPolicies.Policies.splice(i, 1);
                                }
                            }
                            angular.forEach($scope.currentUser.existingPolicies.Policies, function (item) {
                                item.ExternalPolicyNumberLink = item.ExternalPolicyNumber.replace(/\//g, '-');

                                if(item.ExternalPolicyNumber.indexOf("-")>1){ // Endorsement
                                    item.ExternalPolicyNumberLink="E"+ item.ExternalPolicyNumberLink
                                     }

                            });
                        }
                    }
                );


            },

            isViewingPolicy: false,


            showDownloadModal: function () {
                DaModal.open('#downloadDocumentsModal');
            },

            /* isRenewalExpired: function (proposal, policy) {

             if (!proposal) {
             return false;
             }

             var validDate = MITDate.newDate(policy.EndorsEndDate);
             var todayDate = MITDate.newDate();

             var diff = validDate.diff(todayDate, 'days');
             var isExpired = (diff  < 0);

             return isExpired;
             },*/
            utils: {
                isRenewalProposal: function (currentProposal) {
                    var renewalList = $scope.currentUser.renewalProposals;
                    if (renewalList && currentProposal) {
                        for (var i = 0; i < renewalList.length; i++) {
                            var renewalProposal = renewalList[i];
                            var renewalNumber = renewalProposal.ExternalProposalNumber;
                            var ProductId = renewalProposal.ProductId;
                            if (ProductId === '1000009' && renewalNumber.indexOf(currentProposal.ExternalPolicyNumberDisplay) >= 0) {
                                return true;
                            }
                        }
                    }
                    return false;
                },
                getRenewalProposalNo: function (currentProposal) {
                    var renewalList = $scope.currentUser.renewalProposals;
                    if (renewalList && currentProposal) {
                        for (var i = 0; i < renewalList.length; i++) {
                            var renewalProposal = renewalList[i];
                            var renewalNumber = renewalProposal.ExternalProposalNumber;
                            if (renewalNumber.indexOf(currentProposal.ExternalPolicyNumberDisplay) >= 0) {
                                return renewalNumber;
                            }
                        }
                    }
                    return false;
                }

            },
            eventHandler: {

                btnRenewPolicyClicked: function (policySelected) {
                    //do session here
                    var productCode = policySelected.ProductId;
                    var sessionApi = WfeApiSession.instance($scope, productCode);
                    var tokenId = '';
                    $scope.renewalProposal.policyNumber = fn.utils.getRenewalProposalNo(policySelected);
                    fn.renewalProposalApi = WfeApiProposal.instance(undefined, $scope, $scope.renewalProposal);
                    var retrieveRenewal = function () {
                        fn.renewalProposalApi.retrieveRenewal({
                            onSuccess: function (data) {


                                tokenId = data.MITSessionToken;
                                fn.setIsLoading(false);

                                var parameter = {
                                    token: tokenId,

                                    productCode: productCode
                                };


                                $state.go('private.account.user.viewRenewConfirm', parameter);
                            },
                            onError: function () {
                                fn.setIsLoading(false);
                                console.log('error');
                            }
                        });
                    };

                    var todayDate = MITDate.newDate();
                    var endDate = MITDate.newDate(policySelected.PolicyEndDate || policySelected.EndorsEndDate).add(1, 'days');

                    if (todayDate.isBefore(endDate)) {
                        fn.setIsLoading(true);
                        sessionApi.createSession({
                            onSuccess: function () {

                                retrieveRenewal();


                            }, onError: function () {
                                fn.setIsLoading(false);
                                console.log('error');
                            }
                        });
                    } else {
                        DaModal.open('#expiredRenewal');
                    }
                    //$scope.selectedProposal.proposalNumber = policySelected.ExternalProposalNumber;


                    //retrieveRenewal
                    /*if (fn.isRenewalExpired(proposal, policy)) {
                     DaModal.open('#expiredRenewal');
                     return;
                     }



                     var products = {
                     '1000009': {state:'public.motor.quote.steps.confirm', code:'Motor Car'},

                     };

                     var productCode = products['' + proposal.ProductId].code;
                     var productState = products['' + proposal.ProductId].state;
                     var utils = new MitScopeBindingUtils($scope, $localStorage, productCode);



                     var createRenewal = function(){
                     var mitScopeBindingMit = utils;
                     var pAndCModelStorage = mitScopeBindingMit.getPAndCModelFromStorage();

                     utils.initScopeAndStorage();

                     //FIXME: call the correct function
                     $scope.proposal = new Proposal();
                     $scope.proposal.policyNumber = proposal.RenewExternalPolicyNumberLink;

                     $scope.proposal.$retrieveRenewal(undefined, function(response){
                     console.log(response.MITSessionToken);
                     if (response.success) {

                     pAndCModelStorage.session = response;
                     utils.saveToScopePAndCModel('session', pAndCModelStorage.session);

                     pAndCModelStorage.isAccepted = true;
                     $state.go(productState);
                     }
                     });



                     };

                     createRenewal();*/
                   /* try {
                        marketing.polrenew();
                    }
                    catch (err) {
                        console.log(err);
                    }*/

                },

                //btnRenewPolicyClicked: function btnRenewPolicyClicked(policy) {
                //
                //    var parameter = {
                //        policyNumber: policy.ExternalPolicyNumberLink,
                //        //productCode: url
                //        productId: policy.ProductId
                //    };
                //
                //    $state.go('public.travel.quote.steps.confirm', parameter);
                //},

                btnViewPolicyClicked: function btnDownloadClicked(policy) {

                    //var products = {
                    //    '1000003': {url:''},
                    //    '1000004': {url:'travel'},
                    //    '2000000': {url:'motorcycle'},
                    //    '2000002': {url:'sos'},
                    //    '2000003': {url:'termlife'}
                    //};
                    //
                    //var obj = products[policy.ProductId + ''];
                    //var url = '';
                    //if (obj) {
                    //    url = obj.url;
                    //}

                    var parameter = {
                        policyNumber: policy.ExternalPolicyNumberLink,
                        //productCode: url
                        productId: policy.ProductId
                    };
                    console.log(parameter.productId);
                    try {
                        marketing.polview();
                    }
                    catch (err) {
                        console.log(err);
                    }
                    $state.go('private.account.user.viewpolicy', parameter);
                },

                btnShowDownloadClicked: function btnDownloadClicked(policy) {
                    if (!(policy.ExternalPolicyNumber || policy.ExternalProposalNumber)) {
                        return;
                    }
                    $scope.policy.policyNumber = policy.ExternalPolicyNumber || policy.ExternalProposalNumber;
                    console.log($scope.policy);
                    //$scope.ExternalPolicyNumber = policy.ExternalPolicyNumber || policy.ExternalProposalNumber;
                    //$scope.selectedDocument.ExternalPolicyNumber= policy.ExternalPolicyNumber || policy.ExternalProposalNumber;
                    //$scope.policyNumber = policy.ExternalPolicyNumber || policy.ExternalProposalNumber;
                    //$scope.currentUser.documentsForDownload.policyNumber = policy.ExternalPolicyNumber || policy.ExternalProposalNumber;
                    //$scope.currentUser.documentsForDownload.ExternalPolicyNumber = policy.ExternalPolicyNumber || policy.ExternalProposalNumber;

                    fn.showDownloadModal();

                    // call api
                    // var policyDownloadApi = WfeApiPolicy.instance(undefined,policy);
                    //$scope.policy.policyNumber = policy.ExternalPolicyNumber || policy.ExternalProposalNumber;
                    fn.policyRetrieveApi.retrievePolicyDocuments({
                        onSuccess: function (data) {
                            angular.extend($scope.currentUser.documentsForDownload, data);
                        },
                        onError: function () {
                            console.log('errors');
                        }
                    });
                    /*$scope.currentUser.documentsForDownload = Policy.retrievePolicyDocuments( undefined, {
                     policyNumber: policy.ExternalPolicyNumber || policy.ExternalProposalNumber
                     });*/
                    try {
                        marketing.poldwld();
                    }
                    catch (err) {
                        console.log(err);
                    }
                },

                btnDownloadDocumentClicked: function btnDownloadClicked(docObj) {
                    //HKDownload.downloadDocument($scope, docObj);
                    // call api
                    Policy.downloadDocument(undefined, {
                        DocumentId: docObj.DocumentID
                    });
                    //$scope.Document.DocumentId = docObj.DocumentID;
                    //fn.policyDownloadApi.downloadDocument({
                    //    onSuccess:function(data){
                    //
                    //        var document = [];
                    //        for(var i = 0; data[i];i++){
                    //            document.push(data[i]);
                    //        }
                    //       // var a = $window.document.createElement('a');
                    //        var file = new $window.Blob(document, {type: 'application/pdf;base64'});
                    //
                    //       // a.href = $window.URL.createObjectURL(file);
                    //        $window.open($window.URL.createObjectURL(file));
                    //        //a.download = 'policy.pdf';
                    //        //a.click();
                    //
                    //        console.log('success');
                    //    },
                    //    onError:function() {
                    //        console.log('error');
                    //    }
                    //});
                    //console.log(docObj);
                }
            }


        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        //Scroll to top
        $window.scrollTo(0, 0);

        $scope.isViewingPolicy = fn.isViewingPolicy;

        //Event Handlers
        $scope.btnShowDownloadClicked = fn.eventHandler.btnShowDownloadClicked;
        $scope.btnViewPolicyClicked = fn.eventHandler.btnViewPolicyClicked;
        $scope.btnDownloadDocumentClicked = fn.eventHandler.btnDownloadDocumentClicked;
        $scope.btnRenewPolicyClicked = fn.eventHandler.btnRenewPolicyClicked;

        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.utils.isRenewalProposal = fn.utils.isRenewalProposal;


    }];