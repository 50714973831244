/*
 * Created by Gerald Garcia on 29/06/15.
 */

/***
 * The common same height button.
 * @type {*[]}
 */
module.exports = [
    '$timeout',

    function sameHeightButton($timeout) {



            function link($scope,element) {
                var buttonEqHt = element.find('.eqHtCtlClass');



                var watcher = $scope.$watch(function(){
                    //$scope.__height = buttonEqHt[0].offsetHeight;
                    if ($scope.$last === true&&$scope.i.value&&buttonEqHt[0].offsetHeight>0) {
                        console.log(buttonEqHt[0].offsetHeight);

                        $timeout(function () {
                            $scope.$emit('ngRepeatFinished');
                            watcher();
                        });
                    }
                });
            }

        return {
            restrict: 'AE',
            link: link
        };
    }];