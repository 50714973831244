/*
 * Created by Chanon Tangputthajit.
 */
module.exports = [
    '$anchorScroll',
    '$scope',
    '$rootScope',
    '$state',
    '$timeout',
    'MITDate',
    'HKLocale',
    'WfeApiStaticData',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiPolicyContact',
    'WfeApiQuote',
    'DaModal',
    'HKFormValidation',
    'CountryMotorQuoteValidation',
    'WfeApiUser',
    'WfeApiContact',
    'WfeApiAddress',
    '$filter',
    'WfeApiLog',
    'WfeApiEssential',
    '$window',
    '$cookies',
    '$sessionStorage',
    'WfeApiCoverages',
    'StaticDataKeyValuePair',
    'ConfigUtils',
    'WfeApiConfirmation',

    /**
     * Controller for the Basic Details of Motor Quote
     * @constructor
     */
    function BrokerAppController($anchorScroll, $scope, $rootScope, $state, $timeout, MITDate, HKLocale, WfeApiStaticData, WfeApiCurrentProposal,
        WfeApiAsset, WfeApiPolicyContact, WfeApiQuote, DaModal, HKFormValidation, CountryMotorQuoteValidation,
        WfeApiUser, WfeApiContact, WfeApiAddress, $filter, WfeApiLog, WfeApiEssential, $window, $cookies, $sessionStorage, WfeApiCoverages, StaticDataKeyValuePair, config, WfeApiConfirmation) {
        $anchorScroll();

        var fn = {

            gtAnchor: function (x) {
                if (!x) { return; }

                HKFormValidation.animateToID(x);
            },

            setSourceChannels:function(){

                var sc={};
                sc.id=7000002;
                sc.cd='Broker partner';

                $scope.asset.SourceChannelId=sc.id;
                $scope.asset.ChannelDetails=sc.cd;

            },

            initStaticData: function () {

                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getBooleanList();
                };


                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getMakeList();
                        },

                        onError: function () {
                            getMakeList();
                        }
                    });
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            /* highlight few models on top */
                            filteredMake = $filter('filter')(data, function (r) {
                                var notAcceptMake = ["1000001",
                                    "1000046",
                                    // /* Chevrolet */ "1000007",
                                    "1000047",
                                    "1000008",
                                    "1000071",
                                    "1000009",
                                    "1000011",
                                    "1000092",
                                    "1000050",
                                    "1000018",
                                    "1000099",
                                    // /* MG */        "1000101",
                                    "1000093",
                                    "1000032",
                                    "1000035",
                                    "1000053",
                                    "1000036",
                                    "1000086",
                                    "1000038",
                                    "1000066",
                                    "1000039",
                                    "1000087",
                                    "1000088",
                                    "1000041",
                                    "1000098",
                                    "1000005",
                                    "1000027",
                                    "1000002",
                                    "1000004",
                                    "1000102",
                                    "1000096",
                                    "1000010",
                                    "1000097",
                                    "1000103",
                                    "1000020",
                                    "1000023",
                                    "1000024",
                                    "1000104",
                                    "1000094",
                                    "1000065",
                                    "1000100",
                                    "1000105"
                                ];
                                if (!notAcceptMake.includes(r.key)) {
                                    //console.log(r.key);
                                    return r.key;
                                }
                            });

                            data = filteredMake;
                            if (data) {
                                var repeatedData = [];
                                repeatedData.push({ key: "1000091", value: "Toyota" });
                                repeatedData.push({ key: "1000015", value: "Honda" });
                                repeatedData.push({ key: "1000031", value: "Nissan" });
                                repeatedData.push({ key: "1000025", value: "Mazda" });
                                repeatedData.push({ key: "1000080", value: "Isuzu" });
                                repeatedData.push({ key: "1000028", value: "Mitsubishi" });
                                repeatedData.push({ key: "0000000", value: "--------------" });

                                $scope.staticData.makes = repeatedData.concat(data);
                            }
                            else {
                                $scope.staticData.makes = data;
                            }
                            getGenderList();
                        },

                        onError: function () {
                            getGenderList();
                        }
                    });
                };

                var getGenderList = function () {

                    if ($scope.HKLocale.getLocale() === "th-th") {
                        var data = [{ "key": "2", "value": "ชาย" }, { "key": "1", "value": "หญิง" }];
                    } else {
                        var data = [{ "key": "2", "value": "Male" }, { "key": "1", "value": "Female" }];
                    }
                    $scope.staticData.genders = data;
                    getMaritalStatusList();
                };

                var getMaritalStatusList = function () {

                    if ($scope.HKLocale.getLocale() === "th-th") {
                        var data = [{ "key": "1", "value": "โสด" }, { "key": "2", "value": "สมรส" }];
                    } else {
                        var data = [{ "key": "1", "value": "SINGLE" }, { "key": "2", "value": "MARRIED" }];
                    }
                    $scope.staticData.maritalStatuses = data;
                    getDatesPartsDD();
                };

                var getDatesPartsDD = function () {

                    wfeApiStaticData.getDatesPartsDD({
                        onSuccess: function (data) {
                            $scope.staticData.datesDays = data;
                            getDatesPartsMM();
                        },

                        onError: function () {
                            getDatesPartsMM();
                        }
                    });
                };
                var getDatesPartsMM = function () {

                    wfeApiStaticData.getDatesPartsMM({
                        onSuccess: function (data) {
                            $scope.staticData.datesMonths = data;
                            getDatesPartsYY();
                        },

                        onError: function () {
                            getDatesPartsYY();
                        }
                    });
                };
                var getDatesPartsYY = function () {

                    wfeApiStaticData.getDatesPartsYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYears = data;
                            getDatesPartsFullYY();

                        },

                        onError: function () {
                            getDatesPartsFullYY();
                        }
                    });
                };

                var getDatesPartsFullYY = function () {
                    fn.wfeApiStaticData.getDatesPartsFullYY({
                        onSuccess: function (data) {
                            $scope.staticData.datesYearsPolicy = [data[0], { "key": data[0]["key"] + 1, "value": data[0]["value"] + 1, "valueTH": data[0]["valueTH"] + 1 }];
                            fn.utils.setIsSaving(false);
                            getDriverExperienceList();
                        },

                        onError: function () {
                            getDriverExperienceList();
                        }
                    });
                };

                var getDriverExperienceList = function () {
                    wfeApiStaticData.getDriverExperienceList({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                            //getNoClaimDiscountList();
                            getOccupationList();
                        },

                        onError: function () {
                            //getNoClaimDiscountList();
                            getOccupationList();
                        }
                    });
                };

                var getOccupationList = function () {
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getNoOfClaimList();
                        },

                        onError: function () {
                            getNoOfClaimList();
                        }
                    });
                };

                var getNoOfClaimList = function () {
                    wfeApiStaticData.getNoOfClaimList({
                        onSuccess: function (data) {
                            $scope.staticData.nrOfClaimsInPeriods = data;
                            getProvinceList();
                        },

                        onError: function () {
                            getProvinceList();
                        }
                    });
                };

                var getProvinceList = function () {
                    wfeApiStaticData.getProvinceList({
                        onSuccess: function (data) {
                            $scope.staticData.provinces = data;
                            $scope.staticData.provincesByPostal = data;
                            getDistrictList();
                        },

                        onError: function () {
                            getDistrictList();
                        }
                    });
                };

                var getDistrictList = function () {
                    wfeApiStaticData.getDistrictList({
                        onSuccess: function (data) {
                            $scope.staticData.districtByPostal = data;
                            getCountryList();
                        },

                        onError: function () {
                            getCountryList();
                        }
                    });
                };

                var getCountryList = function () {
                    wfeApiStaticData.getCountryList({
                        onSuccess: function (data) {
                            $scope.staticData.countries = data;
                            $scope.phAddress.CountryId = "200";
                            getResidentialStatusList();
                        },

                        onError: function () {
                            getResidentialStatusList();
                        }
                    });
                }

                var getResidentialStatusList = function () {
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentialStatus = data;
                            //getEngineSizes();
                        },

                        onError: function () {
                            //getEngineSizes();
                        }
                    });
                };

                var getEngineSizes = function () {
                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getEngineSizes({
                        onSuccess: function (data) {
                            $scope.staticData.enginesizes = data;
                            getBodyTypes();
                        },
                        onError: function () {
                            getBodyTypes();
                        }
                    });
                };

                var getBodyTypes = function () {
                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getBodyTypes({
                        onSuccess: function (data) {
                            $scope.staticData.bodytypes = data;
                        },
                        onError: function () {
                        }
                    });
                };

                startRetrieval();

            },

            initScopeObjects: function () {
                $scope.staticData = {};

                var dateTomorrow = MITDate.newDate().add(1, 'days');
                $scope.currentProposal = {
                    PolicyStartDate: MITDate.toAngularDate(dateTomorrow),
                    PolicyEndDate: MITDate.toAngularDate(dateTomorrow.add(1, 'years')),
                    PaymentModeId: 7000010
                };

                $scope.asset = {
                    HasAntiTheftDevice: false,
                    'LobInsuranceFormulaId': 2000015, // to be removed later
                    //UsageId: 7000000

                };

                var dateToday = MITDate.newDate();
                $scope.yearNow = (parseInt(MITDate.getYYYY(dateToday)) - 9) || 1941;

                $scope.asset.ProductId = $scope.$parent.product.ProductId;

                $scope.policyHolder = {};
                $scope.mainDriver = {};
                $scope.namedDriver = {};
                $scope.namedDrivers = {
                    PolicyContacts: []
                };
                $scope.accountUser = {};
                $scope.phAddress = {};
                $scope.phContact = {};
                $scope.dynamicForm = {};
                $scope.dynamicForm.addedNamedDriver = false;
                $scope.dynamicForm.namedDriverAction = 'create';
                $scope.bidata = {};
                $scope.errdata = {};
                $scope.callbackObj = {};
                $scope.securityQuestion = {};
                $scope.isSecurityQuestion = false;
                $scope.previousPage = 0;
                $scope.DOBEmpty = false;
                $scope.loadingSpinner = true;
                $scope.loadingSpinner2 = true;
                $scope.loadingSpinner3 = true;
                $scope.partialPolicyNumber = "";
                $scope.applyNCB;
                $scope.isStartQuote = false;
                $scope.isSaveAndExitShowName = true;
                $scope.txtMaker = fn.getTranslateText('maker');
                $scope.txtModel = fn.getTranslateText('model');
                $scope.txtYear = fn.getTranslateText('year');
                $scope.txtDesc = fn.getTranslateText('desc');
                param = 1000003;
                $scope.showMainDriverFlag = false;
                $scope.showContactInfoFlag = false;
                $scope.firstPageShow = true;
                $scope.secondPageShow = false;
                $scope.thirdPageShow = false;
                $scope.showPlanFlag = false;
                $scope.coverNum = 0;
                $scope.sumInsured = {};
                $scope.sumInsured.value = 0;
                $scope.sumInsured.visible = false;
                $scope.chkMaker = false;
                $scope.chkModel = false;
                $scope.chkYear = false;
                $scope.chkDesc = false;
                $scope.chkCamera = false;
                $scope.bodyType = '';// set 4 doors as default
                $scope.gearType = ''; //Set manual as default
                $scope.acctImageURL = '';
                $scope.acctImageLink = '';
                $scope.sectioname = "Start new proposal";
                //$scope.hash = window.location.hash;
                //$sessionStorage.currentPage = 1;
                $scope.loc = '';
                $scope.loc = HKLocale.getLocale();
                if ($scope.loc == 'en-us') {
                    $scope.policyHolder.PreferredLanguageId = 101;
                }
                if ($scope.loc == 'th-th') {
                    $scope.policyHolder.PreferredLanguageId = 2000000;
                }

                $scope.basicCoverageInfo = {
                    "2000015":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any_v2',
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',

                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',

                        ]
                };

                $scope.basicCoverageInfo_Part2 = {
                    "2000015":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000016":
                        [

                            'body_basic_coverage_info_payment'
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_payment'
                        ]
                };
                $scope.tyepOfCoverageTable = {
                    "body_popup_cover_item1": [true, true, true, false, false],
                    "body_popup_cover_item2": [true, true, true, true, true],
                    "body_popup_cover_item3": [true, true, false, true, false],
                    "body_popup_cover_item4": [true, true, false, false, false],
                    "body_popup_cover_item5": [true, false, false, false, false],
                    "body_popup_cover_item6": [true, true, true, false, false]
                };

                $scope.customInsuranceAmount = 0;
                $scope.selectedInsureAmount = 0;
                $scope.carAge = 0;
                $scope.consentPrivacyFlag = false;
                $scope.carUsageList = [{ "key": "6", "value": "btn_car_usage1" },
                { "key": "1000000", "value": "btn_car_usage2" },
                { "key": "1000002", "value": "btn_car_usage3" },
                { "key": "0", "value": "btn_car_usage4" }];
                $scope.policyMinStartDate = MITDate.toAngularDate(MITDate.newDate().add(0, 'days'));
                $scope.policyMaxStartDate = MITDate.toAngularDate(MITDate.newDate().add(59, 'days'));
                $scope.compulsory = {};
                $scope.compulsory.isIncluded = true;
            },

            initApiObjects: function () {
                /* console.log($scope.$parent.sessionApi);
                console.log($scope.currentProposal); */

                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.mainDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.mainDriver);
                fn.namedDriversApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.namedDrivers);
                fn.quoteApi = WfeApiQuote.instance($scope.$parent.sessionApi, $scope);
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.policyContactApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyContact);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataYear = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.yearNow);
                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.phAddressApi = WfeApiAddress.instance($scope.$parent.sessionApi, $scope, $scope.phAddress);

                fn.logApi = WfeApiLog.instance($scope.$parent.sessionApi, undefined, $scope.bidata, $scope.errdata);
                fn.essentialApi = WfeApiEssential.instance($scope.$parent.sessionApi, $scope);

            },

            /** Subscribe MKT email condition */
            initSubscribeWatcher: function () {

                $scope.$watch('policyHolder.HasUnsubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.SubscribedMarketingEmail = !value;
                });
                $scope.$watch('policyHolder.SubscribedMarketingEmail', function (value) {
                    $scope.policyHolder.HasUnsubscribedMarketingEmail = !value;
                });

                $scope.$watch('totalPremium2', function (val) {  //$scope.totalPremium2
                    //if($sessionStorage.currentPage == 2 || $sessionStorage.currentPage == 3){
                    if (val > 0) {
                        fn.saving.saveCoveragesModel(
                            {

                                onSuccess: function () {
                                    fn.logApi.updateLog();
                                    // DATH-1137
                                    fn.updateAssetMaxarPriority();
                                    if ($scope.selectedCoverage.CoverTypeId == 2000017 ||
                                        $scope.selectedCoverage.CoverTypeId == 2000019) {
                                        fn.quoteApi.getInsuranceAmount(
                                            {
                                                onSuccess: function (data) {
                                                    if (data && data[0].value) {
                                                        $scope.customInsuranceAmount = data;
                                                        fn.setDefaultInsureAmount();
                                                    }
                                                },
                                                onError: function () {
                                                }
                                            }
                                        );
                                    }

                                },
                                onError: function () {
                                }
                            }
                        );

                    }
                    //}          
                });


                $scope.$watch(function () { return window.location.hash }, function (val) {
                    if (val == "#your-quote") {
                        if ($sessionStorage.currentPage == 1 && $scope.previousPage != 0) {

                            fn.eventHandlers.onBtnCalculatePremiumClicked();
                        } else {
                            fn.goToPage(2);
                        }
                    } else if (val == "#review") {
                        if ($sessionStorage.currentPage == 1 && $scope.previousPage != 0) {

                            fn.eventHandlers.onBtnCalculatePremiumClicked();
                        } else {
                            fn.goToPage(3);
                        }
                    }
                    var currPage = $sessionStorage.currentPage;
                    var prevPage = $scope.previousPage;

                });

                var destroyWatcher = $scope.$on('$destroy',
                    function () {
                        destroyWatcher();
                    }
                );
            },

            retrieveScopeObjects: function () {

                /** Fire to GA that customer has been visited page */
                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                fn.utils.ConvertMITDateToDisplay();
                                retrieveAsset();
                            },
                            onError: function () {
                                retrieveAsset();
                                fn.utils.ConvertMITDateToDisplay();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                // retrieve coverage
                                fn.updatesCoveragesSPA();
                                $scope.applyNCB = $scope.$parent.applyNCBRoot;
                                if ($scope.$parent.applyNCBRootDontKnow) {
                                    $scope.asset.NcdLevelId = "1000009";
                                }

                                if ($scope.asset.IsRedPlate) {
                                    if ($scope.HKLocale.getLocale === "en-us") {
                                        $scope.asset.VehicleRegistrationNumber = "NA";
                                    } else {
                                        $scope.asset.VehicleRegistrationNumber = "ป้ายแดง";
                                    }
                                    $scope.asset.LicenseCardProvinceId = "2000080";
                                }

                                if ($scope.asset.VehicleRegistrationNumber === "NA" || $scope.asset.VehicleRegistrationNumber === "ป้ายแดง") {
                                    $scope.asset.IsRedPlate = true;
                                } else {
                                    $scope.asset.IsRedPlate = false;
                                }

                                if ($scope.asset.ManufactureId) {
                                    $scope.txtMaker = $filter('filter')($scope.staticData.makes, { key: $scope.asset.ManufactureId })[0].value;
                                    fn.eventHandlers.onDropMakeChanged($scope.asset.ManufactureId);
                                    $scope.asset.PurchasingYear = $scope.asset.PurchasingYear.toString();
                                }

                                if ($scope.asset.ModelId) {
                                    fn.eventHandlers.onDropModelChanged($scope.asset.ManufactureId, $scope.asset.ModelId);

                                    var objParams = {};
                                    objParams.manufacturerId = $scope.asset.ManufactureId;
                                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                                    wfeApiStaticDataModel.getModelListV2({
                                        onSuccess: function (data) {
                                            fn.assetStaticData.model = data;
                                            $scope.txtModel = $filter('filter')(fn.assetStaticData.model, { key: $scope.asset.ModelId })[0].value;
                                        }
                                    });

                                }
                                if ($scope.asset.PurchasingYear) {
                                    fn.eventHandlers.onDropPurchasingYearChanged($scope.asset.ModelId, $scope.asset.PurchasingYear);
                                    $scope.txtYear = $scope.asset.PurchasingYear;
                                }
                                if ($scope.asset.CarDescriptionId) {
                                    var objParams = {};
                                    objParams.modelId = $scope.asset.ModelId;
                                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                                    wfeApiStaticDataModel.getCarDescWithYear({
                                        onSuccess: function (data) {
                                            fn.assetStaticData.description = data;
                                            $scope.txtDesc = $filter('filter')(fn.assetStaticData.description, { key: $scope.asset.CarDescriptionId })[0].description;
                                        }
                                    });
                                }

                                retrievePHAddress();

                            },
                            onError: function () {
                                retrievePHAddress();
                            }
                        }
                    );
                };

                var retrievePHAddress = function () {
                    fn.phAddressApi.retrievePHAddress(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phAddress, data);
                                $scope.phAddress.DistrictId = $scope.phAddress.DistrictId + "";
                                retrievePHContact();
                            },
                            onError: function () {
                                retrievePHContact();
                            }
                        }
                    );
                }

                var retrievePHContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);  
                                retrievePolicyHolder();

                            },
                            onError: function () {
                                retrievePolicyHolder();
                            }
                        }
                    );
                }

                /** PolicyContact  Retrieval : this will retrieve MD if PH is not MD */
                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {
                                //console.log(data);
                                angular.extend($scope.policyHolder, data);
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data, $scope.policyHolder);
                                }

                                if ($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                    $scope.policyHolder.SubscribedMarketingEmail = false;
                                } else {
                                    $scope.policyHolder.SubscribedMarketingEmail = true;
                                }

                                $scope.loadingSpinner = false;
                                $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);

                                /* if ($scope.policyHolder === undefined) {
                                    retrieveMainDriver();
                                } */

                                if ($scope.policyHolder.IsMainDriver) {
                                    fn.utils.copyPHFieldsToMD();
                                    retrieveNamedDrivers();
                                }
                                else {
                                    retrieveMainDriver();
                                }

                                //fn.retrieveAccountUserObjects();
                            },
                            onError: function () {
                                $scope.loadingSpinner = false;
                                $rootScope.$broadcast('ENABLE_PUBLIC_FOOTER', true);
                                fn.retrieveAccountUserObjects();
                            }
                        }
                    );
                };

                var retrieveMainDriver = function () {
                    fn.mainDriverApi.retrieveMDPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.mainDriver, data);
                                if (data && data.Identifier) {
                                    HKFormValidation.splitIdentifier(data, $scope.mainDriver);
                                }
                                if ($scope.mainDriver.DateOfBirth) {
                                    fn.utils.ConvertMITDateToDisplay();
                                }
                                /* if ($scope.mainDriver.IsMainDriver) {
                                    $scope.policyHolder.IsMainDriver = false;
                                } */
                                if ($scope.asset.PlanId === 1000003) {
                                    retrieveNamedDrivers();
                                }
                            }
                        }
                    );
                };

                var retrieveNamedDrivers = function () {
                    fn.namedDriversApi.retrieveNDPolicyContacts(
                        {
                            onSuccess: function (dataArray) {
                                //console.log(dataArray);
                                if (!dataArray) {
                                    return;
                                }

                                angular.forEach(dataArray,
                                    function (data) {
                                        data.isForUpdate = true;
                                    }
                                );
                                //console.log("named driver");
                                //console.log(dataArray);

                                /** Need to enable this condition for VPP */
                                if (dataArray.length > 0) {
                                    //$scope.plan.coversNamed=1;
                                    //$scope.plan.coversNamedWho=0;
                                    $scope.dynamicForm.addedNamedDriver = true;
                                    $scope.dynamicForm.namedDriverAction = 'update';
                                    //console.log('update or delete ND');
                                }
                                //console.log($scope.dynamicForm.namedDriverAction);
                                /** End of condition */

                                $scope.namedDrivers.PolicyContacts.splice(0);
                                angular.extend($scope.namedDrivers.PolicyContacts, dataArray);
                                angular.forEach($scope.namedDrivers.PolicyContacts,
                                    function (obj) {
                                        if (obj.DateOfBirth) {
                                            fn.utils.ConvertNDMITDateToDisplay(obj);
                                            //fn.utils.ConvertMITDateToDisplay(obj);
                                        }

                                        $scope.namedDriver = obj; 
                                        if ($scope.namedDriver.Identifier) {
                                            HKFormValidation.splitIdentifier(obj, $scope.namedDriver);
                                            /*To solve some problem on policyHolder scope*/
                                        }
                                        
                                        
                                        
                                    }

                                );
                                

                            },
                            onError: function () {
                            }
                        }
                    );
                };

                //retrievePolicyHolder();
                retrieveCurrentProposal();
            },

            // Customize normal flow to compatible with SPA
            updatesCoveragesSPA: function () {
                fn.coverageApi.retrieveCoverages(
                    {
                        onSuccess: function (data) {
                            if(data.CoverListsCount == 5 || data.CoverListsCount == 10){
                                $scope.tyepOfCoverageTable = {
                                    "body_popup_cover_item1":[true, true, false, false],
                                    "body_popup_cover_item2":[true, true, true, true],
                                    "body_popup_cover_item3":[true, false, true, false],
                                    "body_popup_cover_item4":[true, false, false, false],
                                    "body_popup_cover_item5":[false, false, false, false],
                                    "body_popup_cover_item6":[true, true, false, false]
                                };
                            }else{
                                $scope.tyepOfCoverageTable = {
                                    "body_popup_cover_item1":[true, true, true, false, false],
                                    "body_popup_cover_item2":[true, true, true, true, true],
                                    "body_popup_cover_item3":[true, true, false, true, false],
                                    "body_popup_cover_item4":[true, true, false, false, false],
                                    "body_popup_cover_item5":[true, false, false, false, false],
                                    "body_popup_cover_item6":[true, true, true, false, false]
                                };
                            }
                            angular.extend($scope.coverages, data);
                            $scope.$parent.PlanId = $scope.asset.PlanId;

                            $scope.compulsory.isIncluded=fn.utils.IsCompulsarySelectedFrontEndFn();
                            var selectedCoverage = fn.utils.getSelectedCoverage();
                            if (selectedCoverage) {
                                fn.utils.setSelectedCoverage(selectedCoverage);
                                for(var i in selectedCoverage.Covers){
                                    if(selectedCoverage.Covers[i].CoverName === "Voluntary Basic Cover"){
                                        $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                        $scope.selectedCoverage.ExcessLevelId = $scope.ExcessLevelId;
                                    }
                                }
                            }
                            //console.log(selectedCoverage);
                            fn.utils.setSumInsured();

                            $scope.coverages.isForUpdate = true;
                            if(selectedCoverage){
                                if(selectedCoverage.CoverTypeId == 2000020){
                                    $scope.isCompulsoryOnly = true;
                                }else{
                                    $scope.isCompulsoryOnly = false;
                                }

                            }else{
                                $scope.isCompulsoryOnly = false;
                            }

                            retrieveExcess();
                        },
                        onError: function () {
                            retrieveExcess();
                        }
                    }
                );


                var retrieveExcess = function () {
                    fn.coverageApi.getVehicleExcess(
                        {
                            onSuccess: function (data) {
                                if (data.length > 0) {
                                    if (data[0].value) {
                                        $scope.allExcess = data[0].value;
                                        $scope.selectedCoverage.excess = data;
                                    }

                                }

                                $scope.loadingSpinner = false;
                                $scope.loadingSpinner2 = false;
                                $scope.loadingSpinner3 = false;
                                $scope.$parent.showPriceOnTopFlag = true;
                                $scope.allowBtnAction = true;
                            },
                            onError: function () {
                                $scope.loadingSpinner = false;
                                $scope.loadingSpinner2 = false;
                                $scope.loadingSpinner3 = false;
                                $scope.$parent.showPriceOnTopFlag = true;
                                $scope.allowBtnAction = true;
                            }
                        }
                    );
                };
            },

            retrieveAccountUserObjects: function () {
                var retrieveUser = function () {
                    if ($scope.$parent.isLoggedIn() && angular.equals({}, $scope.policyHolder)) {
                        fn.wfeApiUser.retrieveUser(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.accountUser, data);
                                    fn.utils.populateAccountUserModel();
                                },
                                onError: function () {
                                }
                            }
                        );
                    }
                };

                retrieveUser();
            },

            utils: {
                setSumInsured: function () {

                    $scope.sumInsured.value = 0;
                    $scope.sumInsured.visible = false;
                    var sc = fn.utils.getSelectedCoverage();

                    if(sc !== undefined && sc.PlanId !== $scope.asset.PlanId){
                        
                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { PlanId: $scope.asset.PlanId })[0];
                        sc = selectedCoverage;
                    }
                    if (!sc) { return; }
                    if (sc.CoverTypeId == 2000018 || sc.CoverTypeId == '2000018') {
                        /*Type 3 is always NA and hidden*/
                        $scope.sumInsured.visible = false;
                        $scope.sumInsured.value = 0;

                        return;
                    }

                    if (sc.CoverTypeId == 2000019 || sc.CoverTypeId == '2000019'
                        || sc.CoverTypeId == 2000017 || sc.CoverTypeId == '2000017'
                        || sc.CoverTypeId == 2000015 || sc.CoverTypeId == '2000015') {

                        /*Type 2+/ 3+ / Type 1 - value from own damage collision*/
                        /*Type 1- own damage collision value.*/
                        var odc = $filter('filter')(sc.Covers, {
                            ProductLineGroupId: 2000105
                        }, true);
                        if (odc && odc[0].InsuranceAmount) {

                            $scope.sumInsured.value = odc[0].InsuranceAmount;
                            $scope.sumInsured.visible = true;
                        }
                        else {
                            $scope.sumInsured.value = 0;
                            $scope.sumInsured.visible = false;
                        }

                        return;
                    }

                    if (sc.CoverTypeId == 2000016 || sc.CoverTypeId == '2000016') {

                        /*Type 2- own damage Fire and theft value*/
                        var odft = $filter('filter')(sc.Covers, {
                            ProductLineGroupId: 2000106
                        }, true);
                        if (odft && odft[0].InsuranceAmount) {

                            $scope.sumInsured.value = odft[0].InsuranceAmount;
                            $scope.sumInsured.visible = true;
                        }
                        else {
                            $scope.sumInsured.value = 0;
                            $scope.sumInsured.visible = false;
                        }
                        return;
                    }

                    if (sc.CoverTypeId == 2000020 || sc.CoverTypeId == '2000020') {
                        /*Type Compulsory only*/
                        $scope.sumInsured.visible = false;
                        $scope.sumInsured.value = 0;

                        return;
                    }

                    /*Exception- Everything fails- hide*/
                    $scope.sumInsured.visible = false;
                    $scope.sumInsured.value = 0;

                },

                setSelectedCoverage: function (selectedCoverage) {
                    $scope.selectedCoverage = selectedCoverage;
                    $scope.isBtnProceedDisabled = false;
                    $scope.isCoverSelected = true;
                },

                setDefaultCoverage: function () {
                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: 2000015 })[0];
                    selectedCoverage.IsSelected = true;
                    /* if (selectedCoverage && $scope.carAge < 8) {
                        selectedCoverage.IsSelected = true;
                    } else {
                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: 2000017 })[0];
                        selectedCoverage.IsSelected = true;
                    } */
                },

                IsCompulsarySelectedFrontEndFn: function () {
                    if (!$scope.coverages) return false;
                    var status = false;
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {

                        try {
                            var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                                ProductLineGroupId: 2000161,
                            }, true);
                            if (ccb) {
                                if (ccb[0].IsSelected) status = true;
                                else status = false;
                            }

                        }
                        catch (err) {
                            status = false; return status;
                        }
                    }
                    return status;
                },

                getSelectedCoverage: function () {

                    if (!$scope.coverages) {
                        return;
                    }
                    if (!$scope.coverages.Coverages) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { IsSelected: true })[0];

                    if (selectedCoverage) {
                        $scope.selectedCoverage = selectedCoverage;
                    }
                    else {
                        $scope.selectedCoverage = null;
                    }
                    

                    return selectedCoverage;
                },

                /** Doesn't use now */
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                autoSetEndDate: function () {

                    if (!$scope.currentProposal.PolicyStartDate) {
                        return;
                    }
                    var startDate = MITDate.newDate($scope.currentProposal.PolicyStartDate);
                    var endDate = startDate.add(366, 'days');
                    $scope.currentProposal.PolicyEndDate = MITDate.toAngularDate(endDate);
                },

                setIsSaving: function (isSaving) {
                    $scope.isSaving = isSaving;
                },

                copyMDFieldsToPH: function () {
                    $scope.policyHolder.DateOfBirth = $scope.mainDriver.DateOfBirth;
                    $scope.policyHolder.GenderId = $scope.mainDriver.GenderId;
                    $scope.policyHolder.MaritalStatusId = $scope.mainDriver.MaritalStatusId;
                    $scope.policyHolder.OccupationId = $scope.mainDriver.OccupationId;
                    $scope.policyHolder.DrivingExperienceId = $scope.mainDriver.DrivingExperienceId;
                    $scope.policyHolder.NrOfClaimsInPeriodId = $scope.mainDriver.NrOfClaimsInPeriodId;
                    $scope.policyHolder.InDriveWork = $scope.mainDriver.InDriveWork;
                    $scope.policyHolder.InCourseWork = $scope.mainDriver.InCourseWork;
                    $scope.policyHolder.DOB_DD = $scope.mainDriver.DOB_DD;
                    $scope.policyHolder.DOB_MON = $scope.mainDriver.DOB_MON;
                    $scope.policyHolder.DOB_Year = $scope.mainDriver.DOB_Year;
                    $scope.policyHolder.FirstName = $scope.mainDriver.FirstName;
                    $scope.policyHolder.LastName = $scope.mainDriver.LastName;
                    $scope.policyHolder.Identifier = $scope.mainDriver.Identifier;
                    $scope.policyHolder.ResidentialStatusId  = $scope.mainDriver.ResidentialStatusId ;
                    if($scope.mainDriver.ResidentialStatusId == 2000001){
                        $scope.policyHolder.NationalityId = $scope.mainDriver.NationalityId;
                    }
                    
                },

                copyPHFieldsToMD: function () {
                    $scope.mainDriver.DateOfBirth = $scope.policyHolder.DateOfBirth;
                    $scope.mainDriver.DOB_DD = $scope.policyHolder.DOB_DD;
                    $scope.mainDriver.DOB_MON = $scope.policyHolder.DOB_MON;
                    $scope.mainDriver.DOB_Year = $scope.policyHolder.DOB_Year;
                    $scope.mainDriver.GenderId = $scope.policyHolder.GenderId;
                    $scope.mainDriver.MaritalStatusId = $scope.policyHolder.MaritalStatusId;
                    $scope.mainDriver.OccupationId = $scope.policyHolder.OccupationId;
                    $scope.mainDriver.IsDemeritPoints = $scope.policyHolder.IsDemeritPoints;
                    $scope.mainDriver.InDriveWork = $scope.policyHolder.InDriveWork;
                    $scope.mainDriver.InCourseWork = $scope.policyHolder.InCourseWork;
                    $scope.mainDriver.FirstName = $scope.policyHolder.FirstName;
                    $scope.mainDriver.LastName = $scope.policyHolder.LastName;
                    $scope.mainDriver.ResidentialStatusId = $scope.policyHolder.ResidentialStatusId;
                    $scope.mainDriver.Identifier = $scope.policyHolder.Identifier;
                    $scope.mainDriver.NationalityId = $scope.policyHolder.NationalityId;
                    $scope.mainDriver.DrivingExperienceId = $scope.policyHolder.DrivingExperienceId;
                    $scope.mainDriver.NrOfClaimsInPeriodId = $scope.policyHolder.NrOfClaimsInPeriodId
                    fn.utils.ConvertMITDateToDisplay();
                },

                /** To push phContact and phAddress of existing user to scope object */
                populateAccountUserModel: function () {
                    angular.extend($scope.policyHolder, $scope.accountUser.Person);
                    $scope.policyHolder.IsMainDriver = true;
                    fn.utils.copyPHFieldsToMD();

                    if ($scope.accountUser.Address) {
                        var phAddress = $scope.accountUser.Address.Addresses[0];

                        if (phAddress) {
                            angular.extend($scope.phAddress, phAddress);
                            $scope.asset.ResidentialDistrictId = phAddress.ProvinceId;
                        }
                    }

                    if ($scope.accountUser.Contact) {
                        var contact = $scope.accountUser.Contact;

                        var PrimaryPhoneNumber = $filter('filter')(contact.Contacts, {
                            ContactType: 1,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryPhoneNumber) {
                            $scope.phContact.PrimaryPhoneNumber = PrimaryPhoneNumber.Value;
                        }

                        var PrimaryEmailAddress = $filter('filter')(contact.Contacts, {
                            ContactType: 0,
                            IsPreferred: 'true'
                        })[0];

                        if (PrimaryEmailAddress) {
                            $scope.phContact.PrimaryEmailAddress = PrimaryEmailAddress.Value;
                        } else {
                            $scope.phContact.PrimaryEmailAddress = "guest@directasia.com";
                        }
                    }
                },

                convertMITDate: function () {
                    if (typeof $scope.mainDriver.DOB_DD !== "undefined" &&
                        typeof $scope.mainDriver.DOB_MON !== "undefined" &&
                        typeof $scope.mainDriver.DOB_Year !== "undefined") {

                        $scope.$parent.setDateValidity($scope.mainDriver.DOB_DD, $scope.mainDriver.DOB_MON, $scope.mainDriver.DOB_Year, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.mainDriver.DateOfBirth = $scope.$parent.convertDateThreeToOne($scope.mainDriver.DOB_DD, $scope.mainDriver.DOB_MON, $scope.mainDriver.DOB_Year);
                        $scope.mainDriver.DateOfBirth == null ? ($scope.mainDriver.DateOfBirth = '') : '';
                        $scope.validations.isValidDriverAge($scope.mainDriver.DateOfBirth, $scope.frmAboutMainDriver.mainDriverDOBDD);
                        $scope.DOBEmpty = false;
                    }
                },

                convertPHMITDate: function () {
                    if (typeof $scope.policyHolder.DOB_DD !== "undefined" &&
                        typeof $scope.policyHolder.DOB_MON !== "undefined" &&
                        typeof $scope.policyHolder.DOB_Year !== "undefined") {

                        $scope.$parent.setDateValidity($scope.policyHolder.DOB_DD, $scope.policyHolder.DOB_MON, $scope.policyHolder.DOB_Year, $scope.frmAboutPH.policyHolderDOBDD);
                        $scope.policyHolder.DateOfBirth = $scope.$parent.convertDateThreeToOne($scope.policyHolder.DOB_DD, $scope.policyHolder.DOB_MON, $scope.policyHolder.DOB_Year);
                        $scope.policyHolder.DateOfBirth == null ? ($scope.policyHolder.DateOfBirth = '') : '';
                        //$scope.validations.isValidDriverAge($scope.policyHolder.DateOfBirth, $scope.frmAboutPH.policyHolderDOBDD);
                        $scope.DOBEmpty = false;
                    }
                },

                convertPolicyStartMITDate: function () {
                    if (typeof $scope.currentProposal.PolicyStartDate_DD !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_MON !== "undefined" &&
                        typeof $scope.currentProposal.PolicyStartDate_Year !== "undefined") {

                        $scope.$parent.setDateValidity($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year, $scope.frmPolicyStartDate.policyStartDateDD);

                        $scope.currentProposal.PolicyStartDate = $scope.$parent.convertDateThreeToOne($scope.currentProposal.PolicyStartDate_DD, $scope.currentProposal.PolicyStartDate_MON, $scope.currentProposal.PolicyStartDate_Year);
                        $scope.currentProposal.PolicyStartDate == null ? ($scope.currentProposal.PolicyStartDate = '') : '';
                        fn.utils.autoSetEndDate();
                    }
                },

                convertNDMITDate: function (nd, formElement) {
                    if (!nd) return;

                    if (typeof nd.DOB_DD !== "undefined" &&
                        typeof nd.DOB_MON !== "undefined" &&
                        typeof nd.DOB_Year !== "undefined") {

                        $scope.$parent.setDateValidity(nd.DOB_DD, nd.DOB_MON, nd.DOB_Year, formElement);
                        nd.DateOfBirth = $scope.$parent.convertDateThreeToOne(nd.DOB_DD, nd.DOB_MON, nd.DOB_Year);
                        nd.DateOfBirth == null ? (nd.DateOfBirth = '') : '';
                        $scope.$parent.isInvalidDriverAge(nd.DateOfBirth, formElement);
                    }
                },

                ConvertMITDateToDisplay: function () {
                    if ($scope.currentProposal.PolicyStartDate) {
                        var d = $scope.$parent.convertDateOneToThree($scope.currentProposal.PolicyStartDate);


                        if (d != null || d.length > 0) {
                            $scope.currentProposal.PolicyStartDate_DD = d[0];
                            $scope.currentProposal.PolicyStartDate_MON = d[1];
                            $scope.currentProposal.PolicyStartDate_Year = d[2];
                        }
                    } else {

                    }
                    if ($scope.policyHolder.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree($scope.policyHolder.DateOfBirth);
                        if (d != null || d.length > 0) {
                            $scope.policyHolder.DOB_DD = d[0];
                            $scope.policyHolder.DOB_MON = d[1];
                            $scope.policyHolder.DOB_Year = d[2];
                        }
                    }

                    if ($scope.mainDriver.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree($scope.mainDriver.DateOfBirth);
                        if (d != null || d.length > 0) {
                            $scope.mainDriver.DOB_DD = d[0];
                            $scope.mainDriver.DOB_MON = d[1];
                            $scope.mainDriver.DOB_Year = d[2];
                        }
                    }

                    if ($scope.namedDriver.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree($scope.namedDriver.DateOfBirth);
                        if (d != null || d.length > 0) {
                            $scope.namedDriver.DOB_DD = d[0];
                            $scope.namedDriver.DOB_MON = d[1];
                            $scope.namedDriver.DOB_Year = d[2];
                        }
                    }

                },
                getPriceToShow: function (c) {

                    if(!c) {return 0;}
                    //console.log(c.CoverTypeId);
                    var cx = $filter('filter')($scope.coverages.Coverages, {CoverTypeId: c.CoverTypeId, PlanId: $scope.asset.PlanId})[0];
                    var baseprice = cx.CalculatedPremium;
                    
                    var ccb = $filter('filter')(cx.Covers, {ProductLineGroupId: 2000161,IsSelected:true},true);
                    if(cx.CoverTypeId !== 2000020 && ccb.length === 0 ){
                        return baseprice;
                    }
                    if(ccb){ 
                        if(ccb[0]){
                            if(ccb[0].TotalPremiumToColl){
                                total = baseprice + ccb[0].TotalPremiumToColl;
                            }
                        }
                    }
                    return total;
                },
                filterOptionalButCompulsoryCovers: function (r) {
                    //console.log(r);

                    var status = false;

                    if (r.ProductLineType === '2' || r.ProductLineType === 2) {
                        if (r.ProductLineGroupId === 2000161 || r.ProductLineGroupId === 2000114) { //Complusory Basic Cove
                            status = false;
                        }
                        else {
                            if (r.IsSelected) {
                                status = true;
                            } else {
                                status = false;
                            }
                        }

                    } else {

                        if (r.ProductLineType === '0' || r.ProductLineType === 0) {

                            /*hides permanent disable for passenger as is merged with driver in copy*/
                            if (r.ProductLineGroupId === 2000101 || r.ProductLineGroupId === 2000102 || r.ProductLineGroupId === 2000112 || r.ProductLineGroupId === 2000107) {
                                status = false;
                            }
                            else {
                                /*always hide Compulsory Core Benefits, Core Benefits*/
                                if (r.ProductLineGroupId === 2000100 || r.ProductLineGroupId === 2000160) {
                                    status = false;
                                }
                                else {
                                    status = true;
                                }
                            }
                        } else {

                            if (r.ProductLineType === '1' || r.ProductLineType === 1) {
                                if (r.ProductLineGroupId === 2000103 || r.ProductLineGroupId === 2000116) { // VBC/MyWorkshop
                                    status = false;
                                }
                                else {
                                    status = true;
                                }
                            } else {
                                status = true;
                            }
                        }
                    }
                    //console.log(r.ProductLineGroupId + " >> " + status);
                    return status;
                },
                getAge: function (d) {
                    var today = new Date();
                    var birthDate = new Date(d);
                    var age = today.getFullYear() - birthDate.getFullYear();
                    var m = today.getMonth() - birthDate.getMonth();
                    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                        age--;
                    }

                    return age;
                },
                ConvertNDMITDateToDisplay: function (nd) {
                    if (nd.DateOfBirth) {
                        var d = $scope.$parent.convertDateOneToThree(nd.DateOfBirth);
                        if (d != null || d.length > 0) {
                            nd.DOB_DD = d[0];
                            nd.DOB_MON = d[1];
                            nd.DOB_Year = d[2];
                        }
                    }
                },
                getExcessValue: function(val){

                    if($scope.selectedCoverage){
                        if(val === undefined){
                            return $scope.selectedCoverage.ExcessAmount
                        }

                        var exc = $filter('filter')($scope.selectedCoverage.excess, {
                            key: val,
    
                        }, true);
                        //console.log(exc[0].value);
                        if(exc){
                            if(exc[0])
                            return exc[0].value;
                        }else{
                            return 0;
                        }
                    }else{
                        return 0;
                    }
                    
                        
                },
                setPlan:function(){
                    if($scope.totalPremium2 > 0){
                        fn.eventHandlers.updateDeductibleAsset();
                    }
                },
                coverageList: function(){
                    if($scope.coverages.Coverages){
                        //if($scope.coverages.Coverages.length === 6){
                            if($scope.asset.PlanId === 1000003 || $scope.asset.PlanId === 1000004){
                                return $filter('filter')($scope.coverages.Coverages, {PlanId: $scope.asset.PlanId });;
                            }
                        /* }else{
                            return $filter('filter')($scope.coverages.Coverages, {PlanId: 1000003 });;
                        } */
                    }
                },
                getInsuranceAmount: function(groupid){
                    if($scope.selectedCoverage){
                        var ins = $filter('filter')($scope.selectedCoverage.Covers, {ProductLineGroupId : groupid});
                        if(ins){
                            if(ins.length > 0){
                                return ins[0].InsuranceAmount;
                            }else{
                                return 0;
                            }
                        }
                        
                    }else{
                        return 0;
                    }
                    
                    
                },
                onUserMismatchHandler: function (errorInformation) {
                    if(!errorInformation){
                        return false;
                    }
                    var contactsNotUpdated = ['PrimaryPhoneNumber','PrimaryEmailAddress'];
                    var contactKeyDisplayMatch = {PrimaryEmailAddress:'body_user_email',PrimaryPhoneNumber:'body_user_mobile',
                        FirstName:'body_user_first_name',LastName:'body_user_lastname',MaritalStatusId:'body_policy_nd_marital',
                        HouseNumber:'body_address_house_number',PostalCode:'body_address_postal_code'};

                    $scope.userMismatchObject = errorInformation;
                    for (var i = 0; i<errorInformation.length;i++){
                        var personInfo = errorInformation[i];
                        if(personInfo){
                            if(personInfo.RoleName==='PH'){
                                personInfo.displayRoleName = 'body_title_policyholder_detail';
                            } else if(personInfo.RoleName==='MD'){
                                personInfo.displayRoleName = 'body_title_maindriver_detail';
                            }
                            personInfo.isEmailPhoneChanged = false;
                            var contactMismatchList = personInfo.MismatchList;
                            if(contactMismatchList){
                                for (var j = 0; j<contactMismatchList.length;j++) {
                                    var contactMismatchObject = contactMismatchList[j];
                                    contactMismatchObject.displayKey = contactKeyDisplayMatch[contactMismatchObject.Key];
                                    contactMismatchObject.additionalInfo = '';
                                    if(contactsNotUpdated.indexOf(contactMismatchObject.Key)>=0){
                                        personInfo.isEmailPhoneChanged = true;
                                        contactMismatchObject.additionalInfo = 'car_v_driver_mismatch_addtional_info';
                                    }
                                }
                            }
                        }
                    }
                },
                getExcessValueSummary: function () {
                    //console.log($scope.selectedCoverage);
                    //var KeyValuePairString = [{ "key": "7000974", "value": "0.00" }, { "key": "7000979", "value": "3000.00" }, { "key": "7000973", "value": "5000.00" }];
                    var KeyValuePairString = $scope.selectedCoverage.excess;
                    if ($scope.selectedCoverage !== undefined && $scope.selectedCoverage !== null) {
                        //console.log($scope.selectedCoverage.Covers);
                        var excessId = $filter('filter')($scope.selectedCoverage.Covers, { 'ProductLineGroupId': 2000103 });
                        //console.log(excessId);
                        if(excessId !== undefined){
                            var excessAmt = $filter('filter')(KeyValuePairString, { 'key': excessId[0].ExcessLevelId });
                            //console.log(excessAmt);
                            if(excessAmt !== undefined && excessAmt.length > 0){
                                return excessAmt[0].value
                            }else{
                                return
                            }
                            
                            
                        }else{
                            var excessAmt = $filter('filter')($scope.selectedCoverage.Covers, { 'ProductLineGroupId': 2000103 });
                            if(excessAmt !== undefined){
                                return excessAmt[0].ExcessAmount;
                            }
                            
                        }
                        
                    } else {
                        return 0;
                    }
                },
                isCMIIncluded: function(){
                    if($scope.selectedCoverage !== undefined && $scope.selectedCoverage !== null){
                        var chk = $filter('filter')($scope.selectedCoverage.Covers, {ProductLineGroupId: 2000161});
                        if(chk !== undefined && chk.length > 0){
                            var isCMIIncluded = chk[0].IsSelected;
                        }else{
                            return false;
                        }
                        
                    
                        return isCMIIncluded;
                    }
                    
                },
                isSelectedExcess: function(eKey){
                    $scope.selectedCoverage.excess
                    var chk = $filter('filter')($scope.selectedCoverage.excess, {key: eKey});
                    if(chk.length > 0){
                        var excessVal = chk[0].value.split(".")[0];
                    }
                    var chk2 = $filter('filter')($scope.selectedCoverage.Covers, {CoverName: "Voluntary Basic Cover"});
                    if(chk2.length > 0){
                        if(excessVal == chk2[0].ExcessAmount){
                            $scope.selectedCoverage.ExcessAmount = excessVal;
                            return true;
                        }else if(eKey == chk2[0].ExcessLevelId){
                            return true;
                        }else{
                            return false;
                        }
                    }
                }
            },

            saving: {

                setUsageId: function () {

                    if ($scope.asset.UsageId === "6") {
                        $scope.asset.InDriveWork = false;
                        $scope.asset.InCourseWork = false;
                        $scope.txtInCourseWork = 1;
                    } else if ($scope.asset.UsageId === "1000000") {
                        $scope.asset.InDriveWork = true;
                        $scope.asset.InCourseWork = false;
                        $scope.txtInCourseWork = 0
                    } else if ($scope.asset.UsageId === "1000002") {
                        $scope.asset.InDriveWork = true;
                        $scope.asset.InCourseWork = true;
                        $scope.txtInCourseWork = 2
                    } else {
                        $scope.asset.InDriveWork = undefined;
                        $scope.asset.InCourseWork = undefined;
                        $scope.txtInCourseWork = null;
                    }

                },

                /* savePHContact: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function () {
                                        if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                        {
                                            fn.confirmationApi.createConfirmation(
                                                {
                                                    onSuccess: function () {
                                                        fn.utils.setIsSaving(false);

                                                        DaModal.close('#quotationPartialSave');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        } else {

                                            fn.essentialApi.partialSave(
                                                {
                                                    onSuccess: function (data) {
                                                        fn.utils.setIsSaving(false);
                                                        DaModal.close('#quotationPartialSave');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                });

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                }, */

                savePHContact: function () {  //
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function () {
                                        fn.confirmationApi.createConfirmation(
                                            {
                                                onSuccess: function () {
                                                    fn.utils.setIsSaving(false);
                                                },
                                                onError: function () {
                                                    fn.utils.setIsSaving(false);
                                                }
                                            }
                                        );
                                    }
                                });

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                saveCurrentProposalChildModels: function () {

                    /**
                     * Section 1 : Create necessary objects, then pre calculate.
                     * */

                    var hasError = false;
                    var triggerSave = function () {
                        saveAsset();
                    };

                    var saveAsset = function () {
                        // Force my workshop as false;
                        $scope.asset.UseMyWorkshop = false;

                        fn.saving.setUsageId();
                        var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');

                        if (saveAssetFunc === "updateAsset") {
                            if ($scope.asset.NcdLevelId != "1000003") {
                                $scope.asset.ReasonForNcdIsZero = "0";
                                $scope.asset.NcdLevelIdOnOtherCar = "0";

                            }
                        }

                        fn.assetApi[saveAssetFunc](
                            {
                                onSuccess: function (data) {
                                    savePH(); /*hold on to Contact PH for Email and Mobile later.*/
                                },
                                onError: function () {
                                    hasError = true;
                                    savePH();
                                }
                            }
                        );
                    };

                    var savePH = function () {

                        //$scope.policyHolder.IsMainDriver = false;
                        /* if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        } */

                        if ($scope.HKLocale.getLocale() === "en-us") {
                            $scope.policyHolder.PreferredLanguageId = 101;
                        } else {
                            $scope.policyHolder.PreferredLanguageId = 2000000;
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function (data) {
                                    saveMD();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveMD();
                                }
                            }
                        );
                    };

                    var saveMD = function () {
                        //console.log($scope.mainDriver);
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');

                        if (!saveMDFunc) {
                            saveND();
                        }

                        fn.mainDriverApi[saveMDFunc](
                            {
                                onSuccess: function () {
                                    saveND();
                                },
                                onError: function () {
                                    hasError = true;
                                    saveND();
                                }
                            }
                        );
                    };

                    var saveND = function () {

                        var namedDriver = $scope.namedDriver;
                        console.log($scope.dynamicForm.namedDriverAction);
                        if ($scope.dynamicForm.addedNamedDriver) {
                                if($scope.dynamicForm.namedDriverAction === 'create'){ // For create ND
                                    namedDriver.isForUpdate = false;
                                    $scope.dynamicForm.namedDriverAction = 'update';
                                }else{  // For update ND
                                    namedDriver.isForUpdate = true;
                                    namedDriver.RoleName = "ND1";
                                }
                                namedDriver.isForDelete = false;

                        } else { // For delete ND
                                namedDriver.isForDelete = true;
                                namedDriver.isForUpdate = true;
                                namedDriver.RoleName = "ND1";
                                // back to re-create 
                                $scope.dynamicForm.namedDriverAction = 'create';
                        }

                            if (!namedDriver.RelationshipIdWithPH) {
                                namedDriver.RelationshipIdWithPH = 1000007;
                            }
                            var saveFunc = fn.saving.getSaveFunctionNameContact(namedDriver, 'NDPolicyContact');

                            if (!saveFunc) {
                                savePHContact();
                                return;
                            }

                            var namedDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, namedDriver);

                            namedDriverApi[saveFunc](
                                {
                                    onSuccess: function () {
                                        savePHContact();
                                    },

                                    onError: function () {
                                        savePHContact();
                                    }
                                }
                            );

                    }

                    var savePHContact = function () {
                        $scope.phContact.PrimaryEmailAddress = 'guest@directasia.com';
                        var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');
                        fn.phContactApi[savePHContactFunc](
                            {
                                onSuccess: function () {
                                    calculatePremium();
                                },
                                onError: function () {
                                    hasError = true;
                                    calculatePremium();
                                }
                            }
                        );
                    };

                    var calculatePremium = function () {
                        if (hasError) {
                            fn.eventHandlers.onDefaultErrorHandler();
                            return;
                        }

                        fn.quoteApi.calculate(
                            {
                                onSuccess: function () {
                                    retrieveCurrentProposal();
                                },
                                onError: function (data) {
                                    fn.utils.setIsSaving(false);
                                    fn.goToPage(1);
                                    try {
                                        if (!$scope.currentProposal.IsRenewalProposal) {
                                            $scope.bidata = {
                                                Source: "B2B"
                                            };
                                            $scope.errdata = data;
                                            
                                        }
                                    } catch (err) {

                                    }

                                    fn.eventHandlers.onDefaultErrorHandler();
                                }

                            }
                        );

                    };

                    /**
                     * Section 2 : Retrieve necessary objects
                     * */

                    var retrieveCurrentProposal = function () {
                        fn.currentProposalApi.retrieveCurrentProposal(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.currentProposal, data);
                                    $scope.allowBtnAction = true;
                                    try {
                                        if (!$scope.currentProposal.IsRenewalProposal) {
                                            $scope.bi.whereDidYouHereUs = 'None';
                                            angular.extend($scope.bidata, {
                                                //Source: $scope.$parent.bi.whereDidYouHereUs // Use GA source instead
                                                Source: "B2B"
                                            });
                                            //if ($scope.$parent.bi.whereDidYouHereUs && !$scope.$parent.bi.isDone) {
                                                fn.logApi.writeLog(
                                                    {
                                                        onSuccess: function () {
                                                        },
                                                        onError: function () {
                                                        }
                                                    }
                                                );
                                            //}
                                        }
                                    } catch (err) {
                                    }

                                    retrieveAsset();
                                },
                                onError: function () {
                                    retrieveAsset();
                                }
                            }
                        );
                    };

                    var retrieveAsset = function () {
                        fn.assetApi.retrieveAsset(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.asset, data);
                                    retrieveCoverages();
                                },
                                onError: function () {
                                    retrieveCoverages();
                                }
                            }
                        );
                    };

                    var retrieveCoverages = function () {
                        fn.coverageApi.retrieveCoverages(
                            {
                                onSuccess: function (data) {

                                    /** Added by OB For temporary coverages sequence*/
                                    var coverages = [];
                                    if(data.CoverListsCount == 5 || data.CoverListsCount == 10){
                                        //var index = [2000017, 2000019, 2000016, 2000018, 2000020];
                                        $scope.tyepOfCoverageTable = {
                                            "body_popup_cover_item1":[true, true, false, false],
                                            "body_popup_cover_item2":[true, true, true, true],
                                            "body_popup_cover_item3":[true, false, true, false],
                                            "body_popup_cover_item4":[true, false, false, false],
                                            "body_popup_cover_item5":[false, false, false, false],
                                            "body_popup_cover_item6":[true, true, false, false]
                                        };
                                    }else{
                                        //var index = [2000015, 2000017, 2000019, 2000016, 2000018, 2000020];
                                        $scope.tyepOfCoverageTable = {
                                            "body_popup_cover_item1":[true, true, true, false, false],
                                            "body_popup_cover_item2":[true, true, true, true, true],
                                            "body_popup_cover_item3":[true, true, false, true, false],
                                            "body_popup_cover_item4":[true, true, false, false, false],
                                            "body_popup_cover_item5":[true, false, false, false, false],
                                            "body_popup_cover_item6":[true, true, true, false, false]
                                        };
                                    }

                                    /* for (var j in index) {
                                        for (var i in data.Coverages) {
                                            if (data.Coverages[i].CoverTypeId === index[j]) {
                                                coverages[j] = data.Coverages[i];
                                            }
                                        }
                                    }

                                    data.Coverages = coverages; */
                                    /** ------------------------ */

                                    angular.extend($scope.coverages, data);
                                    $scope.compulsory.isIncluded = fn.utils.IsCompulsarySelectedFrontEndFn();
                                    var selectedCoverage = fn.utils.getSelectedCoverage();
                                    if (selectedCoverage) {
                                        fn.utils.setSelectedCoverage(selectedCoverage);

                                        /** Added by OB for selected coverages*/
                                        for (var i in selectedCoverage.Covers) {
                                            if (selectedCoverage.Covers[i].CoverName === "Voluntary Basic Cover") {
                                                $scope.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                                $scope.selectedCoverage.ExcessLevelId = selectedCoverage.Covers[i].ExcessLevelId;
                                            }
                                        }
                                        /** ------------------------ */
                                    }else {
                                        fn.utils.setDefaultCoverage();
                                    }
                                    fn.utils.setSumInsured();

                                    $scope.coverages.isForUpdate = true;
                                    if (selectedCoverage) {
                                        if (selectedCoverage.CoverTypeId == 2000020) {
                                            $scope.isCompulsoryOnly = true;
                                        } else {
                                            $scope.isCompulsoryOnly = false;
                                        }

                                    } else {
                                        $scope.isCompulsoryOnly = false;
                                    }

                                    //fn.utils.closeIsSaving();
                                    //retrieveInsuranceAmount();
                                    retrievePHContact();
                                },
                                onError: function () {
                                    retrievePHContact();
                                }
                            }
                        );
                    };

                    var retrievePHContact = function () {
                        fn.phContactApi.retrievePHContact(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.phContact, data);
                                    if ($scope.coverages.CoverListsCount == 5) {
                                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: 2000017 })[0];
                                    } else {
                                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: 2000015 })[0];
                                    }
    
                                    if (selectedCoverage.IsSelected) {
                                        retrieveExcess();
                                    } else {
                                        $scope.loadingSpinner = false;
                                        retrievePolicyHolder();
                                    }

                                },
                                onError: function () {
                                    if ($scope.coverages.CoverListsCount == 5) {
                                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: 2000017 })[0];
                                    } else {
                                        var selectedCoverage = $filter('filter')($scope.coverages.Coverages, { CoverTypeId: 2000015 })[0];
                                    }
                                    if (selectedCoverage.IsSelected) {
                                        retrieveExcess();
                                    } else {
                                        $scope.loadingSpinner = false;
                                        $scope.loadingSpinner2 = false;
                                        $scope.loadingSpinner3 = false;
                                        $scope.$parent.showPriceOnTopFlag = true;
                                        retrievePolicyHolder();
                                    }
                                }
                            }
                        );
                    };

                    var retrieveExcess = function () {
                        fn.coverageApi.getVehicleExcess(
                            {
                                onSuccess: function (data) {
                                    if (data && data[0].value) {
                                        $scope.allExcess = data[0].value;
                                        $scope.selectedCoverage.excess = data;
                                    }

                                    $scope.loadingSpinner = false;
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    retrievePolicyHolder();
                                },
                                onError: function () {
                                    $scope.loadingSpinner = false;
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    retrievePolicyHolder();
                                }
                            }
                        );
                    };

                    /** PolicyContact retrieval: this will retrieve MD if PH is not MD */
                    var retrievePolicyHolder = function () {
                        fn.policyHolderApi.retrievePHPolicyContact(
                            {
                                onSuccess: function (data) {

                                    if (data && data.Identifier) {
                                        HKFormValidation.splitIdentifier(data, $scope.policyHolder);
                                    }

                                    angular.extend($scope.policyHolder, data);
                                    if ($scope.policyHolder.HasUnsubscribedMarketingEmail) {
                                        $scope.policyHolder.SubscribedMarketingEmail = false;
                                    } else {
                                        $scope.policyHolder.SubscribedMarketingEmail = true;
                                    }
                                    if ($scope.policyHolder.DateOfBirth) {
                                        fn.utils.ConvertMITDateToDisplay();
                                    }

                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                    if (data === undefined) {
                                        retrieveMainDriver();
                                        //fn.saving.saveCoveragesModel(preCallbacksA);
                                    }

                                    if (data.IsMainDriver) {
                                        fn.utils.copyPHFieldsToMD();
                                        //fn.saving.saveCoveragesModel(preCallbacksA);
                                    } else {
                                        retrieveMainDriver();
                                        //fn.saving.saveCoveragesModel(preCallbacksA);
                                    }

                                },
                                onError: function () {
                                    $scope.loadingSpinner2 = false;
                                    $scope.loadingSpinner3 = false;
                                    $scope.$parent.showPriceOnTopFlag = true;
                                }
                            }
                        );
                    };

                    var retrieveMainDriver = function () {
                        fn.mainDriverApi.retrieveMDPolicyContact(
                            {
                                onSuccess: function (data) {
                                    angular.extend($scope.mainDriver, data);
                                    if ($scope.mainDriver.DateOfBirth) {
                                        fn.utils.ConvertMITDateToDisplay();
                                    }
                                    if ($scope.mainDriver.IsMainDriver) {
                                        $scope.policyHolder.IsMainDriver = false;
                                    }

                                }
                            }
                        );
                    };


                    /**
                     * Section 3 : Update & Final calculate & Partial Save
                     * */
                    var preCallbacksA = {
                        onSuccess: function () {
                            fn.updateAssetMaxarPriority(callbacksA);
                        }
                    }

                    var callbacksA = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacksB);

                        }
                    };

                    var callbacksB = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPH(callbacksC);
                                    },
                                    onError: function () {
                                        fn.saving.saveOtherDetailsPH(callbacksC);
                                    }
                                }
                            );
                        }
                    };

                    var callbacksC = {
                        onSuccess: function () {
                            fn.updatesCoveragesSPA();
                        }
                    };

                    triggerSave();
                },

                getSaveFunctionName: function (model, functionName) {
                    var saveFunc = model.isForUpdate ? 'update' : 'create';
                    return saveFunc + functionName;
                },

                calculatePremium: function (callbacks) {
                    fn.quoteApi.calculate(callbacks);
                },

                saveOtherDetailsPH: function (callbacks) {
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                /*proceed if pnumber else ps.*/
                                if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                {
                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                } else {
                                    fn.utils.setIsSaving(false);
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                savePHAddress : function (callbacks) {
                    var savePHAddressFunc = fn.phAddressApi.utils.getSaveFunctionName($scope.phAddress, 'PHAddress');

                    fn.phAddressApi[savePHAddressFunc](
                        {
                            onSuccess: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                hasError = true;
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            }
                        }
                    );
                },

                saveOtherDetailsPartSave: function (callbacks) {
                    fn.utils.setIsSaving(false);
                    fn.essentialApi.partialSave(
                        {
                            onSuccess: function (data) {

                                $scope.partialPolicyNumber = data['QuoteNumber'];
                                fn.utils.setIsSaving(false);

                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                                fn.currentProposalApi.retrieveCurrentProposal(
                                    {
                                        onSuccess: function (data) {
                                            angular.extend($scope.currentProposal, data);
                                            //retrieveExcess();
                                            fn.updatesCoveragesSPA();
                                            fn.utils.setIsSaving(false);
                                        },
                                        onError: function () {
                                            //retrieveExcess();
                                            fn.utils.setIsSaving(false);
                                        }
                                    }
                                );
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );

                    /* var retrieveExcess = function () {
                        fn.coverageApi.getVehicleExcess(
                            {
                                onSuccess: function (data) {
                                    if (data && data[0].value)
                                        $scope.allExcess = data[0].value;
                                    //$scope.loadingSpinner = false;
                                    
                                },
                                onError: function () {
                                    //$scope.loadingSpinner = false;
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    }; */
                },

                confirmProposal: function(callbacks){
                    fn.confirmationApi.createConfirmation(
                        {
                            onSuccess: function () {
                                if (callbacks && callbacks.onSuccess) {
                                    callbacks.onSuccess();
                                }
                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                issuePolicy: function (callbacks) {
                    fn.confirmationApi.issuePolicy({
                        onSuccess: function () {
                            if (callbacks && callbacks.onSuccess) {
                                callbacks.onSuccess();
                            }
                        },
                        onError: function () {
                        }
                    });
                },

                saveCoveragesModel: function (callbacks) {

                    var triggerSave = function () {
                        saveCoverages();
                    };

                    var saveCoverages = function () {
                        var saveCoverageFunc = fn.saving.getSaveFunctionName($scope.coverages, 'Coverages');
                        fn.coverageApi[saveCoverageFunc](
                            {
                                onSuccess: function () {
                                    $scope.coverages.isForUpdate = true;

                                    if (callbacks && callbacks.onSuccess) {
                                        callbacks.onSuccess();
                                    }
                                },
                                onError: function () {
                                    fn.utils.setIsSaving(false);
                                }
                            }
                        );
                    };

                    triggerSave();
                },

                savePHContactToProceed: function (callbacks) {

                    fn.utils.setIsSaving(true);
                    var savePH = function () {
                        if ($scope.policyHolder.IsMainDriver) {
                            fn.utils.copyMDFieldsToPH();
                            fn.policyHolderApi.utils.markForDelete($scope.mainDriver);
                        }
                        else {
                            fn.policyHolderApi.utils.removeMarkForDelete($scope.mainDriver);
                        }
                        if($scope.policyHolder.IsMainDriver){  // PH == MD
                            $scope.policyHolder.ConsentId = 7000000;
                            $scope.mainDriver.ConsentId = 7000000;
                        }else{  // PH != MD
                            $scope.policyHolder.ConsentId = 7000002;
                            $scope.mainDriver.ConsentId = 7000000;
                        }
                        var tmpId = $scope.policyHolder.Identifier;
                        if(tmpId){
                            $scope.policyHolder.Identifier = tmpId.replace(/-/g, "");
                        }

                        var savePHFunc = fn.policyHolderApi.utils.getSaveFunctionName($scope.policyHolder, 'PHPolicyContact');
                        fn.policyHolderApi[savePHFunc](
                            {
                                onSuccess: function () {
                                    saveMD();
                                },
                                onError: function () {
                                    saveMD();
                                }
                            }
                        );
                    }

                    var saveMD = function () {
                        
                        var tmpId = $scope.mainDriver.Identifier;
                        if(tmpId){
                            $scope.mainDriver.Identifier = tmpId.replace(/-/g, "");
                        }
                        
                        var saveMDFunc = fn.mainDriverApi.utils.getSaveFunctionName($scope.mainDriver, 'MDPolicyContact');

                        if (saveMDFunc === null) {
                            saveND();
                        }else{
                            fn.mainDriverApi[saveMDFunc](
                                {
                                    onSuccess: function () {
                                        saveND();
                                    },
                                    onError: function () {
                                        hasError = true;
                                        saveND();
                                    }
                                }
                            );
                        }

                        
                    };

                    var saveND = function () {
                        var tmpId = $scope.namedDriver.Identifier;
                        if(tmpId){
                            $scope.namedDriver.Identifier = tmpId.replace(/-/g, "");
                        }
                        
                        var namedDriver = $scope.namedDriver;

                        if ($scope.dynamicForm.addedNamedDriver) {
                                if($scope.dynamicForm.namedDriverAction === 'create'){ // For create ND
                                    namedDriver.isForUpdate = false;
                                    $scope.dynamicForm.namedDriverAction = 'update';
                                }else{  // For update ND
                                    namedDriver.isForUpdate = true;
                                    namedDriver.RoleName = "ND1";
                                }
                                namedDriver.isForDelete = false;

                        } else { // For delete ND
                                namedDriver.isForDelete = true;
                                namedDriver.isForUpdate = true;
                                namedDriver.RoleName = "ND1";
                                // back to re-create 
                                $scope.dynamicForm.namedDriverAction = 'create';
                        }

                            if (!namedDriver.RelationshipIdWithPH) {
                                namedDriver.RelationshipIdWithPH = 1000007;
                            }
                            var saveFunc = fn.saving.getSaveFunctionNameContact(namedDriver, 'NDPolicyContact');

                            if (!saveFunc) {
                                callbacks.onSuccess();
                                return;
                            }

                            var namedDriverApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, namedDriver);

                            namedDriverApi[saveFunc](
                                {
                                    onSuccess: function () {
                                        callbacks.onSuccess();
                                    },

                                    onError: function () {
                                        callbacks.onSuccess();
                                    }
                                }
                            );

                    }

                    savePH();
                },

                savePHContactCallback: function () {
                    fn.utils.setIsSaving(true);
                    var savePHContactFunc = fn.phContactApi.utils.getSaveFunctionName($scope.phContact, 'PHContact');

                    fn.phContactApi[savePHContactFunc](
                        {
                            onSuccess: function () {
                                //if ($scope.$parent.isLoggedIn())
                                fn.updateAssetMaxarPriority({
                                    onSuccess: function () {
                                        if ($scope.currentProposal.StatusCodeId !== undefined && $scope.currentProposal.StatusCodeId === 10)//If Proposal
                                        {
                                            fn.confirmationApi.createConfirmation(
                                                {
                                                    onSuccess: function () {
                                                        fn.utils.setIsSaving(false);

                                                        DaModal.close('#quotationCallback');
                                                    },
                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        } else {

                                            fn.essentialApi.partialSave(
                                                {
                                                    onSuccess: function (data) {

                                                        fn.utils.setIsSaving(false);

                                                        DaModal.close('#quotationCallback');
                                                    },

                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                    }
                                                }
                                            );
                                        }
                                    }
                                });

                            },
                            onError: function () {
                                fn.utils.setIsSaving(false);
                            }
                        }
                    );
                },

                getSaveFunctionNameContact: function (model, functionName) {
                    if (model.isForUpdate && model.isForDelete) {
                        return 'deletePolicyContact';
                    }
                    else if (model.isForDelete) {
                        return null;
                    }

                    if (model.isForUpdate && !model.isForDelete) {
                        return 'updatePolicyContact';
                    }

                    return 'create' + functionName;
                },
                isUserExists: function (callbacks) {
                    callbacks = callbacks || {};

                    fn.essentialApi.isUserExist({
                        onSuccess: function (data) {
                            if (!data.success) {
                                if (callbacks.onError) {
                                    callbacks.onError(data);
                                }
                                return;
                            }

                            var cvrList = [];
                            angular.forEach(data.persons, function (personItem) {
                                var person = personItem.Value;
                                if ((!person) || (!person.CVR)) {
                                    return;
                                }
                                person.CVR.RoleName = personItem.Key;
                                cvrList.push(person.CVR);
                            });

                            var isPromptNeeded = fn.essentialApi.isPromptNeeded(cvrList);

                            if (isPromptNeeded) {
                                if (callbacks.onError) {
                                    callbacks.onError(cvrList);
                                }
                                return;
                            }

                            if (callbacks.onSuccess) {
                                callbacks.onSuccess(cvrList);
                            }

                        },

                        onError: function () {
                            if (callbacks.onError) {
                                DaModal.open('#userMismatchValidation');
                                //this will trigger generic oops
                                fn.utils.setIsSaving(false);
                            }
                        }
                    });

                },

            },

            validations: {
                isCoverNotAvail: function (coverageTypeId) {
                    return HKVehicleValidationService.isCoverNotAvail($scope.asset.PurchasingYear, 1, coverageTypeId);
                },

                checkNCDLevel: function () {
                    if ($scope.asset.NcdLevelId !== '1000003' || $scope.asset.NcdLevelId != 1000003) {
                        $scope.asset.ReasonForNcdIsZero = '';
                        $scope.asset.NcdLevelIdOnOtherCar = '';
                    } else {

                    }

                    if ($scope.asset.NcdLevelId == 1000009 || $scope.asset.NcdLevelId == "1000009") {
                        $scope.$parent.applyNCBRootDontKnow = true;
                    } else {
                        $scope.$parent.applyNCBRootDontKnow = false;
                    }
                },

                checkDOBEmpty: function () {
                    if (typeof $scope.mainDriver.DOB_DD === 'undefined' || typeof $scope.mainDriver.DOB_MON === 'undefined' || typeof $scope.mainDriver.DOB_Year === 'undefined') {
                        $scope.DOBEmpty = true;
                    }
                },

                checkMake: function () {
                    if ($scope.asset.ManufactureId == "0000000" || $scope.asset.ManufactureId == 0000000) {
                        $scope.frmAboutCar.vehicleMake.$setValidity("valid", false);
                    }
                    else {
                        $scope.frmAboutCar.vehicleMake.$setValidity("valid", true);
                    }
                },

                isStartDateInvalid: function () {
                    if (!$scope.currentProposal.PolicyStartDate) return;
                    var startDate = MITDate.newDate($scope.currentProposal.PolicyStartDate);
                    var isMinConditionValid = startDate.diff($scope.policyMinStartDate, 'days') >= 0;
                    var isMaxConditionValid = startDate.diff($scope.policyMaxStartDate, 'days') <= 0;
                    if (!isMinConditionValid) {
                        $scope.frmPolicyStartDate.policyStartDateDD.$setValidity('custom', true);
                        return true;
                    }

                    if (isMaxConditionValid) {
                        $scope.frmPolicyStartDate.policyStartDateDD.$setValidity('custom', true);
                        return false;

                    } else {
                        $scope.frmPolicyStartDate.policyStartDateDD.$setValidity('custom', false);
                        return true;
                    }
                },

                isEndDateInvalid: function (formElement) {
                    return CountryMotorQuoteValidation.isInvalidEndDate($scope.currentProposal.PolicyEndDate, $scope.currentProposal.PolicyStartDate, formElement);
                },

                validateForms: function () {
                    return $scope.frmAboutMainDriver.$invalid || $scope.frmAboutCar.$invalid || $scope.frmContactInfo.$invalid;
                },

                validateForms2: function () {
                    return $scope.frmAboutMotor.$invalid || $scope.frmAboutMainDriver2.$invalid || ($scope.frmAboutNamedDriver2.$invalid && $scope.dynamicForm.addedNamedDriver) || $scope.frmAboutPH.$invalid || $scope.frmYourAdress.$invalid;
                },

                hasInvalid: function hasInvalid() {
                    var isInvalid = false;

                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    if(fn.validations.validateNDForms()){
                        isInvalid = true;
                    }

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                    if (isInvalid) {
                        $timeout(focusError, 100);
                    }

                    return isInvalid;
                },

                checkDrivingExp: function () {
                    /*lessThanTwoDrivingExp*/
                    if ($scope.mainDriver.DrivingExperienceId) {
                        if ($scope.mainDriver.DrivingExperienceId == 1 || $scope.mainDriver.DrivingExperienceId == "1"
                            || $scope.mainDriver.DrivingExperienceId == 2 || $scope.mainDriver.DrivingExperienceId == "2") {
                            //Less than 2 driving experiencee.
                            $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', false);
                            return;
                        }
                    }
                    $scope.frmAboutMainDriver.mainDriverDrivingExp.$setValidity('lt2dexp', true);
                },
                isTHIDValidPH: function () {
                    if ($scope.policyHolder.Identifier)
                        return HKFormValidation.validateTHID($scope.policyHolder.Identifier);
                },

                isTHIDValidMD: function () {
                    if ($scope.mainDriver.Identifier)
                        return HKFormValidation.validateTHID($scope.mainDriver.Identifier);
                },

                isTHIDValidND: function () {
                    if ($scope.namedDriver.Identifier)
                        return HKFormValidation.validateTHID($scope.namedDriver.Identifier);

                },
                validateNDForms: function (frmAboutNamedDrivers) {
                    return $scope.frmAboutNamedDriver.$invalid;
                },
                validateNDForms2: function (frmAboutNamedDrivers2) {
                    return $scope.frmAboutNamedDriver2.$invalid;
                },
                checkNoOfAcc: function () {
                    /*lessThanTwoDrivingExp*/
                    if ($scope.mainDriver.NrOfClaimsInPeriodId) {
                        if ($scope.mainDriver.NrOfClaimsInPeriodId == 1000005 || $scope.mainDriver.NrOfClaimsInPeriodId == "1000005"
                            || $scope.mainDriver.NrOfClaimsInPeriodId == 1000013 || $scope.mainDriver.NrOfClaimsInPeriodId == "1000013"
                            || $scope.mainDriver.NrOfClaimsInPeriodId == 1000014 || $scope.mainDriver.NrOfClaimsInPeriodId == "1000014"
                            || $scope.mainDriver.NrOfClaimsInPeriodId == 1000007 || $scope.mainDriver.NrOfClaimsInPeriodId == "1000007") {
                            //Less than 2 driving experiencee.
                            $scope.frmAboutMainDriver.accidentsInThreeYearsMD.$setValidity('noOfAcci', false);
                            return;
                        }
                    }
                    $scope.frmAboutMainDriver.accidentsInThreeYearsMD.$setValidity('noOfAcci', true);
                },

                checkNoOfAccND: function (nd, formElement) {
                    /*lessThanTwoDrivingExp*/
                    if (nd.NrOfClaimsInPeriodId) {
                        if (nd.NrOfClaimsInPeriodId == 1000005 || nd.NrOfClaimsInPeriodId == "1000005"
                            || nd.NrOfClaimsInPeriodId == 1000013 || nd.NrOfClaimsInPeriodId == "1000013"
                            || nd.NrOfClaimsInPeriodId == 1000014 || nd.NrOfClaimsInPeriodId == "1000014"
                            || nd.NrOfClaimsInPeriodId == 1000007 || nd.NrOfClaimsInPeriodId == "1000007"
                            || nd.NrOfClaimsInPeriodId == 1000007 || nd.NrOfClaimsInPeriodId == "1000007") {
                            /*Less than 2 driving experience.*/
                            DaModal.open("#NoofAccidents");
                            formElement.$setValidity('noOfAcci', false);
                            return;
                        }
                    }
                    formElement.$setValidity('noOfAcci', true);
                },
                checkDrivingExpND:function(){
                    $scope.DrivingExperienceError = false;
                    console.log()
                    if($scope.namedDriver.DrivingExperienceId)
                    {
                        if($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2" )
                        {
                            //Less than 2 driving experiencee.
                            $scope.DrivingExperienceError = true;
                            return;
                        }
                    }
                    $scope.namedDriver.DrivingExperienceError = false;
                },

            },

            eventHandlers: {

                updateDeductibleAsset: function () {
                    $scope.loadingSpinner3 = true;
                    $scope.$parent.showPriceOnTopFlag = false;
                    
                    // selected deductible button with "Type 1" for B2B
                    for (var i in $scope.coverages.Coverages) {
                        if ($scope.coverages.Coverages[i].CoverTypeId === 2000015) {
                            $scope.coverages.Coverages[i].ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                            for (var j in $scope.coverages.Coverages[i].Covers) {
                                if ($scope.coverages.Coverages[i].Covers[j].CoverName === "Voluntary Basic Cover") {
                                    $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                                }
                            }
                        }
                    }

                    // selected deductible button with "Type 1 Save" for B2B
                    for (var i in $scope.coverages.Coverages) {
                        if ($scope.coverages.Coverages[i].CoverTypeId === 7000114) {
                            $scope.coverages.Coverages[i].ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                            for (var j in $scope.coverages.Coverages[i].Covers) {
                                if ($scope.coverages.Coverages[i].Covers[j].CoverName === "Voluntary Basic Cover") {
                                    $scope.coverages.Coverages[i].Covers[j].ExcessLevelId = $scope.selectedCoverage.ExcessLevelId;
                                }
                            }
                        }
                    }

                    fn.saving.saveCoveragesModel({
                        onSuccess: function(){
                            fn.assetApi.updateAsset({
                                onSuccess: function () {
                                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                                        //$scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                                        fn.currentProposalApi[saveCurrentProposalFunc]({
                                            onSuccess: function (){ 
                                                fn.saving.calculatePremium({
                                                    onSuccess: function () {
                                                        fn.updatesCoveragesSPA(); /// retrieve
                                                        fn.utils.setIsSaving(false);
                                                        // test set plan here
                                                        //fn.utils.setPlan();
                                                    },

                                                    onError: function () {
                                                        fn.utils.setIsSaving(false);
                                                        // $scope.loadingSpinner3 = false;
                                                    }
                                                })
                                            }
                                        })
                                    }
                                    
                                });
                            },
                            onError: function () {

                            }
                    });
                },
                onFullDetailClicked: function (totalNum) {
                    if ($scope.coverDetailsClicked)
                        $scope.coverNum = totalNum;
                    else
                        $scope.coverNum = 0;

                    $scope.coverDetailsClicked = !$scope.coverDetailsClicked;
                },
                onNCBApplyRequest: function (bool) {
                    $scope.applyNCB = bool;
                    if ($scope.applyNCB) {
                        $scope.asset.NcdLevelId = "";
                        $scope.asset.ReasonForNcdIsZero = "";
                        $scope.asset.NcdLevelIdOnOtherCar = "";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    } else {
                        $scope.asset.NcdLevelId = "1000003";
                        $scope.asset.ReasonForNcdIsZero = "1000004";
                        $scope.asset.NcdLevelIdOnOtherCar = "0";
                        $scope.$parent.applyNCBRoot = $scope.applyNCB;
                    }
                },

                onPolicyStartDateChanged: function () {
                    fn.utils.autoSetEndDate();
                },

                onDefaultErrorHandler: function () {
                    fn.utils.setIsSaving(false);
                },

                onBtnCalculatePremiumClicked: function () {

                    // Set source channel here
                    fn.setSourceChannels();
                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var carAge = chkDate.getFullYear() - $scope.asset.PurchasingYear;
                    var ncdLevel = 0;
                    if (carAge == 0) {  // 0% For vehicle age 0 year
                        ncdLevel = 0;
                        $scope.asset.NcdLevelId = "1000003";
                    } else if (carAge == 1) { // 20% For vehicle age 1 year
                        ncdLevel = 20;
                        $scope.asset.NcdLevelId = "1000005";
                    } else if (carAge > 1) {  // 30% Vehicle age > 1 year
                        ncdLevel = 30;
                        $scope.asset.NcdLevelId = "1000006";
                    } else {
                        ncdLevel = 0;
                        $scope.asset.NcdLevelId = "1000003";
                    }

                    $scope.carAge = carAge;

                    $scope.pageSubmitted = true;

                    if (fn.validations.hasInvalid()) {
                        //DaModal.open("#formInvalid");  implement later
                        return;
                    }

                    if(fn.validations.isStartDateInvalid()){
                        return;
                    }

                    if(!fn.eventHandlers.isAcceptOccu($scope.mainDriver.OccupationId)){
                        DaModal.open("#mainDriverOccuNotAccept");
                        return
                    }

                    if(!fn.eventHandlers.isAcceptOccu($scope.namedDriver.OccupationId)){
                        DaModal.open("#namedDriverOccuNotAccept");
                        return
                    }

                    if ($scope.asset.PlanId === 1000003) {  // Named driver

                        if ($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2") {
                            return;
                        }

                    }

                    if ($scope.asset.UsageId === "0") {
                        DaModal.open("#usedForCommercial");
                        return;
                    }
                    fn.utils.setIsSaving(false);

                    $scope.allowBtnAction = false;


                    $scope.loadingSpinner2 = true;
                    $scope.loadingSpinner3 = true;
                    $scope.$parent.showPriceOnTopFlag = false;

                    fn.saving.setUsageId();
                    /* $scope.firstPageShow = true;
                    $scope.secondPageShow = true; */
                    fn.goToPage(2);
                    fn.setPreviousPage(1);

                    fn.utils.setIsSaving(true);
                    var callbacks = {
                        onSuccess: function () {
                            fn.saving.saveCurrentProposalChildModels();
                        },
                        onError: function () {
                            fn.eventHandlers.onDefaultErrorHandler();
                        }
                    };

                    $scope.asset.PastInsuranceContactExtNum = "90000";
                    $scope.asset.PrevNcdLevelId = $scope.asset.NcdLevelId;
                    $scope.asset.LeadTypeId = 7000027; //7000004;

                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                    $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                    fn.currentProposalApi[saveCurrentProposalFunc](callbacks);
                },

                onBtnBackClicked: function () {
                    fn.goToPage(1);
                    fn.setPreviousPage(2);

                },
                onBtnBackClicked2: function () {
                    fn.goToPage(2);
                    fn.setPreviousPage(3);

                },
                onBtnBackClicked3: function () {
                    fn.goToPage(3);
                    fn.setPreviousPage(4);

                },
                onBtnNextClicked: function () {
                    

                    fn.utils.setIsSaving(true);
                    if (fn.validations.hasInvalid()) {
                        //DaModal.open("#formInvalid");  implement later
                        return;
                    }

                    if(fn.validations.isStartDateInvalid()){
                        return;
                    }

                    if(!fn.eventHandlers.isAcceptOccu($scope.mainDriver.OccupationId)){
                        DaModal.open("#mainDriverOccuNotAccept");
                        return
                    }

                    if(!fn.eventHandlers.isAcceptOccu($scope.namedDriver.OccupationId)){
                        DaModal.open("#namedDriverOccuNotAccept");
                        return
                    }

                    if ($scope.asset.PlanId === 1000003) {  // Named driver

                        if ($scope.namedDriver.DrivingExperienceId == "1" || $scope.namedDriver.DrivingExperienceId == "2") {
                            return;
                        }

                    }
                    if ($scope.asset.UsageId === "0") {
                        DaModal.open("#usedForCommercial");
                        return;
                    }

                    fn.goToPage(3);
                    fn.setPreviousPage(2);
                    $scope.pageSubmitted = true;
                    $scope.loadingSpinner = true;
                    $scope.$parent.showPriceOnTopFlag = false;
                    var preCallbacks1 = {
                        onSuccess: function () {
                            fn.saving.saveCoveragesModel(preCallbacks2);
                        }
                    };

                    var preCallbacks2 = {
                        onSuccess: function () {
                            fn.updateAssetMaxarPriority(callbacks1);
                        }
                    };

                    var callbacks1 = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacks2);
                        }
                    };

                    var callbacks2 = {
                        onSuccess: function () {
                            
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPartSave(callbacks3);
                                    },
                                    onError: function () {
                                        fn.saving.saveOtherDetailsPartSave(callbacks3);
                                    }
                                }
                            );
                        }
                    };

                    var callbacks3 = {
                        onSuccess: function () {
                            
                            $scope.loadingSpinner = false;
                        }

                    };

                    // Test for navigate next instantaneous -- roll back test
                    

                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                    $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                    fn.currentProposalApi[saveCurrentProposalFunc](preCallbacks1);
                },

                onDropMakeChanged: function (manufacturerId) {
                    $scope.staticData.models = [];
                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleModel option:first").text("กำลังโหลด...");
                        $scope.staticData.models = [{ "key": 0, "value": "กำลังโหลด..." }];
                    } else {
                        $("#vehicleModel option:first").text("Loading...");
                        $scope.staticData.models = [{ "key": 0, "value": "Loading..." }];
                    }
                    //$scope.staticData.models = [];
                    if (!$scope.isStartQuote) {
                        $scope.isStartQuote = true;

                    }

                    if (!manufacturerId) {
                        if ($scope.staticData.models) {
                            $scope.staticData.models.splice(0);
                        }
                        return;
                    }
                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    //wfeApiStaticDataModel.getModelListWithYear({
                    wfeApiStaticDataModel.getModelListV2({
                        onSuccess: function (data) {


                            filteredModel = $filter('filter')(data, function (r) {
                                var notAcceptModel = ["90000148",
                                    "90000784",
                                    "90000546",
                                    "90000214",
                                    "90000810",
                                    "90000406",
                                    "90000402",
                                    "90000456",
                                    "90000747",
                                    "90000043",
                                    "90000667",
                                    "90000737",
                                    "90000185",
                                    "90000186",
                                    "90000189",
                                    "90000194",
                                    "90000720",
                                    "90000203",
                                    "90000772",
                                    "90000773",
                                    "90000229",
                                    "90000230",
                                    "90000231",
                                    "90000598",
                                    "90000676",
                                    "90000232",
                                    "90000234",
                                    "90000235",
                                    "90000236",
                                    "90000237",
                                    "90000238",
                                    "90000723",
                                    "90000809",
                                    "90000242",
                                    "90000270",
                                    "90000274",
                                    "90000278",
                                    "90000280",
                                    "90000279",
                                    "90000282",
                                    "90000283",
                                    "90000286",
                                    "90000287",
                                    "90000288",
                                    "90000289",
                                    "90000790",
                                    "90000778",
                                    "90000801",
                                    "90000802",
                                    "90000781",
                                    "90000794",
                                    "90000465",
                                    "90000812",
                                    "90000469",
                                    "90000490",
                                    "90000491",
                                    "90000492",
                                    "90000762",
                                    "90000493",
                                    "90000494",
                                    "90000495",
                                    "90000496",
                                    "90000785",
                                    "90000786",
                                    "90000497",
                                    "90000498",
                                    "90000499",
                                    "90000500",
                                    "90000501",
                                    "90000502",
                                    "90000503",
                                    "90000504",
                                    "90000505",
                                    "90000042",
                                    "90000165",
                                    "90000243",
                                    "90000029",
                                    "90000046",
                                    "90000066",
                                    "90000067",
                                    "90000807",
                                    "90000031",
                                    "90000174",
                                    "90000182",
                                    "90000303",
                                    "90000353",
                                    "90000438",
                                    "90000440",
                                    "90000527",
                                    "90000162",
                                    "90000217",
                                    "90000296",
                                    "90000529",
                                    "90000122",
                                    "90000010",
                                    "90000249"];
                                if (!notAcceptModel.includes(r.key)) {
                                    //console.log(r.key);
                                    return r.key;
                                }
                            });
                            $scope.staticData.models = filteredModel;

                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            } else {
                                $("#vehicleModel option:first").text("Please select...");
                            }
                            if (data.length == 0) {
                                DaModal.open("#makeAndModelNotAvailable");
                            }

                        },
                        onError: function () {
                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleModel option:first").text("เลือกรุ่นรถ");
                            } else {
                                $("#vehicleModel option:first").text("Please select...");
                            }
                        }
                    });

                },

                onDropModelChanged: function (manufacturerId, modelId) {
                    $scope.staticData.yearOfRegistrations = [];
                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleFirstReg option:first").text("กำลังโหลด...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "กำลังโหลด..." }];
                    } else {
                        $("#vehicleFirstReg option:first").text("Loading...");
                        $scope.staticData.yearOfRegistrations = [{ "key": 0, "value": "Loading..." }];
                    }

                    var objParams = {};
                    objParams.manufacturerId = manufacturerId;
                    objParams.modelId = modelId;

                    if (typeof modelId !== "undefined") {
                        //var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                        //console.log(config.api);
                        $.get(config.api + '/ControlServices/StaticData/getYear/'+ manufacturerId+'/'+modelId+'?lang='+HKLocale.getLocale(), function(data){
                            //console.log(data);
                            var current = new Date();
                            var limitYear = current.getFullYear() - 10;
                            
                            $scope.staticData.yearOfRegistrations = data.KeyValuePairString.filter(val => (val.key > limitYear) ? val : undefined);

                        });
                        /* var current = new Date();
                                var limitYear = current.getFullYear() - 10;
                                //$scope.staticData.yearOfRegistrations = data.filter(val => (val.key >= limitYear) ? val : undefined);
                                // for test only 
                                var data = {};
                                var arr  = [];
                                for(i = current.getFullYear(); i > limitYear ; i--){
                                    
                                    arr.push({'key': i, 'value': i});
                                }
                                console.log(arr);
                                $scope.staticData.yearOfRegistrations = arr; */

                        /* wfeApiStaticDataModel.getYear({
                            onSuccess: function (data) {
                                var current = new Date();
                                var limitYear = current.getFullYear() - 10;
                                //$scope.staticData.yearOfRegistrations = data.filter(val => (val.key >= limitYear) ? val : undefined);
                                // for test only 
                                var data = {}
                                for(i = current.getFullYear(); i > limitYear - 10 ; i--){
                                    data.key = i;
                                    data.value = i;
                                }
                                console.log(data);
                                $scope.staticData.yearOfRegistrations = data;

                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกรุ่นปีรถยนต์");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }

                            },
                            onError: function () {
                                if (HKLocale.getLocale() == "th-th") {
                                    $("#vehicleFirstReg option:first").text("เลือกรุ่นปีรถยนต์");
                                } else {
                                    $("#vehicleFirstReg option:first").text("Please select...");
                                }
                            }
                        }); */
                    }
                },

                onDropPurchasingYearChanged: function (modelId, purchasingYear) {
                    if (HKLocale.getLocale() == "th-th") {
                        $("#vehicleDesc option:first").text("กำลังโหลด...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "กำลังโหลด..." }];
                    } else {
                        $("#vehicleDesc option:first").text("Loading...");
                        $scope.staticData.arrangedcardescriptions = [{ "key": 0, "HGroup": "Loading..." }];
                    }
                    $scope.staticData.cardescriptions = [];
                    var objParams = {};
                    objParams.modelId = modelId;
                    objParams.purchasingYear = purchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getCarDescWithYear({
                        onSuccess: function (data) {
                            $scope.staticData.cardescriptions = data;
                            var acceptTypeOnly = $filter('filter')(data, { tariffType: 110 });
                            $scope.staticData.arrangedcardescriptions = fn.eventHandlers.reduceDesc(acceptTypeOnly);
                            if ($scope.staticData.arrangedcardescriptions.length === 0) {  // If car type filter and got zero, return not available message.
                                DaModal.open("#makeAndModelNotAvailable");
                            }
                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            } else {
                                $("#vehicleDesc option:first").text("Please select...");
                            }

                        },
                        onError: function () {
                            if (HKLocale.getLocale() == "th-th") {
                                $("#vehicleDesc option:first").text("เลือกรุ่นย่อย");
                            } else {
                                $("#vehicleDesc option:first").text("Please select...");
                            }
                        }
                    });

                    // Add NCD Level condition for car age.
                    var chkDate = new Date();
                    var NCDLVLID = "1000003";
                    var carAge = chkDate.getFullYear() - purchasingYear;
                    var ncdLevel = 0;
                    if (carAge == 0) {  // 0% For vehicle age 0 year
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    } else if (carAge == 1) { // 20% For vehicle age 1 year
                        ncdLevel = 20;
                        NCDLVLID = "1000005";
                    } else if (carAge > 1) {  // 30% Vehicle age > 1 year
                        ncdLevel = 30;
                        NCDLVLID = "1000006";
                    } else {
                        ncdLevel = 0;
                        NCDLVLID = "1000003";
                    }

                },

                onCarDescriptionChanged: function (descKey) {

                    var carTarrif = $filter('filter')($scope.staticData.cardescriptions, {
                        key: descKey
                    })[0];

                    if (carTarrif.tariffType) {
                        if (carTarrif.tariffType === '320' || carTarrif.tariffType == 320) {
                            // Do not respect IsDriveWork/IsUseWork/Usage is fixed.
                            $scope.asset.TariffType = 320;

                            DaModal.open("#makeAndModelNotAvailable");
                            $scope.txtDesc = fn.getTranslateText('desc');
                            $scope.asset.CarDescriptionId = null;
                        }
                        else {
                            $scope.asset.TariffType = 110;

                        }
                    }
                    return;
                },

                onBtnInDriveWorkChange: function () {
                    var inDriveWork = $scope.asset.InDriveWork;
                    if (!inDriveWork) {
                        $scope.asset.InCourseWork = false;
                    } else {
                        $scope.asset.InCourseWork = undefined;
                    }

                },

                onIsPHMDButton: function () {

                    if ($scope.policyHolder.IsMainDriver) {
                        fn.utils.copyMDFieldsToPH();
                        $scope.$parent.setDateValidity($scope.policyHolder.DOB_DD, $scope.policyHolder.DOB_MON, $scope.policyHolder.DOB_Year, $scope.frmAboutPH.policyHolderDOBDD);

                    } else {
                        $scope.policyHolder.DOB_DD = null;
                        $scope.policyHolder.DOB_MON = null;
                        $scope.policyHolder.DOB_Year = null;
                        $scope.policyHolder.DateOfBirth = null;
                        $scope.policyHolder.GenderId = null;
                        $scope.policyHolder.MaritalStatusId = null;
                        $scope.policyHolder.OccupationId = null;
                        $scope.policyHolder.DrivingExperienceId = null;
                        $scope.policyHolder.NrOfClaimsInPeriodId = null;
                        $scope.policyHolder.InDriveWork = null;
                        $scope.policyHolder.InCourseWork = null;
                        $scope.policyHolder.ResidentialStatusId = null;
                        $scope.policyHolder.FirstName = null;
                        $scope.policyHolder.LastName = null;
                        $scope.policyHolder.Identifier = null;
                        $scope.policyHolder.NationalityId = null;
                    }
                },

                onBtnNCDClicked: function () {
                    DaModal.open('#checkNCD');
                },

                onBtnNCDSelected: function () {
                    DaModal.close('#checkNCD');
                },

                reduceDesc: function (descData) {
                    //console.log(descData);
                    if (descData) {
                        var p;
                        var header;

                        var descObj2 = new Object();

                        var descKeyObj = new Object();
                        var descTxtObj = new Object();
                        var optArr = new Array;
                        var headArr = new Array;

                        for (var key in descData) {
                            if (!isNaN(descData[key].key)) {
                                //if(descData[key].description.substr(0, 2) != "Z_"){
                                var optSel = new Object();
                                p = descData[key].description.split(" ");
                                HGroup = p[0] + " " + p[1] + " ";
                                descTxt = descData[key].description.replace(HGroup, "").replace("?", " ");
                                descKey = descData[key].key;
                                optSel.key = descKey;
                                optSel.txt = descTxt;
                                optSel.hgroup = HGroup;
                                optSel.oriDesc = descData[key].description;
                                optArr.push(optSel);
                                if (headArr.indexOf(HGroup) == -1) {
                                    headArr.push(HGroup);
                                }
                                //}
                            }

                        }
                        var z = Array();
                        for (var i in headArr) {
                            var x = new Array;
                            var descObj = new Object();
                            for (var j in optArr) {
                                if (headArr[i] == optArr[j].hgroup) {
                                    x.push(optArr[j]);
                                }
                            }
                            descObj.HGroup = headArr[i];
                            descObj.Data = x;
                            z.push(descObj);
                        }
                        return z;
                    }
                },
                setMaker: function (e) {
                    $scope.txtMaker = e.target.textContent.trim();
                    $scope.asset.ManufactureId = e.target.dataset.key;
                    fn.eventHandlers.showModel(e);
                    $scope.txtModel = fn.getTranslateText('model');
                    $scope.txtYear = fn.getTranslateText('year');
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setModel: function (e) {
                    $scope.txtModel = e.target.textContent.trim();
                    $scope.asset.ModelId = e.target.dataset.key;
                    fn.eventHandlers.showYear(e);
                    $scope.txtYear = fn.getTranslateText('year');
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setYear: function (e) {
                    $scope.txtYear = e.target.textContent.trim();
                    $scope.asset.PurchasingYear = e.target.dataset.key;
                    fn.eventHandlers.showDesc(e);
                    $scope.txtDesc = fn.getTranslateText('desc');
                },
                setDesc: function (e) {
                    $scope.txtDesc = e.target.parentNode.dataset.head + " " + e.target.textContent.trim();
                    $scope.asset.CarDescriptionId = e.target.dataset.key;
                },
                showModel: function (e) {
                    e.stopPropagation();
                    $("#vehicleMake3").removeClass('open');
                    $("#vehicleModel3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowModel").html(divArrow);
                },
                showYear: function (e) {
                    e.stopPropagation();
                    $("#vehicleModel3").removeClass('open');
                    $("#vehicleFirstReg3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowYear").html(divArrow);
                },
                showDesc: function (e) {
                    e.stopPropagation();
                    $("#vehicleFirstReg3").removeClass('open');
                    $("#vehiceDesc3").addClass('open');
                    $('.scrollArrow').remove();
                    var divArrow = '<div id="scrollTxt" class="shakeTxt scrollArrow" ><span class="glyphicon glyphicon-arrow-down"></span></div>';
                    $("#arrowDesc").html(divArrow);
                },
                selectCoverage: function (c) {
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                        $scope.coverages.Coverages[i].IsSelected = false;
                    }
                    c.IsSelected = true;
                    if ((c.CoverTypeId == 2000015) || (c.CoverTypeId == 7000114)) {
                        /*don't need to show excess for type 1 in desc.*/
                        fn.coverageApi.getVehicleExcess(
                            {
                                onSuccess: function (data) {
                                    if (data && data[0].value) {
                                        $scope.selectedCoverage.excess = data;
                                    }
                                },
                                onError: function () {
                                }
                            }
                        );
                    }
                    //console.log($scope.coverages);

                    $scope.coverNum = 0;
                    $scope.coverDetailsClicked = true;
                    $scope.isCompulsoryOnly = false;
                    $scope.totalPremium = fn.calculateTotalAmout();

                    /*slide down to correct type , if only its display on small screen*/
                    if ($window.matchMedia) {
                        var mq = $window.matchMedia("(max-width:991px)");
                        if (mq) {
                            if (mq.matches) {
                                var anc = '#dacover_' + c.CoverTypeId;

                                /*timeout cos the box expands and thus creates problems with top-margin calc*/
                                $timeout(function () {
                                    fn.gtAnchor(anc);
                                }, 100);
                            }

                        }
                    }
                    fn.utils.setSumInsured();
                },
                onBtnSaveAndExitClicked: function () {

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if ($scope.phContact.PrimaryPhoneNumber) {
                        if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                            $scope.phContact.PrimaryPhoneNumber = "";
                        } else {
                        }
                    }

                    fn.utils.setIsSaving(true);

                    var precallback = {
                        onSuccess: function(){
                            fn.saving.saveCoveragesModel(callbacks1);
                        }
                    };
                    var callbacks1 = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacks2);
                        }
                    };

                    var callbacks2 = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPartSave(callbacks3);
                                    },
                                    onError: function () {
                                        fn.saving.saveOtherDetailsPartSave(callbacks3);
                                    }
                                }
                            );
                        }
                    };

                    var callbacks3 = {
                        onSuccess: function () {
                            fn.eventHandlers.onBtnExitClicked();
                        }

                    };
                    

                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');

                    $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;
                    fn.currentProposalApi[saveCurrentProposalFunc](precallback);
                },
                setCompulsory: function (val) {
                    if (!$scope.coverages) return;
                    
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                        /*CCB*/
                        var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000161
                        }, true);
                        if (ccb) {
                            ccb[0].IsSelected = val;
                        }

                        /*Death & Dismemberment or permanent disability*/
                        var ddpd = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000101
                        }, true);
                        if (ddpd) {
                            ddpd[0].IsSelected = val;
                        }

                        /*Bodily Injury or affecting health*/
                        var bih = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000102
                        }, true);
                        if (bih) {
                            bih[0].IsSelected = val;
                        }
                    }
                    $scope.compulsory.isIncluded = val
                    //console.log($scope.compulsory.isIncluded);
                },
                onBtnCoverExplainedClicked: function () {
                    DaModal.open('#coverExplained');
                },

                onBtnCoverExplainedSelected: function () {
                    DaModal.close('#coverExplained');
                },

                onBtnToSummary: function () {
                    

                    if(fn.validations.validateForms2()){
                        return;
                    }

                    if(!fn.validations.isTHIDValidMD() && $scope.mainDriver.ResidentialStatusId == 2000000){
                        DaModal.open("#MDIDNotValid");
                        return
                    }else if(!fn.validations.isTHIDValidND() && $scope.dynamicForm.addedNamedDriver && $scope.namedDriver.ResidentialStatusId == 2000000){
                        DaModal.open("#NDIDNotValid");
                        return
                    }else if(!fn.validations.isTHIDValidPH() && $scope.policyHolder.ResidentialStatusId == 2000000){
                        DaModal.open("#PHIDNotValid");
                        return
                    }

                    if(!fn.eventHandlers.isAcceptOccu($scope.policyHolder.OccupationId)){
                        DaModal.open("#policyHolderOccuNotAccept");
                        return
                    }

                    fn.utils.setIsSaving(true);
                    
                    var callbacks1 = {
                        onSuccess: function () {
                            fn.saving.savePHContactToProceed(callbacks2);
                        }
                    };

                    var callbacks2 = {
                        onSuccess: function(){
                            fn.saving.savePHAddress(callbacks3);
                        }
                    };

                    var callbacks3 = {
                        onSuccess: function () {
                            fn.saving.calculatePremium(
                                {
                                    onSuccess: function () {
                                        fn.saving.saveOtherDetailsPartSave(callbacks4);
                                    },
                                    onError: function () {
                                        $scope.showPriceOnTopFlag = true;
                                        $scope.loadingSpinner = false;
                                    }
                                }
                            );
                        }
                    };
                    var callbacks4 = {
                        onSuccess: function(){
                            $scope.showPriceOnTopFlag = true;
                            $scope.loadingSpinner = false;
                            fn.setPreviousPage(3);
                            fn.goToPage(4);
                        },
                        onError: function(){
                            $scope.showPriceOnTopFlag = true;
                            $scope.loadingSpinner = false;
                        }
                    }

                    /* var callbacks3 = {
                        onSuccess: function () {
                            fn.saving.saveOtherDetailsPartSave();
                        }
                    }; */

                    
                    fn.eventHandlers.postBLFC();
                    
                    $scope.showPriceOnTopFlag = false;
                    $scope.loadingSpinner = true;
                    $scope.page2Submitted = true;
                    fn.updateAssetMaxarPriority(callbacks1);

                    //fn.saving.saveCoveragesModel(newCallback1);
                },
                onIsRedPlateClicked: function () {
                    if ($scope.asset.IsRedPlate) {
                        if ($scope.HKLocale.getLocale === "en-us") {
                            $scope.asset.VehicleRegistrationNumber = "NA";
                        } else {
                            $scope.asset.VehicleRegistrationNumber = "ป้ายแดง";
                        }
                        $scope.asset.LicenseCardProvinceId = "2000080";
                    }

                    if ((typeof $scope.asset.VehicleRegistrationNumber !== "undefined") && !$scope.asset.IsRedPlate) {
                        $scope.asset.VehicleRegistrationNumber = "";
                    }
                },
                onIsCommercialPurposeClicked: function () {
                    if ($scope.asset.IsCommercialPurpose) {
                        DaModal.open('#usedForCommercial');
                    }
                },
                isCommercialUsage: function () {
                    if ($scope.asset.UsageId === "0") {
                        DaModal.open("#usedForCommercial");
                        return true;
                    }
                    return false;
                },
                onBtnExitClicked: function () {
                    $state.go('publicB2B.motor.manage.steps.proposal');
                },
                postBLFC: function(){
                    $window.BLFCLayer = [];
                    var BLFCLayer = $window.BLFCLayer;
                    var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();
                    var idNumber = $scope.policyHolder.Identifier
                    var identifier = idNumber.replace(/-/g, "");
                    var cardType = "I"
                    if($scope.policyHolder.ResidentialStatusId == 2000001){
                        cardType = "P"
                    }
                    
                    BLFCLayer.push({
                        mitBaseInitialParam: {MITSessionToken: sessionToken},
                        CardType : cardType,
                        Identification : identifier,
                        FirstName : $scope.policyHolder.FirstName,
                        LastName : $scope.policyHolder.LastName
                    });
    
                    if($scope.policyHolder.FirstName !== '' && $scope.policyHolder.LastName !== '' && identifier.length === 13)
                    {
                        $scope.$parent.checkBLFC();
                    }
                },
                /** Falcon UW API */
                postUWFC: function(){
                    $window.UWFCLayer = [];
                    var UWFCLayer = $window.UWFCLayer;
                    var sessionToken = $scope.sessionApi.pAndCModelStorage.session.MITSessionToken.trim();
                    fn.utils.setSumInsured();
                    if ($scope.asset.ModelId) {
                        var objParams = {};
                        objParams.manufacturerId = $scope.asset.ManufactureId;
                        var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, HKLocale.getLocale(), objParams);
                        wfeApiStaticDataModel.getModelListV2({
                            onSuccess: function (data) {
                                $scope.txtModel = $filter('filter')(data, {key: $scope.asset.ModelId})[0].value;
                                $scope.txtMake = $filter('filter')($scope.staticData.makes, {key: $scope.asset.ManufactureId})[0].value;
                                UWFCLayer.push({
                                    mitBaseInitialParam: {MITSessionToken: sessionToken},
                                    Make : $scope.txtMake, //fn.wfeApiStaticData.utils.getStaticDataDisplay($scope.asset.ManufactureId, $scope.staticData.makes),
                                    Model : $scope.txtModel,
                                    YearOfRegistration : $scope.asset.PurchasingYear.toString(),
                                    SumInsured : $scope.sumInsured.value,
                                    Deductible : parseInt(fn.wfeApiStaticData.utils.getStaticDataDisplay( $filter('filter')($scope.selectedCoverage.Covers, {ProductLineGroupId: '2000103'} )[0].ExcessLevelId, $scope.selectedCoverage.excess)),
                                    Premium : $scope.totalPremium2.toFixed(2)
                                });

                                $scope.$parent.checkUWFC();
                            }
                        });
                    }
                },
                onBtnConvertPolicyClicked: function(){
                    DaModal.open("#convertPolicyModal");
                },
                onBtnConvertPolicyConfirm: function(){

                    fn.utils.setIsSaving(true);

                    var preCallbacks1 = {
                        onSuccess: function () {
                            fn.updateAssetMaxarPriority(callbacks1);
                        }
                    };

                    var callbacks1 = {
                        onSuccess: function () {
                            fn.eventHandlers.postUWFC();
                            fn.saving.savePHContactToProceed(checkUserExist);
                        }
                    };

                    var checkUserExist =  {
                        onSuccess: function(){
                            fn.saving.isUserExists({
                                onSuccess: function (cvrList) {
                                    processContact(cvrList);
                                },
                                onError: function (cvrList) {
    
                                    fn.utils.onUserMismatchHandler(cvrList);
                                    $scope.cvrList = cvrList;
                                    DaModal.open('#userMismatchValidation');
                                    hasError = true;
                                    //savingComplete();
                                }
                            });
                        }
                    };

                    var processContact = function (cvrList) {

                        fn.policyContactApi.processPolicyContact({
                            onSuccess: function () {
                                fn.saving.confirmProposal(callbacks3);
                            },
                            onError: function () {
                                hasError = true;
                                fn.saving.confirmProposal(callbacks3);
                            }
                        }, cvrList);

                    };

                    var callbacks3 = {
                        onSuccess: function(){
                            fn.saving.issuePolicy(redirec);
                        }
                    };

                    var redirec = {
                        onSuccess: function () {
                            fn.utils.setIsSaving(false);
                            DaModal.close("#convertPolicyModal");
                            DaModal.open("#convertPolicyModalSuccess");
                        }

                    };

                    fn.saving.saveCoveragesModel(preCallbacks1);
                    //fn.saving.confirmProposal(callbacks3);
                    
                },
                onBtnConvertPolicyCancel: function(){
                    DaModal.close("#convertPolicyModal");
                },
                onBtnGotoPolicy: function(){
                    DaModal.close("#convertPolicyModalSuccess");
                    var parameter = {
                        policyNumber: $scope.currentProposal.ExternalProposalNumber,
                        productId: 2000003
                    };
                    $timeout(function () {
                        $state.go('publicB2B.motor.manage.steps.viewpolicy', parameter);
                    }, 2000);
                },
                onBtnGotoProposalManage: function(){
                    DaModal.close("#convertPolicyModalSuccess");
                    $timeout(function () {
                        $state.go('publicB2B.motor.manage.steps.proposal');
                    }, 2000);
                    
                },
                isAcceptOccu: function(val){
                    if(val == 2000034 || val == 2000029){
                        return false;
                    }else{
                        return true;
                    }
                },
                addNamedDriver: function(){
                    $scope.dynamicForm.addedNamedDriver = true;
                },
                removeNamedDriver: function(){
                    $scope.dynamicForm.addedNamedDriver = false;
                    $scope.namedDriver = {};
                },
                isPHMDSync: function(){
                    if($scope.policyHolder.IsMainDriver){
                        fn.utils.copyPHFieldsToMD();
                    }
                }
            },// fn.eventHandler end
            scrollingTo: function (elemId) {
                if ($('html, body').width() <= 990) {  // Scrolling only responsive size < 990 PX
                    $('html, body').animate({ scrollTop: $("#" + elemId).offset().top - 90 }, 'slow');
                }
            },
            getTranslateText: function (part) {
                if (HKLocale.getLocale() == 'th-th') {
                    switch (part) {
                        case "maker":
                            return "เลือกยี่ห้อรถ";
                            break;
                        case "model":
                            return "เลือกรุ่นรถ";
                            break;
                        case "year":
                            return "เลือกรุ่นปีรถยนต์";
                            break;
                        case "desc":
                            return "เลือกรุ่นย่อย";
                            break;
                    }

                } else {
                    switch (part) {
                        case "maker":
                            return "Please select";
                            break;
                        case "model":
                            return "Please select";
                            break;
                        case "year":
                            return "Please select";
                            break;
                        case "desc":
                            return "Please select";
                            break;
                    }
                }
            },


            calculateTotalAmout: function () {
                if ((!$scope.coverages) || !$scope.coverages.Coverages) {
                    return 0;
                }
                var coverage = $filter('filter')($scope.coverages.Coverages, { IsSelected: true }, true)[0];
                if (!coverage) {
                    return 0;
                }
                var x = fn.utils.getPriceToShow(coverage);
                return (x);

            },

            getTotalAmout: function () {
                $scope.totalPremium2 = fn.calculateTotalAmout();
                return $scope.totalPremium2;
            },
            checkAllCarInfoCompleted: function () {
                if (($scope.asset.ManufactureId) &&
                    ($scope.asset.ModelId) &&
                    ($scope.asset.PurchasingYear) &&
                    ($scope.asset.CarDescriptionId)) {
                    return true;
                } else {
                    return false;
                }
            },
            checkAllMainDriverCompleted: function () {
                if (
                    ($scope.mainDriver.DOB_DD && $scope.mainDriver.DOB_MON && $scope.mainDriver.DOB_Year)
                    && ($scope.mainDriver.GenderId) && ($scope.mainDriver.MaritalStatusId) &&
                    (($scope.asset.InDriveWork == false) || (($scope.asset.InDriveWork == true) && ($scope.asset.InCourseWork !== undefined)))
                ) {
                    return true;
                } else {
                    return false;
                }
            },
            checkAllContactInfoCompleted: function () {
                if ($scope.phContact.PrimaryPhoneNumber && $scope.policyHolder.FirstName && $scope.phContact.PrimaryEmailAddress) {
                    $scope.contactInfoComplete = true;
                } else {
                    $scope.contactInfoComplete = false;
                }
            },

            nextToMainDriver: function () {

                if (fn.checkAllCarInfoCompleted()) {
                    $scope.showMainDriverFlag = true;
                    fn.scrollingTo("body_title_driver_main_part");
                    $('#yourCar').collapse('hide');
                    $('#maindriver').collapse('show');
                }
            },
            nextToContactInfo: function () {
                if (fn.checkAllMainDriverCompleted() && fn.checkAllCarInfoCompleted()) {
                    $scope.showContactInfoFlag = true;
                    fn.scrollingTo("body_contact_info_part");
                    $('#maindriver').collapse('hide');
                    $('#contactInfoBody').collapse('show');
                }
            },
            assetStaticData: {
                make: [],
                model: [],
                year: [],
                description: []
            },
            disablePremium: function () {
                return "enabled";
            },
            goToPage: function (page) {
                window.scrollTo(0, 0);
                if (page == 1) {
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = true;
                    $scope.secondPageShow = false;
                    $scope.thirdPageShow = false;
                    $scope.fourthPageShow = false;
                    window.location.hash = "#start";
                    $sessionStorage.currentPage = 1;
                } else if (page == 2) {
                    var elmnt = document.getElementById("plansection");
                    elmnt.scrollIntoView();
                    $scope.firstPageShow = true;
                    $scope.secondPageShow = true;
                    $scope.thirdPageShow = false;
                    $scope.fourthPageShow = false;
                    $scope.isSaveAndExitShowName = true;
                    window.location.hash = "#your-quote";
                    $sessionStorage.currentPage = 2;
                } else if (page == 3) {
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = false;
                    $scope.secondPageShow = false;
                    $scope.thirdPageShow = true;
                    $scope.fourthPageShow = false;
                    $scope.isSaveAndExitShowName = false;
                    window.location.hash = "#policy-submission";
                    $sessionStorage.currentPage = 3;
                } else if (page == 4) {
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = false;
                    $scope.secondPageShow = false;
                    $scope.thirdPageShow = false;
                    $scope.fourthPageShow = true;
                    $scope.isSaveAndExitShowName = false;
                    window.location.hash = "#summary";
                    $sessionStorage.currentPage = 4;
                } else {  // default page 1
                    window.scrollTo(0, 0);
                    $scope.firstPageShow = true;
                    $scope.secondPageShow = false;
                    $scope.thirdPageShow = false;
                    window.location.hash = "#start";
                    $sessionStorage.currentPage = 1;
                }
            },
            setPreviousPage: function (val) {
                $scope.previousPage = val;
            },
            refreshCurrentPage: function (page) {
                fn.goToPage(page);

            },
            updateAssetMaxarPriority: function (callbacks) {
                var saveAssetFunc = fn.assetApi.utils.getSaveFunctionName($scope.asset, 'Asset');
                fn.assetApi[saveAssetFunc](callbacks);
            },
            selectIAmount: function (iAmount) {
                $scope.selectedInsureAmount = iAmount;
                $scope.coverages.Coverages.forEach(function (cc, xx) {
                    if ((cc.CoverTypeId == 2000017 || cc.CoverTypeId == 2000019) && cc.IsSelected == true) {
                        $scope.coverages.Coverages[xx].Covers.forEach(function (plo, yy) {
                            if (plo.ProductLineGroupId == 2000105) {
                                $scope.coverages.Coverages[xx].Covers[yy].InsuranceAmountLevelId = iAmount;
                                fn.saving.saveCoveragesModel({
                                    onSuccess: function () {
                                        $scope.loadingSpinner = true;
                                        $scope.loadingSpinner2 = true;
                                        $scope.loadingSpinner3 = true;
                                        fn.quoteApi.calculate({
                                            onSuccess: function () {
                                                //console.log("re-calculate success");
                                                fn.updatesCoveragesSPA();
                                            },
                                            onError: function () {
                                                //console.log("re-calculate failed");

                                            }
                                        });
                                    },
                                    onError: function () {
                                        //console.log("Save coverage failed.");
                                    }
                                });

                            }
                        });
                    }

                });
            },

            setDefaultInsureAmount: function () {
                var sc = fn.utils.getSelectedCoverage();
                if (sc.CoverTypeId == 2000017 || sc.CoverTypeId == 2000019) {
                    var plo = $filter('filter')(sc.Covers, { ProductLineGroupId: 2000105 });
                    if (plo && plo[0]) {
                        $scope.selectedInsureAmount = plo[0].InsuranceAmountLevelId;
                    }
                }
            },
            setBodyType: function (val) {
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.bodyType = val;
                $scope.gearType = '';
                if ($scope.bodyType === '2doors') {
                    DaModal.open("#makeAndModelNotAvailable");
                }
                //console.log('bodytype : ' + $scope.bodyType);
            },
            setGearType: function (val) {
                $scope.txtDesc = fn.getTranslateText('desc');
                $scope.asset.CarDescriptionId = null;
                $scope.gearType = val;
                //console.log('gearType : ' + $scope.gearType);
            },
            isPopularTruck: function () {
                var popularTruckModel = ['D-Max', 'Hilux Revo', 'Hilux Vigo', 'Triton'];
                if (popularTruckModel.indexOf($scope.txtModel) < 0) {
                    return false;
                } else {
                    return true;
                }
            },

            setSumInsured:function()
            {
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;
                if($scope.selectedCoverage.CoverTypeId==2000018 || $scope.selectedCoverage.CoverTypeId=='2000018')
                {
                    //Type 3 is always NA and hidden
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                    return;
                }
                if($scope.selectedCoverage.CoverTypeId==2000019 || $scope.selectedCoverage.CoverTypeId=='2000019'
                || $scope.selectedCoverage.CoverTypeId==2000017 || $scope.selectedCoverage.CoverTypeId=='2000017'
                || $scope.selectedCoverage.CoverTypeId==2000015 || $scope.selectedCoverage.CoverTypeId=='2000015')
                {
                    //Type 2+/ 3+ / Type 1 - value from own damage collision
                    //Type 1- own damage collision value.
                    var odc=$filter('filter')($scope.selectedCoverage.Covers, {
                        ProductLineGroupId: 2000105
                    },true);
                    if(odc && odc[0].InsuranceAmount){

                        $scope.sumInsured.value=odc[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }


                    return;
                }

                if($scope.selectedCoverage.CoverTypeId==2000016 || $scope.selectedCoverage.CoverTypeId=='2000016')
                {

                    //Type 2- own damage Fire and theft value
                    var odft=$filter('filter')($scope.selectedCoverage.Covers, {
                        ProductLineGroupId: 2000106
                    },true);
                    if(odft && odft[0].InsuranceAmount){

                        $scope.sumInsured.value=odft[0].InsuranceAmount;
                        $scope.sumInsured.visible=true;
                    }
                    else {
                        $scope.sumInsured.value=0;
                        $scope.sumInsured.visible=false;
                    }
                    return;
                }

                if($scope.selectedCoverage.CoverTypeId==2000020 || $scope.selectedCoverage.CoverTypeId=='2000020')
                {

                    //Type Compulsory only
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                    return;
                }
                //Exception- Everything fails- hide
                $scope.sumInsured.visible=false;
                $scope.sumInsured.value=0;

            },
            getTariffType: function(){
                if(!$scope.staticData.cardescriptions) return false;
                
                try{
                    var a = $filter('filter')($scope.staticData.cardescriptions, {key: $scope.asset.CarDescriptionId});
                    return a[0].tariffType;
                }catch(err){
                    return 0;
                }
            }

        };

        /** Initialize and retrievals */
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initSubscribeWatcher();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        // if ($scope.HKLocale.getLocale() === "th-th") { 
        //     $window.cwcCookieBanner.setLang('th'); 
        // }else{
        //     $window.cwcCookieBanner.setLang('en');
        // }
        
        /** Event Handlers */
        $scope.onBtnCalculatePremiumClicked = fn.eventHandlers.onBtnCalculatePremiumClicked;
        $scope.onDropMakeChanged = fn.eventHandlers.onDropMakeChanged;
        $scope.onDropModelChanged = fn.eventHandlers.onDropModelChanged;
        $scope.onDropPurchasingYearChanged = fn.eventHandlers.onDropPurchasingYearChanged;
        $scope.onBtnInDriveWorkChange = fn.eventHandlers.onBtnInDriveWorkChange;
        $scope.onPolicyStartDateChanged = fn.eventHandlers.onPolicyStartDateChanged;
        $scope.onIsPHMDButton = fn.eventHandlers.onIsPHMDButton;
        $scope.onCarDescriptionChanged = fn.eventHandlers.onCarDescriptionChanged;
        $scope.onBtnNCDClicked = fn.eventHandlers.onBtnNCDClicked;
        $scope.onBtnNCDSelected = fn.eventHandlers.onBtnNCDSelected;
        $scope.onNCBApplyRequest = fn.eventHandlers.onNCBApplyRequest;
        $scope.getTotalAmout = fn.getTotalAmout;
        $scope.nextToMainDriver = fn.nextToMainDriver;
        $scope.checkAllCarInfoCompleted = fn.checkAllCarInfoCompleted;
        $scope.nextToContactInfo = fn.nextToContactInfo;
        $scope.checkAllContactInfoCompleted = fn.checkAllContactInfoCompleted;
        $scope.onBtnExitClicked = fn.eventHandlers.onBtnExitClicked;
        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.selectCoverage = fn.eventHandlers.selectCoverage;
        $scope.setCompulsory = fn.eventHandlers.setCompulsory;
        $scope.onBtnCoverExplainedClicked = fn.eventHandlers.onBtnCoverExplainedClicked;
        $scope.onBtnCoverExplainedSelected = fn.eventHandlers.onBtnCoverExplainedSelected;
        $scope.isCoverNotAvail = fn.validations.isCoverNotAvail;
        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onFullDetailClicked = fn.eventHandlers.onFullDetailClicked;
        $scope.updateDeductibleAsset = fn.eventHandlers.updateDeductibleAsset;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;
        $scope.disablePremium = fn.disablePremium;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;
        $scope.onBtnBackClicked2 = fn.eventHandlers.onBtnBackClicked2;
        $scope.onBtnBackClicked3 = fn.eventHandlers.onBtnBackClicked3;
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.setBodyType = fn.setBodyType;
        $scope.setGearType = fn.setGearType;
        $scope.isPopularTruck = fn.isPopularTruck;
        $scope.onBtnNDAddClicked = fn.eventHandlers.onBtnNDAddClicked;
        $scope.onBtnNDCancelClicked = fn.eventHandlers.onBtnNDCancelClicked;
        $scope.onBtnNamedDriverModalUpdateClicked = fn.eventHandlers.onBtnNamedDriverModalUpdateClicked;
        $scope.onBtnNamedDriverModalRemoveClicked = fn.eventHandlers.onBtnNamedDriverModalRemoveClicked;
        $scope.onBtnNamedDriverModalRemoveCancelled = fn.eventHandlers.onBtnNamedDriverModalRemoveCancelled;
        $scope.onBtnNamedDriverModalRemoveConfirm = fn.eventHandlers.onBtnNamedDriverModalRemoveConfirm;
        $scope.onBtnToSummary = fn.eventHandlers.onBtnToSummary;
        $scope.onIsRedPlateClicked = fn.eventHandlers.onIsRedPlateClicked;
        $scope.onIsCommercialPurposeClicked = fn.eventHandlers.onIsCommercialPurposeClicked;
        $scope.isCommercialUsage = fn.eventHandlers.isCommercialUsage;
        $scope.convertPHMITDate = fn.utils.convertPHMITDate;
        $scope.convertNDMITDate = fn.utils.convertNDMITDate;
        $scope.convertPolicyStartMITDate = fn.utils.convertPolicyStartMITDate;
        $scope.validateNDForms = fn.validations.validateNDForms;
        $scope.getExcessValueSummary = fn.utils.getExcessValueSummary;
        $scope.isSelectedExcess = fn.utils.isSelectedExcess;
        $scope.getTariffType    = fn.getTariffType;
        $scope.isPHMDSync = fn.eventHandlers.isPHMDSync;
        /** Validations */
        $scope.validations = {
            checkDOBEmpty: fn.validations.checkDOBEmpty,
            isEndDateInvalid: fn.validations.isEndDateInvalid,
            isValidDriverAge: $scope.$parent.isInvalidDriverAge,
            isTHIDValidPH: fn.validations.isTHIDValidPH,
            isTHIDValidMD: fn.validations.isTHIDValidMD,
            isTHIDValidND: fn.validations.isTHIDValidND
        };
        $scope.isStartDateInvalid = fn.validations.isStartDateInvalid,
            $scope.checkNoOfAcc = fn.validations.checkNoOfAcc;
        $scope.checkNoOfAccND = fn.validations.checkNoOfAccND;
        $scope.checkDrivingExpND = fn.validations.checkDrivingExpND;


        $scope.filterOptionalButCompulsoryCovers = fn.utils.filterOptionalButCompulsoryCovers;

        $scope.checkDrivingExp = fn.validations.checkDrivingExp;
        $scope.checkNCDLevel = fn.validations.checkNCDLevel;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.getPriceToShow = fn.utils.getPriceToShow;
        $scope.convertMITDate = fn.utils.convertMITDate;
        $scope.coverageList = fn.utils.coverageList;
        $scope.checkMake = fn.validations.checkMake;
        $scope.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;
        $scope.getExcessValue = fn.utils.getExcessValue;
        $scope.setMaker = fn.eventHandlers.setMaker;
        $scope.setModel = fn.eventHandlers.setModel;
        $scope.setYear = fn.eventHandlers.setYear;
        $scope.setDesc = fn.eventHandlers.setDesc;
        $scope.showModel = fn.eventHandlers.showModel;
        $scope.showYear = fn.eventHandlers.showYear;
        $scope.showDesc = fn.eventHandlers.showDesc;
        $scope.postBLFC = fn.eventHandlers.postBLFC;
        $scope.postUWFC = fn.eventHandlers.postUWFC;
        $scope.toThaiYear = fn.toThaiYear;
        $scope.gtAnchor = fn.gtAnchor;
        $scope.int = parseInt;
        $scope.scrollingTo = fn.scrollingTo;
        $scope.selectIAmount = fn.selectIAmount;
        $scope.setPlan = fn.utils.setPlan;
        $scope.getInsuranceAmount = fn.utils.getInsuranceAmount;
        $scope.onBtnConvertPolicyClicked = fn.eventHandlers.onBtnConvertPolicyClicked;
        $scope.onBtnConvertPolicyConfirm = fn.eventHandlers.onBtnConvertPolicyConfirm;
        $scope.onBtnConvertPolicyCancel = fn.eventHandlers.onBtnConvertPolicyCancel;
        $scope.onBtnGotoPolicy = fn.eventHandlers.onBtnGotoPolicy;
        $scope.onBtnGotoProposalManage = fn.eventHandlers.onBtnGotoProposalManage;
        $scope.addNamedDriver = fn.eventHandlers.addNamedDriver;
        $scope.removeNamedDriver = fn.eventHandlers.removeNamedDriver;
        $scope.isCMIIncluded = fn.utils.isCMIIncluded;
        $scope.testSaveContact = fn.saving.savePHContact;
        /** sessionTimeOut */
        $scope.$on('$viewContentLoaded', function () {
            $timeout(function () {
                // Post
                fn.refreshCurrentPage($sessionStorage.currentPage);
            }, 0);

        });


    }];