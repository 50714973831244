module.exports = [
    '$anchorScroll',
    '$scope',
    '$window',
    '$document',
    '$http',
    '$filter',
    'ConfigUtils',
    'WfeApiCurrentProposal',
    'WfeApiAsset',
    'WfeApiContact',
    'WfeApiPayment',

    function ConfirmCounterController($anchorScroll, $scope, $window, $document, $http, $filter,
                                      ConfigUtils,
                                      WfeApiCurrentProposal, WfeApiAsset, WfeApiContact, WfeApiPayment) {
        $anchorScroll();

        var marketing = {
            ga: function(productId){
                if(productId === '2000003') {
                    $window.dataLayer = $window.dataLayer || [];
                    var dataLayer = $window.dataLayer;
                    dataLayer.push({
                        'event':'content-view',
                        'virtualPageURL':'/motor/quote/confirm-counter-buy/step-7/'}
                    );
                }
            },
            gaEvents:function(){
                $window.dataLayer = $window.dataLayer || [];
                var dataLayer = $window.dataLayer;
                dataLayer.push({
                    'event': 'Print Quote',
                    'category': 'Portal',
                    'action': 'Navigation',
                    'label': 'Print Quote'
                });

            },

            googleAdwords: function (revenue, productId) {
                $window.google_trackConversion({
                    google_conversion_id: 1015783185,
                    google_conversion_language: 'en',
                    google_conversion_format: '2',
                    google_conversion_color: 'ffffff',
                    google_conversion_label: ConfigUtils.marketing[productId].googleConversionLabel,
                    google_conversion_value: revenue,
                    google_conversion_currency: 'THB',
                    google_remarketing_only: false
                });
            },

        };

        var fn = {
            printWindow:function()
            {
                $window.print();
                try{
                    marketing.gaEvents();
                }
                catch(e){

                }
            },

            initStaticData: function () {
            },

            initScopeObjects: function () {
                $scope.currentProposal = {};
                $scope.asset = {};
                $scope.phContact = {};
                $scope.payment = {};
                $scope.productId = '';
                $scope.selectedCoverage = {};
            },

            initApiObjects: function () {
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.paymentApi = WfeApiPayment.instance($scope.$parent.sessionApi, $scope, $scope.payment);
            },

            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrieveCurrentProposal();
                };

                var retrieveCurrentProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                $scope.productId = $scope.currentProposal.ProductId;
                                $scope.currentProposal.isForUpdate = true;
                                retrieveAsset();
                            },
                            onError: function () {
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function () {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                $scope.asset.isForUpdate = true;

                                $scope.selectedCoverage = $filter('filter')($scope.asset.Coverages, {IsSelected: true})[0];

                                retrievePHContact();
                            },
                            onError: function () {
                                retrievePHContact();
                            }
                        }
                    );
                };

                var retrievePHContact = function () {
                    fn.phContactApi.retrievePHContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.phContact, data);
                                $scope.phContact.isForUpdate = true;
                                retrievePayment();
                            },
                            onError: function () {
                                retrievePayment();
                            }
                        }
                    );
                };

                var retrievePayment = function () {
                    fn.paymentApi.retrievePayment(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.payment, data);
                                $scope.payment.isForUpdate = true;
                                invalidateSession();
                                firingMarketing($scope.currentProposal.ExternalProposalNumber, $scope.currentProposal.TotalPremium, $scope.currentProposal.ProductId);
                            },
                            onError: function () {
                                firingMarketing($scope.currentProposal.ExternalProposalNumber, $scope.currentProposal.TotalPremium, $scope.currentProposal.ProductId);
                            }
                        }
                    );
                };
                var invalidateSession = function(){
                    //Invalidate the session once reached this page.
                    $scope.$parent.sessionApi.setToken('invalid');

                };

                var firingMarketing = function (quoteNo, revenue, productId) {
                    try {
                        marketing.ga(productId);
                    } catch (err) {
                        console.log(err);
                    }

                };

                startRetrievalScopeObject();
            },

            utils: {
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },
            },

            navigation: {},

            saving: {},

            validations: {},

            eventHandlers: {}


        };

        ////Initialize and retrievals
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;
        $scope.printWindow=fn.printWindow;
    }];