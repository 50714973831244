/*
 * Created by Nuwan Amarasinghe on 02/01/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$stateParams',
    'HKLocale',
    'WfeApiStaticData',

    /**
     * Controller for the Privacy Statement of Motor
     * This page only redirects the user to Motor Quote if he/she answered 'yes'
     * @param $scope
     * @param $state
     * @constructor
     */
    function MotorQuoteStartController($scope, $state, $stateParams, HKLocale, WfeApiStaticData) {

        var fn = {
            initStaticData: function () {
                var wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale() , $scope.$parent.product.ProductId);

                var startRetrieval = function () {
                    getBooleanListWithSometimes();
                };

                var getBooleanListWithSometimes = function () {
                    wfeApiStaticData.getBooleanListWithSometimes({
                        onSuccess: function (data) {
                            $scope.staticData.booleansSometimes = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                        }
                    });
                };

                startRetrieval();

            },

            initScopeObjects: function(){
                $scope.isAccepted = null;
                $scope.isTokenLoaded = false;
                $scope.staticData = {};
                $scope.initialAssessment = {};
            },

            isValidToProceed: function isValidToProceed(){

                if ($scope.isAccepted) {
                    $scope.$parent.setIsAccepted(true);
                    return true;
                }
                $scope.$parent.setIsAccepted(false);
                return false;
            },

            goToNext: function() {
                return fn.goToProduct('public.' + $scope.$parent.product.ProductCode + '.quote.steps.basic');
            },

            goToProduct: function(productState) {

                var isValid = fn.isValidToProceed();

                if (isValid) {
                    $state.go(productState);
                }

                return isValid;

            },

            eventHandlers: {

                onLoadingWithToken: function () {
                    var token = $stateParams.token;

                    if (!token) {
                        return false;
                    }

                    $scope.$parent.sessionApi.setToken(token);
                    $scope.isAccepted = true;
                    fn.goToNext();
                    return true;
                },

                btnNextClicked: function () {
				
                    var isValid = fn.isValidToProceed();

                    if (!isValid) {
                        return;
                    }

                    var callbacks =
                    {
                        onSuccess: function () {

                            fn.goToNext();
                        }
                    };

                    $scope.$parent.sessionApi.createSession(callbacks);

                }

            }

        };
        fn.initScopeObjects();
        fn.initStaticData();

        // if ($scope.HKLocale.getLocale() === "th-th") { 
        //     $window.cwcCookieBanner.setLang('th'); 
        // }else{
        //         $window.cwcCookieBanner.setLang('en');
        // }

        $scope.isTokenLoaded = !fn.eventHandlers.onLoadingWithToken();

        //Function declaration
        $scope.btnNextClicked = fn.eventHandlers.btnNextClicked;

        //if ($scope.isUserLoggedIn) {
        //    $scope.accountUser = new AccountUser();
        //    $scope.accountUser.$retrieve();
        //}

    }];