/*
 * Created by Chanon Tangputthajit 2021/04/26
 */
module.exports = [
    '$scope',
    '$state',
    '$anchorScroll',
    'DaModal',
    'StaticDataKeyValuePair',
    'WfeApiCurrentProposal',
    'WfeApiStaticData',
    'WfeApiPayment',
    'WfeApiConfirmation',
    'WfeApiPolicyContact',
    '$window',
    'HKFormValidation',
    'WfeApiContact',
    'WfeApiCoverages',
    '$filter',
    'WfeApiAsset',
    '$timeout',
    '$localStorage',
    '$sessionStorage',
    

    /**
     * Controller for the Buy of Quote
     * @constructor
     */
    function B2CBuyController($scope, $state, $anchorScroll, DaModal, StaticDataKeyValuePair, 
                                        WfeApiCurrentProposal,  WfeApiStaticData, 
                                        WfeApiPayment, WfeApiConfirmation, WfeApiPolicyContact,
                                        $window, HKFormValidation,
                                        WfeApiContact, WfeApiCoverages, $filter, WfeApiAsset, $timeout, $localStorage,$sessionStorage) {

        $anchorScroll();

        var marketing = {


        dLCE:function(ref,v)
        {
            $window.dataLayer = $window.dataLayer || [];
            var dataLayer = $window.dataLayer;
            switch(ref)
            {
                case 39:
                    if(v==1000001 || v== '1000001' ) {s='Single Payment'};
                    if(v==1000005 || v== '1000005' ) {s='Monthly Payment'};
                    dataLayer.push({
                    'event':'dataLayer-initialised',
                    'how-do-you-want-to-pay':s
                });
                    break;
                case 40:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'payment-method':v
                });
                    break;
                case 41:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'issuing-bank':v
                });
                    break;
                case 42:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'card-type':v
                });
                    break;
                case 43:
                    if(v==0 || v== '0' || v==false) {s='No'};
                    if(v==1 || v== '1' ||v ==true) {s='Yes'};
                    dataLayer.push({
                    'event':'dataLayer-initialised',
                    'would-you-like-to-choose-easy-renew':s
                });
                    break;
                case 44:
                    dataLayer.push({
                        'event':'dataLayer-initialised',
                        'callback-Priority':v
                    });
                    break;
                case 45:dataLayer.push({
                    'event':'dataLayer-initialised',
                    'card-type':v
                });
                    break;
                case 46:
                    if(v==1000001 || v== '1000001' ) {s='Single Payment'};
                    if(v==1000005 || v== '1000005' ) {s='Installment'};
                    dataLayer.push({
                    'event':'dataLayer-initialised',
                    'payment-method':s
                });
                    break; 
                case 47:
                    if (v === true) { s = 'Yes' } 
                    else { s = 'No' }
                    dataLayer.push({
                        'event':'dataLayer-initialised',
                        'consent-to-buy':s
                    });
                    break; 
                default: break;
            }

            // console.log(dataLayer);
        }
        };

        var fn = {
            postToMaxParent:function(callBackStatus,Priority,FinalCall){

                var monthlySinglePayment=($scope.currentProposal.PaymentTermId==1000005 || $scope.currentProposal.PaymentTermId=="1000005")?'Monthly':'Single Payment';
                var creditCardCounterService=($scope.currentProposal.PaymentModeId==4 || $scope.currentProposal.PaymentModeId=="4")?'Credit Card':'Counter Payment';
                var creditCardType=$scope.creditCard.CreditCardTypeString;
                var easyRenew=$scope.$parent.filterStatic($scope.staticData.booleans,$scope.currentProposal.AutoRenewalFlag);
                var sale=undefined;

                $window.maxarLayer = $window.maxarLayer || $localStorage.maxarLayer;
                var maxarLayer = $window.maxarLayer;
                maxarLayer.push( {

                    totalPremium:$scope.currentProposal.TotalPremium,
                    monthlySinglePayment:monthlySinglePayment,
                    creditCardCounterService:creditCardCounterService,
                    creditCardType:creditCardType,
                    easyRenew:easyRenew,
                    sale:sale,
                    message:$scope.callbackObj.message,
                    commercial:$scope.callbackObj.commercial,
                    type:'Quote lead',
                    sourcePage:'Buy',
                    callback:callBackStatus,
                    priority:Priority,
                    dataFinal:FinalCall,
                    referenceID:$scope.phContact.PrimaryPhoneNumber,
                    telephone:$scope.phContact.PrimaryPhoneNumber,

                });

                $localStorage.maxarLayer = maxarLayer

                //console.log(maxarLayer);

                if($scope.$parent !== null){
                    $scope.$parent.postMaxar();
                }
            },

            gtAnchor:function(x){
                if (!x)
                {return;}

                //alert(x);
                HKFormValidation.animateToID(x);
                $('html,body').animate({scrollTop:$(x).offset().top-150},1000)
                DaModal.close("#formInvalid")
            },

            gotoOnFocusAnchor: function () {

               if($scope.warningCounter == 1){
                    $('html,body').animate({scrollTop:$($scope.getFocusWarningID).offset().top-150},1000)
                }
                else{
                    $('html,body').animate({scrollTop:$('#card_detail').offset().top-150},1000)
                }
                
                DaModal.close("#formInvalid")

            },

            goToNextStep: function () {
                $state.go('public.' + $scope.product.ProductCode + '.quote.steps.done');
            },

            goToPrevStep: function () {
                fn.utils.setIsSaving(false);
                $state.go('publicB2C.motor.quote.steps.confirm');
            },
            goToCounterConfirmStep: function () {
                $state.go('public.' + $scope.product.ProductCode + '.quote.steps.counterdone');
            },




            ShowPaymentErrorMsg: function () {

                var validationNumberString = (data.ErrorInfo.ValidationNumber || '') + '';
                $scope.cyberResponseId = validationNumberString;

                DaModal.open('#cyberSourceResponse');

            },


            initScopeObjects: function () {
                $scope.currentdate = new Date();
                $scope.isBtnSaveHide = true;
                $scope.currentProposal = {};
                $scope.creditCard = {};
                // $scope.paymentConsentPrivacyFlag = false;
                $scope.isBtnBackHide = false;  // Hide back button
                $scope.buyControllerStaticData = {
                    allPaymentTerms: [],
                    products: [],
                    localePaymentTerms:[]
                };
                $scope.phContact = {};
                $scope.currentProposal.agreeInstallments=false;
                $scope.policyHolder={};
                $scope.coverages = {};
                $scope.selectedCoverage = {};
                $scope.changeNextCopy=true;
                $scope.proceedBuy=true;
                $scope.staticData={};
                $scope.callbackObj={};
                $scope.showSaveAndExitOnBuyPage=false;
                $scope.showConfirmBuyPageB2C=true;
                $scope.showConfirmBuyPageOnlineSelling=false;
                $scope.asset = {};

                // For OptinMonster Checker
                $sessionStorage.currentPage = 5; 
                $scope.Ca = $sessionStorage.currentPage
            },

            initApiObjects: function () {
                
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                $scope.buyControllerStaticData.products = StaticDataKeyValuePair.getProducts();
                $scope.buyControllerStaticData.allPaymentTerms = StaticDataKeyValuePair.getAllPaymentTerms();
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);
                fn.wfeApiStaticDataPaymentPlan = new WfeApiStaticData($scope, $scope.HKLocale.getLocale(), 4);
                fn.paymentApi = WfeApiPayment.instance($scope.$parent.sessionApi, $scope, $scope.creditCard);
                fn.phContactApi = WfeApiContact.instance($scope.$parent.sessionApi, $scope, $scope.phContact);
                fn.coverageApi = WfeApiCoverages.instance($scope.$parent.sessionApi, $scope, $scope.coverages);
                fn.policyHolderApi = WfeApiPolicyContact.instance($scope.$parent.sessionApi, $scope, $scope.policyHolder);
                fn.assetApi = WfeApiAsset.instance($scope.$parent.sessionApi, $scope, $scope.asset);

            },
            initStaticData: function () {

                var wfeApiStaticDataPaymentPlan = fn.wfeApiStaticDataPaymentPlan;

                var startRetrieval = function () {
                    getBooleanList();
                };

                var getBooleanList = function () {
                    fn.wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getPaymentTermList();
                        },
                        onError: function () {
                            getPaymentTermList();
                        }
                    });
                };

                var getPaymentTermList = function () {
                    wfeApiStaticDataPaymentPlan.getPaymentTermList({
                        onSuccess: function (data) {
                            $scope.buyControllerStaticData.localePaymentTerms = data;
                        },
                        onError: function () {
                            /* nothing to do */
                        }
                    });
                };

                startRetrieval();

            },

            retrieveScopeObjects: function () {
                var startRetrievalScopeObject = function () {
                    retrieveCoverages();
                };

                var retrieveCoverages = function () {
                    fn.coverageApi.retrieveCoverages({
                        onSuccess: function (data) {
                            angular.extend($scope.coverages, data);
                            var selectedCoverage = $filter('filter')(data.Coverages, {IsSelected: true})[0];

                            if (selectedCoverage) {
                                angular.extend($scope.selectedCoverage, selectedCoverage);
                            }
                            retrieveProposal();
                        },
                        onError: function () {
                            retrieveProposal();
                        }
                    });
                };

                var retrieveProposal = function () {
                    fn.currentProposalApi.retrieveCurrentProposal({
                            onSuccess: function (data) {
                                angular.extend($scope.currentProposal, data);
                                $scope.currentProposal.isForUpdate = true;
                                $scope.isRenewable = $scope.$parent.product.isRenewable;
                                $scope.currentProposal.AutoRenewalFlag = $scope.isRenewable;
                                //$scope.currentProposal.PaymentTermId="1000001"; //single only
                                if($scope.currentProposal.PaymentModeId == 4){
                                    marketing.dLCE(42, 'Credit Card')
                                }
                                else{
                                    marketing.dLCE(42, 'Debit Card')
                                }

                                if($scope.currentProposal.PaymentTermId != undefined && $scope.currentProposal.PaymentTermId != ''){
                                    marketing.dLCE(46, $scope.currentProposal.PaymentTermId);
                                }
                                retrievePolicyHolder();
                            },
                            onError: function () {
                                /* nothing to do */
                            }
                    });
                };

                fn.phContactApi.retrievePHContact(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.phContact, data);
                        }
                    }
                );

                var retrievePolicyHolder = function () {
                    fn.policyHolderApi.retrievePHPolicyContact(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.policyHolder, data);
                                retrieveAsset();
                            }
                        }
                    );
                };

                var retrieveAsset = function() {
                    fn.assetApi.retrieveAsset(
                        {
                            onSuccess: function (data) {
                                angular.extend($scope.asset, data);
                                //Set PlanId for parent
                                $scope.$parent.PlanId = $scope.asset.PlanId;
                            }
                        }
                    );
                };

                startRetrievalScopeObject();
            },

            issuePolicy: function (creditCard) {
                fn.utils.setIsSaving(false);

                if (!creditCard) {
                    console.error('No credit card object provided');
                    return;
                }

                //fn.setIsPageSaving(false);
                var parameters = {
                    token: creditCard.MITSessionToken,
                    productState: $scope.product.ProductCode
                };

                $state.go('public.' + $scope.product.ProductCode + '.quote.steps.issue', parameters);
            },

            utils: {
                isRenewalProposal: function () {
                    var result = false;
                    if ($scope.currentProposal) {
                        if ($scope.currentProposal.IsRenewalProposal) {
                            result = $scope.currentProposal.IsRenewalProposal;
                        }
                    }
                    return result;
                },

                setIsSaving: function (isSaving) {
                    $scope.DaLoadingMask.toggle(isSaving);
                }
            },

            saving:{
                savePHContactCallback: function () {
                    //onsuccess of MAXAR
                        try{
                            $scope.$parent.gaEvents(6,3);
                        }
                        catch(e){}

                    $scope.$parent.maxarCallbackTrigger = true;
                    fn.postToMaxParent('No',1,'Y');

                },
            },

            validations: {
                hasInvalid: function hasInvalid() {
                    
                    var isInvalid = false;

                    if (fn.validations.validateForms()) {
                        isInvalid = true;
                    }

                    var focusError = function () {
                        HKFormValidation.focusOnError();
                        $timeout.cancel(focusError);
                    };

                 

                    if (!$scope.paymentConsentPrivacyFlag) {
                        isInvalid = true;
                        //$('#consentpopup').popover('show');
                        // $scope.policyHolder.ConsentId = 7000001;
                    } 

                    else {
                        //$('#consentpopup').popover('hide');
                        // $scope.policyHolder.ConsentId = 7000000;
                    }

                    return isInvalid;
                },

                validateForms: function () {
                    return $scope.frmPayment.$invalid;
                },
            },

            eventHandlers: {

                onBtnCallbackClicked :function(){
                    DaModal.open('#quotationCallback');

                },
                onBtnCallbackContinueClicked: function () {

                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        return;
                    }

                    $scope.pageSubmitted = true;


                    if ($scope.phContact.PrimaryEmailAddress.invalid
                        || $scope.phContact.PrimaryPhoneNumber.invalid) {
                        return;
                    }
                    fn.saving.savePHContactCallback();
                },
                onBtnCallbackCancelClicked: function () {
                    /*** regex will be false when phone number is invalid */
                    var regex = /^[0][6|8|9][0-9]\d{7}/;
                    if (!regex.test($scope.phContact.PrimaryPhoneNumber)) {
                        $scope.phContact.PrimaryPhoneNumber = "";
                    }

                    DaModal.close('#quotationCallback');
                },

                onBtnSaveAndExitClicked: function () {
                    try{

                        $scope.$parent.gaEvents(0,2);
                    }
                    catch(e){}
                    $scope.$parent.maxarCallbackTrigger = true;
                    fn.postToMaxParent('No',1,'Y')


                },
                onBtnPartialSaveContinueClicked: function () {
                    if($scope.phContact.PrimaryEmailAddress != $scope.phContact.PrimaryEmailAddressReenter){
                        $scope.IsReEmailMismatch = true;
                        return;
                    }

                    $scope.pageSubmitted = true;
                    if ($scope.phContact.PrimaryEmailAddress.invalid || $scope.phContact.PrimaryEmailAddressReenter.invalid) {
                        return;
                    }
                    fn.saving.partialSave();

                },
                onBtnPartialSaveCancelClicked: function () {
                    DaModal.close('#quotationPartialSave');
                },

                doBuyFormValidation:function()
                {
                    return $scope.frmPayment.$invalid&&$scope.frmPayment.$invalid;
                    // return $scope.frmPaymentMethods.$invalid&&$scope.frmCreditCard.$invalid;
                },
                errorOnRequest: function () {
                    fn.utils.setIsSaving(false);
                    console.error('ERR: There was a problem when saving information. ');
                },

                onBtnNextClicked: function () {
                    $scope.pageSubmitted = true;
                    // to do about "warning wording"            
                    $scope.warningCounter = 0
                    $scope.getFocusWarningID = ''
                    
                    // Warning : Credit card number is required
                    if($scope.frmPayment.AccountNumber.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#AccountNumber'
                    }

                    // Warning : Credit card type is required
                    if($scope.frmPayment.CreditCardTypeString.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#creditcardtype'
                    }

                    // // Warning : Credit Card Holder Name is required
                    if($scope.frmPayment.CreditCardHolderName.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#CreditCardHolderName'
                    }

                    // // Warning : Expired is required
                    if($scope.frmPayment.ExpMonth.$invalid === true || $scope.frmPayment.ExpYear.$invalid === true  ){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#CreditCardExpired'
                    }
                    
                    // // Warning : Card Verfication Number is required
                    if($scope.frmPayment.CardVerficationNumber.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#CardVerficationNumber'
                    }

                    // Warning : Issuing Bank External Contact Number is required
                    if($scope.frmPayment.IssuingBankExternalContactNumber.$invalid === true){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#IssuingBankExternalContactNumber'
                    }
                    
                    // Warning : Other Bank External Contact Number is required
                    if($scope.frmPayment.IssuingBankExternalContactNumber.$invalid === false 
                        && $scope.frmPayment.OtherBankName !== undefined){
                        if($scope.frmPayment.OtherBankName.$invalid === true)  
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#OtherBankName'
                    }

                    // Warning : Consent is required
                    if(($scope.paymentConsentPrivacyFlag === false)||($scope.paymentConsentPrivacyFlag === undefined)){
                        $scope.warningCounter += 1
                        $scope.getFocusWarningID = '#paymentConsent_message_label'
                    }

                    if (fn.validations.hasInvalid()) {
                        DaModal.open("#formInvalid")
                        return
                    }

                    if($scope.paymentTermSelectedId !== "") {
                        $scope.currentProposal.PaymentTermId = $scope.paymentTermSelectedId;
                    }                 

                    try{ $scope.$parent.gaEvents(0,0); }
                    catch(e){ /* do nothing */ }
                    
                    // $scope.pageSubmitted = true;
                    var isInValid = fn.eventHandlers.doBuyFormValidation();

                    if (isInValid) {
                        return;
                    }

                    var selectedCoverage = $filter('filter')($scope.coverages.Coverages, {IsSelected : true})[0];
                    var newMaxarPrior;

                    if(selectedCoverage.CoverTypeId === 2000017){
                        // Type 2+
                        newMaxarPrior = 4;
                    }
                    else{
                        // Other
                        newMaxarPrior = 1;
                    }

                    fn.postToMaxParent('No', newMaxarPrior,'Y')
                    //confirmation again- as PaymentMethod might have changed.

                    //return to counter done- if was counter services.
                    //public.motor.quote.steps.counterdone
                    var saveCurrentProposal = function () {
                        
                        var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                        $scope.currentProposal.ProductId = $scope.$parent.product.ProductId;

                        fn.currentProposalApi[saveCurrentProposalFunc]({
                                onSuccess: function () {
                                    proceedWhere();
                                },
                                onError: function () {
                                    $scope.hasError = true;
                                    proceedWhere();
                                }
                            }
                        );
                    };

                    var proceedWhere =function(){   
                        //counter
                        if($scope.currentProposal.PaymentModeId == 2000003){
                            createConfirmation();
                        }
                        else{
                            fn.eventHandlers.onBtnNextProceed();
                        }
                    };

                    var createConfirmation = function () {
                        fn.utils.setIsSaving(true);
                        fn.confirmationApi.createConfirmation({
                            onSuccess: function () {
                                savingComplete();
                            },
                            onError: function () {
                                $scope.hasError = true;
                                savingComplete();
                            }
                        });
                    };

                    var savingComplete= function(){
                        fn.utils.setIsSaving(false);

                        //counter
                        if($scope.currentProposal.PaymentModeId == 2000003){
                            if(!$scope.hasError){
                                fn.goToCounterConfirmStep();
                            }
                            else{
                                //error. Counter didnt updated.
                                DaModal.open('#failedCounter');
                            }
                        }
                        else{
                            fn.eventHandlers.onBtnNextProceed();
                        }
                    };

                    //update first for Payment term Id
                    //if counter payment- the also have to change-PaymentTermId ( Diff From Single/ Monthly for Credit card)

                    //counter
                    if($scope.currentProposal.PaymentModeId == 2000003){
                        $scope.currentProposal.PaymentTermId = 2000001;
                    }
                    saveCurrentProposal();
                },

                onBtnNextProceed: function(){
                    var callbacks = {
                        enableLoadingMask: function () {
                            fn.utils.setIsSaving(true);
                        },
                        onPaymentSuccess: fn.issuePolicy,
                        onPaymentError: fn.eventHandlers.errorOnRequest
                    };

                    $scope.totalPremium = 100;
                    //abtasty removed by Rak 14 June 2022 ----- $scope.$parent.abTastyTracking(7);
                    //update currentproposal
                    var saveCurrentProposalFunc = fn.currentProposalApi.utils.getSaveFunctionName($scope.currentProposal, 'CurrentProposal');
                    if ($scope.currentProposal.IsRenewalProposal) {
                        fn.currentProposalApi[saveCurrentProposalFunc](
                            {
                                onSuccess: function () {
                                    $scope.PaymentScope.doPayment(undefined, $scope.currentProposal.ProductId, $scope.currentProposal.IsRenewalProposal, $scope.totalPremium, callbacks);
                                },
                                onError: function () {
                                }
                            }
                        );
                    } else {
                        $scope.PaymentScope.doPayment(undefined, $scope.currentProposal.ProductId, $scope.currentProposal.IsRenewalProposal, $scope.totalPremium, callbacks);
                    }
                },

                onBtnBackClicked: function () {
                    try{
                        $scope.$parent.gaEvents(0,1);
                    }
                    catch(e){}
                    fn.utils.setIsSaving(true);
                    fn.goToPrevStep();
                },

                openPanelview:function(p1){
                    if (!p1)
                        return;

                    fn.gtAnchor(p1);
                },

                onShowPanel:function(x) {
                    $(x).show();
                },

                onHidePanel:function(x) {
                    $(x).hide();
                },

                setPaymentSchedule:function(key){
                    $scope.currentProposal.PaymentTermId = key;
                    if(key=='1000005' || key==1000005)
                    {
                        //monthly
                        $scope.currentProposal.PaymentModeId=null;
                    }
                    else
                    { //single

                    }

                },


                setPaymentMethod:function(method){
                    if(method=='credit')
                    {
                        $scope.currentProposal.PaymentModeId=4;
                        marketing.dLCE(42, 'Credit Card')
                    }
                    else
                    {
                        $scope.currentProposal.PaymentModeId=7000001;
                        marketing.dLCE(42, 'Debit Card')
                    }

                    $scope.$parent.setSelectedPayment(method);
                },

                seeFullPayment:function(flag){
                    $scope.fullPayment = !flag;
                },

                onFocusReEmail: function(){
                    $scope.IsReEmailMismatch = false;
                },

                isErrorBoxNeeded: function(){
                    const errorCodeList = ['204', '208', '205', '203', '216', '231'];
                    const matched = errorCodeList.includes($scope.cyberResponseId)
                    return matched;
                }
            },

            setConsent: function (val) {
                $scope.paymentConsentPrivacyFlag = val;
            },
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

        //For oyster demo
        $scope.onShowPanel = fn.eventHandlers.onShowPanel;
        $scope.onHidePanel = fn.eventHandlers.onHidePanel;
        $scope.onOpenPanelview = fn.eventHandlers.openPanelview;

        $scope.setPaymentSchedule = fn.eventHandlers.setPaymentSchedule; //used this in development.
        $scope.setPaymentMethod = fn.eventHandlers.setPaymentMethod;

        //For oyster demo

        $scope.seeFullPayment = fn.eventHandlers.seeFullPayment;
        $scope.gtAnchor = fn.gtAnchor;
        $scope.gotoOnFocusAnchor = fn.gotoOnFocusAnchor;
        $scope.onBtnNextClicked = fn.eventHandlers.onBtnNextClicked;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;

        $scope.utils = {};
        $scope.utils.getStaticDataDisplay = fn.wfeApiStaticData.utils.getStaticDataDisplay;

        $scope.stepFn = {};
        $scope.stepFn.isRenewalProposal = fn.utils.isRenewalProposal;

        $scope.onBtnSaveAndExitClicked = fn.eventHandlers.onBtnSaveAndExitClicked;
        $scope.onBtnPartialSaveContinueClicked = fn.eventHandlers.onBtnPartialSaveContinueClicked;
        $scope.onBtnPartialSaveCancelClicked = fn.eventHandlers.onBtnPartialSaveCancelClicked;
        $scope.onBtnCallbackClicked = fn.eventHandlers.onBtnCallbackClicked;
        $scope.onBtnCallbackContinueClicked = fn.eventHandlers.onBtnCallbackContinueClicked;
        $scope.onBtnCallbackCancelClicked = fn.eventHandlers.onBtnCallbackCancelClicked;
        $scope.divertAfterSECB=$scope.$parent.divertAfterSECB;
        $scope.onFocusReEmail = fn.eventHandlers.onFocusReEmail;
        $scope.setConsent = fn.setConsent;
        $scope.isErrorBoxNeeded = fn.eventHandlers.isErrorBoxNeeded;
        $scope.getSelectedPaymentTermIDFromFourthPage = $sessionStorage.getPaymentTermIDtoFourthPage
        $scope.isMobile = $window.matchMedia("(max-width: 768px)").matches;

        $scope.dLCE=marketing.dLCE;
        $scope.$on('$viewContentLoaded', function() {
            $timeout(function(){
                $scope.$parent.changeGlobalContactNo();
            }, 1000);
        });
        
        
    }];