/*
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$state',
    '$filter',
    '$anchorScroll',
	'DaLoadingMask',
	'Policy',
	'DaModal',
    'WfeApiStaticData',
    'WfeApiConfirmation',
    'WfeApiCurrentProposal',
    /**
     * Controller for the Decline Renewal Controller
     * @constructor
     */
	 
	 function DeclineRenewalController($scope, $state, $filter, $anchorScroll, DaLoadingMask,Policy,
                                       DaModal,WfeApiStaticData,WfeApiConfirmation,WfeApiCurrentProposal) {
        $anchorScroll();
		
		var fn = {


            initStaticData:function(){
                var getDeclineReason = function getDeclineReason(){

                    fn.wfeApiStaticData.getDeclineReasonList({
                        onSuccess:function(data){
                            $scope.declineReason = data;
                        },
                        onError:function(){
                            console.log('error');
                        }
                    });

                };
                getDeclineReason();
            },
            initApiObjects: function () {
                fn.confirmationApi = WfeApiConfirmation.instance($scope.$parent.sessionApi, $scope);
                fn.currentProposalApi = WfeApiCurrentProposal.instance($scope.$parent.sessionApi, $scope, $scope.currentProposal);
                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), $scope.$parent.product.ProductId);

                },
            initScopeObjects: function () {
                $scope.currentProposal = {};
                $scope.confirmation = {};
				$scope.declineReason = [];
            },
            retrieveScopeObjects:function(){
                fn.currentProposalApi.retrieveCurrentProposal(
                    {
                        onSuccess: function(data) {
                            angular.extend($scope.currentProposal, data);

                            //angular.extend($scope.policy, data);
                        }
                    }
                );
            },
            //initWatchers: function(){
            //    var //policyHolderWatcher,
            //        currentProposalWatcher,
				//	//coverageWatcher;
            //        confirmationWatcher;
				//	//confirmationCreateWatcher;
            //
            //    var pAndCCallBack = function(newValueModel){
            //        if (!newValueModel) {
            //            return;
            //        }
            //
            //        currentProposalWatcher = $scope.$parent.$on('CurrentProposal/Bind', currentProposalCallBack);
            //        //policyHolderWatcher = $scope.$parent.$on('PolicyHolder/Bind', policyHolderCallBack);
            //
            //        //coverageWatcher = $scope.$parent.$on('Coverages/Bind', coverageCallBack);
            //
            //        confirmationWatcher = $scope.$parent.$on('Confirmation/Bind', confirmationCallBack);
            //        //confirmationCreateWatcher = $scope.$parent.$on('Confirmation/Create', confirmationCreateCallBack);
            //
            //        if ($scope.$parent.mitScopeBindingUtils) {
            //            $scope.$parent.mitScopeBindingUtils.firstBind();
            //        }
            //    };
            //
            //    var currentProposalCallBack = function(eventObj, newValue){
            //        angular.extend($scope.currentProposal, newValue);
            //        if (newValue.ProductId) {
            //            //$scope.$parent.mitScopeBindingUtils.retrieveCoverage();
            //            fn.getDeclineReason(newValue.ProductId);
				//		//$scope.declineReason = fn.getDeclineReason(newValue.ProductId);
            //
				//		if(!newValue.IsRenewalProposal)//checking if it is renewal
				//		 { $state.go('private.account.user.policies'); }
            //        }
            //    };
            //
            //    var confirmationCallBack = function(eventObj, newValue){
            //        angular.extend($scope.confirmation, newValue);
            //    };
            //
            //
            //    var destroyWatcher = $scope.$on('$destroy',
            //        function () {
            //            //policyHolderWatcher();
            //            currentProposalWatcher();
            //            //coverageWatcher();
            //
            //            confirmationWatcher();
            //            //confirmationCreateWatcher();
            //
            //            destroyWatcher();
            //        }
            //    );
            //
            //    pAndCCallBack({});
            //},

			setIsSaving: function (isSaving) {
                if (!angular.isDefined(isSaving)) {
                    return;
                }

                $scope.IsSaving = isSaving;

                DaLoadingMask.toggle(isSaving);
            },

            saving:{
                saveAllModel:function(){
                    var triggerSave = function () {
                        updateCurrentProposal();
                    };

                    var updateCurrentProposal=function(){
                        fn.currentProposalApi.updateCurrentProposal(
                            {
                                onSuccess: function() {
                                    //console.log('success updateCurrentProposal');
                                    createConfirmation();
                                },
                                onError:function(){
                                    console.log('error updateCurrentProposal');
                                }
                            }
                        );
                    };

                    var createConfirmation= function () {
                        fn.confirmationApi.createConfirmation({
                            onSuccess:function(){
                                //console.log('success createConfirmation');
                                fn.navigation.goToSuccessful();
                            },
                            onError:function(){
                                console.log('error createConfirmation');
                                fn.navigation.ProcessedOnError();
                            }
                        });
                        //$scope.$parent.mitScopeBindingUtils.saveConfirmation($scope.confirmation, fn.goToSuccessful, fn.ProcessedOnError);
                    };

                    triggerSave();
                }

            },

			navigation:{
                goToSuccessful: function () {
                    fn.setIsSaving(false);
                    $state.go('public.'+ $scope.$parent.product.ProductCode +'.quote.steps.declinerenewalsuccess'); //successfully page
                },

                ProcessedOnError: function () {
                    DaModal.alert('Sorry we are unable to process your request at the moment.');
                    fn.setIsSaving(false);
                    $state.go('private.account.user.policies');
                },
            },

			
			eventHandlers:{
                onBtnSubmitClicked : function (){
                    $scope.pageSubmitted = true;
                    if(!$scope.frmAboutPolicyDecline.DeclineReasonId.$invalid)
                    {
                        fn.setIsSaving(true);
                        $scope.currentProposal.IsDeclineProposal = true;
                        fn.saving.saveAllModel();
                    }
                },
                onBtnBackClicked : function () {
                    $state.go('private.account.user.policies');
                }
            }


		};
			

			

		

		
        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();

		$scope.onBtnSubmitClicked = fn.eventHandlers.onBtnSubmitClicked;
        $scope.onBtnBackClicked = fn.eventHandlers.onBtnBackClicked;


		}];