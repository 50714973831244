/*
 * Description :
 * app.js (daModule) encapsulates "Application Wide" related code into a single unit of code and contains Providers,
 * Services, Factory, Directives and Third Party (Dependency) Objects. app.js (daModule) will be merged in a bundle.js file and
 *
 * Version Notes :
 * 13 Apr 2015 - [Gerald] Initial version
 * 20 Aug 2015 - [C.K.] Clean up the code and re-organized.
 * 29 Nov 2016 -[OB and Suhas] Rename HK Modules to TH
 */

var routes = require('./routes.js');
var wfeBase = require('DA.WFE');
var wthCountryLevelModule = require('./src/module/module.js');

var daModule = angular.module('da-app', [
    'ngAnimate',
    'ngSanitize',
    'ngStorage',
    'ui.router',
    /* 'ui.router.css', */
    'ngCookies',
    wthCountryLevelModule.name,
    wfeBase.name,
    'ngIdle'
]);


var userStateController = require('./src/controller/userstate/userstate-controller.js'),
    rootController = require('./src/controller/root/root-controller.js'),
    paymentController = require('./src/controller/payment/payment-controller.js'),

    // *** myAccount ***D:\SVN\Web-Tier\WHK\trunk\app\src\controller\account
    accountController = require('./src/controller/account/account-controller.js'),
    policyAccountPoliciesController = require('./src/controller/policy/account/policies-controller.js'),
    policyAccountProposalsController = require('./src/controller/policy/account/proposals-controller.js'),
    policyViewPolicyController = require('./src/controller/policy/account/view-policy-controller.js'),
    policyUserTabController = require('./src/controller/account/user/user-tab-controller.js'),
    myProfileController = require('./src/controller/account/user/myprofile-controller.js'),
    myProfileFormPersonalDetailsController = require('./src/controller/account/user/form-personal-details-controller.js'),
    myProfileFormPasswordController = require('./src/controller/account/password/form-password-controller.js'),
    loginController = require('./src/controller/login/login-controller.js'),
    loginLandingController = require('./src/controller/login/loginlanding-controller.js'),
    forgotPasswordController = require('./src/controller/login/forgot-password-controller.js'),
    changePasswordController = require('./src/controller/account/password/change-password-controller.js'),
    // *** All Products ***
    partialSaveController = require('./src/controller/product/quote/partial-save-controller.js'),
    issuePolicyController = require('./src/controller/product/quote/issue-policy-controller.js'),
    asiapayCallbackController = require('./src/controller/product/quote/asiapay-callback-controller.js'),
    cybersourceCallbackController = require('./src/controller/product/quote/cybersource-callback-controller.js'),
    asiapayerrorCallbackController = require('./src/controller/product/quote/asiapayerror-callback-controller.js'),
    productQuoteBuyController = require('./src/controller/product/quote/buy-controller.js'),
    sessionTokenController = require('./src/controller/product/quote/sessiontoken-controller.js'),
    viewRenewalProposalController = require('./src/controller/product/quote/viewRenewalProposal-controller.js'),
    confirmPaymentController = require('./src/controller/product/quote/confirm-payment-controller.js'),
    confirmCounterController = require('./src/controller/product/quote/confirm-counter-controller.js'),
    declineRenewalController = require('./src/controller/product/quote/decline-renewal-controller.js'),
    declineRenewalSuccessController = require('./src/controller/product/quote/decline-renewal-success-controller.js'),
    pageRouterController = require('./src/controller/pagerouter/partial-save-router-controller.js'),

    // *** Motor Car ***
    motorQuoteBaseQuoteController = require('./src/controller/motor/quote/base-quote-controller.js'),
    motorQuoteDriverController = require('./src/controller/motor/quote/driver-controller.js'),
    motorQuoteStartController = require('./src/controller/motor/quote/start-controller.js'),
    motorQuoteStepsController = require('./src/controller/motor/quote/steps-controller.js'),
    motorQuoteBasicController = require('./src/controller/motor/quote/basic-controller.js'),
    motorQuoteCoverController = require('./src/controller/motor/quote/cover-controller.js'),
    motorQuoteCustomiseCoverController = require('./src/controller/motor/quote/customise-cover-controller.js'),
    motorQuoteQuoteController = require('./src/controller/motor/quote/quote-controller.js'),
    motorQuoteConfirmController = require('./src/controller/motor/quote/confirm-controller.js'),

    // *** New confirm&buy for OIC ***
    motorQuoteConfirmBuyMixController = require('./src/controller/motor/quote/confirm-buy-mix-controller.js'),

    // *** JTestController ***
    WidgetSessionTokenController = require('./src/controller/widget/widget-session-controller.js'),
    WidgetController = require('./src/controller/widget/widget-controller.js'),
    WidgetRouterVariantController = require('./src/controller/widget/widget-router-variant-controller'),
    newsessionTokenController = require('./src/controller/product/quote/newsessiontoken-controller.js'),

    // *** Single Page App ***
    SinglePageAppController = require('./src/controller/motor/quote/spa-controller.js'),

    // *** Broker App ***
    BrokerChangePasswordController = require('./src/controller/account/password/b2b-change-password-controller.js'),
    BrokerQuoteStepsController = require('./src/controller/motor/quote/broker-quote.step-controller.js'),
    B2BUserstateController = require('./src/controller/userstate/b2b-userstate-controller.js'),
    BrokerAppController = require('./src/controller/motor/quote/b2b-controller.js'),
    BrokerSessionTokenController = require('./src/controller/product/quote/brokersessiontoken-controller.js'),
    BrokerForgotPasswordController = require('./src/controller/login/broker-forgot-password-controller.js'),
    BrokerPolicyAccountProposalsController = require('./src/controller/policy/account/broker-proposals-controller.js'),
    BrokerPolicyAccountPoliciesController = require('./src/controller/policy/account/broker-policies-controller.js'),
    BrokerPolicyViewPolicyController = require('./src/controller/policy/account/broker-view-policy-controller.js'),

    // *** Easy renew mockup ***
    easyRenewController = require('./src/controller/motor/quote/easy-renew.controller.js'),
    easyRenewPaymentController = require('./src/controller/payment/easyrenew-payment-controller.js'),
    easyRenewRouterController = require('./src/controller/pagerouter/easy-renew-router-controller.js'),

    // *** Agent login ***
    AgentLoginController = require('./src/controller/login/agent-login-controller.js'),

    // *** Agent login ***
    BrokerLoginController = require('./src/controller/login/broker-login-controller.js');

    // *** MGM router ***
    MGMRouterController = require('./src/controller/pagerouter/mgm-router-controller.js'),

    // *** B2C ***
    B2CQuoteStepsController = require('./src/controller/motor/quote/b2c-quote.step-controller.js'),
    B2CAppController = require('./src/controller/motor/quote/b2c-app-controller'),
    B2CSessionTokenController = require('./src/controller/product/quote/b2c-sessiontoken-controller.js'),
    B2CConfirmController = require('./src/controller/motor/quote/b2c-confirm-controller.js'),
    B2CBuyController = require('./src/controller/product/quote/b2c-buy-controller.js'),
    B2CV2AppController = require('./src/controller/motor/quote/b2cv2-app-controller'),
    B2CPaymentController = require('./src/controller/payment/b2c-payment-controller.js'),
    B2CCybersourceCallbackController = require('./src/controller/product/quote/b2c-cybersource-callback-controller.js'),
    B2CissuePolicyController = require('./src/controller/product/quote/b2c-issue-policy-controller.js'),
    B2CconfirmPaymentController = require('./src/controller/product/quote/b2c-confirm-payment-controller.js'),
    B2CMTISuccessController = require('./src/controller/product/quote/b2c-mti-success-controller.js'),
    UserstateController = require('./src/controller/userstate/userstate-controller.js'),


/* Instantiation of Application Module Objects i.e. Factory, Services and Directive */
daModule
    .controller('RootController', rootController)
    .controller('UserstateController', userStateController)
    .controller('PaymentController', paymentController)
    .controller('PageRouterController', pageRouterController)

    // *** MyAccount ***
    .controller('AccountController', accountController)
    .controller('PolicyAccountPoliciesController', policyAccountPoliciesController)
    .controller('PolicyAccountProposalsController', policyAccountProposalsController)
    .controller('PolicyViewPolicyController', policyViewPolicyController)
    .controller('PolicyUserTabController', policyUserTabController)
    .controller('MyProfileController', myProfileController)
    .controller('MyProfileFormPersonalDetailsController', myProfileFormPersonalDetailsController)
    .controller('MyProfileFormPasswordController', myProfileFormPasswordController)
    .controller('LoginController',loginController)
    .controller('LoginLandingController',loginLandingController)

    .controller('ForgotPasswordController',forgotPasswordController)
    .controller('ChangePasswordController', changePasswordController)

    // *** Product ***
    .controller('PartialSaveController', partialSaveController)
    .controller('IssuePolicyController', issuePolicyController)

    .controller('AsiaPayCallbackController', asiapayCallbackController)
    .controller('CybersourceCallbackController', cybersourceCallbackController )
    .controller('AsiapayErrorCallbackController', asiapayerrorCallbackController)
    .controller('ProductQuoteBuyController', productQuoteBuyController)
    .controller('SessionTokenController', sessionTokenController)
    .controller('ViewRenewalProposalController', viewRenewalProposalController)
    .controller('ConfirmPaymentController', confirmPaymentController)
    .controller('ConfirmCounterController', confirmCounterController)
    .controller('DeclineRenewalController', declineRenewalController )
    .controller('DeclineRenewalSuccessController', declineRenewalSuccessController )

    // *** Motor Car ***
    .controller('MotorQuoteBaseQuoteController', motorQuoteBaseQuoteController)
    .controller('MotorQuoteDriverController', motorQuoteDriverController)
    .controller('MotorQuoteStartController', motorQuoteStartController)
    .controller('MotorQuoteStepsController', motorQuoteStepsController)
    .controller('MotorQuoteBasicController', motorQuoteBasicController)
    .controller('MotorQuoteCoverController', motorQuoteCoverController)
    .controller('MotorQuoteCustomiseCoverController', motorQuoteCustomiseCoverController)
    .controller('MotorQuoteQuoteController', motorQuoteQuoteController)
    .controller('MotorQuoteConfirmController', motorQuoteConfirmController)

    // *** New confirm&buy for OIC ***
    .controller('MotorQuoteConfirmBuyMixController', motorQuoteConfirmBuyMixController)

    // *** Widget Controller ***
    .controller('WidgetSessionTokenController', WidgetSessionTokenController)
    .controller('WidgetController', WidgetController)
    .controller('WidgetRouterVariantController', WidgetRouterVariantController)

    // *** New quote page for test ***
    .controller('NewSessionTokenController', newsessionTokenController)

    // *** Single Page App controller ***
    .controller('SinglePageAppController', SinglePageAppController)

    // *** Broker App controller ***
    .controller('BrokerQuoteStepsController', BrokerQuoteStepsController)
    .controller('BrokerAppController', BrokerAppController)
    .controller('BrokerSessionTokenController', BrokerSessionTokenController)
    .controller('B2BUserstateController', B2BUserstateController)
    .controller('BrokerChangePasswordController', BrokerChangePasswordController)
    .controller('BrokerForgotPasswordController', BrokerForgotPasswordController)
    .controller('BrokerPolicyAccountProposalsController', BrokerPolicyAccountProposalsController)
    .controller('BrokerPolicyAccountPoliciesController', BrokerPolicyAccountPoliciesController)
    .controller('BrokerPolicyViewPolicyController', BrokerPolicyViewPolicyController)

    // *** Easy renew controller ****
    .controller('EasyRenewController', easyRenewController)
    .controller('EasyRenewPaymentController', easyRenewPaymentController)
    .controller('EasyRenewRouterController', easyRenewRouterController)

    .controller('AgentLoginController', AgentLoginController)
    .controller('BrokerLoginController', BrokerLoginController)

    // *** MGM router ***
    .controller('MGMRouterController', MGMRouterController)

    // *** B2C (New portal) ***
    .controller('B2CQuoteStepsController', B2CQuoteStepsController)
    .controller('B2CAppController', B2CAppController)
    .controller('B2CSessionTokenController', B2CSessionTokenController)
    .controller('B2CConfirmController', B2CConfirmController)
    .controller('B2CBuyController', B2CBuyController)
    .controller('B2CV2AppController', B2CV2AppController)
    .controller('B2CPaymentController', B2CPaymentController)
    .controller('B2CCybersourceCallbackController', B2CCybersourceCallbackController )
    .controller('B2CIssuePolicyController', B2CissuePolicyController)
    .controller('B2CConfirmPaymentController', B2CconfirmPaymentController)
    .controller('B2CMTISuccessController', B2CMTISuccessController)
    .controller('UserstateController', UserstateController)

    .filter('customCurrency', ['$filter', function($filter) {
        return function(input) {
            input = parseFloat(input);
            input = input.toFixed(input % 1 === 0 ? 0 : 2);
            return 	'\u0E3F' + input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        };
    }]);

/* Config Application Routes */
daModule.config(routes);