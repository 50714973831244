/**
 * Created by Gerald Garcia on 18/03/15.
 */
module.exports = [
    '$scope',
    '$state',
    '$sessionStorage',
    '$localStorage',
    'ConfigUtils',
    'WfeApiAuth',
    'DaModal',
    'HKFormValidation',
    '$window',
    '$timeout',

    function BrokerForgotPasswordController($scope, $state, $sessionStorage, $localStorage, ConfigUtils, WfeApiAuth, DaModal, HKFormValidation, $window,$timeout) {


        var fn = {

            setIsSaving : function (isSaving) {
                $scope.IsSaving = isSaving;
            },

            initApiObjects: function () {
                fn.authApi = WfeApiAuth.instance(undefined, $scope, $scope.credentials);
            },

            initScopeObjects: function(){
                $scope.credentials = {
                    UserIdentifier: '',
                    UserIdentifierTypeId: 1
                };
            },

            validations: {
                hasInvalid: function hasInvalid() {
                    return $scope.frmLogin.$invalid;
                },

            },

            eventHandler: {

                onSuccessCloseCallback: function () {
                    $state.go('publicB2B.dashboard3');
                },

                btnSubmitClicked: function () {

                    $scope.pageSubmitted = true;

                    if (fn.validations.hasInvalid()) {
                        return;
                    }

                    fn.setIsSaving(true);

                    fn.authApi.resetPassword(
                        {
                            onSuccess: function () {
                                DaModal.open('#passwordResetSuccess', {
                                    onClose: fn.eventHandler.onSuccessCloseCallback
                                });
                            },
                            onError: function (data) {
                                $scope.loginError = true;
                                fn.util.setIsSaving(false);
                                console.log(data.ErrorInfo.StatusCode);
                            }
                        }
                    );

                }
            }
        };

        //// Initialization
        fn.initScopeObjects();
        fn.initApiObjects();

        //Scroll to top
        $window.scrollTo(0, 0);

        ////Event Handlers
        $scope.btnSubmitClicked = fn.eventHandler.btnSubmitClicked;

        $scope.IsSaving = false;

    }];