module.exports = [
    function daTHMobileNo() {
        var fn = function (scope, element, attr, ctrl) {
            function customValidator(value) {
                var isValid = false;
                var regex = /^[0][6|8|9][0-9]\d{7}/;

                if (value) {
                    if (regex.test(value)) {
                        isValid = true;
                    }
                } else {
                    isValid = true;
                }

                ctrl.$setValidity('thMobileNo', isValid);
                return value;
            }

            ctrl.$parsers.unshift(customValidator);
        };

        return {
            restrict: 'A',
            require: 'ngModel',
            link: fn
        };
    }];