module.exports = [
    'MITDate',

    function VehicleValidationService(MITDate) {
        var limitYear = MITDate.getYYYY(MITDate.newDate().add(-10, 'years'));

        var fn = {
            validations: {
                isCoverNotAvail: function (purchasingYear, comprehensiveId, coverageTypeId) {
                    var result = false;
                    var flag = false;

                    if (!coverageTypeId || angular.isUndefined(coverageTypeId)) {
                        flag = true;
                    }

                    if (!purchasingYear || angular.isUndefined(purchasingYear)) {
                        flag = true;
                    }

                    if (coverageTypeId) {
                        if (coverageTypeId === comprehensiveId || flag) {
                            if (purchasingYear < limitYear) {
                                result = true;
                            }
                        }
                    }

                    return result;
                }
            }
        };

        this.isCoverNotAvail = fn.validations.isCoverNotAvail;
    }];
