/**
 * Created by Say Wee Ng
 */
module.exports = [
    '$scope',
    '$state',
    '$sessionStorage',
    '$localStorage',
    'ConfigUtils',
    'WfeApiAuth',
    'WfeApiUser',
    'DaAuthorization',
    'HKFormValidation',
    '$window',
    '$timeout',
    '$stateParams',

    function BrokerLoginController($scope, $state, $sessionStorage, $localStorage, 
                                    ConfigUtils, WfeApiAuth, WfeApiUser, DaAuthorization, 
                                    HKFormValidation, $window,$timeout, $stateParams) {

        var fn = {

            initApiObjects: function () {
                fn.authApi = WfeApiAuth.instance(undefined, $scope, $scope.credentials);
                fn.userApi = WfeApiUser.instance(undefined, $scope, $scope.person);
            },

            initScopeObjects: function(){
                // will be remove later
                // from parameter
                var tmp_credential = {
                    user: $stateParams.user,
                    temppass: $stateParams.temppass
                };

                if(tmp_credential.user!==undefined && tmp_credential.temppass !== undefined){
                    $scope.credentials = {
                        UserIdentifier: tmp_credential.user,
                        Password: tmp_credential.temppass,
                        UserIdentifierTypeId: 1
                    };
                    
                }else{
                    $scope.credentials = {
                        UserIdentifier: '',
                        Password: '',
                        UserIdentifierTypeId: 1
                    };
                }

                $localStorage.tmp_credential = $scope.credentials;

                $scope.person = {};
            },

            eventHandlers: {
                onBtnLoginClicked: function () {
                    
                    fn.authApi.authenticateUser(
                        {
                            onSuccess: function () {
                                fn.util.retrieveAccUser();
                                
                                
                            },
                            onError: function (data) {
                                $scope.loginError = true;
                                fn.util.setIsSaving(false);
                                //console.log(data.ErrorInfo.StatusCode);
                            }
                        }
                    );
                    //fn.util.procceedToNext();
                }
            },

            util: {
                retrieveAccUser: function()
                {
                    fn.userApi.retrieveUser(
                        {
                            onSuccess: function(data) {

                                    angular.extend($scope.person, data.Person);
                                    fn.util.setCurrentUser();
                                    fn.util.setIsSaving(false);
                                    fn.util.procceedToNext();
                                
                            },
                            onError: function () {
                                fn.util.setIsSaving(false);
                            }
                        }
                    );
                },
                setIsSaving: function (isSaving) {
                    $scope.IsSaving = isSaving;
                },
                setCurrentUser: function () {
                    //var person = data.Person;
                    var authPerson = DaAuthorization.getAuthorizedPerson();
                    authPerson.FirstName = $scope.person.FirstName;
                    authPerson.LastName = $scope.person.LastName;
                    $scope.setLoggedInUser(authPerson);
                },
                procceedToNext: function()
                {
                    $state.go('publicB2B.motor.manage.steps.proposal');
                }
            }
        };

        //// Initialization
        fn.initScopeObjects();
        fn.initApiObjects();

        ////Event Handlers
        $scope.onBtnLoginClicked = fn.eventHandlers.onBtnLoginClicked;


    }];