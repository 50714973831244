module.exports = [
    function daInputCheckRepeating() {
        var fn = function (scope, element, attr, ctrl) {
            function customValidator(value) {
                var regex = /^(?:([a-zA-Z0-9])(?!.*\1))*$/;
                var isValid = false;

                if (value) {
                    var newVal = value;
                    if (attr.daInputCheckRepeating && !isNaN(attr.daInputCheckRepeating) && attr.daInputCheckRepeating > 0) {
                        newVal = newVal.substr(0, attr.daInputCheckRepeating);
                    }

                    if (regex.test(newVal)) {
                        isValid = true;
                    }
                } else {
                    isValid = true;
                }

                ctrl.$setValidity('checkRepeating', isValid);
                return value;
            }

            ctrl.$parsers.unshift(customValidator);
        };

        return {
            restrict: 'A',
            require: 'ngModel',
            link: fn
        };
    }];