/**
 * Created by Gerald Garcia on 04/03/15.
 */
module.exports = [
    '$localStorage',
    '$scope',
    '$state',
    '$stateParams',
    '$filter',
    'MITDate',
    'MitScopeBindingUtils',
    'StaticDataKeyValuePair',
    'Policy',
    'Asset',
    'PolicyContact',
    'Coverage',
    'Proposal',
    'DaModal',
    'DaLoadingMask',
    'HKLocale',
    'HKDownload',
    'WfeApiPolicy',
    'WfeApiAsset',
    'WfeApiStaticData',
    'WfeApiPolicyContact',
    'WfeApiUser',
    'WfeApiPayment',

    function ViewPolicyController($localStorage, $scope, $state, $stateParams, $filter,
                                  MITDate, MitScopeBindingUtils, StaticDataKeyValuePair,
                                  Policy, Asset, PolicyContact, Coverage, Proposal,
                                  DaModal, DaLoadingMask, HKLocale, HKDownload,
                                  WfeApiPolicy, WfeApiAsset, WfeApiStaticData, WfeApiPolicyContact, WfeApiUser, WfeApiPayment) {

        var fn = {
            initStaticData: function () {
                var wfeApiStaticData = fn.wfeApiStaticData;

                var startRetrieval = function () {
                    getProductList();
                };

                var getProductList = function () {
                    wfeApiStaticData.getProductList({
                        onSuccess: function (data) {
                            $scope.staticData.product = data;
                            getMakeList();
                        },

                        onError: function () {
                            getMakeList();
                        }
                    });
                };

                var getMakeList = function () {
                    wfeApiStaticData.getMakeList({
                        onSuccess: function (data) {
                            $scope.staticData.makes = data;
                            getOccupationList();
                        },

                        onError: function () {
                            getOccupationList();
                        }
                    });
                };

                var getOccupationList = function () {
                    wfeApiStaticData.getOccupationList({
                        onSuccess: function (data) {
                            $scope.staticData.occupations = data;
                            getNoClaimDiscountList();
                        },

                        onError: function () {
                            getNoClaimDiscountList();
                        }
                    });
                };

                var getNoClaimDiscountList = function () {
                    wfeApiStaticData.getNoClaimDiscountList({
                        onSuccess: function (data) {
                            $scope.staticData.ncdLevels = data;
                            getVehicleMileageList();
                        },

                        onError: function () {
                            getVehicleMileageList();
                        }
                    });
                };

                var getVehicleMileageList = function () {
                    wfeApiStaticData.getVehicleMileageList({
                        onSuccess: function (data) {
                            $scope.staticData.mileages = data;
                            getDriverExperienceList();
                        },

                        onError: function () {
                            getDriverExperienceList();
                        }
                    });
                };

                var getDriverExperienceList = function () {
                    wfeApiStaticData.getDriverExperienceList({
                        onSuccess: function (data) {
                            $scope.staticData.drivingExperiences = data;
                            getBooleanList();
                        },

                        onError: function () {
                            getBooleanList();
                        }
                    });
                };

                var getBooleanList = function () {
                    wfeApiStaticData.getBooleanList({
                        onSuccess: function (data) {
                            $scope.staticData.booleans = data;
                            getVehicleUsageList();
                        },

                        onError: function () {
                            getVehicleUsageList();
                        }
                    });
                };

                var getVehicleUsageList = function () {
                    wfeApiStaticData.getVehicleUsageList({
                        onSuccess: function (data) {
                            $scope.staticData.vehicleUsages = data;
                            getPolicyTypeList();
                        },

                        onError: function () {
                            getPolicyTypeList();
                        }
                    });
                };

                var getPolicyTypeList = function () {
                    wfeApiStaticData.getPolicyTypeList({
                        onSuccess: function (data) {
                            $scope.staticData.policyTypes = data;
                            getParticipantTypeList();
                        },

                        onError: function () {
                            getParticipantTypeList();
                        }
                    });
                };

                var getParticipantTypeList = function () {
                    wfeApiStaticData.getParticipantTypeList({
                        onSuccess: function (data) {
                            $scope.staticData.participantTypes = data;
                            getAllPaymentTermsLists();
                        },

                        onError: function () {
                            getAllPaymentTermsLists();
                        }
                    });
                };

                var getAllPaymentTermsLists = function () {
                    wfeApiStaticData.getAllPaymentTermsList({
                        onSuccess: function (data) {
                            $scope.staticData.allPaymentTerms = data;
                            getResidentialStatusList();
                        },

                        onError: function () {
                            getResidentialStatusList();
                        }
                    });
                };

                var getResidentialStatusList = function () {
                    wfeApiStaticData.getResidentialStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.residentialStatuses = data;
                            getGenderList();
                        },
                        OnError: function () {
                            getGenderList();
                        }
                    });
                };

                var getGenderList = function () {
                    wfeApiStaticData.getGenderList({
                        onSuccess: function (data) {
                            $scope.staticData.genders = data;
                            getMaritalStatusList();
                        },
                        onError: function () {
                            getMaritalStatusList();
                        }
                    });
                };

                var getMaritalStatusList = function () {
                    wfeApiStaticData.getMaritalStatusList({
                        onSuccess: function (data) {
                            $scope.staticData.maritalStatuses = data;
                            getProvinceList();
                        },
                        onError: function () {
                            getProvinceList();
                        }
                    });
                };

                var getProvinceList = function () {
                    wfeApiStaticData.getProvinceList({
                        onSuccess: function (data) {
                            $scope.staticData.provinces = data;
                            getCountryList();
                        },
                        onError: function () {
                            getCountryList();
                        }
                    });
                };



                var getCountryList = function () {
                    wfeApiStaticData.getCountryList({
                        onSuccess: function (data) {
                            $scope.staticData.countries = data;
                        },
                        onError: function () {
                        }
                    });
                };

                startRetrieval();
            },

            initApiObjects: function () {
                fn.wfeApiPolicy = WfeApiPolicy.instance(undefined, $scope, $scope.policy);
                fn.wfeApiAsset = WfeApiAsset.instance(undefined, $scope, $scope.policy);
                fn.wfeApiPolicyContact = WfeApiPolicyContact.instance(undefined, $scope, $scope.policy);
                fn.wfeApiPayment = WfeApiPayment.instance(undefined, $scope, $scope.policy);

                fn.wfeApiUser = WfeApiUser.instance($scope, HKLocale.getLocale(), $scope.accountUser);

                fn.wfeApiStaticData = WfeApiStaticData.instance($scope, HKLocale.getLocale(), $scope.policy.ProductId);
            },

            initScopeObjects: function () {
                $scope.currentUser = {
                    documentsForDownload: {},
                    renewalProposals: {}//Proposal.retrieveExistingRenewalProposals()
                };

                $scope.selectedCoverage = {};

                $scope.payment = {};
                var s=$stateParams.policyNumber.toString();
                if(s.indexOf('E')==0)
                {
                    //Endorsement- replace first - with /
                   s= s.substr(1);
                   s= s.replace('-', '/');
                }
                else
                {
                    //Renewal- replace all - with /
                    s=s.replace(/-/g, '/');
                }
                $scope.policy = {
                    policyNumber: s,
                    ProductId: $stateParams.productId
                };

                $scope.asset = {};
                $scope.policyHolder = {};
                $scope.mainDriver = {};
                $scope.namedDriver = {};

                //required for Health
                $scope.accountUser = {};
                $scope.phContact = {};
                $scope.phAddress = {};
                $scope.insuredPerson = {};

                $scope.namedDrivers = {
                    PolicyContacts: []
                };
                $scope.additionalTravellers = {
                    PolicyContacts: []
                };

                $scope.coverages = {
                    Coverages: []
                };

                $scope.staticData = {};
                $scope.isCompulsoryAvailFlag=false;
                $scope.isCompulsoryOnly=false;

                $scope.sumInsured={};
                $scope.sumInsured.value=0;
                $scope.sumInsured.visible=false;

                $scope.basicCoverageInfo = {
                    "2000015":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Choice of deductible, sum insured is xxx,xxx",
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Deductible of xxx sum insured is xxx,xxx",
                        ],
                    "2000019":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_any',
                            //"Deductible of xxx sum insured is xxx,xxx",
                        ],
                    "2000016":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                            //"Deductible of x,xxx. Sum insured is xxx,xxx",

                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_best_price',
                            'body_basic_coverage_info_fix_our_network',
                            //"Deductible of x,xxx. Sum insured is xxx,xxx",

                        ]};
                $scope.basicCoverageInfo_Part2 = {
                    "2000015":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000017":
                        [
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000019":
                        [
                            //"Deductible of xxx sum insured is xxx,xxx",
                            'body_basic_coverage_info_zero_installment',
                            'body_basic_coverage_info_payment'
                        ],
                    "2000016":
                        [

                            'body_basic_coverage_info_payment'
                        ],
                    "2000018":
                        [
                            'body_basic_coverage_info_payment'
                        ]};
            },

            initScopeFunction: function () {
                $scope.utils = {
                    filterTravelCovers:fn.utils.filterTravelCovers,
                    getStaticDataDisplay: fn.wfeApiStaticData.utils.getStaticDataDisplay,
                    filterBaseCover: fn.utils.filterBaseCover,
                    filterMandatoryCovers: fn.utils.filterMandatoryCovers
                };

                $scope.btnShowDownloadClicked = fn.eventHandler.btnShowDownloadClicked;
                $scope.btnDownloadDocumentClicked = fn.eventHandler.btnDownloadDocumentClicked;
            },

            retrieveScopeObjects: function () {
                fn.wfeApiUser.retrieveUser(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.accountUser, data);
                            populateAccountUserModel();
                        },
                        onError: function () {
                        }
                    }
                );

                fn.wfeApiPolicy.retrieveByPolicy(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.policy, data);
                        }
                    }
                );

                fn.wfeApiAsset.retrieveByPolicy(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.asset, data);
                            fn.utils.setSumInsured();
                            if ($scope.asset.ManufactureId) {
                                retrieveModelStaticData();
                            }
                            angular.extend($scope.coverages, $scope.asset.Coverages);
                            fn.bindCoverage($scope.coverages);
                            fn.eventHandler.isOnlyCompulsory();
                            fn.eventHandler.isCompulsoryAvail();
                        }
                    }
                );

                fn.wfeApiPolicyContact.retrieveByPolicy(
                    {
                        onSuccess: function (data) {
                            data.PolicyContacts = data.PolicyContacts || [];
                            var proposalObj = data;

                            var policyHolder = $filter('filter')(proposalObj.PolicyContacts,
                                {RoleName: PolicyContact.RoleNames.PolicyHolder}, true)[0];

                            var bindPolicyContactObj = function (scopeObj, jsonObj) {
                                if (jsonObj) {
                                    jsonObj.success = proposalObj.success;
                                    jsonObj.isSaving = false;
                                    angular.extend(scopeObj, jsonObj);
                                }
                            };

                            if (policyHolder) {
                                bindPolicyContactObj($scope.policyHolder, policyHolder);
                            }

                            if (policyHolder) {
                                switch ($scope.policy.ProductId) {
                                    //Term Life
                                    case '2000002':

                                        break;
                                    //Health
                                    case '2000006':
                                        if (policyHolder.IsInsuree) {
                                            bindPolicyContactObj($scope.insuredPerson, policyHolder);
                                        } else {
                                            var insuredPerson =
                                                $filter('filter')(proposalObj.PolicyContacts,
                                                    function (arrayItem) {
                                                        return arrayItem.RoleName.substr(0, 2) === PolicyContact.RoleNames.Insurer;
                                                    }, true)[0];

                                            if (insuredPerson) {
                                                bindPolicyContactObj($scope.insuredPerson, insuredPerson);
                                            }
                                        }
                                        break;
                                    //Travel
                                    case '2000001':
                                        var additionalTravellers =
                                            $filter('filter')(proposalObj.PolicyContacts,
                                                function (arrayItem) {
                                                    return arrayItem.RoleName.substr(0, 2) === PolicyContact.RoleNames.AdditionalTraveller;
                                                }, true);

                                        if (additionalTravellers) {
                                            $scope.additionalTravellers.PolicyContacts.splice(0);
                                            angular.forEach(additionalTravellers, function (item, index) {
                                                var traveller = PolicyContact.newAdditionalTraveller();

                                                bindPolicyContactObj(traveller, item);
                                                $scope.additionalTravellers.PolicyContacts[index] = traveller;
                                            });
                                        }

                                        if (policyHolder.IsInsuree) {
                                            var newTraveller = PolicyContact.newAdditionalTraveller();
                                            var aTraveller = {};

                                            angular.extend(aTraveller, policyHolder);
                                            angular.extend(aTraveller, newTraveller);
                                            aTraveller.RelationshipIdWithPH = PolicyContact.RelationshipTypes.MYSELF;
                                            $scope.additionalTravellers.PolicyContacts.push(aTraveller);
                                        }
                                        break;
                                    //Motorcar & Motorcycle
                                    case '1000009':
                                    case '2000005':
                                    case '2000004':
                                        var mainDriver =
                                            $filter('filter')(proposalObj.PolicyContacts,
                                                {RoleName: PolicyContact.RoleNames.MainDriver}, true)[0];

                                        var namedDrivers =
                                            $filter('filter')(proposalObj.PolicyContacts,
                                                function (arrayItem) {
                                                    return arrayItem.RoleName.substr(0, 2) === PolicyContact.RoleNames.NamedDriver;
                                                }, true);

                                        if (mainDriver) {
                                            bindPolicyContactObj($scope.mainDriver, mainDriver);
                                        }

                                        if (namedDrivers) {
                                            $scope.namedDrivers.PolicyContacts.splice(0);
                                            angular.forEach(namedDrivers, function (item, index) {
                                                var nDriver = PolicyContact.newNamedDriver();

                                                bindPolicyContactObj(nDriver, item);
                                                $scope.namedDrivers.PolicyContacts[index] = nDriver;
                                            });
                                        }

                                        if (policyHolder.IsMainDriver) {
                                            copyPHFieldsToMD();
                                        }

                                        if (policyHolder.IsInsuree) {
                                            var newNamedDriver = PolicyContact.newNamedDriver();
                                            var aNamedDriver = {};

                                            angular.extend(aNamedDriver, policyHolder);
                                            angular.extend(aNamedDriver, newNamedDriver);
                                            aNamedDriver.RelationshipIdWithPH = PolicyContact.RelationshipTypes.MYSELF;
                                            $scope.namedDrivers.PolicyContacts.push(aNamedDriver);
                                        }
                                        break;
                                }
                            }
                        }
                    }
                );

                fn.wfeApiPayment.retrievePaymentByPolicy(
                    {
                        onSuccess: function (data) {
                            angular.extend($scope.payment, data);
                        }
                    }
                );

                var populateAccountUserModel = function () {
                    $scope.phContact = $scope.accountUser.Contact;

                    var PrimaryPhoneNumber = $filter('filter')($scope.phContact.Contacts, {
                        ContactType: 1,
                        IsPreferred: 'true'
                    })[0];
                    if (PrimaryPhoneNumber) {
                        $scope.phContact.PrimaryPhoneNumber = PrimaryPhoneNumber.Value;
                    }

                    if ($scope.accountUser.Address) {
                        $scope.phAddress = $scope.accountUser.Address.Addresses[0];
                        $scope.phAddress.CountryId = $scope.phAddress.CountryId.toString();
                    }
                };

                var retrieveModelStaticData = function () {

                    var objParams = {};
                    objParams.manufacturerId = $scope.asset.ManufactureId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    console.log(objParams);

                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getModelListWithYear({
                        onSuccess: function (data) {
                            console.log(data);
                            $scope.staticData.models = data;
                            retrieveDescStaticData();
                            if(data.length==0) {
                                DaModal.alert('The Make and Model you choose is not available. Call us at 02-767-7777 to talk with an agent.');

                            }
                        }
                    });
                };

                var retrieveDescStaticData = function () {

                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);

                    wfeApiStaticDataModel.getCarDescWithYear({
                        onSuccess: function (data) {
                            console.log(data);
                            getEngineSizes();
                            for(var i in data)
                                if(data[i]['key'] == $scope.asset.CarDescriptionId)
                                    $scope.staticData.cardescriptions = data[i];

                        }
                    });
                };

                var getEngineSizes = function () {
                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getEngineSizes({
                        onSuccess: function (data) {
                            $scope.staticData.enginesizes = data;
                            getGearTypes();
                        },
                        onError: function () {
                            getGearTypes();
                        }
                    });
                };

                var getGearTypes = function () {
                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getGearTypes({
                        onSuccess: function (data) {
                            $scope.staticData.geartypes = data;
                            getBodyTypes();
                        },
                        onError: function () {
                            getBodyTypes();
                        }
                    });
                };

                var getBodyTypes = function () {
                    var objParams = {};
                    objParams.modelId = $scope.asset.ModelId;
                    objParams.purchasingYear = $scope.asset.PurchasingYear;
                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale(), objParams);
                    wfeApiStaticDataModel.getBodyTypes({
                        onSuccess: function (data) {
                            $scope.staticData.bodytypes = data;
                            getImportStatuses();
                        },
                        onError: function () {
                            getImportStatuses();
                        }
                    });
                };

                var getImportStatuses = function () {

                    var wfeApiStaticDataModel = WfeApiStaticData.instance($scope, $scope.HKLocale.getLocale());
                    wfeApiStaticDataModel.getImportStatuses({
                        onSuccess: function (data) {
                            $scope.staticData.importstatuses = data;

                        },
                        onError: function () {

                        }
                    });
                };

                var copyPHFieldsToMD = function () {
                    angular.extend($scope.mainDriver, $scope.policyHolder);
                    $scope.mainDriver.RelationshipIdWithPH = PolicyContact.RelationshipTypes.MYSELF;
                };
            },

            //setIsLoading: function (isLoading) {
            //    DaLoadingMask.toggle(isLoading);
            //},
            //
            //
            //getCoverage: function (coverageObj) {
            //    var coverage = $filter('filter')(
            //        fn.coveragesStatic.coverages,
            //        {
            //            CoverTypeId: coverageObj.CoverTypeId
            //        },
            //        true
            //    )[0];
            //
            //    if (coverage) {
            //        return coverage;
            //    }
            //
            //},

            //'coveragesStatic': Coverage.getStaticCoverages(),
            //
            //policyData: {
            //    renewalProposals: []
            //},
            //
            //getPolicyData: function () {
            //    $scope.currentUser.renewalProposals.$promise.then(function () {
            //        angular.forEach($scope.currentUser.renewalProposals.Proposals, function (item) {
            //            item.ExternalPolicyNumberLink = item.ExternalProposalNumber.replace(/\//g, '-');
            //            item.RenewExternalPolicyNumberLink = item.ExternalProposalNumber;
            //        });
            //    });
            //},


            bindCoverage: function (newValue) {

                if (!newValue.Coverages) {
                    return;
                }

                var selectedCover = $filter('filter')(newValue.Coverages, {IsSelected: true}, true)[0];

                if (selectedCover) {
                    angular.extend($scope.selectedCoverage, selectedCover);
                }

               /* if($scope.policy.ProductId === '1000009')
                {
                    fn.utils.addExtraCarComprehensiveCoverforDisplay();
                }*/

            },

            showDownloadModal: function () {
                DaModal.open('#downloadDocumentsModal');
            },

            isRenewalExpired: function (proposal, policy) {
                if (!proposal) {
                    return false;
                }

                var validDate = MITDate.newDate(policy.EndorsEndDate);
                var todayDate = MITDate.newDate();

                var diff = validDate.diff(todayDate, 'days');
                var isExpired = (diff < 0);

                return isExpired;
            },

            eventHandler: {
                isOnlyCompulsory:function(){
                    if(!$scope.coverages){
                        $scope.isCompulsoryOnly=false;
                        return;
                    }
                    try {
                        for (var i = 0; i < $scope.coverages.Coverages.length; i++) {
                            if ($scope.coverages.Coverages[i].CoverTypeId == 2000020 || $scope.coverages.Coverages[i].CoverTypeId == '2000020') {
                                $scope.isCompulsoryOnly = true;
                            }
                            else {
                                $scope.isCompulsoryOnly = false;
                            }
                        }
                    }
                    catch(err)
                    {
                        $scope.isCompulsoryOnly = false;
                    }
                },
                isCompulsoryAvail:function()
                {
                    if(!$scope.coverages){
                        $scope.isCompulsoryAvailFlag=false;
                        return;
                    }
                    for (var i = 0; i < $scope.coverages.Coverages.length; i++) {

                        var ccb = $filter('filter')($scope.coverages.Coverages[i].Covers, {
                            ProductLineGroupId: 2000161,
                        }, true);
                        if (ccb) {
                            if (ccb[0].IsSelected)    $scope.isCompulsoryAvailFlag=true;
                            else    $scope.isCompulsoryAvailFlag=false;
                        }

                    }

                },

                btnShowDownloadClicked: function (policy) {
                    fn.showDownloadModal();
                    $scope.currentUser.documentsForDownload = Policy.retrievePolicyDocuments(undefined, {
                        policyNumber: policy.ExternalPolicyNumber || policy.ExternalProposalNumber
                    });
                },

                //btnRenewPolicyClicked: function btnRenewPolicyClicked(proposal, policy) {
                //    if (fn.isRenewalExpired(proposal, policy)) {
                //        DaModal.open('#expiredRenewal');
                //        return;
                //    }
                //
                //    var products = {
                //        '1000003': {state: 'public.motor.quote.steps.confirm', code: 'Motor Car'},
                //        '1000004': {state: 'public.travel.quote.steps.confirm', code: 'travel'},
                //        '2000000': {state: 'public.motorcycle.quote.steps.confirm', code: 'motorcycle'},
                //        '2000002': {state: 'public.sos.quote.steps.confirm', code: 'SOS'},
                //        '2000003': {state: 'public.termlife.quote.confirm', code: 'Term Life'}
                //    };
                //
                //    var productCode = products['' + proposal.ProductId].code;
                //    var productState = products['' + proposal.ProductId].state;
                //    var utils = new MitScopeBindingUtils($scope, $localStorage, productCode);
                //
                //
                //    var createRenewal = function () {
                //        var mitScopeBindingMit = utils;
                //        var pAndCModelStorage = mitScopeBindingMit.getPAndCModelFromStorage();
                //
                //        utils.initScopeAndStorage();
                //
                //        $scope.proposal = new Proposal();
                //        $scope.proposal.policyNumber = proposal.RenewExternalPolicyNumberLink;
                //
                //        $scope.proposal.$retrieveRenewal(undefined, function (response) {
                //            if (response.success) {
                //
                //                pAndCModelStorage.session = response;
                //                utils.saveToScopePAndCModel('session', pAndCModelStorage.session);
                //
                //                pAndCModelStorage.isAccepted = true;
                //                $state.go(productState);
                //            }
                //        });
                //    };
                //
                //    createRenewal();
                //},

                btnDownloadDocumentClicked: function btnDownloadClicked(docObj) {
                    HKDownload.downloadDocument($scope, docObj);
                    //Policy.downloadDocument(undefined, {
                    //    DocumentId: docObj.DocumentID
                    //});
                }
            },

            utils: {
                filterOptionalButCompulsoryCovers: function (r) {

                    var status = false;

                    if (r.ProductLineType === '2' || r.ProductLineType === 2) {

                        if (r.ProductLineGroupId === 2000161 || r.ProductLineGroupId ===2000114) { //Complusory Basic Cove
                            /*if(r.IsSelected)
                             status = true;
                             else status=false;*/
                            status=false;
                        }
                        else {
                            if(r.IsSelected){status=true}
                            else
                                status = false;
                        }



                    } else {

                        if (r.ProductLineType === '0' || r.ProductLineType === 0) {

                            if (r.ProductLineGroupId === 2000101 || r.ProductLineGroupId === 2000102
                                || r.ProductLineGroupId=== 2000112 || r.ProductLineGroupId===2000107) {
                                //|| r.ProductLineGroupId==2000144
                                //hides permenanet disba for passenger as is merged with driver in copy
                               // if(r.IsSelected)
                               //     status = true;
                               // else status=false;
                                status=false;
                            }
                            else {
                                if(r.ProductLineGroupId===2000100
                                    || r.ProductLineGroupId===2000160) // always hide Compulsory Core Benefits, Core Benefits
                                {
                                    status=false;
                                }
                                else {
                                    status = true;
                                }


                            }

                        } else {

                            if(r.ProductLineType === '1' || r.ProductLineType === 1)
                            {
                                if(r.ProductLineGroupId===2000103 || r.ProductLineGroupId===2000116) // VBC, MyWorkshop
                                {
                                    status=false;
                                }
                                else
                                { status=true;}
                            }
                            else {


                                status = true;
                            }
                        }

                        //status = true;



                    }


                    return status;

                },

                addExtraCarComprehensiveCoverforDisplay: function () {
                    var coverageCovers = $scope.selectedCoverage;
                    var comprehensiveCoverlist = {};
                    var FinalComprehensiveCoverList = [];

                        if(coverageCovers.CoverTypeId === 1) // only when it is comprehensive
                        {console.log('addExtraCarComprehensiveCoverforDisplay : if 1');
                            comprehensiveCoverlist = coverageCovers.Covers;

                            for (var k = 0; k < comprehensiveCoverlist.length; k++) {
                                console.log('addExtraCarComprehensiveCoverforDisplay : if 1 for 1');
                                var cover = comprehensiveCoverlist[k];

                                if(cover.ProductLineGroupId === 1000102)  // before Damage to own car (No other vehicle involved)
                                {
                                    var ownDamageCollisionCover = {
                                        "CoverName": "Collision with third party vehicle - Comprehensive[Added]",
                                        "IsSelected": true,
                                        "ProductLineGroupId": 1000103,
                                        "ProductLineTypeString":"Mandatory",
                                        "ProductLineOptionId":2000809,"ProductLineType":0};
                                    FinalComprehensiveCoverList.push(ownDamageCollisionCover);
                                }
                                FinalComprehensiveCoverList.push(cover);

                            }

                            $scope.selectedCoverage.Covers = FinalComprehensiveCoverList;
                        }
                },

                filterTravelCovers: function (r) {
                    var status = false;
                    if($scope.policy.ProductId === '2000001'){

                        if(r && r.ProductLineGroupId){
                            if (r.ProductLineGroupId === 2000036) {
                                status = false;
                            } else if (r.ProductLineGroupId === 2000027) {
                                status = false;
                            }
                            else {
                                status = true;
                            }
                        }
                    } else {
                        status = true;//pass all non travel product
                    }
                    return status;

                },
                filterBaseCover: function (r) {
                    var status = false;
                    if (r.CoverName === 'Basic Cover' || r.ProductLineGroupId === 1000087) {
                        status = true;
                    }
                    return status;
                },

                filterMandatoryCovers: function (r) {
                    var status = false;
                    if (r.ProductLineType === '0' || r.ProductLineType === 0) {
                        if (r.CoverName === 'Basic Cover' || r.ProductLineGroupId === 1000087) {
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000108) { //policyExcess
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 1000109) {//Administration Fee
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000059) {//Recovery Expenses - TPO
                            status = false;
                        }
                        else if (r.ProductLineGroupId === 2000060) {//Legal Expenses - TPO
                            status = false;
                        }
                        else {
                            status = true;
                        }
                    } else {
                        status = false;
                    }

                    return status;
                },

                setSumInsured:function()
                {
                    $scope.sumInsured.value=0;
                    $scope.sumInsured.visible=false;
                    var sc = $scope.asset.Coverages.Coverages[0];
                    if(!sc) {return;}
                    if(sc.CoverTypeId==2000018 || sc.CoverTypeId=='2000018')
                    {
                        //Type 3 is always NA and hidden
                        $scope.sumInsured.visible=false;
                        $scope.sumInsured.value=0;

                        return;
                    }
                    if(sc.CoverTypeId==2000019 || sc.CoverTypeId=='2000019'
                        || sc.CoverTypeId==2000017 || sc.CoverTypeId=='2000017'
                        || sc.CoverTypeId==2000015 || sc.CoverTypeId=='2000015')
                    {
                        //Type 2+/ 3+ / Type 1 - value from own damage collision
                        //Type 1- own damage collision value.
                        var odc=$filter('filter')(sc.Covers, {
                            ProductLineGroupId: 2000105
                        },true);
                        if(odc && odc[0].InsuranceAmount){

                            $scope.sumInsured.value=odc[0].InsuranceAmount;
                            $scope.sumInsured.visible=true;
                        }
                        else {
                            $scope.sumInsured.value=0;
                            $scope.sumInsured.visible=false;
                        }


                        return;
                    }

                    if(sc.CoverTypeId==2000016 || sc.CoverTypeId=='2000016')
                    {

                        //Type 2- own damage Fire and theft value
                        var odft=$filter('filter')(sc.Covers, {
                            ProductLineGroupId: 2000106
                        },true);
                        if(odft && odft[0].InsuranceAmount){

                            $scope.sumInsured.value=odft[0].InsuranceAmount;
                            $scope.sumInsured.visible=true;
                        }
                        else {
                            $scope.sumInsured.value=0;
                            $scope.sumInsured.visible=false;
                        }
                        return;
                    }

                    if(sc.CoverTypeId==2000020 || sc.CoverTypeId=='2000020')
                    {

                        //Type Compulsory only
                        $scope.sumInsured.visible=false;
                        $scope.sumInsured.value=0;

                        return;
                    }
                    //Exception- Everything fails- hide
                    $scope.sumInsured.visible=false;
                    $scope.sumInsured.value=0;

                },
            }
        };

        fn.initScopeObjects();
        fn.initApiObjects();
        fn.initStaticData();
        fn.retrieveScopeObjects();
        fn.initScopeFunction();

        //Event Handlers
        //
        //$scope.btnRenewPolicyClicked = fn.eventHandler.btnRenewPolicyClicked;
        //$scope.getCoverage = fn.getCoverage;
        //$scope.utils = fn.utils;
        /*$scope.filterCovers = fn.filterCovers;
         $scope.filterOptionalCovers = fn.filterOptionalCovers;*/

        $scope.filterOptionalButCompulsoryCovers = fn.utils.filterOptionalButCompulsoryCovers;
        $scope.setSumInsured = fn.utils.setSumInsured;

    }];